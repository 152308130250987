import { Helmet } from 'react-helmet-async';

type Props = {
  title: string;
};

export const PageHead: React.FC<Props> = ({ title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
    </Helmet>
  );
};
