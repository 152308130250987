const PATH = '/chats';

export const CHAT_API_ROUTES = {
  getMy: `${PATH}/my`,
  createChat: `${PATH}/create`,
  pullMy: `${PATH}/my/pull`,
  getChatDetail: (id: number) => `${PATH}/${id}/detail`,
  getChatMessages: (id: number) => `${PATH}/messages/${id}`,
  pullChatMessages: (id: number) => `${PATH}/pull/info/messages/${id}`,
  sendMessage: (id: number) => `${PATH}/messages/send/${id}`,
  deleteChat: (id: number) => `${PATH}/delete/${id}`,
  readMessages: `${PATH}/messages/read`,
  updateMessage: (id: number) => `${PATH}/messages/${id}/update`,
  deleteMessage: (id: number) => `${PATH}/messages/${id}/delete`,
  getAllChatPhotos: (id: number) => `${PATH}/${id}/content/photos`,
  getAllChatVideos: (id: number) => `${PATH}/${id}/content/videos`,
  getAllChatFiles: (id: number) => `${PATH}/${id}/content/files`,
  getAllChatPhotosVideos: (id: number) => `${PATH}/${id}/content/photos-videos`,
  getUnreadCount: `${PATH}/count-unread`,
  searchChats: `${PATH}/my/search`,
  searchChatMessages: (id: number) => `${PATH}/${id}/search`,
  searchMessages: `${PATH}/search/messages`,
  getCompanionChats: (id: number) => `${PATH}/${id}/list`,
  getCreatedChatsCount: '/chats/count',
  quickReplies: `${PATH}/quick-reply`
};
