import { Address } from '@/modules/showroom/advert/create/Address/Address';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

export function AddressWrap() {
  const addressId = useUpdateVrpStore((s) => s.addressId);
  const setAddressId = useUpdateVrpStore((s) => s.setAddressId);
  const isAddressFilled = useUpdateVrpStore((s) => s.isAddressFilled);
  const setAddressFilled = useUpdateVrpStore((s) => s.setAddressFilled);

  return (
    <Address
      addressId={addressId}
      setAddressId={setAddressId}
      isAddressFilled={isAddressFilled}
      setAddressFilled={setAddressFilled}
    />
  );
}
