import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { CountryCodeType } from '@/modules/country-codes/types';
import { useEmirates } from '@/modules/emirate/api';
import { EmirateType } from '@/modules/emirate/types';
import { AddressMapWrap } from '@/modules/showroom/address/AddressForm/AddressMapWrap';
import { CreateShowroomAddressReq } from '@/modules/showroom/address/types';
import {
  DEFAULT_CALL_AFTER_MINUTES,
  DEFAULT_CALL_BEFORE_MINUTES
} from '@/modules/showroom/advert/create/helpers';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { validatePhoneInput } from '@/ui/PhoneInput/helpers';
import { PhoneInput } from '@/ui/PhoneInput/PhoneInput';
import { Select } from '@/ui/Select/Select';
import { SelectRange } from '@/ui/Select/SelectRange';
import { Skeleton } from '@/ui/Skeleton';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { makeHoursArray, minutesToHHMM } from '@/utils/date';
import { maxLength, preventExtraSpaces } from '@/utils/format';

import cls from './AddressForm.module.scss';

const emiratesReq = { limit: MAX_PG_LIMIT };
const hours = makeHoursArray();
const DUBAI_CENTER_COORDS = [25.204567, 55.270695];
const MAX_NAME_LEN = 64;

type Props = {
  isOpen: boolean;
  close: () => void;
  buttonText: string;
  onSubmit: (values: CreateShowroomAddressReq) => void;
  values?: CreateShowroomAddressReq;
  loading?: boolean;
};

export function AddressForm({
  isOpen,
  close,
  values,
  loading,
  onSubmit,
  buttonText
}: Props) {
  const { t } = useTranslation();

  // Emirates
  const [emirates, isEmiratesLoading] = useEmirates(emiratesReq);
  const [emirateId, setEmirateId] = useState<Nullable<number>>(
    values?.emirate_id || null
  );

  const [selectedEmirate, setSelectedEmirate] =
    useState<Nullable<EmirateType>>(null);

  const emiratesOptions = useMemo<OptionI[]>(
    () => emirates?.map((e) => ({ id: String(e.id), text: e.name })) || [],
    [emirates]
  );
  const emirateOption = useMemo(
    () => emiratesOptions.find((opt) => opt.id === String(emirateId)),
    [emirateId, emiratesOptions]
  );

  // Working hours
  const [minutesFrom, setMinutesFrom] = useState<number>(
    values?.working_hours_from || DEFAULT_CALL_AFTER_MINUTES
  );
  const [minutesTo, setMinutesTo] = useState<number>(
    values?.working_hours_up_to || DEFAULT_CALL_BEFORE_MINUTES
  );

  const options = useMemo<OptionI[]>(
    () =>
      hours.map((h) => ({
        id: String(h),
        text: minutesToHHMM(h * 60)
      })),
    []
  );

  const after = options.find(
    (opt) => Number(opt.id) === minutesFrom / 60
  ) as OptionI;
  const before = options.find(
    (opt) => Number(opt.id) === minutesTo / 60
  ) as OptionI;

  const afterValue = useMemo(
    () => ({
      ...after,
      text: t('common.fromTime', { time: after.text })
    }),
    [after, t]
  );
  const beforeValue = useMemo(
    () => ({
      ...before,
      text: t('common.toTime', { time: before.text })
    }),
    [before, t]
  );

  const afterOptions = useMemo(() => {
    if (before) {
      const beforeH = Number(before.id);
      return options.filter((o) => Number(o.id) < beforeH);
    }

    return options;
  }, [before, options]);

  const beforeOptions = useMemo(() => {
    if (after) {
      const afterH = Number(after.id);
      return options.filter((o) => Number(o.id) > afterH);
    }

    return options;
  }, [after, options]);

  const handleAfter = (v: OptionI) => {
    const h = Number(v.id);

    if (!before) {
      setMinutesFrom(h * 60);
    } else if (before) {
      const beforeH = Number(before.id);

      if (h < beforeH) {
        setMinutesFrom(h * 60);
      }
    }
  };

  const handleBefore = (v: OptionI) => {
    const h = Number(v.id);

    if (!after) {
      setMinutesTo(h * 60);
    } else if (after) {
      const afterH = Number(after.id);

      if (h > afterH) {
        setMinutesTo(h * 60);
      }
    }
  };

  // Name
  const [name, setName] = useState<string>(values?.name || '');

  // Phone
  const [phone, setPhone] = useState<string>(values?.phone || '');
  const [phoneCode, setPhoneCode] = useState<Nullable<CountryCodeType>>(null);
  const [isMaskCorrect, setMaskCorrect] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  useEffect(() => {
    setIsPhoneValid(validatePhoneInput(phoneCode, phone, isMaskCorrect));
  }, [phoneCode, isMaskCorrect, phone, setIsPhoneValid]);

  // Map
  const [address, setAddress] = useState<string>(values?.address || '');

  const [lat, setLat] = useState<number>(values?.lat || DUBAI_CENTER_COORDS[0]);
  const [long, setLong] = useState<number>(
    values?.long || DUBAI_CENTER_COORDS[1]
  );

  // Reset values on open
  useEffect(() => {
    if (isOpen) {
      setName(values?.name || '');
      setPhone(values?.phone || '');
      setEmirateId(values?.emirate_id || null);
      setAddress(values?.address || '');
      setLat(values?.lat || DUBAI_CENTER_COORDS[0]);
      setLong(values?.long || DUBAI_CENTER_COORDS[1]);
      setMinutesFrom(values?.working_hours_from || DEFAULT_CALL_AFTER_MINUTES);
      setMinutesTo(values?.working_hours_up_to || DEFAULT_CALL_BEFORE_MINUTES);
    }
  }, [isOpen, values]);

  // Submit
  const isValid = !!name && !!address && !!emirateId && isPhoneValid;

  const onSubmitClick = () => {
    if (isValid)
      onSubmit({
        name,
        phone,
        address,
        lat,
        long,
        emirate_id: emirateId,
        working_hours_from: minutesFrom,
        working_hours_up_to: minutesTo
      });
  };

  useEffect(() => {
    if (emirateId === values?.emirate_id && !selectedEmirate) return;

    if (emirateId && emirates) {
      const emirate = emirates.find((em) => em.id === emirateId);
      if (emirate) setSelectedEmirate(emirate);
    }
  }, [emirateId, emirates, selectedEmirate, values?.emirate_id]);

  return (
    <AsideModal
      title={values ? t('address') : t('showroom.newAddress')}
      name="address-form"
      isOpen={isOpen}
      close={close}
      containerClass={cls.modal_container}
    >
      <div className={cls.root}>
        <div className={cls.field}>
          <Input
            value={name}
            onChange={(e) =>
              setName(
                maxLength(
                  preventExtraSpaces(e.currentTarget.value.trimStart()),
                  MAX_NAME_LEN
                )
              )
            }
            disabled={loading}
            label={t('addressName')}
          />
        </div>
        <div className={cls.field}>
          <PhoneInput
            code={phoneCode}
            setCode={setPhoneCode}
            value={phone}
            onChange={setPhone}
            label={t('phone')}
            inputMode="numeric"
            setMaskCorrect={setMaskCorrect}
            hideCountries
          />
        </div>
        <div className={cls.field}>
          {emiratesOptions.length > 0 ? (
            <Select
              name="address-emirate"
              value={emirateOption}
              onChange={(v) => setEmirateId(Number(v.id))}
              options={emiratesOptions}
              disabled={isEmiratesLoading}
              label={t('emirate')}
            />
          ) : (
            <Skeleton width="100%" height={56} style={{ borderRadius: 12 }} />
          )}
        </div>
        {isOpen && (
          <AddressMapWrap
            address={address}
            setAddress={setAddress}
            lat={lat}
            long={long}
            setLat={setLat}
            setLong={setLong}
            emirate={selectedEmirate}
            loading={loading}
          />
        )}
        <div className={cls.field}>
          <SelectRange
            name={`showroom-call-time`}
            from={afterValue}
            onFromChange={handleAfter}
            fromOptions={afterOptions}
            to={beforeValue}
            onToChange={handleBefore}
            toOptions={beforeOptions}
            label={t('showroom.workingHours')}
            fullWidth
          />
        </div>

        <div className={cls.add_address}>
          <Button
            onClick={onSubmitClick}
            loading={loading}
            disabled={loading || !isValid}
            color="green"
            size="l"
            fullWidth
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </AsideModal>
  );
}
