import { useState } from 'react';

import { SECONDS_IN_DAY } from '@/utils/consts';
import { getCookie, setCookie } from '@/utils/cookie';

const COOKIE_AGE_DAYS = 30;

const getFromCookie = (name: string): boolean => {
  const fromCookie = getCookie(name);
  return fromCookie ? (JSON.parse(fromCookie) as boolean) : false;
};

export function useCookieBool(
  name: string,
  ageDays?: number
): [boolean, (v: boolean) => void, () => boolean] {
  const [value, setValue] = useState(getFromCookie(name));

  const toggleValue = (v: boolean) => {
    const days = ageDays || COOKIE_AGE_DAYS;

    setCookie(name, JSON.stringify(v), {
      'max-age': days * SECONDS_IN_DAY
    });
    setValue(v);
  };

  const updateValue = () => {
    const v = getFromCookie(name);
    setValue(v);
    return v;
  };

  return [value, toggleValue, updateValue];
}
