import { ReviewCard } from '@/modules/reviews/ReviewsList/ReviewCard/ReviewCard';
import { MyReview } from '@/modules/reviews/types';

import cls from './ReviewsList.module.scss';

type Props = {
  list: MyReview[];
};

export function ReviewsList({ list }: Props) {
  return (
    <ul className={cls.list}>
      {list.map((r) => (
        <li key={r.id}>
          <ReviewCard review={r} />
        </li>
      ))}
    </ul>
  );
}
