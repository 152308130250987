import Lottie from 'lottie-react';
import { useState } from 'react';

type Props = {
  animationData: unknown;
  loop?: boolean;
};

export function LottieNoLoop({ animationData, loop = false }: Props) {
  const [key, setKey] = useState(0);

  return (
    <Lottie
      key={key}
      onClick={() => setKey((p) => p + 1)}
      animationData={animationData}
      loop={loop}
      autoplay
    />
  );
}
