import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import {
  PromotionPackage,
  PromotionPackageOption
} from '@/modules/promotion/types';
import { InfoIcon } from '@/modules/showroom/advert/my/UnpublishAdvert/PromoList/icons';
import { PackageOptions } from '@/modules/showroom/tariff/TariffsPage/AdvertsCalc/Package/Options';
import { Button } from '@/ui/Button/Button';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces, pluralKey } from '@/utils/format';

import cls from './PromoList.module.scss';

type Props = {
  promo: PromotionPackage;
  disabled?: boolean;
  onPromotionClick: (promoPackage: PromotionPackage) => Promise<void>;
};

export const PromoRow: React.FC<Props> = ({
  promo,
  disabled,
  onPromotionClick
}) => {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [isOptionsOpen, setOptionsOpen] = useState(false);

  return (
    <div className={cls.promo}>
      <div className={cls.left}>
        <img src={promo.logo_url || ''} />
        <div className={cls.title}>
          <div className={cls.title_wrap}>
            <h2>{promo.name}</h2>
            {!!promo.options && promo.options.length > 0 && (
              <div
                className={cls.options_wrap}
                onMouseLeave={() => setOptionsOpen(false)}
              >
                <span onMouseEnter={() => setOptionsOpen(true)}>
                  <InfoIcon />
                </span>
                <PackageOptions
                  options={promo.options as PromotionPackageOption[]}
                  isOpen={isOptionsOpen}
                  close={() => setOptionsOpen(false)}
                />
              </div>
            )}
          </div>
          {promo.valid_days && (
            <p>
              {pluralKey(
                promo.valid_days,
                t('advertisements.boost.daysPlural.one', {
                  days: promo.valid_days
                }),
                t('advertisements.boost.daysPlural.few', {
                  days: promo.valid_days
                }),
                t('advertisements.boost.daysPlural.many', {
                  days: promo.valid_days
                })
              )}
            </p>
          )}
        </div>
      </div>

      <div className={cls.right}>
        <div className={cls.price}>
          <h2>
            {t('priceCurrency', {
              price: numberWithSpaces(promo.price, lang),
              currency: ADV_CUR
            })}
          </h2>

          {promo.old_price && (
            <p>
              {t('priceCurrency', {
                price: numberWithSpaces(promo.old_price, lang),
                currency: ADV_CUR
              })}
            </p>
          )}
        </div>

        <Button
          onClick={() => onPromotionClick(promo)}
          disabled={disabled}
          size="s"
          color="black"
          variant="secondary"
        >
          {t('unpublish.promo.connect')}
        </Button>
      </div>
    </div>
  );
};
