import cls from './Cover.module.scss';
import { BackIcon, CloseIcon } from './icons';

type Props = {
  title: string;
  text: string;
  onBackClick: () => void;
  onCloseClick: () => void;
  hideBack?: boolean;
};

export function CropperHeader({
  title,
  text,
  onBackClick,
  onCloseClick,
  hideBack
}: Props) {
  return (
    <header className={cls.cropper_header}>
      <div className={cls.cropper_header_row}>
        {!hideBack && (
          <button
            className={cls.cropper_header_btn}
            type="button"
            onClick={onBackClick}
          >
            <BackIcon />
          </button>
        )}
        <p className={cls.cropper_header_title}>{title}</p>
        <button
          className={cls.cropper_header_btn}
          type="button"
          onClick={onCloseClick}
        >
          <CloseIcon />
        </button>
      </div>
      <p>{text}</p>
    </header>
  );
}
