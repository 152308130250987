/* eslint-disable @typescript-eslint/no-explicit-any */
import { Marker, Map } from 'react-mapkit';

type Props = {
  latitude: number;
  longitude: number;
  mapProps: {
    mapkit: any;
    map: any;
    mapRef: React.RefObject<HTMLDivElement>;
  };
};

export function AddressMap({ latitude, longitude, mapProps }: Props) {
  return (
    <Map {...mapProps}>
      <Marker
        key={latitude + longitude}
        latitude={latitude}
        longitude={longitude}
      />
    </Map>
  );
}
