export function AddIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33331 9.9999C3.33331 10.4892 3.73735 10.8881 4.22152 10.8881H9.11319V15.7798C9.11319 16.2625 9.51069 16.6666 9.99997 16.6666C10.4892 16.6666 10.8947 16.2625 10.8947 15.7798V10.8881H15.7798C16.2626 10.8881 16.6666 10.4892 16.6666 9.9999C16.6666 9.51063 16.2626 9.10516 15.7798 9.10516H10.8947V4.22146C10.8947 3.73729 10.4892 3.33325 9.99997 3.33325C9.51069 3.33325 9.11319 3.73729 9.11319 4.22146V9.10516H4.22152C3.73735 9.10516 3.33331 9.51063 3.33331 9.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
