export function RemoveIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.82706 16.1722C4.17407 16.5112 4.75933 16.5069 5.08973 16.1765L10.0015 11.2647L14.9104 16.175C15.2466 16.5112 15.8312 16.5163 16.1717 16.1707C16.5121 15.8237 16.5136 15.2522 16.1774 14.9146L11.2685 9.99772L16.1774 5.08881C16.5136 4.75266 16.5187 4.17458 16.1717 3.83413C15.8246 3.48712 15.2466 3.48568 14.9104 3.82838L10.0015 8.73729L5.08973 3.82694C4.75933 3.49143 4.16752 3.47914 3.82706 3.83269C3.48804 4.1797 3.49235 4.75697 3.82275 5.08737L8.73453 9.99772L3.82275 14.9175C3.49235 15.2464 3.48149 15.8317 3.82706 16.1722Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.69071 17.5C9.12826 17.5 9.47342 17.3174 9.71323 16.9515L17.6225 4.62159C17.7997 4.34425 17.8708 4.11218 17.8708 3.88381C17.8708 3.30439 17.4633 2.90454 16.8779 2.90454C16.4625 2.90454 16.2214 3.04422 15.9694 3.4397L8.65705 15.0568L4.88887 10.2117C4.64367 9.88692 4.38636 9.75092 4.02286 9.75092C3.42207 9.75092 3 10.1715 3 10.7524C3 11.002 3.09323 11.2541 3.30309 11.509L7.66264 16.9635C7.95294 17.3265 8.26679 17.5 8.69071 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
