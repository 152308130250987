import { useCallback, useEffect } from 'react';

import { VrpBaseInfo } from '@/modules/showroom/advert/vrp/types';

import { getDrawSettings } from './draw-settings';
import { loadFonts, loadImg, getNumberImage, getCodeImage } from './helpers';
import cls from './VrpNumber.module.scss';

type Props = {
  preview: string;
  setPreview: (v: string) => void;
  emirateId: number;
  type: VrpBaseInfo;
  number: string;
  code?: string;
};

export function VrpPlate({
  preview,
  setPreview,
  emirateId,
  type,
  number,
  code
}: Props) {
  const settings = getDrawSettings(emirateId, type.id);

  // Init draw
  const init = useCallback(
    async (src: string, number: string) => {
      if (!settings) return;
      await loadFonts();

      const canvas = document.createElement('canvas');
      canvas.width = settings.plate.w;
      canvas.height = settings.plate.h;
      canvas.style.fontFamily = 'Oswald';
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Plate image
      const { plate } = settings;
      const plateImg = await loadImg(src);
      ctx.drawImage(plateImg, 0, 0, plate.w, plate.h);

      // Number
      const { number: numberCfg } = settings;
      if (number) {
        const numberImgSrc = getNumberImage(number, numberCfg);
        if (!numberImgSrc) return;
        const numImg = await loadImg(numberImgSrc);
        ctx.drawImage(
          numImg,
          numberCfg.x,
          numberCfg.y,
          numberCfg.w,
          numberCfg.h
        );
      }

      // Code
      const { code: codeCfg } = settings;
      if (codeCfg && code && code.length <= 2) {
        const codeImgSrc = getCodeImage(code, codeCfg);
        if (!codeImgSrc) return;
        const codeImg = await loadImg(codeImgSrc);
        ctx.drawImage(codeImg, codeCfg.x, codeCfg.y, codeCfg.w, codeCfg.h);
      }

      setPreview(canvas.toDataURL('image/png'));
    },
    [code, setPreview, settings]
  );

  useEffect(() => {
    if (type.image_url) {
      init(type.image_url, number);
    }
  }, [init, type.image_url, number]);

  return (
    <div className={cls.plate}>{preview && <img src={preview} alt="" />}</div>
  );
}
