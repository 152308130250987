export function UncheckIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6579 22C18.1192 22 22.6641 17.4647 22.6641 12C22.6641 6.5353 18.1068 2 12.6455 2C7.19656 2 2.66406 6.5353 2.66406 12C2.66406 17.4647 7.20895 22 12.6579 22ZM12.6579 20.1164C8.1625 20.1164 4.57118 16.5105 4.57118 12C4.57118 7.48946 8.15013 3.88352 12.6455 3.88352C17.1532 3.88352 20.7693 7.48946 20.7693 12C20.7693 16.5105 17.1656 20.1164 12.6579 20.1164Z"
        fill="#CCD1D7"
      />
    </svg>
  );
}

type CheckIconProps = {
  color?: string;
};

export function CheckIcon(props: CheckIconProps) {
  const { color = 'var(--thm-black)' } = props;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3272 21.9876C17.7885 21.9876 22.3334 17.4551 22.3334 11.9938C22.3334 6.5325 17.7761 2 12.3148 2C6.86588 2 2.33337 6.5325 2.33337 11.9938C2.33337 17.4551 6.87826 21.9876 12.3272 21.9876Z"
        fill={color}
      />
      <path
        d="M11.2374 16.7617C10.8535 16.7617 10.5439 16.613 10.2467 16.2167L7.94329 13.3313C7.76991 13.1207 7.68323 12.8731 7.68323 12.6254C7.68323 12.1177 8.05474 11.709 8.57487 11.709C8.88447 11.709 9.13214 11.8081 9.40459 12.1796L11.1879 14.5573L15.1383 8.16722C15.3365 7.82047 15.6213 7.64709 15.9186 7.64709C16.4139 7.64709 16.8597 7.96908 16.8597 8.50158C16.8597 8.74926 16.7235 9.00932 16.5749 9.23223L12.1662 16.2167C11.9309 16.5883 11.6089 16.7617 11.2374 16.7617Z"
        fill="var(--thm-white)"
      />
    </svg>
  );
}

export function OptionIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.52274 17.4997C8.96029 17.4997 9.30545 17.3171 9.54526 16.9513L17.4546 4.62134C17.6318 4.344 17.7028 4.11193 17.7028 3.88357C17.7028 3.30415 17.2954 2.9043 16.7099 2.9043C16.2946 2.9043 16.0534 3.04398 15.8014 3.43946L8.48908 15.0565L4.7209 10.2114C4.4757 9.88667 4.21839 9.75067 3.85489 9.75067C3.2541 9.75067 2.83203 10.1712 2.83203 10.7521C2.83203 11.0017 2.92526 11.2538 3.13512 11.5088L7.49467 16.9632C7.78497 17.3263 8.09882 17.4997 8.52274 17.4997Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49002 16.1721C4.83703 16.5111 5.42229 16.5067 5.75269 16.1763L10.6645 11.2646L15.5734 16.1749C15.9096 16.5111 16.4941 16.5162 16.8346 16.1706C17.1751 15.8236 17.1765 15.2521 16.8403 14.9145L11.9314 9.9976L16.8403 5.08869C17.1765 4.75254 17.1816 4.17446 16.8346 3.834C16.4876 3.487 15.9096 3.48556 15.5734 3.82826L10.6645 8.73717L5.75269 3.82682C5.42229 3.49131 4.83048 3.47901 4.49002 3.83257C4.151 4.17957 4.15531 4.75685 4.48571 5.08725L9.3975 9.9976L4.48571 14.9174C4.15531 15.2463 4.14446 15.8316 4.49002 16.1721Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowIcon() {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70711 0.707106L13.2929 6.29289C13.9229 6.92286 13.4767 8 12.5858 8L1.41421 8C0.523309 8 0.0771399 6.92286 0.707105 6.29289L6.29289 0.707107C6.68342 0.316583 7.31658 0.316582 7.70711 0.707106Z"
        fill="var(--thm-grey-1)"
      />
    </svg>
  );
}
