import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { DepositHistoryItem } from '@/modules/wallet/DepositHistory/DepositHistoryItem';
import { mapDepositHistoryData } from '@/modules/wallet/DepositHistory/helpers';
import { useDepositHistory } from '@/modules/wallet/hooks';
import { DepositHistoryReq } from '@/modules/wallet/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './DepositHistory.module.scss';

type Props = {
  from: Date;
  to: Date;
};

export const DepositHistory: React.FC<Props> = ({ from, to }) => {
  const { t } = useTranslation();
  const [lang] = useLang();
  const fromPeriodUnix = useMemo(
    () => Math.round(from.getTime() / 1000),
    [from]
  );
  const toPeriodUnix = useMemo(() => Math.round(to.getTime() / 1000), [to]);

  const req = useMemo<DepositHistoryReq>(
    () => ({ period_start: fromPeriodUnix, period_end: toPeriodUnix }),
    [fromPeriodUnix, toPeriodUnix]
  );
  const [data, loading] = useDepositHistory(req);
  const mappedData = useMemo(
    () => (data ? mapDepositHistoryData(data.deposits) : []),
    [data]
  );

  return (
    <div className={cls.root}>
      {!loading && data?.deposits && data?.deposits.length > 0 && (
        <ul className={cls.list}>
          {mappedData.map((group, i) => {
            const dateStr = group.day.toLocaleDateString(lang, {
              day: '2-digit',
              month: 'long'
            });

            return (
              <Fragment key={i}>
                <li key={i} className={cls.date_group}>
                  <b>{dateStr}</b>
                  <span>{t('common.dot')}</span>
                  <b>
                    {t('priceCurrency', {
                      price: numberWithSpaces(group.total, lang),
                      currency: CURRENCY_DISPLAY.aed
                    })}
                  </b>
                </li>

                {group.deposits.map((deposit, k) => (
                  <li key={k} className={cls.deposit_item}>
                    <DepositHistoryItem deposit={deposit} />
                  </li>
                ))}
              </Fragment>
            );
          })}
        </ul>
      )}

      {!loading && (!data || data?.deposits.length === 0) && (
        <p className={cls.no_data}>{t('wallet.emptyData')}</p>
      )}

      {loading && (
        <div className={cls.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
