import cn from 'classnames';

import { OptionI } from '@/app/types';
import {
  ComplectationCategory,
  ComplectationOptionType
} from '@/modules/showroom/advert/complectation/types';
import { Checkbox } from '@/ui/Checkbox/Checkbox';

import cls from './OptionsModal.module.scss';
import { OptionsSelect } from './OptionsSelect';

type Props = {
  isLast: boolean;
  selectedOptions: number[];
  category: ComplectationCategory;
  onSelectOptionAdd: (id: number, idsToRemove?: number[]) => void;
  onSelectOptionRemove: (id: number) => void;
  onSelectClear: (ids: number[]) => void;
  toggleOption: (id: number) => void;
};

export const OptionsCategory: React.FC<Props> = ({
  selectedOptions,
  category,
  onSelectOptionAdd,
  onSelectOptionRemove,
  onSelectClear,
  toggleOption,
  isLast
}) => {
  return (
    <>
      <div className={cls.category}>
        <h2 className={cls.subtitle}>{category.name}</h2>
        <ul className={cls.options}>
          {category.options.map((opt) => {
            const isCheckbox =
              opt.select_type === ComplectationOptionType.checkbox;

            const isMulti = opt.select_type === ComplectationOptionType.multi;

            const selectOptions: OptionI[] = opt.items.map((v) => ({
              id: String(v.id),
              text: v.name
            }));

            return (
              <li
                key={opt.id}
                className={cn({
                  [cls.checkboxes_li]: isCheckbox
                })}
              >
                {isCheckbox ? (
                  <ul className={cls.checkboxes}>
                    {opt.items.map((v) => (
                      <li key={v.id}>
                        <div className={cls.checkbox}>
                          <Checkbox
                            id={String(v.id)}
                            checked={selectedOptions.includes(v.id)}
                            onChange={() => toggleOption(v.id)}
                            small
                          >
                            {v.name}
                          </Checkbox>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <OptionsSelect
                    initialIds={selectedOptions}
                    options={selectOptions}
                    onOptionAdd={onSelectOptionAdd}
                    onOptionRemove={onSelectOptionRemove}
                    onClear={onSelectClear}
                    name={opt.name}
                    multiple={isMulti}
                    dropdownTop={isLast}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>

      {!isLast && <div className={cls.hr} />}
    </>
  );
};
