import { isWindows } from '@/utils/detect';

export function loadNotoFont() {
  if (!isWindows()) return Promise.resolve();

  return new Promise<void>((resolve, reject) => {
    const notoFont = new FontFace(
      'Noto Color Emoji',
      'url(/fonts/noto/NotoColorEmoji-Regular.woff2)'
    );
    const promises = [notoFont.load()];
    Promise.all(promises)
      .then((fonts) => {
        fonts.forEach((font) => {
          document.fonts.add(font);
        });
        resolve();
      })
      .catch(reject);
  });
}
