import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon, QuickReplyIcon } from '@/modules/chat/QuickReplies/icons';
import { QuickReply } from '@/modules/chat/QuickReplies/QuickReply';
import { useQuickRepliesStore } from '@/modules/chat/QuickReplies/store';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './QuickReplies.module.scss';

export function QuickReplies() {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const replies = useQuickRepliesStore((s) => s.quickRepliesList);
  const repliesLoading = useQuickRepliesStore((s) => s.loading);
  const fetchQuickReplies = useQuickRepliesStore((s) => s.fetchQuickReplies);
  const isOpen = useQuickRepliesStore((s) => s.isOpen);
  const setOpen = useQuickRepliesStore((s) => s.setOpen);

  useEffect(() => {
    if (isOpen) {
      setHidden(!isOpen);
      setTimeout(() => setShowAnimation(isOpen), 50);
    }

    if (!isOpen) {
      setShowAnimation(isOpen);
      setTimeout(() => setHidden(!isOpen), 200);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchQuickReplies();
    }
  }, [fetchQuickReplies, isOpen]);

  const hasReplies = replies && replies.length > 0;

  return (
    <div
      className={cn(cls.wrapper, {
        [cls.hidden]: hidden,
        [cls.open]: showAnimation
      })}
    >
      <button className={cls.close_btn} onClick={() => setOpen(false)} />
      <div className={cn(cls.root, { [cls.root_open]: showAnimation })}>
        <header className={cls.header}>
          <h2>{t('chat.quickReplies.title')}</h2>
          <button onClick={() => setOpen(false)}>
            <CloseIcon />
          </button>
        </header>

        <div className={cn(cls.content, 'hide-scrollbar')}>
          {!repliesLoading && hasReplies && (
            <div className={cls.messages}>
              {replies.map((reply) => (
                <QuickReply key={reply.id} reply={reply} />
              ))}
            </div>
          )}

          {!repliesLoading && !hasReplies && (
            <div className={cls.placeholder}>
              <QuickReplyIcon />
              <p>{t('chat.quickReplies.placeholder')}</p>
            </div>
          )}

          {repliesLoading && <Spinner color="var(--clr-primary)" />}
        </div>
      </div>
    </div>
  );
}
