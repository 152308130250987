import { useState } from 'react';

import { Nullable } from '@/app/types';
import { createAdvertisement } from '@/modules/showroom/advert/create/api';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import {
  createRentAdvertisement,
  updateRentAdvertisement
} from '@/modules/showroom/advert/rent/api';
import { RentAdvertAuthorDetail } from '@/modules/showroom/advert/rent/detail/types';
import { RentAdvertStatusId } from '@/modules/showroom/advert/rent/my/types';
import {
  CARS_CATEGORY_ID,
  RENT_CARS_CATEGORY_ID
} from '@/modules/showroom/advert/search/consts';
import { updateAdvertisement } from '@/modules/showroom/advert/update/api';
import { openInNewTab } from '@/utils/common';
import { showAlert } from '@/utils/network';

export function useCreateAdvertCopy(
  advert: MyAdvert | Nullable<AdvertAuthorDetail>
): [() => Promise<void>, boolean] {
  const [carCreating, setCarCreating] = useState(false);
  const onCreateAdvertCopy = async () => {
    if (!advert || !advert.type) return;
    setCarCreating(true);

    try {
      const { data } = await createAdvertisement({
        category_id: CARS_CATEGORY_ID,
        type_id: advert.type.id,
        emirate_id: null,
        vrp_type_id: null,
        vrp_plate_number: null,
        vrp_image_url: null,
        vrp_plate_code_id: null
      });

      const complectationOptionsItemsIds =
        'complectation' in advert && advert.complectation
          ? advert.complectation.complectation_options.reduce<number[]>(
              (acc, cat) => {
                const itemsIds = cat.options.reduce<number[]>((acc2, opt) => {
                  return [...acc2, ...opt.items.map((item) => item.id)];
                }, []);

                return [...acc, ...itemsIds];
              },
              []
            )
          : undefined;

      await updateAdvertisement(data.id, {
        is_published: false,
        type_id: advert.type.id,
        brand_id: advert.brand?.id,
        model_id: advert.model?.id,
        generation_id: advert.generation?.id,
        year_of_issue_id: advert.year_of_issue?.id,
        modification_id: advert.modification?.id,
        body_id: advert.body?.id,
        engine_id: advert.modification?.engine?.id,
        drive_unit_id: advert.modification?.drive_unit?.id,
        transmission_id: advert.modification?.transmission?.id,
        colour_id: advert.colour?.id,
        regional_spec_id:
          'regional_spec' in advert ? advert.regional_spec?.id : undefined,
        owner_id: 'owner' in advert ? advert.owner?.id : undefined,
        contacts:
          'communication_method' in advert
            ? advert.communication_method
                ?.filter((c) => !!c.showroom_contact)
                ?.map((c) => ({
                  id: c.showroom_contact?.id as number,
                  communication_method: {
                    phone: {
                      available: !!c.available_phone,
                      call_after: c.call_after,
                      call_before: c.call_before
                    },
                    whatsapp: {
                      available: !!c.available_whatsapp
                    }
                  }
                }))
            : undefined,
        address_id: 'address_id' in advert ? advert.address_id : undefined,
        complectation_id:
          'complectation' in advert ? advert.complectation?.id : undefined,
        options_items_list_ids: complectationOptionsItemsIds,
        stickers_ids:
          'stickers' in advert ? advert.stickers?.map((s) => s.id) : undefined
      });

      openInNewTab(`/cars/edit/${data.id}`);
    } catch (error) {
      showAlert({ error });
    } finally {
      setCarCreating(false);
    }
  };

  return [onCreateAdvertCopy, carCreating];
}

export function useCreateRentAdvertCopy(
  advert: Nullable<RentAdvertAuthorDetail>
): [() => Promise<void>, boolean] {
  const [carCreating, setCarCreating] = useState(false);
  const onCreateAdvertCopy = async () => {
    if (!advert) return;
    setCarCreating(true);

    try {
      const { data } = await createRentAdvertisement(RENT_CARS_CATEGORY_ID);

      const complectationOptionsItemsIds =
        'complectation' in advert && advert.complectation
          ? advert.complectation.complectation_options.reduce<number[]>(
              (acc, cat) => {
                const itemsIds = cat.options.reduce<number[]>((acc2, opt) => {
                  return [...acc2, ...opt.items.map((item) => item.id)];
                }, []);

                return [...acc, ...itemsIds];
              },
              []
            )
          : undefined;

      await updateRentAdvertisement(data.id, {
        status_id: RentAdvertStatusId.draft,
        brand_id: advert.brand?.id,
        model_id: advert.model?.id,
        generation_id: advert.generation?.id,
        year_of_issue_id: advert.year_of_issue?.id,
        modification_id: advert.modification?.id,
        body_id: advert.body?.id,
        engine_id: advert.modification?.engine?.id,
        drive_unit_id: advert.modification?.drive_unit?.id,
        transmission_id: advert.modification?.transmission?.id,
        body_colour_id: advert.body_colour?.id,
        interior_colour_id: advert.interior_colour?.id,
        contacts:
          'communication_method' in advert
            ? advert.communication_method
                ?.filter((c) => !!c.showroom_contact)
                ?.map((c) => ({
                  id: c.showroom_contact?.id as number,
                  communication_method: {
                    phone: {
                      available: !!c.available_phone,
                      call_after: c.call_after,
                      call_before: c.call_before
                    },
                    whatsapp: {
                      available: !!c.available_whatsapp
                    }
                  }
                }))
            : undefined,
        showroom_address_id:
          'address_id' in advert ? advert.address?.id : undefined,
        complectation_id:
          'complectation' in advert ? advert.complectation?.id : undefined,
        options_items_list_ids: complectationOptionsItemsIds,
        tariff_ids: advert.tariffs?.map((tf) => tf.id) || null
      });

      openInNewTab(`/cars/rent/edit/${data.id}`);
    } catch (error) {
      showAlert({ error });
    } finally {
      setCarCreating(false);
    }
  };

  return [onCreateAdvertCopy, carCreating];
}
