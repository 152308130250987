import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  Appearance,
  Layout,
  loadStripe,
  StripeElementsOptions,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useAccountStore } from '@/modules/accounts/store';
import { PKEY } from '@/modules/payment/consts';
import { useDarkmode } from '@/modules/theme/useTheme';
import { Button } from '@/ui/Button/Button';
import { BUSINESS_APP_URL } from '@/utils/consts';
import { showAlert } from '@/utils/network';

const stripePromise = loadStripe(PKEY as string);

type LocaleType = 'ru' | 'en';
type ResultType = 'success' | 'error' | null;

type FormProps = {
  showResult?: boolean;
  onConfirm?: () => void;
};

function TopUpForm({ showResult, onConfirm }: FormProps) {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();
  const accountInfo = useAccountStore((s) => s.accountInfo);

  const [, setEmail] = useState('');
  const [resultText, setResultText] = useState('');
  const [resultType, setResultType] = useState<ResultType>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const paymentElementOptions = useMemo<StripePaymentElementOptions>(() => {
    const layout = 'tabs' as Layout;
    if (accountInfo?.email) {
      return {
        layout,
        defaultValues: {
          billingDetails: {
            email: accountInfo?.email
          }
        }
      };
    }

    return { layout };
  }, [accountInfo]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${BUSINESS_APP_URL}/payment/topup`,
        receipt_email: accountInfo?.email || undefined
      }
    });
    if (onConfirm) onConfirm();

    if (error.type === 'card_error' || error.type === 'validation_error') {
      showAlert({ type: 'error', text: error.message || '' });
    } else {
      showAlert({ type: 'error', text: t('payment.error') });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );
    if (!clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent)
        switch (paymentIntent.status) {
          case 'succeeded':
            setResultType('success');
            setResultText(t('payment.success') as string);
            break;
          case 'processing':
            setResultText(t('payment.processing') as string);
            break;
          case 'requires_payment_method':
            setResultText(t('payment.fail') as string);
            setResultType('error');
            break;
          default:
            setResultText(t('payment.wentWrong') as string);
            setResultType('error');
            break;
        }
    });
  }, [stripe, t]);

  const onCloseClick = () => {
    window.location.href = '/payment/wallet';
  };

  if (showResult && resultText) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: 24, lineHeight: '32px', marginBottom: 12 }}>
          {resultText}
        </h1>
        {resultType && (
          <p style={{ fontSize: 16, lineHeight: '21px', marginBottom: 28 }}>
            {resultType === 'success'
              ? t('payment.resultSuccess')
              : t('payment.resultError')}
          </p>
        )}
        <Button onClick={onCloseClick} fullWidth color="green">
          {t('common.close')}
        </Button>
      </div>
    );
  }

  return (
    <form id="topup-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.value.email)}
      />

      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <div style={{ marginTop: 12 }}>
        <Button
          loading={isLoading}
          disabled={isLoading || !stripe || !elements}
          type="submit"
          color="green"
          fullWidth
        >
          {t('payment.do')}
        </Button>
      </div>
    </form>
  );
}

type Props = {
  clientSecret: string;
  showResult?: boolean;
  onConfirm?: () => void;
};

export function PaymentTopUp({ clientSecret, showResult, onConfirm }: Props) {
  const [locale] = useLang();
  const { isDark } = useDarkmode();

  const appearance = useMemo<Appearance>(() => {
    return {
      theme: isDark ? 'night' : 'stripe'
    };
  }, [isDark]);

  const options = useMemo<StripeElementsOptions>(() => {
    return { locale: locale as LocaleType, appearance, clientSecret };
  }, [appearance, clientSecret, locale]);

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <TopUpForm showResult={showResult} onConfirm={onConfirm} />
        </Elements>
      )}
    </>
  );
}
