export function DownloadIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 19C4.44772 19 4 19.4477 4 20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20C20 19.4477 19.5523 19 19 19H5ZM12 2C11.4477 2 11 2.44772 11 3V13.585L6.70711 9.29289C6.34662 8.93241 5.77939 8.90468 5.3871 9.2097L5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L11.2929 16.7071C11.3214 16.7356 11.3515 16.7623 11.3833 16.7872C11.3902 16.7927 11.3974 16.7982 11.4047 16.8037C11.4289 16.8215 11.4537 16.8383 11.4793 16.8539C11.4914 16.8614 11.5036 16.8685 11.5159 16.8753C11.5357 16.8862 11.5561 16.8966 11.5769 16.9063C11.5939 16.9143 11.6112 16.9218 11.6287 16.9288C11.6484 16.9367 11.6682 16.9439 11.6883 16.9505C11.7031 16.9553 11.7185 16.9599 11.734 16.9642C11.7556 16.9702 11.7773 16.9754 11.7992 16.9798C11.8166 16.9833 11.8341 16.9864 11.8515 16.989C11.8719 16.9921 11.8929 16.9946 11.9142 16.9964C11.9321 16.9979 11.9494 16.9989 11.9668 16.9995C11.9776 16.9998 11.9888 17 12 17L12.0323 16.9995C12.0503 16.9989 12.0683 16.9978 12.0862 16.9963L12 17C12.0506 17 12.1004 16.9962 12.149 16.989C12.1659 16.9864 12.1834 16.9833 12.2007 16.9798C12.2227 16.9754 12.2444 16.9702 12.2658 16.9643C12.2815 16.9599 12.2969 16.9553 12.3121 16.9503C12.3318 16.9439 12.3516 16.9367 12.3711 16.9289C12.3888 16.9218 12.4061 16.9143 12.4232 16.9063C12.4439 16.8966 12.4643 16.8862 12.4843 16.8751C12.4964 16.8685 12.5086 16.8614 12.5207 16.854C12.5463 16.8383 12.5711 16.8215 12.5952 16.8037C12.6343 16.7747 12.6717 16.7425 12.7071 16.7071L12.6167 16.7872C12.6485 16.7623 12.6786 16.7356 12.7071 16.7071L18.7071 10.7071C19.0976 10.3166 19.0976 9.68342 18.7071 9.29289C18.3166 8.90237 17.6834 8.90237 17.2929 9.29289L13 13.585V3C13 2.44772 12.5523 2 12 2Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.69989 5.69998C6.08649 5.31338 6.71329 5.31338 7.09989 5.69998L11.9999 10.6L16.8999 5.69998C17.2865 5.31338 17.9133 5.31338 18.2999 5.69998C18.6865 6.08658 18.6865 6.71338 18.2999 7.09998L13.3999 12L18.2999 16.9C18.6865 17.2866 18.6865 17.9134 18.2999 18.3C17.9133 18.6866 17.2865 18.6866 16.8999 18.3L11.9999 13.4L7.09989 18.3C6.71329 18.6866 6.08649 18.6866 5.69989 18.3C5.3133 17.9134 5.3133 17.2866 5.69989 16.9L10.5999 12L5.69989 7.09998C5.3133 6.71338 5.3133 6.08658 5.69989 5.69998Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronIcon(): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.48799 8L5.41058 3.92259C5.08514 3.59715 5.08514 3.06951 5.41058 2.74408C5.73602 2.41864 6.26366 2.41864 6.58909 2.74408L11.2558 7.41074C11.5812 7.73618 11.5812 8.26382 11.2558 8.58926L6.58909 13.2559C6.26366 13.5814 5.73602 13.5814 5.41058 13.2559C5.08514 12.9305 5.08514 12.4028 5.41058 12.0774L9.48799 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
