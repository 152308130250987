import { ChatsWrap } from '@/modules/chat/ChatsSearch/ChatsWrap';
import { MessagesWrap } from '@/modules/chat/ChatsSearch/MessagesWrap';
import { ChatSimple, ChatWithFoundedMessage } from '@/modules/chat/types';

import cls from './ChatsSearch.module.scss';

type Props = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  onChatClick: (c: ChatSimple) => void;
  onChatMessageClick: (c: ChatWithFoundedMessage) => void;
};

export function ChatsSearch({
  searchText,
  setSearchText,
  onChatClick,
  onChatMessageClick
}: Props) {
  const handleChatClick = (c: ChatSimple) => {
    setSearchText('');
    onChatClick(c);
  };

  const handleChatMessageClick = (c: ChatWithFoundedMessage) => {
    setSearchText('');
    onChatMessageClick(c);
  };

  return (
    <div className={cls.root}>
      {searchText && (
        <ChatsWrap
          key={`chats-${searchText}`}
          searchText={searchText}
          onChatClick={handleChatClick}
        />
      )}

      {searchText && (
        <MessagesWrap
          key={`msgs-${searchText}`}
          searchText={searchText}
          onChatClick={handleChatMessageClick}
        />
      )}
    </div>
  );
}
