import axiosLib, { AxiosInstance } from 'axios';

import { getCookieAuth } from '@/modules/auth/helpers';
import { useAuthStore } from '@/modules/auth/store';
import { AuthType } from '@/modules/auth/types';
import { API_URL, UNAUTH_CODE } from '@/utils/consts';

const HEADERS = {
  Accept: 'application/json',
  'X-Device-Platform': 'Web',
  'X-Device-Model': window.navigator.userAgent
};

// Auth
const [, initialAuth] = getCookieAuth();

class AutoAPIClass {
  public lang = '';

  public axios: AxiosInstance = axiosLib.create({
    baseURL: API_URL,
    headers: initialAuth
      ? {
          Authorization: `Bearer ${initialAuth.access_token}`,
          ...HEADERS
        }
      : HEADERS
  });

  constructor(baseURL: string, auth?: AuthType) {
    this.configure(baseURL, auth);
  }

  public configure(baseURL: string, auth?: AuthType): void {
    this.axios = axiosLib.create({
      baseURL,
      headers: auth
        ? {
            Authorization: `Bearer ${auth.access_token}`,
            ...HEADERS
          }
        : HEADERS
    });

    this.axios.interceptors.response.use(undefined, (error) => {
      // Handle 401
      if (
        error &&
        error.isAxiosError &&
        error.response?.status === UNAUTH_CODE
      ) {
        const authStore = useAuthStore.getState();
        authStore.signout();
      }

      return Promise.reject(error);
    });
  }

  public updateAuth(auth?: AuthType): void {
    if (auth) {
      this.axios.defaults.headers.Authorization = `Bearer ${auth.access_token}`;
    } else {
      this.axios.defaults.headers.Authorization = '';
    }
  }

  public setLang(v: string): void {
    this.lang = v;
    this.axios.defaults.headers.lang = v;
  }
}

// API Object
export const AutoAPI = new AutoAPIClass(API_URL, initialAuth);
export const { axios } = AutoAPI;
