import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { PromotionPackage } from '@/modules/promotion/types';
import { Package } from '@/modules/showroom/tariff/TariffsPage/AdvertsCalc/Package/Package';
import {
  PackagesCountType,
  TARIFFS_MAX_DAYS_LEN
} from '@/modules/showroom/tariff/TariffsPage/helpers';
import { TARIFFS_VALUE_MAX_LENGTH } from '@/modules/showroom/tariff/TariffsPage/TariffsPage';
import { Input } from '@/ui/Input/Input';
import { ADV_CUR } from '@/utils/consts';
import {
  maxLength,
  numberWithCommas,
  numberWithSpaces,
  onlyNumbers
} from '@/utils/format';

import cls from './AdvertsCalc.module.scss';

type Props = {
  packages: PromotionPackage[];
  packagesCount: PackagesCountType;
  onPackagesCountChange: (packageId: string, count: number) => void;
  title: string;
  firstDayPrice: number;
  dayPrice: number;
  daysCount: number;
  setDaysCount: (v: number) => void;
  advertsCount: string;
  setAdvertsCount: (v: number) => void;
};

export function AdvertsCalc({
  packages,
  packagesCount,
  onPackagesCountChange,
  title,
  firstDayPrice,
  dayPrice,
  daysCount,
  setDaysCount,
  advertsCount,
  setAdvertsCount
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [isDaysFocused, setDaysFocused] = useState(false);
  const onDaysCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    let num = Number(
      onlyNumbers(maxLength(e.currentTarget.value, TARIFFS_MAX_DAYS_LEN))
    );
    if (!num) num = 1;
    setDaysCount(num);
  };

  return (
    <div className="box" style={{ marginBottom: 16 }}>
      <h2 className={cls.title}>{title}</h2>

      <div className={cls.header}>
        <div className={cls.prices_wrap}>
          <div className={cls.price}>
            <b>
              {t('priceCurrency', {
                price: numberWithSpaces(firstDayPrice, lang),
                currency: ADV_CUR
              })}
            </b>
            <small>{t('tariffs.firstDayPrice')}</small>
          </div>

          <div className={cls.price}>
            <b>
              {t('priceCurrency', {
                price: numberWithSpaces(dayPrice, lang),
                currency: ADV_CUR
              })}
            </b>
            <small>{t('tariffs.perDayPrice')}</small>
          </div>
        </div>

        <div className={cls.fields}>
          <div
            className={cls.field}
            style={{ zIndex: isDaysFocused ? 1 : undefined }}
          >
            <Input
              value={numberWithCommas(advertsCount)}
              onChange={(e) =>
                setAdvertsCount(Number(onlyNumbers(e.currentTarget.value)))
              }
              onFocus={() => setDaysFocused(true)}
              inputMode="numeric"
              label={t('tariffs.advertsCount')}
              maxLength={TARIFFS_VALUE_MAX_LENGTH}
            />
          </div>

          <div
            className={cls.field}
            style={{ zIndex: !isDaysFocused ? 1 : undefined }}
          >
            <Input
              value={numberWithCommas(daysCount)}
              onChange={onDaysCountChange}
              onFocus={() => setDaysFocused(false)}
              inputMode="numeric"
              label={t('tariffs.calc.days')}
              maxLength={TARIFFS_VALUE_MAX_LENGTH}
            />
          </div>
        </div>
      </div>

      <h3 className={cls.subtitle}>{t('advertisements.promo.packages')}</h3>

      <ul className={cls.promolist}>
        {packages.map((p) => (
          <li key={p.id}>
            <Package
              promoPackage={p}
              count={packagesCount[String(p.id)]}
              onChange={onPackagesCountChange}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
