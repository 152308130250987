import cn from 'classnames';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { useDefaultPeriod } from '@/hooks/useDefaultPeriod';
import { useMyReviews, useReviewsSortFilters } from '@/modules/reviews/hooks';
import { ReviewsContext } from '@/modules/reviews/reviews-ctx';
import { ReviewsList } from '@/modules/reviews/ReviewsList/ReviewsList';
import { ReviewsSummary } from '@/modules/reviews/ReviewsSummary/ReviewsSummary';
import { GetMyReviewsReq } from '@/modules/reviews/types';
import { CARS_CATEGORY_ID } from '@/modules/showroom/advert/search/consts';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { DatesFilter } from '@/ui/Filters/DatesFilter';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { Placeholder } from '@/ui/Placeholder/Placeholder';
import { Select } from '@/ui/Select/Select';
import { Spinner } from '@/ui/Spinner/Spinner';
import { TabOption, Tabs } from '@/ui/Tabs/Tabs';
import { pluralKey } from '@/utils/format';

import emptyAnim from './empty.json';
import cls from './ReviewsPage.module.scss';

const ALL_VALUE = 'all';

export function ReviewsPage() {
  const { t } = useTranslation();

  // Summary
  const [summaryData] = useMyReviews();

  // Dates
  const defaultPeriod = useDefaultPeriod();
  const [from, setFrom] = useState<Date>(defaultPeriod.from);
  const [to, setTo] = useState<Date>(defaultPeriod.to);
  const onDatesChange = (fromDate: Date, toDate: Date) => {
    setFrom(fromDate);
    setTo(toDate);
  };
  const fromPeriodUnix = useMemo(
    () => Math.round(from.getTime() / 1000),
    [from]
  );
  const toPeriodUnix = useMemo(() => Math.round(to.getTime() / 1000), [to]);

  // Categories
  const categoriesTabs = useMemo(
    () => [
      { id: ALL_VALUE, text: t('common.all') },
      { id: String(CARS_CATEGORY_ID), text: t('carShort') },
      { id: String(VRP_CATEGORY_ID), text: t('numbers') }
    ],
    [t]
  );
  const [category, setCategory] = useState(categoriesTabs[0].id);
  const categoryTab = categoriesTabs.find((opt) => opt.id === category);
  const onCategoryChange = (opt: TabOption) => {
    setCategory(opt.id);
  };

  // Ratings
  const ratings = useMemo(() => {
    const list = new Array(5).fill(0).map((_, i) => {
      const count = i + 1;
      return {
        id: String(count),
        text: pluralKey(
          count,
          t('profile.reviews.starsPlural.one', {
            rating: count
          }),
          t('profile.reviews.starsPlural.few', {
            rating: count
          }),
          t('profile.reviews.starsPlural.many', {
            rating: count
          })
        )
      };
    });
    return [{ id: ALL_VALUE, text: t('profile.reviews.allRatings') }, ...list];
  }, [t]);
  const [rating, setRating] = useState<Nullable<OptionI>>(ratings[0]);

  // Sort
  const [sorts] = useReviewsSortFilters();
  const sortsOptions = useMemo<OptionI[]>(() => {
    if (!sorts) return [];
    return sorts.map((s) => ({ id: String(s.id), text: s.name }));
  }, [sorts]);
  const [sort, setSort] = useState<OptionI>();
  useEffect(() => {
    if (sortsOptions.length > 0 && !sort) {
      setSort(sortsOptions[0]);
    }
  }, [sort, sortsOptions]);

  // Req
  const reviewsReq = useMemo<GetMyReviewsReq>(
    () => ({
      period_start: fromPeriodUnix,
      period_end: toPeriodUnix,
      category_id: category === ALL_VALUE ? undefined : Number(category),
      rating: rating && rating.id !== ALL_VALUE ? Number(rating.id) : null,
      sort_filter_id: sort ? Number(sort.id) : null
    }),
    [category, fromPeriodUnix, toPeriodUnix, rating, sort]
  );

  // Reviews list
  const [data, loading, fetchReviews] = useMyReviews(reviewsReq);
  const noReviews = summaryData ? summaryData.count_reviews <= 0 : false;
  const showEmpty = data ? data.count_reviews <= 0 : false;

  return (
    <ReviewsContext.Provider value={{ fetchReviews }}>
      <LayoutHeader title={t('ratingsAndReviews')} />
      <PageHead title={t('pageTitle', { title: t('ratingsAndReviews') })} />

      {noReviews ? (
        <>
          <div className={cls.placeholder}>
            <Placeholder
              lottieData={emptyAnim}
              title={t('profile.reviews.myEmpty.title')}
            />
          </div>
        </>
      ) : (
        <>
          {loading && !data && (
            <div className={cls.placeholder}>
              <div className={cls.spinner}>
                <Spinner />
              </div>
            </div>
          )}

          {data && (
            <div className={cls.root}>
              <div className={cls.tabs}>
                <Tabs
                  tabs={categoriesTabs}
                  active={categoryTab}
                  onChange={onCategoryChange}
                  variant="underline"
                />
              </div>

              <div className={cls.container}>
                <div className={cls.row}>
                  <div className={cls.list_wrap}>
                    <ul className={cls.filters}>
                      <li className={cn(cls.filter_flex, cls.filter_date)}>
                        <DatesFilter
                          from={from}
                          to={to}
                          onDatesChange={onDatesChange}
                        />
                      </li>
                      <li className={cls.filter_flex}>
                        <Select
                          name="reviews-ratings-filter"
                          options={ratings}
                          value={rating}
                          onChange={setRating}
                          size="s"
                          active
                        />
                      </li>
                      <li className={cls.filter_flex}>
                        <Select
                          name="reviews-sort-filter"
                          options={sortsOptions}
                          value={sort}
                          onChange={setSort}
                          dropdownFullWidth={false}
                          dropdownCls={cls.sort_dropdown}
                          size="s"
                          active
                        />
                      </li>
                    </ul>

                    {loading && (
                      <div className={cls.placeholder}>
                        <div className={cls.spinner}>
                          <Spinner />
                        </div>
                      </div>
                    )}

                    {!loading && (
                      <>
                        {showEmpty ? (
                          <div className={cls.placeholder}>
                            <Placeholder
                              lottieData={emptyAnim}
                              title={t('profile.reviews.emptyByFilters.title')}
                              text={t('profile.reviews.emptyByFilters.text')}
                            />
                          </div>
                        ) : (
                          <ReviewsList list={data.reviews} />
                        )}
                      </>
                    )}
                  </div>

                  {summaryData && (
                    <div className={cls.summary}>
                      <ReviewsSummary data={summaryData} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </ReviewsContext.Provider>
  );
}
