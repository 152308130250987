import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { EngagementByDaySmall, Nullable } from '@/app/types';
import { getEngagementStatsSummary } from '@/modules/overview/api';
import {
  GetStatsEngagementReq,
  GetStatsEngagementRes
} from '@/modules/overview/types';
import { showAlert } from '@/utils/network';

export function useEngagementStats(
  params: GetStatsEngagementReq,
  lang: string
): [GetStatsEngagementRes | undefined, boolean] {
  const abortRef = useRef<Nullable<AbortController>>(
    new window.AbortController()
  );
  const [data, setData] = useState<GetStatsEngagementRes>();
  const [loading, setLoading] = useState(true);

  const load = useCallback(
    async (
      prms: GetStatsEngagementReq,
      lng: string,
      abort: Nullable<AbortController>
    ) => {
      setLoading(true);

      try {
        const r = await getEngagementStatsSummary(
          prms,
          lng,
          abort || undefined
        );
        setData(r.data);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (abortRef.current) abortRef.current.abort();
    const ab = new AbortController();
    abortRef.current = ab;
    load(params, lang, ab);
  }, [lang, load, params]);

  useEffect(() => {
    return () => {
      if (abortRef.current) abortRef.current.abort();
    };
  }, []);

  return [data, loading];
}

export function useMapEngagementDays(
  data: EngagementByDaySmall[],
  datesRange?: [Date, Date]
) {
  const mapped = useMemo(
    () => fillMissingDays(data, datesRange),
    [data, datesRange]
  );
  return mapped;
}

function fillMissingDays(
  data: EngagementByDaySmall[],
  datesRange?: [Date, Date]
) {
  const result: EngagementByDaySmall[] = [];

  const datesRangeFrom = datesRange
    ? new Date(datesRange[0].setHours(0, 0, 0, 0))
    : null;
  const datesRangeTo = datesRange
    ? new Date(datesRange[1].setHours(0, 0, 0, 0))
    : null;

  const startDate =
    datesRangeFrom ||
    new Date(new Date(data[0].date * 1000).setHours(0, 0, 0, 0));
  const endDateObj =
    datesRangeTo ||
    new Date(new Date(data[data.length - 1].date * 1000).setHours(0, 0, 0, 0));

  while (startDate <= endDateObj) {
    const currentTimestamp = startDate.getTime();
    const existingData = data.find((item) => {
      const itemDate = new Date(item.date * 1000).setHours(0, 0, 0, 0);
      return itemDate === currentTimestamp;
    });

    if (existingData) {
      result.push(existingData);
    } else {
      result.push({ date: currentTimestamp / 1000, count: 0 });
    }

    startDate.setDate(startDate.getDate() + 1);
  }

  return result;
}
