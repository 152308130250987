'use client';

import { useNavigate } from 'react-router-dom';

import { useAccountStore } from '@/modules/accounts/store';
import { useAuthStore } from '@/modules/auth/store';
import { LoginAccountRes } from '@/modules/auth/types';

import { Signin } from './Signin';

export function SigninPage() {
  const navigate = useNavigate();
  const setAuth = useAuthStore((s) => s.setAuth);
  const setAccountInfo = useAccountStore((s) => s.setAccountInfo);

  const onSubmit = (response: LoginAccountRes) => {
    setAuth(response);
    setAccountInfo(response);
    navigate(response.showroom_created ? '/' : '/showroom/create');
  };

  return (
    <div className="box">
      <Signin onSubmit={onSubmit} />
    </div>
  );
}
