export function BackIcon() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.78346 13.3333L15.5001 7.61666C15.9512 7.16563 15.9512 6.43436 15.5001 5.98332C15.0491 5.53229 14.3178 5.53229 13.8668 5.98332L6.17508 13.675C5.71947 14.1306 5.71947 14.8693 6.17508 15.3249L13.8668 23.0167C14.3178 23.4677 15.0491 23.4677 15.5001 23.0167C15.9512 22.5656 15.9512 21.8344 15.5001 21.3833L9.78346 15.6667H21.6835C22.3278 15.6667 22.8501 15.1443 22.8501 14.5C22.8501 13.8557 22.3278 13.3333 21.6835 13.3333H9.78346Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66437 8.49434C6.18808 8.01805 6.1654 7.16754 6.67571 6.67991C7.16333 6.1696 8.02519 6.19228 8.50148 6.66857L14.0015 12.1686L19.5015 6.66857C19.9891 6.18094 20.8283 6.1696 21.3159 6.67991C21.8262 7.16754 21.8149 8.01805 21.3273 8.50568L15.8273 14.0057L21.3273 19.4943C21.8149 19.9933 21.8262 20.8325 21.3159 21.3201C20.8283 21.8304 19.9891 21.8191 19.5015 21.3315L14.0015 15.8315L8.50148 21.3315C8.02519 21.8077 7.16333 21.8304 6.67571 21.3201C6.1654 20.8325 6.18808 19.982 6.66437 19.5057L12.1644 14.0057L6.66437 8.49434Z"
        fill="currentColor"
      />
    </svg>
  );
}
