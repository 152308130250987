import cn from 'classnames';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MAX_HIDDEN_SHOWROOM_DESCR_LEN,
  MAX_SHOWROOM_DESCR_LEN,
  MAX_SHOWROOM_NAME_LEN
} from '@/modules/showroom/ShowroomEdit/helpers';
import { ShowroomBox } from '@/modules/showroom/ShowroomEdit/ShowroomBox';
import { Input } from '@/ui/Input/Input';
import { Textarea } from '@/ui/Textarea/Textarea';
import { maxLength, preventExtraSpaces } from '@/utils/format';
import { MAX_SITE_LEN } from '@/utils/validate';

import cls from './BaseInfo.module.scss';

const TEXTAREA_MIN_ROWS = 6;

type Props = {
  fieldsOnly?: boolean;
  isSiteValid: boolean;
  name?: string;
  setName?: (v: string) => void;
  siteUrl: string;
  setSiteUrl: (v: string) => void;
  description: string;
  setDescription: (v: string) => void;
};

export function BaseInfo({
  fieldsOnly,
  name,
  setName,
  isSiteValid,
  siteUrl,
  setSiteUrl,
  description,
  setDescription
}: Props) {
  const { t } = useTranslation();

  const [siteFocused, setSiteFocused] = useState(false);
  const isValid = description.length <= MAX_SHOWROOM_DESCR_LEN;
  const Container = fieldsOnly ? Fragment : ShowroomBox;

  return (
    <Container>
      {!fieldsOnly && <h2>{t('showroom.about')}</h2>}
      {setName && (
        <div className={cls.field}>
          <Input
            value={name || ''}
            onChange={(e) =>
              setName(
                maxLength(
                  preventExtraSpaces(e.currentTarget.value.trimStart()),
                  MAX_SHOWROOM_NAME_LEN
                )
              )
            }
            label={t('name')}
          />
        </div>
      )}
      <div className={cls.field}>
        <Input
          value={siteUrl}
          onChange={(e) =>
            setSiteUrl(
              maxLength(
                preventExtraSpaces(e.currentTarget.value.trimStart()),
                MAX_SITE_LEN
              )
            )
          }
          onFocus={() => setSiteFocused(true)}
          label={t('site')}
          errorText={siteFocused && !isSiteValid ? t('siteError') : ''}
        />
      </div>
      <div className={cls.field}>
        <Textarea
          value={description}
          onChange={(e) =>
            setDescription(
              maxLength(
                preventExtraSpaces(e.currentTarget.value.trimStart()),
                MAX_HIDDEN_SHOWROOM_DESCR_LEN
              )
            )
          }
          placeholder={t('advertisements.description.title')}
          minRows={TEXTAREA_MIN_ROWS}
        />

        <span
          className={cn(cls.counter, {
            [cls.counter_red]: !isValid
          })}
        >
          {t('common.slashSeparated', {
            first: description.length,
            second: MAX_SHOWROOM_DESCR_LEN
          })}
        </span>
      </div>
    </Container>
  );
}
