export function ArrowIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99944 5.15402C10.2631 5.16155 10.4891 5.25949 10.6925 5.46289L16.4255 11.3239C16.5912 11.4972 16.6816 11.7006 16.6816 11.9492C16.6816 12.454 16.2899 12.8457 15.7852 12.8457C15.5441 12.8457 15.3181 12.7478 15.1448 12.5745L9.99944 7.30106L4.85407 12.5745C4.6808 12.7478 4.46233 12.8457 4.21373 12.8457C3.70898 12.8457 3.31724 12.454 3.31724 11.9492C3.31724 11.7081 3.40764 11.4972 3.57338 11.3239L9.3139 5.46289C9.50977 5.25195 9.73577 5.15402 9.99944 5.15402Z"
        fill="currentColor"
      />
    </svg>
  );
}
