import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { axios } from '@/app/api';
import { blockUserViaChat, unblockUserViaChat } from '@/modules/accounts/api';
import { CHAT_API_ROUTES } from '@/modules/chat/api';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatBlockStatusType } from '@/modules/chat/types';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { showAlert } from '@/utils/network';

import cls from './Actions.module.scss';
import confirm from './confirm.json';
import { SearchIcon, LockIcon, RemoveIcon } from './icons';

type Props = {
  chatId: number;
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  parentCls: string;
  hideSearch?: boolean;
};

export const Actions: React.FC<Props> = ({
  chatId,
  isOpen,
  setOpen,
  parentCls,
  hideSearch
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setSearchOpen = useChatDetailStore((s) => s.setSearchOpen);
  const onSearchClick = () => {
    setSearchOpen(true);
    setOpen(false);
  };

  const updateBlockStatus = useChatDetailStore((s) => s.updateBlockStatus);
  const blockStatus = useChatDetailStore((s) => s.detail?.block_status);
  const detail = useChatDetailStore((s) => s.detail);

  // Block
  const [isBlockConfirmOpen, setBlockConfirmOpen] = useState(false);
  const onBlockSubmit = async (blocking: boolean) => {
    setBlockConfirmOpen(false);

    if (!detail) return;
    const advertisement_id = detail.ad?.id;
    const companionId = detail.companion?.user_id;
    if (!advertisement_id || !companionId) return;

    const promise = !blocking
      ? unblockUserViaChat(companionId, { advertisement_id })
      : blockUserViaChat(companionId, { advertisement_id });

    promise
      .then((r) => {
        if (r.data) {
          updateBlockStatus(r.data);
          // getBlockList();
        }
      })
      .catch((error) => showAlert({ error }));
  };

  // Delete
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const onDeleteSubmit = async () => {
    setDeleteConfirmOpen(false);

    try {
      await axios.delete(CHAT_API_ROUTES.deleteChat(chatId));
      showAlert({ type: 'success', text: t('chat.actions.deleted') });
      navigate('/chat');
    } catch (error) {
      showAlert({ error });
    }
  };

  return (
    <>
      <Dropdown
        name="chat-detail-actions"
        isOpen={isOpen}
        close={() => setOpen(false)}
        parentCls={parentCls}
        withModal
        actionsModal
      >
        {!hideSearch && (
          <DropdownItem>
            <button
              className={cls.action_btn}
              type="button"
              onClick={onSearchClick}
            >
              {t('common.search')}
              <SearchIcon />
            </button>
          </DropdownItem>
        )}

        {blockStatus && blockStatus.title ? (
          <>
            {blockStatus.title === ChatBlockStatusType.do_unblock && (
              <DropdownItem>
                <button
                  className={cls.action_btn}
                  type="button"
                  onClick={() => {
                    setOpen(false);
                    onBlockSubmit(false);
                  }}
                >
                  {blockStatus.name}
                  <LockIcon />
                </button>
              </DropdownItem>
            )}
          </>
        ) : (
          <DropdownItem>
            <button
              className={cls.action_btn}
              type="button"
              onClick={() => {
                setOpen(false);
                setBlockConfirmOpen(true);
              }}
            >
              {t('chat.actions.block')}
              <LockIcon />
            </button>
          </DropdownItem>
        )}

        <DropdownItem>
          <button
            className={cn(cls.action_btn, cls.action_btn_delete)}
            type="button"
            onClick={() => {
              setOpen(false);
              setDeleteConfirmOpen(true);
            }}
          >
            {t('chat.actions.delete')}
            <RemoveIcon />
          </button>
        </DropdownItem>
      </Dropdown>

      <ConfirmModal
        name="chat-block-confirm"
        isOpen={isBlockConfirmOpen}
        close={() => setBlockConfirmOpen(false)}
        onButtonClick={(confirmed) => {
          if (confirmed) {
            onBlockSubmit(true);
          } else {
            setBlockConfirmOpen(false);
          }
        }}
        title={t('chat.actions.blockConfirm')}
        confirmText={t('chat.actions.block')}
        cancelText={t('common.cancel')}
      />

      <ConfirmModal
        name="chat-delete-confirm"
        isOpen={isDeleteConfirmOpen}
        close={() => setDeleteConfirmOpen(false)}
        onButtonClick={(confirmed) => {
          if (confirmed) {
            onDeleteSubmit();
          } else {
            setDeleteConfirmOpen(false);
          }
        }}
        lottieData={confirm}
        title={t('chat.actions.deleteTitle')}
        text={t('chat.actions.deleteText')}
        confirmText={t('chat.actions.delete')}
        cancelText={t('common.cancel')}
      />
    </>
  );
};
