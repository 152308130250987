import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { SortFilter } from '@/modules/showroom/advert/my/types';
import { PrimarySelect } from '@/ui/PrimarySelect/PrimarySelect';

import cls from './SortSelect.module.scss';

type Props = {
  sortFilters: SortFilter[];
  sortId: Nullable<number>;
  setSortId: (sortId: Nullable<number>) => void;
};

export function SortSelect({ sortFilters, sortId, setSortId }: Props) {
  const { t } = useTranslation();

  const options = useMemo<OptionI[]>(
    () => sortFilters.map((e) => ({ id: String(e.id), text: e.name })),
    [sortFilters]
  );
  const selectedSort = options.find((opt) => opt.id === String(sortId));

  const onSortAdd = (option: OptionI) => {
    setSortId(Number(option.id));
  };

  const onSortRemove = () => {
    setSortId(null);
  };

  return (
    <div className={cls.root}>
      <PrimarySelect
        name="search-sort-select"
        options={options}
        value={selectedSort ? [selectedSort] : undefined}
        onOptionAdd={onSortAdd}
        onOptionRemove={onSortRemove}
        placeholder={t('sort')}
        showScrollbar
      />
    </div>
  );
}
