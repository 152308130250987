import { useRef, useEffect } from 'react';

export function useInterval(callback: () => void, delay: number | null): void {
  const savedCallback = useRef<() => void | null>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback?.current !== 'undefined') {
        savedCallback?.current();
      }
    }

    let id: NodeJS.Timeout;
    if (delay !== null) {
      id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => clearInterval(id);
  }, [delay]);
}
