import { useCallback, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { useRefState } from '@/hooks/useRefState';
import { defaultRentSearchReq } from '@/modules/showroom/advert/rent/my/helpers';
import {
  GetMyRentAdvertsQueryReq,
  GetRentAdvertsReq,
  GetRentAdvertsRes,
  RentAdvert,
  RentAdvertExistedStatus
} from '@/modules/showroom/advert/rent/my/types';
import { parseResultsParam } from '@/modules/showroom/advert/search/helpers';
import { LOAD_COUNT } from '@/modules/showroom/advert/search/hooks';
import { showAlert } from '@/utils/network';

export function useRentSearchReq(): [GetRentAdvertsReq, boolean] {
  const [searchParams] = useSearchParams();
  // const req = paramsToSearchReq(parseSearchParams(searchParams));
  const req = defaultRentSearchReq;
  const showResults = parseResultsParam(searchParams);
  return [req, showResults];
}

// Search adverts
type UseRentSearchAdvertsPg = {
  isAllLoaded: boolean;
  isLoading: boolean;
  ads: RentAdvert[] | undefined;
  load: (skip: number) => void;
};

export function useRentSearchAdvertsPg(
  req: GetRentAdvertsReq,
  status: Nullable<RentAdvertExistedStatus> | undefined,
  searchQuery?: string
): UseRentSearchAdvertsPg {
  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoading, setLoading, isLoadingRef] = useRefState<boolean>(false);
  const [ads, setAds] = useState<GetRentAdvertsRes>();

  const abortController = useRef<Nullable<AbortController>>(null);

  const load = useCallback(
    async (skip: number) => {
      if (isLoadingRef.current) return;
      if (status === undefined) return;

      setLoading(true);
      if (!skip) setAds([]);

      try {
        const queryReq: GetMyRentAdvertsQueryReq = {
          status_id: status?.id || null,
          skip,
          search: searchQuery,
          limit: LOAD_COUNT
        };

        if (abortController.current) abortController.current.abort();
        const ab = new window.AbortController();
        abortController.current = ab;

        const r = await axios.post<GetRentAdvertsRes>(
          '/advertisements/rent/my',
          req,
          { params: queryReq, signal: ab.signal }
        );
        const loaded = r.data;
        const len = loaded.length;

        setAds((prev) => {
          if (!prev || skip === 0) {
            return loaded;
          }

          return [...prev, ...loaded];
        });
        setAllLoaded(len < LOAD_COUNT);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [req, status]
  );

  return { isAllLoaded, isLoading, ads, load };
}
