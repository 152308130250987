import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { handleText } from '@/modules/chat/Detail/Messages/Message/helpers';
import { useAdvertCreation } from '@/modules/showroom/advert/create/hooks';
import { Message } from '@/modules/showroom/advert/create/Message/Message';
import { useCreateRentAdvertCopy } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/helpers';
import { deleteRentAdvertisement } from '@/modules/showroom/advert/rent/api';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { RENT_CARS_CATEGORY_ID } from '@/modules/showroom/advert/search/consts';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { Button } from '@/ui/Button/Button';
import { showAlert } from '@/utils/network';

import { ChevronIcon } from './icons';
import cls from './StickyMsg.module.scss';

export function RentStickyMsg() {
  const { t } = useTranslation();

  const detail = useRentUpdateAdvertStore((s) => s.detail);

  // Flags
  const isRejected = detail?.status.title === AdvertStatusType.rejected;
  const reasonText = detail?.moderation_rejected_reason || '';

  const updatesPending = !!detail?.updates_pending_moderation;
  const updatesRejectReason = detail?.updates_reject_reason || '';

  const isModeration = detail?.status.title === AdvertStatusType.moderation;
  const isDraft = detail?.status.title === AdvertStatusType.draft;
  const isPublished = detail?.status.title === AdvertStatusType.published;
  const isSold = detail?.status.title === AdvertStatusType.sold;
  const isRemoved = detail?.status.title === AdvertStatusType.removed;

  // Create
  const { rentCarCreating, createRentCar } = useAdvertCreation();

  // Copy
  const [copyAd, copying] = useCreateRentAdvertCopy(detail);

  // Delete
  const [deleting, setDeleting] = useState(false);
  const deleteAd = async (action: 'create' | 'copy') => {
    if (!detail) return;
    setDeleting(true);

    try {
      await deleteRentAdvertisement(detail.id);

      if (action === 'copy') {
        copyAd();
      } else {
        createRentCar(RENT_CARS_CATEGORY_ID);
      }
    } catch (error) {
      showAlert({ error });
    } finally {
      setDeleting(false);
    }
  };

  const loading = deleting || copying || rentCarCreating;

  return (
    <div className={cls.root}>
      {isRejected && reasonText && (
        <Message title={t('rejectReason') as string} red>
          <p
            dangerouslySetInnerHTML={{
              __html: handleText(reasonText)
            }}
          />
        </Message>
      )}

      {updatesRejectReason && (
        <Message title={t('rejectReason') as string} red>
          <p
            dangerouslySetInnerHTML={{
              __html: handleText(updatesRejectReason)
            }}
          />
        </Message>
      )}

      {updatesPending && (
        <Message title={t('publish.updatesPending.title') as string}>
          <p>{t('publish.updatesPending.subtitle')}</p>
        </Message>
      )}

      {(isDraft || isRemoved) && (
        <Message>
          <p>{t('advertisements.msgs.draft.1')}</p>
          <p>{t('advertisements.msgs.draft.2')}</p>
        </Message>
      )}

      {(isPublished || isModeration) && (
        <Message>
          <p>
            {t(
              isModeration
                ? 'advertisements.msgs.moderation.1'
                : 'advertisements.msgs.published.1'
            )}
          </p>
          <p>{t('advertisements.msgs.published.2')}</p>

          <Button
            onClick={() => deleteAd('copy')}
            disabled={loading}
            loading={deleting || copying}
            variant="link"
            color="blue"
            size="compact"
            gap={4}
          >
            {t('advertisements.msgs.deleteAndCopy')}
            <ChevronIcon />
          </Button>

          <Button
            onClick={() => deleteAd('create')}
            disabled={loading}
            loading={deleting || rentCarCreating}
            variant="link"
            color="blue"
            size="compact"
            gap={4}
          >
            {t('advertisements.msgs.deleteAndCreate')}
            <ChevronIcon />
          </Button>
        </Message>
      )}

      {isSold && (
        <Message>
          <p>{t('advertisements.msgs.sold.1')}</p>
          <p>{t('advertisements.msgs.sold.2')}</p>

          <Button
            onClick={() => copyAd()}
            disabled={loading}
            loading={copying}
            variant="link"
            color="blue"
            size="compact"
            gap={4}
          >
            {t('advertisements.msgs.createCopy')}
            <ChevronIcon />
          </Button>

          <Button
            onClick={() => createRentCar(RENT_CARS_CATEGORY_ID)}
            disabled={loading}
            loading={rentCarCreating}
            variant="link"
            color="blue"
            size="compact"
            gap={4}
          >
            {t('advertisements.msgs.createNew')}
            <ChevronIcon />
          </Button>
        </Message>
      )}
    </div>
  );
}
