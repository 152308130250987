import { AxiosResponse } from 'axios';

import { axios } from '@/app/api';
import {
  AccountInfo,
  BalanceReq,
  BalanceRes,
  BlockUserViaChatReq,
  BlockUserViaChatRes,
  MakePaymentReq,
  MakePaymentRes,
  TopUpBalanceReq
} from '@/modules/accounts/types';

export function getAccountInfo() {
  return axios.get<AccountInfo>('/dealers/accounts/detail');
}

export function getBalance(params?: BalanceReq) {
  return axios.get<BalanceRes>('/dealers/accounts/balance', { params });
}

export function blockUserViaChat(
  account_id: number,
  req: BlockUserViaChatReq
): Promise<AxiosResponse<BlockUserViaChatRes>> {
  return axios.post<BlockUserViaChatRes>(`/accounts/${account_id}/block`, req);
}

export function unblockUserViaChat(
  account_id: number,
  req: BlockUserViaChatReq
): Promise<AxiosResponse<BlockUserViaChatRes>> {
  return axios.post<BlockUserViaChatRes>(
    `/accounts/${account_id}/unblock`,
    req
  );
}

export function topupBalance(
  req: TopUpBalanceReq
): Promise<AxiosResponse<unknown>> {
  return axios.post<unknown>(`/dealers/accounts/balance/top-up`, req);
}

export function makePayment(
  req: MakePaymentReq
): Promise<AxiosResponse<MakePaymentRes>> {
  return axios.post<MakePaymentRes>(`/accounts/payment/make-and-complete`, req);
}

export function setAccountPlatform(
  platform: 'web'
): Promise<AxiosResponse<unknown>> {
  return axios.post<unknown>(`/accounts/device/current`, { platform });
}
