/* eslint-disable import/no-duplicates */

import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';

import { Nullable } from '@/app/types';
import { getDateAgo } from '@/utils/date';

export enum RangeType {
  today = 'today',
  week = 'week',
  twoWeeks = 'twoWeeks',
  month = 'month',
  threeMonths = 'threeMonths',
  monthToDate = 'monthToDate',
  yearToDate = 'yearToDate'
}

export function getDatesByType(
  type: RangeType,
  initialStart?: Nullable<Date>
): [Date, Date] | undefined {
  let start: Nullable<Date> = initialStart || null;
  const end = endOfDay(new Date());

  switch (type) {
    case RangeType.today:
      start = startOfDay(end);
      return [start, end];
    case RangeType.week:
      start = startOfDay(getDateAgo(7));
      return [start, end];
    case RangeType.twoWeeks:
      start = startOfDay(getDateAgo(14));
      return [start, end];
    case RangeType.month:
      start = startOfDay(getDateAgo(30));
      return [start, end];
    case RangeType.threeMonths:
      start = startOfDay(getDateAgo(90));
      return [start, end];
    case RangeType.monthToDate:
      start = startOfMonth(end);
      return [start, end];
    case RangeType.yearToDate:
      start = startOfYear(end);
      return [start, end];
    default:
      break;
  }
}
