import { useCallback, useEffect, useState } from 'react';

import { getNotifications } from '@/modules/notifications/api';
import { GetNotificationsRes } from '@/modules/notifications/types';
import { showAlert } from '@/utils/network';

export function useGetNotifications(): [
  GetNotificationsRes | undefined,
  boolean,
  () => Promise<void>
] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetNotificationsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getNotifications();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading, load];
}
