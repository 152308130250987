import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { CallbackListGroup } from '@/modules/callback/CallbackPage/CallbackListGroup/CallbackListGroup';
import { CallbackRowSkeleton } from '@/modules/callback/CallbackPage/CallbackRow/CallbackRowSkeleton';
import { useCallbackListPg } from '@/modules/callback/hooks';
import { CallbackItem } from '@/modules/callback/types';

import cls from './CallbackPage.module.scss';

const skeletonsArr = new Array(4).fill(null);

type Props = {
  search: string;
  setHasNotViewed: (v: boolean) => void;
};

export function CallbackPage({ search, setHasNotViewed }: Props) {
  const { t } = useTranslation();

  const {
    isAllLoaded,
    isLoading: loading,
    data,
    load
  } = useCallbackListPg(search);

  const fetchData = () => {
    if (isAllLoaded || loading) return;
    load(data ? data.length : 0);
  };

  const onDataChange = useCallback(
    (data?: CallbackItem[]) => {
      const notViewed = data ? data.filter((v) => !v.has_been_viewed) : [];
      setHasNotViewed(notViewed.length > 0);
    },
    [setHasNotViewed]
  );

  useEffect(() => {
    onDataChange(data);
  }, [onDataChange, data]);

  return (
    <>
      {data && data.length > 0 && <CallbackListGroup list={data} />}

      {!loading && data && data.length <= 0 && (
        <div className={cls.empty}>
          <p>{t('callback.message')}</p>
          <p>{t('callback.empty')}</p>
        </div>
      )}

      {loading && (
        <>
          {skeletonsArr.map((_s, i) => (
            <CallbackRowSkeleton key={i} />
          ))}
        </>
      )}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !loading) {
              fetchData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </>
  );
}
