import { axios } from '@/app/api';
import {
  GetStatsEngagementReq,
  GetStatsEngagementRes
} from '@/modules/overview/types';

const PATH = '/dealers/showrooms/advertisements';

export function getEngagementStatsSummary(
  params: GetStatsEngagementReq,
  lang: string,
  abort?: AbortController
) {
  return axios.get<GetStatsEngagementRes>(`${PATH}/stats/engagement`, {
    signal: abort?.signal,
    params,
    headers: { lang }
  });
}
