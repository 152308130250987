import { axios } from '@/app/api';
import {
  DepositHistoryReq,
  DepositHistoryRes,
  ExpensesDetailStatisticsReq,
  ExpensesDetailStatisticsRes,
  ExpensesDetailStatisticsResV2
} from '@/modules/wallet/types';

const PATH = '/dealers/accounts';

export function getExpensesDetailStatistics(
  params: ExpensesDetailStatisticsReq,
  lang: string,
  abort?: AbortController
) {
  return axios.get<ExpensesDetailStatisticsRes>(
    `${PATH}/expenses/detail-statistics`,
    {
      signal: abort?.signal,
      params,
      headers: { lang }
    }
  );
}

export function getExpensesDetailStatisticsV2(
  params: ExpensesDetailStatisticsReq,
  lang: string,
  abort?: AbortController
) {
  return axios.get<ExpensesDetailStatisticsResV2>(
    `${PATH}/expenses/detail-statistics/v2`,
    {
      signal: abort?.signal,
      params,
      headers: { lang }
    }
  );
}

export function getDepositHistory(params: DepositHistoryReq) {
  return axios.get<DepositHistoryRes>(`${PATH}/deposits/detail-statistics`, {
    params
  });
}
