import { CommonObj, Nullable } from '@/app/types';

export enum PhoneSendCodeChannel {
  sms = 'sms',
  call = 'call',
  whatsapp = 'whatsapp'
}

export interface CreateShowroomContactReq {
  name: string;
  phone: string;
  lang_ids: number[];
  email: Nullable<string>;
  avatar_url?: Nullable<string>;
  call_after?: Nullable<number>;
  call_before?: Nullable<number>;
}

export interface UpdateShowroomContactReq {
  contact_id: number;
  phone?: Nullable<string>;
  email?: Nullable<string>;
  name?: Nullable<string>;
  lang_ids?: Nullable<number[]>;
  avatar_url?: Nullable<string>;
  call_after?: Nullable<number>;
  call_before?: Nullable<number>;
}

export type GetShowroomContactsRes = ShowroomContact[];

export interface ShowroomContactSmall extends CommonObj {
  avatar_url: Nullable<string>;
}

export interface ShowroomContact extends ShowroomContactSmall {
  phone: string;
  email: Nullable<string>;
  contact_langs: ShowroomContactLang[];
  call_after: Nullable<number>;
  call_before: Nullable<number>;
}

export interface ShowroomContactLang extends CommonObj {
  title: string;
}

export type GetShowroomContactLangsRes = ShowroomContactLang[];
