import { Photos } from '@/modules/showroom/advert/create/Photos/Photos';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function PhotosWrap() {
  const photos = useRentUpdateAdvertStore((s) => s.photos);
  const setPhotos = useRentUpdateAdvertStore((s) => s.setPhotos);
  const addPhotos = useRentUpdateAdvertStore((s) => s.addPhotos);
  const removePhoto = useRentUpdateAdvertStore((s) => s.removePhoto);
  const replaceLocalPhoto = useRentUpdateAdvertStore(
    (s) => s.replaceLocalPhoto
  );
  const isPhotosFilled = useRentUpdateAdvertStore((s) => s.isPhotosFilled);
  const setPhotosFilled = useRentUpdateAdvertStore((s) => s.setPhotosFilled);

  return (
    <Photos
      photos={photos}
      setPhotos={setPhotos}
      addPhotos={addPhotos}
      removePhoto={removePhoto}
      replaceLocalPhoto={replaceLocalPhoto}
      isPhotosFilled={isPhotosFilled}
      setPhotosFilled={setPhotosFilled}
    />
  );
}
