import { Promotion } from '@/modules/showroom/advert/create/Promotion/Promotion';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function PromotionWrap() {
  const promotionPackageId = useUpdateAdvertStore((s) => s.promotionPackageId);
  const setPromotionPackageId = useUpdateAdvertStore(
    (s) => s.setPromotionPackageId
  );
  const autoExtension = useUpdateAdvertStore((s) => s.autoExtension);
  const setAutoExtension = useUpdateAdvertStore((s) => s.setAutoExtension);

  return (
    <Promotion
      promotionPackageId={promotionPackageId}
      setPromotionPackageId={setPromotionPackageId}
      autoExtension={autoExtension}
      setAutoExtension={setAutoExtension}
    />
  );
}
