import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useLang } from '@/hooks/useLang';
import { useAccountStore } from '@/modules/accounts/store';
import { useSignout } from '@/modules/auth/hooks';
import { LANG_NAME_MAP, LANGS_MAP, saveLangInCookie } from '@/modules/i18n';
import { useShowroom } from '@/modules/showroom/api';
import { Theme } from '@/modules/theme/types';
import { useDarkmode } from '@/modules/theme/useTheme';
import { Avatar } from '@/ui/Avatar/Avatar';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';

import {
  CheckIcon,
  ChevronIcon,
  ExitIcon,
  TranslateIcon,
  RightChevronIcon,
  SwitchThemeIcon,
  SettingsIcon
} from './icons';
import cls from './User.module.scss';

export function User() {
  const { t, i18n } = useTranslation();
  const [lang] = useLang();
  const { pathname } = useLocation();

  const [isOpen, setOpen] = useState(false);
  const [isLangOpen, setLangOpen] = useState(false);
  const [isThemeOpen, setThemeOpen] = useState(false);

  const { theme, isDark, setDarkTheme, setLightTheme } = useDarkmode();
  const onThemeClick = (dark: boolean) => {
    if (dark) {
      setDarkTheme();
    } else {
      setLightTheme();
    }
    setThemeOpen(false);
    setOpen(false);
  };

  const onLangClick = (loc: string) => {
    i18n.changeLanguage(loc);
    saveLangInCookie(loc);
    setLangOpen(false);
    setOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setLangOpen(false);
      setThemeOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  // Signout
  const { isConfirmOpen, setConfirmOpen, onConfirmClick } = useSignout();

  return (
    <div className={cls.root}>
      <button
        className={cn(cls.btn, { [cls.btn_open]: isOpen })}
        type="button"
        onClick={() => setOpen(!isOpen)}
      >
        <UserRow />
        <ChevronIcon />
      </button>

      <Dropdown
        name="user-dropdown"
        parentCls={cls.root}
        isOpen={isOpen}
        close={() => setOpen(false)}
        className={cls.dropdown}
        toLeft
      >
        <div className={cls.dropdown_user}>
          <UserRow />
        </div>
        <li className={cls.dropdown_hr}></li>

        <DropdownItem>
          <button
            className={cls.dropdown_btn}
            onClick={() => {
              window.setTimeout(() => {
                setThemeOpen(true);
              }, 100);
            }}
          >
            <SwitchThemeIcon />
            {t('common.theme')}
            <span>{t(isDark ? 'theme.dark' : 'theme.light')}</span>
            <span className={cls.chevron_right}>
              <RightChevronIcon />
            </span>
          </button>
        </DropdownItem>

        <DropdownItem>
          <button
            className={cls.dropdown_btn}
            onClick={() => {
              window.setTimeout(() => {
                setLangOpen(true);
              }, 100);
            }}
          >
            <TranslateIcon />
            {LANG_NAME_MAP[lang]}
            <span className={cls.chevron_right}>
              <RightChevronIcon />
            </span>
          </button>
        </DropdownItem>

        <DropdownItem>
          <Link className={cls.dropdown_btn} to="/settings">
            <SettingsIcon />
            {t('common.settings')}
          </Link>
        </DropdownItem>
        <li className={cls.dropdown_hr}></li>
        <DropdownItem>
          <button
            className={cn(cls.dropdown_btn, cls.dropdown_btn_red)}
            onClick={() => {
              setOpen(false);
              setConfirmOpen(true);
            }}
          >
            <ExitIcon />
            {t('common.signout')}
          </button>
        </DropdownItem>
      </Dropdown>

      <Dropdown
        name="lang-select"
        isOpen={isLangOpen}
        close={() => setLangOpen(false)}
        parentCls={cls.root}
        className={cls.lang_dropdown}
      >
        {Object.values(LANGS_MAP).map((loc) => (
          <DropdownItem key={loc}>
            <button
              className={cls.lang_btn}
              onClick={() => onLangClick(loc)}
              type="button"
            >
              <span style={{ visibility: loc === lang ? 'visible' : 'hidden' }}>
                <CheckIcon />
              </span>
              {LANG_NAME_MAP[loc]}
            </button>
          </DropdownItem>
        ))}
      </Dropdown>

      <Dropdown
        name="theme-select"
        isOpen={isThemeOpen}
        close={() => setThemeOpen(false)}
        parentCls={cls.root}
        className={cls.theme_dropdown}
      >
        <DropdownItem>
          <button
            className={cls.theme_btn}
            onClick={() => onThemeClick(true)}
            type="button"
          >
            <span
              style={{
                visibility: theme === Theme.dark ? 'visible' : 'hidden'
              }}
            >
              <CheckIcon />
            </span>
            {t('theme.dark')}
          </button>
        </DropdownItem>

        <DropdownItem>
          <button
            className={cls.theme_btn}
            onClick={() => onThemeClick(false)}
            type="button"
          >
            <span
              style={{
                visibility: theme === Theme.light ? 'visible' : 'hidden'
              }}
            >
              <CheckIcon />
            </span>
            {t('theme.light')}
          </button>
        </DropdownItem>
      </Dropdown>

      <ConfirmModal
        name="user-dropdown-signout"
        isOpen={isConfirmOpen}
        close={() => setConfirmOpen(false)}
        title={t('auth.signoutConfirm')}
        confirmText={t('common.confirm')}
        cancelText={t('common.cancel')}
        onButtonClick={onConfirmClick}
      />
    </div>
  );
}

function UserRow() {
  const accountInfo = useAccountStore((s) => s.accountInfo);
  const [showroom] = useShowroom();
  const email = accountInfo?.email;
  const name = showroom?.name || showroom?.username || email || accountInfo?.id;

  return (
    <div className={cls.row}>
      <Avatar
        size={36}
        src={showroom?.logo_url}
        user={accountInfo ? { id: accountInfo?.id, name: String(name) } : null}
      />
      <div className={cls.text}>
        <b className={cls.name}>{name}</b>
        {email && <span className={cls.email}>{email}</span>}
      </div>
    </div>
  );
}
