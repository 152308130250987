import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { usePromotionPackages } from '@/modules/promotion/hooks';
import { NO_PROMO_ID } from '@/modules/showroom/advert/create/helpers';
import { CARS_CATEGORY_ID } from '@/modules/showroom/advert/search/consts';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { useTariff } from '@/modules/showroom/tariff/api';
import { AdvertsCalc } from '@/modules/showroom/tariff/TariffsPage/AdvertsCalc/AdvertsCalc';
import {
  getInitialPackagesCount,
  PackagesCountType
} from '@/modules/showroom/tariff/TariffsPage/helpers';
import { Result } from '@/modules/showroom/tariff/TariffsPage/Result/Result';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { Message } from '@/ui/Message/Message';
import { PageHead } from '@/ui/PageHead/PageHead';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './TariffsPage.module.scss';

export const TARIFFS_VALUE_MAX_LENGTH = 7;

export function TariffsPage() {
  const { t } = useTranslation();

  // Packages
  const [packages, packagesLoading] = usePromotionPackages();
  const packagesList = packages?.filter((p) => p.id !== NO_PROMO_ID);

  // Tariff data
  const [carsData, carsDataLoading] = useTariff(CARS_CATEGORY_ID);
  const [platesData, platesDataLoading] = useTariff(VRP_CATEGORY_ID);
  const loading = packagesLoading || carsDataLoading || platesDataLoading;

  // Cars
  const [carsCount, setCarsCount] = useState('');
  const [carsDays, setCarsDays] = useState('30');

  const [carsPackagesCount, setCarsPackagesCount] =
    useState<Nullable<PackagesCountType>>(null);
  const onCarsPackagesCountChange = (packageId: string, count: number) => {
    setCarsPackagesCount((prev) => ({ ...prev, [packageId]: count }));
  };

  useEffect(() => {
    if (!carsPackagesCount && packagesList) {
      setCarsPackagesCount(getInitialPackagesCount(packagesList));
    }
  }, [carsPackagesCount, packagesList]);

  const carsPrice = useMemo(() => {
    if (carsData && carsDays && carsCount) {
      const count = Number(carsCount);
      const daysNum = Number(carsDays) - 1;
      const perDay = Number(carsData.price_per_day);
      const firstDay = Number(carsData.price_first_day);

      const firstDayTotal = count * firstDay;
      const perDayTotal = perDay * count * daysNum;

      return firstDayTotal + perDayTotal;
    }

    return 0;
  }, [carsCount, carsDays, carsData]);

  // Plates
  const [platesCount, setPlatesCount] = useState('');
  const [platesDays, setPlatesDays] = useState('30');

  const [platesPackagesCount, setPlatesPackagesCount] =
    useState<Nullable<PackagesCountType>>(null);
  const onPlatesPackagesCountChange = (packageId: string, count: number) => {
    setPlatesPackagesCount((prev) => ({ ...prev, [packageId]: count }));
  };

  useEffect(() => {
    if (!platesPackagesCount && packagesList) {
      setPlatesPackagesCount(getInitialPackagesCount(packagesList));
    }
  }, [platesPackagesCount, packagesList]);

  const platesPrice = useMemo(() => {
    if (platesData && platesDays && platesCount) {
      const count = Number(platesCount);
      const daysNum = Number(platesDays) - 1;
      const perDay = Number(platesData.price_per_day);
      const firstDay = Number(platesData.price_first_day);

      const firstDayTotal = count * firstDay;
      const perDayTotal = perDay * count * daysNum;

      return firstDayTotal + perDayTotal;
    }

    return 0;
  }, [platesCount, platesDays, platesData]);

  // Total
  const packagesCount = useMemo<PackagesCountType>(() => {
    if (!packagesList || !carsPackagesCount || !platesPackagesCount) return {};

    const result = packagesList.reduce<PackagesCountType>((acc, p) => {
      const packageId = String(p.id);
      const tmp = { ...acc };
      tmp[packageId] =
        carsPackagesCount[packageId] + platesPackagesCount[packageId];
      return { ...tmp };
    }, {});

    return result;
  }, [carsPackagesCount, packagesList, platesPackagesCount]);

  const packagesPrice = useMemo<PackagesCountType>(() => {
    if (!packagesList || !carsPackagesCount || !platesPackagesCount) return {};

    const result = packagesList.reduce<PackagesCountType>((acc, p) => {
      const packageId = String(p.id);
      const packagePrice = p.price;
      const tmp = { ...acc };
      tmp[packageId] = packagesCount[packageId] * packagePrice;
      return { ...tmp };
    }, {});

    return result;
  }, [carsPackagesCount, packagesCount, packagesList, platesPackagesCount]);

  const packagesTotal = Object.values(packagesPrice).reduce(
    (acc, v) => acc + v,
    0
  );
  const totalPrice = carsPrice + platesPrice + packagesTotal;

  return (
    <>
      <LayoutHeader title={t('tariffs.title')} />
      <PageHead title={t('pageTitle', { title: t('tariffs.title') })} />

      <div className={cls.root}>
        <div className={cls.content}>
          <div className={cls.message}>
            <Message
              text={[t('tariffs.text1'), t('tariffs.text2')]}
              closeCookieName="tariffs-message"
            />
          </div>

          {loading && <Spinner centered />}

          {!loading && packagesList && carsData && carsPackagesCount && (
            <AdvertsCalc
              packages={packagesList}
              packagesCount={carsPackagesCount}
              onPackagesCountChange={onCarsPackagesCountChange}
              title={t('tariffs.carsPublish')}
              firstDayPrice={Number(carsData.price_first_day)}
              dayPrice={Number(carsData.price_per_day)}
              daysCount={Number(carsDays)}
              setDaysCount={(v) => setCarsDays(String(v))}
              advertsCount={carsCount}
              setAdvertsCount={(v) => setCarsCount(String(v))}
            />
          )}

          {!loading && packagesList && platesData && platesPackagesCount && (
            <AdvertsCalc
              packages={packagesList}
              packagesCount={platesPackagesCount}
              onPackagesCountChange={onPlatesPackagesCountChange}
              title={t('tariffs.vrpPublish')}
              firstDayPrice={Number(platesData.price_first_day)}
              dayPrice={Number(platesData.price_per_day)}
              daysCount={Number(platesDays)}
              setDaysCount={(v) => setPlatesDays(String(v))}
              advertsCount={platesCount}
              setAdvertsCount={(v) => setPlatesCount(String(v))}
            />
          )}
        </div>

        <Result
          packages={packagesList || []}
          carsDays={Number(carsDays || '0')}
          carsCount={Number(carsCount || '0')}
          carsPrice={carsPrice}
          platesDays={Number(platesDays || '0')}
          platesCount={Number(platesCount || '0')}
          platesPrice={platesPrice}
          packagesPrice={packagesPrice}
          packagesCount={packagesCount}
          total={totalPrice}
        />
      </div>
    </>
  );
}
