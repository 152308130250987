import cn from 'classnames';

import { useDarkmode } from '@/modules/theme/useTheme';

import cls from './FiltersList.module.scss';
import { ListProps } from './helpers';
import { ArrowIcon, CheckIcon } from './icons';

export function List({
  selectStyle,
  showImage = true,
  list,
  onClick,
  activeId,
  showArrow
}: ListProps) {
  const { isDark } = useDarkmode();

  return (
    <ul className={cls.list}>
      {list.map((v) => {
        const isActive = Array.isArray(activeId)
          ? activeId.includes(v.id)
          : v.id === activeId;
        const darkImg = 'dark_mode_photo_url' in v ? v.dark_mode_photo_url : '';
        const defaultImg = 'photo_url' in v ? v.photo_url : '';
        const resultImg = isDark ? darkImg : defaultImg;
        const hasImage = !!resultImg;
        const showImgPlaceholder = hasImage && !showImage;

        return (
          <li key={v.id}>
            <button
              className={cn(cls.row, {
                [cls.row_select]: selectStyle,
                [cls.row_active]: isActive
              })}
              type="button"
              onClick={() => onClick(v)}
            >
              {selectStyle ? (
                <>
                  <CheckIcon />
                  <span>{v.name}</span>
                </>
              ) : (
                <>
                  {showImage && hasImage && (
                    <img src={resultImg as string} alt="" />
                  )}
                  {showImgPlaceholder && (
                    <div className={cls.img_placeholder} />
                  )}
                  <span>{v.name}</span>
                  {showArrow && <ArrowIcon />}
                </>
              )}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
