import { useTranslation } from 'react-i18next';

import { ShowroomEditValues } from '@/modules/showroom/ShowroomEdit/ShowroomEdit';
import { showAlert } from '@/utils/network';
import { validateSiteUrl } from '@/utils/validate';

const requiredValues = {
  name: 'name',
  username: 'username',
  contactName: 'contactName',
  contactPhone: 'contactPhone',
  _siteUrl: 'siteUrl'
};

export const useShowroomDataValidate = () => {
  const { t } = useTranslation();

  const validate = (values: ShowroomEditValues): boolean => {
    const validateErrors: string[] = [];

    // Site url
    const isSiteUrlValid = values.siteUrl
      ? validateSiteUrl(values.siteUrl)
      : true;
    if (!isSiteUrlValid) validateErrors.push(requiredValues._siteUrl);

    // Other values
    Object.keys(requiredValues)
      .filter((key) => !key.startsWith('_'))
      .forEach((key) => {
        if (!(values as Record<string, unknown>)[key])
          validateErrors.push((requiredValues as Record<string, string>)[key]);
      });

    // Alert
    if (validateErrors.length > 0) {
      const errorsStrings = validateErrors
        .map((error) => t(`showroom.required.${error}`))
        .join(t('common.listSeparator') || '');

      showAlert({
        error: t('showroom.required.alert', { requiredFields: errorsStrings })
      });

      return false;
    }

    return true;
  };

  return { validate };
};
