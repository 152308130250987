import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Trans, useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { changeBookingStatus, updateBooking } from '@/modules/booking/api';
import { MyBookingCard } from '@/modules/booking/MyBookings/MyBookingsList/MyBookingCard/MyBookingCard';
import { BookingType } from '@/modules/booking/types';
import { CountryCodeType } from '@/modules/country-codes/types';
import { Button } from '@/ui/Button/Button';
import { DateTimePicker } from '@/ui/DateTimePicker/DateTimePicker';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { Input } from '@/ui/Input/Input';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { validatePhoneInput } from '@/ui/PhoneInput/helpers';
import { PhoneInput } from '@/ui/PhoneInput/PhoneInput';
import { Select } from '@/ui/Select/Select';
import { Textarea } from '@/ui/Textarea/Textarea';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { diffInDays, getDateString } from '@/utils/date';
import { maxLength, numberWithSpaces, pluralKey } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './BookingConfirm.module.scss';
import { ChevronIcon, CloseIcon } from './icons';

const MAX_REASON_TEXT_LEN = 512;

type Props = {
  isOpen: boolean;
  close: () => void;
  booking: BookingType;
  decline?: boolean;
  onConfirm?: () => void;
};

export function BookingConfirm({
  isOpen,
  close,
  booking,
  decline,
  onConfirm
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [reasonText, setReasonText] = useState('');
  const [isTariffsOpen, setTariffsOpen] = useState(false);

  // Name
  const [name, setName] = useState(booking.about_lead.name || '');

  // Phone
  const [phone, setPhone] = useState<string>(
    booking.about_lead.phone_number || ''
  );
  const [phoneCode, setPhoneCode] = useState<Nullable<CountryCodeType>>(null);
  const [isMaskCorrect, setMaskCorrect] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  useEffect(() => {
    setIsPhoneValid(validatePhoneInput(phoneCode, phone, isMaskCorrect));
  }, [phoneCode, isMaskCorrect, phone]);

  // Dates
  const [isStartOpen, setStartOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(booking.about_booking.dates.start * 1000)
  );

  const [isEndOpen, setEndOpen] = useState(false);
  const [endDate, setEndDate] = useState(
    new Date(booking.about_booking.dates.end * 1000)
  );

  const datesChanged =
    booking.about_booking.dates.start !== startDate.getTime() / 1000 ||
    booking.about_booking.dates.end !== endDate.getTime() / 1000;

  const minDate = useMemo(() => {
    const d = new Date(startDate);
    d.setDate(d.getDate() + 1);
    return d;
  }, [startDate]);

  const maxDate = useMemo(() => {
    const d = new Date(endDate);
    d.setDate(d.getDate() - 1);
    return d;
  }, [endDate]);

  const startSelect = useMemo(
    () => ({
      id: `booking-confirm-date-start-${booking.id}`,
      text: getDateString(startDate, lang, {
        day: '2-digit',
        month: 'long'
      })
    }),
    [booking.id, lang, startDate]
  );

  const endSelect = useMemo(
    () => ({
      id: `booking-confirm-date-end-${booking.id}`,
      text: getDateString(endDate, lang, {
        day: '2-digit',
        month: 'long'
      })
    }),
    [booking.id, lang, endDate]
  );

  const tariffByPeriod = useMemo(() => {
    const days = diffInDays(endDate, startDate);
    const tariff = booking.rent_ad.tariffs.find((tf) => {
      if (tf.days_up_to) {
        return days >= tf.days_from && days <= tf.days_up_to;
      }

      return days >= tf.days_from;
    });
    return tariff;
  }, [booking.rent_ad.tariffs, endDate, startDate]);

  // Days
  const daysCount = useMemo(() => {
    const days = diffInDays(endDate, startDate);
    return days;
  }, [endDate, startDate]);

  const daysStr = useMemo(() => {
    return pluralKey(
      daysCount,
      t('daysPlural.one', {
        days: daysCount
      }),
      t('daysPlural.few', {
        days: daysCount
      }),
      t('daysPlural.many', {
        days: daysCount
      })
    );
  }, [daysCount, t]);

  const totalPrice = useMemo(() => {
    if (decline || !datesChanged)
      return (
        booking.about_booking.total_cost + (booking.about_booking.deposit || 0)
      );

    if (!tariffByPeriod) return null;

    const { deposit_not_required, deposit } = booking.rent_ad;
    const depNum = deposit || booking.about_booking.deposit || 0;
    const dep = deposit_not_required ? 0 : depNum;

    const daysPrice =
      daysCount && tariffByPeriod ? daysCount * tariffByPeriod.price : 0;
    return dep + daysPrice;
  }, [
    booking.about_booking,
    booking.rent_ad,
    datesChanged,
    daysCount,
    decline,
    tariffByPeriod
  ]);

  const isFormValid = decline
    ? true
    : !!name &&
      isPhoneValid &&
      !!startDate &&
      !!endDate &&
      !!tariffByPeriod &&
      !!totalPrice;

  // Confirm
  const [loading, setLoading] = useState(false);
  const onConfirmClick = async () => {
    setLoading(true);

    try {
      if (!decline && isFormValid && totalPrice !== null) {
        await updateBooking(booking.id, {
          period: {
            start: startDate.getTime() / 1000,
            end: endDate.getTime() / 1000,
            total_cost: totalPrice
          },
          name,
          phone_number: phone
        });
      }
      await changeBookingStatus(booking.id, {
        confirm: !decline,
        comment: decline ? reasonText : undefined
      });
      close();
      if (onConfirm) onConfirm();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdaptiveModal
      name={`booking-confirm-${booking.id}`}
      isOpen={isOpen}
      close={close}
    >
      <div className={cls.root}>
        <header className={cls.header}>
          <h2 className={cls.title}>
            {t(decline ? 'booking.declineTitle' : 'booking.acceptTitle')}
          </h2>
          <button className={cls.close_btn} type="button" onClick={close}>
            <CloseIcon />
          </button>
        </header>

        <div className={cls.container}>
          <p className={cls.text}>
            {t(decline ? 'booking.declineText' : 'booking.acceptText')}
          </p>

          <MyBookingCard booking={booking} simple />

          <div className={cls.hr} />

          <div className={cls.details}>
            <h3 className={cls.subtitle}>{t('booking.details')}</h3>
            <div className={cls.row}>
              <Input
                value={name}
                onChange={(e) => setName(e.currentTarget.value.trimStart())}
                label={t('contacts.name')}
                disabled={decline}
              />
              <PhoneInput
                code={phoneCode}
                setCode={setPhoneCode}
                value={phone}
                onChange={setPhone}
                disabled={decline}
                label={t('phone')}
                inputMode="numeric"
                setMaskCorrect={setMaskCorrect}
                hideCountries
              />
            </div>
            <div className={cls.row}>
              <div className={cls.date}>
                <button
                  className={cls.date_btn}
                  type="button"
                  onClick={() => setStartOpen(true)}
                  disabled={decline}
                />
                <Select
                  name={startSelect.id}
                  value={startSelect}
                  label={t('booking.dateStart')}
                  options={[]}
                  disabled={decline}
                  readOnly
                />
                <Dropdown
                  name={startSelect.id}
                  isOpen={isStartOpen}
                  close={() => setStartOpen(false)}
                  parentCls={cls.date}
                >
                  <li>
                    <DateTimePicker
                      label={t('booking.dateStart')}
                      date={startDate}
                      maxDate={maxDate}
                      onChange={(d) => {
                        if (d) {
                          setStartDate(d);
                          setStartOpen(false);
                        }
                      }}
                      inline
                    />
                  </li>
                </Dropdown>
              </div>

              <div className={cls.date}>
                <button
                  className={cls.date_btn}
                  type="button"
                  onClick={() => setEndOpen(true)}
                  disabled={decline}
                />
                <Select
                  name={endSelect.id}
                  value={endSelect}
                  label={t('booking.dateEnd')}
                  options={[]}
                  disabled={decline}
                  readOnly
                />
                <Dropdown
                  name={endSelect.id}
                  isOpen={isEndOpen}
                  close={() => setEndOpen(false)}
                  parentCls={cls.date}
                >
                  <li>
                    <DateTimePicker
                      label={t('booking.dateEnd')}
                      date={endDate}
                      minDate={minDate}
                      onChange={(d) => {
                        if (d) {
                          setEndDate(d);
                          setEndOpen(false);
                        }
                      }}
                      inline
                    />
                  </li>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className={cls.hr} />

          {decline ? (
            <div className={cls.reason}>
              <h3 className={cls.subtitle}>{t('rejectReason')}</h3>
              <div>
                <Textarea
                  value={reasonText}
                  onChange={(e) =>
                    setReasonText(
                      maxLength(e.currentTarget.value, MAX_REASON_TEXT_LEN)
                    )
                  }
                  placeholder={t('booking.declineReasonPlaceholder')}
                  minRows={3}
                />
                <small className={cls.counter}>
                  {t('common.slashSeparated', {
                    first: reasonText.length,
                    second: MAX_REASON_TEXT_LEN
                  })}
                </small>
              </div>
            </div>
          ) : (
            <div className={cls.result}>
              <h3 className={cls.subtitle}>{t('booking.total')}</h3>

              {!!booking.about_booking.deposit && (
                <div className={cls.result_row}>
                  <span>{t('booking.securityDeposit')}</span>
                  <div className={cls.result_line} />
                  <span>
                    {t('priceCurrency', {
                      price: booking.about_booking.deposit,
                      currency: CURRENCY_DISPLAY.aed
                    })}
                  </span>
                </div>
              )}

              <div
                className={cn(cls.result_row, {
                  [cls.result_row_open]: isTariffsOpen
                })}
              >
                <span>
                  {t('booking.tariff')}
                  <span className={cls.chevron}>
                    <ChevronIcon />
                  </span>
                </span>
                <div className={cls.result_line} />
                <span>
                  <Trans
                    t={t}
                    i18nKey="rentTariffs.price"
                    components={{ dayWrap: <span /> }}
                    values={{
                      price: numberWithSpaces(
                        datesChanged && tariffByPeriod
                          ? tariffByPeriod.price
                          : booking.about_booking.tariff.price,
                        lang
                      ),
                      currency: CURRENCY_DISPLAY.aed
                    }}
                  />
                </span>
                <button
                  className={cn(cls.result_toggle, {
                    [cls.result_toggle_open]: isTariffsOpen
                  })}
                  type="button"
                  onClick={() => setTariffsOpen((p) => !p)}
                />
              </div>

              <Collapse isOpened={isTariffsOpen}>
                <ul className={cls.tariffs}>
                  {booking.rent_ad.tariffs.map((tf) => (
                    <li key={tf.id}>
                      <div className={cls.result_row}>
                        <span>
                          {tf.days_up_to
                            ? t('rentTariffs.daysPlural.many', {
                                value: t('common.dashSeparated', {
                                  first: tf.days_from,
                                  second: tf.days_up_to
                                })
                              })
                            : t('rentTariffs.daysPlural.many', {
                                value: `${tf.days_from}+`
                              })}
                        </span>
                        <div className={cls.result_line} />
                        <span>
                          {t('priceCurrency', {
                            price: numberWithSpaces(tf.price, lang),
                            currency: CURRENCY_DISPLAY.aed
                          })}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Collapse>

              <div className={cls.result_row}>
                <span>{t('booking.totalDays')}</span>
                <div className={cls.result_line} />
                <span>{daysStr}</span>
              </div>

              <div className={cls.result_row}>
                <span>{t('booking.total')}</span>
                <div className={cls.result_line} />
                <b>
                  {t('priceCurrency', {
                    price: totalPrice,
                    currency: CURRENCY_DISPLAY.aed
                  })}
                </b>
              </div>
            </div>
          )}

          {decline ? (
            <Button
              onClick={onConfirmClick}
              disabled={loading}
              loading={loading}
              color="red"
              fullWidth
            >
              {t('booking.declineAction')}
            </Button>
          ) : (
            <Button
              onClick={onConfirmClick}
              disabled={loading || !isFormValid}
              loading={loading}
              color="green"
              fullWidth
            >
              {t('common.confirm')}
            </Button>
          )}
        </div>
      </div>
    </AdaptiveModal>
  );
}
