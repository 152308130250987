import { create } from 'zustand';

import { PhotoType } from '@/app/types';

interface SignupStore {
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  siteUrl: string;
  setSiteUrl: (siteUrl: string) => void;
  logoUrl: string;
  setLogoUrl: (logoUrl: string) => void;
  coverUrl: string;
  setCoverUrl: (coverUrl: string) => void;
  login: string;
  setLogin: (login: string) => void;
  photos: PhotoType[];
  setPhotos: (photos: PhotoType[]) => void;
  contactName: string;
  setContactName: (contactName: string) => void;
  contactPhone: string;
  setContactPhone: (contactPhone: string) => void;
  langIds: number[];
  setLangIds: (langIds: number[]) => void;
  reset: () => void;
}

export const useSignupStore = create<SignupStore>((set) => ({
  name: '',
  setName: (name: string) => set({ name }),
  description: '',
  setDescription: (description: string) => set({ description }),
  siteUrl: '',
  setSiteUrl: (siteUrl: string) => set({ siteUrl }),
  logoUrl: '',
  setLogoUrl: (logoUrl: string) => set({ logoUrl }),
  coverUrl: '',
  setCoverUrl: (coverUrl: string) => set({ coverUrl }),
  login: '',
  setLogin: (login: string) => set({ login }),
  photos: [],
  setPhotos: (photos: PhotoType[]) => set({ photos }),
  contactName: '',
  setContactName: (contactName: string) => set({ contactName }),
  contactPhone: '',
  setContactPhone: (contactPhone: string) => set({ contactPhone }),
  langIds: [],
  setLangIds: (langIds: number[]) => set({ langIds }),
  reset: () => {
    set({
      name: '',
      description: '',
      siteUrl: '',
      logoUrl: '',
      coverUrl: '',
      login: '',
      photos: [],
      contactName: '',
      contactPhone: '',
      langIds: []
    });
  }
}));
