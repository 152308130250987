import { Photos } from '@/modules/showroom/advert/create/Photos/Photos';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function PhotosWrap() {
  const photos = useUpdateAdvertStore((s) => s.photos);
  const setPhotos = useUpdateAdvertStore((s) => s.setPhotos);
  const addPhotos = useUpdateAdvertStore((s) => s.addPhotos);
  const removePhoto = useUpdateAdvertStore((s) => s.removePhoto);
  const replaceLocalPhoto = useUpdateAdvertStore((s) => s.replaceLocalPhoto);
  const isPhotosFilled = useUpdateAdvertStore((s) => s.isPhotosFilled);
  const setPhotosFilled = useUpdateAdvertStore((s) => s.setPhotosFilled);

  return (
    <Photos
      photos={photos}
      setPhotos={setPhotos}
      addPhotos={addPhotos}
      removePhoto={removePhoto}
      replaceLocalPhoto={replaceLocalPhoto}
      isPhotosFilled={isPhotosFilled}
      setPhotosFilled={setPhotosFilled}
    />
  );
}
