import { useTranslation } from 'react-i18next';

import {
  DeleteIcon,
  PhoneIcon
} from '@/modules/showroom/contact/ContactsList/ContactCard/icons';
import { ShowroomContact } from '@/modules/showroom/contact/types';
import { Avatar } from '@/ui/Avatar/Avatar';
import { Button } from '@/ui/Button/Button';
import { useFormatPhone } from '@/ui/PhoneInput/helpers';
import { minutesToHHMM } from '@/utils/date';
import { removeSpaces } from '@/utils/format';

import cls from './ContactCard.module.scss';

type Props = {
  contact: ShowroomContact;
  onEditClick: (contact: ShowroomContact) => void;
  onDeleteClick: (contact: ShowroomContact) => void;
};

export function ContactCard({ contact, onEditClick, onDeleteClick }: Props) {
  const { t } = useTranslation();
  const { contact_langs } = contact;
  const formattedPhone = useFormatPhone(removeSpaces(contact.phone || ''));

  const callAfter = contact.call_after;
  const callBefore = contact.call_before;
  const hasWorkingTime = callAfter !== null || callBefore !== null;

  return (
    <div className={cls.root}>
      <div className={cls.wrap}>
        <div className={cls.header}>
          <div className={cls.person}>
            <Avatar
              src={contact.avatar_url || ''}
              user={{ id: contact.id, name: contact.name }}
              size={48}
            />
            <div className={cls.name}>
              <h2>{contact.name}</h2>
              {contact.email && <p>{contact.email}</p>}
            </div>
          </div>

          {hasWorkingTime && (
            <div className={cls.work_time}>
              <p>{t('workingTime')}</p>
              <b>
                {callAfter !== null &&
                  t('common.fromTime', {
                    time: minutesToHHMM(callAfter)
                  })}
                {callBefore !== null &&
                  t('common.toTime', {
                    time: minutesToHHMM(callBefore)
                  })}
              </b>
            </div>
          )}

          <div className={cls.actions}>
            <Button
              size="compact"
              variant="secondary"
              color="black"
              onClick={() => onEditClick(contact)}
            >
              {t('common.change')}
            </Button>
            <div className={cls.delete}>
              <Button
                size="compact"
                variant="secondary"
                color="black"
                onClick={() => onDeleteClick(contact)}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        </div>
        <div className={cls.info}>
          <div className={cls.langs}>
            <p>{t('showroom.contacts.speakOn')}</p>
            <b>{contact_langs.map((l) => l.name).join(', ')}</b>
          </div>
          <div className={cls.phone}>
            <PhoneIcon />
            <b>{formattedPhone}</b>
          </div>
        </div>
      </div>
    </div>
  );
}
