import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AddIcon } from '@/modules/showroom/address/AddressSearch/icons';
import { Button } from '@/ui/Button/Button';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

import cls from './AddressSearch.module.scss';

type Props = {
  onAddClick: () => void;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
};

export function AddressSearch({
  onAddClick,
  searchQuery,
  setSearchQuery
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={cls.root}>
      <InputSearch
        value={searchQuery}
        onChange={(v) => setSearchQuery(v)}
        placeholder={t('common.search')}
        showClear
        fullWidth
        compact
      />
      <Button
        onClick={onAddClick}
        size="compact"
        variant="secondary"
        color="black"
      >
        <AddIcon />
        {t('addAddress')}
      </Button>
    </div>
  );
}
