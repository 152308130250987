import { Outlet, useLocation } from 'react-router-dom';

import { getLayoutByPath } from '@/app/router/layouts';
import { useAuthed } from '@/modules/auth/hooks';

export function RootLayout() {
  const [isAuthed] = useAuthed();
  const location = useLocation();
  const Layout = getLayoutByPath(location.pathname, isAuthed);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
