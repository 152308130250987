import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import {
  MAX_MILEAGE_LEN,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { SpecsSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { maxLength, numberWithSpaces, onlyNumbers } from '@/utils/format';

import cls from './Mileage.module.scss';

type Props = {
  mileage: SpecsSlice['mileage'];
  setMileage: SpecsSlice['setMileage'];
  isMileageFilled: SpecsSlice['isMileageFilled'];
  setMileageFilled: SpecsSlice['setMileageFilled'];
  isNewCar: boolean;
};

export function Mileage({
  mileage,
  setMileage,
  isMileageFilled,
  setMileageFilled,
  isNewCar
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const isValid = !!mileage;

  const onClick = () => {
    setMileageFilled(true);
    scrollToSection(SCROLLTO.photo);
  };

  return (
    <div className={cls.root} id={SCROLLTO.mileage}>
      <div className="box">
        <h1 className={cls.title}>{t('mileage')}</h1>
        <p className={cls.desc}>
          {isNewCar ? t('mileageNew') : t('mileageUsed')}
        </p>
        <Input
          value={mileage ? numberWithSpaces(mileage, lang) : ''}
          onChange={(e) =>
            setMileage(
              Number(
                maxLength(onlyNumbers(e.currentTarget.value), MAX_MILEAGE_LEN)
              )
            )
          }
          inputMode="numeric"
          label={t('common.commaSeparated', {
            first: t('mileage'),
            second: t('common.km')
          })}
        />

        {!isMileageFilled && (
          <div style={{ marginTop: 16 }}>
            <Button
              onClick={onClick}
              disabled={!isValid}
              variant="primary"
              color="blue"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
