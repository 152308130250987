import { RefObject, useCallback, useEffect, useState } from 'react';

export function useElementScroll(ref: RefObject<HTMLDivElement>) {
  const [y, setY] = useState(0);

  const onWindowScroll = useCallback(() => {
    const el = ref.current;
    if (el) setY(el.scrollTop);
  }, [ref]);

  useEffect(() => {
    const el = ref.current;
    onWindowScroll();
    el && el.addEventListener('scroll', onWindowScroll);

    if (el) {
      return () => el.removeEventListener('scroll', onWindowScroll);
    }
  }, [onWindowScroll, ref]);

  return y;
}
