import { CommonObj, Nullable, PaginationReq } from '@/app/types';

// Option
export enum ComplectationOptionType {
  single = 'single_select',
  multi = 'multi_select',
  checkbox = 'checkbox'
}

export interface ComplectationOptionItem extends CommonObj {
  is_option_title_needed: boolean;
}

export interface ComplectationOption {
  id: Nullable<number>;
  name: Nullable<string>;
  select_type: ComplectationOptionType;
  items: ComplectationOptionItem[];
}

// Category
export interface ComplectationCategory extends CommonObj {
  options: ComplectationOption[];
}

export interface ComplectationCategoryDetailed extends ComplectationCategory {
  count_items: number;
}

// Complectation
export interface ComplectationType {
  id: Nullable<number>;
  name: Nullable<string>;
}

export interface ComplectationWithOptions extends ComplectationType {
  complectation_options: ComplectationCategoryDetailed[];
}

// Get complectations
export interface GetComplectationsReq extends PaginationReq {
  modification_id: Nullable<number>;
}
export type GetComplectationsRes = ComplectationType[];

// Get options
export interface GetOptionsReq {
  modification_id: Nullable<number>;
}
export type GetOptionsRes = ComplectationCategory[];

// Get options by complectation
export interface GetOptionsByComplectationReq {
  complectation_id: number;
}
export type GetOptionsByComplectationRes = ComplectationCategory[];
