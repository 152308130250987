import { create } from 'zustand';

import { getCountryCodes } from '@/modules/country-codes/api';
import { CountryCodeType } from '@/modules/country-codes/types';
import { LANGS } from '@/modules/i18n';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';
import {
  getFromStorage,
  removeFromStorage,
  saveInStorage
} from '@/utils/local-storage';
import { showAlert } from '@/utils/network';

const CODES_STORE_NAME = 'dealers-autoae-country-codes-list';

type StoreValue = Record<string, CountryCodeType[]>;
const defaultValue = LANGS.reduce((acc, lang) => {
  return { ...acc, [lang]: [] };
}, {});

interface CountryCodesStore {
  data: StoreValue;
  fetchData: () => void;
  reset: () => void;
}

export const useCountryCodesStore = create<CountryCodesStore>((set, get) => ({
  data: getFromStorage(CODES_STORE_NAME) || defaultValue,
  fetchData: async () => {
    const current = get().data;
    if (Object.values(current).every((list) => !!list.length)) return;

    try {
      const promises = LANGS.map((lang) =>
        getCountryCodes(MAX_PG_LIMIT_REQ, { lang })
      );
      const results = await Promise.all(promises);
      const data: StoreValue = results.reduce((acc, res, i) => {
        return { ...acc, [LANGS[i]]: res.data };
      }, {});

      set({ data });
      saveInStorage(CODES_STORE_NAME, data);
    } catch (error) {
      showAlert({ error });
    }
  },
  reset: () => {
    set({ data: defaultValue });
    removeFromStorage(CODES_STORE_NAME);
  }
}));
