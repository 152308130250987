import cn from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';

import cls from './FilterBtn.module.scss';
import { AddIcon, CloseIcon } from './icons';

type Props = {
  text: string;
  onClick: () => void;
  onResetClick?: () => void;
  value?: string;
  active?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
  searchValue?: string;
  searchPlaceholder?: Nullable<string>;
  onSearchChange?: (v: string) => void;
};

export function FilterBtn({
  text,
  onClick,
  onResetClick,
  value,
  active,
  showSearch,
  disabled,
  searchValue,
  searchPlaceholder: searchPlaceholderProp,
  onSearchChange
}: Props) {
  const { t } = useTranslation();
  const showReset = !!value && !!onResetClick;

  const searchPlaceholder =
    searchPlaceholderProp || (t('common.search') as string);
  const searchRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    onClick();

    window.setTimeout(() => {
      if (searchRef.current) searchRef.current.focus();
    }, 0);
  };

  return (
    <div
      className={cn(cls.root, {
        [cls.root_active]: active,
        [cls.root_search_active]: showSearch,
        [cls.root_filled]: !!value,
        [cls.root_reset]: showReset
      })}
    >
      <AddIcon />

      {showSearch ? (
        <input
          ref={searchRef}
          type="text"
          className={cls.search_field}
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={(e) => {
            if (onSearchChange) {
              onSearchChange(e.currentTarget.value);
            }
          }}
        />
      ) : (
        <>
          {text}
          {value && <span className={cls.value}>{value}</span>}
        </>
      )}

      <button
        className={cls.btn}
        onClick={handleClick}
        disabled={disabled}
        type="button"
      />

      {showReset && (
        <button
          className={cls.reset_btn}
          type="button"
          onClick={onResetClick}
          disabled={disabled}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
