import cn from 'classnames';

import { Skeleton } from '@/ui/Skeleton';

import cls from './ChatCard.module.scss';

export function ChatCardSkeleton() {
  return (
    <div className={cn(cls.root, cls.root_skeleton)}>
      <Skeleton width={85} height={64} borderRadius={10} />

      <div className={cls.wrapper}>
        <div className={cls.top}>
          <Skeleton width={120} height={22} />
          <div style={{ marginLeft: 'auto' }}>
            <Skeleton width={50} height={18} />
          </div>
        </div>

        <div className={cls.content}>
          <div className={cls.text}>
            <Skeleton width="80%" height={20} />
            <Skeleton width="80%" height={18} />
          </div>
        </div>
      </div>
    </div>
  );
}
