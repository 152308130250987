import { CommonObj, Nullable } from '@/app/types';
import { EmirateType } from '@/modules/emirate/types';
import { ShowroomAddress } from '@/modules/showroom/address/types';
import { VrpType } from '@/modules/showroom/advert/vrp/types';
import { ShowroomContact } from '@/modules/showroom/contact/types';
import {
  TransportBody,
  TransportBrand,
  TransportModel,
  TransportType
} from '@/modules/transport/types';

export const skeletons = new Array(6).fill(0);

export type FilterType =
  | TransportBrand
  | TransportModel
  | TransportBody
  | TransportType
  | VrpType
  | CommonObj
  | ShowroomAddress
  | ShowroomContact
  | EmirateType;

export interface ListProps {
  list: FilterType[];
  onClick: (v: FilterType) => void;
  activeId: Nullable<number> | Nullable<number[]>;
  showImage?: boolean;
  showArrow?: boolean;
  selectStyle?: boolean;
  multiple?: boolean;
}

export interface Props extends ListProps {
  label?: Nullable<string>;
  search: string;
  loading?: boolean;
  allButtonText?: Nullable<string>;
  onAllClick?: () => void;
}
