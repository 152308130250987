import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/app/api';
import {
  GetAccountReviewsRes,
  GetMyReviewsReq,
  GetMyReviewsRes,
  GetReviewsSortFilters
} from '@/modules/reviews/types';
import { showAlert } from '@/utils/network';

export function useAccountReviews(
  accountId?: number
): [GetAccountReviewsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetAccountReviewsRes>();

  const load = useCallback(async () => {
    if (!accountId) return;
    setLoading(true);

    try {
      const r = await axios.get(`/reviews/${accountId}/list`);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useMyReviews(
  params?: GetMyReviewsReq
): [GetMyReviewsRes | undefined, boolean, () => Promise<void>] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetMyReviewsRes>();

  const load = useCallback(
    async (hideLoading?: boolean) => {
      !hideLoading && setLoading(true);

      try {
        const r = await axios.get(`/reviews/list`, { params });
        setData(r.data);
      } catch (error) {
        showAlert({ error });
      } finally {
        setLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading, load];
}

export function useReviewsSortFilters(): [
  GetReviewsSortFilters | undefined,
  boolean
] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetReviewsSortFilters>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get(`/reviews/sort-filters`);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
