import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Description } from '@/modules/showroom/advert/create/Description/Description';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function DescriptionWrap() {
  const { t } = useTranslation();

  const detail = useRentUpdateAdvertStore((s) => s.detail);
  const storeAdvertId = useRentUpdateAdvertStore((s) => s.advertId);
  const advertId = storeAdvertId || detail?.id;

  const description = useRentUpdateAdvertStore((s) => s.description);
  const setDescription = useRentUpdateAdvertStore((s) => s.setDescription);
  const isDescrGenerating = useRentUpdateAdvertStore(
    (s) => s.isDescrGenerating
  );
  const setDescrGenerating = useRentUpdateAdvertStore(
    (s) => s.setDescrGenerating
  );
  const isDescriptionFilled = useRentUpdateAdvertStore(
    (s) => s.isDescriptionFilled
  );
  const setDescriptionFilled = useRentUpdateAdvertStore(
    (s) => s.setDescriptionFilled
  );

  const saveDetail = useRentUpdateAdvertStore((s) => s.saveDetail);
  const updateAdvert = useCallback(() => {
    if (advertId) return saveDetail(advertId);
    return Promise.resolve();
  }, [advertId, saveDetail]);

  return (
    <Description
      nextSection={SCROLLTO.tariffs}
      placeholder={t('advertisements.description.placeholder')}
      value={description}
      onChange={setDescription}
      generating={isDescrGenerating}
      setGenerating={setDescrGenerating}
      isDescriptionFilled={isDescriptionFilled}
      setDescriptionFilled={setDescriptionFilled}
      advertId={advertId}
      updateAdvert={updateAdvert}
      rent
    />
  );
}
