import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { AddressForm } from '@/modules/showroom/address/AddressForm/AddressForm';
import { AddressList } from '@/modules/showroom/address/AddressList/AddressList';
import { AddressListSkeleton } from '@/modules/showroom/address/AddressList/AddressListSkeleton';
import { AddressSearch } from '@/modules/showroom/address/AddressSearch/AddressSearch';
import {
  createShowroomAddress,
  deleteShowroomAddress,
  updateShowroomAddress,
  useShowroomAddressList
} from '@/modules/showroom/address/api';
import {
  CreateShowroomAddressReq,
  ShowroomAddress
} from '@/modules/showroom/address/types';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { PageHead } from '@/ui/PageHead/PageHead';
import { Placeholder } from '@/ui/Placeholder/Placeholder';
import { Skeleton } from '@/ui/Skeleton';
import { showAlert } from '@/utils/network';

import cls from './AddressPage.module.scss';
import emptyImg from './empty.png';
import emptySearchImg from './emptySearch.png';

export function AddressPage() {
  const { t } = useTranslation();

  const [list, loading, fetchAddressList] = useShowroomAddressList();

  const [searchQuery, setSearchQuery] = useState('');
  const filteredList = useMemo(() => {
    return list
      ? searchQuery
        ? list.filter(
            (addressItem) =>
              addressItem.address
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              addressItem.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : list
      : [];
  }, [list, searchQuery]);

  // Add
  const [isAddLoading, setAddLoading] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);
  const onAddClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
    }

    setAddOpen(true);
  };
  const onAddSubmit = async (values: CreateShowroomAddressReq) => {
    setAddLoading(true);

    try {
      await createShowroomAddress(values);
      fetchAddressList();
      setAddOpen(false);
    } catch (error) {
      showAlert({ error });
    } finally {
      setAddLoading(false);
    }
  };

  // Edit
  const [isEditLoading, setEditLoading] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [editingAddress, setEditingAddress] =
    useState<Nullable<ShowroomAddress>>(null);
  const onEditClick = (address: ShowroomAddress) => {
    setEditingAddress(address);
    setEditOpen(true);
  };
  const onEditSubmit = async (values: CreateShowroomAddressReq) => {
    if (!editingAddress) return;
    setEditLoading(true);

    try {
      await updateShowroomAddress({ ...values, address_id: editingAddress.id });
      fetchAddressList();
      setEditOpen(false);
      setEditingAddress(null);
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditLoading(false);
    }
  };

  // Delete
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deletingAddress, setDeletingAddress] =
    useState<Nullable<ShowroomAddress>>(null);
  const onDeleteClick = (address: ShowroomAddress) => {
    setDeletingAddress(address);
    setDeleteConfirmOpen(true);
  };
  const onDeleteConfirm = async (confirmed: boolean) => {
    if (!deletingAddress) return;
    if (!confirmed) {
      setDeleteConfirmOpen(false);
      setDeletingAddress(null);
      return;
    }

    setDeleteLoading(true);

    try {
      await deleteShowroomAddress(deletingAddress.id);
      fetchAddressList();
      setDeleteConfirmOpen(false);
      setDeletingAddress(null);
    } catch (error) {
      showAlert({ error });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div>
      <LayoutHeader title={t('addresses')} />
      <PageHead title={t('pageTitle', { title: t('addresses') })} />

      {!loading && list && list.length > 0 && (
        <AddressSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onAddClick={onAddClick}
        />
      )}

      {list && list.length <= 0 && !loading && (
        <div className={cls.placeholder}>
          <Placeholder
            imgSrc={emptyImg}
            text={t('noAddresses')}
            onButtonClick={onAddClick}
            buttonText={t('addAddress')}
            to="/"
            buttonColor="green"
            buttonVariant="primary"
            buttonFullWidth
          />
        </div>
      )}

      {list && list.length > 0 && filteredList.length === 0 && (
        <div className={cls.placeholder}>
          <Placeholder
            imgSrc={emptySearchImg}
            title={t('noFindedAddressesTitle')}
            text={t('noFindedAddressesText', { searchQuery })}
          />
        </div>
      )}

      <div className={cls.container}>
        {!list && loading && (
          <>
            <Skeleton
              width="100%"
              height={36}
              style={{ borderRadius: 8, marginBottom: 24 }}
            />
            <AddressListSkeleton />
          </>
        )}

        {filteredList && filteredList.length > 0 && !loading && (
          <AddressList
            list={filteredList}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </div>

      <AddressForm
        isOpen={isAddOpen}
        close={() => setAddOpen(false)}
        buttonText={t('addAddress')}
        onSubmit={onAddSubmit}
        loading={isAddLoading}
      />

      {editingAddress && (
        <AddressForm
          key={editingAddress.id}
          isOpen={isEditOpen}
          close={() => setEditOpen(false)}
          buttonText={t('common.save')}
          onSubmit={onEditSubmit}
          loading={isEditLoading}
          values={{
            ...editingAddress,
            emirate_id: editingAddress.emirate.id,
            phone: editingAddress.phone || ''
          }}
        />
      )}

      {deletingAddress && (
        <ConfirmModal
          name="address-delete"
          isOpen={isDeleteConfirmOpen}
          close={() => setDeleteConfirmOpen(false)}
          title={t('addressDeleteConfirm', {
            address: deletingAddress.address
          })}
          confirmText={t('common.confirm')}
          cancelText={t('common.cancel')}
          onButtonClick={onDeleteConfirm}
          loading={isDeleteLoading}
        />
      )}
    </div>
  );
}
