import { useMemo } from 'react';

import { Nullable } from '@/app/types';
import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useSpecsEffect } from '@/modules/showroom/advert/create/Specs/helpers';
import { useTransportGenerations } from '@/modules/transport/api';
import {
  TransportYear,
  TransportModel,
  TransportGeneration
} from '@/modules/transport/types';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Skeleton } from '@/ui/Skeleton';
import { MAX_PG_LIMIT } from '@/utils/consts';

import cls from './Generation.module.scss';
import { SelectedIcon } from './icons';

const skeletons = new Array(2).fill(0);

type Props = {
  year: TransportYear;
  model: TransportModel;
  selected: Nullable<TransportGeneration>;
  onChange: (g: TransportGeneration) => void;
  disabled?: boolean;
};

export const Generation: React.FC<Props> = ({
  year,
  model,
  selected,
  onChange,
  disabled
}) => {
  const imgPlaceholder = useImgPlaceholder();

  const req = useMemo(
    () => ({
      model_id: model.id,
      year_of_issue_id: year.id,
      limit: MAX_PG_LIMIT
    }),
    [model, year]
  );
  const [generations, isLoading] = useTransportGenerations(req);

  useSpecsEffect(selected, generations, onChange);

  return (
    <div className={cls.root}>
      <div className={cls.list_wrap}>
        {generations && !isLoading && (
          <ul className={cls.list}>
            {generations.map((g) => (
              <li key={g.id}>
                <button
                  className={cls.generation_btn}
                  type="button"
                  onClick={() => onChange(g)}
                  disabled={disabled}
                >
                  <ImgSkeleton
                    className={cls.img}
                    src={g.photo_url || imgPlaceholder}
                    alt=""
                    imgProxyWidth={215}
                    imgProxyHeight={163}
                  />
                  <span className={cls.name}>
                    {g.id === selected?.id ? (
                      <SelectedIcon />
                    ) : (
                      <span className={cls.circle} />
                    )}

                    {g.name}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        )}

        {isLoading && (
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton width="100%" height={130} borderRadius={12} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
