import { Nullable } from '@/app/types';
import { useDarkmode } from '@/modules/theme/useTheme';
import {
  TransportBody,
  TransportBrand,
  TransportGeneration,
  TransportModel,
  TransportModification,
  TransportYear
} from '@/modules/transport/types';

import cls from './Header.module.scss';

type Props = {
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  year: Nullable<TransportYear>;
  body: Nullable<TransportBody>;
  generation: Nullable<TransportGeneration>;
  modification: Nullable<TransportModification>;
};

export function Header({
  brand,
  model,
  year,
  body,
  generation,
  modification
}: Props) {
  const { isDark } = useDarkmode();

  if (!brand) return <></>;

  return (
    <div className={cls.root}>
      <div className={cls.text}>
        <h1>
          {brand.name} {model?.name}
        </h1>

        {year && (
          <ul className={cls.specs}>
            <li>{year.name}</li>
            <li>
              {body?.name} {generation?.name}
            </li>
            <li>{modification?.name}</li>
          </ul>
        )}
      </div>

      <img
        className={cls.img}
        src={isDark ? brand.dark_mode_photo_url : brand.photo_url}
        alt={brand.name}
      />
    </div>
  );
}
