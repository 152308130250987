import cn from 'classnames';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Header } from '@/ui/layouts/SidebarLayout/Header/Header';
import { Sidebar } from '@/ui/layouts/SidebarLayout/Sidebar/Sidebar';

import cls from './SidebarLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

export function SidebarLayout({ children }: Props) {
  const { pathname } = useLocation();

  const isChat = pathname.startsWith('/chat');
  const isWallet = pathname.startsWith('/payment/wallet');
  const isAdverts = pathname.startsWith('/adverts/');
  const isBooking = pathname.startsWith('/booking/');
  const isLeads = pathname.startsWith('/leads');
  const isTariffs = pathname.startsWith('/payment/tariffs');
  const isAddresses = pathname.startsWith('/showroom/address');
  const isContacts = pathname.startsWith('/showroom/contacts');
  const isReviews = pathname.startsWith('/showroom/reviews');
  const isSettings = pathname.startsWith('/settings');
  const isCreditReqs = pathname.startsWith('/credits/requests');
  const isCallback = pathname.startsWith('/callback');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_chat]: isChat,
        [cls.root_adverts]: isAdverts || isBooking || isLeads,
        [cls.root_wallet]: isWallet,
        [cls.root_tariffs]: isTariffs,
        [cls.root_addresses]: isAddresses,
        [cls.root_contacts]: isContacts,
        [cls.root_reviews]: isReviews,
        [cls.root_settings]: isSettings,
        [cls.root_creditreq]: isCreditReqs,
        [cls.root_callback]: isCallback
      })}
    >
      <div className={cls.sidebar}>
        <Sidebar />
      </div>

      <div className={cls.container}>
        <Header />
        <main className={cls.main}>
          <div className={cls.inner}>{children}</div>
        </main>
      </div>
    </div>
  );
}
