import imageCompression from 'browser-image-compression';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Nullable } from '@/app/types';
import { useRefState } from '@/hooks/useRefState';
import { uploadFile } from '@/modules/files/api';
import { UploadRes } from '@/modules/files/types';
import { Cropper } from '@/ui/Cropper/Cropper';
import { showAlert } from '@/utils/network';

import {
  UploadAcceptType,
  ThumbnailType,
  compressOptions,
  MAX_THUMB_SIZE,
  acceptMap,
  getImageFromVideo,
  getImageUrlFromFile,
  handleUploadResponse,
  handleImage
} from './helpers';

export type Props = {
  type: UploadAcceptType;
  onUpload: (
    r: UploadRes,
    f: File,
    thumbnail: Nullable<ThumbnailType>,
    poster: Nullable<ThumbnailType>
  ) => void;
  close: () => void;
  onUploading?: (uploading: boolean) => void;
  videoPoster?: boolean;
  crop?: boolean;
  maxCropSize?: number;
  aspect?: number;
};

export const UploadFile: React.FC<Props> = ({
  type,
  onUpload,
  close,
  videoPoster,
  crop,
  aspect,
  onUploading,
  maxCropSize
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [isCropOpen, setCropOpen] = useState(false);
  const [cropUrl, setCropUrl] = useState('');

  const [isClicked, setClicked, isClickedRef] = useRefState(false);
  const [isUploading, setUploading] = useState(false);

  const onCrop = async (blob: Nullable<Blob>) => {
    if (blob) {
      setUploading(true);

      try {
        const file = new File([blob], 'cropped-uploaded', {
          type: blob.type
        });

        const { response } = await handleImage({
          file
        });
        onUpload(response, file, { r: response, f: file }, null);
      } catch (error) {
        showAlert({ error });
        setCropOpen(false);
        setCropUrl('');
      } finally {
        setUploading(false);
      }
    } else {
      setCropOpen(false);
      setCropUrl('');
      setUploading(false);
      close();
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) return;
    setUploading(true);

    const f = e.target.files ? e.target.files[0] : null;
    e.target.value = '';
    if (!f) {
      close();
      setUploading(false);
      return;
    }

    try {
      if (type === 'image') {
        if (crop) {
          const url = await getImageUrlFromFile(f);

          if (typeof url === 'string') {
            setCropUrl(url);
            setCropOpen(true);
          }
        } else {
          const response = await handleImage({
            file: f
          });

          onUpload(response.response, f, null, null);
        }
      } else if (type === 'video') {
        // Video poster
        let poster = null;
        if (videoPoster) {
          const posterFile = await getImageFromVideo(f);

          if (posterFile) {
            const compressedPoster = await imageCompression(posterFile, {
              ...compressOptions,
              maxWidthOrHeight: MAX_THUMB_SIZE
            });
            const posterResponse = await uploadFile({ file: compressedPoster });
            poster = { r: posterResponse.data, f: compressedPoster };
          }
        }

        // Video
        const { data } = await uploadFile({ file: f }, f.type);
        onUpload({ ...handleUploadResponse(data) }, f, null, poster);
      } else {
        // Files
        const { data } = await uploadFile({ file: f });
        onUpload(data, f, null, null);
      }
    } catch (error) {
      showAlert({ error });

      if (ref && ref.current && !isClicked) {
        ref.current.click();
      }
    } finally {
      setUploading(false);
    }
  };

  useLayoutEffect(() => {
    if (ref && ref.current && !isClickedRef.current) {
      ref.current.click();
      setClicked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onUploading) {
      onUploading(isUploading);
    }

    return () => {
      if (onUploading) onUploading(false);
    };
  }, [onUploading, isUploading]);

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={ref}
        type="file"
        accept={acceptMap[type]}
        onChange={onFileChange}
        disabled={isUploading}
      />

      <Cropper
        name="upload-cropper"
        isOpen={isCropOpen}
        close={() => setCropOpen(false)}
        cropperData={{
          src: cropUrl,
          maxWidth: maxCropSize,
          maxHeight: maxCropSize,
          loading: isUploading,
          aspect
        }}
        onCrop={onCrop}
      />
    </>
  );
};
