import { PaymentTopUp } from '@/modules/payment/PaymentTopUp/PaymentTopUp';

export function PaymentTopUpRoute() {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );

  return (
    <div style={{ maxWidth: 480, margin: '0 auto' }}>
      {clientSecret && <PaymentTopUp clientSecret={clientSecret} showResult />}
    </div>
  );
}
