import ReactSkeleton, { SkeletonProps } from 'react-loading-skeleton';

type Props = SkeletonProps;

export function Skeleton(props: Props) {
  return (
    <ReactSkeleton
      baseColor="var(--thm-grey-1)"
      highlightColor="var(--thm-silver-grey-2)"
      containerClassName="skeleton-container"
      {...props}
    />
  );
}
