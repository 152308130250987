import { useMemo } from 'react';

import { Nullable } from '@/app/types';
import { useCountryCodes } from '@/modules/country-codes/hooks';
import { CountryCodeType } from '@/modules/country-codes/types';
import { Props as InputProps } from '@/ui/Input/Input';
import { onlyNumbers, removeSpaces } from '@/utils/format';

export const MAX_PHONE_LENGTH = 16;

export function handleStr(str: string): string {
  const v = str.trimStart();
  return onlyNumbers(v, '+ ');
}

export function handlePlusAtStart(str: string): string {
  let result = str;
  while (result.startsWith('++')) {
    result = result.slice(1);
  }

  return result;
}

export function handlePlusAtEnd(str: string): string {
  let result = str;
  while (result.endsWith('+')) {
    result = result.slice(0, result.length - 1);
  }

  return result;
}

export function formatByMask(str: string, code: string, mask: string): string {
  let maskIndex = 0;
  const initial = code + ' ';
  let formattedValue = initial;

  for (let i = initial.length - 1; i < str.length; i++) {
    if (mask[maskIndex] === ' ' && str[i] !== ' ') {
      formattedValue += ' ';
      maskIndex++;
    }
    if (mask[maskIndex] === '#' && /\d/.test(str[i])) {
      formattedValue += str[i];
      maskIndex++;
    }
  }

  return formattedValue;
}

export interface Props extends Omit<InputProps, 'onChange' | 'ref'> {
  code: Nullable<CountryCodeType>;
  setCode: React.Dispatch<React.SetStateAction<Nullable<CountryCodeType>>>;
  onChange: (v: string) => void;
  hideCountries?: boolean;
  setMaskCorrect?: (v: boolean) => void;
}

export function searchCountryCode(codes: CountryCodeType[], search: string) {
  const withoutSpaces = removeSpaces(search);

  const RUSSIAN_CODE_ID = 166;
  const isRussia = withoutSpaces.startsWith('+7');

  if (isRussia) {
    return codes.find((cd) => cd.id === RUSSIAN_CODE_ID);
  }

  return codes.find((cd) => withoutSpaces.startsWith(cd.code));
}

export function useFormatPhone(phone: string) {
  const phoneWithPlus = phone.startsWith('+') ? phone : `+${phone}`;

  const codes = useCountryCodes();
  const code = useMemo(
    () => findCountryCodeByPhone(codes || [], phoneWithPlus),
    [codes, phoneWithPlus]
  );

  const formatted = code
    ? formatByMask(phoneWithPlus, code.code, code.mask)
    : phone;
  return formatted;
}

function findCountryCodeByPhone(
  codes: CountryCodeType[],
  phone: string
): CountryCodeType | null {
  let matchingCountry = null;
  let maxMatchLength = 0;

  for (const country of codes) {
    const countryCode = country.code;
    if (phone.startsWith(countryCode)) {
      const matchLength = countryCode.length;
      if (matchLength > maxMatchLength) {
        maxMatchLength = matchLength;
        matchingCountry = country;
      }
    }
  }

  return matchingCountry;
}

export function validatePhoneInput(
  code: Nullable<CountryCodeType>,
  phone: string,
  isMaskCorrect: boolean,
  hideCountries?: boolean
) {
  const noCountries =
    typeof hideCountries === 'boolean' ? hideCountries : false;

  const valid =
    (noCountries ||
      (!!code &&
        removeSpaces(phone).length >=
          removeSpaces(code.mask).length + removeSpaces(code.code).length)) &&
    !!phone &&
    !!onlyNumbers(phone).length &&
    Number(onlyNumbers(phone)) !== 0 &&
    isMaskCorrect &&
    removeSpaces(phone).length <= MAX_PHONE_LENGTH;

  return valid;
}
