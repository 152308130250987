import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useComplectationsOptions } from '@/modules/showroom/advert/complectation/api';
import { TransportModification } from '@/modules/transport/types';
import { Button } from '@/ui/Button/Button';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { Spinner } from '@/ui/Spinner/Spinner';

import { RemoveIcon, CloseIcon } from './icons';
import { OptionsCategory } from './OptionsCategory';
import cls from './OptionsModal.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
  initial: number[];
  onSave: (selectedIds: number[]) => void;
  onReset: () => void;
  modification: Nullable<TransportModification>;
};

export function OptionsModal({
  isOpen,
  close,
  initial,
  onSave,
  onReset,
  modification
}: Props) {
  const { t } = useTranslation();

  const req = useMemo(
    () => ({
      modification_id: modification?.id || null
    }),
    [modification?.id]
  );
  const [allCategories, loading] = useComplectationsOptions(req);
  const [selectedOptions, setSelectedOptions] = useState<number[]>(initial);

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions(initial);
    }
  }, [initial, isOpen]);

  const toggleOption = (id: number) => {
    setSelectedOptions((prev) => {
      if (prev.includes(id)) return prev.filter((v) => v !== id);
      return [...prev, id];
    });
  };

  // Handlers
  const [resetKey, setResetKey] = useState(0);
  const onResetClick = () => {
    setSelectedOptions([]);
    setResetKey((p) => p + 1);
    onReset();
    close();
  };
  const onSaveClick = () => {
    onSave(selectedOptions);
  };

  // Select handlers
  const onSelectOptionAdd = (id: number, idsToRemove?: number[]) => {
    setSelectedOptions((prev) => {
      const tmp =
        idsToRemove && idsToRemove.length > 0
          ? prev.filter((v) => !idsToRemove.includes(v))
          : prev;
      return [...tmp, id];
    });
  };

  const onSelectOptionRemove = (id: number) => {
    setSelectedOptions((prev) => prev.filter((v) => v !== id));
  };

  const onSelectOptionClear = (ids: number[]) => {
    setSelectedOptions((prev) => prev.filter((v) => !ids.includes(v)));
  };

  return (
    <AdaptiveModal
      name="options"
      isOpen={isOpen}
      close={close}
      rootClass={cls.modal_root}
      containerClass={cn(cls.modal_container, cls.modal_container_imp)}
    >
      <div className={cls.root}>
        <div className={cls.header}>
          <h1 className={cls.title}>{t('complectation.options')}</h1>
          <button className={cls.close_btn} type="button" onClick={close}>
            <CloseIcon />
          </button>
        </div>

        {loading && (
          <div className={cls.spinner}>
            <Spinner color="var(--clr-blue)" />
          </div>
        )}

        {!loading && allCategories && (
          <>
            {allCategories.map((c, i) => (
              <OptionsCategory
                key={resetKey + c.id}
                category={c}
                isLast={i === allCategories.length - 1}
                selectedOptions={selectedOptions}
                onSelectOptionAdd={onSelectOptionAdd}
                onSelectOptionRemove={onSelectOptionRemove}
                onSelectClear={onSelectOptionClear}
                toggleOption={toggleOption}
              />
            ))}

            <div className={cls.bottom}>
              <button
                className={cls.reset_btn}
                type="button"
                onClick={onResetClick}
                style={{
                  visibility:
                    selectedOptions && selectedOptions.length > 0
                      ? 'visible'
                      : 'hidden'
                }}
              >
                <RemoveIcon />
                {t('common.resetAll')}
              </button>

              <Button onClick={onSaveClick} color="blue">
                {t('common.save')}
              </Button>
            </div>
          </>
        )}
      </div>
    </AdaptiveModal>
  );
}
