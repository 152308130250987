export function EditIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.14478 16.6415L15.3001 6.49038L13.5654 4.74834L3.40274 14.8995L2.52295 17.0224C2.4163 17.293 2.70173 17.5819 2.95932 17.4785L5.14478 16.6415ZM16.1654 5.63988L17.1434 4.67838C17.6287 4.19303 17.6558 3.65524 17.2108 3.20857L16.8579 2.85409C16.4203 2.41483 15.8792 2.45424 15.3923 2.93056L14.4159 3.89784L16.1654 5.63988Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49976 13.4999L4.5831 10.5833C4.26093 10.2611 3.7386 10.2611 3.41643 10.5833C3.09426 10.9054 3.09426 11.4278 3.41643 11.7499L6.91051 15.244C7.23594 15.5694 7.76358 15.5694 8.08902 15.244L16.9164 6.41659C17.2386 6.09443 17.2386 5.57209 16.9164 5.24993C16.5943 4.92776 16.0719 4.92776 15.7498 5.24993L7.49976 13.4999Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33203 9.99966C3.33203 10.4889 3.73607 10.8879 4.22024 10.8879H9.11191V15.7795C9.11191 16.2623 9.50941 16.6663 9.99868 16.6663C10.4879 16.6663 10.8934 16.2623 10.8934 15.7795V10.8879H15.7785C16.2613 10.8879 16.6654 10.4889 16.6654 9.99966C16.6654 9.51039 16.2613 9.10492 15.7785 9.10492H10.8934V4.22122C10.8934 3.73704 10.4879 3.33301 9.99868 3.33301C9.50941 3.33301 9.11191 3.73704 9.11191 4.22122V9.10492H4.22024C3.73607 9.10492 3.33203 9.51039 3.33203 9.99966Z"
        fill="currentColor"
      />
    </svg>
  );
}
