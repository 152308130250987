import { Nav } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/Nav';
// import { Onboarding } from '@/ui/layouts/SidebarLayout/Sidebar/Onboarding/Onboarding';
import { User } from '@/ui/layouts/SidebarLayout/Sidebar/User/User';

import cls from './Sidebar.module.scss';

export function Sidebar() {
  return (
    <div className={cls.root}>
      <User />
      <div className={cls.container}>
        <Nav />
        {/* <div className={cls.onboarding}>
          <Onboarding />
        </div> */}
      </div>
    </div>
  );
}
