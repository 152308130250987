import { CreateShowroom } from '@/modules/showroom/CreateShowroom/CreateShowroom';

import cls from './CreateShowroom.module.scss';

export function CreateShowroomPage() {
  return (
    <div className="container">
      <div className={cls.root}>
        <CreateShowroom />
      </div>
    </div>
  );
}
