import { useMemo } from 'react';

import { Nullable } from '@/app/types';
import { skeletons } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { TransportGeneration } from '@/modules/transport/types';
import { GenerationItem } from '@/ui/GenerationSelect/GenerationItem';
import { Skeleton } from '@/ui/Skeleton';

import cls from './FiltersList.module.scss';

type FilterType = TransportGeneration;

type Props = {
  label: string;
  selected: FilterType[];
  list: FilterType[];
  onClick: (v: FilterType) => void;
  loading?: boolean;
  allButtonText?: Nullable<string>;
  onAllClick?: () => void;
};

export function GenFiltersList({
  label,
  selected,
  list,
  onClick,
  loading,
  allButtonText,
  onAllClick
}: Props) {
  const selectedIds = useMemo(() => selected.map((v) => v.id), [selected]);

  return (
    <div>
      <b className={cls.label}>{label}</b>

      {loading && (
        <>
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton
                  width="100%"
                  height={36}
                  style={{ borderRadius: 8, marginBottom: 4 }}
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {!loading && (
        <>
          {allButtonText && onAllClick && (
            <button className={cls.row} type="button" onClick={onAllClick}>
              {allButtonText}
            </button>
          )}

          <ul className={cls.generations}>
            {list.map((v) => (
              <li key={v.id}>
                <GenerationItem
                  generation={v}
                  isActive={selectedIds.includes(v.id)}
                  onClick={onClick}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
