/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { Nullable } from '@/app/types';
import { useLayoutHeaderStore } from '@/ui/layouts/SidebarLayout/store';

type Props = {
  title: Nullable<string>;
  buttonText?: Nullable<string>;
  onButtonClick?: () => void;
};

export function LayoutHeader({ title, buttonText, onButtonClick }: Props) {
  const setHeader = useLayoutHeaderStore((s) => s.setHeader);
  const resetHeader = useLayoutHeaderStore((s) => s.resetHeader);

  useEffect(() => {
    setHeader(title, buttonText, onButtonClick);

    return () => {
      resetHeader();
    };
  }, [title]);

  return null;
}
