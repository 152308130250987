export function PlayIcon(): JSX.Element {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.18531 0.631111V7.20997C1.18531 7.2365 1.18158 7.4075 1.20269 7.44647C1.29238 7.61202 1.552 7.83212 2.01491 7.60961C2.4793 7.38638 5.38684 5.64168 7.0658 4.62618C7.27839 4.4976 7.40728 4.26986 7.40728 4.02429C7.40728 3.7748 7.27438 3.54414 7.05654 3.41654C5.37617 2.43228 2.47845 0.728812 2.01491 0.431725C1.51715 0.112708 1.25445 0.431725 1.18531 0.631111Z"
        fill="currentColor"
      />
    </svg>
  );
}
