export function SparklesIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.86559 4.25542C7.94816 4.25542 7.99722 4.20149 8.0102 4.12486C8.18085 3.16693 8.16519 3.12904 9.19438 2.94171C9.27208 2.92282 9.32495 2.8759 9.32495 2.79227C9.32495 2.71351 9.27102 2.66065 9.19332 2.6477C8.16519 2.47106 8.17978 2.43102 8.0102 1.4656C7.99722 1.38792 7.94816 1.33398 7.86559 1.33398C7.78196 1.33398 7.73398 1.38792 7.72104 1.46774C7.55354 2.42247 7.56708 2.46036 6.5368 2.6477C6.45317 2.66172 6.40625 2.71351 6.40625 2.79227C6.40625 2.8759 6.45317 2.92282 6.54001 2.94171C7.56387 3.12262 7.55033 3.16266 7.72104 4.1238C7.73398 4.20149 7.78196 4.25542 7.86559 4.25542Z"
        fill="currentColor"
      />
      <path
        d="M4.98555 8.40491C5.10803 8.40491 5.197 8.31915 5.21102 8.2005C5.42164 6.62746 5.46761 6.62209 7.10586 6.31372C7.21859 6.29377 7.30329 6.21073 7.30329 6.08826C7.30329 5.96958 7.21752 5.88061 7.10372 5.86766C5.47082 5.63923 5.41677 5.58898 5.21102 3.98577C5.197 3.86116 5.1091 3.77539 4.98555 3.77539C4.86581 3.77539 4.77791 3.86116 4.76009 3.99278C4.57109 5.56367 4.49269 5.56308 2.86525 5.86766C2.75145 5.88762 2.66675 5.96958 2.66675 6.08826C2.66675 6.21774 2.75145 6.29377 2.89114 6.31372C4.50029 6.56867 4.57109 6.61342 4.76009 8.18859C4.77791 8.31915 4.86581 8.40491 4.98555 8.40491Z"
        fill="currentColor"
      />
      <path
        d="M9.09267 14.9896C9.27594 14.9896 9.4134 14.8565 9.44629 14.6672C9.87229 11.4536 10.3243 10.9654 13.4933 10.6122C13.6885 10.5912 13.8249 10.4467 13.8249 10.2575C13.8249 10.0752 13.6906 9.93186 13.4943 9.90764C10.3264 9.54165 9.88725 9.06729 9.44629 5.84878C9.41127 5.65954 9.27488 5.53125 9.09267 5.53125C8.91046 5.53125 8.773 5.65954 8.74497 5.84878C8.31791 9.06729 7.8621 9.55553 4.69691 9.90764C4.49687 9.92867 4.36157 10.0731 4.36157 10.2575C4.36157 10.4457 4.4958 10.589 4.69585 10.6122C7.85511 11.0339 8.28344 11.4536 8.74497 14.6672C8.77407 14.8565 8.91152 14.9896 9.09267 14.9896Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_41999_821)">
        <path
          d="M2.20801 7.98906C2.20801 8.19187 2.29879 8.3939 2.45348 8.54218L6.46082 12.5417C6.62254 12.697 6.80832 12.7736 6.99629 12.7736C7.41737 12.7736 7.72378 12.468 7.72378 12.0573C7.72378 11.8497 7.64519 11.6645 7.5055 11.5327L6.36831 10.3677L3.92877 8.16421L3.69253 8.59296L5.90987 8.73859H13.0345C13.4833 8.73859 13.7917 8.42937 13.7917 7.98906C13.7917 7.54875 13.4833 7.23953 13.0345 7.23953H5.90987L3.69253 7.38516L3.92877 7.82031L6.36831 5.61046L7.5055 4.44405C7.64519 4.31218 7.72378 4.12703 7.72378 3.91937C7.72378 3.50875 7.41737 3.20312 6.99629 3.20312C6.80832 3.20312 6.62254 3.27969 6.46082 3.435L2.45348 7.43594C2.29879 7.58422 2.20801 7.78624 2.20801 7.98906Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_41999_821">
          <rect
            width="11.5837"
            height="9.59373"
            fill="white"
            transform="translate(2.20801 3.20312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BackIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57223 14.0856C11.8985 14.0856 14.6667 11.325 14.6667 7.99873C14.6667 4.67245 11.8985 1.91187 8.57223 1.91187C5.24596 1.91187 2.48537 4.66491 2.48537 7.99119H3.63938C3.63938 5.24569 5.82673 3.06588 8.57223 3.06588C11.3177 3.06588 13.5126 5.25323 13.5126 7.99873C13.5126 10.7443 11.3177 12.9542 8.57223 12.9391C6.95812 12.9316 5.53257 12.1773 4.64255 10.9932C4.4389 10.7141 4.11456 10.6311 3.83549 10.8046C3.55642 10.9705 3.48853 11.3477 3.71481 11.6418C4.85374 13.1126 6.61116 14.0856 8.57223 14.0856ZM1.79899 6.82209C1.32381 6.82209 1.21067 7.14642 1.46712 7.50846L2.8097 9.41677C3.02089 9.71091 3.33768 9.71091 3.54133 9.41677L4.88391 7.50092C5.13281 7.14642 5.01213 6.82209 4.54449 6.82209H1.79899Z"
        fill="currentColor"
      />
    </svg>
  );
}
