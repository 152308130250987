import { Address } from '@/modules/showroom/advert/create/Address/Address';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function AddressWrap() {
  const addressId = useUpdateAdvertStore((s) => s.addressId);
  const setAddressId = useUpdateAdvertStore((s) => s.setAddressId);
  const isAddressFilled = useUpdateAdvertStore((s) => s.isAddressFilled);
  const setAddressFilled = useUpdateAdvertStore((s) => s.setAddressFilled);

  return (
    <Address
      addressId={addressId}
      setAddressId={setAddressId}
      isAddressFilled={isAddressFilled}
      setAddressFilled={setAddressFilled}
    />
  );
}
