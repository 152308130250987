import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { ApiError, Nullable } from '@/app/types';
import { GetSortFiltersRes } from '@/modules/showroom/advert/my/types';
import { RentAdvertAuthorDetail } from '@/modules/showroom/advert/rent/detail/types';
import {
  CreateRentAdvertRes,
  GetRentSortFiltersReq
} from '@/modules/showroom/advert/rent/types';
import {
  UpdateRentAdvertReq,
  UpdateRentAdvertRes
} from '@/modules/showroom/advert/rent/update/types';
import { showAlert } from '@/utils/network';

// Rent sort
export function getRentSortFilters(params?: GetRentSortFiltersReq) {
  return axios.get<GetSortFiltersRes>(
    '/advertisements/rent/sort_filters/list',
    {
      params
    }
  );
}

export function useRentSortFilters(
  params?: GetRentSortFiltersReq
): [GetSortFiltersRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetSortFiltersRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getRentSortFilters(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Create
export function createRentAdvertisement(category_id: number) {
  return axios.post<CreateRentAdvertRes>('/advertisements/rent/create', {
    category_id
  });
}

// Update
export function updateRentAdvertisement(id: number, req: UpdateRentAdvertReq) {
  return axios.patch<UpdateRentAdvertRes>(
    `/advertisements/rent/${id}/update`,
    req
  );
}

// Delete
export function deleteRentAdvertisement(id: number) {
  return axios.delete<unknown>(`/advertisements/rent/${id}/delete`);
}

// Author detail
export function getRentAuthorDetail(id: number) {
  return axios.get<RentAdvertAuthorDetail>(
    `/advertisements/rent/my/${id}/detail`
  );
}

export function useRentAuthorDetail(
  id: Nullable<number>
): [RentAdvertAuthorDetail | undefined, boolean, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<RentAdvertAuthorDetail>();
  const [notFound, setNotFound] = useState(false);

  const load = useCallback(async () => {
    if (!id) return;
    setLoading(true);

    try {
      const r = await getRentAuthorDetail(id);
      setNotFound(false);
      setData(r.data);
    } catch (error) {
      showAlert({ error });

      const e = error as AxiosError<ApiError>;
      const notFoundCode = 420;
      const notFoundErr = e && e.response?.data?.code === notFoundCode;
      setNotFound(notFoundErr);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading, notFound];
}
