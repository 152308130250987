export function EditIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.14478 16.6415L15.3001 6.49042L13.5654 4.74837L3.40274 14.8995L2.52295 17.0224C2.4163 17.293 2.70173 17.5819 2.95932 17.4785L5.14478 16.6415ZM16.1654 5.63991L17.1434 4.67841C17.6287 4.19306 17.6558 3.65527 17.2108 3.2086L16.8579 2.85412C16.4203 2.41486 15.8792 2.45427 15.3923 2.93059L14.4159 3.89787L16.1654 5.63991Z"
        fill="currentColor"
      />
    </svg>
  );
}
