export function CloseIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 23.1411C5.84372 23.6157 6.66308 23.6096 7.12564 23.1471L14.0021 16.2706L20.8746 23.1451C21.3452 23.6157 22.1637 23.6229 22.6403 23.1391C23.117 22.6533 23.119 21.8531 22.6484 21.3805L15.7759 14.4968L22.6484 7.62435C23.119 7.15375 23.1262 6.34444 22.6403 5.8678C22.1545 5.38199 21.3452 5.37998 20.8746 5.85975L14.0021 12.7322L7.12564 5.85774C6.66308 5.38803 5.83455 5.37081 5.35791 5.86579C4.88328 6.3516 4.88932 7.15978 5.35187 7.62234L12.2284 14.4968L5.35187 21.3845C4.88932 21.845 4.87411 22.6644 5.35791 23.1411Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RemoveIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59194 19.4071C5.00834 19.8139 5.71066 19.8087 6.10714 19.4122L12.0013 13.5181L17.892 19.4105C18.2954 19.8139 18.9969 19.82 19.4054 19.4053C19.814 18.9889 19.8157 18.3031 19.4123 17.898L13.5216 11.9977L19.4123 6.10701C19.8157 5.70364 19.8219 5.00994 19.4054 4.60139C18.989 4.18498 18.2954 4.18326 17.892 4.59449L12.0013 10.4852L6.10714 4.59277C5.71066 4.19016 5.00049 4.1754 4.59194 4.59967C4.18511 5.01607 4.19028 5.7088 4.58676 6.10528L10.4809 11.9977L4.58676 17.9014C4.19028 18.2962 4.17725 18.9985 4.59194 19.4071Z"
        fill="currentColor"
      />
    </svg>
  );
}
