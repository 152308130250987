import { CommonObj, Nullable, PhotoType } from '@/app/types';
import { AccountType } from '@/modules/accounts/types';
import { CategoryType } from '@/modules/category/types';
import { EmirateType } from '@/modules/emirate/types';
import { EmojiType } from '@/modules/emojis/types';
import { RegSpec } from '@/modules/regional-specs/types';
import { ComplectationWithOptions } from '@/modules/showroom/advert/complectation/types';
import { VrpPlateCode, VrpType } from '@/modules/showroom/advert/vrp/types';
import {
  TransportBody,
  TransportBrand,
  TransportColor,
  TransportDriveUnit,
  TransportEngine,
  TransportGeneration,
  TransportModel,
  TransportModification,
  TransportOwner,
  TransportTransmission,
  TransportType,
  TransportYear
} from '@/modules/transport/types';

export enum AdvertStatusType {
  moderation = 'on_moderation',
  rejected = 'rejected',
  published = 'published',
  sold = 'sold',
  draft = 'draft',
  removed = 'removed_from_publication'
}

export interface AdvertStatus extends CommonObj {
  title: AdvertStatusType;
}

export interface AdvertExistedStatus {
  status: AdvertStatusType;
  title: string;
  count: number;
}

export type GetExistedStatusesRes = AdvertExistedStatus[];

// Detail
export interface AdvertDetail {
  id: number;
  category: CategoryType;
  status: AdvertStatus;
  count_view: number;
  count_view_today: number;
  in_favorites: boolean;
  author_id: number;
  is_my: boolean;
  count_calls: number;
  count_add_to_favorites: number;
  url: string;
  description: Nullable<string>;
  owner: Nullable<TransportOwner>;
  call_before: Nullable<number>;
  call_after: Nullable<number>;
  name: Nullable<string>;
  address: Nullable<string>;
  email: Nullable<string>;
  emirate: Nullable<EmirateType>;
  lat: Nullable<number>;
  long: Nullable<number>;
  snapshot_url: Nullable<string>;
  available_chat: Nullable<boolean>;
  available_phone: Nullable<boolean>;
  available_trade: Nullable<boolean>;
  price: Nullable<number>;
  published_at: Nullable<number>;
  price_is_increase: Nullable<boolean>;
  note: Nullable<string>;
  author_avatar_url: Nullable<string>;
  account_type: AccountType;
  chat_id: Nullable<number>;
  compare_item_id: Nullable<number>;
  stickers: Nullable<EmojiType[]>;
  description_language_code: Nullable<string>;
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  generation: Nullable<TransportGeneration>;
  year_of_issue: Nullable<TransportYear>;
  body: Nullable<TransportBody>;
  modification: Nullable<TransportModification>;
  type: Nullable<TransportType>;
  engine: Nullable<TransportEngine>;
  drive_unit: Nullable<TransportDriveUnit>;
  transmission: Nullable<TransportTransmission>;
  colour: Nullable<TransportColor>;
  mileage: Nullable<number>;
  is_broken: Nullable<boolean>;
  photos: Nullable<PhotoType[]>;
  vin: Nullable<string>;
  regional_spec: Nullable<RegSpec>;
  complectation: Nullable<ComplectationWithOptions>;
  detailed_characteristics_available: Nullable<boolean>;
  vrp_plate_number: Nullable<string>;
  vrp_image_url: Nullable<string>;
  vrp_type: Nullable<VrpType>;
  vrp_plate_code: Nullable<VrpPlateCode>;
  vrp_plate_emirate: Nullable<EmirateType>;
}

// Count ads by status
export interface CountAdsByStatus {
  status: AdvertStatus;
  count_ads: number;
}
export type GetCountAdsByStatusRes = CountAdsByStatus[];
