import { create } from 'zustand';

import { ClientCommunicationRes } from '@/modules/client-comm/types';
import { CountryCodeType } from '@/modules/country-codes/types';
import { RefreshWeekDay } from '@/modules/refresh/types';
import { BREAKPOINT } from '@/utils/consts';

interface CommonStore {
  windowWidth: number;
  setWindowWidth: (windowWidth: number) => void;
  callbackCountKey: number;
  setCallbackCountKey: (callbackCountKey: number) => void;
  countryCodes: CountryCodeType[];
  setCountryCodes: (countryCodes: CountryCodeType[]) => void;
  weekDays: RefreshWeekDay[];
  setWeekDays: (weekDays: RefreshWeekDay[]) => void;
  refreshPrice: number;
  setRefreshPrice: (refreshPrice: number) => void;
  clientCommunication: ClientCommunicationRes | undefined;
  setClientCommunication: (
    clientCommunication: ClientCommunicationRes | undefined
  ) => void;
}

export const useCommonStore = create<CommonStore>((set) => ({
  windowWidth: window.innerWidth,
  setWindowWidth: (windowWidth: number) => set({ windowWidth }),
  callbackCountKey: 0,
  setCallbackCountKey: (callbackCountKey: number) => set({ callbackCountKey }),
  countryCodes: [],
  setCountryCodes: (countryCodes: CountryCodeType[]) => set({ countryCodes }),
  weekDays: [],
  setWeekDays: (weekDays) => set({ weekDays }),
  refreshPrice: 0,
  setRefreshPrice: (refreshPrice) => set({ refreshPrice }),
  clientCommunication: undefined,
  setClientCommunication: (
    clientCommunication: ClientCommunicationRes | undefined
  ) => set({ clientCommunication })
}));

export function useDesktop(): boolean {
  const windowWidth = useCommonStore((state) => state.windowWidth);
  return windowWidth > BREAKPOINT;
}
