import { create } from 'zustand';

import { Nullable } from '@/app/types';

interface ChatUtilsStore {
  isChatOpen: boolean;
  setChatOpen: (isChatOpen: boolean) => void;
  modalChatId: Nullable<number>;
  setModalChatId: (modalChatId: Nullable<number>) => void;
  scrollToChatMsgId: Nullable<number>;
  setScrollToChatMsgId: (scrollToChatMsgId: Nullable<number>) => void;
  initialChatText: string;
  setInitialChatText: (initialChatText: string) => void;
  unreadCount: number;
  setUnreadCount: (unreadCount: number) => void;
  unreadCountUpdateKey: number;
  updateUnreadCountUpdateKey: () => void;
  chatResetKey: number;
  updateChatResetKey: () => void;
}

export const useChatUtilsStore = create<ChatUtilsStore>((set, get) => ({
  isChatOpen: false,
  setChatOpen: (isChatOpen: boolean) => set({ isChatOpen }),
  modalChatId: null,
  setModalChatId: (modalChatId: Nullable<number>) => set({ modalChatId }),
  scrollToChatMsgId: null,
  setScrollToChatMsgId: (scrollToChatMsgId: Nullable<number>) =>
    set({ scrollToChatMsgId }),
  initialChatText: '',
  setInitialChatText: (initialChatText: string) => set({ initialChatText }),
  unreadCount: 0,
  setUnreadCount: (unreadCount: number) => set({ unreadCount }),
  unreadCountUpdateKey: 0,
  updateUnreadCountUpdateKey: () =>
    set({ unreadCountUpdateKey: get().unreadCountUpdateKey + 1 }),
  chatResetKey: 0,
  updateChatResetKey: () => set({ chatResetKey: get().chatResetKey + 1 })
}));
