import {
  AdvertStatusType,
  CountAdsByStatus
} from '@/modules/showroom/advert/types';
import { CHARTS_COLORS } from '@/utils/consts';

enum CategoryType {
  active = 'active',
  inactive = 'inactive',
  sold = 'sold'
}

const COLORS: Record<CategoryType, string> = {
  [CategoryType.active]: CHARTS_COLORS.cars,
  [CategoryType.inactive]: CHARTS_COLORS.vrp,
  [CategoryType.sold]: 'var(--clr-orange)'
};

type MappedValue = {
  count: number;
  percent: number;
  color: string;
  label: string;
};

export function mapCountAdsByStatus(
  list: CountAdsByStatus[],
  total: number
): MappedValue[] {
  const map: Record<CategoryType, CountAdsByStatus[]> = {
    [CategoryType.active]: [],
    [CategoryType.inactive]: [],
    [CategoryType.sold]: []
  };

  list.forEach((v) => {
    if (v.status.title === AdvertStatusType.published) {
      map[CategoryType.active].push(v);
    } else if (v.status.title === AdvertStatusType.sold) {
      map[CategoryType.sold].push(v);
    } else {
      map[CategoryType.inactive].push(v);
    }
  });

  const categoriesMap: Record<string, MappedValue> = {};
  Object.keys(map).forEach((k) => {
    const list = map[k as CategoryType];
    const count = list.reduce((acc, cur) => acc + cur.count_ads, 0);
    categoriesMap[k] = {
      count,
      percent: (count / total) * 100,
      color: COLORS[k as CategoryType],
      label: `advertisements.statusType.${k}`
    };
  });

  return Object.values(categoriesMap);
}
