import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import cls from '@/modules/showroom/advert/create/Condition/Condition.module.scss';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { Button } from '@/ui/Button/Button';
import { Switch } from '@/ui/Switch/Switch';

type Props = {
  isBookingAvailable: RentUpdateAdvertStore['isBookingAvailable'];
  setBookingAvailable: RentUpdateAdvertStore['setBookingAvailable'];
  isFilled: RentUpdateAdvertStore['isBookingAvailableFilled'];
  setFilled: RentUpdateAdvertStore['setBookingAvailableFilled'];
};

export function AvailableBooking({
  isBookingAvailable,
  setBookingAvailable,
  isFilled,
  setFilled
}: Props) {
  const { t } = useTranslation();
  const [touched, setTouched] = useState(false);

  const onClick = () => {
    setFilled(true);
    scrollToSection(SCROLLTO.phone);
  };

  return (
    <div className={cls.root} id={SCROLLTO.booking}>
      <div className="box">
        <h1 className={cls.title}>{t('booking.title')}</h1>
        <div className={cls.row}>
          <button
            className={cls.toggle_btn}
            onClick={() => {
              setTouched(true);
              setBookingAvailable(!isBookingAvailable);
            }}
            type="button"
          />

          <div>
            <p className={cls.label}>{t('booking.toggle.title')}</p>
            <p className={cls.subtitle}>{t('booking.toggle.text')}</p>
          </div>

          <Switch
            id="booking-available-switch"
            checked={!!isBookingAvailable}
            onChange={(e) => {
              setTouched(true);
              setBookingAvailable(e.currentTarget.checked);
            }}
            green
          />
        </div>

        {!isFilled && (
          <>
            {touched ? (
              <Button
                onClick={onClick}
                variant="primary"
                color="blue"
                fullWidth
              >
                {t('common.continue')}
              </Button>
            ) : (
              <Button
                onClick={onClick}
                variant="secondary"
                color="black"
                fullWidth
              >
                {t('common.skip')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
