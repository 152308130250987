export function ImgIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.2422 33.0921L32.2376 23.7048C31.4945 23.0153 30.6024 22.6667 29.6995 22.6667C28.7737 22.6667 27.9432 22.9846 27.1723 23.6739L19.5448 30.491L16.4294 27.6797C15.7201 27.0409 14.9402 26.72 14.1494 26.72C13.3682 26.72 12.6693 27.0241 11.9738 27.6628L5.60003 33.3752C5.68312 36.4868 7.13047 38.1104 9.92656 38.1104H37.3079C40.6961 38.1104 42.385 36.3622 42.2422 33.0921ZM9.54017 39.5298H38.4597C42.1188 39.5298 44 37.6625 44 34.0589V13.4908C44 9.884 42.1188 8.00598 38.4597 8.00598H9.54017C5.89491 8.00598 4 9.884 4 13.4908V34.0589C4 37.6625 5.89491 39.5298 9.54017 39.5298ZM9.73201 36.2209C8.1617 36.2209 7.30881 35.408 7.30881 33.767V13.7795C7.30881 12.1386 8.1617 11.3148 9.73201 11.3148H38.2679C39.8244 11.3148 40.6911 12.1386 40.6911 13.7795V33.767C40.6911 35.408 39.8244 36.2209 38.2679 36.2209H9.73201Z"
        fill="currentColor"
      />
      <path
        d="M17.0837 24.0745C19.2958 24.0745 21.1194 22.2508 21.1194 20.0188C21.1194 17.8067 19.2958 15.9662 17.0837 15.9662C14.8517 15.9662 13.0281 17.8067 13.0281 20.0188C13.0281 22.2508 14.8517 24.0745 17.0837 24.0745Z"
        fill="currentColor"
      />
    </svg>
  );
}
