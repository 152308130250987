import { create } from 'zustand';

import { axios } from '@/app/api';
import { LANGS } from '@/modules/i18n';
import {
  GetRentPayMethodsRes,
  RentPayMethod
} from '@/modules/showroom/advert/rent/pay-methods/types';
import {
  getFromStorage,
  removeFromStorage,
  saveInStorage
} from '@/utils/local-storage';
import { showAlert } from '@/utils/network';

const PAY_METHODS_STORE_NAME = 'dealers-autoae-paymethods-list';

type StoreValue = Record<string, RentPayMethod[]>;
const defaultValue = LANGS.reduce((acc, lang) => {
  return { ...acc, [lang]: [] };
}, {});

interface RentPayMethodsStore {
  data: StoreValue;
  fetchData: () => void;
  reset: () => void;
}

export const useRentPayMethodsStore = create<RentPayMethodsStore>(
  (set, get) => ({
    data: getFromStorage(PAY_METHODS_STORE_NAME) || defaultValue,
    fetchData: async () => {
      const current = get().data;
      if (Object.values(current).every((list) => !!list.length)) return;

      try {
        const promises = LANGS.map((lang) =>
          axios.get<GetRentPayMethodsRes>('/advertisements/rent/pay-methods', {
            headers: { lang }
          })
        );
        const results = await Promise.all(promises);
        const data: StoreValue = results.reduce((acc, res, i) => {
          return { ...acc, [LANGS[i]]: res.data };
        }, {});

        set({ data });
        saveInStorage(PAY_METHODS_STORE_NAME, data);
      } catch (error) {
        showAlert({ error });
      }
    },
    reset: () => {
      set({ data: defaultValue });
      removeFromStorage(PAY_METHODS_STORE_NAME);
    }
  })
);
