import { axios } from '@/app/api';
import {
  DeleteNotificationsBody,
  GetNotificationsRes,
  SetUpNotificationsBody
} from '@/modules/notifications/types';

const PATH = '/dealers/accounts/notifications';

export function getNotifications() {
  return axios.get<GetNotificationsRes>(PATH);
}

export function deleteNotifications(body: DeleteNotificationsBody) {
  return axios.delete<unknown>(PATH, { data: body });
}

export function setUpNotifications(body: SetUpNotificationsBody) {
  return axios.patch<unknown>(PATH, body);
}
