import cn from 'classnames';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { CountryCodeType } from '@/modules/country-codes/types';
import { PhoneSendCodeChannel } from '@/modules/showroom/contact/types';
import { Button } from '@/ui/Button/Button';
import { LottieNoLoop } from '@/ui/LottieNoLoop';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { CloseIcon } from '@/ui/PhoneConfirm/PhoneCodeConfirm/icons';
import { PhoneCodeConfirm } from '@/ui/PhoneConfirm/PhoneCodeConfirm/PhoneCodeConfirm';
import { validatePhoneInput } from '@/ui/PhoneInput/helpers';
import { PhoneInput } from '@/ui/PhoneInput/PhoneInput';
import { onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

import phoneAnim from './phone.json';
import cls from './PhoneCodeConfirm.module.scss';

type Props = {
  oldPhone: string;
  onConfirm: (phone: string) => void;
  confirmPhoneSendCode: (
    phone: string,
    channel?: PhoneSendCodeChannel
  ) => Promise<unknown>;
  confirmPhone: (phone: string, code: string) => Promise<unknown>;
  isEditPhoneOpen: boolean;
  setEditPhoneOpen: Dispatch<SetStateAction<boolean>>;
};

export function EditPhone({
  oldPhone,
  onConfirm,
  confirmPhoneSendCode,
  confirmPhone,
  isEditPhoneOpen,
  setEditPhoneOpen
}: Props) {
  const { t } = useTranslation();

  const [phone, setPhone] = useState(oldPhone);

  const [isPhoneConfirmOpen, setPhoneConfirmOpen] = useState(false);
  const [isPhoneConfirmed, setPhoneConfirmed] = useState(false);

  // Phone
  const [code, setCode] = useState<Nullable<CountryCodeType>>(null);
  const [isMaskCorrect, setMaskCorrect] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  useEffect(() => {
    setIsPhoneValid(validatePhoneInput(code, phone, isMaskCorrect));
  }, [code, isMaskCorrect, phone, setIsPhoneValid]);

  const [isCodeSending, setCodeSending] = useState(false);

  const sendCode = async () => {
    setCodeSending(true);

    try {
      const phoneValue = onlyNumbers(phone, '+');
      await confirmPhoneSendCode(phoneValue, PhoneSendCodeChannel.sms);
      setPhoneConfirmOpen(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setCodeSending(false);
    }
  };

  const handleCofirmModalClose = () => {
    if (isPhoneConfirmed) onConfirm(phone);

    setEditPhoneOpen(false);
  };

  return (
    <AdaptiveModal
      name="edit-phone-modal"
      isOpen={isEditPhoneOpen}
      close={handleCofirmModalClose}
      containerClass={cn(cls.modal_container, cls.modal_container_imp)}
    >
      {isPhoneConfirmOpen && (
        <PhoneCodeConfirm
          phone={phone}
          confirmPhone={confirmPhone}
          confirmPhoneSendCode={confirmPhoneSendCode}
          close={handleCofirmModalClose}
          isPhoneConfirmed={isPhoneConfirmed}
          setPhoneConfirmed={setPhoneConfirmed}
          isEdit
        />
      )}

      {!isPhoneConfirmOpen && (
        <div className={cls.root}>
          <div className={cls.icon}>
            <LottieNoLoop animationData={phoneAnim} />
          </div>

          <h1 className={cls.title}>{t('changePhoneNumber')}</h1>

          <h2 className={cls.code_title}>{t('auth.changePhoneText')}</h2>

          <div className={cls.fields}>
            <PhoneInput
              code={code}
              setCode={setCode}
              value={phone}
              onChange={setPhone}
              label={t('phone')}
              inputMode="numeric"
              setMaskCorrect={setMaskCorrect}
              hideCountries
            />
          </div>

          <Button
            color="green"
            disabled={!isPhoneValid}
            loading={isCodeSending}
            onClick={sendCode}
            fullWidth
          >
            {t('common.continue')}
          </Button>

          <button className={cls.close_btn} onClick={handleCofirmModalClose}>
            <CloseIcon />
          </button>
        </div>
      )}
    </AdaptiveModal>
  );
}
