import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { AGE_MAX_LEN } from '@/modules/showroom/advert/rent/update/consts';
import { isAgeValid } from '@/modules/showroom/advert/rent/update/helpers';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { maxLength, numberWithSpaces, onlyNumbers } from '@/utils/format';

import cls from './Age.module.scss';

type Props = {
  age: RentUpdateAdvertStore['age'];
  setAge: RentUpdateAdvertStore['setAge'];
  driveExp: RentUpdateAdvertStore['driveExp'];
  setDriveExp: RentUpdateAdvertStore['setDriveExp'];
  isAgeFilled: RentUpdateAdvertStore['isAgeFilled'];
  setAgeFilled: RentUpdateAdvertStore['setAgeFilled'];
};

export function Age({
  age,
  setAge,
  driveExp,
  setDriveExp,
  isAgeFilled,
  setAgeFilled
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const isValid = isAgeValid(age, driveExp);

  const onContinueClick = () => {
    setAgeFilled(true);
    scrollToSection(SCROLLTO.payMethods, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.age}>
      <div className="box">
        <h1 className={cls.title}>{t('age.title')}</h1>
        <p className={cls.subtitle}>{t('age.subtitle')}</p>

        <div className={cls.row}>
          <Input
            value={age ? numberWithSpaces(age, lang) : ''}
            onChange={(e) =>
              setAge(
                Number(
                  onlyNumbers(maxLength(e.currentTarget.value, AGE_MAX_LEN))
                )
              )
            }
            label={t('age.label')}
          />
          <Input
            value={driveExp ? numberWithSpaces(driveExp, lang) : ''}
            onChange={(e) =>
              setDriveExp(
                Number(
                  onlyNumbers(maxLength(e.currentTarget.value, AGE_MAX_LEN))
                )
              )
            }
            label={t('age.drivingExpLabel')}
          />
        </div>

        <div className={cls.buttons}>
          {!isAgeFilled && (
            <Button
              onClick={onContinueClick}
              disabled={!isValid}
              color="blue"
              variant="primary"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
