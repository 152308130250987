import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTrackBackground, Range } from 'react-range';

import { Nullable } from '@/app/types';
import { useRouteUnload } from '@/hooks/useRouteUnload';
import { updateCreditForm } from '@/modules/credit/api';
import { useCreditForm } from '@/modules/credit/hooks';
import { Button } from '@/ui/Button/Button';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';
import { Input } from '@/ui/Input/Input';
import { Spinner } from '@/ui/Spinner/Spinner';
import { Switch } from '@/ui/Switch/Switch';
import { Textarea } from '@/ui/Textarea/Textarea';
import { formatEmail, maxLength, onlyNumbers, pluralKey } from '@/utils/format';
import { showAlert } from '@/utils/network';
import { MAX_EMAIL_LENGTH, validateEmail } from '@/utils/validate';

import cls from './CreditForm.module.scss';
import { AddIcon, DeleteEmailIcon } from './icons';

const MIN_RATE = 1;
const MIN_DURATION = 1;
const MAX_DURATION = 25;
const MAX_INFO_LEN = 1000;
const MAX_EMAILS_LEN = 10;
const FIRST_PAY_VALUES = [0, 10, 15, 20, 25, 30];

type Props = {
  onFormSaved?: () => void;
};

export const CreditForm: React.FC<Props> = ({ onFormSaved }) => {
  const { t } = useTranslation();
  const [data, loading] = useCreditForm();

  const [enabled, setEnabled] = useState(false);

  const [yearRateTouched, setYearRateTouched] = useState<boolean>(false);
  const [yearRate, setYearRate] = useState<number>(MIN_RATE);
  const rateValid = yearRate >= MIN_RATE && yearRate <= 100;
  const showRateError = yearRateTouched && !rateValid;

  const [firstPay, setFirstPay] = useState<number>(FIRST_PAY_VALUES[0]);
  const [duration, setDuration] = useState<number>(MIN_DURATION);
  const [info, setInfo] = useState<string>('');

  const [emails, setEmails] = useState<string[]>([]);
  const emailsValid = emails.length > 0 && emails.every(validateEmail);
  const updateEmail = (v: string, i: number) => {
    setEmails((prev) => {
      if (!v && emails.length > 1) return prev.filter((_, j) => i !== j);
      return prev.map((e, j) => (i === j ? v : e));
    });
  };
  const addEmail = () => {
    if (emails.length < MAX_EMAILS_LEN) {
      setEmails((p) => [...p, '']);
    }
  };
  const deleteEmail = (index: number) => {
    setEmails((prev) => [...prev].filter((v, i) => i !== index));
  };

  useEffect(() => {
    if (data) {
      setEnabled(!!data.credits_available);
      setFirstPay(data.first_pay || FIRST_PAY_VALUES[0]);
      setYearRate(data.year_rate || MIN_RATE);
      setYearRateTouched(false);
      setDuration(data.credit_term || MIN_DURATION);
      setInfo(data.additional_information || '');
      setEmails(data.emails || []);
    }
  }, [data]);

  const [updating, setUpdating] = useState(false);
  const isValid = rateValid && emailsValid;

  const newValues = useMemo(
    () => ({
      credits_available: enabled,
      first_pay: firstPay,
      year_rate: yearRate,
      credit_term: duration,
      additional_information: info,
      emails
    }),
    [duration, emails, enabled, firstPay, info, yearRate]
  );

  const submitCreditForm = async () => {
    await updateCreditForm(newValues);
  };

  const saveForm = async () => {
    if (!isValid) return;
    setUpdating(true);

    try {
      await submitCreditForm();
      setFormSubmitted(true);
      showAlert({ type: 'success', text: t('common.saved') });
      onFormSaved?.();
    } catch (error) {
      showAlert({ error });
    } finally {
      setUpdating(false);
    }
  };

  const toggleEnabled = () => setEnabled(!enabled);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [initialValues, setInitialValues] =
    useState<Nullable<Record<string, unknown>>>(null);

  const getInitialValues = () => {
    if (!data) return;

    const values = {
      additional_information: data.additional_information || info,
      first_pay: data.first_pay || firstPay,
      year_rate: data.year_rate || yearRate,
      credit_term: data.credit_term || duration,
      emails: data.emails || emails,
      credits_available: data.credits_available || enabled
    };

    setInitialValues({ ...values });
  };

  useEffect(() => {
    if (!data || initialValues) return;

    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initialValues]);

  useEffect(() => {
    setFormSubmitted(false);
  }, [info, firstPay, yearRate, duration, emails, enabled]);

  useRouteUnload(
    initialValues && !formSubmitted
      ? !isEqual(initialValues, newValues)
      : false,
    t('leaveWarn')
  );

  return (
    <>
      {!data && loading && <Spinner color="var(--clr-blue)" centered />}

      {!loading && (
        <div
          className="box"
          style={{
            borderRadius: 16,
            boxShadow: 'var(--shadow-primary)',
            maxWidth: 600
          }}
        >
          <div className={cls.switch}>
            <div className={cls.switch_text}>
              <b>{t('credits.switchTitle')}</b>
              <p>{t('credits.toggleText')}</p>
            </div>
            <Switch id="credit-form-toggle" checked={enabled} green />
            <button onClick={toggleEnabled} type="button" />
          </div>

          {enabled && (
            <>
              <div className={cls.hr} />
              <b className={cls.label}>{t('credits.firstPay')}</b>
              <ul className={cls.chips}>
                {FIRST_PAY_VALUES.map((v) => (
                  <li key={v}>
                    <CheckBtn
                      onClick={() => setFirstPay(v)}
                      active={v === firstPay}
                      disabled={updating}
                    >
                      {v}%
                    </CheckBtn>
                  </li>
                ))}
              </ul>
              <div className={cls.hr} />
              <b className={cls.label}>{t('credits.yearRate')}</b>
              <Input
                label={t('common.fromValue', { value: '' })}
                value={yearRate.toString()}
                onChange={(e) =>
                  setYearRate(
                    Number(maxLength(onlyNumbers(e.currentTarget.value), 3))
                  )
                }
                suffix="%"
                onBlur={() => setYearRateTouched(true)}
                errorText={showRateError ? t('credits.rateError') : ''}
              />
              <div className={cls.hr} />
              <b className={cls.label}>{t('credits.duration')}</b>
              <div className={cls.range}>
                <Range
                  values={[duration]}
                  step={1}
                  min={1}
                  max={MAX_DURATION}
                  onChange={(values) => setDuration(values[0])}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style
                      }}
                      className={cls.range_track}
                    >
                      <div
                        ref={props.ref}
                        style={{
                          background: getTrackBackground({
                            values: [duration],
                            colors: ['var(--clr-blue)', 'var(--thm-grey-1)'],
                            min: 1,
                            max: 25
                          })
                        }}
                        className={cls.range_track_inner}
                      >
                        {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style
                      }}
                      className={cls.range_thumb}
                    >
                      <div
                        className={cls.range_thumb_inner}
                        style={{
                          left: duration === MIN_DURATION ? 0 : 'auto',
                          right: duration === MAX_DURATION ? 0 : 'auto'
                        }}
                      >
                        {pluralKey(
                          duration,
                          t('credits.yearPlural.one', {
                            value: duration
                          }),
                          t('credits.yearPlural.few', {
                            value: duration
                          }),
                          t('credits.yearPlural.many', {
                            value: duration
                          })
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>

              <div className={cls.hr} />
              <b className={cls.label}>{t('credits.info')}</b>
              <div className={cls.textarea}>
                <Textarea
                  value={info}
                  onChange={(e) =>
                    setInfo(maxLength(e.currentTarget.value, MAX_INFO_LEN))
                  }
                  minRows={3}
                />
                <small>
                  {t('common.slashSeparated', {
                    first: info.length,
                    second: MAX_INFO_LEN
                  })}
                </small>
              </div>
              <b className={cls.label}>{t('credits.email')}</b>
              <ul className={cls.emails}>
                {emails.map((em, i) => (
                  <li key={i}>
                    <Input
                      label={t('auth.email')}
                      value={em}
                      onChange={(e) =>
                        updateEmail(formatEmail(e.currentTarget.value), i)
                      }
                      maxLength={MAX_EMAIL_LENGTH}
                    />

                    {i !== 0 && (
                      <Button
                        onClick={() => deleteEmail(i)}
                        variant="secondary"
                        color="black"
                      >
                        <DeleteEmailIcon />
                      </Button>
                    )}
                  </li>
                ))}
              </ul>
              <div className={cls.add_email}>
                <Button
                  variant="outline"
                  color="black"
                  size="compact"
                  gap={8}
                  disabled={updating || emails.length >= MAX_EMAILS_LEN}
                  onClick={addEmail}
                >
                  <AddIcon />
                  {t('credits.addEmail')}
                </Button>
              </div>
            </>
          )}

          <div className={cls.btn}>
            <Button
              color="green"
              onClick={saveForm}
              disabled={updating || loading || !isValid}
              loading={updating || loading}
              fullWidth
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
