import {
  ChatMessageContent,
  ChatMessageContentType
} from '@/modules/chat/types';
import { UploadItem } from '@/modules/files/upload/helpers';

export const MAX_ATTACH_IMG_FILE_SIZE_MB = 5;

export function mapUploadItems(items: UploadItem[]): ChatMessageContent[] {
  return items.map((v) => {
    const { response, file } = v;
    const isVideo = !file.type.startsWith('image');
    const thumb = isVideo ? v.poster?.r.url : v.thumbnail?.r.url;

    const { url, extension, size, name } = response;

    return {
      type: isVideo
        ? ChatMessageContentType.video
        : ChatMessageContentType.image,
      url,
      extension,
      size,
      name,
      upload_date: Date.now() / 1000,
      thumbnail_url: thumb || null,
      duration: v.duration || null,
      coords: null
    };
  });
}
