import { StateCreator } from 'zustand';

import { Nullable } from '@/app/types';
import {
  TransportBody,
  TransportColor,
  TransportDriveUnit,
  TransportEngine,
  TransportGeneration,
  TransportModification,
  TransportTransmission,
  TransportYear
} from '@/modules/transport/types';

import { CreateAdvertStore, SpecStep, SpecsSlice } from './types';

export const makeSpecsSlice: StateCreator<
  CreateAdvertStore,
  [],
  [],
  SpecsSlice
> = (set) => ({
  year: null,
  setYear: (year: Nullable<TransportYear>) => set({ year }),
  generation: null,
  setGeneration: (generation: Nullable<TransportGeneration>) =>
    set({ generation }),
  body: null,
  setBody: (body: Nullable<TransportBody>) => set({ body }),
  engine: null,
  setEngine: (engine: Nullable<TransportEngine>) => set({ engine }),
  drive: null,
  setDrive: (drive: Nullable<TransportDriveUnit>) => set({ drive }),
  transmission: null,
  setTransmission: (transmission: Nullable<TransportTransmission>) =>
    set({ transmission }),
  modification: null,
  setModification: (modification: Nullable<TransportModification>) =>
    set({ modification }),
  color: null,
  setColor: (color: Nullable<TransportColor>) => set({ color }),
  mileage: 0,
  setMileage: (mileage: number) => set({ mileage }),
  isMileageFilled: false,
  setMileageFilled: (isMileageFilled: boolean) => set({ isMileageFilled }),
  isSpecsFilled: false,
  setSpecsFilled: (isSpecsFilled: boolean) => set({ isSpecsFilled }),
  step: SpecStep.year,
  setStep: (step: Nullable<SpecStep>) => set({ step })
});
