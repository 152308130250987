import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { EmojiType } from '@/modules/emojis/types';
import { MAX_STICKERS_LEN } from '@/modules/showroom/advert/create/helpers';
import { Stickers } from '@/modules/showroom/advert/create/Stickers/Stickers';
import { useAdvertPromoUpdate } from '@/modules/showroom/advert/hooks';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { Button } from '@/ui/Button/Button';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './StickersModal.module.scss';

import { CloseIcon } from '../icons';

type Props = {
  isOpen: boolean;
  close: () => void;
  advert: MyAdvert;
  stickersList: EmojiType[];
  onPublishToggle?: () => void;
};

export function StickersModal({
  isOpen,
  close,
  advert,
  stickersList,
  onPublishToggle
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const initialIds = advert.stickers ? advert.stickers.map((s) => s.id) : [];
  const [selected, setSelected] = useState<number[]>(initialIds);
  const stickerPrice = stickersList[0].price;

  const newStickersIds = selected.filter((sId) => !initialIds.includes(sId));
  const totalPrice = stickerPrice * newStickersIds.length;

  useEffect(() => {
    const ids = advert.stickers ? advert.stickers.map((s) => s.id) : [];
    setSelected(ids);
  }, [advert.stickers]);

  const isSameSelected =
    initialIds.length === selected.length &&
    selected.every((s) => initialIds.includes(s));

  const buttonText =
    totalPrice <= 0
      ? t('common.save')
      : t('advertisements.promo.connectPrice', {
          price: t('priceCurrency', {
            price: numberWithSpaces(totalPrice || 0, lang),
            currency: CURRENCY_DISPLAY.aed
          })
        });

  // Save
  const { updatePromoStickers } = useAdvertPromoUpdate();
  const hasChanges = !isSameSelected;
  const [loading, setLoading] = useState(false);
  const onSubmitClick = async () => {
    setLoading(true);

    try {
      await updatePromoStickers(advert.id, { stickers_ids: selected });
      if (onPublishToggle) onPublishToggle();
      close();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdaptiveModal
      name={`${advert.id}-stickers-modal`}
      isOpen={isOpen}
      close={close}
    >
      <div className={cls.root}>
        <header className={cls.header}>
          <h2 className={cls.title}>
            <Trans
              t={t}
              i18nKey="stickers.titleShort"
              components={{
                countWrap: <span />
              }}
              values={{ index: selected.length, count: MAX_STICKERS_LEN }}
            />
          </h2>
          <button className={cls.close_btn} type="button" onClick={close}>
            <CloseIcon />
          </button>
        </header>
        <Stickers
          stickersIds={selected}
          setStickersIds={setSelected}
          skeletonCount={15}
          contentOnly
        />
        <div className={cls.btn}>
          <Button
            onClick={onSubmitClick}
            loading={loading}
            disabled={loading || !hasChanges}
            color="green"
            fullWidth
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </AdaptiveModal>
  );
}
