import { createContext } from 'react';

interface MyAdvertCardContextType {
  isPublished: boolean;
  onEditPriceClick: () => void;
}

export const MyAdvertCardContext = createContext<MyAdvertCardContextType>({
  isPublished: false,
  onEditPriceClick: () => null
});
