import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { useLang } from '@/hooks/useLang';
import { Input } from '@/ui/Input/Input';
import { maxLength, numberWithSpaces, onlyNumbers } from '@/utils/format';

import cls from './FiltersList.module.scss';

function handleValue(
  e: React.FormEvent<HTMLInputElement>,
  maxLen: number
): number {
  return Number(maxLength(onlyNumbers(e.currentTarget.value), maxLen));
}

type Props = {
  label: string;
  maxLength: number;
  from: Nullable<number>;
  to: Nullable<number>;
  setFrom: (v: Nullable<number>) => void;
  setTo: (v: Nullable<number>) => void;
};

export function FilterRange({
  label,
  maxLength,
  from,
  to,
  setFrom,
  setTo
}: Props) {
  const [locale] = useLang();
  const { t } = useTranslation();

  const debouncedFrom = useDebounce(from, 1200);
  const debouncedTo = useDebounce(to, 1200);

  // Swap from/to
  useEffect(() => {
    if (
      typeof debouncedFrom === 'number' &&
      typeof debouncedTo === 'number' &&
      debouncedTo < debouncedFrom
    ) {
      const newFrom = debouncedTo;
      const newTo = debouncedFrom;
      setFrom(newFrom);
      setTo(newTo);
      return;
    }

    if (typeof debouncedFrom === 'number') {
      const v = debouncedFrom;
      setFrom(v);
    }

    if (typeof debouncedTo === 'number') {
      const v = debouncedTo;
      setTo(v);
    }
  }, [debouncedFrom, debouncedTo, setFrom, setTo]);

  const handleFrom = (v: number) => {
    setFrom(v);
  };

  const handleTo = (v: number) => {
    setTo(v);
  };

  return (
    <div className={cls.root_range}>
      <b className={cls.label}>{label}</b>

      <div className={cls.fields}>
        <Input
          value={from ? numberWithSpaces(from, locale) : ''}
          onChange={(e) => handleFrom(handleValue(e, maxLength))}
          placeholder={t('from')}
          inputMode="numeric"
          small
        />
        <Input
          value={to ? numberWithSpaces(to, locale) : ''}
          onChange={(e) => handleTo(handleValue(e, maxLength))}
          placeholder={t('to')}
          inputMode="numeric"
          small
        />
      </div>
    </div>
  );
}
