export function ReplyIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.313 4.00622L2.73885 10.2151C2.55482 10.3889 2.54653 10.679 2.72034 10.8631C2.72633 10.8694 2.7325 10.8756 2.73885 10.8816L9.313 17.0905C9.49703 17.2643 9.78711 17.256 9.96091 17.072C10.0413 16.9869 10.086 16.8743 10.086 16.7573V13.5611C14.5197 13.5611 17.8593 14.4649 20.2488 17.0679C20.2855 17.1078 20.3453 17.1781 20.4282 17.2786L20.4283 17.2785C20.5571 17.4347 20.7881 17.4569 20.9443 17.3281C21.0532 17.2382 21.1009 17.0938 21.0668 16.9567C21.0299 16.8086 21.0034 16.7068 20.9874 16.6515C19.6839 12.1501 16.4757 8.34297 10.086 7.43169V4.33944C10.086 4.08631 9.88083 3.8811 9.6277 3.8811C9.51067 3.8811 9.39808 3.92587 9.313 4.00622Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function EditIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0057 5.74988L16.2498 8.99396L8.34548 16.8993C7.63666 17.608 6.76455 18.1318 5.80585 18.4244L3.14523 19.2367C2.98364 19.286 2.81266 19.195 2.76333 19.0334C2.74556 18.9752 2.74556 18.913 2.76333 18.8548L3.5755 16.1944C3.86822 15.2356 4.3921 14.3633 5.10105 13.6544L13.0057 5.74988ZM17.5442 3.15685L18.8442 4.45693C19.3477 4.9603 19.3792 5.75694 18.9387 6.297L18.8442 6.40143L17.2223 8.02229L13.9773 4.77729L15.5996 3.15681C16.1366 2.61992 17.0072 2.61993 17.5442 3.15685Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DeleteIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4165 5.5L16.382 18.0631C16.2841 19.2518 15.2908 20.1667 14.098 20.1667H7.90165C6.70892 20.1667 5.7156 19.2518 5.61771 18.0631L4.58317 5.5H17.4165ZM10.9998 7.33333C10.7467 7.33333 10.5415 7.53854 10.5415 7.79167V17.4167C10.5415 17.6698 10.7467 17.875 10.9998 17.875C11.253 17.875 11.4582 17.6698 11.4582 17.4167V7.79167C11.4582 7.53854 11.253 7.33333 10.9998 7.33333ZM13.7498 7.33333C13.4935 7.33333 13.2827 7.53519 13.2716 7.79123L12.8531 17.4171L12.8527 17.4362C12.8527 17.6785 13.0491 17.875 13.2915 17.875C13.5478 17.875 13.7586 17.6731 13.7697 17.4171L14.1883 7.79123L14.1887 7.77217C14.1887 7.52981 13.9922 7.33333 13.7498 7.33333ZM8.24984 7.33333L8.23078 7.33375C7.98864 7.34427 7.80089 7.5491 7.81141 7.79123L8.22993 17.4171C8.24106 17.6731 8.45188 17.875 8.70817 17.875C8.71453 17.875 8.72088 17.8749 8.72723 17.8746C8.96937 17.8641 9.15712 17.6592 9.1466 17.4171L8.72808 7.79123C8.71695 7.53519 8.50613 7.33333 8.24984 7.33333ZM11.9165 1.375C12.8563 1.375 13.664 1.9407 14.0176 2.75016L17.4165 2.75C17.9228 2.75 18.3332 3.16041 18.3332 3.66667V4.35417C18.3332 4.48073 18.2306 4.58333 18.104 4.58333H3.89567C3.76911 4.58333 3.6665 4.48073 3.6665 4.35417V3.66667C3.6665 3.16041 4.07691 2.75 4.58317 2.75L7.98212 2.75016C8.33571 1.9407 9.14338 1.375 10.0832 1.375H11.9165Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AddQuickReplyIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99924 18.3327C14.5644 18.3327 18.3333 14.5583 18.3333 9.99935C18.3333 5.43383 14.5578 1.66602 9.99263 1.66602C5.43556 1.66602 1.66663 5.43383 1.66663 9.99935C1.66663 14.5583 5.44218 18.3327 9.99924 18.3327ZM9.99924 16.6911C6.28611 16.6911 3.31465 13.7128 3.31465 9.99935C3.31465 6.2859 6.2795 3.30757 9.99263 3.30757C13.7057 3.30757 16.6919 6.2859 16.6919 9.99935C16.6919 13.7128 13.7123 16.6911 9.99924 16.6911Z"
        fill="currentColor"
      />
      <path
        d="M6.50293 10.5385C6.50293 10.7533 6.67588 10.9101 6.89868 10.9101H9.55055L8.13547 14.6886C7.93316 15.2296 8.51074 15.5323 8.86989 15.0925L13.2193 9.67857C13.3109 9.57882 13.3593 9.46007 13.3593 9.34937C13.3593 9.13465 13.1864 8.97638 12.9636 8.97638H10.3117L11.7268 5.19789C11.9291 4.65834 11.3515 4.35565 10.9924 4.78742L6.64297 10.2027C6.55133 10.3091 6.50293 10.4264 6.50293 10.5385Z"
        fill="currentColor"
      />
    </svg>
  );
}
