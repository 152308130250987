import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';

import cls from './Actions.module.scss';
import { EditIcon, ImageIcon, CropIcon, DeleteIcon } from './icons';

type Props = {
  onUploadClick: () => void;
  onDeleteClick: () => void;
  onPreviewClick: () => void;
};

export function Actions({
  onUploadClick,
  onDeleteClick,
  onPreviewClick
}: Props) {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  return (
    <div className={cls.root}>
      <div className={cls.wrap}>
        <button
          className={cls.open_btn}
          type="button"
          onClick={() => setOpen(true)}
        >
          <EditIcon />
          {t('common.change')}
        </button>

        <Dropdown
          name="showroom-cover-actions"
          isOpen={isOpen}
          close={() => setOpen(false)}
          parentCls={cls.wrap}
        >
          <DropdownItem>
            <button
              className={cls.dropdown_btn}
              type="button"
              onClick={() => {
                setOpen(false);
                onUploadClick();
              }}
            >
              {t('cover.uploadImage')}
              <ImageIcon />
            </button>
          </DropdownItem>
          <DropdownItem>
            <button
              className={cls.dropdown_btn}
              type="button"
              onClick={() => {
                setOpen(false);
                onPreviewClick();
              }}
            >
              {t('cover.preview.action')}
              <CropIcon />
            </button>
          </DropdownItem>
          <DropdownItem>
            <button
              className={cn(cls.dropdown_btn, cls.dropdown_btn_red)}
              type="button"
              onClick={() => {
                setOpen(false);
                onDeleteClick();
              }}
            >
              {t('common.delete')}
              <DeleteIcon />
            </button>
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  );
}
