import cn from 'classnames';
import { useMemo } from 'react';

import { Nullable } from '@/app/types';
import { useSpecsEffect } from '@/modules/showroom/advert/create/Specs/helpers';
import { useTransportBodies } from '@/modules/transport/api';
import {
  TransportYear,
  TransportGeneration,
  TransportBody,
  TransportIconType
} from '@/modules/transport/types';
import { Skeleton } from '@/ui/Skeleton';
import { MAX_PG_LIMIT } from '@/utils/consts';

import cls from './Body.module.scss';

const skeletons = new Array(3).fill(0);

type Props = {
  year: TransportYear;
  generation: TransportGeneration;
  selected: Nullable<TransportBody>;
  onChange: (body: TransportBody) => void;
  disabled?: boolean;
};

export const Body: React.FC<Props> = ({
  year,
  generation,
  selected,
  onChange,
  disabled
}) => {
  const req = useMemo(
    () => ({
      limit: MAX_PG_LIMIT,
      icon_type: TransportIconType.svg,
      generation_id: generation.id,
      year_of_issue_id: year.id
    }),
    [generation, year]
  );
  const [bodies, isLoading] = useTransportBodies(req);

  useSpecsEffect(selected, bodies, onChange);

  return (
    <div className={cls.root}>
      <div className={cls.list_wrap}>
        {bodies && !isLoading && (
          <ul className={cls.list}>
            {bodies.map((b) => (
              <li key={b.id}>
                <button
                  className={cn(cls.body_btn, {
                    [cls.body_btn_active]: b.id === selected?.id
                  })}
                  onClick={() => onChange(b)}
                  disabled={disabled}
                >
                  {b.photo_url && <img src={b.photo_url} alt="" />}
                  <span>{b.name}</span>
                </button>
              </li>
            ))}
          </ul>
        )}

        {isLoading && (
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton width="100%" height={186} borderRadius={16} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
