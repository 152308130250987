import { useCallback } from 'react';

import { ChatMessageContent } from '@/modules/chat/types';
import { showAlert } from '@/utils/network';

export const useDownloadFile = () => {
  const download = useCallback(async (file: ChatMessageContent) => {
    if (!file.url) return;

    try {
      const res = await fetch(file.url);
      const fileBlob = await res.blob();
      const data = new Blob([fileBlob], {
        type: `${file.type}/${file.extension}`
      });
      const fileUrl = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      tempLink.href = fileUrl;
      tempLink.setAttribute('download', `${file.name}.${file.extension}`);
      tempLink.click();
    } catch (error) {
      showAlert({ error });
    }
  }, []);

  return [download];
};
