import { Header } from '@/modules/showroom/advert/create/Header/Header';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function HeaderWrap() {
  const brand = useUpdateAdvertStore((s) => s.brand);
  const model = useUpdateAdvertStore((s) => s.model);
  const year = useUpdateAdvertStore((s) => s.year);
  const body = useUpdateAdvertStore((s) => s.body);
  const generation = useUpdateAdvertStore((s) => s.generation);
  const modification = useUpdateAdvertStore((s) => s.modification);

  return (
    <Header
      brand={brand}
      model={model}
      year={year}
      body={body}
      generation={generation}
      modification={modification}
    />
  );
}
