export function DeleteIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05173 14.7583C8.33937 14.7583 8.52476 14.5761 8.51793 14.3077L8.30879 7.15154C8.30074 6.8856 8.11289 6.70895 7.83768 6.70895C7.5525 6.70895 7.36834 6.8912 7.37516 7.15836L7.57871 14.3114C7.58677 14.5829 7.77584 14.7583 8.05173 14.7583ZM10.0624 14.7583C10.3451 14.7583 10.5441 14.5785 10.5441 14.3126V7.1559C10.5441 6.88997 10.3451 6.70895 10.0624 6.70895C9.77841 6.70895 9.58497 6.88997 9.58497 7.1559V14.3126C9.58497 14.5785 9.77841 14.7583 10.0624 14.7583ZM12.0773 14.7583C12.3488 14.7583 12.5367 14.5829 12.5447 14.3114L12.7483 7.15836C12.7551 6.8912 12.5709 6.70895 12.2858 6.70895C12.0105 6.70895 11.8227 6.8856 11.8147 7.15713L11.6111 14.3077C11.6043 14.5761 11.7897 14.7583 12.0773 14.7583ZM6.96139 4.80702H8.22115V3.3893C8.22115 3.03586 8.46838 2.80911 8.84844 2.80911H11.2638C11.6439 2.80911 11.8911 3.03586 11.8911 3.3893V4.80702H13.1509V3.32596C13.1509 2.28216 12.4847 1.66675 11.3536 1.66675H8.75862C7.62759 1.66675 6.96139 2.28216 6.96139 3.32596V4.80702ZM3.93742 5.54966H16.1916C16.528 5.54966 16.7901 5.2825 16.7901 4.94613C16.7901 4.61221 16.528 4.35065 16.1916 4.35065H3.93742C3.60665 4.35065 3.33334 4.61221 3.33334 4.94613C3.33334 5.28809 3.60665 5.54966 3.93742 5.54966ZM6.92753 17.0693H13.2015C14.2464 17.0693 14.925 16.4296 14.974 15.3834L15.4433 5.41874H14.1772L13.7285 15.1802C13.7148 15.5897 13.4446 15.8617 13.0554 15.8617H7.06241C6.68439 15.8617 6.41422 15.5829 6.39497 15.1802L5.92386 5.41874H4.68021L5.15501 15.389C5.20525 16.4352 5.87145 17.0693 6.92753 17.0693Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PhoneIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7644 16.2054C10.4728 18.9171 13.7517 20.9999 16.4302 20.9999C17.6322 20.9999 18.6874 20.582 19.5341 19.6478C20.0287 19.098 20.325 18.4649 20.325 17.8342C20.325 17.3472 20.1374 16.8787 19.6779 16.5451L16.8539 14.5396C16.417 14.2327 16.0433 14.0859 15.7079 14.0859C15.2834 14.0859 14.9022 14.3295 14.4734 14.749L13.8119 15.4046C13.711 15.5056 13.5792 15.5515 13.4599 15.5515C13.319 15.5515 13.1764 15.4964 13.0829 15.4456C12.5057 15.1328 11.5164 14.2828 10.5979 13.3719C9.68701 12.461 8.83703 11.4717 8.53179 10.8944C8.48095 10.7935 8.42589 10.6584 8.42589 10.5191C8.42589 10.3981 8.46425 10.2739 8.5652 10.1729L9.22833 9.4964C9.64039 9.06765 9.89148 8.69395 9.89148 8.26189C9.89148 7.92656 9.74299 7.56039 9.42271 7.11585L7.43971 4.32196C7.10787 3.85503 6.62258 3.65405 6.10554 3.65405C5.48664 3.65405 4.86094 3.92607 4.31453 4.45081C3.40288 5.31419 3 6.38443 3 7.56976C3 10.2482 5.05274 13.5046 7.7644 16.2054Z"
        fill="currentColor"
      />
    </svg>
  );
}
