export function ValidIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.5C8.62826 17.5 8.97342 17.3174 9.21323 16.9515L17.1225 4.62159C17.2997 4.34425 17.3708 4.11218 17.3708 3.88381C17.3708 3.30439 16.9633 2.90454 16.3779 2.90454C15.9625 2.90454 15.7214 3.04422 15.4694 3.4397L8.15705 15.0568L4.38887 10.2117C4.14367 9.88692 3.88636 9.75092 3.52286 9.75092C2.92207 9.75092 2.5 10.1715 2.5 10.7524C2.5 11.002 2.59323 11.2541 2.80309 11.509L7.16264 16.9635C7.45294 17.3265 7.76679 17.5 8.19071 17.5Z"
        fill="var(--clr-green)"
      />
    </svg>
  );
}
