import { useMemo } from 'react';

import { Nullable } from '@/app/types';
import { useSpecsEffect } from '@/modules/showroom/advert/create/Specs/helpers';
import { useTransportDriveUnits } from '@/modules/transport/api';
import {
  TransportBrand,
  TransportModel,
  TransportGeneration,
  TransportBody,
  TransportDriveUnit,
  TransportIconType,
  TransportEngine
} from '@/modules/transport/types';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';
import { Skeleton } from '@/ui/Skeleton';
import { MAX_PG_LIMIT } from '@/utils/consts';

import cls from './Drive.module.scss';

const skeletons = new Array(5).fill(0);

type Props = {
  brand: TransportBrand;
  model: TransportModel;
  generation: TransportGeneration;
  body: TransportBody;
  engine: TransportEngine;
  selected: Nullable<TransportDriveUnit>;
  onChange: (g: TransportDriveUnit) => void;
  disabled?: boolean;
};

export const Drive: React.FC<Props> = ({
  brand,
  model,
  generation,
  body,
  engine,
  selected,
  onChange,
  disabled
}) => {
  const req = useMemo(
    () => ({
      limit: MAX_PG_LIMIT,
      icon_type: TransportIconType.svg,
      brand_id: brand.id,
      model_id: model.id,
      generation_id: generation.id,
      body_id: body.id,
      engine_id: engine.id
    }),
    [engine.id, body.id, brand.id, generation.id, model.id]
  );

  const [units, isLoading] = useTransportDriveUnits(req);

  useSpecsEffect(selected, units, onChange);

  return (
    <div className={cls.root}>
      <div className={cls.list_wrap}>
        {units && !isLoading && (
          <ul className={cls.list}>
            {units.map((u) => (
              <li key={u.id}>
                <CheckBtn
                  active={selected?.id === u.id}
                  onClick={() => onChange(u)}
                  disabled={disabled}
                >
                  {u.icon_url && <img src={u.icon_url} alt="" />}
                  {u.name}
                </CheckBtn>
              </li>
            ))}
          </ul>
        )}

        {isLoading && (
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton width={106} height={48} borderRadius={27} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
