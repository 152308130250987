export function CameraIcon(): JSX.Element {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9816 29.4369H29.0161C31.6282 29.4369 33 28.0851 33 25.4951V12.7587C33 10.1688 31.6282 8.82684 29.0161 8.82684H25.7655C24.8132 8.82684 24.5043 8.65449 23.921 8.04106L22.961 7.00111C22.33 6.33791 21.6936 6 20.4586 6H15.4716C14.2367 6 13.6003 6.33791 12.9693 7.00111L12.0071 8.04106C11.4237 8.64453 11.1072 8.82684 10.1626 8.82684H6.9816C4.36183 8.82684 3 10.1688 3 12.7587V25.4951C3 28.0851 4.36183 29.4369 6.9816 29.4369ZM17.9989 25.6466C14.3627 25.6466 11.4417 22.7355 11.4417 19.0751C11.4417 15.4246 14.3627 12.5157 17.9989 12.5157C21.6394 12.5157 24.5484 15.4246 24.5484 19.0751C24.5484 22.7355 21.6294 25.6466 17.9989 25.6466ZM17.9989 23.4783C20.4308 23.4783 22.39 21.5247 22.39 19.0751C22.39 16.6332 20.4308 14.674 17.9989 14.674C15.5714 14.674 13.6 16.6332 13.6 19.0751C13.6 21.5247 15.5813 23.4783 17.9989 23.4783ZM25.3331 14.615C25.3331 13.7591 26.0832 13.009 26.9613 13.009C27.8272 13.009 28.5652 13.7591 28.5652 14.615C28.5652 15.5052 27.8294 16.2167 26.9613 16.2288C26.0789 16.231 25.3331 15.5151 25.3331 14.615Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PictureIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.2422 33.092L32.2376 23.7047C31.4945 23.0152 30.6024 22.6666 29.6995 22.6666C28.7737 22.6666 27.9432 22.9845 27.1723 23.6738L19.5448 30.4908L16.4294 27.6796C15.7201 27.0408 14.9402 26.7199 14.1494 26.7199C13.3682 26.7199 12.6693 27.024 11.9738 27.6627L5.60003 33.3751C5.68312 36.4867 7.13047 38.1103 9.92656 38.1103H37.3079C40.6961 38.1103 42.385 36.362 42.2422 33.092ZM9.54017 39.5297H38.4597C42.1188 39.5297 44 37.6624 44 34.0588V13.4906C44 9.88388 42.1188 8.00586 38.4597 8.00586H9.54017C5.89491 8.00586 4 9.88388 4 13.4906V34.0588C4 37.6624 5.89491 39.5297 9.54017 39.5297ZM9.73201 36.2208C8.1617 36.2208 7.30881 35.4078 7.30881 33.7669V13.7794C7.30881 12.1385 8.1617 11.3147 9.73201 11.3147H38.2679C39.8243 11.3147 40.6911 12.1385 40.6911 13.7794V33.7669C40.6911 35.4078 39.8243 36.2208 38.2679 36.2208H9.73201Z"
        fill="currentColor"
      />
      <path
        d="M17.0837 24.0744C19.2957 24.0744 21.1194 22.2507 21.1194 20.0187C21.1194 17.8066 19.2957 15.9661 17.0837 15.9661C14.8516 15.9661 13.028 17.8066 13.028 20.0187C13.028 22.2507 14.8516 24.0744 17.0837 24.0744Z"
        fill="currentColor"
      />
    </svg>
  );
}
