import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { ExpensesAdvertType } from '@/modules/wallet/ExpensesAdverts/helpers';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './ExpensesAdverts.module.scss';

type Props = {
  expensesAdvert: ExpensesAdvertType;
};

export function ExpensesAdvert({ expensesAdvert }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { advert } = expensesAdvert;

  const name = useAdvertName(advert);
  const advertUrl = advert.url ? `${APP_URL}/${advert.url}` : '';
  const isWhite = advert.colour?.hex.toLowerCase() === '#ffffff';

  const imgPlaceholder = useImgPlaceholder();
  const imgSrc = useMemo(() => {
    if (advert.vrp_image_url) return advert.vrp_image_url;
    if (advert.photos && advert.photos[0]) {
      return advert.photos[0].thumbnail_url || advert.photos[0].photo_url;
    }

    return imgPlaceholder;
  }, [advert.photos, advert.vrp_image_url, imgPlaceholder]);

  const isPublished = advert.status?.title === AdvertStatusType.published;
  const mod = advert.modification;

  return (
    <div className={cls.root}>
      <div className={cls.img}>
        <ImgSkeleton
          className={cls.img_inner}
          src={imgSrc}
          alt=""
          imgProxyWidth={228}
          imgProxyHeight={171}
        />
        {!isPublished && advert.status && (
          <span className={cls.status}>{advert.status?.name}</span>
        )}
      </div>

      <div className={cls.content}>
        <div className={cls.header}>
          <div>
            <p className={cls.name}>
              {advert.colour && (
                <span
                  className={cn(cls.color, { [cls.color_white]: isWhite })}
                  style={{ backgroundColor: advert.colour?.hex }}
                />
              )}
              {name}
            </p>

            {mod && (
              <>
                {Object.keys(mod).length <= 2 ? (
                  <p className={cls.specs}>
                    {t('mileageKm', {
                      mileage: numberWithSpaces(advert.mileage || 0, lang)
                    })}
                    <span>{t('common.dot')}</span>
                    {mod.name}
                  </p>
                ) : (
                  <p className={cls.specs}>
                    {t('mileageKm', {
                      mileage: numberWithSpaces(advert.mileage || 0, lang)
                    })}
                    <span>{t('common.dot')}</span>
                    {mod.volume?.short_name} {mod.transmission?.short_name}
                    <span>{t('common.dot')}</span>
                    {t('common.commaSeparated', {
                      first: t('horsepower', {
                        value: mod.horse_power?.name || ''
                      }),
                      second: mod.engine?.name
                    })}
                    <span>{t('common.dot')}</span>
                    {mod.drive_unit?.short_name}
                  </p>
                )}
              </>
            )}
          </div>

          <div className={cls.total}>
            <b>
              {t('priceCurrency', {
                price: numberWithSpaces(expensesAdvert.total, lang),
                currency: ADV_CUR
              })}
            </b>
            <span>{t('wallet.expensesTotal')}</span>
          </div>
        </div>

        <div className={cls.expenses}>
          <ul className={cls.categories}>
            {expensesAdvert.categories.map((c) => (
              <li key={c.id}>
                <div className={cls.category}>
                  <p className={cls.category_name}>
                    <span
                      className={cls.category_color}
                      style={{ backgroundColor: c.color }}
                    />
                    {c.name}
                    <span className={cls.category_separator}>
                      {t('common.x')}
                    </span>
                    <b>{c.count}</b>
                  </p>
                  <span className={cls.category_line} />
                  <b className={cls.category_total}>
                    {t('priceCurrency', {
                      price: numberWithSpaces(c.total, lang),
                      currency: ADV_CUR
                    })}
                  </b>
                </div>
              </li>
            ))}

            {expensesAdvert.promos.map((p) => (
              <li key={p.id}>
                <div className={cls.category}>
                  <p className={cls.category_name}>
                    {p.logo && <img className={cls.promo_logo} src={p.logo} />}
                    {p.name}
                    <span className={cls.category_separator}>
                      {t('common.x')}
                    </span>
                    <b>{p.count}</b>
                  </p>
                  <span className={cls.category_line} />

                  <b className={cls.category_total}>
                    {t('priceCurrency', {
                      price: numberWithSpaces(p.total, lang),
                      currency: ADV_CUR
                    })}
                  </b>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {advertUrl && (
        <a
          className={cls.link}
          href={advertUrl}
          target="_blank"
          rel="noreferrer"
        />
      )}
    </div>
  );
}
