import { useTranslation } from 'react-i18next';

import { NotificationsForm } from '@/modules/notifications/NotificationsForm/NotificationsForm';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';

export const NotificationsPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <LayoutHeader title={t('notifications')} />
      <PageHead title={t('pageTitle', { title: t('notifications') })} />

      <div
        className="box"
        style={{
          boxShadow: 'var(--shadow-primary)',
          maxWidth: 632
        }}
      >
        <NotificationsForm />
      </div>
    </div>
  );
};
