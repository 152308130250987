import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { MapkitProvider } from 'react-mapkit';

import 'nprogress/nprogress.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '@/styles/rc-tooltip.css';
import '@/styles/index.scss';

import { Globals } from '@/app/Globals';
import { AppRouter } from '@/app/router/AppRouter';
import { useLang } from '@/hooks/useLang';
import i18n from '@/modules/i18n';
import { MAPKIT_TOKEN } from '@/modules/mapkit/helpers';

import { AppProgress } from './AppProgress';
import { useApp } from './useApp';

function App() {
  const [lang] = useLang();
  useApp();

  return (
    <Suspense fallback={<AppProgress />}>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          {/* @ts-ignore */}
          <MapkitProvider tokenOrCallback={MAPKIT_TOKEN} language={lang}>
            <AppRouter />
            <Globals />
          </MapkitProvider>
        </HelmetProvider>
      </I18nextProvider>
    </Suspense>
  );
}

export default App;
