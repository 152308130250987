import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { PromotionPackage } from '@/modules/promotion/types';
import { NO_PROMO_ID } from '@/modules/showroom/advert/create/helpers';
import { Promotion } from '@/modules/showroom/advert/create/Promotion/Promotion';
import { useAdvertPromoUpdate } from '@/modules/showroom/advert/hooks';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { Button } from '@/ui/Button/Button';
import { Message } from '@/ui/Message/Message';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { dateDistanceToNowArray } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './PromoModal.module.scss';

import { CloseIcon } from '../icons';

type Props = {
  isOpen: boolean;
  close: () => void;
  advert: MyAdvert;
  promotionsList: PromotionPackage[];
  onPublishToggle?: () => void;
};

export function PromoModal({
  isOpen,
  close,
  advert,
  promotionsList,
  onPublishToggle
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const adPromo = advert.promotion_package;
  const adPromoId = adPromo?.id || null;
  const adPromoLeftTime = useMemo(() => {
    if (!adPromo) return '';

    const leftTimeStr = dateDistanceToNowArray(
      new Date(adPromo.end_date * 1000),
      lang
    ).join(t('common.spaceSeparator') || ' ');

    return leftTimeStr
      ? t('advertisements.promoLeft', {
          date: leftTimeStr
        })
      : '';
  }, [adPromo, lang, t]);

  const [promoPackageId, setPromoPackageId] = useState(adPromoId);

  const [autoExtension, setAutoExtension] = useState(
    adPromo ? !!adPromo.promotion_package_automatic_extension : true
  );

  useEffect(() => {
    if (promoPackageId !== NO_PROMO_ID) setAutoExtension(true);
  }, [promoPackageId]);

  useEffect(() => {
    setPromoPackageId(adPromoId);
  }, [adPromoId]);

  useEffect(() => {
    if (isOpen) {
      const v = adPromo
        ? !!adPromo.promotion_package_automatic_extension
        : true;
      setAutoExtension(v);
    }
  }, [adPromo, isOpen]);

  const isSameSelected = !!promoPackageId && adPromoId === promoPackageId;
  const selectedPackage = promotionsList.find((p) => p.id === promoPackageId);
  const buttonText = isSameSelected
    ? t('common.save')
    : t('advertisements.promo.connectPrice', {
        price: t('priceCurrency', {
          price: numberWithSpaces(selectedPackage?.price || 0, lang),
          currency: CURRENCY_DISPLAY.aed
        })
      });

  // Save
  const { updateAutoExtension, updatePromoPackage } = useAdvertPromoUpdate();
  const hasChanges =
    !isSameSelected ||
    autoExtension !== !!adPromo?.promotion_package_automatic_extension;
  const [loading, setLoading] = useState(false);
  const onSubmitClick = async () => {
    setLoading(true);

    try {
      await updateAutoExtension(advert.id, autoExtension);
      if (promoPackageId && !isSameSelected)
        await updatePromoPackage(advert.id, promoPackageId);

      if (onPublishToggle) onPublishToggle();
      close();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdaptiveModal
      name={`${advert.id}-promo-modal`}
      isOpen={isOpen}
      close={close}
    >
      <div className={cls.root}>
        <header className={cls.header}>
          <h2 className={cls.title}>{t('advertisements.promo.title')}</h2>
          <button className={cls.close_btn} type="button" onClick={close}>
            <CloseIcon />
          </button>
        </header>
        <Promotion
          activePackageId={adPromoId}
          activeLeft={adPromoLeftTime}
          promotionPackageId={promoPackageId}
          setPromotionPackageId={setPromoPackageId}
          autoExtension={autoExtension}
          setAutoExtension={setAutoExtension}
          promotionsList={promotionsList}
          contentOnly
          ignoreAutoextEffect
        />
        <div className={cls.btn}>
          {!isSameSelected && adPromoId && adPromoId !== NO_PROMO_ID && (
            <div className={cls.msg}>
              <Message text={[t('advertisements.promo.currentNew')]} />
            </div>
          )}
          <Button
            onClick={onSubmitClick}
            loading={loading}
            disabled={loading || !hasChanges}
            color="green"
            fullWidth
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </AdaptiveModal>
  );
}
