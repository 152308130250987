import { RentSearchStore } from '@/modules/showroom/advert/rent/my/store';
import { GetRentAdvertsReq } from '@/modules/showroom/advert/rent/my/types';
import { EMPTY_BRAND_ID } from '@/modules/showroom/advert/search/consts';
import {
  countByArr,
  mapAutoFilters
} from '@/modules/showroom/advert/search/helpers';

export const defaultRentSearchReq: GetRentAdvertsReq = {
  count_seats: null,
  auto_filter: null,
  price_max: null,
  price_min: null,
  transmission_ids: null,
  body_ids: null,
  body_colour_ids: null,
  sort_filter_id: null
};

export function calcSelectedParamsCount(
  store: RentSearchStore
): [number, number] {
  let count = 0;
  count += countByArr(store.bodiesIds);
  count += countByArr(store.transmissionsIds);
  count += countByArr(store.colorsIds);
  count += store.priceMin ? 1 : 0;
  count += store.priceMax ? 1 : 0;
  count += store.countSeats ? 1 : 0;

  let allParamsCount = count;

  allParamsCount += countByArr(
    store.autoFilters.filter((a) => a.brand_id !== EMPTY_BRAND_ID)
  );

  return [count, allParamsCount];
}

export function storeToSearchReq(store: RentSearchStore): GetRentAdvertsReq {
  const result: GetRentAdvertsReq = {
    ...defaultRentSearchReq,
    count_seats: store.countSeats,
    auto_filter: mapAutoFilters(store.autoFilters),
    sort_filter_id: store.sortId,
    body_ids: store.bodiesIds,
    transmission_ids: store.transmissionsIds,
    body_colour_ids: store.colorsIds,
    price_min: store.priceMin || null,
    price_max: store.priceMax || null
  };

  return result;
}
