import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonObj } from '@/app/types';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { MAX_VRP_NUMBER_LEN } from '@/modules/showroom/advert/vrp/helpers';
import { Dropdown } from '@/ui/Dropdown/Dropdown';

import cls from '../Filters.module.scss';

const numberLengthOptions: CommonObj[] = new Array(MAX_VRP_NUMBER_LEN)
  .fill(null)
  .map((_, i) => ({
    id: ++i,
    name: String(i)
  }));

export function PlateNumberLengthFilter() {
  const { t } = useTranslation();

  const [isPlateNumLenOpen, setPlateNumLenOpen] = useState(false);

  const vrpPlateNumberLen = useSearchStore((s) => s.vrpPlateNumberLen);
  const setVrpPlateNumberLen = useSearchStore((s) => s.setVrpPlateNumberLen);
  const selected = numberLengthOptions?.find(
    (tp) => tp.id === vrpPlateNumberLen
  );

  const onChange = (v: FilterType) => {
    const numLen = v as CommonObj;
    setVrpPlateNumberLen(numLen.id);
    setPlateNumLenOpen(false);
  };

  const onReset = () => {
    setPlateNumLenOpen(false);
    setVrpPlateNumberLen(null);
  };

  return (
    <>
      <FilterBtn
        text={t('numberLength')}
        onClick={() => setPlateNumLenOpen(true)}
        active={isPlateNumLenOpen}
        onResetClick={onReset}
        value={selected?.name}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-plate-num-len"
        isOpen={isPlateNumLenOpen}
        close={() => setPlateNumLenOpen(false)}
        parentCls={cls.li}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={vrpPlateNumberLen}
            list={numberLengthOptions || []}
            onClick={onChange}
            search=""
            allButtonText={t('common.anyN')}
            onAllClick={onReset}
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
}
