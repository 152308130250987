import { useState } from 'react';

import { OptionI } from '@/app/types';
import {
  WalletCategoriesType,
  useWalletCategories
} from '@/modules/wallet/hooks';
import { Tabs } from '@/ui/Tabs/Tabs';

type Props = {
  onClick: (s: WalletCategoriesType) => void;
};

export function WalletFilters({ onClick }: Props) {
  const options = useWalletCategories();
  const [option, setOption] = useState<WalletCategoriesType>(options[0]);
  const onChange = (opt: WalletCategoriesType) => {
    setOption(opt);
    onClick(opt);
  };

  return (
    <Tabs
      variant="underline"
      size="l"
      tabs={options}
      active={option}
      onChange={onChange as (opt: OptionI) => void}
    />
  );
}
