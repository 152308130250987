import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { StoreRentTariff } from '@/modules/showroom/advert/rent/tariffs/types';
import { TARIFF_PRICE_MAX_LEN } from '@/modules/showroom/advert/rent/update/consts';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { SelectRange } from '@/ui/Select/SelectRange';
import {
  maxLength,
  numberWithSpaces,
  onlyNumbers,
  pluralKey
} from '@/utils/format';

import { RemoveIcon } from './icons';
import cls from './Tariffs.module.scss';

const days = new Array(29).fill(0);

export const defaultTariff = () => ({
  localId: nanoid(),
  price: 0,
  days_from: 1,
  days_up_to: 2
});

type Props = {
  tariff: StoreRentTariff;
  index: number;
  prevTariff?: StoreRentTariff;
  nextTariff?: StoreRentTariff;
  onTariffChange: (tf: StoreRentTariff) => void;
  onDeleteClick: (localId: string, index: number) => void;
  showDelete: boolean;
  isLast: boolean;
};

export function Tariff({
  tariff,
  index,
  prevTariff,
  nextTariff,
  onTariffChange,
  onDeleteClick,
  showDelete,
  isLast
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { localId } = tariff;

  const fromOptions = useMemo<OptionI[]>(() => {
    return days.map((_, i) => {
      const num = i + 1;
      return {
        id: `${num}`,
        text: t('common.fromValue', {
          value: pluralKey(
            num,
            t('rentTariffs.daysPlural.one', { value: num }),
            t('rentTariffs.daysPlural.few', { value: num }),
            t('rentTariffs.daysPlural.many', { value: num })
          )
        })
      };
    }, []);
  }, [t]);

  const toOptions = useMemo<OptionI[]>(() => {
    return days.map((_, i) => {
      const num = i + 1;
      const text = t('common.toValue', {
        value: pluralKey(
          num,
          t('rentTariffs.daysPlural.one', { value: num }),
          t('rentTariffs.daysPlural.few', { value: num }),
          t('rentTariffs.daysPlural.many', { value: num })
        )
      });

      return {
        id: `${num}`,
        text:
          i === days.length - 1
            ? t('rentTariffs.daysPlural.many', {
                value: `${num}+`
              })
            : text
      };
    }, []);
  }, [t]);

  const fromStr = String(tariff.days_from);
  const fromOption = fromOptions.find((opt) => opt.id === fromStr);

  const onFromChange = (opt: OptionI) => {
    const v = Number(opt.id);
    onTariffChange({ ...tariff, days_from: v });
  };

  const toStr = String(tariff.days_up_to);
  const toOption = toOptions.find((opt) => opt.id === toStr);
  const onToChange = (opt: OptionI) => {
    const v = Number(opt.id);

    onTariffChange({
      ...tariff,
      days_up_to: tariff.days_up_to === v && isLast ? null : v
    });
  };

  const prevTariffTo = prevTariff ? prevTariff.days_up_to : null;

  const filteredFromOptions = useMemo(() => {
    if (prevTariffTo)
      return fromOptions.filter((opt) => {
        const num = Number(opt.id);
        return num === prevTariffTo + 1;
      });

    return toOption
      ? fromOptions.filter((opt) => {
          const to = Number(toOption.id);
          const num = Number(opt.id);
          return num < to;
        })
      : fromOptions;
  }, [fromOptions, prevTariffTo, toOption]);

  const nextTariffFrom = nextTariff ? nextTariff.days_from : null;

  const filteredToOptions = useMemo(() => {
    if (nextTariffFrom)
      return toOptions.filter((opt) => {
        const num = Number(opt.id);
        return num > nextTariffFrom - 1;
      });

    return fromOption
      ? toOptions.filter((opt) => {
          const from = Number(fromOption.id);
          const num = Number(opt.id);
          return num > from;
        })
      : toOptions;
  }, [fromOption, nextTariffFrom, toOptions]);

  const selectValueText = useMemo(() => {
    const { days_from, days_up_to } = tariff;
    if (days_from && days_up_to) {
      return t('rentTariffs.daysPlural.many', {
        value: t('common.hyphenSeparatedShort', {
          first: days_from,
          second: days_up_to
        })
      });
    }

    if (days_from) {
      if (isLast && index !== 0) {
        return t('rentTariffs.daysPlural.many', {
          value: `${days_from}+`
        });
      } else {
        return pluralKey(
          days_from,
          t('rentTariffs.daysPlural.one', { value: days_from }),
          t('rentTariffs.daysPlural.few', { value: days_from }),
          t('rentTariffs.daysPlural.many', { value: days_from })
        );
      }
    }

    return '';
  }, [isLast, t, tariff, index]);

  const onPriceChange = (v: string) => {
    const num = Number(v);
    onTariffChange({ ...tariff, price: num });
  };

  return (
    <div className={cls.row}>
      <SelectRange
        name={`rent-tariff-${localId}`}
        valueText={selectValueText}
        from={fromOption}
        fromOptions={filteredFromOptions}
        onFromChange={onFromChange}
        to={toOption}
        toOptions={filteredToOptions}
        onToChange={onToChange}
        dropdownTop
      />
      <Input
        value={tariff.price ? numberWithSpaces(tariff.price, lang) : ''}
        label={t('rentTariffs.priceLabel')}
        onChange={(e) =>
          onPriceChange(
            maxLength(onlyNumbers(e.currentTarget.value), TARIFF_PRICE_MAX_LEN)
          )
        }
      />
      {showDelete && (
        <div className={cls.remove}>
          <Button
            onClick={() => onDeleteClick(localId, index)}
            variant="secondary"
            color="black"
          >
            <RemoveIcon />
          </Button>
        </div>
      )}
    </div>
  );
}
