import { getSign } from '@/modules/imgproxy/sign';
import { REACT_APP_API_URL, STORAGE_URL } from '@/utils/consts';

export const IMGPROXY_KEY = process.env.REACT_APP_IMGPROXY_KEY;
export const IMGPROXY_SALT = process.env.REACT_APP_IMGPROXY_SALT;

export type ImgProxyOptions = {
  width?: number;
  height?: number;
};

export function imgproxy(url: string | undefined, options?: ImgProxyOptions) {
  if (!url || !url.includes(STORAGE_URL)) return url;

  const pixelRatio =
    typeof window !== 'undefined' ? window.devicePixelRatio : 1;

  const size =
    options && options.width
      ? `s:${Math.round(options.width * pixelRatio)}${
          options.height ? `:${Math.round(options.height * pixelRatio)}` : ''
        }/`
      : '';

  const renderParam = `${size}plain`;
  const path = `/${renderParam}/${url}`;
  const sign = getSign(path);

  return sign ? `${REACT_APP_API_URL}/image/${sign}${path}` : url;
}

export function useImgProxy(
  url: string | undefined,
  options?: ImgProxyOptions
) {
  return imgproxy(url, options);
}
