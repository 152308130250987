import { Condition } from '@/modules/showroom/advert/create/Condition/Condition';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function ConditionWrap() {
  const broken = useUpdateAdvertStore((s) => s.broken);
  const setBroken = useUpdateAdvertStore((s) => s.setBroken);
  const isBrokenFilled = useUpdateAdvertStore((s) => s.isBrokenFilled);
  const setBrokenFilled = useUpdateAdvertStore((s) => s.setBrokenFilled);

  return (
    <Condition
      broken={broken}
      setBroken={setBroken}
      isBrokenFilled={isBrokenFilled}
      setBrokenFilled={setBrokenFilled}
    />
  );
}
