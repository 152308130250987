import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import {
  GetCategoriesReq,
  GetCategoriesRes,
  GetRentCategoriesReq,
  GetRentCategoriesRes
} from '@/modules/category/types';
import { showAlert } from '@/utils/network';

export function getCategories(params?: GetCategoriesReq) {
  return axios.get<GetCategoriesRes>('/categories/list', { params });
}

export function getRentCategories(params?: GetRentCategoriesReq) {
  return axios.get<GetRentCategoriesRes>('/categories/rent/list', { params });
}

export function useCategories(
  params?: GetCategoriesReq
): [GetCategoriesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetCategoriesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getCategories(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useRentCategories(
  params?: GetRentCategoriesReq
): [GetRentCategoriesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetRentCategoriesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getRentCategories(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
