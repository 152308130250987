import { arrayMoveImmutable } from 'array-move';
import cn from 'classnames';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortEnd
} from 'react-sortable-hoc';

import { PhotoType } from '@/app/types';
import { handleImage, UploadItem } from '@/modules/files/upload/helpers';
import { UploadImages } from '@/modules/files/upload/UploadImages/UploadImages';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import {
  DeleteIcon,
  EyeIcon,
  BinIcon
} from '@/modules/showroom/advert/create/Photos/icons';
import cls from '@/modules/showroom/advert/create/Photos/Photos.module.scss';
import { MAX_SHOWROOM_PHOTOS_LEN } from '@/modules/showroom/ShowroomEdit/helpers';
import { Button } from '@/ui/Button/Button';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Previews } from '@/ui/Previews/Previews';
import { Spinner } from '@/ui/Spinner/Spinner';
import { showAlert } from '@/utils/network';

const FILE_SIZE_MB = 20;

// Element
interface ElementProps extends SortableElementProps {
  value: PhotoType;
  onPreviewClick: (url: string) => void;
  onRemoveClick: (url: string) => void;
  replaceLocalPhoto: (uploaded: PhotoType, localUrl: string) => void;
}

const SortableItem = SortableElement<ElementProps>(
  ({
    value,
    onPreviewClick,
    onRemoveClick,
    replaceLocalPhoto
  }: ElementProps) => {
    const [loading, setLoading] = useState(false);

    const upload = useCallback(
      async (file: File, localUrl: string) => {
        if (loading) return;
        setLoading(true);

        try {
          const r = await handleImage({
            file
          });

          replaceLocalPhoto(
            {
              localUrl,
              photo_url: r.response.url,
              thumbnail_url: r.response.url,
              file: null
            },
            localUrl
          );
          setLoading(false);
        } catch (error) {
          showAlert({ error });
          onRemoveClick(localUrl);
          setLoading(false);
        }
      },
      [onRemoveClick, replaceLocalPhoto, loading]
    );

    useEffect(() => {
      if (value.file && value.localUrl) {
        upload(value.file, value.localUrl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <li className={cls.li}>
        <div className={cls.photo_cell}>
          {value.file ? (
            <ImgSkeleton
              className={cls.photo_cell_img}
              draggable={false}
              src={value.localUrl || value.thumbnail_url || value.photo_url}
              alt=""
              imgProxyWidth={165}
              imgProxyHeight={124}
            />
          ) : (
            <ImgSkeleton
              className={cls.photo_cell_img}
              draggable={false}
              src={value.thumbnail_url || value.photo_url}
              alt=""
              imgProxyWidth={165}
              imgProxyHeight={124}
            />
          )}

          <button
            className={cls.action_btn}
            type="button"
            onClick={() => onPreviewClick(value.photo_url)}
            onTouchEnd={() => onPreviewClick(value.photo_url)}
            disabled={!!value.file || loading}
          >
            <EyeIcon />
          </button>
          <button
            className={cls.action_btn}
            type="button"
            onClick={() => onRemoveClick(value.photo_url)}
            onTouchEnd={() => onRemoveClick(value.photo_url)}
            disabled={!!value.file || loading}
          >
            <DeleteIcon />
          </button>

          {(!!value.file || loading) && (
            <span className={cls.spinner}>
              <Spinner color="var(--clr-white)" size={40} />
            </span>
          )}
        </div>
      </li>
    );
  }
);

// Container
interface ContainerProps extends SortableContainerProps {
  items: PhotoType[];
  onUpload: (imgs: UploadItem[]) => void;
  onLocalUpload: (v: File[]) => void;
  replaceLocalPhoto: (uploaded: PhotoType, localUrl: string) => void;
  onPreviewClick: (url: string) => void;
  onRemoveClick: (url: string) => void;
  largePhotos?: boolean;
}

const SortableList = SortableContainer<ContainerProps>(
  ({
    items,
    onUpload,
    onLocalUpload,
    largePhotos,
    ...restProps
  }: ContainerProps) => {
    const availableLen = MAX_SHOWROOM_PHOTOS_LEN - items.length;

    return (
      <ul className={cn(cls.list, { [cls.list_large]: largePhotos })}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${
              value.localUrl || value.thumbnail_url || value.photo_url
            }`}
            index={index}
            value={value}
            {...restProps}
          />
        ))}

        {availableLen > 0 && (
          <li>
            <UploadImages
              onUpload={onUpload}
              onLocalUpload={onLocalUpload}
              maxFileSizeMb={FILE_SIZE_MB}
              maxFilesLen={MAX_SHOWROOM_PHOTOS_LEN}
              maxFileLenSlice={availableLen}
              square
            />
          </li>
        )}
      </ul>
    );
  }
);

// Photos
type Props = {
  photos: PhotoType[];
  setPhotos: (v: PhotoType[]) => void;
  addPhotos: (v: PhotoType[]) => void;
  removePhoto: (v: PhotoType['photo_url']) => void;
  replaceLocalPhoto: (uploaded: PhotoType, localUrl: string) => void;
  fieldsOnly?: boolean;
  largePhotos?: boolean;
};

export function Photos(props: Props) {
  const { t } = useTranslation();

  const {
    photos,
    addPhotos,
    removePhoto,
    replaceLocalPhoto,
    setPhotos,
    fieldsOnly,
    largePhotos
  } = props;

  const isMax = photos.length >= MAX_SHOWROOM_PHOTOS_LEN;
  const availableLen = MAX_SHOWROOM_PHOTOS_LEN - photos.length;

  const onUpload = (imgs: UploadItem[]) => {
    if (isMax) return;

    const sliced = imgs.slice(0, availableLen);
    if (sliced.length <= 0) return;

    addPhotos(
      sliced.map((v) => ({
        photo_url: v.response.url,
        thumbnail_url: v.thumbnail?.r.url || null
      }))
    );
  };

  const onLocalUpload = (files: File[]) => {
    if (isMax) return;

    const sliced = files.slice(0, availableLen);
    if (sliced.length <= 0) return;

    addPhotos(
      sliced.map((f) => {
        const localUrl = URL.createObjectURL(f);
        return {
          localUrl,
          photo_url: localUrl,
          thumbnail_url: localUrl,
          file: f
        };
      })
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const hasLoadingPhotos = photos.some((p) => !!p.file);
    if (oldIndex !== newIndex && !hasLoadingPhotos) {
      setPhotos(arrayMoveImmutable(photos, oldIndex, newIndex));
    }
  };

  const removeAllPhotos = () => {
    setPhotos([]);
  };

  // Preview
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const onPreviewClick = (photoUrl: string) => {
    setPreviewUrl(photoUrl);
    setPreviewOpen(true);
  };

  const Container = fieldsOnly ? Fragment : Root;

  return (
    <>
      <Container>
        {!fieldsOnly && (
          <div className={cls.header}>
            <h1 className={cls.title}>
              <Trans
                t={t}
                i18nKey="showroom.photos.title"
                components={{
                  countWrap: <span />
                }}
                values={{
                  index: photos.length,
                  count: MAX_SHOWROOM_PHOTOS_LEN
                }}
              />
            </h1>

            {photos.length > 0 && (
              <Button
                onClick={removeAllPhotos}
                size="compact"
                variant="secondary"
                color="black"
                gap={4}
              >
                <BinIcon />
                {t('showroom.photos.removeAll')}
              </Button>
            )}
          </div>
        )}

        <p className={cls.desc}>
          <span>{t('upload.image.params')}</span>
        </p>

        {photos.length > 0 ? (
          <SortableList
            items={photos}
            onSortEnd={onSortEnd}
            axis="xy"
            onUpload={onUpload}
            onLocalUpload={onLocalUpload}
            replaceLocalPhoto={replaceLocalPhoto}
            onPreviewClick={onPreviewClick}
            onRemoveClick={removePhoto}
            largePhotos={largePhotos}
          />
        ) : (
          <div className={cls.upload}>
            <UploadImages
              onUpload={onUpload}
              onLocalUpload={onLocalUpload}
              maxFileSizeMb={FILE_SIZE_MB}
              maxFilesLen={MAX_SHOWROOM_PHOTOS_LEN}
              maxFileLenSlice={availableLen}
            />
          </div>
        )}
      </Container>

      {photos.length > 0 && (
        <Previews
          photos={photos.map((p) => p.photo_url)}
          isOpen={isPreviewOpen}
          close={() => setPreviewOpen(false)}
          initialUrl={previewUrl}
        />
      )}
    </>
  );
}

function Root({ children }: { children: React.ReactNode }) {
  return (
    <div className={cls.root} id={SCROLLTO.photo} style={{ marginBottom: 24 }}>
      <div className="box">{children}</div>
    </div>
  );
}
