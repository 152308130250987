import { MyBookingCard } from '@/modules/booking/MyBookings/MyBookingsList/MyBookingCard/MyBookingCard';
import { MyBookingCardSkeleton } from '@/modules/booking/MyBookings/MyBookingsList/MyBookingCard/MyBookingCardSkeleton';
import { BookingType } from '@/modules/booking/types';
import { LOAD_COUNT } from '@/modules/showroom/advert/search/hooks';

import cls from './MyBookingsList.module.scss';

type Props = {
  list: BookingType[];
  onConfirm?: () => void;
};

export function MyBookingsList({ list, onConfirm }: Props) {
  return (
    <ul className={cls.root}>
      {list.map((b) => (
        <li key={b.id}>
          <MyBookingCard booking={b} onConfirm={onConfirm} />
        </li>
      ))}
    </ul>
  );
}

const skeletons = new Array(LOAD_COUNT).fill(0);
export function MyBookingsListSkeleton() {
  return (
    <ul className={cls.root}>
      {skeletons.map((_, i) => (
        <li key={i}>
          <MyBookingCardSkeleton />
        </li>
      ))}
    </ul>
  );
}
