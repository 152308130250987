import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import {
  PromotionPackage,
  PromotionPackageOption
} from '@/modules/promotion/types';
import { PackageOptions } from '@/modules/showroom/tariff/TariffsPage/AdvertsCalc/Package/Options';
import { TARIFFS_VALUE_MAX_LENGTH } from '@/modules/showroom/tariff/TariffsPage/TariffsPage';
import { Input } from '@/ui/Input/Input';
import { ADV_CUR } from '@/utils/consts';
import {
  numberWithCommas,
  numberWithSpaces,
  onlyNumbers,
  pluralKey
} from '@/utils/format';

import { InfoIcon } from './icons';
import cls from './Package.module.scss';

type Props = {
  count: number;
  onChange: (packageId: string, count: number) => void;
  promoPackage: PromotionPackage;
};

export function Package({ count, onChange, promoPackage: p }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const hasOptions = !!p.options && p.options.length > 0;
  const [isOptionsOpen, setOptionsOpen] = useState(false);

  return (
    <div className={cls.root}>
      <div className={cls.package}>
        {p.logo_url && (
          <img className={cls.package_img} src={p.logo_url} alt="" />
        )}

        <div>
          <div className={cls.title}>
            <b>{p.name}</b>

            {hasOptions && (
              <div
                className={cls.options_wrap}
                onMouseLeave={() => setOptionsOpen(false)}
              >
                <span onMouseEnter={() => setOptionsOpen(true)}>
                  <InfoIcon />
                </span>
                <PackageOptions
                  options={p.options as PromotionPackageOption[]}
                  isOpen={isOptionsOpen}
                  close={() => setOptionsOpen(false)}
                />
              </div>
            )}
          </div>

          {p.valid_days && (
            <small className={cls.duration}>
              {pluralKey(
                p.valid_days,
                t('advertisements.boost.daysPlural.one', {
                  days: p.valid_days
                }),
                t('advertisements.boost.daysPlural.few', {
                  days: p.valid_days
                }),
                t('advertisements.boost.daysPlural.many', {
                  days: p.valid_days
                })
              )}
            </small>
          )}
        </div>

        <div className={cls.price_wrap}>
          <b className={cls.price}>
            {t('priceCurrency', {
              price: numberWithSpaces(p.price, lang),
              currency: ADV_CUR
            })}
          </b>

          {typeof p.old_price === 'number' && (
            <span className={cls.old_price}>
              {t('priceCurrency', {
                price: numberWithSpaces(p.old_price, lang),
                currency: ADV_CUR
              })}
            </span>
          )}
        </div>
      </div>

      <div className={cls.input}>
        <Input
          value={count ? numberWithCommas(count) : ''}
          onChange={(e) =>
            onChange(String(p.id), Number(onlyNumbers(e.currentTarget.value)))
          }
          onClear={() => onChange(String(p.id), 0)}
          inputMode="numeric"
          placeholder={'0'}
          maxLength={TARIFFS_VALUE_MAX_LENGTH}
          highlightFilled
        />
      </div>
    </div>
  );
}
