import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import {
  GetComplectationsReq,
  GetComplectationsRes,
  GetOptionsByComplectationReq,
  GetOptionsByComplectationRes,
  GetOptionsReq,
  GetOptionsRes
} from '@/modules/showroom/advert/complectation/types';
import { showAlert } from '@/utils/network';

export function getOptionsByComplectation({
  complectation_id
}: GetOptionsByComplectationReq) {
  return axios.get<GetOptionsByComplectationRes>(
    `/advertisements/transport-options/${complectation_id}`
  );
}

export function useComplectations(
  params?: Nullable<GetComplectationsReq>
): [GetComplectationsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetComplectationsRes>();

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get('/advertisements/complectations/list', {
        params
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useComplectationsOptions(
  params?: GetOptionsReq
): [GetOptionsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetOptionsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get('/advertisements/transport-options/list', {
        params
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
