import { useEffect } from 'react';

import { useLang } from '@/hooks/useLang';
import { useTransportOwnersStore } from '@/modules/transport/owner/store';
import { TransportOwner } from '@/modules/transport/types';

export function useTransportOwners(): TransportOwner[] {
  const [lang] = useLang();

  const data = useTransportOwnersStore((s) => s.data);
  const list = data[lang];

  const fetchOwners = useTransportOwnersStore((s) => s.fetchData);
  useEffect(() => {
    fetchOwners();
  }, [fetchOwners]);

  return list;
}
