import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { OptionI } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { hours } from '@/modules/showroom/advert/create/Phone/helpers';
import {
  cancelRefreshSchedule,
  refreshAdOnce,
  setRefreshSchedule
} from '@/modules/showroom/advert/my/api';
import { useWeekDaysForScheduleRefresh } from '@/modules/showroom/advert/my/hooks';
import { RefreshIcon } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/RefreshModal/icons';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { Button } from '@/ui/Button/Button';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { PrimarySelect } from '@/ui/PrimarySelect/PrimarySelect';
import { ToggleButton } from '@/ui/ToggleButton/ToggleButton';
import { ToggleButtonGroup } from '@/ui/ToggleButton/ToggleButtonGroup';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { minutesToHHMM } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './RefreshModal.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
  advert: MyAdvert;
};

export function RefreshModal({ isOpen, close, advert }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { refreshScheduleIsSetted, scheduleWeekDaysIds, scheduleHour } =
    useWeekDaysForScheduleRefresh(
      advert.current_week_days_for_schedule_refresh
    );

  const weekDays = useCommonStore((s) => s.weekDays);
  const refreshPrice = useCommonStore((s) => s.refreshPrice);

  const [isRefreshing, setRefreshing] = useState(false);
  const [isScheduling, setScheduling] = useState(false);
  const [isCanceling, setCanceling] = useState(false);
  const [selectedHoursOption, setSelectedHoursOption] = useState<OptionI[]>([]);
  const [selectedWeekDays, setSelectedWeeksDays] =
    useState<number[]>(scheduleWeekDaysIds);

  const sheduleOptionsSelected =
    !!selectedHoursOption && !!selectedWeekDays.length;

  const hoursOptions = useMemo<OptionI[]>(
    () =>
      hours.map((h) => ({
        id: String(h),
        text: minutesToHHMM(h * 60)
      })),
    []
  );

  const handleWeekDaysChange = (selectedValues: number[]) => {
    setSelectedWeeksDays(selectedValues);
  };

  const handleHoursOptionChange = (v: OptionI[]) => {
    setSelectedHoursOption(v);
  };

  const handleRefreshAdOnce = async () => {
    setRefreshing(true);
    try {
      await refreshAdOnce({
        ad_id: advert.id
      });

      showAlert({ text: t('refresh.refreshApplied') });
      close();
    } catch (error) {
      showAlert({ error });
    } finally {
      setRefreshing(false);
    }
  };

  const handleSetRefreshSchedule = async () => {
    if (!selectedHoursOption.length || !selectedWeekDays.length) return;

    setScheduling(true);
    try {
      await setRefreshSchedule(
        { ad_id: advert.id },
        {
          week_day_ids: selectedWeekDays,
          hours_after_midnight: Number(selectedHoursOption[0].id)
        }
      );

      showAlert({ text: t('refresh.scheduleApplied') });
      close();
    } catch (error) {
      showAlert({ error });
    } finally {
      setScheduling(false);
    }
  };

  const handleCancelRefreshSchedule = async () => {
    setCanceling(true);
    try {
      await cancelRefreshSchedule({ ad_id: advert.id });
      close();
      setSelectedHoursOption([]);
      setSelectedWeeksDays([]);
    } catch (error) {
      showAlert({ error });
    } finally {
      setCanceling(false);
    }
  };

  useEffect(() => {
    if (typeof scheduleHour !== 'number') return;

    const findedHoursOptions = hoursOptions.find(
      (hour) => hour.id === scheduleHour.toString()
    );
    findedHoursOptions && setSelectedHoursOption([findedHoursOptions]);
  }, [hoursOptions, scheduleHour]);

  return (
    <AdaptiveModal
      name={`${advert.id}-refresh-modal`}
      isOpen={isOpen}
      close={close}
      containerClass={cls.root_modal}
    >
      <div className={cls.root}>
        <div className={cls.description}>
          <div className={cls.header}>
            <RefreshIcon />
            <h2>{t('refresh.title')}</h2>
          </div>
          <p>{t('refresh.description')}</p>
          {!refreshScheduleIsSetted && (
            <Button
              variant="secondary"
              color="blue"
              size="m"
              onClick={handleRefreshAdOnce}
              loading={isRefreshing}
              disabled={isScheduling}
            >
              {t('refresh.refreshFor', {
                price: t('priceCurrency', {
                  price: numberWithSpaces(refreshPrice, lang),
                  currency: CURRENCY_DISPLAY.aed
                })
              })}
            </Button>
          )}
        </div>
        <div className={cls.schedule}>
          <p>{t('refresh.schedule')}</p>

          <ToggleButtonGroup
            value={selectedWeekDays}
            onChange={handleWeekDaysChange}
            multiple
          >
            {weekDays.map((weekDay) => (
              <ToggleButton key={weekDay.id} value={weekDay.id}>
                {weekDay.short_name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <PrimarySelect
            name={`${advert.id}-refresh-select`}
            value={selectedHoursOption}
            onChange={handleHoursOptionChange}
            options={hoursOptions}
            multiple={false}
            placeholder={t('refresh.time')}
            dropdownFullWidth
          />

          <Button
            color="blue"
            size="m"
            disabled={!sheduleOptionsSelected || isRefreshing || isCanceling}
            onClick={handleSetRefreshSchedule}
            loading={isScheduling}
          >
            {t('common.save')}
          </Button>

          {sheduleOptionsSelected && (
            <>
              <p className={cls.caption}>{t('refresh.upInSelectedDays')}</p>

              <p className={cls.caption}>
                {t('refresh.price', {
                  price: t('priceCurrency', {
                    price: numberWithSpaces(refreshPrice, lang),
                    currency: CURRENCY_DISPLAY.aed
                  })
                })}
              </p>
            </>
          )}

          {refreshScheduleIsSetted && (
            <Button
              variant="secondary"
              color="black"
              size="m"
              onClick={handleCancelRefreshSchedule}
              loading={isCanceling}
            >
              {t('refresh.cancelSchedule')}
            </Button>
          )}
        </div>
      </div>
    </AdaptiveModal>
  );
}
