import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { Phone } from '@/modules/showroom/advert/create/Phone/Phone';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function PhoneWrap() {
  const contacts = useRentUpdateAdvertStore((s) => s.contacts);
  const setContacts = useRentUpdateAdvertStore((s) => s.setContacts);
  const disableChat = useRentUpdateAdvertStore((s) => s.disableChat);
  const setDisableChat = useRentUpdateAdvertStore((s) => s.setDisableChat);
  const isPhoneFilled = useRentUpdateAdvertStore((s) => s.isPhoneFilled);
  const setPhoneFilled = useRentUpdateAdvertStore((s) => s.setPhoneFilled);

  return (
    <Phone
      nextSection={SCROLLTO.stickers}
      contacts={contacts}
      setContacts={setContacts}
      disableChat={disableChat}
      setDisableChat={setDisableChat}
      isPhoneFilled={isPhoneFilled}
      setPhoneFilled={setPhoneFilled}
      hideChat
    />
  );
}
