import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDebounce } from '@/hooks/useDebounce';
import { createAdvertisement } from '@/modules/showroom/advert/create/api';
import { getAuthorDetail } from '@/modules/showroom/advert/detail/api';
import { createRentAdvertisement } from '@/modules/showroom/advert/rent/api';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { CARS_CATEGORY_ID } from '@/modules/showroom/advert/search/consts';
import { updateAdvertisement } from '@/modules/showroom/advert/update/api';
import {
  makeUpdateAdvertReq,
  makeUpdateAdvertReqForVrp
} from '@/modules/showroom/advert/update/helpers';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { UpdateAdvertReq } from '@/modules/showroom/advert/update/types';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';
import { useTransportTypes } from '@/modules/transport/api';
import { showAlert } from '@/utils/network';

const AUTOSAVE_DEBOUNCE = 5000;

export const useCarAdvertAutosave = () => {
  const [autosaveDisabled, setAutosaveDisabled] = useState(false);

  const store = useUpdateAdvertStore((s) => s);
  const debouncedStore = useDebounce(store, AUTOSAVE_DEBOUNCE);

  const brand = useUpdateAdvertStore((s) => s.brand);
  const model = useUpdateAdvertStore((s) => s.model);

  const advertId = useUpdateAdvertStore((s) => s.advertId);

  const updateDraftAdvert = useCallback(async () => {
    if (!advertId || !debouncedStore.detail) return;

    const r = await getAuthorDetail(advertId);
    const req: UpdateAdvertReq = makeUpdateAdvertReq(debouncedStore, r.data);

    try {
      await updateAdvertisement(advertId, req);
    } catch (error) {
      setAutosaveDisabled(true);
    }
  }, [advertId, debouncedStore]);

  useEffect(() => {
    if (!brand || !model || autosaveDisabled) return;
    if (advertId) updateDraftAdvert();
  }, [advertId, autosaveDisabled, brand, model, updateDraftAdvert]);
};

export const useVrpAdvertAutosave = () => {
  const [autosaveDisabled, setAutosaveDisabled] = useState(false);

  const store = useUpdateVrpStore((s) => s);
  const debouncedStore = useDebounce(store, AUTOSAVE_DEBOUNCE);

  const emirateId = useUpdateVrpStore((s) => s.emirateId);
  const advertId = useUpdateVrpStore((s) => s.advertId);

  const updateDraftAdvert = useCallback(async () => {
    if (!advertId || !debouncedStore.detail) return;

    const r = await getAuthorDetail(advertId);
    const req: UpdateAdvertReq = makeUpdateAdvertReqForVrp(
      debouncedStore,
      r.data
    );

    try {
      await updateAdvertisement(advertId, req);
    } catch (error) {
      setAutosaveDisabled(true);
    }
  }, [advertId, debouncedStore]);

  useEffect(() => {
    if (!emirateId || autosaveDisabled) return;

    if (advertId) updateDraftAdvert();
  }, [advertId, autosaveDisabled, emirateId, updateDraftAdvert]);
};

// Creation
export function useAdvertCreation(onCreate?: () => void) {
  const navigate = useNavigate();

  const [types] = useTransportTypes();

  const updateVrpStore = useUpdateVrpStore((s) => s);
  const updateAdvertStore = useUpdateAdvertStore((s) => s);
  const rentUpdateAdvertStore = useRentUpdateAdvertStore((s) => s);

  // Plate
  const [plateCreating, setPlateCreating] = useState(false);
  const createPlate = async () => {
    if (plateCreating) return;
    setPlateCreating(true);

    try {
      const { data } = await createAdvertisement({
        category_id: VRP_CATEGORY_ID,
        type_id: null,
        emirate_id: null,
        vrp_type_id: null,
        vrp_plate_number: null,
        vrp_image_url: null,
        vrp_plate_code_id: null
      });
      updateVrpStore.resetAll();
      navigate(`/vrp/edit/${data.id}`);
      if (onCreate) onCreate();
    } catch (error) {
      showAlert({ error });
    } finally {
      setPlateCreating(false);
    }
  };

  // Car
  const [carCreating, setCarCreating] = useState(false);
  const createCar = async (typeId?: number) => {
    if (carCreating) return;
    const type = types?.find((tp) => tp.id === typeId);
    setCarCreating(true);

    try {
      const { data } = await createAdvertisement({
        category_id: CARS_CATEGORY_ID,
        type_id: typeId || null,
        emirate_id: null,
        vrp_type_id: null,
        vrp_plate_number: null,
        vrp_image_url: null,
        vrp_plate_code_id: null
      });
      updateAdvertStore.resetAll(true);
      if (type) updateAdvertStore.setType(type);
      navigate(`/cars/edit/${data.id}`);
      if (onCreate) onCreate();
    } catch (error) {
      showAlert({ error });
    } finally {
      setCarCreating(false);
    }
  };

  // Rent car
  const [rentCarCreating, setRentCarCreating] = useState(false);
  const createRentCar = async (categoryId: number) => {
    if (rentCarCreating) return;
    setRentCarCreating(true);

    try {
      const { data } = await createRentAdvertisement(categoryId);
      rentUpdateAdvertStore.resetAll(true);
      navigate(`/cars/rent/edit/${data.id}`);
      if (onCreate) onCreate();
    } catch (error) {
      showAlert({ error });
    } finally {
      setRentCarCreating(false);
    }
  };

  return {
    plateCreating,
    createPlate,
    carCreating,
    createCar,
    rentCarCreating,
    createRentCar
  };
}
