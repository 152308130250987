import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { DiscountType } from '@/modules/discount/types';
import {
  isDiscountsValid,
  isPriceValid,
  MAX_PRICE_LEN,
  MIN_PRICE
} from '@/modules/showroom/advert/create/helpers';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import {
  isVrpPriceValid,
  MIN_VRP_PRICE,
  VRP_CATEGORY_ID
} from '@/modules/showroom/advert/vrp/helpers';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { Input } from '@/ui/Input/Input';
import { ADV_CUR, CURRENCY_DISPLAY } from '@/utils/consts';
import { maxLength, numberWithSpaces, onlyNumbers } from '@/utils/format';

import cls from './PriceEdit.module.scss';

type Props = {
  discountTypes: DiscountType[];
  advert: MyAdvert;
  isOpen: boolean;
  close: () => void;
  parentCls: string;
  savePrice: (updatedAdvert: MyAdvert) => void;
  loading: boolean;
  euroRate?: Nullable<number>;
  usdRate?: Nullable<number>;
};

export function PriceEdit({
  discountTypes: allDiscountTypes,
  advert,
  isOpen,
  close,
  parentCls,
  savePrice,
  loading,
  euroRate,
  usdRate
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();
  const isVrp = advert.category.id === VRP_CATEGORY_ID;

  const [top, setTop] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  // Price
  const [price, setPrice] = useState(advert.price ? String(advert.price) : '');
  const priceNum = Number(price || 0);

  const maxPriceLen = MAX_PRICE_LEN;
  const [priceTouched, setPriceTouched] = useState(false);
  const checkPriceValid = isVrp ? isVrpPriceValid : isPriceValid;
  const showPriceError =
    priceTouched && !checkPriceValid(Number(onlyNumbers(price)));

  // Discount
  const [discount, setDiscount] = useState(
    advert.discount ? String(advert.discount.discount_amount) : ''
  );
  const discNum = Number(discount || 0);
  const [discountTypes, setDiscountTypes] = useState(
    advert.discount ? advert.discount.discounts?.map((d) => d.id) || [] : []
  );

  const [showDiscounts, setShowDiscounts] = useState(
    !(!discNum && discountTypes.length <= 0)
  );
  const [discountTouched, setDiscountTouched] = useState(false);
  const showDiscountError = discountTouched && discNum > priceNum;
  const discountsValid = isDiscountsValid(priceNum, discNum, discountTypes);

  const toggleType = (id: number) => {
    return () => {
      if (discountTypes.includes(id)) {
        setDiscountTypes(discountTypes.filter((dt) => dt !== id));
      } else {
        setDiscountTypes([...discountTypes, id]);
      }
    };
  };

  const onRemoveDiscountClick = () => {
    setDiscount('');
    setDiscountTypes([]);
    setShowDiscounts(false);
  };

  const onAddDiscountClick = () => {
    setShowDiscounts(true);
  };

  // Currency
  const euroPrice = euroRate ? Number.parseInt(String(priceNum / euroRate)) : 0;
  const usdPrice = usdRate ? Number.parseInt(String(priceNum / usdRate)) : 0;

  // Submit
  const onSubmit = () => {
    savePrice({
      ...advert,
      price: priceNum,
      discount: {
        discount_amount: discNum,
        discounts: allDiscountTypes.filter((d) => discountTypes.includes(d.id))
      }
    });
    close();
  };

  useEffect(() => {
    setPrice(advert.price ? String(advert.price) : '');
    setPriceTouched(false);
  }, [advert]);

  useEffect(() => {
    if (!rootRef.current) return;

    const { top } = rootRef.current.getBoundingClientRect();
    setTop(top > 474);
  }, []);

  return (
    <Dropdown
      name={`${advert.id}-price-edit`}
      isOpen={isOpen}
      close={close}
      parentCls={parentCls}
      className={cls.dropdown}
      listTop={top}
      toLeft
    >
      <li>
        <div className={cls.root} ref={rootRef}>
          <h2 className={cls.title}>{t('price')}</h2>
          <div className={cls.field}>
            <Input
              value={priceNum ? numberWithSpaces(priceNum, lang) : ''}
              onChange={(e) => {
                setPrice(
                  maxLength(onlyNumbers(e.currentTarget.value), maxPriceLen)
                );
              }}
              onBlur={() => setPriceTouched(true)}
              errorText={
                showPriceError
                  ? t('priceMin', {
                      minPrice: isVrp ? MIN_VRP_PRICE : MIN_PRICE,
                      currency: ADV_CUR
                    })
                  : ''
              }
              label={t('pricePlaceholder', { currency: ADV_CUR })}
              inputMode="numeric"
            />

            {!!price && !showPriceError && (!!usdPrice || !!euroPrice) && (
              <p className={cls.text}>
                ~ {CURRENCY_DISPLAY.usd}
                {numberWithSpaces(usdPrice, lang)} {t('common.dot')}{' '}
                {CURRENCY_DISPLAY.euro}
                {numberWithSpaces(euroPrice, lang)}
              </p>
            )}
          </div>

          {showDiscounts && (
            <div className={cls.discounts}>
              <h2 className={cls.title}>{t('discount.title')}</h2>

              {allDiscountTypes && (
                <>
                  <ul className={cls.types}>
                    {allDiscountTypes.map((dt) => (
                      <li key={dt.id}>
                        <Checkbox
                          id={`price-disc-type-${advert.id}-${dt.id}`}
                          checked={discountTypes.includes(dt.id)}
                          onChange={toggleType(dt.id)}
                        >
                          {dt.name}
                        </Checkbox>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              <div className={cls.field}>
                <Input
                  value={discNum ? numberWithSpaces(discNum, lang) : ''}
                  onChange={(e) => {
                    setDiscount(onlyNumbers(e.currentTarget.value));
                  }}
                  onBlur={() => setDiscountTouched(true)}
                  errorText={showDiscountError ? t('discount.amountError') : ''}
                  label={t('common.commaSeparated', {
                    first: t('discount.amount'),
                    second: CURRENCY_DISPLAY.aed
                  })}
                  inputMode="numeric"
                />
                {discNum < priceNum && !!priceNum && !!discNum && (
                  <span className={cls.text}>
                    {t('discount.price', {
                      amount: numberWithSpaces(priceNum - discNum, lang),
                      currency: CURRENCY_DISPLAY.aed
                    })}
                  </span>
                )}
              </div>
            </div>
          )}

          <div className={cls.buttons}>
            {showDiscounts ? (
              <Button
                onClick={onRemoveDiscountClick}
                variant="secondary"
                color="black"
                size="s"
                fullWidth
              >
                {t('discount.remove')}
              </Button>
            ) : (
              <Button
                onClick={onAddDiscountClick}
                variant="secondary"
                color="black"
                size="s"
                fullWidth
              >
                {t('discount.add')}
              </Button>
            )}

            <Button
              loading={loading}
              disabled={
                loading ||
                !!showPriceError ||
                (showDiscounts ? !discountsValid : false)
              }
              onClick={onSubmit}
              color="green"
              size="s"
              fullWidth
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
      </li>
    </Dropdown>
  );
}
