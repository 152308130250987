import cn from 'classnames';

import {
  CommonModal,
  CommonModalProps
} from '@/ui/modals/CommonModal/CommonModal';

import cls from './BottomModal.module.scss';

export const BottomModal: React.FC<CommonModalProps> = ({
  containerClass,
  children,
  ...rest
}) => {
  return (
    <CommonModal containerClass={cn(cls.container, containerClass)} {...rest}>
      {children}
    </CommonModal>
  );
};
