export function StarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.89217 13.6765C4.16923 13.8869 4.51787 13.818 4.91722 13.5333L7.99943 11.3171L11.0843 13.5333C11.4858 13.818 11.8285 13.8869 12.1105 13.6765C12.3864 13.4719 12.4451 13.1358 12.2853 12.6794L11.0671 9.13832L14.1768 6.9532C14.5772 6.6753 14.7435 6.37233 14.6338 6.04509C14.5251 5.72632 14.2104 5.56954 13.7168 5.57638L9.90294 5.6006L8.74411 2.04003C8.59015 1.57479 8.34805 1.33331 7.99943 1.33331C7.65455 1.33331 7.41246 1.57479 7.25855 2.04003L6.09967 5.6006L2.28477 5.57638C1.79226 5.56954 1.47752 5.72632 1.36885 6.04405C1.25319 6.37233 1.42538 6.6753 1.8258 6.9532L4.93548 9.13832L3.71738 12.6794C3.55751 13.1358 3.61724 13.4719 3.89217 13.6765Z"
        fill="currentColor"
      />
    </svg>
  );
}
