import { useTranslation } from 'react-i18next';

import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { useTransportOwners } from '@/modules/transport/owner/hooks';
import { TransportOwner } from '@/modules/transport/types';
import { Button } from '@/ui/Button/Button';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';

import cls from './Owner.module.scss';

type Props = {
  owner: CreateAdvertSlice['owner'];
  setOwner: CreateAdvertSlice['setOwner'];
  isOwnerFilled: CreateAdvertSlice['isOwnerFilled'];
  setOwnerFilled: CreateAdvertSlice['setOwnerFilled'];
};

export function Owner({
  owner,
  setOwner,
  isOwnerFilled,
  setOwnerFilled
}: Props) {
  const { t } = useTranslation();

  const owners = useTransportOwners();
  const onOwnerClick = (o: TransportOwner) => {
    return () => {
      setOwner(o);
    };
  };
  const onClick = () => {
    setOwnerFilled(true);
    scrollToSection(SCROLLTO.regspecs, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.owner}>
      <div className="box">
        <h1 className={cls.title}>{t('owner.multiple')}</h1>

        <ul className={cls.list}>
          {owners.map((o) => (
            <li key={o.id}>
              <CheckBtn active={owner?.id === o.id} onClick={onOwnerClick(o)}>
                {o.name}
              </CheckBtn>
            </li>
          ))}
        </ul>

        {!isOwnerFilled && owner && (
          <Button onClick={onClick} variant="primary" color="blue" fullWidth>
            {t('common.continue')}
          </Button>
        )}
      </div>
    </div>
  );
}
