import { useState, useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { useRefState } from '@/hooks/useRefState';
import { GetMyAdvertsQueryReq } from '@/modules/showroom/advert/my/types';
import {
  parseResultsParam,
  defaultSearchReq
} from '@/modules/showroom/advert/search/helpers';
import {
  SearchAdvert,
  SearchAdvertsCountReq,
  SearchAdvertsCountRes,
  SearchAdvertsReq,
  SearchAdvertsRes
} from '@/modules/showroom/advert/search/types';
import {
  AdvertExistedStatus,
  AdvertStatusType
} from '@/modules/showroom/advert/types';
import { showAlert } from '@/utils/network';

export const LOAD_COUNT = 20;

// Search adverts
type UseSearchAdvertsPg = {
  isAllLoaded: boolean;
  isLoading: boolean;
  isLoadingRef: React.MutableRefObject<boolean>;
  ads: SearchAdvert[] | undefined;
  load: (skip: number) => void;
};

export function useSearchAdvertsPg(
  req: SearchAdvertsReq,
  status: Nullable<AdvertExistedStatus> | undefined,
  search?: string
): UseSearchAdvertsPg {
  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoading, setLoading, isLoadingRef] = useRefState<boolean>(false);
  const [ads, setAds] = useState<SearchAdvertsRes>();

  const abortController = useRef<Nullable<AbortController>>(null);

  const load = useCallback(
    async (skip: number) => {
      if (isLoadingRef.current) return;
      if (status === undefined) return;

      setLoading(true);
      if (!skip) setAds([]);

      try {
        const queryReq: GetMyAdvertsQueryReq = {
          search: search || null,
          status: status?.status || null,
          skip,
          limit: LOAD_COUNT
        };

        if (abortController.current) abortController.current.abort();
        const ab = new window.AbortController();
        abortController.current = ab;

        const r = await axios.post<SearchAdvertsRes>(
          '/dealers/showrooms/advertisements/my',
          req,
          { params: queryReq, signal: ab.signal }
        );
        const loaded = r.data;
        const len = loaded.length;

        setAds((prev) => {
          if (!prev || skip === 0) {
            return loaded;
          }

          return [...prev, ...loaded];
        });
        setAllLoaded(len < LOAD_COUNT);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [req, status, search]
  );

  return { isAllLoaded, isLoading, isLoadingRef, ads, load };
}

export function useSearchAdvertsCount(
  req: SearchAdvertsCountReq,
  status: Nullable<AdvertStatusType>
): [SearchAdvertsCountRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SearchAdvertsCountRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.post(
        '/dealers/showrooms/advertisements/my/count',
        req,
        { params: { status } }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [req, status]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useSearchReq(): [SearchAdvertsReq, boolean] {
  const [searchParams] = useSearchParams();
  // const req = paramsToSearchReq(parseSearchParams(searchParams));
  const req = defaultSearchReq;
  const showResults = parseResultsParam(searchParams);
  return [req, showResults];
}
