export function InfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99677 18.328C14.5609 18.328 18.329 14.5548 18.329 9.99726C18.329 5.43315 14.5543 1.6665 9.99016 1.6665C5.43411 1.6665 1.66602 5.43315 1.66602 9.99726C1.66602 14.5548 5.44072 18.328 9.99677 18.328ZM9.99677 16.687C6.28447 16.687 3.31367 13.7095 3.31367 9.99726C3.31367 6.28496 6.27786 3.30755 9.99016 3.30755C13.7024 3.30755 16.6879 6.28496 16.6879 9.99726C16.6879 13.7095 13.709 16.687 9.99677 16.687Z"
        fill="currentColor"
      />
      <path
        d="M8.58584 14.4339H11.832C12.1888 14.4339 12.4686 14.1745 12.4686 13.8134C12.4686 13.4727 12.1888 13.2024 11.832 13.2024H10.9335V9.39203C10.9335 8.91586 10.6955 8.60087 10.2464 8.60087H8.72734C8.36907 8.60087 8.09067 8.87264 8.09067 9.2133C8.09067 9.57447 8.36907 9.83379 8.72734 9.83379H9.54238V13.2024H8.58584C8.22907 13.2024 7.94922 13.4727 7.94922 13.8134C7.94922 14.1745 8.22907 14.4339 8.58584 14.4339ZM9.94499 7.35352C10.554 7.35352 11.0341 6.86544 11.0341 6.26073C11.0341 5.64876 10.554 5.16357 9.94499 5.16357C9.34687 5.16357 8.85734 5.64876 8.85734 6.26073C8.85734 6.86544 9.34687 7.35352 9.94499 7.35352Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.5002C8.62826 17.5002 8.97342 17.3176 9.21323 16.9518L17.1225 4.62183C17.2997 4.34449 17.3708 4.11242 17.3708 3.88406C17.3708 3.30464 16.9633 2.90479 16.3779 2.90479C15.9625 2.90479 15.7214 3.04446 15.4694 3.43994L8.15705 15.057L4.38887 10.2119C4.14367 9.88716 3.88636 9.75116 3.52286 9.75116C2.92207 9.75116 2.5 10.1717 2.5 10.7526C2.5 11.0022 2.59323 11.2543 2.80309 11.5093L7.16264 16.9637C7.45294 17.3267 7.76679 17.5002 8.19071 17.5002Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function UncheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.82792 16.1721C4.17492 16.5111 4.76018 16.5067 5.09058 16.1763L10.0024 11.2646L14.9113 16.1749C15.2474 16.5111 15.832 16.5162 16.1725 16.1706C16.513 15.8236 16.5144 15.2521 16.1782 14.9145L11.2693 9.9976L16.1782 5.08869C16.5144 4.75254 16.5195 4.17446 16.1725 3.834C15.8255 3.487 15.2474 3.48556 14.9113 3.82826L10.0024 8.73717L5.09058 3.82682C4.76018 3.49131 4.16837 3.47901 3.82792 3.83257C3.48889 4.17957 3.49321 4.75685 3.8236 5.08725L8.73539 9.9976L3.8236 14.9174C3.49321 15.2463 3.48235 15.8316 3.82792 16.1721Z"
        fill="currentColor"
      />
    </svg>
  );
}
