import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useAccountBalance } from '@/modules/accounts/balance/useAccountBalance';
import { useAccountStore } from '@/modules/accounts/store';
import { PromotionPackage } from '@/modules/promotion/types';
import {
  OptionIcon,
  CloseIcon as OptionXIcon
} from '@/modules/showroom/advert/create/Promotion/icons';
import { TopUpModal } from '@/modules/showroom/tariff/TopUpModal/TopUpModal';
import { Button } from '@/ui/Button/Button';
import { Switch } from '@/ui/Switch/Switch';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces, pluralKey } from '@/utils/format';

import cls from './ActivatePromo.module.scss';
import { CloseIcon, DurationIcon, PlusIcon, RefreshIcon } from './icons';

type Props = {
  close?: () => void;
  promoPackage: PromotionPackage;
  onConfirmClick: () => void;
  loading?: boolean;
  autoExtension: boolean;
  setAutoExtension: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ActivatePromo({
  close,
  promoPackage,
  onConfirmClick,
  loading,
  autoExtension,
  setAutoExtension
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { balanceAmount, balanceLoading } = useAccountBalance();
  const fetchBalance = useAccountStore((s) => s.fetchBalance);
  const isBalanceValid = !!promoPackage && promoPackage.price <= balanceAmount;

  const [isTopUpOpen, setTopUpOpen] = useState(false);
  const onTopUpConfirm = () => {
    fetchBalance();
  };

  const buttonText = t('advertisements.promo.connectPrice', {
    price: t('priceCurrency', {
      price: numberWithSpaces(promoPackage.price, lang),
      currency: ADV_CUR
    })
  });

  return (
    <>
      <div className={cls.root}>
        <div className={cls.header}>
          {promoPackage.logo_url && (
            <img
              className={cls.package_icon}
              src={promoPackage.logo_url}
              alt=""
            />
          )}
          <h2 className={cls.title}>
            {t('advertisements.promo.connect', { name: promoPackage.name })}
          </h2>

          {close && (
            <button className={cls.close_btn} type="button" onClick={close}>
              <CloseIcon />
            </button>
          )}
        </div>

        {promoPackage.options && (
          <ul className={cls.options}>
            {promoPackage.options.map((opt) => (
              <li key={opt.id}>
                <p
                  className={cn(cls.option, {
                    [cls.option_included]: opt.include
                  })}
                >
                  {opt.name}
                  {opt.include ? <OptionIcon /> : <OptionXIcon />}
                </p>
              </li>
            ))}
          </ul>
        )}

        <div className={cls.autoext}>
          <div className={cls.autoext_text}>
            <p>{t('advertisements.promo.autoextTitle')}</p>
            <small>{t('advertisements.promo.autoextText')}</small>
          </div>
          <Switch id="publish-autoext" checked={autoExtension} green readOnly />
          <button
            onClick={() => setAutoExtension(!autoExtension)}
            type="button"
          />
        </div>

        {isBalanceValid ? (
          <div className={cls.row}>
            <Button
              onClick={onConfirmClick}
              disabled={balanceLoading || loading}
              loading={balanceLoading || loading}
              color="green"
            >
              {buttonText}
            </Button>
          </div>
        ) : (
          <>
            <div className={cls.balance_container}>
              <div className={cls.balance_wrap}>
                <b className={cls.balance}>
                  {t('priceCurrency', {
                    price: numberWithSpaces(balanceAmount, lang),
                    currency: ADV_CUR
                  })}
                </b>

                <button
                  className={cls.plus_btn}
                  type="button"
                  onClick={() => setTopUpOpen(true)}
                >
                  <PlusIcon />
                </button>
              </div>

              <div className={cls.action_wrap}>
                <button
                  className={cls.update_btn}
                  type="button"
                  onClick={fetchBalance}
                  disabled={balanceLoading}
                >
                  <RefreshIcon />
                  {t('wallet.refreshBalance')}
                </button>
                <span />
                <p>{t('wallet.notEnough')}</p>
              </div>
            </div>

            <Button
              disabled
              loading={balanceLoading || loading}
              fullWidth
              color="green"
            >
              {buttonText}
            </Button>
          </>
        )}

        {promoPackage.valid_days && (
          <p className={cls.duration}>
            <DurationIcon />
            {pluralKey(
              promoPackage.valid_days,
              t('advertisements.boost.daysPlural.one', {
                days: promoPackage.valid_days
              }),
              t('advertisements.boost.daysPlural.few', {
                days: promoPackage.valid_days
              }),
              t('advertisements.boost.daysPlural.many', {
                days: promoPackage.valid_days
              })
            )}
          </p>
        )}
      </div>

      <TopUpModal
        isOpen={isTopUpOpen}
        setOpen={setTopUpOpen}
        onConfirm={onTopUpConfirm}
      />
    </>
  );
}
