import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { MyReview } from '@/modules/reviews/types';
import { Avatar } from '@/ui/Avatar/Avatar';

import { StarIcon } from './icons';
import cls from './ReviewCard.module.scss';

const stars = new Array(5).fill(0);

type Props = {
  review: MyReview;
};

export function ReviewAuthor({ review }: Props) {
  const { t } = useTranslation();

  const comment = review.comments.reviewer;
  const avatarSrc =
    comment.avatar?.thumbnail_url || comment.avatar?.photo_url || '';

  return (
    <div className={cls.author}>
      <Avatar
        src={avatarSrc}
        user={{ id: comment.id, name: comment.name || '' }}
        size={36}
      />
      <div className={cls.review_info}>
        <b className={cls.name}>{comment.name}</b>
        <div className={cls.stars_wrap}>
          <ul className={cls.stars}>
            {stars.map((_, i) => (
              <li key={i}>
                <span
                  className={cn(cls.star, {
                    [cls.star_fill]: i + 1 <= review.count_star_id
                  })}
                >
                  <StarIcon />
                </span>
              </li>
            ))}
          </ul>
          <p
            className={cn(cls.deal_status, {
              [cls.deal_status_green]: review.deal_done
            })}
          >
            {t(
              review.deal_done
                ? 'profile.reviews.dealDone'
                : 'profile.reviews.dealNotDone'
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
