export function ChevronIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5602 16.6064C15.0077 17.0539 15.0077 17.7794 14.5602 18.2269C14.1128 18.6744 13.3872 18.6744 12.9398 18.2269L6.52311 11.8102C6.07563 11.3628 6.07563 10.6372 6.52311 10.1898L12.9398 3.77311C13.3872 3.32563 14.1128 3.32563 14.5602 3.77311C15.0077 4.22058 15.0077 4.94608 14.5602 5.39356L8.95379 11L14.5602 16.6064Z"
        fill="currentColor"
      />
    </svg>
  );
}
