import type { ApexOptions } from 'apexcharts';

import { getDateString } from '@/utils/date';

export function getApexOptions(
  xCategories: number[],
  lang: string,
  isDark: boolean,
  axisColor: string
): ApexOptions {
  return {
    colors: ['#007AFF'],
    stroke: {
      width: 2
    },
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      parentHeightOffset: 0
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      axisBorder: {
        color: axisColor
      },
      axisTicks: {
        color: axisColor
      },
      tooltip: {
        enabled: false
      },
      type: 'datetime',
      categories: xCategories,
      labels: {
        formatter: (value) =>
          getDateString(new Date(value), lang, {
            day: '2-digit',
            month: '2-digit'
          })
      }
    },
    yaxis: {
      show: false
    },
    tooltip: {
      theme: isDark ? 'dark' : 'light',
      x: {
        formatter: (value) => getDateString(new Date(value), lang)
      },
      y: {
        formatter: (value) => String(Number(value))
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    fill: {
      gradient: {
        shade: isDark ? 'dark' : 'light'
      }
    }
  };
}
