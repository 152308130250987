export function ChevronIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58602 7.99399C3.42417 7.8356 3.33398 7.6245 3.33398 7.38136C3.33398 6.89103 3.71429 6.50798 4.2033 6.50798C4.44915 6.50798 4.67589 6.60016 4.83488 6.77148L10.3723 12.4388H9.63586L15.1678 6.77148C15.3316 6.59881 15.5597 6.50798 15.7981 6.50798C16.2857 6.50798 16.6673 6.89103 16.6673 7.38136C16.6673 7.62585 16.5785 7.83696 16.414 7.99399L10.6822 13.8599C10.4919 14.0612 10.2604 14.1643 10.0044 14.167C9.74366 14.167 9.51693 14.0625 9.32045 13.8599L3.58602 7.99399Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2468 2.76178C16.3272 1.84377 15.0515 1.66699 13.4235 1.66699H6.55318C4.94879 1.66699 3.6715 1.84377 2.75188 2.76178C1.83385 3.6798 1.66602 4.94494 1.66602 6.55415V13.4245C1.66602 15.0557 1.83385 16.3208 2.75188 17.2388C3.67311 18.1569 4.94879 18.3337 6.56783 18.3337H13.4235C15.0515 18.3337 16.3288 18.1569 17.2468 17.2388C18.1648 16.3192 18.3327 15.0557 18.3327 13.4245V6.56881C18.3327 4.93761 18.1648 3.6782 17.2468 2.76178ZM16.5817 6.32591V13.6747C16.5817 14.6033 16.4639 15.4598 15.9622 15.9542C15.4678 16.4544 14.6023 16.5827 13.6754 16.5827H6.32493C5.39797 16.5827 4.53089 16.4544 4.02916 15.9542C3.53636 15.4598 3.41696 14.6033 3.41696 13.6747V6.34789C3.41696 5.39573 3.53636 4.5408 4.02916 4.03907C4.53089 3.54627 5.4053 3.41794 6.34692 3.41794H13.6754C14.6023 3.41794 15.4678 3.54627 15.9622 4.0464C16.4639 4.5408 16.5817 5.39573 16.5817 6.32591Z"
        fill="currentColor"
      />
      <path
        d="M12.7933 12.3296C13.238 12.3296 13.5246 11.9919 13.5246 11.5197V7.35929C13.5246 6.74836 13.1822 6.47363 12.6283 6.47363H8.44432C7.95993 6.47363 7.6516 6.76015 7.6516 7.20487C7.6516 7.64958 7.96565 7.9361 8.45415 7.9361H9.96329L11.1894 7.7931L9.86125 9.00695L6.73 12.1455C6.57915 12.2947 6.47852 12.5073 6.47852 12.7166C6.47852 13.1879 6.80203 13.4953 7.25478 13.4953C7.49662 13.4953 7.69538 13.4069 7.85589 13.248L10.979 10.1264L12.1856 8.81282L12.0547 10.104V11.5312C12.0547 12.0123 12.3413 12.3296 12.7933 12.3296Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PlusIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 9.99966C3.33398 10.4889 3.73802 10.8879 4.2222 10.8879H9.11386V15.7795C9.11386 16.2623 9.51136 16.6663 10.0006 16.6663C10.4899 16.6663 10.8954 16.2623 10.8954 15.7795V10.8879H15.7805C16.2633 10.8879 16.6673 10.4889 16.6673 9.99966C16.6673 9.51039 16.2633 9.10492 15.7805 9.10492H10.8954V4.22122C10.8954 3.73704 10.4899 3.33301 10.0006 3.33301C9.51136 3.33301 9.11386 3.73704 9.11386 4.22122V9.10492H4.2222C3.73802 9.10492 3.33398 9.51039 3.33398 9.99966Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 9V13C9 13.5523 8.55228 14 8 14C7.44772 14 7 13.5523 7 13V9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H7V3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3V7H13C13.5523 7 14 7.44772 14 8C14 8.55228 13.5523 9 13 9H9Z"
        fill="currentColor"
      />
    </svg>
  );
}
