import { Nullable } from '@/app/types';
import { Brand } from '@/modules/showroom/advert/create/Brand/Brand';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { TransportBrand } from '@/modules/transport/types';

type Props = {
  brands: TransportBrand[];
};

export function BrandWrap({ brands }: Props) {
  const disableCoreChanges = useUpdateAdvertStore((s) => s.disableCoreChanges);
  const brand = useUpdateAdvertStore((s) => s.brand);
  const setBrand = useUpdateAdvertStore((s) => s.setBrand);
  const resetBrandKey = useUpdateAdvertStore((s) => s.resetBrandKey);
  const resetAll = useUpdateAdvertStore((s) => s.resetAll);

  const onBrandChange = (b: Nullable<TransportBrand>) => {
    if (!b) {
      resetAll(true);
    } else {
      setBrand(b);
    }
  };

  return (
    <Brand
      brands={brands}
      brand={brand}
      onChange={onBrandChange}
      key={resetBrandKey}
      disabled={disableCoreChanges()}
    />
  );
}
