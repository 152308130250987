/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { usePromotionsList } from '@/hooks/usePromotionsList';
import { usePromotionPackages } from '@/modules/promotion/hooks';
import { PromotionPackageWithEndDate } from '@/modules/promotion/types';
import { NO_PROMO_ID } from '@/modules/showroom/advert/create/helpers';
import { ActivatePromoModal } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/ActivatePromo/ActivatePromoModal';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { PromoRow } from '@/modules/showroom/advert/my/UnpublishAdvert/PromoList/PromoRow';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './PromoList.module.scss';

type Props = {
  advertId: MyAdvert['id'];
  promotionPackage: Nullable<PromotionPackageWithEndDate>;
  setPromoActivateOpen: (v: boolean) => void;
  close: () => void;
};

export const PromoList: React.FC<Props> = ({
  advertId,
  promotionPackage,
  setPromoActivateOpen,
  close
}) => {
  const { t } = useTranslation();

  const [promotionsData, promotionsLoading] = usePromotionPackages();
  const packagesList = promotionsData?.filter((p) => p.id !== NO_PROMO_ID);

  const onPublishToggle = () => {
    close();
  };

  const {
    isLoading,
    isActivateOpen,
    autoExtension,
    setAutoExtension,
    onPromotionClick,
    onActivateClick,
    selected,
    setActivateOpen
  } = usePromotionsList(advertId, onPublishToggle);

  useEffect(() => {
    setPromoActivateOpen(isActivateOpen);
  }, [isActivateOpen]);

  return (
    <div className={cls.root}>
      <p className={cls.text}>{t('unpublish.promo.text')}</p>

      {!promotionsLoading && (
        <ul className={cls.list}>
          {packagesList?.map((promo) => (
            <li key={promo.id}>
              <PromoRow
                promo={promo}
                disabled={
                  !!promotionPackage && promotionPackage.id === promo.id
                }
                onPromotionClick={onPromotionClick}
              />
            </li>
          ))}
        </ul>
      )}

      {promotionsLoading && (
        <div className={cls.spinner}>
          <Spinner />
        </div>
      )}

      {selected && (
        <ActivatePromoModal
          isOpen={isActivateOpen}
          close={() => setActivateOpen(false)}
          promoPackage={selected}
          onConfirmClick={onActivateClick}
          loading={isLoading}
          autoExtension={autoExtension}
          setAutoExtension={setAutoExtension}
          hiddenBackdrop
        />
      )}
    </div>
  );
};
