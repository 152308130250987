import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { GraphView } from '@/modules/overview/CountCard/GraphView';
import { useAdvertStatsEngagement } from '@/modules/showroom/advert/my/hooks';
import { Spinner } from '@/ui/Spinner/Spinner';
import { numberWithSpaces } from '@/utils/format';

import cls from './Statistics.module.scss';

const skeletons = new Array(3).fill(null);
const CHART_HEIGHT = 148;
const AXIS_COLOR = 'var(--thm-silver-grey-2)';

type Props = {
  advertId: number;
  rent?: boolean;
};

export function Statistics({ advertId, rent }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [advertStatsEngagement, advertStatsEngagementLoading] =
    useAdvertStatsEngagement(advertId, rent);

  const fromDate = useMemo(() => {
    if (!advertStatsEngagement) return null;
    const { leads, shows, views } = advertStatsEngagement;
    const viewsMin = Math.min(...views.map((v) => v.date));
    const showsMin = Math.min(...shows.map((v) => v.date));
    const leadsMin = Math.min(...leads.map((v) => v.date));
    const min = Math.min(viewsMin, showsMin, leadsMin);
    return new Date(min * 1000);
  }, [advertStatsEngagement]);

  const toDate = useMemo(() => {
    if (!advertStatsEngagement) return null;
    const { leads, shows, views } = advertStatsEngagement;
    const viewsMax = Math.max(...views.map((v) => v.date));
    const showsMax = Math.max(...shows.map((v) => v.date));
    const leadsMax = Math.max(...leads.map((v) => v.date));
    const max = Math.max(viewsMax, showsMax, leadsMax);
    return new Date(max * 1000);
  }, [advertStatsEngagement]);

  const datesRange =
    fromDate && toDate ? ([fromDate, toDate] as [Date, Date]) : undefined;

  // Shows
  const showsTotal = useMemo(() => {
    const summary = advertStatsEngagement
      ? advertStatsEngagement?.shows.reduce((a, b) => a + b.count, 0)
      : 0;
    return numberWithSpaces(summary, lang);
  }, [advertStatsEngagement, lang]);

  // Views (clicks)
  const viewsTotal = advertStatsEngagement
    ? advertStatsEngagement.views.reduce((a, b) => a + b.count, 0)
    : 0;

  const viewsAmount = useMemo(() => {
    return numberWithSpaces(viewsTotal, lang);
  }, [viewsTotal, lang]);

  const viewsConversion = useMemo(() => {
    if (
      !advertStatsEngagement?.shows ||
      advertStatsEngagement.shows.length <= 0
    )
      return '';
    const showsSum = advertStatsEngagement.shows.reduce(
      (a, b) => a + b.count,
      0
    );
    const ratio = Math.round((viewsTotal / showsSum) * 100);

    return t('common.percentValue', { value: ratio });
  }, [advertStatsEngagement?.shows, t, viewsTotal]);

  // Leads
  const leadsTotal = advertStatsEngagement
    ? advertStatsEngagement.leads.reduce((a, b) => a + b.count, 0)
    : 0;

  const leadsAmount = useMemo(() => {
    return numberWithSpaces(leadsTotal, lang);
  }, [leadsTotal, lang]);

  const leadsConversion = useMemo(() => {
    if (
      !advertStatsEngagement?.leads ||
      advertStatsEngagement.leads.length <= 0
    )
      return '';
    const ratio = Math.round((leadsTotal / viewsTotal) * 100);
    return t('common.percentValue', { value: ratio });
  }, [advertStatsEngagement?.leads, leadsTotal, t, viewsTotal]);

  return (
    <div className={cls.root}>
      {advertStatsEngagementLoading &&
        skeletons.map((s, i) => {
          return (
            <div className={cls.centered} key={i}>
              <Spinner color="var(--clr-blue)" />
            </div>
          );
        })}

      {!advertStatsEngagementLoading && (
        <>
          {advertStatsEngagement?.shows && (
            <GraphView
              data={advertStatsEngagement.shows}
              datesRange={datesRange}
              title={t('views.shows')}
              amount={showsTotal}
              tooltipOverlay={t('views.showsTooltip')}
              chartHeight={CHART_HEIGHT}
              axisColor={AXIS_COLOR}
            />
          )}

          {advertStatsEngagement?.views && (
            <GraphView
              data={advertStatsEngagement.views}
              datesRange={datesRange}
              title={t('views.views')}
              amount={viewsAmount}
              conversion={viewsConversion}
              tooltipOverlay={t('views.viewsTooltip')}
              chartHeight={CHART_HEIGHT}
              axisColor={AXIS_COLOR}
            />
          )}

          {advertStatsEngagement?.leads && (
            <GraphView
              data={advertStatsEngagement.leads}
              datesRange={datesRange}
              title={t('views.leads')}
              amount={leadsAmount}
              conversion={leadsConversion}
              tooltipOverlay={t('views.leadsTooltip')}
              chartHeight={CHART_HEIGHT}
              axisColor={AXIS_COLOR}
            />
          )}
        </>
      )}
    </div>
  );
}
