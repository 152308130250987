import cn from 'classnames';
import { forwardRef, useMemo, useState } from 'react';

import { Nullable } from '@/app/types';

import { EyeIcon, EyeStrokeIcon, RemoveIcon } from './icons';
import cls from './Input.module.scss';

export interface Props {
  value: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  oneChar?: boolean;
  small?: boolean;
  placeholder?: Nullable<string>;
  label?: Nullable<string>;
  name?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  inputMode?: React.InputHTMLAttributes<HTMLInputElement>['inputMode'];
  errorText?: Nullable<string>;
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'];
  maxLength?: number;
  highlightFilled?: boolean;
  suffix?: React.ReactNode;
  bottomLabel?: Nullable<string>;
}

export const Input = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const {
      value,
      onChange,
      disabled,
      readOnly,
      required,
      onFocus,
      onBlur,
      onKeyDown,
      onPaste,
      onClear,
      autoFocus,
      oneChar,
      small,
      placeholder,
      name,
      autoComplete,
      label,
      errorText,
      type = 'text',
      inputMode,
      maxLength,
      highlightFilled,
      suffix,
      bottomLabel
    } = props;

    const isPassword = type === 'password';
    const [isPassHidden, setPassHidden] = useState(isPassword);

    const inputType = useMemo(() => {
      if (isPassword) {
        return isPassHidden ? 'password' : 'text';
      }

      return type;
    }, [type, isPassword, isPassHidden]);

    const topPadding = small ? 8 : 12;
    const rightPadding = small ? 8 : 12;
    const oneCharPaddingRight = small ? 8 : 12;

    const [autofillHandled, setAutofillHandled] = useState(false);

    const onAnimationStart = (e: React.AnimationEvent<HTMLInputElement>) => {
      if (e.animationName.includes('onAutoFillStart') && !autofillHandled) {
        setAutofillHandled(true);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setAutofillHandled(false);
      if (onChange) onChange(e);
    };

    return (
      <div
        className={cn(cls.root, {
          [cls.root_filled]: !!value || autofillHandled,
          [cls.root_small]: small,
          [cls.root_onechar]: oneChar
        })}
      >
        <div className={cls.input_wrap}>
          <input
            ref={ref}
            className={cn(cls.input, {
              [cls.input_error]: !!errorText,
              [cls.input_filled]: value && highlightFilled
            })}
            type={inputType}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            onAnimationStart={onAnimationStart}
            placeholder={placeholder || ''}
            disabled={disabled}
            readOnly={readOnly}
            name={name}
            style={{
              paddingRight:
                isPassword || onClear
                  ? 40
                  : oneChar
                  ? oneCharPaddingRight
                  : rightPadding,
              paddingTop: label ? 22 : topPadding
            }}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            inputMode={inputMode}
            maxLength={maxLength}
          />

          <span className={cls.border} />

          {!oneChar && !small && label && (
            <span className={cls.label}>
              {label}
              {required && <span className={cls.required_symbol}>*</span>}
            </span>
          )}

          {isPassword && !onClear && (
            <button
              className={cn(cls.action, cls.action_password)}
              type="button"
              onClick={() => setPassHidden(!isPassHidden)}
            >
              {isPassHidden ? <EyeStrokeIcon /> : <EyeIcon />}
            </button>
          )}

          {!isPassword && value && onClear && (
            <button className={cls.action} type="button" onClick={onClear}>
              <RemoveIcon />
            </button>
          )}

          {suffix && <div className={cls.action}>{suffix}</div>}
        </div>

        <span
          className={cn(cls.error_wrap, {
            [cls.error_wrap_active]: !!errorText
          })}
        >
          {errorText && <span className={cls.error}>{errorText}</span>}
        </span>

        <span
          className={cn(cls.bottom_label_wrap, {
            [cls.bottom_label_active]: !!bottomLabel
          })}
        >
          {bottomLabel && (
            <span className={cls.bottom_label}>{bottomLabel}</span>
          )}
        </span>
      </div>
    );
  }
);

Input.displayName = 'Input';
