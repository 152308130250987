import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { ApiError } from '@/app/types';
import { useShowroomStore } from '@/modules/showroom/store';
import {
  CheckLoginValidityRes,
  CreateShowroomReq,
  CreateShowroomRes,
  GetShowroomRes,
  UpdateShowroomReq
} from '@/modules/showroom/types';
import { showAlert } from '@/utils/network';

const PATH = '/dealers/showrooms';

export function createShowroom(req: CreateShowroomReq) {
  return axios.post<CreateShowroomRes>(PATH, req);
}

export function updateShowroom(req: UpdateShowroomReq) {
  return axios.patch<unknown>(PATH, req);
}

function getShowroom() {
  return axios.get<GetShowroomRes>(PATH);
}

export function useShowroom(): [
  GetShowroomRes | null,
  boolean,
  () => Promise<void>
] {
  const [isLoading, setLoading] = useState(false);
  const showroom = useShowroomStore((s) => s.showroom);
  const setShowroom = useShowroomStore((s) => s.setShowroom);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getShowroom();
      setShowroom(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [setShowroom]);

  return [showroom, isLoading, load];
}

function checkLoginValidity(u: string) {
  return axios.get<CheckLoginValidityRes>(`${PATH}/username/validate`, {
    params: { u }
  });
}

const USERNAME_TAKEN_CODE = 34;

export function useCheckLoginValidity(login: string) {
  const [isValidating, setValidating] = useState(false);
  const [isValid, setValid] = useState(false);

  const check = useCallback(
    async (value: string) => {
      if (!login) {
        setValid(false);
        return;
      }
      setValidating(true);

      try {
        const r = await checkLoginValidity(value);
        setValid(r.data.result);
      } catch (error) {
        setValid(false);
        const e = error as AxiosError<ApiError>;
        if (e.response?.data?.code !== USERNAME_TAKEN_CODE)
          showAlert({ error });
      } finally {
        setValidating(false);
      }
    },
    [login]
  );

  useEffect(() => {
    check(login);
  }, [check, login]);

  return [isValid, isValidating];
}
