export function PhoneIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.17627 10.8037C6.98184 12.6115 9.16781 14 10.9535 14C11.7548 14 12.4583 13.7214 13.0227 13.0986C13.3525 12.7321 13.55 12.31 13.55 11.8895C13.55 11.5649 13.4249 11.2525 13.1186 11.0301L11.236 9.69311C10.9446 9.48851 10.6955 9.39062 10.472 9.39062C10.1889 9.39062 9.93479 9.55304 9.64895 9.83271L9.20796 10.2698C9.14066 10.3371 9.05279 10.3677 8.97328 10.3677C8.87931 10.3677 8.78424 10.331 8.72195 10.2971C8.33713 10.0886 7.67759 9.52192 7.06529 8.91464C6.45801 8.30736 5.89135 7.64782 5.68786 7.263C5.65397 7.19572 5.61726 7.10566 5.61726 7.01279C5.61726 6.93215 5.64283 6.84931 5.71013 6.78201L6.15222 6.33098C6.42693 6.04515 6.59432 5.79602 6.59432 5.50798C6.59432 5.28442 6.49533 5.04031 6.28181 4.74395L4.95981 2.88136C4.73858 2.57007 4.41506 2.43608 4.07036 2.43608C3.65776 2.43608 3.24063 2.61743 2.87635 2.96726C2.26858 3.54285 2 4.25634 2 5.04655C2 6.83218 3.3685 9.00313 5.17627 10.8037Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function MailIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00937 8.50319C8.26056 8.50319 8.48959 8.38492 8.75752 8.11699L13.9388 2.97316C13.7103 2.76669 13.2992 2.66673 12.7162 2.66673H3.13369C2.62483 2.66673 2.26348 2.76182 2.05559 2.95366L7.26126 8.11699C7.52539 8.38005 7.75336 8.50319 8.00937 8.50319ZM1.49277 12.0859L5.75774 7.85885L1.498 3.65639C1.39067 3.84099 1.3335 4.15193 1.3335 4.59196V11.1597C1.3335 11.5956 1.38532 11.9055 1.49277 12.0859ZM3.2831 13.0898H12.8656C13.3873 13.0898 13.7545 12.9894 13.9677 12.7917L9.6206 8.47763L9.25402 8.84907C8.83856 9.24881 8.44587 9.43138 8.00937 9.43138C7.56804 9.43138 7.17424 9.24881 6.7637 8.84907L6.39331 8.47976L2.05595 12.7868C2.2957 12.9894 2.70186 13.0898 3.2831 13.0898ZM14.5125 12.081C14.6139 11.8958 14.6668 11.5907 14.6668 11.1597V4.59196C14.6668 4.16168 14.6097 3.85073 14.5072 3.67101L10.2562 7.85885L14.5125 12.081Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PersonIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.32398 14.6566H13.1305C13.9589 14.6566 14.4532 14.2642 14.4532 13.6127C14.4532 11.7165 12.0487 9.10861 8.22356 9.10861C4.40578 9.10861 2 11.7165 2 13.6127C2 14.2642 2.49557 14.6566 3.32398 14.6566ZM8.2296 7.79994C9.83603 7.79994 11.2029 6.37162 11.2029 4.52508C11.2029 2.71392 9.832 1.33334 8.2296 1.33334C6.62717 1.33334 5.25764 2.7381 5.25764 4.53849C5.25764 6.37162 6.61847 7.79994 8.2296 7.79994Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CopyIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25732 4.01914V3.05127C5.25732 1.92427 5.83967 1.33333 6.95808 1.33333H8.92615C9.52891 1.33333 9.99993 1.48981 10.3915 1.89096L13.0033 4.54831C13.4168 4.9723 13.5599 5.41189 13.5599 6.10034V10.251C13.5599 11.377 12.9776 11.9689 11.8592 11.9689H10.9701V10.9429H11.7696C12.2784 10.9429 12.5339 10.6741 12.5339 10.1872V5.85098H10.2234C9.57972 5.85098 9.23839 5.51391 9.23839 4.86589V2.35935H7.04334C6.53451 2.35935 6.28335 2.63241 6.28335 3.11505V4.01988C6.25364 4.01938 6.22332 4.01914 6.19235 4.01914H5.25732ZM10.0948 2.78816V4.73643C10.0948 4.91595 10.1691 4.99451 10.3486 4.99451H12.2668L10.0948 2.78816Z"
        fill="currentColor"
      />
      <path
        d="M2.6665 12.9368C2.6665 14.0681 3.2455 14.6547 4.36726 14.6547H9.26929C10.391 14.6547 10.9701 14.0628 10.9701 12.9368V8.90606C10.9701 8.20463 10.8811 7.87647 10.4424 7.42914L7.60391 4.54682C7.18236 4.11665 6.8232 4.01914 6.19235 4.01914L4.36726 4.01914C3.2498 4.01914 2.6665 4.60578 2.6665 5.73707V12.9368ZM3.69253 12.873V5.79656C3.69253 5.31822 3.94369 5.04516 4.45346 5.04516H6.06279V7.912C6.06279 8.66233 6.43378 9.02811 7.17459 9.02811H9.94306V12.873C9.94306 13.3599 9.68762 13.6287 9.18308 13.6287H4.44917C3.94369 13.6287 3.69253 13.3599 3.69253 12.873ZM7.27598 8.12325C7.0599 8.12325 6.96763 8.0319 6.96763 7.81489V5.24647L9.80055 8.12325H7.27598Z"
        fill="currentColor"
      />
    </svg>
  );
}

export const ClockIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6032_1429)">
      <path
        d="M4.41132 8.88044C4.09029 8.88044 3.84536 8.63558 3.84536 8.3145C3.84536 7.99929 4.09029 7.75436 4.41132 7.75436H7.43438V3.66373C7.43438 3.34725 7.67931 3.1036 7.99451 3.1036C8.31099 3.1036 8.56046 3.34725 8.56046 3.66373V8.3145C8.56046 8.63558 8.31099 8.88044 7.99451 8.88044H4.41132ZM8.00033 15.3334C12.018 15.3334 15.3349 12.0119 15.3349 8.00002C15.3349 3.98236 12.0122 0.666687 7.99451 0.666687C3.98394 0.666687 0.666992 3.98236 0.666992 8.00002C0.666992 12.0119 3.98976 15.3334 8.00033 15.3334Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6032_1429">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
