import cn from 'classnames';
import Tooltip from 'rc-tooltip';

import { Nullable } from '@/app/types';
import { useSpecsEffect } from '@/modules/showroom/advert/create/Specs/helpers';
import { useTransportColors } from '@/modules/transport/api';
import { TransportColor } from '@/modules/transport/types';
import { Skeleton } from '@/ui/Skeleton';
import { Spinner } from '@/ui/Spinner/Spinner';
import { MAX_PG_LIMIT_REQ, rcTooltipProps } from '@/utils/consts';

import cls from './Color.module.scss';
import { CheckIcon } from './icons';

const skeletons = new Array(20).fill(0);

type Props = {
  color: Nullable<TransportColor>;
  onChange: (g: TransportColor) => void;
  disabled?: boolean;
};

export const Color: React.FC<Props> = ({ color, onChange, disabled }) => {
  const [colors, isLoading] = useTransportColors(MAX_PG_LIMIT_REQ);

  useSpecsEffect(color, colors, onChange);

  return (
    <div className={cls.root}>
      <div className={cls.list_wrap}>
        {colors && !isLoading && (
          <ul className={cls.list}>
            {colors.map((c) => {
              const isWhite = c.hex.toLowerCase() === '#ffffff';
              const isActive = color?.id === c.id;

              return (
                <li key={c.id}>
                  <Tooltip
                    placement="top"
                    overlay={c.name}
                    overlayStyle={{ pointerEvents: 'none', userSelect: 'none' }}
                    {...rcTooltipProps}
                  >
                    <button
                      className={cn(cls.color_btn, {
                        [cls.color_btn_white]: isWhite,
                        [cls.color_btn_active]: isActive
                      })}
                      type="button"
                      onClick={() => onChange(c)}
                      style={{ '--color': c.hex } as React.CSSProperties}
                      disabled={disabled}
                    >
                      <span className={cls.color_circle} />
                      <span className={cls.color_name}>{c.name}</span>
                      {isActive && <CheckIcon />}
                    </button>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        )}

        {isLoading && (
          <>
            <ul className={cn(cls.list, cls.list_loading)}>
              {skeletons.map((_, i) => (
                <li key={i}>
                  <Skeleton width={52} height={52} borderRadius="50%" />
                </li>
              ))}
            </ul>

            <div className={cls.spinner}>
              <Spinner />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
