import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { GetDiscountTypesRes } from '@/modules/discount/types';
import { showAlert } from '@/utils/network';

function getDiscountTypes() {
  return axios.get('/discounts/list');
}

export function useDiscountTypes(): [GetDiscountTypesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetDiscountTypesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getDiscountTypes();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
