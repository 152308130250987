import { Promotion } from '@/modules/showroom/advert/create/Promotion/Promotion';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

export function PromotionWrap() {
  const promotionPackageId = useUpdateVrpStore((s) => s.promotionPackageId);
  const setPromotionPackageId = useUpdateVrpStore(
    (s) => s.setPromotionPackageId
  );
  const autoExtension = useUpdateVrpStore((s) => s.autoExtension);
  const setAutoExtension = useUpdateVrpStore((s) => s.setAutoExtension);

  return (
    <Promotion
      promotionPackageId={promotionPackageId}
      setPromotionPackageId={setPromotionPackageId}
      autoExtension={autoExtension}
      setAutoExtension={setAutoExtension}
    />
  );
}
