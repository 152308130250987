import cn from 'classnames';
import { useMemo, useRef } from 'react';

import { useDesktop } from '@/app/common-store';
import { useWindowClick } from '@/hooks/useWindowClick';
import { BottomModal } from '@/ui/modals/BottomModal/BottomModal';
import { escapePlus } from '@/utils/format';

import cls from './Dropdown.module.scss';

// Dropdown
type Props = {
  name: string;
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
  withModal?: boolean;
  disableOutsideClick?: boolean;
  listTop?: boolean;
  noHover?: boolean;
  isDesktop?: boolean;
  parentCls?: string;
  className?: string;
  modalClassName?: string;
  toLeft?: boolean;
  showScrollbar?: boolean;
  actionsModal?: boolean;
};

export const Dropdown: React.FC<Props> = ({
  name,
  isOpen,
  close,
  children,
  fullWidth,
  fullHeight,
  withModal,
  disableOutsideClick,
  parentCls,
  className,
  modalClassName,
  noHover,
  isDesktop: isDesktopProp,
  listTop,
  toLeft,
  showScrollbar,
  actionsModal
}) => {
  const isDesktop = useDesktop();
  const desktop = useMemo(() => {
    return typeof isDesktopProp === 'boolean' ? isDesktopProp : isDesktop;
  }, [isDesktop, isDesktopProp]);

  const rootRef = useRef<HTMLDivElement>(null);

  useWindowClick((e) => {
    if (withModal && !desktop) return;
    if (disableOutsideClick) return;

    const el = e.target as HTMLElement | null;
    const parentEl = parentCls
      ? el?.closest(`.${escapePlus(parentCls)}`)
      : null;

    const clickedRoot = parentEl
      ? parentEl.querySelector(`.${escapePlus(cls.root)}`)
      : null;
    const thisRoot = rootRef?.current;

    if ((!!clickedRoot && clickedRoot !== thisRoot) || !parentEl) {
      close();
    }
  });

  const jsx = (
    <div
      ref={rootRef}
      className={cn(cls.root, className, {
        [cls.root_open]: isOpen,
        [cls.root_fullwidth]: fullWidth,
        [cls.root_fullheight]: fullHeight,
        [cls.root_top]: !!listTop,
        [cls.root_nohover]: noHover,
        [cls.root_modal]: withModal && !desktop,
        [cls.root_left]: toLeft
      })}
    >
      <ul
        className={cn(cls.list, {
          ['hide-scrollbar']: !showScrollbar,
          ['custom-scrollbar']: showScrollbar,
          [cls.actions_list]: actionsModal && !isDesktop
        })}
      >
        {children}
      </ul>
    </div>
  );

  return (
    <>
      {!desktop && withModal ? (
        <BottomModal
          name={name}
          isOpen={isOpen}
          close={close}
          containerClass={cn(
            cls.modal_container,
            cls.modal_container_imp,
            modalClassName,
            { [cls.actions_modal]: actionsModal && !isDesktop }
          )}
        >
          {jsx}
        </BottomModal>
      ) : (
        jsx
      )}
    </>
  );
};

// Dropdown item
type ItemProps = {
  children: React.ReactNode;
  active?: boolean;
  className?: string;
};

export const DropdownItem: React.FC<ItemProps> = ({
  children,
  active,
  className
}) => {
  return (
    <li className={cn(cls.li, className)} data-active={active}>
      {children}
    </li>
  );
};
