import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { TransportBrand, TransportModel } from '@/modules/transport/types';

type Props = {
  brand: TransportBrand;
  model: Nullable<TransportModel>;
  models: TransportModel[] | undefined;
  onClick: (v: FilterType) => void;
  onAllClick: () => void;
  search: string;
  loading?: boolean;
  showArrow?: boolean;
};

export function ModelFilters({
  brand,
  model,
  models,
  loading,
  onClick,
  onAllClick,
  search,
  showArrow
}: Props) {
  const { t } = useTranslation();

  return (
    <FiltersList
      label={t('brandModels', { brand: brand.name })}
      activeId={model?.id || null}
      list={models || []}
      loading={loading}
      onClick={onClick}
      search={search}
      allButtonText={t('allModels')}
      onAllClick={onAllClick}
      showArrow={showArrow}
    />
  );
}
