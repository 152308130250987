export const ArrowRightIcon = () => (
  <svg
    width="8"
    height="16"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.06436 7.9994C7.063 7.77952 6.98514 7.59097 6.80969 7.41686L2.23593 2.88543C2.09451 2.73783 1.91544 2.66675 1.70429 2.66675C1.27447 2.66675 0.935608 2.99808 0.935608 3.42519C0.935608 3.63017 1.02582 3.8249 1.1692 3.97791L5.23632 7.99805L1.1692 12.0209C1.02718 12.1739 0.935608 12.3625 0.935608 12.575C0.935608 13.0021 1.27447 13.3334 1.70429 13.3334C1.91409 13.3334 2.09451 13.2623 2.23593 13.1147L6.80969 8.58194C6.9865 8.40784 7.06436 8.21793 7.06436 7.9994Z"
      fill="currentColor"
    />
  </svg>
);

export const TrashIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66207 17.7099C10.0072 17.7099 10.2297 17.4912 10.2215 17.1691L9.97054 8.58175C9.96087 8.26263 9.73546 8.05065 9.4052 8.05065C9.06299 8.05065 8.842 8.26934 8.85018 8.58994L9.09444 17.1735C9.10411 17.4994 9.33099 17.7099 9.66207 17.7099ZM12.0748 17.7099C12.4141 17.7099 12.6529 17.4941 12.6529 17.1751V8.58699C12.6529 8.26787 12.4141 8.05065 12.0748 8.05065C11.7341 8.05065 11.5019 8.26787 11.5019 8.58699V17.1751C11.5019 17.4941 11.7341 17.7099 12.0748 17.7099ZM14.4927 17.7099C14.8186 17.7099 15.044 17.4994 15.0536 17.1735L15.2979 8.58994C15.3061 8.26934 15.0851 8.05065 14.7429 8.05065C14.4126 8.05065 14.1872 8.26263 14.1776 8.58846L13.9334 17.1691C13.9251 17.4912 14.1476 17.7099 14.4927 17.7099ZM8.35365 5.76833H9.86537V4.06707C9.86537 3.64294 10.162 3.37083 10.6181 3.37083H13.5166C13.9726 3.37083 14.2693 3.64294 14.2693 4.06707V5.76833H15.7811V3.99105C15.7811 2.73849 14.9816 2 13.6243 2H10.5103C9.15309 2 8.35365 2.73849 8.35365 3.99105V5.76833ZM4.72489 6.65949H19.4299C19.8336 6.65949 20.1481 6.3389 20.1481 5.93526C20.1481 5.53455 19.8336 5.22068 19.4299 5.22068H4.72489C4.32796 5.22068 4 5.53455 4 5.93526C4 6.34562 4.32796 6.65949 4.72489 6.65949ZM8.31302 20.483H15.8418C17.0957 20.483 17.91 19.7154 17.9688 18.46L18.5319 6.50239H17.0126L16.4741 18.2161C16.4578 18.7075 16.1335 19.0339 15.6665 19.0339H8.47487C8.02126 19.0339 7.69706 18.6994 7.67396 18.2161L7.10862 6.50239H5.61624L6.186 18.4668C6.24629 19.7221 7.04572 20.483 8.31302 20.483Z"
      fill="currentColor"
    />
  </svg>
);

export const AddIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33334 10C3.33334 10.4892 3.73738 10.8882 4.22155 10.8882H9.11322V15.7798C9.11322 16.2626 9.51072 16.6667 10 16.6667C10.4892 16.6667 10.8947 16.2626 10.8947 15.7798V10.8882H15.7798C16.2626 10.8882 16.6667 10.4892 16.6667 10C16.6667 9.51072 16.2626 9.10525 15.7798 9.10525H10.8947V4.22155C10.8947 3.73738 10.4892 3.33334 10 3.33334C9.51072 3.33334 9.11322 3.73738 9.11322 4.22155V9.10525H4.22155C3.73738 9.10525 3.33334 9.51072 3.33334 10Z"
      fill="currentColor"
    />
  </svg>
);
