import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { mapCountAdsByStatus } from '@/modules/showroom/advert/AdvertsCard/helpers';
import { useCountAdsByStatus } from '@/modules/showroom/advert/api';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { Card } from '@/ui/Card/Card';

import cls from './AdvertsCard.module.scss';

const cardStyle = {
  height: '100%'
};

export function AdvertsCard() {
  const { t } = useTranslation();

  const [list] = useCountAdsByStatus();
  const totalCount = list
    ? list.reduce((acc, cur) => acc + cur.count_ads, 0)
    : 0;
  const mappedList = useMemo(() => {
    if (!list) return [];
    return mapCountAdsByStatus(list, totalCount);
  }, [list, totalCount]);

  return (
    <Card flat style={cardStyle}>
      <div className={cls.root}>
        <h2 className={cls.title}>
          <span>{t('adverts')}</span>
          <span>{t('itemsCount', { value: totalCount })}</span>
        </h2>

        <div>
          <div className={cls.bar}>
            {mappedList.map((v) => (
              <span
                key={v.color}
                style={{ width: `${v.percent}%`, background: v.color }}
              />
            ))}
          </div>
          <ul className={cls.list}>
            {mappedList.map((v) => (
              <li key={v.color}>
                <div className={cls.status_row}>
                  <span
                    className={cls.status_color}
                    style={{ background: v.color }}
                  />
                  <span>{t(v.label)}</span>
                  <span className={cls.status_count}>
                    {t('itemsCount', { value: v.count })}
                  </span>
                </div>
              </li>
            ))}
          </ul>

          <ButtonLink
            to="/adverts"
            variant="secondary"
            color="black"
            size="s"
            fullWidth
          >
            {t('advertsList')}
          </ButtonLink>
        </div>
      </div>
    </Card>
  );
}
