import { useEffect, useMemo, useState } from 'react';
import useWebSocket, { Options } from 'react-use-websocket';

import { Nullable } from '@/app/types';
import { GetLastActivity } from '@/modules/activity/types';
import { SOCKET_API_URL } from '@/utils/consts';

export const ACITIVITY_ROUTES = {
  setActive: (authToken: string) =>
    `${SOCKET_API_URL}/ws/active/set-active?token=${authToken}`,
  getUserActivity: (accId: string) =>
    `${SOCKET_API_URL}/ws/active/status/${accId}`
};

export function useUserActivity(
  account_id: Nullable<number>
): [number | null | undefined, boolean] {
  const [data, setData] = useState<GetLastActivity>();
  const [isOnline, setOnline] = useState(false);

  const socketOptions = useMemo<Options>(
    () => ({
      shouldReconnect: () => true
    }),
    []
  );

  const { lastMessage } = useWebSocket<GetLastActivity>(
    ACITIVITY_ROUTES.getUserActivity(String(account_id)),
    socketOptions,
    !!account_id
  );

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const parsed = JSON.parse(lastMessage.data);
      if (parsed && typeof parsed === 'object') {
        const res = parsed as GetLastActivity;
        setData(res);
        setOnline(res.online);
      }
    }
  }, [lastMessage]);

  return [data?.last_online_time, isOnline];
}
