import { useCallback, useEffect } from 'react';

import { AutoAPI } from '@/app/api';
import { useCommonStore } from '@/app/common-store';
import { useLang } from '@/hooks/useLang';
import { setAccountPlatform } from '@/modules/accounts/api';
import { useAccountStore } from '@/modules/accounts/store';
import { getCookieAuth } from '@/modules/auth/helpers';
import { useAuthStore } from '@/modules/auth/store';
import { AuthState } from '@/modules/auth/types';
import { useCurrencyStore } from '@/modules/currency/store';
import {
  getCurrentPriceForRefresh,
  getRefreshWeekDays
} from '@/modules/refresh/api';
import { useShowroomStore } from '@/modules/showroom/store';
import { showAlert } from '@/utils/network';

const handleAuth = () => {
  const [authInCookie, parsedAuth] = getCookieAuth();
  const authStore = useAuthStore.getState();

  if (authInCookie) {
    if (parsedAuth) {
      authStore.setAuth(parsedAuth);
    } else {
      authStore.setAuthState(AuthState.not_authed);
    }
  } else {
    authStore.setAuthState(AuthState.not_authed);
  }
};

export function useApp() {
  const [lang] = useLang();

  // Calc window height
  const calcVh = useCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  // Set window width
  const setWindowWidth = useCommonStore((state) => state.setWindowWidth);
  const saveWidth = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, [setWindowWidth]);

  const onWindowResize = useCallback(() => {
    calcVh();
    saveWidth();
  }, [calcVh, saveWidth]);

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  // Auth
  useEffect(() => {
    handleAuth();
  }, []);

  // Handle (not_)authed
  const authState = useAuthStore((s) => s.authState);
  const fetchAccountInfo = useAccountStore((s) => s.fetchAccountInfo);
  const resetAccountInfo = useAccountStore((s) => s.reset);
  const setShowroom = useShowroomStore((s) => s.setShowroom);
  useEffect(() => {
    if (authState === AuthState.authed) {
      fetchAccountInfo();
      setAccountPlatform('web');
    } else {
      resetAccountInfo();
      setShowroom(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  // Set header lang
  useEffect(() => {
    AutoAPI.setLang(lang);
  }, [lang]);

  // Rates
  const fetchRates = useCurrencyStore((s) => s.fetchRates);
  useEffect(() => {
    fetchRates();
  }, [fetchRates]);

  // Refresh
  const setWeekDays = useCommonStore((s) => s.setWeekDays);
  const setRefreshPrice = useCommonStore((s) => s.setRefreshPrice);
  const loadRefreshData = async () => {
    try {
      const weekDays = await getRefreshWeekDays();
      if (weekDays.data) setWeekDays(weekDays.data);

      const refreshPrice = await getCurrentPriceForRefresh();
      if (refreshPrice.data?.price) setRefreshPrice(refreshPrice.data.price);
    } catch (error) {
      showAlert({ error });
    }
  };
  useEffect(() => {
    loadRefreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
