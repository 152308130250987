import cn from 'classnames';
import { useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { Advert } from '@/modules/wallet/ExpensesDays/Advert';
import { MappedDay } from '@/modules/wallet/ExpensesDays/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { ADV_CUR } from '@/utils/consts';
import { getDateString, isSameYear } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';

import cls from './ExpensesDays.module.scss';
import { ChevronIcon } from './icons';

type Props = {
  days: MappedDay[];
  loading: boolean;
};

export function ExpensesDays({ days, loading }: Props) {
  const daysWithDate = useMemo(
    () =>
      days.map((day) => {
        const date = new Date(day.dateString);
        return { ...day, date, datetime: date.getTime() };
      }),
    [days]
  );

  const sortedDays = useMemo(
    () => [...daysWithDate].sort((a, b) => b.datetime - a.datetime),
    [daysWithDate]
  );

  if (loading) return <Spinner color="var(--clr-blue)" centered />;

  return (
    <ul className={cls.list}>
      {sortedDays.map((d) => (
        <li key={d.dateString}>
          <ExpensesDay day={d} />
        </li>
      ))}
    </ul>
  );
}

type MappedDayWithDate = MappedDay & {
  date: Date;
  datetime: number;
};

type DayProps = {
  day: MappedDayWithDate;
};

function ExpensesDay({ day }: DayProps) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [openedCategory, setOpenedCategory] = useState<Nullable<number>>(null);
  const toggleCategory = (catId: number) => {
    setOpenedCategory((prev) => (prev === catId ? null : catId));
  };

  const sameYear = isSameYear(day.date);
  const dateStr = getDateString(day.date, lang, {
    day: '2-digit',
    month: 'long',
    year: sameYear ? undefined : 'numeric'
  });

  return (
    <div className={cls.root}>
      <p className={cls.header}>
        <b>{dateStr}</b>
        <span>{t('common.dot')}</span>
        <b>
          {t('priceCurrency', {
            price: numberWithSpaces(day.total, lang),
            currency: ADV_CUR
          })}
        </b>
      </p>

      <ul>
        {day.categories.map((cat) => {
          const hasAdverts = cat.adverts.length > 0;
          const isOpened = openedCategory === cat.id;

          return (
            <li key={cat.id}>
              <div>
                <button
                  className={cn(cls.category_btn, {
                    [cls.category_btn_active]: isOpened
                  })}
                  type="button"
                  disabled={!hasAdverts}
                  onClick={() => toggleCategory(cat.id)}
                >
                  <div className={cls.cell}>
                    <span className={cls.icon}>
                      {hasAdverts && <ChevronIcon />}
                    </span>
                    <span
                      className={cls.category_circle}
                      style={{ backgroundColor: cat.color }}
                    />
                    <span>{cat.name}</span>
                  </div>
                  <div className={cls.cell}>
                    <b className={cls.category_total}>
                      {t('priceCurrency', {
                        price: numberWithSpaces(cat.total, lang),
                        currency: ADV_CUR
                      })}
                    </b>
                  </div>
                </button>
                {hasAdverts && (
                  <Collapse isOpened={isOpened}>
                    <ul className={cls.adverts}>
                      {cat.adverts.map((advert, i) => (
                        <li key={i}>
                          <Advert advert={advert} />
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
