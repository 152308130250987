import { Age } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Age/Age';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function AgeWrap() {
  const age = useRentUpdateAdvertStore((s) => s.age);
  const setAge = useRentUpdateAdvertStore((s) => s.setAge);
  const driveExp = useRentUpdateAdvertStore((s) => s.driveExp);
  const setDriveExp = useRentUpdateAdvertStore((s) => s.setDriveExp);
  const isAgeFilled = useRentUpdateAdvertStore((s) => s.isAgeFilled);
  const setAgeFilled = useRentUpdateAdvertStore((s) => s.setAgeFilled);

  return (
    <Age
      age={age}
      setAge={setAge}
      driveExp={driveExp}
      setDriveExp={setDriveExp}
      isAgeFilled={isAgeFilled}
      setAgeFilled={setAgeFilled}
    />
  );
}
