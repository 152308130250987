export function ArrowIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96532 3.3864C7.19385 3.14645 7.57364 3.13718 7.8136 3.36571C8.05356 3.59425 8.06282 3.97403 7.83429 4.21399L4.79981 7.4002H12.5998C12.9312 7.4002 13.1998 7.66882 13.1998 8.0002C13.1998 8.33157 12.9312 8.6002 12.5998 8.6002H4.7998L7.83429 11.7864C8.06282 12.0264 8.05356 12.4061 7.8136 12.6347C7.57364 12.8632 7.19385 12.8539 6.96532 12.614L2.96532 8.41399C2.74463 8.18227 2.74463 7.81813 2.96532 7.5864L6.96532 3.3864Z"
        fill="currentColor"
      />
    </svg>
  );
}
