import type { AxiosResponse } from 'axios';

import { axios } from '@/app/api';
import { getFormData } from '@/utils/network';

import { UploadCfRes, UploadReq, UploadRes } from './types';

export const FILES_API_ROUTES = {
  upload: '/files/v2/upload',
  uploadCf: '/files/v3/upload',
  getSignedLink: '/files/signed-link',
  setWatermark: '/files/set-watermark'
};

export function uploadFile(
  req: UploadReq,
  content_type?: string
): Promise<AxiosResponse<UploadRes>> {
  return axios.post<UploadRes>(
    FILES_API_ROUTES.upload,
    getFormData({ file: req.file }),
    { params: { content_type } }
  );
}

export function uploadFileToCf(
  req: UploadReq,
  content_type?: string
): Promise<AxiosResponse<UploadCfRes>> {
  return axios.post<UploadCfRes>(
    FILES_API_ROUTES.uploadCf,
    getFormData({ file: req.file }),
    { params: { content_type } }
  );
}

export function setWatermark(file: File): Promise<AxiosResponse<Blob>> {
  return axios.post<Blob>(
    FILES_API_ROUTES.setWatermark,
    getFormData({ file }),
    { responseType: 'blob' }
  );
}
