import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useSpecsEffect } from '@/modules/showroom/advert/create/Specs/helpers';
import { useTransportModifications } from '@/modules/transport/api';
import {
  TransportBody,
  TransportDriveUnit,
  TransportEngine,
  TransportGeneration,
  TransportModification,
  TransportTransmission
} from '@/modules/transport/types';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { Skeleton } from '@/ui/Skeleton';
import { MAX_PG_LIMIT } from '@/utils/consts';

import cls from './Modification.module.scss';

const skeletons = new Array(4).fill(0);

type Props = {
  body: TransportBody;
  generation: TransportGeneration;
  engine: TransportEngine;
  driveUnit: TransportDriveUnit;
  transmission: TransportTransmission;
  selected: Nullable<TransportModification>;
  onChange: (g: TransportModification) => void;
  disabled?: boolean;
};

export const Modification: React.FC<Props> = ({
  body,
  generation,
  engine,
  driveUnit,
  transmission,
  selected,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();

  const req = useMemo(
    () => ({
      limit: MAX_PG_LIMIT,
      body_id: body.id,
      generation_id: generation.id,
      drive_unit_id: driveUnit.id,
      engine_id: engine.id,
      transmission_id: transmission.id
    }),
    [body.id, driveUnit.id, engine.id, generation.id, transmission.id]
  );
  const [mods, isLoading] = useTransportModifications(req);

  useSpecsEffect(selected, mods, onChange);

  return (
    <div className={cls.root}>
      <div className={cls.list_wrap}>
        {mods && !isLoading && (
          <ul className={cls.list}>
            {mods.map((m) => (
              <li key={m.id}>
                <Checkbox
                  id={`adv-create-spec-mod-${m.id}`}
                  checked={selected?.id === m.id}
                  onChange={() => onChange(m)}
                  disabled={disabled}
                  rounded
                >
                  {t('horsepower', { value: m.horse_power?.name })}{' '}
                  <span style={{ color: 'var(--thm-silver-grey)' }}>
                    ({m.volume?.name} {m.transmission.short_name})
                  </span>
                </Checkbox>
              </li>
            ))}
          </ul>
        )}

        {isLoading && (
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton width={224} height={24} borderRadius={4} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
