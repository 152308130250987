import { Nullable } from '@/app/types';
import {
  RentAdvert,
  RentAdvertBase,
  RentAdvertExistedStatus
} from '@/modules/showroom/advert/rent/my/types';
import { RentTariff } from '@/modules/showroom/advert/rent/tariffs/types';

export interface BookingType {
  id: number;
  booking_request_date: number;
  archived: boolean;
  rent_ad: {
    id: RentAdvert['id'];
    url: RentAdvert['url'];
    photos: RentAdvertBase['photos'];
    brand: RentAdvertBase['brand'];
    model: RentAdvertBase['model'];
    year_of_issue: RentAdvertBase['year_of_issue'];
    body: RentAdvertBase['body'];
    modification: RentAdvertBase['modification'];
    tariffs: RentAdvertBase['tariffs'];
    deposit_not_required: boolean;
    deposit: Nullable<number>;
  };
  about_booking: {
    tariff: RentTariff;
    total_cost: number;
    deposit: Nullable<number>;
    dates: {
      start: number;
      end: number;
    };
  };
  about_lead: {
    name: string;
    phone_number: string;
    email: Nullable<string>;
  };
}

export enum BookingExistedStatusType {
  confirmed = 'confirmed',
  new = 'new',
  archive = 'archive'
}

export interface BookingExistedStatus
  extends Omit<RentAdvertExistedStatus, 'status'> {
  status: BookingExistedStatusType;
}
export type GetExistedBookingStatusesRes = BookingExistedStatus[];

export type GetMyBookingsQueryReq = {
  status_id: Nullable<number>;
  archived: Nullable<boolean>;
  search: Nullable<string>;
};
export type GetMyBookingsRes = BookingType[];

export interface UpdateBookingReq {
  period: {
    start: number;
    end: number;
    total_cost: number;
  };
  name: string;
  phone_number: string;
}

export interface ChangeBookingStatusReq {
  confirm: boolean;
  comment?: Nullable<string>;
}
