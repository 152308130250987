import { useEffect } from 'react';

import { useLang } from '@/hooks/useLang';
import { useCountryCodesStore } from '@/modules/country-codes/store';
import { CountryCodeType } from '@/modules/country-codes/types';

export function useCountryCodes(): CountryCodeType[] {
  const [lang] = useLang();

  const data = useCountryCodesStore((s) => s.data);
  const list = data[lang];

  const fetchCodes = useCountryCodesStore((s) => s.fetchData);
  useEffect(() => {
    fetchCodes();
  }, [fetchCodes]);

  return list;
}
