import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useAccountStore } from '@/modules/accounts/store';
import { TailIcon } from '@/modules/chat/Detail/Messages/Message/icons';
import { useShowroom } from '@/modules/showroom/api';
import { Avatar } from '@/ui/Avatar/Avatar';
import { formatDate } from '@/utils/date';

import cls from './OfferPreviewMessage.module.scss';

type Props = {
  message: string;
};

export const OfferPreviewMessage: React.FC<Props> = ({ message }) => {
  const [locale] = useLang();
  const { t } = useTranslation();
  const accountInfo = useAccountStore((s) => s.accountInfo);
  const [showroom] = useShowroom();

  return (
    <div className={cls.root}>
      <p className={cls.title}>{t('offer.previewMessageTitle')}</p>
      <div className={cls.preview}>
        <Avatar
          size={36}
          src={showroom?.logo_url}
          user={
            accountInfo ? { id: accountInfo?.id, name: String(name) } : null
          }
        />

        <div className={cls.message}>
          <p className={cls.text}>{message}</p>
          <small>{formatDate(new Date(), 'HH:mm', locale)}</small>

          <div className={cls.tail}>
            <TailIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
