import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { Switch } from '@/ui/Switch/Switch';

import cls from './Condition.module.scss';

type Props = {
  broken: CreateAdvertSlice['broken'];
  setBroken: CreateAdvertSlice['setBroken'];
  isBrokenFilled: CreateAdvertSlice['isBrokenFilled'];
  setBrokenFilled: CreateAdvertSlice['setBrokenFilled'];
};

export function Condition({
  broken,
  setBroken,
  isBrokenFilled,
  setBrokenFilled
}: Props) {
  const { t } = useTranslation();
  const [touched, setTouched] = useState(false);

  const onClick = () => {
    setBrokenFilled(true);
    scrollToSection(SCROLLTO.mileage);
  };

  return (
    <div className={cls.root} id={SCROLLTO.condition}>
      <div className="box">
        <h1 className={cls.title}>{t('condition')}</h1>
        <div className={cls.row}>
          <button
            className={cls.toggle_btn}
            onClick={() => {
              setTouched(true);
              setBroken(!broken);
            }}
            type="button"
          />

          <div>
            <p className={cls.label}>{t('broken')}</p>
            <p className={cls.subtitle}>{t('brokenText')}</p>
          </div>

          <Switch
            id="broken-cond-switch"
            checked={!!broken}
            onChange={(e) => {
              setTouched(true);
              setBroken(e.currentTarget.checked);
            }}
            green
          />
        </div>

        {!isBrokenFilled && (
          <>
            {touched ? (
              <Button
                onClick={onClick}
                variant="primary"
                color="blue"
                fullWidth
              >
                {t('common.continue')}
              </Button>
            ) : (
              <Button
                onClick={onClick}
                variant="secondary"
                color="black"
                fullWidth
              >
                {t('common.skip')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
