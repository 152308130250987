import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { GetMyReviewsRes } from '@/modules/reviews/types';
import { numberWithSpaces, pluralKey } from '@/utils/format';

import { StarIcon } from './icons';
import cls from './ReviewsSummary.module.scss';

const MAX_STAR = 5;
const stars = new Array(MAX_STAR).fill(0);
function getEstimateProgress(total: number, count: number): number {
  return (count / total) * 100;
}

type Props = {
  data: GetMyReviewsRes;
};

export function ReviewsSummary({ data }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { reviews_average_score, estimates, count_reviews } = data;
  const sortedEstimates = [...estimates].sort((a, b) => b.star_id - a.star_id);

  return (
    <div className={cls.root}>
      <div className={cls.score}>
        <b>{reviews_average_score}</b>
        <span>
          <span className={cls.score_of}>
            {t('outOf', { value: MAX_STAR })}
          </span>
          <span className={cls.score_count}>
            {pluralKey(
              count_reviews,
              t('profile.reviews.pluralBasedOn.one', {
                reviews: count_reviews
              }),
              t('profile.reviews.pluralBasedOn.few', {
                reviews: count_reviews
              }),
              t('profile.reviews.pluralBasedOn.many', {
                reviews: count_reviews
              })
            )}
          </span>
        </span>
      </div>

      <ul className={cls.estimates}>
        {sortedEstimates.map((e) => (
          <li key={e.star_id}>
            <div className={cls.estimate}>
              <ul className={cls.stars}>
                {stars.map((_, i) => (
                  <li key={i}>
                    <span
                      className={cn(cls.star, {
                        [cls.star_fill]: i + 1 <= e.star_id
                      })}
                    >
                      <StarIcon />
                    </span>
                  </li>
                ))}
              </ul>
              <div className={cls.estimate_bar}>
                <div
                  className={cls.estimate_bar_progress}
                  style={{
                    width: `${getEstimateProgress(count_reviews, e.count)}%`
                  }}
                ></div>
              </div>
              <span className={cls.estimate_count}>
                {numberWithSpaces(e.count, lang)}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
