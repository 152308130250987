import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useChatDetailStore } from '@/modules/chat/Detail/store';

import { ChevronIcon } from './icons';
import cls from './SearchNav.module.scss';

export const SearchNav: React.FC = () => {
  const { t } = useTranslation();

  const isAreaLoading = useChatDetailStore((s) => s.isAreaLoading);
  const searchIndex = useChatDetailStore((s) => s.searchIndex);
  const searchIds = useChatDetailStore((s) => s.searchIds);
  const setSearchIndex = useChatDetailStore((s) => s.setSearchIndex);
  const scrollToMsg = useChatDetailStore((s) => s.scrollToMsg);

  const onPrevClick = () => {
    if (searchIndex > 0) {
      const newIndex = searchIndex - 1;
      setSearchIndex(newIndex);
      scrollToMsg(searchIds[newIndex]);
    }
  };

  const onNextClick = () => {
    if (searchIndex < searchIds.length - 1) {
      const newIndex = searchIndex + 1;
      setSearchIndex(newIndex);
      scrollToMsg(searchIds[newIndex]);
    }
  };

  return (
    <div className={cls.root}>
      <p
        className={cls.count}
        style={{ visibility: searchIds.length > 0 ? 'visible' : 'hidden' }}
      >
        {t('counter', {
          index: searchIndex + 1,
          count: searchIds.length
        })}
      </p>

      <div className={cls.buttons}>
        <button
          className={cn(cls.btn, cls.btn_prev)}
          type="button"
          onClick={onPrevClick}
          disabled={isAreaLoading || searchIndex <= 0}
        >
          <ChevronIcon />
        </button>
        <button
          className={cn(cls.btn, cls.btn_next)}
          type="button"
          onClick={onNextClick}
          disabled={isAreaLoading || searchIndex >= searchIds.length - 1}
        >
          <ChevronIcon />
        </button>
      </div>
    </div>
  );
};
