import { ExpensesByDay, ViewByDay } from '@/modules/showroom/advert/my/types';
import { CHARTS_COLORS } from '@/utils/consts';

export const RENT_ADVERTS_URL = '/adverts/rent';
export const CARS_ADVERTS_URL = '/adverts/cars';
export const VRP_ADVERTS_URL = '/adverts/vrp';
export const BAR_COLORS: Record<string, string> = {
  3: CHARTS_COLORS.cars,
  2: CHARTS_COLORS.vrp,
  1: CHARTS_COLORS.services
};
export const DEFAULT_BAR_COLOR = 'var(--clr-blue)';

export function mapViewsAndExpensesData(views: ViewByDay[], name: string) {
  let viewsCount = 0;
  const dates = views.map((view) => view.date * 1000);
  const series = [
    {
      name,
      data: views.map((view) => {
        viewsCount += view.count_views;
        return view.count_views;
      })
    }
  ];

  return { dates, series, viewsCount };
}

export function mapExpenses(expenses: ExpensesByDay[]) {
  let expensesSum = 0;

  expenses.forEach((expense) => {
    expensesSum += expense.sum_amount;
  });

  const percent = 100 / expensesSum;

  return expenses.map((expense) => ({
    ...expense,
    percent_amount: expense.sum_amount * percent,
    color: BAR_COLORS[expense.id.toString()] || DEFAULT_BAR_COLOR
  }));
}
