import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import {
  getDepositHistory,
  getExpensesDetailStatistics,
  getExpensesDetailStatisticsV2
} from '@/modules/wallet/api';
import {
  DepositHistoryReq,
  DepositHistoryRes,
  ExpensesDetailStatisticsReq,
  ExpensesDetailStatisticsRes,
  ExpensesDetailStatisticsResV2
} from '@/modules/wallet/types';
import { showAlert } from '@/utils/network';

export enum WalletCategories {
  debit = 'debit',
  refill = 'refill'
}

export type WalletCategoriesType = {
  id: WalletCategories;
  text: string;
};

export function useWalletCategories(): WalletCategoriesType[] {
  const { t } = useTranslation();

  const filters = useMemo<WalletCategoriesType[]>(
    () => [
      { id: WalletCategories.debit, text: t('wallet.writeOffs') },
      { id: WalletCategories.refill, text: t('wallet.refill') }
    ],
    [t]
  );
  return filters;
}

export enum WalletFilterType {
  services = 'services',
  adverts = 'adverts'
}

export function useWalletFilters(): OptionI[] {
  const { t } = useTranslation();

  const filters = useMemo<OptionI[]>(
    () => [
      { id: WalletFilterType.services, text: t('wallet.byServices') },
      { id: WalletFilterType.adverts, text: t('wallet.byAdverts') }
    ],
    [t]
  );
  return filters;
}

export function useExpensesDetailStatistics(
  params: ExpensesDetailStatisticsReq,
  lang: string
): [ExpensesDetailStatisticsRes | undefined, boolean] {
  const abortRef = useRef<Nullable<AbortController>>(
    new window.AbortController()
  );
  const [data, setData] = useState<ExpensesDetailStatisticsRes>();
  const [loading, setLoading] = useState(true);

  const load = useCallback(
    async (
      prms: ExpensesDetailStatisticsReq,
      lng: string,
      abort: Nullable<AbortController>
    ) => {
      setLoading(true);

      try {
        const r = await getExpensesDetailStatistics(
          prms,
          lng,
          abort || undefined
        );
        setData(r.data);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (abortRef.current) abortRef.current.abort();
    const ab = new AbortController();
    abortRef.current = ab;
    load(params, lang, ab);
  }, [lang, load, params]);

  useEffect(() => {
    return () => {
      if (abortRef.current) abortRef.current.abort();
    };
  }, []);

  return [data, loading];
}

export function useExpensesDetailStatisticsV2(
  params: ExpensesDetailStatisticsReq,
  lang: string
): [ExpensesDetailStatisticsResV2 | undefined, boolean] {
  const abortRef = useRef<Nullable<AbortController>>(
    new window.AbortController()
  );
  const [data, setData] = useState<ExpensesDetailStatisticsResV2>();
  const [loading, setLoading] = useState(true);

  const load = useCallback(
    async (
      prms: ExpensesDetailStatisticsReq,
      lng: string,
      abort: Nullable<AbortController>
    ) => {
      setLoading(true);

      try {
        const r = await getExpensesDetailStatisticsV2(
          prms,
          lng,
          abort || undefined
        );
        setData(r.data);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (abortRef.current) abortRef.current.abort();
    const ab = new AbortController();
    abortRef.current = ab;
    load(params, lang, ab);
  }, [lang, load, params]);

  useEffect(() => {
    return () => {
      if (abortRef.current) abortRef.current.abort();
    };
  }, []);

  return [data, loading];
}

export function useTotalExpenses(
  expensesData: ExpensesDetailStatisticsRes | undefined
) {
  const total = useMemo(() => {
    if (!expensesData) return 0;
    return expensesData.expenses.reduce<number>((acc, cur) => {
      return acc + cur.amount;
    }, 0);
  }, [expensesData]);

  return total;
}

export function useDepositHistory(
  params: DepositHistoryReq
): [DepositHistoryRes | undefined, boolean] {
  const [data, setData] = useState<DepositHistoryRes>();
  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getDepositHistory(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, loading];
}
