import { useTranslation } from 'react-i18next';

import { GenFiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/GenFiltersList';
import { TransportModel, TransportGeneration } from '@/modules/transport/types';

type Props = {
  model: TransportModel;
  generations: TransportGeneration[];
  allGenerations: TransportGeneration[] | undefined;
  onClick: (v: TransportGeneration) => void;
  onAllClick: () => void;
  loading?: boolean;
};

export function GenerationsFilters({
  model,
  generations,
  allGenerations,
  onClick,
  onAllClick,
  loading
}: Props) {
  const { t } = useTranslation();

  return (
    <GenFiltersList
      label={t('modelGenerations', { model: model.name })}
      selected={generations}
      list={allGenerations || []}
      loading={loading}
      onClick={onClick}
      allButtonText={t('allGenerations')}
      onAllClick={onAllClick}
    />
  );
}
