import { ApexOptions } from 'apexcharts';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import {
  deleteRentAdvertisement,
  updateRentAdvertisement
} from '@/modules/showroom/advert/rent/api';
import { RentAdvert } from '@/modules/showroom/advert/rent/my/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import {
  deleteAdvertisement,
  updateAdvertisement
} from '@/modules/showroom/advert/update/api';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { APP_URL } from '@/utils/consts';
import { copyToClipboard } from '@/utils/copy';
import { getDateString } from '@/utils/date';
import { showAlert } from '@/utils/network';

export type UseMyAdvertActionsParams = {
  advert: MyAdvert | RentAdvert;
  fetchData: () => void;
};

export type UseMyAdvertActions = {
  isLoading: boolean;
  isUnpublishOpen: boolean;
  isDeleteOpen: boolean;
  setDeleteOpen: (v: boolean) => void;
  onPublishClick: () => Promise<void>;
  onUnpublishClick: () => void;
  closeUnpublish: () => void;
  onUnpublishSubmit: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onDeleteSubmit: () => Promise<void>;
  onShareClick: () => void;
};

export function useMyAdvertActions({
  advert,
  fetchData
}: UseMyAdvertActionsParams): UseMyAdvertActions {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isRent = 'tariffs' in advert;
  const isVrp = 'category' in advert && advert.category.id === VRP_CATEGORY_ID;
  const { refresh } = useContext(MyAdvertsContext);

  // Actions
  const [isLoading, setLoading] = useState(false);
  const [isUnpublishOpen, setUnpublishOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);

  const onShareClick = () => {
    const detailUrl = advert.url ? `${APP_URL}/${advert.url}` : '';
    copyToClipboard(detailUrl, t('common.copied') as string);
  };

  const onPublishClick = async () => {
    setLoading(true);

    try {
      const updateFn = isRent ? updateRentAdvertisement : updateAdvertisement;
      const r = await updateFn(advert.id, { is_published: true });
      const s = r.data.status.title;

      const isModeration = s === AdvertStatusType.moderation;
      const isDraft = s === AdvertStatusType.draft;
      if (isModeration)
        showAlert({ type: 'success', text: t('publish.onModeration') });
      if (isDraft) showAlert({ type: 'success', text: t('publish.drafted') });

      fetchData();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onUnpublishClick = () => {
    setUnpublishOpen(true);
  };

  const closeUnpublish = () => {
    setUnpublishOpen(false);
  };

  const onUnpublishSubmit = () => {
    closeUnpublish();
    fetchData();
  };

  const onEditClick = () => {
    const editUrl = isVrp
      ? `/vrp/edit/${advert.id}`
      : `/cars/edit/${advert.id}`;
    const rentEditUrl = `/cars/rent/edit/${advert.id}`;
    navigate(isRent ? rentEditUrl : editUrl);
  };

  const onDeleteClick = () => {
    setDeleteOpen(true);
  };

  const onDeleteSubmit = async () => {
    setDeleteOpen(false);
    setLoading(true);

    try {
      const deleteFn = isRent ? deleteRentAdvertisement : deleteAdvertisement;
      await deleteFn(advert.id);
      showAlert({
        type: 'success',
        text: t('advertisements.deleted')
      });
      refresh();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    isUnpublishOpen,
    isDeleteOpen,
    setDeleteOpen,
    onPublishClick,
    onUnpublishClick,
    closeUnpublish,
    onUnpublishSubmit,
    onEditClick,
    onDeleteClick,
    onDeleteSubmit,
    onShareClick
  };
}

export function getViewsAndExpensesApexOptions(
  xCategories: unknown[],
  lang: string
): ApexOptions {
  return {
    chart: {
      offsetX: 0,
      offsetY: 0,
      redrawOnParentResize: true,
      parentHeightOffset: 0,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ['var(--clr-blue)'],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: xCategories,
      labels: {
        formatter: (value) => {
          const d = new Date(value);
          return getDateString(d, lang, {
            day: '2-digit',
            month: '2-digit'
          });
        }
      },
      axisBorder: {
        show: true,
        strokeWidth: 2,
        color: 'var(--thm-silver-grey-1)'
      },
      axisTicks: {
        show: false
      }
    },
    legend: {
      show: false
    },
    fill: {
      opacity: 1
    }
  };
}
