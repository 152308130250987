import { useMemo } from 'react';

import { AccountIdType } from '@/modules/accounts/types';
import { useRentSortFilters } from '@/modules/showroom/advert/rent/api';
import { useRentSearchReq } from '@/modules/showroom/advert/rent/my/hooks';
import { RentAdverts } from '@/modules/showroom/advert/rent/my/RentAdverts';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

type Props = {
  showResults?: boolean;
};

export function RentAdvertsWrap({ showResults: showResultsProp }: Props) {
  const [req, showResults] = useRentSearchReq();

  const sortReq = useMemo(
    () => ({
      ...MAX_PG_LIMIT_REQ,
      account_type: AccountIdType.showroom
    }),
    []
  );
  const [sortFilters] = useRentSortFilters(sortReq);

  return (
    <RentAdverts
      req={req}
      showResults={showResults || !!showResultsProp}
      sortFilters={sortFilters || []}
    />
  );
}
