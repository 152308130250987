const PATH = '/dealers/showrooms/callback';

export const CALLBACK_API_ROUTES = {
  changeStatus: `${PATH}/change-status`,
  changeCbStatus: (id: number) => `${PATH}/${id}/change-status`,
  getStatus: `${PATH}/status`,
  getList: `${PATH}/list`,
  getCount: `${PATH}/count`,
  markViewed: `${PATH}/mark-viewed`
};
