import { AvailableBooking } from '@/modules/showroom/advert/rent/update/AdditionalInfo/AvailableBooking/AvailableBooking';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function AvailableBookingWrap() {
  const isBookingAvailable = useRentUpdateAdvertStore(
    (s) => s.isBookingAvailable
  );
  const setBookingAvailable = useRentUpdateAdvertStore(
    (s) => s.setBookingAvailable
  );
  const isFilled = useRentUpdateAdvertStore((s) => s.isBookingAvailableFilled);
  const setFilled = useRentUpdateAdvertStore(
    (s) => s.setBookingAvailableFilled
  );
  return (
    <AvailableBooking
      isBookingAvailable={isBookingAvailable}
      setBookingAvailable={setBookingAvailable}
      isFilled={isFilled}
      setFilled={setFilled}
    />
  );
}
