import { Trans, useTranslation } from 'react-i18next';

import { useStickers } from '@/modules/emojis/api';
import {
  MAX_STICKERS_LEN,
  SCROLLTO
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';
import { Skeleton } from '@/ui/Skeleton';

import cls from './Stickers.module.scss';

const skeletons = (count: number) => new Array(count).fill(0);

type Props = {
  stickersIds: CreateAdvertSlice['stickers'];
  setStickersIds: CreateAdvertSlice['setStickers'];
  contentOnly?: boolean;
  skeletonCount?: number;
};

export function Stickers({
  stickersIds,
  setStickersIds,
  contentOnly,
  skeletonCount
}: Props) {
  const { t } = useTranslation();
  const [list, loading] = useStickers();

  const onStickerClick = (id: number) => {
    return () => {
      if (stickersIds.includes(id)) {
        setStickersIds(stickersIds.filter((s) => s !== id));
      } else {
        setStickersIds([...stickersIds, id]);
      }
    };
  };
  const isMax = stickersIds.length >= MAX_STICKERS_LEN;

  return (
    <div className={contentOnly ? '' : cls.root} id={SCROLLTO.stickers}>
      <div className={contentOnly ? '' : 'box'}>
        {!contentOnly && (
          <h1 className={cls.title}>
            <Trans
              t={t}
              i18nKey="stickers.title"
              components={{
                countWrap: <span />
              }}
              values={{ index: stickersIds.length, count: MAX_STICKERS_LEN }}
            />
          </h1>
        )}
        <p className={cls.desc}>{t('stickers.text')}</p>

        {list && !loading && (
          <ul className={cls.list}>
            {list.map((c) => {
              const active = stickersIds.includes(c.id);

              return (
                <li key={c.id}>
                  <CheckBtn
                    active={active}
                    onClick={onStickerClick(c.id)}
                    disabled={isMax ? !active || loading : loading}
                  >
                    {c.title}
                  </CheckBtn>
                </li>
              );
            })}
          </ul>
        )}

        {loading && (
          <ul className={cls.list}>
            {skeletons(skeletonCount || 10).map((_, i) => (
              <li key={i}>
                <Skeleton width={120} height={48} borderRadius={27} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
