import { axios } from '@/app/api';

import { CreateAdvertReq, CreateAdvertRes } from './types';

export function createAdvertisement(req: CreateAdvertReq) {
  return axios.post<CreateAdvertRes>(
    '/dealers/showrooms/advertisements/create',
    req
  );
}
