import { Phone } from '@/modules/showroom/advert/create/Phone/Phone';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

export function PhoneWrap() {
  const contacts = useUpdateVrpStore((s) => s.contacts);
  const setContacts = useUpdateVrpStore((s) => s.setContacts);
  const disableChat = useUpdateVrpStore((s) => s.disableChat);
  const setDisableChat = useUpdateVrpStore((s) => s.setDisableChat);
  const isPhoneFilled = useUpdateVrpStore((s) => s.isPhoneFilled);
  const setPhoneFilled = useUpdateVrpStore((s) => s.setPhoneFilled);

  return (
    <Phone
      contacts={contacts}
      setContacts={setContacts}
      disableChat={disableChat}
      setDisableChat={setDisableChat}
      isPhoneFilled={isPhoneFilled}
      setPhoneFilled={setPhoneFilled}
    />
  );
}
