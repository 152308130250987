import { ContactCardSkeleton } from '@/modules/showroom/contact/ContactsList/ContactCard/ContactCardSkeleton';

import cls from './ContactsList.module.scss';

const skeletons = new Array(4).fill(0);

export function ContactsListSkeleton() {
  return (
    <ul className={cls.list}>
      {skeletons.map((_, i) => (
        <li key={i}>
          <ContactCardSkeleton />
        </li>
      ))}
    </ul>
  );
}
