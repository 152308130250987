import { useCallback, useEffect, useRef, useState } from 'react';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { useRefState } from '@/hooks/useRefState';
import {
  BookingExistedStatus,
  BookingExistedStatusType,
  BookingType,
  ChangeBookingStatusReq,
  GetExistedBookingStatusesRes,
  GetMyBookingsQueryReq,
  GetMyBookingsRes,
  UpdateBookingReq
} from '@/modules/booking/types';
import { LOAD_COUNT } from '@/modules/showroom/advert/search/hooks';
import { showAlert } from '@/utils/network';

// Statuses
export function changeBookingStatus(
  booking_id: number,
  req: ChangeBookingStatusReq
) {
  return axios.patch<unknown>(
    `/advertisements/rent/booking/bookings/my/${booking_id}/change-status`,
    req
  );
}

function getBookingStatuses() {
  return axios.get<GetExistedBookingStatusesRes>(
    '/advertisements/rent/booking/bookings/my/count-by-status'
  );
}

export function useBookingStatuses(
  refreshKey?: number,
  onLoad?: (res: GetExistedBookingStatusesRes) => void
): [GetExistedBookingStatusesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetExistedBookingStatusesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getBookingStatuses();
      const list = r.data;
      setData(list);
      if (onLoad) onLoad(list);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [onLoad]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

// Bookings
export function updateBooking(booking_id: number, req: UpdateBookingReq) {
  return axios.patch<unknown>(
    `/advertisements/rent/booking/bookings/my/${booking_id}/update`,
    req
  );
}

type UseBookingsPg = {
  isAllLoaded: boolean;
  isLoading: boolean;
  ads: BookingType[] | undefined;
  load: (skip: number) => void;
};

export function useBookingsPg(
  status: Nullable<BookingExistedStatus> | undefined,
  search: string
): UseBookingsPg {
  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoading, setLoading, isLoadingRef] = useRefState<boolean>(false);
  const [ads, setAds] = useState<GetMyBookingsRes>();

  const abortController = useRef<Nullable<AbortController>>(null);

  const load = useCallback(
    async (skip: number) => {
      if (isLoadingRef.current) return;
      if (status === undefined) return;

      setLoading(true);
      if (!skip) setAds([]);

      try {
        const isArchived = status?.status === BookingExistedStatusType.archive;
        const queryReq: GetMyBookingsQueryReq = {
          status_id: isArchived ? null : status?.id || null,
          archived: isArchived,
          search
        };

        if (abortController.current) abortController.current.abort();
        const ab = new window.AbortController();
        abortController.current = ab;

        const r = await axios.get<GetMyBookingsRes>(
          '/advertisements/rent/booking/bookings/my',
          { params: queryReq, signal: ab.signal }
        );
        const loaded = r.data;
        const len = loaded.length;

        setAds((prev) => {
          if (!prev || skip === 0) {
            return loaded;
          }

          return [...prev, ...loaded];
        });
        setAllLoaded(len < LOAD_COUNT);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, search]
  );

  return { isAllLoaded, isLoading, ads, load };
}

export function useBookings(
  status: Nullable<BookingExistedStatus> | undefined,
  search: string
): UseBookingsPg {
  const [isLoading, setLoading, isLoadingRef] = useRefState<boolean>(false);
  const [ads, setAds] = useState<GetMyBookingsRes>();

  const abortController = useRef<Nullable<AbortController>>(null);

  const load = useCallback(
    async () => {
      if (isLoadingRef.current) return;
      if (status === undefined) return;

      setLoading(true);

      try {
        const isArchived = status?.status === BookingExistedStatusType.archive;
        const queryReq: GetMyBookingsQueryReq = {
          status_id: isArchived ? null : status?.id || null,
          archived: isArchived,
          search
        };

        if (abortController.current) abortController.current.abort();
        const ab = new window.AbortController();
        abortController.current = ab;

        const r = await axios.get<GetMyBookingsRes>(
          '/advertisements/rent/booking/bookings/my',
          { params: queryReq, signal: ab.signal }
        );
        const loaded = r.data;

        setAds(loaded);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, search]
  );

  return { isAllLoaded: !!ads, isLoading, ads, load };
}
