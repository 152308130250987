import { Nullable } from '@/app/types';
import { VRP_MOTO_TYPE_ID } from '@/modules/showroom/advert/vrp/helpers';

export interface DrawSettings {
  plate: { w: number; h: number };
  number: {
    w: number;
    h: number;
    x: number;
    y: number;
    fw: number;
    fs: number;
  };
  code: null | {
    w: number;
    h: number;
    x: number;
    y: number;
    fw: number;
    fs: number;
  };
}

// Defaults
const fw = 600;
const DEFAULT_PLATE = {
  w: 672,
  h: 151
};

const MOTO_PLATE = {
  w: 672,
  h: 339
};

const MOTO_DEFAULTS = {
  plate: MOTO_PLATE,
  number: {
    w: 324,
    h: 154,
    x: 271,
    y: 155,
    fw,
    fs: 104
  },
  code: {
    w: 53,
    h: 154,
    x: 92,
    y: 26,
    fw,
    fs: 104
  }
};

// Dubai
const DUBAI_PRIVATE: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 283,
    h: 166,
    x: 258,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  },
  code: {
    w: 121,
    h: 89,
    x: 35,
    y: 55,
    fw,
    fs: 60
  }
};

const DUBAI_CLASSIC: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 283,
    h: 166,
    x: 258,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  },
  code: null
};

const DUBAI_MOTO: DrawSettings = {
  ...MOTO_DEFAULTS
};

// Ajman
const AJMAN_PRIVATE: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 283,
    h: 166,
    x: 161,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  },
  code: {
    w: 61,
    h: 166,
    x: 43,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  }
};

const AJMAN_MOTO: DrawSettings = {
  ...MOTO_DEFAULTS,
  code: null
};

// Sharjah
const SHARJAH_PRIVATE: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 283,
    h: 166,
    x: 331,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  },
  code: {
    w: 39,
    h: 116,
    x: 51,
    y: DEFAULT_PLATE.h / 2 - 116 / 2,
    fw,
    fs: 78
  }
};

const SHARJAH_MOTO: DrawSettings = {
  ...MOTO_DEFAULTS,
  code: null
};

// Fujairah
const FUJAIRAH_PRIVATE: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 204,
    h: 119,
    x: 386,
    y: DEFAULT_PLATE.h / 2 - 119 / 2,
    fw,
    fs: 80
  },
  code: {
    w: 39,
    h: 119,
    x: 72,
    y: DEFAULT_PLATE.h / 2 - 119 / 2,
    fw,
    fs: 80
  }
};

const FUJAIRAH_MOTO: DrawSettings = {
  ...MOTO_DEFAULTS,
  code: null
};

// AbuDhabi
const ABUDHABI_PRIVATE: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 283,
    h: 166,
    x: 297,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  },
  code: {
    w: 62,
    h: 100,
    x: 54.75,
    y: 2,
    fw,
    fs: 68
  }
};

const ABUDHABI_MOTO: DrawSettings = {
  ...MOTO_DEFAULTS
};

// RAK
const RAK_PRIVATE: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 283,
    h: 166,
    x: 323,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  },
  code: {
    w: 59,
    h: 166,
    x: 210,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  }
};

const RAK_MOTO: DrawSettings = {
  ...MOTO_DEFAULTS,
  code: null
};

// UAK
const UAK_PRIVATE: DrawSettings = {
  plate: DEFAULT_PLATE,
  number: {
    w: 283,
    h: 166,
    x: 323,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  },
  code: {
    w: 59,
    h: 166,
    x: 62,
    y: DEFAULT_PLATE.h / 2 - 166 / 2,
    fw,
    fs: 112
  }
};

const UAK_MOTO: DrawSettings = {
  ...MOTO_DEFAULTS,
  code: null
};

// Choose settings
type SettingsMapInnerType = Record<number, DrawSettings>;
type SettingsMapType = Record<number, SettingsMapInnerType>;
const settingsMap: SettingsMapType = {
  1: {
    1: ABUDHABI_PRIVATE,
    [VRP_MOTO_TYPE_ID]: ABUDHABI_MOTO
  },
  2: {
    1: AJMAN_PRIVATE,
    [VRP_MOTO_TYPE_ID]: AJMAN_MOTO
  },
  3: {
    1: DUBAI_PRIVATE,
    2: DUBAI_CLASSIC,
    [VRP_MOTO_TYPE_ID]: DUBAI_MOTO
  },
  4: {
    1: RAK_PRIVATE,
    [VRP_MOTO_TYPE_ID]: RAK_MOTO
  },
  5: {
    1: UAK_PRIVATE,
    [VRP_MOTO_TYPE_ID]: UAK_MOTO
  },
  6: {
    1: SHARJAH_PRIVATE,
    [VRP_MOTO_TYPE_ID]: SHARJAH_MOTO
  },
  7: {
    1: FUJAIRAH_PRIVATE,
    [VRP_MOTO_TYPE_ID]: FUJAIRAH_MOTO
  }
};

export function getDrawSettings(
  emirateId: number,
  typeId: number
): Nullable<DrawSettings> {
  const byEmirate = settingsMap[emirateId];
  if (!byEmirate) return null;
  return byEmirate[typeId];
}
