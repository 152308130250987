import cn from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';

import { EditInputContext } from '@/ui/EditInput/edit-ctx';
import { Spinner } from '@/ui/Spinner/Spinner';
import { TextareaFlat } from '@/ui/Textarea/TextareaFlat';

import cls from './EditInput.module.scss';
import { CheckIcon } from './icons';

type Props = {
  value: string;
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onSave: (v: string) => void;
  isLoading?: boolean;
  placeholder?: string;
  editing?: boolean;
  disabled?: boolean;
  small?: boolean;
  rounded?: boolean;
};

export function EditInput({
  value,
  onChange,
  placeholder,
  editing,
  disabled,
  small,
  onSave,
  isLoading,
  rounded
}: Props) {
  const [isEditing, setEditing] = useState(
    typeof editing === 'boolean' ? editing : false
  );

  const rootRef = useRef<HTMLDivElement>(null);

  const ctx = useContext(EditInputContext);

  const openEdit = () => {
    setEditing(true);
    if (rootRef && rootRef.current) {
      const field = rootRef.current.querySelector('textarea');

      if (field) {
        const end = field.value.length;

        window.setTimeout(() => {
          field.focus();
          field.setSelectionRange(end, end);
        }, 100);
      }
    }
  };

  useEffect(() => {
    if (editing) {
      openEdit();
    }
  }, [editing]);

  useEffect(() => {
    if (ctx.openKey) {
      openEdit();
    }
  }, [ctx.openKey]);

  return (
    <div
      ref={rootRef}
      className={cn(cls.root, {
        [cls.root_small]: small,
        [cls.root_editing]: isEditing,
        [cls.root_filled]: !!value,
        [cls.root_loading]: isLoading,
        [cls.root_rounded]: rounded
      })}
      onBlur={() => null}
    >
      <TextareaFlat
        value={value}
        onChange={onChange}
        disabled={!isEditing || isLoading}
        placeholder={placeholder}
        minRows={1}
        onFocus={() => setEditing(true)}
      />

      {!isEditing && !isLoading && (
        <button
          className={cls.open_btn}
          type="button"
          disabled={isLoading || disabled}
          onClick={openEdit}
        />
      )}

      <button
        className={cn(cls.btn, cls.btn_editing)}
        type="button"
        disabled={isLoading || disabled || !value}
        onClick={() => {
          onSave(value);
          setEditing(false);
        }}
      >
        {isLoading ? (
          <Spinner size={small ? 20 : 24} color="var(--clr-blue)" />
        ) : (
          <CheckIcon />
        )}
      </button>
    </div>
  );
}
