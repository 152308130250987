import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isPhoneValid,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import {
  isTariffsValid,
  isDepositValid,
  isAgeValid,
  isPayMethodsValid
} from '@/modules/showroom/advert/rent/update/helpers';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { EditorCheckList } from '@/ui/layouts/EditorLayout/EditorCheckList/EditorCheckList';

export function CheckList() {
  const { t } = useTranslation();

  const isSpecsFilled = useRentUpdateAdvertStore((s) => s.isSpecsFilled);
  const isPhotosFilled = useRentUpdateAdvertStore((s) => s.isPhotosFilled);
  const isComplectationFilled = useRentUpdateAdvertStore(
    (s) => s.isComplectationFilled
  );
  const isDescriptionFilled = useRentUpdateAdvertStore(
    (s) => s.isDescriptionFilled
  );
  const isAddressFilled = useRentUpdateAdvertStore((s) => s.isAddressFilled);

  const photos = useRentUpdateAdvertStore((s) => s.photos);

  const complectationId = useRentUpdateAdvertStore((s) => s.complectationId);
  const complectationOptionsItemsIds = useRentUpdateAdvertStore(
    (s) => s.complectationOptionsItemsIds
  );
  const isComplectationValid =
    !!complectationId || complectationOptionsItemsIds.length > 0;

  const description = useRentUpdateAdvertStore((s) => s.description);
  const addressId = useRentUpdateAdvertStore((s) => s.addressId);
  const contacts = useRentUpdateAdvertStore((s) => s.contacts);
  const phoneValid = isPhoneValid(contacts);
  const tariffs = useRentUpdateAdvertStore((s) => s.tariffs);
  const isTariffsFilled = useRentUpdateAdvertStore((s) => s.isTariffsFilled);
  const deposit = useRentUpdateAdvertStore((s) => s.deposit);
  const depositReturnDays = useRentUpdateAdvertStore(
    (s) => s.depositReturnDays
  );
  const isDepositFilled = useRentUpdateAdvertStore((s) => s.isDepositFilled);
  const age = useRentUpdateAdvertStore((s) => s.age);
  const driveExp = useRentUpdateAdvertStore((s) => s.driveExp);
  const isAgeFilled = useRentUpdateAdvertStore((s) => s.isAgeFilled);
  const payMethodsIds = useRentUpdateAdvertStore((s) => s.payMethodsIds);
  const isPayMethodIdsFilled = useRentUpdateAdvertStore(
    (s) => s.isPayMethodIdsFilled
  );
  const docsIds = useRentUpdateAdvertStore((s) => s.docsIds);
  const isDocsIdsFilled = useRentUpdateAdvertStore((s) => s.isDocsIdsFilled);

  const resetAll = useRentUpdateAdvertStore((s) => s.resetAll);

  const list = useMemo(
    () => [
      {
        id: SCROLLTO.specs,
        text: t('specs'),
        checked: isSpecsFilled,
        disabled: false
      },
      {
        id: SCROLLTO.photo,
        text: t('upload.image.photo'),
        checked: photos.length > 0,
        disabled: !isSpecsFilled
      },
      {
        id: SCROLLTO.complectation,
        text: t('complectation.title'),
        checked: isComplectationValid,
        disabled: !isPhotosFilled
      },
      {
        id: SCROLLTO.descr,
        text: t('advertisements.description.title'),
        checked: !!description,
        disabled: !isComplectationFilled
      },
      {
        id: SCROLLTO.tariffs,
        text: t('rentTariffs.title'),
        checked: isTariffsValid(tariffs),
        disabled: !isDescriptionFilled
      },
      {
        id: SCROLLTO.deposit,
        text: t('deposit.title'),
        checked: isDepositValid(deposit, depositReturnDays),
        disabled: !isTariffsFilled
      },
      {
        id: SCROLLTO.age,
        text: t('age.title'),
        checked: !!isAgeValid(age, driveExp),
        disabled: !isDepositFilled
      },
      {
        id: SCROLLTO.payMethods,
        text: t('payMethods.title'),
        checked: isPayMethodsValid(payMethodsIds),
        disabled: !isAgeFilled
      },
      {
        id: SCROLLTO.docs,
        text: t('docs.title'),
        checked: !!docsIds && docsIds.length > 0,
        disabled: !isPayMethodIdsFilled
      },
      {
        id: SCROLLTO.showroom,
        text: t('delivery.title'),
        checked: !!addressId,
        disabled: !isDocsIdsFilled
      },
      {
        id: SCROLLTO.phone,
        text: t('showroom.contact.multiple'),
        checked: phoneValid,
        disabled: !isAddressFilled
      }
    ],
    [
      addressId,
      age,
      deposit,
      depositReturnDays,
      description,
      docsIds,
      driveExp,
      isAddressFilled,
      isAgeFilled,
      isComplectationFilled,
      isComplectationValid,
      isDepositFilled,
      isDescriptionFilled,
      isDocsIdsFilled,
      isPayMethodIdsFilled,
      isPhotosFilled,
      isSpecsFilled,
      isTariffsFilled,
      payMethodsIds,
      phoneValid,
      photos.length,
      t,
      tariffs
    ]
  );

  return (
    <EditorCheckList
      list={list}
      onClick={scrollToSection}
      onResetClick={() => resetAll(true)}
    />
  );
}
