import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Description } from '@/modules/showroom/advert/create/Description/Description';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function DescriptionWrap() {
  const { t } = useTranslation();

  const detail = useUpdateAdvertStore((s) => s.detail);
  const storeAdvertId = useUpdateAdvertStore((s) => s.advertId);
  const advertId = storeAdvertId || detail?.id;

  const description = useUpdateAdvertStore((s) => s.description);
  const setDescription = useUpdateAdvertStore((s) => s.setDescription);
  const isDescrGenerating = useUpdateAdvertStore((s) => s.isDescrGenerating);
  const setDescrGenerating = useUpdateAdvertStore((s) => s.setDescrGenerating);
  const isDescriptionFilled = useUpdateAdvertStore(
    (s) => s.isDescriptionFilled
  );
  const setDescriptionFilled = useUpdateAdvertStore(
    (s) => s.setDescriptionFilled
  );

  const saveDetail = useUpdateAdvertStore((s) => s.saveDetail);
  const updateAdvert = useCallback(() => {
    if (advertId) return saveDetail(advertId);
    return Promise.resolve();
  }, [advertId, saveDetail]);

  return (
    <Description
      nextSection={SCROLLTO.vin}
      placeholder={t('advertisements.description.placeholder')}
      value={description}
      onChange={setDescription}
      generating={isDescrGenerating}
      setGenerating={setDescrGenerating}
      isDescriptionFilled={isDescriptionFilled}
      setDescriptionFilled={setDescriptionFilled}
      advertId={advertId}
      updateAdvert={updateAdvert}
    />
  );
}
