import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDebounce } from '@/hooks/useDebounce';
import { useCheckLoginValidity } from '@/modules/showroom/api';
import { useSignupStore } from '@/modules/showroom/CreateShowroom/store';
import { Login } from '@/modules/showroom/ShowroomEdit/Login/Login';
import { Button } from '@/ui/Button/Button';
import { validateUsername } from '@/utils/validate';

import cls from './CreateShowroom.module.scss';
import { ArrowIcon } from './icons';

export function ShowroomUsername() {
  const { t } = useTranslation();

  const login = useSignupStore((s) => s.login);
  const setLogin = useSignupStore((s) => s.setLogin);

  const debouncedLogin = useDebounce(login, 800);
  const isLoginValid = validateUsername(login);

  const [isLoginAvailable, isLoginValidating] =
    useCheckLoginValidity(debouncedLogin);

  const navigate = useNavigate();
  const onPrevClick = () => {
    navigate('/showroom/create/design');
  };
  const onNextClick = () => {
    navigate('/showroom/create/photo');
  };

  return (
    <>
      <div className={cls.header}>
        <button className={cls.back_btn} type="button" onClick={onPrevClick}>
          <ArrowIcon />
        </button>
        <h2 className={cls.title}>{t('shortAddress')}</h2>
      </div>
      <Login
        login={login}
        setLogin={setLogin}
        isLoginAvailable={isLoginAvailable}
        isLoginValidating={isLoginValidating}
        debouncedLogin={debouncedLogin}
        fieldsOnly
      />
      <div className={cls.btn}>
        <Button
          onClick={onNextClick}
          disabled={(!isLoginAvailable && !isLoginValidating) || !isLoginValid}
          color="blue"
          fullWidth
        >
          {t('common.continue')}
        </Button>
      </div>
    </>
  );
}
