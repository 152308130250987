import { create } from 'zustand';

import { Nullable } from '@/app/types';
import { getQuickReplies, saveQuickReply } from '@/modules/chat/api-req';
import {
  GetQuickReplyReq,
  GetQuickReplyRes,
  QuickReplyType
} from '@/modules/chat/types';
import { showAlert } from '@/utils/network';

export type QuickRepliesStore = {
  loading: boolean;
  quickRepliesList: Nullable<QuickReplyType[]>;
  fetchQuickReplies: (
    params?: GetQuickReplyReq
  ) => Promise<Nullable<GetQuickReplyRes>>;
  saveQuickReply: (text: string) => void;
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  quickReplyText: Nullable<string>;
  setQuickReplyText: (text: string) => void;
  resetQuickReplyText: () => void;
};

export const useQuickRepliesStore = create<QuickRepliesStore>((set, get) => ({
  loading: false,
  quickRepliesList: null,
  fetchQuickReplies: async (params) => {
    set({ loading: true });
    try {
      const { data } = await getQuickReplies(params);
      set({ quickRepliesList: data });
      return data || null;
    } catch (error) {
      showAlert({ error });
    } finally {
      set({ loading: false });
    }

    return null;
  },
  saveQuickReply: async (text) => {
    set({ loading: true });

    try {
      const req = {
        text
      };

      const { data } = await saveQuickReply(req);
      if (data.result) {
        await get().fetchQuickReplies();
      }
    } catch (error) {
      showAlert({ error });
    } finally {
      set({ loading: false });
    }
  },
  isOpen: false,
  setOpen: (state: boolean) => set({ isOpen: state }),
  quickReplyText: null,
  setQuickReplyText: (quickReplyText) => set({ quickReplyText }),
  resetQuickReplyText: () => set({ quickReplyText: null })
}));
