import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { useEmirates } from '@/modules/emirate/api';
import { EmirateType, GetEmiratesReq } from '@/modules/emirate/types';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

import cls from '../Filters.module.scss';

export function EmirateFilter() {
  const { t } = useTranslation();

  const [isCatOpen, setCatOpen] = useState(false);
  const onceOpened = useOnceTrue(isCatOpen);

  const emirateId = useSearchStore((s) => s.vrpPlateEmirateId);
  const setEmirateId = useSearchStore((s) => s.setPlateEmirateId);

  const emiratesReq = useMemo<Nullable<GetEmiratesReq>>(() => {
    if (!onceOpened && !emirateId) return null;
    return MAX_PG_LIMIT_REQ;
  }, [onceOpened, emirateId]);
  const [allEmirates, emiratesLoading] = useEmirates(emiratesReq);
  const emirate = allEmirates?.find((tp) => tp.id === emirateId);

  const onEmirateChange = (v: FilterType) => {
    const em = v as EmirateType;
    setEmirateId(em.id);
    setCatOpen(false);
  };

  const onEmirateReset = () => {
    setCatOpen(false);
    setEmirateId(null);
  };

  return (
    <>
      <FilterBtn
        text={t('category')}
        onClick={() => setCatOpen(true)}
        active={isCatOpen}
        onResetClick={onEmirateReset}
        value={emirate?.name}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-emirate"
        isOpen={isCatOpen}
        close={() => setCatOpen(false)}
        parentCls={cls.li}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={emirateId}
            list={allEmirates || []}
            loading={emiratesLoading}
            onClick={onEmirateChange}
            search=""
            allButtonText={t('common.anyW')}
            onAllClick={onEmirateReset}
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
}
