import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { createShowroom } from '@/modules/showroom/api';
import { useShowroomDataValidate } from '@/modules/showroom/CreateShowroom/hooks';
import { useSignupStore } from '@/modules/showroom/CreateShowroom/store';
import { Contact } from '@/modules/showroom/ShowroomEdit/Contact/Contact';
import { ShowroomEditValues } from '@/modules/showroom/ShowroomEdit/ShowroomEdit';
import { Button } from '@/ui/Button/Button';
import { showAlert } from '@/utils/network';

import cls from './CreateShowroom.module.scss';
import { ArrowIcon } from './icons';

export function ShowroomContact() {
  const { t } = useTranslation();

  // Contact
  const name = useSignupStore((s) => s.name);
  const photos = useSignupStore((s) => s.photos);
  const description = useSignupStore((s) => s.description);
  const siteUrl = useSignupStore((s) => s.siteUrl);
  const login = useSignupStore((s) => s.login);
  const coverUrl = useSignupStore((s) => s.coverUrl);
  const logoUrl = useSignupStore((s) => s.logoUrl);

  const contactName = useSignupStore((s) => s.contactName);
  const setContactName = useSignupStore((s) => s.setContactName);
  const contactPhone = useSignupStore((s) => s.contactPhone);
  const setContactPhone = useSignupStore((s) => s.setContactPhone);
  const langIds = useSignupStore((s) => s.langIds);
  const setLangIds = useSignupStore((s) => s.setLangIds);
  const isContactValid = contactName && contactPhone && langIds.length > 0;

  const resetValues = useSignupStore((s) => s.reset);
  const navigate = useNavigate();
  const { validate } = useShowroomDataValidate();

  const [isLoading, setLoading] = useState(false);

  const onCreateShowroom = async (values: ShowroomEditValues) => {
    const isDataValid = validate(values);
    if (!isDataValid) return;

    setLoading(true);

    try {
      await createShowroom({
        description: values.description.trim(),
        site_url: values.siteUrl,
        photos: values.photos,
        name: values.name.trimEnd(),
        username: values.username,
        logo_url: values.logoUrl,
        cover: values.coverUrl || '',
        contact: {
          name: values.contactName,
          phone: values.contactPhone,
          email: values.contactEmail || '',
          lang_ids: values.langIds || []
        }
      });
      resetValues();
      navigate('/showroom/create/success');
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onContactContinueClick = async () => {
    const values: ShowroomEditValues = {
      photos,
      description,
      siteUrl,
      name,
      username: login,
      coverUrl,
      logoUrl,
      contactName,
      contactPhone,
      langIds
    };
    onCreateShowroom(values);
  };

  const onPrevClick = () => {
    navigate('/showroom/create/photo');
  };

  return (
    <>
      <div className={cls.header}>
        <button className={cls.back_btn} type="button" onClick={onPrevClick}>
          <ArrowIcon />
        </button>
        <h2 className={cls.title}>{t('showroom.contact.title')}</h2>
      </div>
      <Contact
        contactName={contactName}
        setContactName={setContactName}
        contactPhone={contactPhone}
        setContactPhone={setContactPhone}
        langIds={langIds}
        setLangIds={setLangIds}
        fieldsOnly
        showLang
      />
      <div className={cls.btn}>
        <Button
          onClick={onContactContinueClick}
          disabled={!isContactValid || isLoading}
          loading={isLoading}
          color="blue"
          fullWidth
        >
          {t('common.continue')}
        </Button>
      </div>
    </>
  );
}
