export function CloseIcon() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 23.1411C5.84372 23.6157 6.66308 23.6096 7.12564 23.1471L14.0021 16.2706L20.8746 23.1451C21.3452 23.6157 22.1637 23.6229 22.6403 23.1391C23.117 22.6533 23.119 21.8531 22.6484 21.3805L15.7759 14.4968L22.6484 7.62435C23.119 7.15375 23.1262 6.34444 22.6403 5.8678C22.1545 5.38199 21.3452 5.37998 20.8746 5.85975L14.0021 12.7322L7.12564 5.85774C6.66308 5.38803 5.83455 5.37081 5.35791 5.86579C4.88328 6.3516 4.88932 7.15978 5.35187 7.62234L12.2284 14.4968L5.35187 21.3845C4.88932 21.845 4.87411 22.6644 5.35791 23.1411Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PlusIcon() {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4964 26.1594C20.8862 26.1594 26.1615 20.8768 26.1615 14.4963C26.1615 8.10655 20.8769 2.83325 14.4871 2.83325C8.10868 2.83325 2.83334 8.10655 2.83334 14.4963C2.83334 20.8768 8.11793 26.1594 14.4964 26.1594Z"
        fill="currentColor"
      />
      <path
        d="M8.9783 14.5035C8.9783 13.8493 9.43761 13.3859 10.0877 13.3859H13.3881V10.0835C13.3881 9.43544 13.8442 8.96484 14.4871 8.96484C15.1414 8.96484 15.6047 9.43544 15.6047 10.0835V13.3859H18.9163C19.5572 13.3859 20.0258 13.8493 20.0258 14.5035C20.0258 15.1484 19.5572 15.5933 18.9163 15.5933H15.6047V18.9049C15.6047 19.553 15.1414 20.0123 14.4871 20.0123C13.8442 20.0123 13.3881 19.5437 13.3881 18.9049V15.5933H10.0877C9.43761 15.5933 8.9783 15.1484 8.9783 14.5035Z"
        fill="var(--thm-bg)"
      />
    </svg>
  );
}

export function RefreshIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7153 17.6039C14.8732 17.6039 18.3334 14.1532 18.3334 9.9953C18.3334 5.83745 14.8732 2.38672 10.7153 2.38672C6.55745 2.38672 3.10671 5.82802 3.10671 9.98587H4.54924C4.54924 6.554 7.28343 3.82924 10.7153 3.82924C14.1472 3.82924 16.8908 6.56343 16.8908 9.9953C16.8908 13.4272 14.1472 16.1896 10.7153 16.1708C8.69766 16.1614 6.91573 15.2185 5.8032 13.7383C5.54863 13.3895 5.14321 13.2858 4.79437 13.5026C4.44553 13.71 4.36067 14.1815 4.64352 14.5492C6.06718 16.3876 8.26396 17.6039 10.7153 17.6039ZM2.24875 8.5245C1.65477 8.5245 1.51335 8.92991 1.8339 9.38247L3.51213 11.7678C3.77612 12.1355 4.17211 12.1355 4.42667 11.7678L6.1049 9.37304C6.41602 8.92991 6.26517 8.5245 5.68062 8.5245H2.24875Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DurationIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4909 10.9978H6.42032C6.05563 10.9978 5.77738 10.7197 5.77738 10.3549C5.77738 9.99685 6.05563 9.71861 6.42032 9.71861H9.85455V5.0716C9.85455 4.71207 10.1328 4.43528 10.4909 4.43528C10.8504 4.43528 11.1338 4.71207 11.1338 5.0716V10.3549C11.1338 10.7197 10.8504 10.9978 10.4909 10.9978Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8297 9.99774C18.8297 14.5553 15.0616 18.3285 10.4975 18.3285C5.94146 18.3285 2.16675 14.5553 2.16675 9.99774C2.16675 5.43363 5.93484 1.66699 10.4909 1.66699C15.055 1.66699 18.8297 5.43363 18.8297 9.99774ZM3.8144 9.99774C3.8144 13.71 6.78521 16.6875 10.4975 16.6875C14.2098 16.6875 17.1887 13.71 17.1887 9.99774C17.1887 6.28545 14.2032 3.30804 10.4909 3.30804C6.77859 3.30804 3.8144 6.28545 3.8144 9.99774Z"
        fill="currentColor"
      />
    </svg>
  );
}
