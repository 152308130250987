export function ChevronIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58528 7.99374C3.42344 7.83536 3.33325 7.62425 3.33325 7.38111C3.33325 6.89079 3.71356 6.50774 4.20256 6.50774C4.44842 6.50774 4.67516 6.59992 4.83414 6.77124L10.3715 12.4385H9.63512L15.1671 6.77124C15.3309 6.59857 15.559 6.50774 15.7973 6.50774C16.2849 6.50774 16.6666 6.89079 16.6666 7.38111C16.6666 7.6256 16.5778 7.83671 16.4132 7.99374L10.6815 13.8596C10.4912 14.0609 10.2596 14.164 10.0037 14.1667C9.74293 14.1667 9.51619 14.0623 9.31972 13.8596L3.58528 7.99374Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RemoveIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76023 6.06738C4.42002 5.72717 4.40382 5.11966 4.76833 4.77136C5.11663 4.40685 5.73224 4.42305 6.07245 4.76326L10.001 8.69183L13.9296 4.76326C14.2779 4.41495 14.8773 4.40685 15.2256 4.77136C15.5901 5.11966 15.582 5.72717 15.2337 6.07548L11.3051 10.0041L15.2337 13.9245C15.582 14.2809 15.5901 14.8803 15.2256 15.2286C14.8773 15.5932 14.2779 15.5851 13.9296 15.2367L10.001 11.3082L6.07245 15.2367C5.73224 15.577 5.11663 15.5932 4.76833 15.2286C4.40382 14.8803 4.42002 14.2728 4.76023 13.9326L8.6888 10.0041L4.76023 6.06738Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ListIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49976 13.5L4.5831 10.5834C4.26093 10.2612 3.7386 10.2612 3.41643 10.5834C3.09426 10.9055 3.09426 11.4279 3.41643 11.75L6.91051 15.2441C7.23594 15.5696 7.76358 15.5696 8.08902 15.2441L16.9164 6.41671C17.2386 6.09455 17.2386 5.57221 16.9164 5.25005C16.5943 4.92788 16.0719 4.92788 15.7498 5.25005L7.49976 13.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
