import { create } from 'zustand';

import { LANGS } from '@/modules/i18n';
import { getRegSpecs } from '@/modules/regional-specs/api';
import { RegSpec } from '@/modules/regional-specs/types';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';
import {
  getFromStorage,
  saveInStorage,
  removeFromStorage
} from '@/utils/local-storage';
import { showAlert } from '@/utils/network';

const REGSPECS_STORE_NAME = 'autoae-regspecs-list';

type StoreValue = Record<string, RegSpec[]>;
const defaultValue = LANGS.reduce((acc, lang) => {
  return { ...acc, [lang]: [] };
}, {});

interface RegSpecsStore {
  data: StoreValue;
  fetchData: () => void;
  reset: () => void;
}

export const useRegSpecsStore = create<RegSpecsStore>((set, get) => ({
  data: getFromStorage(REGSPECS_STORE_NAME) || defaultValue,
  fetchData: async () => {
    const current = get().data;
    if (Object.values(current).every((list) => !!list.length)) return;

    try {
      const promises = LANGS.map((lang) => getRegSpecs(MAX_PG_LIMIT_REQ, lang));
      const results = await Promise.all(promises);
      const data: StoreValue = results.reduce((acc, res, i) => {
        return { ...acc, [LANGS[i]]: res.data };
      }, {});

      set({ data });
      saveInStorage(REGSPECS_STORE_NAME, data);
    } catch (error) {
      showAlert({ error });
    }
  },
  reset: () => {
    set({ data: defaultValue });
    removeFromStorage(REGSPECS_STORE_NAME);
  }
}));
