import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import {
  DEFAULT_CALL_AFTER_MINUTES,
  DEFAULT_CALL_BEFORE_MINUTES,
  isPhoneValid,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { defaultCommunicationMethod } from '@/modules/showroom/advert/create/Phone/helpers';
import {
  ContactType,
  PhoneContact
} from '@/modules/showroom/advert/create/Phone/PhoneContact';
import { PhoneContactAdd } from '@/modules/showroom/advert/create/Phone/PhoneContactAdd';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import {
  createShowroomContact,
  updateShowroomContact,
  useShowroomContactLangs,
  useShowroomContacts
} from '@/modules/showroom/contact/api';
import {
  ContactForm,
  ContactFormValues
} from '@/modules/showroom/contact/ContactForm/ContactForm';
import { ShowroomContact } from '@/modules/showroom/contact/types';
import { Button } from '@/ui/Button/Button';
import { Skeleton } from '@/ui/Skeleton';
import { Switch } from '@/ui/Switch/Switch';
import { showAlert } from '@/utils/network';

import cls from './Phone.module.scss';

type Props = {
  nextSection?: string;
  hideChat?: boolean;
  contacts: CreateAdvertSlice['contacts'];
  setContacts: CreateAdvertSlice['setContacts'];
  disableChat: CreateAdvertSlice['disableChat'];
  setDisableChat: CreateAdvertSlice['setDisableChat'];
  isPhoneFilled: CreateAdvertSlice['isPhoneFilled'];
  setPhoneFilled: CreateAdvertSlice['setPhoneFilled'];
};

export function Phone({
  hideChat,
  nextSection,
  contacts: storeContacts,
  setContacts,
  disableChat,
  setDisableChat,
  isPhoneFilled,
  setPhoneFilled
}: Props) {
  const { t } = useTranslation();
  const [langs] = useShowroomContactLangs();

  const [list, contactsLoading, fetchContacts] = useShowroomContacts();
  const contacts = useMemo<ContactType[]>(() => {
    if (!list) return [];
    return list.map((c) => {
      const storeContact = storeContacts?.find((sc) => sc.id === c.id);
      return {
        ...c,
        communication_method: storeContact
          ? storeContact.communication_method
          : defaultCommunicationMethod
      };
    });
  }, [list, storeContacts]);

  const storeContactIds = storeContacts?.map((c) => c.id);
  const selectedContacts = useMemo(
    () =>
      contacts.filter((c) =>
        storeContactIds ? storeContactIds.includes(c.id) : false
      ),
    [contacts, storeContactIds]
  );
  const selectedIds = selectedContacts.map((c) => c.id);
  const unselectedContacts = useMemo(
    () =>
      contacts.filter((c) => {
        return !selectedIds.includes(c.id);
      }),
    [contacts, selectedIds]
  );

  const onContactReplace = (oldId: number, newId: number) => {
    const prev = storeContacts || [];
    const newArr = prev.map((c) => {
      if (c.id === oldId) {
        const newContact = contacts.find((pc) => pc.id === newId);
        if (newContact) return newContact;
      }
      return c;
    });
    setContacts(newArr);
  };

  const onContactAdd = (id: number) => {
    const prev = storeContacts ? storeContacts : [];
    const newContact = contacts.find((pc) => pc.id === id);
    if (newContact) setContacts([...prev, newContact]);
  };

  const onContactRemove = (removeId: number) => {
    const prev = storeContacts ? storeContacts : [];
    setContacts(prev.filter((c) => c.id !== removeId));
  };

  const onContactChange = (fullContact: ContactType) => {
    const { communication_method, ...contact } = fullContact;

    const sc = storeContacts || [];
    const res = sc.map((c) => {
      if (c.id === contact.id) {
        return {
          ...c,
          communication_method: { ...communication_method }
        };
      }

      return c;
    });
    setContacts(res);
  };

  // Add
  const [isAddLoading, setAddLoading] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSubmit = async (values: ContactFormValues) => {
    setAddLoading(true);

    try {
      await createShowroomContact({
        name: values.name,
        avatar_url: values.avatar,
        phone: values.phone,
        email: values.email,
        lang_ids: values.langIds,
        call_after: values.call_after,
        call_before: values.call_before
      });
      fetchContacts();
      setAddOpen(false);
    } catch (error) {
      showAlert({ error });
    } finally {
      setAddLoading(false);
    }
  };

  // Edit
  const [isEditLoading, setEditLoading] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [editingContact, setEditingContact] =
    useState<Nullable<ShowroomContact>>(null);
  const onEditClick = (contact: ShowroomContact) => {
    setEditingContact(contact);
    setEditOpen(true);
  };
  const onEditSubmit = async (values: ContactFormValues) => {
    if (!editingContact) return;
    setEditLoading(true);

    try {
      const phone =
        values.phone === editingContact.phone ? undefined : values.phone;
      const email =
        values.email === editingContact.email ? undefined : values.email;

      await updateShowroomContact({
        phone,
        email,
        contact_id: editingContact.id,
        name: values.name,
        lang_ids: values.langIds,
        avatar_url: values.avatar,
        call_after: values.call_after,
        call_before: values.call_before
      });
      fetchContacts();
      setEditOpen(false);
      setEditingContact(null);
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditLoading(false);
    }
  };

  // Continue
  const isValid = isPhoneValid(storeContacts);
  const onContinueClick = () => {
    setPhoneFilled(true);
    scrollToSection(nextSection || SCROLLTO.price, 100);
  };

  // Checkbox
  const enableChat = !disableChat;

  return (
    <div className={cls.root} id={SCROLLTO.phone}>
      <div className="box">
        <h1 className={cls.title}>{t('showroom.contact.multiple')}</h1>

        {selectedContacts.length > 0 && (
          <ul className={cls.list}>
            {selectedContacts.map((c) => (
              <li key={c.id}>
                <PhoneContact
                  list={contacts}
                  contact={c}
                  onRemoveClick={onContactRemove}
                  onContactReplace={onContactReplace}
                  onContactChange={onContactChange}
                  onEditClick={onEditClick}
                  loading={isAddLoading || isEditLoading}
                />
              </li>
            ))}
          </ul>
        )}

        {!contactsLoading && unselectedContacts.length > 0 && (
          <PhoneContactAdd
            list={unselectedContacts}
            onAdd={onContactAdd}
            onEditClick={onEditClick}
            loading={isAddLoading || isEditLoading}
          />
        )}

        {contactsLoading && (
          <Skeleton
            width="100%"
            height={56}
            borderRadius={12}
            style={{ marginBottom: 16 }}
          />
        )}

        <ul className={cls.checkboxes}>
          {!hideChat && (
            <li>
              <div className={cls.checkbox_wrap}>
                <button
                  className={cls.checkbox_btn}
                  type="button"
                  onClick={() => setDisableChat(!disableChat)}
                />

                <div className={cls.checkbox_text}>
                  <p>{t('enableChat.title')}</p>
                  <small>{t('enableChat.text')}</small>
                </div>
                <Switch
                  id="advert-create-phone-enableChat"
                  checked={enableChat}
                  onChange={() => null}
                  green
                />
              </div>
            </li>
          )}
        </ul>

        <div className={cls.buttons}>
          <Button
            onClick={onAddClick}
            variant="tertiary"
            color="blue"
            fullWidth
          >
            {t('addContact')}
          </Button>

          {!isPhoneFilled && (
            <Button
              onClick={onContinueClick}
              disabled={!isValid}
              color="blue"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>

      <ContactForm
        langsList={langs || []}
        isOpen={isAddOpen}
        close={() => setAddOpen(false)}
        title={t('showroom.contacts.new')}
        onSubmit={onAddSubmit}
        loading={isAddLoading}
      />

      {editingContact && (
        <ContactForm
          isEdit
          key={editingContact.id}
          langsList={langs || []}
          isOpen={isEditOpen}
          close={() => setEditOpen(false)}
          title={t('showroom.contacts.manager')}
          onSubmit={onEditSubmit}
          loading={isEditLoading}
          values={{
            name: editingContact.name,
            phone: editingContact.phone,
            email: editingContact.email || '',
            avatar: editingContact.avatar_url || '',
            langIds: editingContact.contact_langs.map((l) => l.id),
            call_after: editingContact.call_after || DEFAULT_CALL_AFTER_MINUTES,
            call_before:
              editingContact.call_before || DEFAULT_CALL_BEFORE_MINUTES
          }}
        />
      )}
    </div>
  );
}
