import { Navigate, useParams } from 'react-router';

import { useEmirates } from '@/modules/emirate/api';
import { useAuthorDetail } from '@/modules/showroom/advert/detail/api';
import { VRP_ADVERTS_URL } from '@/modules/showroom/advert/my/helpers';
import { UpdateVrp } from '@/modules/showroom/advert/vrp/UpdateVrp/UpdateVrp';
import { Spinner } from '@/ui/Spinner/Spinner';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

export function UpdateVrpWrap() {
  const { id } = useParams();
  const numId = id ? Number(id) : null;
  const isNaN = Number.isNaN(numId);

  const [emirates, emiratesLoading] = useEmirates(MAX_PG_LIMIT_REQ);
  const [detail, detailLoading, notFound] = useAuthorDetail(numId);
  const loading = emiratesLoading || detailLoading;

  if (!numId || isNaN || notFound) return <Navigate to={VRP_ADVERTS_URL} />;
  if (loading) return <Spinner centered />;
  if (!detail || !emirates) return <Spinner centered />;

  return <UpdateVrp detail={detail} emirates={emirates} />;
}
