import { MyAdvertCardSkeleton } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/MyAdvertCardSkeleton';
import { MyRentAdvertCard } from '@/modules/showroom/advert/rent/my/MyRentAdvertsList/MyRentAdvertCard/MyRentAdvertCard';
import { RentAdvert } from '@/modules/showroom/advert/rent/my/types';
import { LOAD_COUNT } from '@/modules/showroom/advert/search/hooks';

import cls from './MyRentAdvertsList.module.scss';

type Props = {
  list: RentAdvert[];
  onPublishToggle?: () => void;
};

export function MyRentAdvertsList({ list, onPublishToggle }: Props) {
  return (
    <ul className={cls.root}>
      {list.map((advert) => (
        <li key={advert.id}>
          <MyRentAdvertCard advert={advert} onPublishToggle={onPublishToggle} />
        </li>
      ))}
    </ul>
  );
}

const skeletons = new Array(LOAD_COUNT).fill(0);
export function MyRentAdvertsListSkeleton() {
  return (
    <ul className={cls.root}>
      {skeletons.map((_, i) => (
        <li key={i}>
          <MyAdvertCardSkeleton />
        </li>
      ))}
    </ul>
  );
}
