import { ExtPhotoType, PhotoType } from '@/app/types';
import {
  isDiscountsValid,
  isPhoneValid,
  isPriceValid
} from '@/modules/showroom/advert/create/helpers';
import {
  CreateAdvertSlice,
  SpecsSlice
} from '@/modules/showroom/advert/create/types';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import {
  optimizeReq,
  optimizeReqForVrp
} from '@/modules/showroom/advert/update/compare-req';
import { UpdateAdvertReq } from '@/modules/showroom/advert/update/types';
import { CreateVrpStore } from '@/modules/showroom/advert/vrp/CreateVrp/store';
import { isVrpPriceValid } from '@/modules/showroom/advert/vrp/helpers';
import { SLUG_NEW } from '@/utils/consts';

export function makeUpdateAdvertReq(
  store: CreateAdvertSlice & SpecsSlice,
  detail: AdvertAuthorDetail
): Omit<UpdateAdvertReq, 'is_published'> {
  return optimizeReq(store, detail);
}

export function makeUpdateAdvertReqForVrp(
  store: CreateVrpStore,
  detail: AdvertAuthorDetail
): Omit<UpdateAdvertReq, 'is_published'> {
  return optimizeReqForVrp(store, detail);
}

export function getDetailFilledMaps(detail: AdvertAuthorDetail) {
  const isNewCar = detail.type?.slug === SLUG_NEW;

  const complectationId = detail.complectation?.id || null;
  const complectationOptionsItemsIds = detail.complectation
    ? detail.complectation.complectation_options.reduce<number[]>(
        (acc, cat) => {
          const itemsIds = cat.options.reduce<number[]>((acc2, opt) => {
            return [...acc2, ...opt.items.map((item) => item.id)];
          }, []);

          return [...acc, ...itemsIds];
        },
        []
      )
    : [];

  const warranty = detail.car_warranty?.expired_at || '';

  const discountsValid = isDiscountsValid(
    detail.price || 0,
    detail.discount?.discount_amount || 0,
    detail.discount?.discounts || []
  );

  const initialMap: Record<string, boolean> = {
    isSpecsFilled: !!detail.modification && !!detail.colour,
    isBrokenFilled: detail.is_broken !== null,
    isMileageFilled: detail.mileage !== null,
    isPhotosFilled: !!detail.photos && detail.photos.length > 0,
    isComplectationFilled:
      !!complectationId || complectationOptionsItemsIds.length > 0,
    isOwnerFilled: isNewCar ? true : !!detail.owner,
    isRegSpecsFilled: !!detail.regional_spec,
    isWarrantyFilled: !!warranty && !!detail.car_warranty?.include,
    isDescriptionFilled: !!detail.description,
    isVinFilled: !!detail.vin,
    isAddressFilled: !!detail.address_id,
    isPhoneFilled: isPhoneValid(detail.communication_method),
    isPriceFilled: isPriceValid(detail.price || 0) && discountsValid
  };
  const newMap: Record<string, boolean> = { ...initialMap };
  const keys = Object.keys(initialMap);
  const keysWithDefaultValue = ['isMileageFilled', 'isBrokenFilled'];

  keys.forEach((key, i) => {
    const nextValues = keys
      .filter((k) => !keysWithDefaultValue.includes(k))
      .slice(i);

    const someNextFilled = nextValues.some((k) => {
      if (k === 'isOwnerFilled') {
        return !!detail.owner;
      }
      return initialMap[k];
    });

    newMap[key] = initialMap[key] || someNextFilled;
  });

  return { initialMap, newMap };
}

export function getVrpDetailFilledMaps(detail: AdvertAuthorDetail) {
  const discountsValid = isDiscountsValid(
    detail.price || 0,
    detail.discount?.discount_amount || 0,
    detail.discount?.discounts || []
  );

  const isNumberSaved = !!detail.vrp_image_url;
  const initialMap: Record<string, boolean> = {
    numberWasSaved: isNumberSaved,
    isDescriptionFilled: !!detail.description,
    isAddressFilled: !!detail.address_id,
    isPhoneFilled: isPhoneValid(detail.communication_method),
    isPriceFilled: isVrpPriceValid(detail.price || 0) && discountsValid
  };
  const newMap: Record<string, boolean> = { ...initialMap };
  const keys = Object.keys(initialMap);
  keys.forEach((key, i) => {
    const nextValues = keys.slice(i);
    const someNextFilled = nextValues.some((k) => initialMap[k]);
    newMap[key] = initialMap[key] || someNextFilled;
  });

  return { initialMap, newMap };
}

export function cleanPhotosObjects(
  photos: Array<ExtPhotoType | PhotoType>
): Array<Omit<ExtPhotoType | PhotoType, 'localUrl' | 'file'>> {
  return photos
    .map((p) => {
      if ('medium_url' in p) {
        const ph = p as ExtPhotoType;
        return {
          photo_url: ph.photo_url,
          thumbnail_url: ph.thumbnail_url,
          medium_url: ph.medium_url
        };
      }

      return {
        photo_url: p.photo_url,
        thumbnail_url: p.thumbnail_url
      };
    })
    .filter((p) => !p.photo_url.includes('blob:'));
}
