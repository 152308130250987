import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axios } from '@/app/api';
import {
  AdvertStatusType,
  GetCountAdsByStatusRes,
  GetExistedStatusesRes
} from '@/modules/showroom/advert/types';
import { showAlert } from '@/utils/network';

// Existed statuses
export function getExistedStatuses(category_id?: number) {
  return axios.get<GetExistedStatusesRes>(
    '/dealers/showrooms/advertisements/existed-statuses',
    { params: { category_id } }
  );
}

export function useExistedStatuses(
  category_id?: number,
  refreshKey?: number,
  onLoad?: (res: GetExistedStatusesRes) => void
): [GetExistedStatusesRes | undefined, boolean] {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetExistedStatusesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getExistedStatuses(category_id);
      const list = r.data;

      const hasPublished = list.find(
        (v) => v.status === AdvertStatusType.published
      );

      const res = hasPublished
        ? list
        : [
            {
              status: AdvertStatusType.published,
              title: t('advertisements.statusType.onSale'),
              count: 0
            },
            ...list
          ];

      setData(res);
      if (onLoad) onLoad(res);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [category_id, t, onLoad]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}

// Count ads by status
export function getCountAdsByStatus() {
  return axios.get<GetCountAdsByStatusRes>(
    '/dealers/showrooms/advertisements/count-by-status'
  );
}

export function useCountAdsByStatus(
  refreshKey?: number
): [GetCountAdsByStatusRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetCountAdsByStatusRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getCountAdsByStatus();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}
