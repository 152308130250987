import cn from 'classnames';
import { Link } from 'react-router-dom';

import cls from './BackLink.module.scss';
import { ChevronIcon } from './icons';

type Props = {
  onClick?: () => void;
  to?: string;
  className?: string;
  external?: boolean;
  text: string;
};

export const BackLink: React.FC<Props> = ({
  className,
  onClick,
  to,
  external,
  text
}) => {
  if (onClick) {
    return (
      <button
        className={cn(cls.root, className)}
        type="button"
        onClick={onClick}
      >
        <ChevronIcon />
        <span>{text}</span>
      </button>
    );
  }

  if (external && to)
    return (
      <a
        className={cn(cls.root, className)}
        href={to}
        target="_blank"
        rel="noreferrer"
      >
        <ChevronIcon />
        <span>{text}</span>
      </a>
    );

  return (
    <Link className={cn(cls.root, className)} to={to || '/'}>
      <ChevronIcon />
      <span>{text}</span>
    </Link>
  );
};
