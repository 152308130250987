import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { useRentSearchStore } from '@/modules/showroom/advert/rent/my/store';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import cls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useTransportColors } from '@/modules/transport/api';
import {
  GetTransportColorsReq,
  TransportColor
} from '@/modules/transport/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

export function ColorFilter() {
  const { t } = useTranslation();

  const [isColorOpen, setColorOpen] = useState(false);
  const onceOpened = useOnceTrue(isColorOpen);

  const colorsIds = useRentSearchStore((s) => s.colorsIds);
  const setColorsIds = useRentSearchStore((s) => s.setColorsIds);

  const req = useMemo<Nullable<GetTransportColorsReq>>(() => {
    if (!onceOpened) return null;
    return MAX_PG_LIMIT_REQ;
  }, [onceOpened]);

  const [allColors, colorsLoading] = useTransportColors(req);
  const selectedColors = allColors?.filter((b) => colorsIds?.includes(b.id));

  const onColorsChange = (v: FilterType) => {
    const b = v as TransportColor;
    if (colorsIds && colorsIds.includes(b.id)) {
      setColorsIds(colorsIds.filter((bodyId) => bodyId !== b.id));
    } else {
      setColorsIds(colorsIds ? [...colorsIds, b.id] : [b.id]);
    }
  };

  const onColorsReset = () => {
    setColorOpen(false);
    setColorsIds(null);
  };

  return (
    <>
      <FilterBtn
        text={t('color')}
        onClick={() => setColorOpen(true)}
        active={isColorOpen}
        onResetClick={onColorsReset}
        value={selectedColors
          ?.map((b) => b.name)
          .join(t('common.listSeparator') as string)}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-color"
        isOpen={isColorOpen}
        close={() => setColorOpen(false)}
        parentCls={cls.li}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={colorsIds}
            list={allColors || []}
            loading={colorsLoading}
            onClick={onColorsChange}
            search=""
            allButtonText={t('common.anyN')}
            onAllClick={onColorsReset}
            selectStyle
            multiple
          />
        </li>
      </Dropdown>
    </>
  );
}
