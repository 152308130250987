import cn from 'classnames';
import Tooltip from 'rc-tooltip';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import cls from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/Actions.module.scss';
import {
  EditIcon,
  PauseIcon,
  EyeIcon,
  DeleteIcon,
  ShareIcon
} from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/icons';
import { useMyAdvertActions } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/helpers';
import { UnpublishRentAdvert } from '@/modules/showroom/advert/rent/my/MyRentAdvertsList/MyRentAdvertCard/UnpublishRentAdvert/UnpublishRentAdvert';
import { RentAdvert } from '@/modules/showroom/advert/rent/my/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { rcTooltipProps } from '@/utils/consts';

type Props = {
  advert: RentAdvert;
  onPublishToggle?: () => void;
};

export function Actions({ advert, onPublishToggle }: Props) {
  const { t } = useTranslation();

  // Flags
  const isPublished = advert.status.title === AdvertStatusType.published;
  const isRemoved = advert.status.title === AdvertStatusType.removed;
  const canDelete = !isPublished;

  // Actions hook
  const fetchData = useCallback(() => {
    if (onPublishToggle) onPublishToggle();
  }, [onPublishToggle]);
  const params = useMemo(() => ({ advert, fetchData }), [advert, fetchData]);
  const actionsData = useMyAdvertActions(params);
  const {
    onShareClick,
    isLoading: loading,
    onPublishClick,
    onUnpublishClick,
    isUnpublishOpen,
    closeUnpublish,
    onUnpublishSubmit,
    onDeleteClick,
    onDeleteSubmit,
    isDeleteOpen,
    setDeleteOpen,
    onEditClick
  } = actionsData;

  return (
    <>
      <ul className={cls.root}>
        <li>
          <Tooltip
            placement="left"
            overlay={t('common.share')}
            {...rcTooltipProps}
          >
            <button
              className={cls.action_btn}
              type="button"
              onClick={onShareClick}
            >
              <ShareIcon />
            </button>
          </Tooltip>
        </li>

        <li>
          <Tooltip
            placement="left"
            overlay={t('common.edit')}
            {...rcTooltipProps}
          >
            <button
              className={cls.action_btn}
              type="button"
              onClick={onEditClick}
            >
              <EditIcon />
            </button>
          </Tooltip>
        </li>

        {isPublished && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('unpublish.do')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onUnpublishClick}
                disabled={loading}
              >
                <PauseIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {isRemoved && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('publish.do')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onPublishClick}
                disabled={loading}
              >
                <EyeIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {canDelete && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('advertisements.delete')}
              {...rcTooltipProps}
            >
              <button
                className={cn(cls.action_btn, cls.action_btn_red)}
                type="button"
                onClick={() => {
                  onDeleteClick();
                }}
                disabled={loading}
              >
                <DeleteIcon />
              </button>
            </Tooltip>
          </li>
        )}
      </ul>

      {isUnpublishOpen && (
        <UnpublishRentAdvert
          advertId={advert.id}
          name={`my-rent-unpublish-${advert.id}`}
          isOpen={isUnpublishOpen}
          close={closeUnpublish}
          onSubmit={onUnpublishSubmit}
        />
      )}

      {canDelete && (
        <ConfirmModal
          name="my-delete-confirm"
          isOpen={isDeleteOpen}
          close={() => setDeleteOpen(false)}
          onButtonClick={(confirmed) => {
            if (confirmed) {
              onDeleteSubmit();
            } else {
              setDeleteOpen(false);
            }
          }}
          title={t('advertisements.deleteConfirm')}
          confirmText={t('common.delete')}
          cancelText={t('common.cancel')}
        />
      )}
    </>
  );
}
