import { APP_AUTH_NAME } from '@/utils/consts';
import { getCookie } from '@/utils/cookie';

import { AuthType } from './types';

export const AUTH_REDIRECT_ROUTES = ['/signin', '/signup'];
export const CODE_LEN = 4;
export const COUNTER = 60;

// Get auth from cookie
export const getCookieAuth = (): [string | undefined, AuthType | undefined] => {
  let auth: AuthType | undefined;
  const authInCookie = getCookie(APP_AUTH_NAME);

  if (authInCookie) {
    const parsed = JSON.parse(authInCookie) as AuthType;

    if (parsed) {
      auth = parsed;
    }
  }

  return [authInCookie, auth];
};
