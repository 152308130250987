import { useMemo, useRef } from 'react';

import { Input } from '@/ui/Input/Input';
import { maxLength, onlyNumbers } from '@/utils/format';

import cls from './Input.module.scss';

type Props = {
  value: string;
  onChange: (v: string) => void;
  length: number;
  numbersOnly?: boolean;
  disabled?: boolean;
  small?: boolean;
  onLastKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export function SplittedInput({
  value,
  onChange,
  length,
  disabled,
  small,
  numbersOnly,
  onLastKeyDown
}: Props) {
  const arr = useMemo(() => new Array(length).fill(0), [length]);
  const refs = useRef<HTMLInputElement[]>([]);

  const onInputChange = (v: string, i: number) => {
    const arr = value.split('');
    arr[i] = v;
    onChange(arr.join(''));
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>, i: number) => {
    const inputValue =
      e.currentTarget.value.length > 1
        ? e.currentTarget.value.slice(1)
        : e.currentTarget.value;

    const v = maxLength(numbersOnly ? onlyNumbers(inputValue) : inputValue, 1);
    onInputChange(v, i);

    const isLast = i === length - 1;
    const nextField = refs.current[i + 1];
    if (v && v !== value[i] && !isLast && nextField) {
      nextField.focus();
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, i: number) => {
    const isFirst = i === 0;
    if (!isFirst && e.key === 'Backspace' && refs.current[i - 1]) {
      e.preventDefault();
      onChange(value.slice(0, value.length - 1));
      refs.current[i - 1].focus();
      return;
    }

    const isLast = i === length - 1;
    if (isLast && onLastKeyDown) onLastKeyDown(e);
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData('text');
    if (pastedText) {
      e.preventDefault();
      onChange(pastedText.slice(0, length));
    }
  };

  return (
    <div className={cls.splitted_container} style={{ gap: small ? 4 : 12 }}>
      {arr.map((_, i) => {
        return (
          <Input
            key={i}
            value={value[i] || ''}
            onChange={(e) => handleChange(e, i)}
            onKeyDown={(e) => onKeyDown(e, i)}
            onPaste={(e) => onPaste(e)}
            ref={(el) => {
              if (el) refs.current[i] = el;
            }}
            disabled={disabled}
            inputMode={numbersOnly ? 'numeric' : undefined}
            autoFocus={i === 0}
            small={small}
            oneChar
            name="fixSafariAutocompleteWithSearchWordLol"
          />
        );
      })}
    </div>
  );
}
