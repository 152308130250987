/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';

import { Nullable } from '@/app/types';
import { emiratesNamesMap } from '@/modules/mapkit/helpers';
import {
  SearchAutoCompleteItem,
  SearchAutoCompleteResponse
} from '@/modules/mapkit/types';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';

import cls from './SuggestionsDropdown.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
  emirateId?: Nullable<number>;
  search: any;
  query: string;
  onClick: (v: SearchAutoCompleteItem) => void;
};

export const SuggestionsDropdown: React.FC<Props> = ({
  emirateId,
  search,
  query,
  onClick,
  isOpen,
  close
}) => {
  const [data, setData] = useState<SearchAutoCompleteItem[]>([]);

  const load = useCallback(() => {
    if (query) {
      search.autocomplete(
        query,
        (err: unknown, res: SearchAutoCompleteResponse) => {
          if (err) {
            console.error(err);
            return;
          }

          if (res.results) {
            const results = res.results
              .filter((v) => !!v.coordinate)
              .filter((v) => {
                if (emirateId) {
                  return (
                    !!v.administrativeArea &&
                    emiratesNamesMap[emirateId].includes(
                      v.administrativeArea.toLowerCase()
                    )
                  );
                }

                return true;
              });
            setData(results);
          }
        }
      );
    }
  }, [query, search, emirateId]);

  useEffect(() => {
    load();
  }, [load]);

  if (!data || data.length <= 0) return <></>;

  return (
    <Dropdown
      name="mapkit-suggestions"
      className={cls.root}
      isOpen={isOpen}
      close={close}
      fullWidth
    >
      {data.map((v) => (
        <DropdownItem key={v._completionUrl}>
          <button
            className={cls.suggest_btn}
            type="button"
            onClick={() => onClick(v)}
          >
            <b>{v.displayLines[0]}</b> {v.displayLines[1]}
          </button>
        </DropdownItem>
      ))}
    </Dropdown>
  );
};
