import React, { useState, ReactNode } from 'react';

import { ToggleButtonProps } from '@/ui/ToggleButton/ToggleButton';

import cls from './ToggleButton.module.scss';

interface ToggleButtonGroupProps {
  value: number[];
  children: ReactNode;
  multiple?: boolean;
  onChange: (selectedValues: number[]) => void;
}

export const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  value,
  children,
  multiple = false,
  onChange
}) => {
  const [selectedValues, setSelectedValues] = useState<number[]>(value || []);

  const handleToggle = (value: number) => {
    let newSelectedValues: number[];

    if (multiple) {
      if (selectedValues.includes(value)) {
        newSelectedValues = selectedValues.filter((item) => item !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
    } else {
      newSelectedValues = [value];
    }

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  return (
    <div className={cls.group_root}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<ToggleButtonProps>(child)) {
          return React.cloneElement(
            child as React.ReactElement<ToggleButtonProps>,
            {
              isSelected: selectedValues.includes(child.props.value),
              onToggle: handleToggle
            }
          );
        }
        return child;
      })}
    </div>
  );
};
