import cn from 'classnames';

import cls from './Stepper.module.scss';

type Props = {
  index: number;
  count: number;
};

export function Stepper({ index, count }: Props) {
  return (
    <div className={cls.stepper}>
      {Array.from({ length: count }).map((_, i) => (
        <div key={i} className={cls.step_wrap}>
          <div
            className={cn(cls.step, {
              [cls.step_completed]: i < index,
              [cls.step_active]: i === index
            })}
          />
          {i !== count - 1 && <span />}
        </div>
      ))}
    </div>
  );
}
