import { useTranslation } from 'react-i18next';

import { useCreateAdvertCopy } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/helpers';
import { CopyIcon } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/icons';
import { MyAdvert } from '@/modules/showroom/advert/my/types';

import cls from './Actions.module.scss';

type Props = {
  advert: MyAdvert;
  onClick?: () => void;
};

export function CreateAdvertCopy({ advert, onClick }: Props) {
  const { t } = useTranslation();
  const [onCreateAdvertCopy, creating] = useCreateAdvertCopy(advert);

  return (
    <button
      className={cls.dropdown_btn}
      onClick={() => {
        onClick && onClick();
        onCreateAdvertCopy();
      }}
      type="button"
      disabled={creating}
    >
      {t('advertisements.createCopy')}
      <CopyIcon />
    </button>
  );
}
