import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { UploadItem } from '@/modules/files/upload/helpers';
import { UploadAvatar } from '@/modules/files/upload/UploadAvatar/UploadAvatar';
import { hours } from '@/modules/showroom/advert/create/Phone/helpers';
import {
  confirmContactEmail,
  confirmContactEmailSendCode,
  confirmContactPhone,
  confirmContactPhoneSendCode
} from '@/modules/showroom/contact/api';
import { ShowroomContactLang } from '@/modules/showroom/contact/types';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { EditEmail } from '@/ui/PhoneConfirm/EmailCodeConfirm/EditEmail';
import { EmailConfirm } from '@/ui/PhoneConfirm/EmailConfirm';
import { EditPhone } from '@/ui/PhoneConfirm/PhoneCodeConfirm/EditPhone';
import { PhoneConfirm } from '@/ui/PhoneConfirm/PhoneConfirm';
import { useFormatPhone } from '@/ui/PhoneInput/helpers';
import { MultipleSelect } from '@/ui/Select/MultipleSelect';
import { SelectRange } from '@/ui/Select/SelectRange';
import { minutesToHHMM } from '@/utils/date';
import { maxLength, preventExtraSpaces } from '@/utils/format';

import cls from './ContactForm.module.scss';

const MAX_NAME_LEN = 64;

export type ContactFormValues = {
  name: string;
  phone: string;
  email: string;
  avatar: string;
  langIds: number[];
  call_after: Nullable<number>;
  call_before: Nullable<number>;
};

type Props = {
  langsList: ShowroomContactLang[];
  isOpen: boolean;
  close: () => void;
  title: string;
  onSubmit: (values: ContactFormValues) => void;
  onDeleteClick?: () => void;
  values?: ContactFormValues;
  loading?: boolean;
  isEdit?: boolean;
};

export function ContactForm({
  langsList,
  isOpen,
  close,
  title,
  onSubmit,
  onDeleteClick,
  values,
  loading,
  isEdit
}: Props) {
  const { t } = useTranslation();
  const [isEditPhoneOpen, setEditPhoneOpen] = useState(false);
  const [isEditEmailOpen, setEditEmailOpen] = useState(false);

  const [name, setName] = useState(values?.name || '');
  const [phone, setPhone] = useState(values?.phone || '');
  const [email, setEmail] = useState(values?.email || '');

  const formattedPhone = useFormatPhone(phone);

  // Avatar
  const [avatar, setAvatar] = useState(values?.avatar || '');
  const onAvatarUpload = (v: UploadItem[]) => {
    if (v[0]) {
      setAvatar(v[0].response.url);
    }
  };
  const [avatarUploading, setAvatarUploading] = useState(false);

  // Langs
  const [langs, setLangs] = useState(values?.langIds || []);
  const langsOptions = langsList.map((l) => ({
    id: String(l.id),
    text: l.name
  }));
  const langsValue = langsOptions.filter((l) => langs.includes(Number(l.id)));
  const onLangChange = (opts: OptionI[]) => {
    setLangs(opts.map((o) => Number(o.id)));
  };

  // Hours
  const hoursOptions = useMemo<OptionI[]>(
    () =>
      hours.map((h) => ({
        id: String(h),
        text: minutesToHHMM(h * 60)
      })),
    []
  );

  const [after, setAfter] = useState<OptionI>();
  const [before, setBefore] = useState<OptionI>();
  const afterValue = useMemo(
    () =>
      after
        ? {
            ...after,
            text: t('common.fromTime', { time: after.text })
          }
        : undefined,
    [after, t]
  );
  const beforeValue = useMemo(
    () =>
      before
        ? {
            ...before,
            text: t('common.toTime', { time: before.text })
          }
        : undefined,
    [before, t]
  );

  const afterOptions = useMemo(() => {
    if (before) {
      const beforeH = Number(before.id);
      return hoursOptions.filter((o) => Number(o.id) < beforeH);
    }

    return hoursOptions;
  }, [before, hoursOptions]);

  const beforeOptions = useMemo(() => {
    if (after) {
      const afterH = Number(after.id);
      return hoursOptions.filter((o) => Number(o.id) > afterH);
    }

    return hoursOptions;
  }, [after, hoursOptions]);

  const handleAfter = (v: OptionI) => {
    const h = Number(v.id);

    if (!before) {
      setAfter(v);
    } else if (before) {
      const beforeH = Number(before.id);

      if (h < beforeH) {
        setAfter(v);
      }
    }
  };

  const handleBefore = (v: OptionI) => {
    const h = Number(v.id);

    if (!after) {
      setBefore(v);
    } else if (after) {
      const afterH = Number(after.id);

      if (h > afterH) {
        setBefore(v);
      }
    }
  };

  // Reset values on open
  useEffect(() => {
    if (isOpen) {
      setAvatar(values?.avatar || '');
      setName(values?.name || '');
      setPhone(values?.phone || '');
      setEmail(values?.email || '');
      setLangs(values?.langIds || []);
    }
  }, [isOpen, values]);

  useEffect(() => {
    if (!after) {
      const contactMinutes = values?.call_after;
      const opt = hoursOptions.find((o) => {
        const currentHourMinutes = Number(o.id) * 60;
        return currentHourMinutes === contactMinutes;
      });
      setAfter(opt);
    }
  }, [after, hoursOptions, values?.call_after]);

  useEffect(() => {
    if (!before) {
      const contactMinutes = values?.call_before;
      const opt = hoursOptions.find((o) => {
        const currentHourMinutes = Number(o.id) * 60;
        return currentHourMinutes === contactMinutes;
      });
      setBefore(opt);
    }
  }, [before, hoursOptions, values?.call_before]);

  // Submit
  const isValid = !!name && !!phone && langs.length > 0;

  const onSubmitClick = () => {
    if (isValid)
      onSubmit({
        avatar,
        name,
        phone,
        email,
        langIds: langs,
        call_after: after ? Number(after.id) * 60 : null,
        call_before: before ? Number(before.id) * 60 : null
      });
  };

  return (
    <AsideModal
      title={title}
      name="contact-form"
      isOpen={isOpen}
      close={close}
      containerClass={cls.modal_container}
    >
      <div className={cls.root}>
        <div className={cls.avatar}>
          <UploadAvatar
            onUpload={onAvatarUpload}
            avatarSrc={avatar}
            label={t(isEdit || avatar ? 'changePhoto' : 'selectPhoto')}
            setAvatarUploading={setAvatarUploading}
            crop
          />
        </div>

        <div className={cls.field}>
          <Input
            value={name}
            onChange={(e) =>
              setName(
                maxLength(
                  preventExtraSpaces(e.currentTarget.value.trimStart()),
                  MAX_NAME_LEN
                )
              )
            }
            disabled={loading}
            label={t('showroom.contact.name')}
          />
        </div>

        {phone ? (
          <>
            <div className={cls.field}>
              <Input value={formattedPhone} label={t('phone')} disabled />

              <button
                className={cls.edit_btn}
                onClick={() => setEditPhoneOpen(true)}
              >
                {t('common.change')}
              </button>
            </div>

            <EditPhone
              oldPhone={formattedPhone}
              onConfirm={(p) => setPhone(p)}
              confirmPhoneSendCode={confirmContactPhoneSendCode}
              confirmPhone={confirmContactPhone}
              setEditPhoneOpen={setEditPhoneOpen}
              isEditPhoneOpen={isEditPhoneOpen}
            />
          </>
        ) : (
          <div className={cls.field}>
            <PhoneConfirm
              onConfirm={(p) => setPhone(p)}
              confirmPhoneSendCode={confirmContactPhoneSendCode}
              confirmPhone={confirmContactPhone}
              supportEmailKey="auth.supportEmail"
              hideCountries
            />
          </div>
        )}

        {email ? (
          <>
            <div className={cls.field}>
              <Input value={email} label={t('auth.email')} disabled />

              <button
                className={cls.edit_btn}
                onClick={() => setEditEmailOpen(true)}
              >
                {t('common.change')}
              </button>
            </div>

            <EditEmail
              oldEmail={email}
              onConfirm={(p) => setEmail(p)}
              confirmEmailSendCode={confirmContactEmailSendCode}
              confirmEmail={confirmContactEmail}
              setEditEmailOpen={setEditEmailOpen}
              isEditEmailOpen={isEditEmailOpen}
            />
          </>
        ) : (
          <div className={cls.field}>
            <EmailConfirm
              onConfirm={(p) => setEmail(p)}
              confirmEmailSendCode={confirmContactEmailSendCode}
              confirmEmail={confirmContactEmail}
            />
          </div>
        )}

        <div className={cls.hours}>
          <SelectRange
            name={`contact-call-time`}
            from={afterValue}
            onFromChange={handleAfter}
            fromOptions={afterOptions}
            to={beforeValue}
            onToChange={handleBefore}
            toOptions={beforeOptions}
            label={t('contacts.calls.workTime')}
            fullWidth
          />
        </div>

        <div className={cls.field}>
          <MultipleSelect
            name="contact-form-langs"
            options={langsOptions}
            onChange={onLangChange}
            value={langsValue}
            disabled={loading}
            label={t('showroom.contacts.speakOn')}
            dropdownFullWidth
          />

          <p className={cls.label}>{t('showroom.contacts.langInfo')}</p>
        </div>

        <div className={cls.footer}>
          {isEdit ? (
            <>
              <Button
                onClick={onSubmitClick}
                loading={loading}
                disabled={loading || !isValid || avatarUploading}
                color="green"
                fullWidth
              >
                {t('common.saveChanges')}
              </Button>
              {onDeleteClick && (
                <Button
                  onClick={onDeleteClick}
                  loading={loading}
                  disabled={loading}
                  color="red"
                  variant="tertiary"
                  fullWidth
                >
                  {t('showroom.contacts.delete')}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                onClick={onSubmitClick}
                loading={loading}
                disabled={loading || !isValid || avatarUploading}
                color="green"
                fullWidth
              >
                {t('showroom.contacts.addManager')}
              </Button>
              <p className={cls.text}>{t('showroom.contacts.createText')}</p>
            </>
          )}
        </div>
      </div>
    </AsideModal>
  );
}
