import { AxiosError } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { useRefState } from '@/hooks/useRefState';
import { useAccountStore } from '@/modules/accounts/store';
import { CREDITS_API_ROUTES } from '@/modules/credit/api';
import {
  CreditRequestType,
  GetCreditFormRes,
  GetCreditRequestsReq,
  GetCreditRequestsRes
} from '@/modules/credit/types';
import { showAlert } from '@/utils/network';

export function useCreditForm(): [
  GetCreditFormRes | undefined,
  boolean,
  VoidFunction
] {
  const account = useAccountStore((s) => s.accountInfo);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetCreditFormRes>();

  const load = useCallback(async () => {
    if (!account?.id) return;

    setLoading(true);

    try {
      const r = await axios.get<GetCreditFormRes>(CREDITS_API_ROUTES.getForm, {
        params: { account_id: account.id }
      });
      setData(r.data);
    } catch (error) {
      const e = error as AxiosError;
      const notFound = e.isAxiosError && e.response?.status === 404;
      if (!notFound) showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [account]);

  useEffect(() => {
    load();
  }, [account?.id, load]);

  return [data, loading, load];
}

const LOAD_COUNT = 20;

export type UseCreditRequestsPg = {
  isAllLoaded: boolean;
  isLoading: boolean;
  response: GetCreditRequestsRes | undefined;
  data: CreditRequestType[] | undefined;
  load: (skip: number) => void;
};

export function useCreditRequestsPg(
  search: string,
  orderBy?: GetCreditRequestsReq['order_by']
): UseCreditRequestsPg {
  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoading, setLoading, isLoadingRef] = useRefState<boolean>(false);
  const [response, setResponse] = useState<GetCreditRequestsRes>();
  const [data, setData] = useState<CreditRequestType[]>();

  const abortController = useRef<Nullable<AbortController>>(null);

  const load = useCallback(
    async (skip: number) => {
      if (isLoadingRef.current) return;

      setLoading(true);
      if (!skip) {
        setData([]);
        setResponse(undefined);
      }

      try {
        const queryReq: GetCreditRequestsReq = {
          search: search || undefined,
          skip,
          limit: LOAD_COUNT,
          order_by: orderBy
        };

        if (abortController.current) abortController.current.abort();
        const ab = new window.AbortController();
        abortController.current = ab;

        const r = await axios.get<GetCreditRequestsRes>(
          CREDITS_API_ROUTES.getRequests,
          { params: queryReq, signal: ab.signal }
        );

        const loaded = r.data.requested_credits;
        const len = loaded.length;

        setResponse(r.data);
        setData((prev) => {
          if (!prev || skip === 0) {
            return loaded;
          }

          return [...prev, ...loaded];
        });
        setAllLoaded(len < LOAD_COUNT);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderBy, search]
  );

  return { isAllLoaded, isLoading, data, response, load };
}
