import { useThemeStore } from './store';

export function useDarkmode() {
  const isDark = useThemeStore((s) => s.isDark);
  const toggleDark = useThemeStore((s) => s.toggleDark);
  const setSystemTheme = useThemeStore((s) => s.setSystem);
  const setLightTheme = useThemeStore((s) => s.setLight);
  const setDarkTheme = useThemeStore((s) => s.setDark);
  const theme = useThemeStore((s) => s.theme);

  return {
    isDark,
    toggleDark,
    setSystemTheme,
    setDarkTheme,
    setLightTheme,
    theme
  };
}
