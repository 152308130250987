import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { StatisticAdvertWithAmount } from '@/modules/wallet/ExpensesDays/types';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './ExpensesDays.module.scss';

type Props = {
  advert: StatisticAdvertWithAmount;
};

export function Advert({ advert }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const name = useAdvertName(advert, true);
  const isVrp = !!advert.vrp_image_url;
  const url = useMemo(() => {
    if (advert.url) return `${APP_URL}/${advert.url}`;
    return isVrp ? `/vrp/edit/${advert.id}` : `/cars/edit/${advert.id}`;
  }, [advert.id, advert.url, isVrp]);

  return (
    <>
      <a className={cls.advert} href={url} target="_blank" rel="noreferrer">
        {advert.promotion && (
          <>
            <img src={advert.promotion.logo_url} alt="" />
            <span>{advert.promotion.name}</span>
          </>
        )}
        {name}
        <span className={cls.advert_line} />
        <span>
          {t('priceCurrency', {
            price: numberWithSpaces(advert.amount, lang),
            currency: ADV_CUR
          })}
        </span>
      </a>
    </>
  );
}
