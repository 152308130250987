import cn from 'classnames';
import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { Nullable } from '@/app/types';

import cls from './Textarea.module.scss';
import { TextareaProps as Props } from './TextareaProps';

export const TextareaFlat: React.FC<Props> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    onPaste,
    disabled,
    placeholder,
    onKeyDown,
    name,
    minRows,
    maxRows,
    autoFocus,
    suffix,
    allowScrollAfter
  } = props;

  const [el, setElement] = useState<Nullable<HTMLTextAreaElement>>(null);

  useEffect(() => {
    if (!el || !allowScrollAfter) return;

    if (el.offsetHeight < allowScrollAfter) {
      el.style.maxHeight = 'unset';
      el.style.overflow = 'hidden';
    } else {
      el.style.maxHeight = `${allowScrollAfter}px`;
      el.style.overflow = 'auto';
    }
  }, [value, el, allowScrollAfter]);

  return (
    <div className={cls.root}>
      <TextareaAutosize
        minRows={minRows}
        maxRows={maxRows}
        ref={(e) => {
          if (e) {
            setElement(e);
          }
        }}
        autoFocus={autoFocus}
        className={cn(cls.textarea_flat, 'hide-scrollbar')}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onPaste={onPaste}
        placeholder={placeholder || ''}
        disabled={disabled}
        name={name}
      />
      {suffix && <div className={cls.suffix}>{suffix}</div>}
    </div>
  );
};

TextareaFlat.displayName = 'TextareaFlat';
