import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@/hooks/useDebounce';
import { MyBookingsResult } from '@/modules/booking/MyBookings/MyBookingsResult';
import { Statuses } from '@/modules/booking/MyBookings/Statuses';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { SEARCH_RESULT_ID } from '@/modules/showroom/advert/search/consts';
import filtersCls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import searchCls from '@/modules/showroom/advert/search/SearchAdverts/SearchAdverts.module.scss';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

export function MyBookings() {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);

  const { setStatus, refreshKey } = useContext(MyAdvertsContext);

  useEffect(() => {
    return () => {
      setStatus(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={searchCls.root}>
      <div>
        <Statuses />

        <div className={filtersCls.root}>
          <div className={filtersCls.search}>
            <InputSearch
              value={search}
              onChange={setSearch}
              placeholder={t('common.search')}
              showClear
              compact
              outlined
            />
          </div>
        </div>

        <div className={searchCls.container}>
          <div id={SEARCH_RESULT_ID} key={refreshKey + debouncedSearch}>
            <MyBookingsResult search={debouncedSearch} />
          </div>
        </div>
      </div>
    </div>
  );
}
