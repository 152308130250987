export function AddIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6639 7.99785C14.6639 11.6439 11.6494 14.6625 7.9981 14.6625C4.35326 14.6625 1.3335 11.6439 1.3335 7.99785C1.3335 4.34657 4.34797 1.33325 7.99281 1.33325C11.6441 1.33325 14.6639 4.34657 14.6639 7.99785ZM5.47886 7.3634C5.10735 7.3634 4.84489 7.62817 4.84489 8.002C4.84489 8.37055 5.10735 8.62475 5.47886 8.62475H7.36477V10.5171C7.36477 10.8821 7.62541 11.1499 7.9928 11.1499C8.36663 11.1499 8.6314 10.8875 8.6314 10.5171V8.62475H10.5238C10.89 8.62475 11.1577 8.37055 11.1577 8.002C11.1577 7.62817 10.89 7.3634 10.5238 7.3634H8.6314V5.47632C8.6314 5.10597 8.36663 4.83706 7.9928 4.83706C7.62541 4.83706 7.36477 5.10597 7.36477 5.47632V7.3634H5.47886Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.96654 14.63C11.6189 14.63 14.6342 11.6113 14.6342 7.96505C14.6342 4.31354 11.6136 1.30005 7.96125 1.30005C4.31538 1.30005 1.30005 4.31354 1.30005 7.96505C1.30005 11.6113 4.32067 14.63 7.96654 14.63Z"
          fill="var(--thm-black)"
        />
      </g>
      <path
        d="M5.84794 10.6962C5.50809 10.6962 5.24438 10.4261 5.24438 10.0852C5.24438 9.92697 5.30531 9.77292 5.42251 9.66219L7.10568 7.9718L5.42251 6.28787C5.30531 6.17186 5.24438 6.02307 5.24438 5.8637C5.24438 5.51746 5.50809 5.25912 5.84794 5.25912C6.02193 5.25912 6.15784 5.3177 6.27621 5.43074L7.96646 7.11534L9.66962 5.42545C9.79326 5.30183 9.92918 5.24854 10.0979 5.24854C10.4377 5.24854 10.7067 5.51217 10.7067 5.85311C10.7067 6.01661 10.6523 6.15483 10.5233 6.28258L8.83605 7.9718L10.518 9.65691C10.6405 9.76763 10.7015 9.92169 10.7015 10.0852C10.7015 10.4261 10.4324 10.6962 10.0873 10.6962C9.91332 10.6962 9.76154 10.6376 9.64726 10.5182L7.96646 8.83591L6.29325 10.5182C6.17372 10.6376 6.02193 10.6962 5.84794 10.6962Z"
        fill="var(--thm-white)"
      />
    </svg>
  );
}
