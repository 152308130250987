import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { getDateString } from '@/utils/date';

import cls from './NameRow.module.scss';

type Props = {
  name: string;
  date?: Date;
  nameClassName?: string;
};

export function NameRow({ name, date, nameClassName }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const createdDate = date || new Date();
  const createdStr = getDateString(createdDate, lang, {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });

  return (
    <div className={cls.info}>
      <b className={cn(cls.name, nameClassName)}>{name}</b>
      <div className={cls.text}>
        <p>{t('dealer')}</p>
        <span className={cls.text_separator}>{t('common.dot')}</span>
        <p>{t('since', { date: createdStr })}</p>
      </div>
    </div>
  );
}
