import { create } from 'zustand';

import { AutoAPI } from '@/app/api';
import { Nullable } from '@/app/types';
import { logoutAccount } from '@/modules/auth/api';
import { APP_AUTH_NAME } from '@/utils/consts';
import { deleteCookie, setCookie } from '@/utils/cookie';
import { showAlert } from '@/utils/network';

import { AuthState, AuthType } from './types';

interface AuthStore {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
  auth: Nullable<AuthType>;
  setAuth: (auth: AuthType) => void;
  remove: () => void;
  signout: () => Promise<void>;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  authState: AuthState.initial,
  setAuthState: (authState: AuthState) => set({ authState }),
  auth: null,
  setAuth: (auth: AuthType) => {
    setCookie(APP_AUTH_NAME, JSON.stringify(auth));
    AutoAPI.updateAuth(auth);
    set({ auth, authState: AuthState.authed });
  },
  remove: () => {
    deleteCookie(APP_AUTH_NAME);
    AutoAPI.updateAuth();
    set({ auth: null, authState: AuthState.not_authed });
  },
  signout: () => {
    const { remove } = get();
    return new Promise<void>((resolve, reject) => {
      logoutAccount()
        .then(() => {
          remove();
          resolve();
        })
        .catch((error) => {
          showAlert({ error });
          remove();
          reject();
        });
    });
  }
}));
