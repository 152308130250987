export function CheckIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.82886 20.9999C10.3539 20.9999 10.7681 20.7808 11.0559 20.3417L20.547 5.54581C20.7597 5.213 20.8449 4.93452 20.8449 4.66048C20.8449 3.96517 20.356 3.48535 19.6534 3.48535C19.155 3.48535 18.8656 3.65297 18.5633 4.12754L9.78846 18.068L5.26664 12.2539C4.97241 11.8642 4.66364 11.701 4.22743 11.701C3.50648 11.701 3 12.2057 3 12.9027C3 13.2022 3.11188 13.5048 3.36371 13.8107L8.59516 20.356C8.94352 20.7917 9.32015 20.9999 9.82886 20.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RemoveIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59194 19.4071C5.00834 19.8139 5.71066 19.8087 6.10714 19.4122L12.0013 13.5181L17.892 19.4105C18.2954 19.8139 18.9969 19.82 19.4054 19.4053C19.814 18.9889 19.8157 18.3031 19.4123 17.898L13.5216 11.9977L19.4123 6.10701C19.8157 5.70364 19.8219 5.00994 19.4054 4.60139C18.989 4.18498 18.2954 4.18326 17.892 4.59449L12.0013 10.4852L6.10714 4.59277C5.71066 4.19016 5.00049 4.1754 4.59194 4.59967C4.18511 5.01607 4.19028 5.7088 4.58676 6.10528L10.4809 11.9977L4.58676 17.9014C4.19028 18.2962 4.17725 18.9985 4.59194 19.4071Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DotIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="2" fill="var(--thm-silver-grey)" />
    </svg>
  );
}
