import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouteUnload } from '@/hooks/useRouteUnload';
import { EmirateType } from '@/modules/emirate/types';
import { VrpStickyBar } from '@/modules/showroom/advert/create/StickyBar/VrpStickyBar';
import { VrpStickyMsg } from '@/modules/showroom/advert/create/StickyMsg/VrpStickyMsg';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { AddressWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/AddressWrap';
import { CheckList } from '@/modules/showroom/advert/vrp/UpdateVrp/CheckList';
import { DescriptionWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/DescriptionWrap';
import { PhoneWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/PhoneWrap';
import { PriceWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/PriceWrap';
import { PromotionWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/PromotionWrap';
import { PublishResultWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/PublishResultWrap';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';
import { VrpNumberWrap } from '@/modules/showroom/advert/vrp/UpdateVrp/VrpNumberWrap';
import { EditorContainer } from '@/ui/layouts/EditorLayout/EditorContainer';
import { EditorSections } from '@/ui/layouts/EditorLayout/EditorSections';
import { EditorSectionsWrap } from '@/ui/layouts/EditorLayout/EditorSectionsWrap';
import { isLocalDev } from '@/utils/consts';

type Props = {
  detail: AdvertAuthorDetail;
  emirates: EmirateType[];
};

export function UpdateVrp({ detail, emirates }: Props) {
  const { t } = useTranslation();

  const storeDetail = useUpdateVrpStore((s) => s.detail);
  const handleDetail = useUpdateVrpStore((s) => s.handleDetail);
  useEffect(() => {
    handleDetail(detail);
  }, [handleDetail, detail]);

  const isNumberSaved = useUpdateVrpStore((s) => s.isNumberSaved);
  const isDescriptionFilled = useUpdateVrpStore((s) => s.isDescriptionFilled);
  const isPhoneFilled = useUpdateVrpStore((s) => s.isPhoneFilled);
  const isAddressFilled = useUpdateVrpStore((s) => s.isAddressFilled);
  const isPriceFilled = useUpdateVrpStore((s) => s.isPriceFilled);

  const isRejected = detail?.status.title === AdvertStatusType.rejected;
  const updatesRejectReason = detail?.updates_reject_reason || '';
  const isFullScrollStickyMsg = isRejected || !!updatesRejectReason;

  // Number saving
  const [numberWasSaved, setNumberWasSaved] = useState(isNumberSaved);
  useEffect(() => {
    if (isNumberSaved && !numberWasSaved) {
      setNumberWasSaved(true);
    }

    if (!isNumberSaved && numberWasSaved) {
      setNumberWasSaved(false);
    }
  }, [isNumberSaved, numberWasSaved]);

  const showPromo = useUpdateVrpStore((s) => s.showPromo);

  // Handle page leave
  const showPageLeaveWarn = useUpdateVrpStore((s) => s.showPageLeaveWarn);
  useRouteUnload(isLocalDev ? false : showPageLeaveWarn, t('leaveWarn'));

  if (!storeDetail) return <></>;

  return (
    <>
      <EditorContainer>
        <CheckList numberWasSaved={numberWasSaved} />

        <EditorSectionsWrap>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 36 }}>
            <EditorSections>
              <VrpNumberWrap emirates={emirates} />
            </EditorSections>

            {!isFullScrollStickyMsg && <VrpStickyMsg />}
          </div>

          <EditorSections>
            {numberWasSaved && (
              <>
                <DescriptionWrap />
                {isDescriptionFilled && (
                  <>
                    <AddressWrap />

                    {isAddressFilled && (
                      <>
                        <PhoneWrap />

                        {isPhoneFilled && (
                          <>
                            <PriceWrap />

                            {isPriceFilled && showPromo() && (
                              <>
                                <PromotionWrap />
                                <PublishResultWrap />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </EditorSections>
        </EditorSectionsWrap>

        {isFullScrollStickyMsg && <VrpStickyMsg />}
      </EditorContainer>

      <VrpStickyBar emirates={emirates} />
    </>
  );
}
