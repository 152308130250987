import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDocumentClick } from '@/hooks/useDocumentClick';
import { useAccountStore } from '@/modules/accounts/store';
import { Button } from '@/ui/Button/Button';
import { LOGO_ID } from '@/ui/layouts/BaseLayout/BaseLayout';
import { LottieNoLoop } from '@/ui/LottieNoLoop';

import congratsAnim from './congrats.json';
import cls from './CreateShowroom.module.scss';

export function ShowroomSuccess() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const accountInfo = useAccountStore((s) => s.accountInfo);
  const setAccountInfo = useAccountStore((s) => s.setAccountInfo);

  const onFinalClick = useCallback(() => {
    if (accountInfo) setAccountInfo({ ...accountInfo, showroom_created: true });
    navigate('/');
  }, [accountInfo, navigate, setAccountInfo]);

  const onDocumentClick = useCallback(
    (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      const logoClicked = el?.closest(`#${LOGO_ID}`);
      if (logoClicked) {
        onFinalClick();
      }
    },
    [onFinalClick]
  );
  useDocumentClick(onDocumentClick);

  return (
    <div className={cls.created}>
      <LottieNoLoop animationData={congratsAnim} />
      <h2>{t('showroom.created.title')}</h2>
      <p>{t('showroom.created.text')}</p>
      <Button onClick={onFinalClick} color="blue" fullWidth>
        {t('showroom.created.action')}
      </Button>
    </div>
  );
}
