import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { PrimarySelect } from '@/ui/PrimarySelect/PrimarySelect';

import cls from './OptionsModal.module.scss';

type Props = {
  initialIds: number[];
  options: OptionI[];
  onOptionAdd: (id: number, idsToRemove?: number[]) => void;
  onOptionRemove: (id: number) => void;
  onClear: (ids: number[]) => void;
  name: Nullable<string>;
  multiple?: boolean;
  dropdownTop?: boolean;
};

export const OptionsSelect: React.FC<Props> = ({
  initialIds,
  options,
  onOptionAdd,
  onOptionRemove,
  onClear,
  multiple,
  dropdownTop,
  name
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<OptionI[]>(
    options.filter(({ id }) => initialIds.includes(Number(id)))
  );

  const handleOptionAdd = (opt: OptionI, removeOtherOptions?: boolean) => {
    const selectedIds = removeOtherOptions
      ? selected.map((v) => Number(v.id))
      : [];
    setSelected((prev) => (removeOtherOptions ? [opt] : [...prev, opt]));
    onOptionAdd(Number(opt.id), selectedIds);
  };

  const handleOptionRemove = (opt: OptionI) => {
    setSelected((prev) => prev.filter((v) => v.id !== opt.id));
    onOptionRemove(Number(opt.id));
  };

  const onClearClick = () => {
    const selectedIds = selected.map((v) => Number(v.id));
    setSelected([]);
    onClear(selectedIds);
  };

  return (
    <div className={cls.select}>
      <PrimarySelect
        name="options-select"
        value={selected}
        onOptionAdd={handleOptionAdd}
        onOptionRemove={handleOptionRemove}
        onClear={onClearClick}
        options={options}
        placeholder={name}
        clearText={t('common.any')}
        multiple={multiple}
        dropdownTop={dropdownTop}
        dropdownFullWidth
      />
    </div>
  );
};
