import { Tariffs } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Tariffs/Tariffs';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function TariffsWrap() {
  const tariffs = useRentUpdateAdvertStore((s) => s.tariffs);
  const setTariffs = useRentUpdateAdvertStore((s) => s.setTariffs);
  const isTariffsFilled = useRentUpdateAdvertStore((s) => s.isTariffsFilled);
  const setTariffsFilled = useRentUpdateAdvertStore((s) => s.setTariffsFilled);

  return (
    <Tariffs
      tariffs={tariffs}
      setTariffs={setTariffs}
      isTariffsFilled={isTariffsFilled}
      setTariffsFilled={setTariffsFilled}
    />
  );
}
