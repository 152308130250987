import { ChatAdvert } from '@/modules/chat/types';
import { useAdvertName } from '@/modules/showroom/advert/hooks';

type Props = {
  advert: ChatAdvert;
};

export function ChatAdName({ advert }: Props) {
  const name = useAdvertName(advert);
  return <>{name}</>;
}
