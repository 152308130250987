import { HOURS_IN_DAY } from '@/utils/consts';
import { diffInDays } from '@/utils/date';

const MAX_HOURS = HOURS_IN_DAY * 30;

interface StoredValue<T> {
  value: T;
  createdAt: number;
}

export function saveInStorage<T>(name: string, value: T) {
  if (typeof window === 'undefined') return null;

  const savedValue: StoredValue<T> = { value, createdAt: Date.now() };
  window.localStorage.setItem(name, JSON.stringify(savedValue));
}

export function getFromStorage<T>(name: string, maxHours?: number): T | null {
  if (typeof window === 'undefined') return null;

  const storageValue = window.localStorage.getItem(name);
  if (!storageValue) return null;
  const parsed = JSON.parse(storageValue) as StoredValue<T>;

  const createdDate = new Date(parsed.createdAt);
  const diffDays = diffInDays(new Date(), createdDate);
  const max = maxHours || MAX_HOURS;
  if (diffDays > max) return null;
  return parsed.value;
}

export function removeFromStorage(name: string): void {
  if (typeof window === 'undefined') return;
  window.localStorage.removeItem(name);
}
