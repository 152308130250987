import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowClick } from '@/hooks/useWindowClick';
import { useRentSearchStore } from '@/modules/showroom/advert/rent/my/store';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import cls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { escapePlus, maxLength, onlyNumbers } from '@/utils/format';

export function SeatsFilter() {
  const { t } = useTranslation();

  const [isSeatsOpen, setSeatsOpen] = useState(false);

  const countSeats = useRentSearchStore((s) => s.countSeats);
  const setCountSeats = useRentSearchStore((s) => s.setCountSeats);
  const countSeatsStr = countSeats ? String(countSeats) : '';

  const onResetClick = () => {
    setSeatsOpen(false);
    setCountSeats(null);
  };

  useWindowClick((e) => {
    const el = e.target as HTMLElement | null;
    const parentEl = el?.closest(`.${escapePlus(cls.li_input)}`);

    if (!parentEl) {
      setSeatsOpen(false);
    }
  });

  return (
    <>
      <div>
        <FilterBtn
          text={t('countSeats')}
          onClick={() => setSeatsOpen(true)}
          active={isSeatsOpen}
          showSearch={isSeatsOpen}
          onResetClick={onResetClick}
          value={countSeatsStr}
          searchValue={countSeatsStr}
          onSearchChange={(v) =>
            setCountSeats(v ? Number(onlyNumbers(maxLength(v, 2))) : null)
          }
          searchPlaceholder={t('countSeats')}
        />
      </div>
    </>
  );
}
