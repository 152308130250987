export function DeleteIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05173 14.7583C8.33937 14.7583 8.52476 14.5761 8.51793 14.3077L8.30879 7.15154C8.30074 6.8856 8.11289 6.70895 7.83768 6.70895C7.5525 6.70895 7.36834 6.8912 7.37516 7.15836L7.57871 14.3114C7.58677 14.5829 7.77584 14.7583 8.05173 14.7583ZM10.0624 14.7583C10.3451 14.7583 10.5441 14.5785 10.5441 14.3126V7.1559C10.5441 6.88997 10.3451 6.70895 10.0624 6.70895C9.77841 6.70895 9.58497 6.88997 9.58497 7.1559V14.3126C9.58497 14.5785 9.77841 14.7583 10.0624 14.7583ZM12.0773 14.7583C12.3488 14.7583 12.5367 14.5829 12.5447 14.3114L12.7483 7.15836C12.7551 6.8912 12.5709 6.70895 12.2858 6.70895C12.0105 6.70895 11.8227 6.8856 11.8147 7.15713L11.6111 14.3077C11.6043 14.5761 11.7897 14.7583 12.0773 14.7583ZM6.96139 4.80702H8.22115V3.3893C8.22115 3.03586 8.46838 2.80911 8.84844 2.80911H11.2638C11.6439 2.80911 11.8911 3.03586 11.8911 3.3893V4.80702H13.1509V3.32596C13.1509 2.28216 12.4847 1.66675 11.3536 1.66675H8.75862C7.62759 1.66675 6.96139 2.28216 6.96139 3.32596V4.80702ZM3.93742 5.54966H16.1916C16.528 5.54966 16.7901 5.2825 16.7901 4.94613C16.7901 4.61221 16.528 4.35065 16.1916 4.35065H3.93742C3.60665 4.35065 3.33334 4.61221 3.33334 4.94613C3.33334 5.28809 3.60665 5.54966 3.93742 5.54966ZM6.92753 17.0693H13.2015C14.2464 17.0693 14.925 16.4296 14.974 15.3834L15.4433 5.41874H14.1772L13.7285 15.1802C13.7148 15.5897 13.4446 15.8617 13.0554 15.8617H7.06241C6.68439 15.8617 6.41422 15.5829 6.39497 15.1802L5.92386 5.41874H4.68021L5.15501 15.389C5.20525 16.4352 5.87145 17.0693 6.92753 17.0693Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PinIcon() {
  return (
    <svg
      width="35"
      height="43"
      viewBox="0 0 35 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7453 33.8904C20.9871 34.0761 20.2562 34.375 19.6278 34.8382L19.1555 35.1863C18.8341 35.4233 18.5724 35.732 18.3913 36.088C18.0206 36.8167 16.9794 36.8167 16.6087 36.088C16.4276 35.732 16.1659 35.4233 15.8445 35.1863L15.3722 34.8382C14.7438 34.375 14.0129 34.0761 13.2547 33.8904C5.6411 32.0257 0 25.2603 0 17.2C0 7.7007 7.83502 0 17.5 0C27.165 0 35 7.7007 35 17.2C35 25.2603 29.3589 32.0257 21.7453 33.8904ZM17.5 43C18.4665 43 19.25 42.2299 19.25 41.28C19.25 40.3301 18.4665 39.56 17.5 39.56C16.5335 39.56 15.75 40.3301 15.75 41.28C15.75 42.2299 16.5335 43 17.5 43Z"
        fill="#E60000"
      />
      <path
        d="M17.8402 25.8V18.6166C19.9499 18.455 21.5834 16.7746 21.5834 14.7711C21.5834 12.6382 19.746 10.8933 17.5001 10.8933C15.2542 10.8933 13.4167 12.6382 13.4167 14.7711C13.4167 16.807 15.05 18.455 17.16 18.6166L17.1595 25.8H17.8402ZM15.458 15.0943H14.7775C14.7775 13.4785 16.1385 12.186 17.8399 12.186V12.8323C16.5131 12.8321 15.458 13.834 15.458 15.0943Z"
        fill="white"
      />
    </svg>
  );
}
