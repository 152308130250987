import { RegSpecs } from '@/modules/showroom/advert/create/RegSpecs/RegSpecs';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function RegSpecsWrap() {
  const regSpecs = useUpdateAdvertStore((s) => s.regSpecs);
  const setRegSpecs = useUpdateAdvertStore((s) => s.setRegSpecs);
  const isRegSpecsFilled = useUpdateAdvertStore((s) => s.isRegSpecsFilled);
  const setRegSpecsFilled = useUpdateAdvertStore((s) => s.setRegSpecsFilled);

  return (
    <RegSpecs
      regSpecs={regSpecs}
      setRegSpecs={setRegSpecs}
      isRegSpecsFilled={isRegSpecsFilled}
      setRegSpecsFilled={setRegSpecsFilled}
    />
  );
}
