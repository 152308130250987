import { create } from 'zustand';

import { Nullable } from '@/app/types';

interface LayoutHeaderStore {
  title: Nullable<string>;
  buttonText?: Nullable<string>;
  onButtonClick?: () => void;
  setHeader: (
    title: LayoutHeaderStore['title'],
    buttonText?: LayoutHeaderStore['buttonText'],
    onButtonClick?: LayoutHeaderStore['onButtonClick']
  ) => void;
  resetHeader: () => void;
}

export const useLayoutHeaderStore = create<LayoutHeaderStore>((set) => ({
  title: null,
  buttonText: null,
  onButtonClick: undefined,
  setHeader: (title, buttonText, onButtonClick) => {
    set({ title, buttonText, onButtonClick });
  },
  resetHeader: () => {
    set({ title: null, buttonText: null, onButtonClick: undefined });
  }
}));
