import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { GetEmojisReq, GetEmojisRes } from '@/modules/emojis/types';
import { showAlert } from '@/utils/network';

export function getStickers(params?: GetEmojisReq) {
  return axios.get<GetEmojisRes>('/emojis/list', { params });
}

export function useStickers(
  params?: GetEmojisReq
): [GetEmojisRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetEmojisRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getStickers(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useStickersPrice(): [number | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<number>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getStickers({ limit: 1 });
      if (r.data[0]) setData(r.data[0].price);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
