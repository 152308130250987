import cn from 'classnames';
import { useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { MyAdvertCardContext } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/ctx';
import {
  ClickIcon,
  EyeIcon,
  HeartIcon,
  LeadIcon,
  StatsIcon
} from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/icons';
import { Statistics } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Statistics/Statistics';
import { Actions } from '@/modules/showroom/advert/rent/my/MyRentAdvertsList/MyRentAdvertCard/Actions';
import { Features } from '@/modules/showroom/advert/rent/my/MyRentAdvertsList/MyRentAdvertCard/Features/Features';
import { Tariffs } from '@/modules/showroom/advert/rent/my/MyRentAdvertsList/MyRentAdvertCard/Tariffs/Tariffs';
import { RentAdvert } from '@/modules/showroom/advert/rent/my/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { APP_URL, emptyFn } from '@/utils/consts';
import { getDateString, isThisYear } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';

import cls from './MyRentAdvertCard.module.scss';

type Props = {
  advert: RentAdvert;
  onPublishToggle?: () => void;
};

export function MyRentAdvertCard({ advert, onPublishToggle }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { engagement } = advert;

  const imgPlaceholder = useImgPlaceholder();
  const imgSrc = useMemo(() => {
    if (advert.photos && advert.photos[0]) {
      return advert.photos[0].thumbnail_url || advert.photos[0].photo_url;
    }
    return imgPlaceholder;
  }, [advert.photos, imgPlaceholder]);
  const name = useAdvertName(advert);

  const statusTitle = advert.status.title;
  const isDraft = statusTitle === AdvertStatusType.draft;
  const isPublished = statusTitle === AdvertStatusType.published;
  const isModeration = statusTitle === AdvertStatusType.moderation;
  const isRejected = statusTitle === AdvertStatusType.rejected;
  const isSold = statusTitle === AdvertStatusType.sold;
  const showStatus = !isPublished;

  const advertUrl = advert.url ? `${APP_URL}/${advert.url}` : '';
  const editUrl = `/cars/rent/edit/${advert.id}`;

  const mod = advert.modification;

  const publishedAtStr = useMemo(() => {
    if (!advert.published_at) return '';
    const date = new Date(advert.published_at * 1000);

    const thisYear = isThisYear(date);
    const dateStr = getDateString(date, lang, {
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      year: thisYear ? undefined : 'numeric'
    });
    return t('advertisements.my.publishedAt', { date: dateStr });
  }, [advert.published_at, lang, t]);

  const updatedAtStr = useMemo(() => {
    if (!advert.updated_at_by_request_update) return '';
    const date = new Date(advert.updated_at_by_request_update * 1000);

    const thisYear = isThisYear(date);
    const dateStr = getDateString(date, lang, {
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      year: thisYear ? undefined : 'numeric'
    });
    return t('advertisements.my.updatedAt', { date: dateStr });
  }, [advert.updated_at_by_request_update, lang, t]);

  // Statistics
  const [statsOpened, setStatsOpened] = useState(false);
  const statsOnceOpened = useOnceTrue(statsOpened);

  // Hover
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={cn(cls.root, { [cls.root_gap]: statsOpened })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <MyAdvertCardContext.Provider
        value={{ isPublished, onEditPriceClick: emptyFn }}
      >
        <div className={cls.advert}>
          {isPublished && advertUrl ? (
            <a
              className={cls.link}
              href={advertUrl}
              target="_blank"
              rel="noreferrer"
            />
          ) : (
            <Link className={cls.link} to={editUrl} />
          )}

          <div className={cls.img}>
            <ImgSkeleton
              className={cls.img_inner}
              src={imgSrc}
              alt=""
              imgProxyWidth={232}
              imgProxyHeight={174}
            />
            {isDraft && <Link className={cls.link} to={editUrl} />}
            {showStatus && (
              <span
                className={cn(cls.status, {
                  [cls.status_green]: isSold,
                  [cls.status_orange]: isModeration
                })}
              >
                {advert.status.name}
              </span>
            )}
          </div>

          <div className={cls.info}>
            <p className={cls.name_wrap}>
              <b className={cls.name}>{name}</b>
              {isPublished && advertUrl ? (
                <a
                  className={cls.link}
                  href={advertUrl}
                  target="_blank"
                  rel="noreferrer"
                />
              ) : (
                <Link className={cls.link} to={editUrl} />
              )}
            </p>

            {(advert.body || mod) && (
              <p className={cls.specs}>
                {advert.body?.name}
                <span>{t('common.dot')}</span>
                {t('common.commaSeparated', {
                  first: t('volume', { value: mod?.volume?.short_name }),
                  second: mod?.engine?.name
                })}
                <span>{t('common.dot')}</span>
                {mod?.drive_unit?.name}
              </p>
            )}

            <Features advert={advert} hovered={hovered} />
          </div>

          {advert.tariffs && (
            <div className={cls.tariffs}>
              <Tariffs tariffs={advert.tariffs} hovered={hovered} />
            </div>
          )}

          <div className={cls.right_col}>
            <ul
              className={cls.stats}
              style={{
                visibility: isPublished || isSold ? 'visible' : 'hidden'
              }}
            >
              <li>
                <div className={cls.stats_row}>
                  <EyeIcon />
                  <span>{numberWithSpaces(engagement.count_shows, lang)}</span>
                </div>
              </li>
              <li>
                <div className={cls.stats_row}>
                  <ClickIcon />
                  <span>{numberWithSpaces(engagement.count_views, lang)}</span>
                </div>
              </li>
              <li>
                <div className={cls.stats_row}>
                  <LeadIcon />
                  <span>{numberWithSpaces(engagement.count_leads, lang)}</span>
                </div>
              </li>
              <li>
                <div className={cls.stats_row}>
                  <HeartIcon />
                  <span>
                    {numberWithSpaces(engagement.count_add_to_favorites, lang)}
                  </span>
                </div>
              </li>
              {!isModeration && !isRejected && !isDraft && (
                <li>
                  <button
                    className={cls.stats_row}
                    type="button"
                    onClick={() => setStatsOpened((prev) => !prev)}
                  >
                    <StatsIcon />
                    <span>
                      {t(statsOpened ? 'common.hide' : 'common.detailed')}
                    </span>
                  </button>
                </li>
              )}
            </ul>

            <div className={cls.actions}>
              <Actions advert={advert} onPublishToggle={onPublishToggle} />
            </div>
          </div>

          {(publishedAtStr || updatedAtStr) && (
            <p className={cls.dates}>
              {publishedAtStr && (
                <>
                  {publishedAtStr} {t('common.dot')}
                </>
              )}{' '}
              {updatedAtStr}
            </p>
          )}
        </div>

        {(isPublished || isSold) && statsOnceOpened && (
          <Collapse isOpened={statsOpened}>
            <Statistics advertId={advert.id} rent />
          </Collapse>
        )}
      </MyAdvertCardContext.Provider>
    </div>
  );
}
