import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';

import { APP_URL } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import { SnapshotReq, SnapshotRes } from './types';

const cachedUrls: Record<string, string> = {};

function getSnapshotUrl(
  params?: SnapshotReq
): Promise<AxiosResponse<SnapshotRes>> {
  return axios.get<SnapshotRes>(`${APP_URL}/app/api/snapshot/`, { params });
}

export function useSnapshotUrl(params?: SnapshotReq): [string, boolean] {
  const [isLoading, setLoading] = useState(false);
  const [url, setUrl] = useState('');

  const load = useCallback(async (p?: SnapshotReq) => {
    setLoading(true);

    try {
      const { data } = await getSnapshotUrl(p);
      setUrl(data.url);
      const queryStr = qs.stringify(p);
      cachedUrls[queryStr] = data.url;
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleParams = useCallback(() => {
    const queryStr = qs.stringify(params);
    if (queryStr in cachedUrls && cachedUrls[queryStr]) {
      setUrl(cachedUrls[queryStr]);
    } else {
      load(params);
    }
  }, [params, load]);

  useEffect(() => {
    handleParams();
  }, [handleParams]);

  return [url, isLoading];
}
