export function ArrowIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3125 11.9836C3.3125 12.2878 3.44867 12.5908 3.6807 12.8133L9.69172 18.8126C9.9343 19.0455 10.213 19.1604 10.4949 19.1604C11.1265 19.1604 11.5862 18.702 11.5862 18.086C11.5862 17.7745 11.4683 17.4968 11.2587 17.299L9.55296 15.5515L5.89365 12.2463L5.53928 12.8894L8.86529 13.1079H19.5523C20.2255 13.1079 20.6881 12.644 20.6881 11.9836C20.6881 11.3231 20.2255 10.8593 19.5523 10.8593H8.86529L5.53928 11.0777L5.89365 11.7305L9.55296 8.41569L11.2587 6.66608C11.4683 6.46827 11.5862 6.19054 11.5862 5.87906C11.5862 5.26312 11.1265 4.80469 10.4949 4.80469C10.213 4.80469 9.9343 4.91953 9.69172 5.1525L3.6807 11.1539C3.44867 11.3763 3.3125 11.6794 3.3125 11.9836Z"
        fill="currentColor"
      />
    </svg>
  );
}
