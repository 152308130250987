import { create } from 'zustand';

import { axios } from '@/app/api';
import { CURRENCY_API_ROUTES } from '@/modules/currency/api';
import {
  getFromStorage,
  saveInStorage,
  removeFromStorage
} from '@/utils/local-storage';
import { showAlert } from '@/utils/network';

const MAX_STORAGE_HOURS = 1;
const CURRENCY_STORE_NAME = 'autoae-currency-rates';

const CURRENCIES = {
  euro: 'eur',
  usd: 'usd',
  aed: 'aed'
};
type CurrencyStoreValue = Record<string, number>;

export function makeKey(base: string, target: string): string {
  return `${base}-${target}`;
}

interface CurrencyStore {
  rates: CurrencyStoreValue;
  fetchRates: () => void;
  reset: () => void;
}

export const useCurrencyStore = create<CurrencyStore>((set, get) => ({
  rates: getFromStorage(CURRENCY_STORE_NAME, MAX_STORAGE_HOURS) || {},
  fetchRates: async () => {
    const current = get().rates;
    if (Object.keys(current).length > 0) return;

    try {
      // Euro
      const euroRes = await axios.post(
        CURRENCY_API_ROUTES.getCurrencyRateFiat,
        null,
        {
          params: { base: CURRENCIES.euro, target: CURRENCIES.aed }
        }
      );
      const euroValue = {
        [makeKey(CURRENCIES.euro, CURRENCIES.aed)]: euroRes.data.rate
      };

      // USD
      const usdRes = await axios.post(
        CURRENCY_API_ROUTES.getCurrencyRateFiat,
        null,
        {
          params: { base: CURRENCIES.usd, target: CURRENCIES.aed }
        }
      );
      const usdValue = {
        [makeKey(CURRENCIES.usd, CURRENCIES.aed)]: usdRes.data.rate
      };

      const merged = { ...current, ...euroValue, ...usdValue };
      set({ rates: merged });
      saveInStorage(CURRENCY_STORE_NAME, merged);
    } catch (error) {
      showAlert({ error });
    }
  },
  reset: () => {
    set({ rates: {} });
    removeFromStorage(CURRENCY_STORE_NAME);
  }
}));
