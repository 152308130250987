import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { useVrpTypes } from '@/modules/showroom/advert/vrp/api';
import { GetVrpTypesReq } from '@/modules/showroom/advert/vrp/types';
import { TransportType } from '@/modules/transport/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';

import cls from '../Filters.module.scss';

export function PlateTypeFilter() {
  const { t } = useTranslation();

  const [isPlateTypeOpen, setPlateTypeOpen] = useState(false);
  const onceOpened = useOnceTrue(isPlateTypeOpen);

  const emirateId = useSearchStore((s) => s.vrpPlateEmirateId);
  const typeId = useSearchStore((s) => s.vrpTypeId);
  const setTypeId = useSearchStore((s) => s.setVrpTypeId);

  const typesReq = useMemo<Nullable<GetVrpTypesReq>>(() => {
    if (!onceOpened && !typeId) return null;
    return { emirate_id: emirateId };
  }, [onceOpened, emirateId, typeId]);
  const [allTypes, typesLoading] = useVrpTypes(typesReq);
  const type = allTypes?.find((tp) => tp.id === typeId);

  const onTypeChange = (v: FilterType) => {
    const tp = v as TransportType;
    setTypeId(tp.id);
    setPlateTypeOpen(false);
  };

  const onTypeReset = () => {
    setPlateTypeOpen(false);
    setTypeId(null);
  };

  return (
    <>
      <FilterBtn
        text={t('type')}
        onClick={() => setPlateTypeOpen(true)}
        active={isPlateTypeOpen}
        onResetClick={onTypeReset}
        value={type?.name}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-plate-type"
        isOpen={isPlateTypeOpen}
        close={() => setPlateTypeOpen(false)}
        parentCls={cls.li}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={typeId}
            list={allTypes || []}
            loading={typesLoading}
            onClick={onTypeChange}
            search=""
            allButtonText={t('common.any')}
            onAllClick={onTypeReset}
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
}
