import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_EMAILS_LEN } from '@/modules/notifications/NotificationsForm/helpers';
import {
  AddIcon,
  ArrowRightIcon,
  TrashIcon
} from '@/modules/notifications/NotificationsForm/icons';
import { NoticationsFormEmail } from '@/modules/notifications/types';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { maxLength } from '@/utils/format';
import { MAX_EMAIL_LENGTH } from '@/utils/validate';

import cls from './NotificationsForm.module.scss';

type Props = {
  title: string;
  emails: NoticationsFormEmail[];
  onChange: (email: NoticationsFormEmail) => void;
  onDelete: (id: NoticationsFormEmail['id']) => void;
  onAddClick: () => void;
  disabled?: boolean;
};

export const NotificationsFormGroup = ({
  title,
  emails,
  onChange,
  onDelete,
  onAddClick,
  disabled
}: Props) => {
  const { t } = useTranslation();

  const canAdd = useMemo(() => {
    if (emails.length >= MAX_EMAILS_LEN) return false;
    return emails.every((e) => !!e.email);
  }, [emails]);

  return (
    <li className={cls.group}>
      <div className={cls.group__top}>
        <h3>{title}</h3>
        <ArrowRightIcon />
      </div>

      <ul className={cls.groupList}>
        {emails.map((email, index) => (
          <li key={email.id}>
            <Input
              value={email.email}
              label={t('auth.email')}
              maxLength={MAX_EMAIL_LENGTH}
              onChange={(e) =>
                onChange({
                  ...email,
                  email: maxLength(e.currentTarget.value, MAX_EMAIL_LENGTH)
                })
              }
              disabled={disabled}
            />

            {index > 0 && (
              <Button
                variant="secondary"
                size="l"
                color="black"
                type="button"
                onClick={() => onDelete(email.id)}
                disabled={disabled}
              >
                <TrashIcon />
              </Button>
            )}
          </li>
        ))}
      </ul>

      {canAdd && (
        <div className={cls.add_email}>
          <Button
            variant="outline"
            color="black"
            size="compact"
            gap={8}
            onClick={onAddClick}
          >
            <AddIcon />
            {t('credits.addEmail')}
          </Button>
        </div>
      )}
    </li>
  );
};
