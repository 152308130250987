import { Nullable } from '@/app/types';
import { Theme } from '@/modules/theme/types';
import { getCookie } from '@/utils/cookie';

export const THEME_NAME = 'auto.ae-dealersapp-theme';
export const getFromCookie = (): Nullable<Theme> => {
  const cookieValue = getCookie(THEME_NAME);
  if (!cookieValue) return null;

  if (
    typeof cookieValue === 'string' &&
    Object.values(Theme).includes(cookieValue as Theme)
  ) {
    return cookieValue as Theme;
  }

  return null;
};
