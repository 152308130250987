import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { GetEmiratesReq, GetEmiratesRes } from '@/modules/emirate/types';
import { showAlert } from '@/utils/network';

function getEmirates(params?: GetEmiratesReq) {
  return axios.get('/emirates/list', {
    params
  });
}

export function useEmirates(
  params?: Nullable<GetEmiratesReq>
): [GetEmiratesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetEmiratesRes>();

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await getEmirates(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
