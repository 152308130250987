import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Nullable } from '@/app/types';
import { useAuthStore } from '@/modules/auth/store';
import { AuthState, AuthType } from '@/modules/auth/types';

export function useAuthed(): [boolean, AuthState, Nullable<AuthType>] {
  const auth = useAuthStore((state) => state.auth);
  const authState = useAuthStore((state) => state.authState);
  return [authState === AuthState.authed, authState, auth];
}

// Signout
interface SignoutData {
  isConfirmOpen: boolean;
  setConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmClick: (confirmed: boolean) => void;
}

export function useSignout(): SignoutData {
  const navigate = useNavigate();
  const signout = useAuthStore((s) => s.signout);

  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);

  const logout = () => {
    signout().finally(() => {
      navigate('/signin');
    });
  };

  const onConfirmClick = (confirmed: boolean) => {
    if (confirmed) {
      logout();
    }
    setConfirmOpen(false);
  };

  return { isConfirmOpen, setConfirmOpen, onConfirmClick };
}
