import { useCallback, useRef, useState } from 'react';

import { Nullable } from '@/app/types';
import { useRefState } from '@/hooks/useRefState';
import { getLeads } from '@/modules/leads/api';
import { GetLeadsQueryReq, GetLeadsReq, Lead } from '@/modules/leads/types';
import { showAlert } from '@/utils/network';

const LOAD_COUNT = 20;

type UseLeadsPg = {
  isAllLoaded: boolean;
  isLoading: boolean;
  data: Lead[];
  load: (skip: number) => void;
};

export function useLeadsPg(
  queryReq: Omit<GetLeadsQueryReq, 'skip' | 'limit'>,
  req: GetLeadsReq
): UseLeadsPg {
  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoading, setLoading, isLoadingRef] = useRefState<boolean>(false);
  const [data, setData] = useState<Lead[]>([]);

  const abortController = useRef<Nullable<AbortController>>(null);

  const load = useCallback(
    async (skip: number) => {
      if (isLoadingRef.current) return;

      setLoading(true);
      if (!skip) setData([]);

      try {
        const query: GetLeadsQueryReq = {
          ...queryReq,
          skip,
          limit: LOAD_COUNT
        };

        if (abortController.current) abortController.current.abort();
        const ab = new window.AbortController();
        abortController.current = ab;

        const r = await getLeads(query, req);
        const loaded = r.data.leads;
        const len = loaded.length;

        setData((prev) => (skip === 0 ? loaded : [...prev, ...loaded]));
        setAllLoaded(len < LOAD_COUNT);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    [isLoadingRef, queryReq, req, setLoading]
  );

  return { isAllLoaded, isLoading, data, load };
}
