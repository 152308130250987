import { sanitize } from 'isomorphic-dompurify';

import {
  ChatMessageContent,
  ChatMessageContentType
} from '@/modules/chat/types';

export const MAX_EDIT_TIME_MIN = 60;

export function isImageContent(type: ChatMessageContentType): boolean {
  return type === ChatMessageContentType.image;
}

export function isDocContent(type: ChatMessageContentType): boolean {
  return type === ChatMessageContentType.file;
}

export function isVideoContent(type: ChatMessageContentType): boolean {
  return type === ChatMessageContentType.video;
}

export function isLocationContent(type: ChatMessageContentType): boolean {
  return type === ChatMessageContentType.location;
}

export function replaceURLs(msg: string, removeLinks?: boolean): string {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  return msg.replace(urlRegex, (url) => {
    if (removeLinks) return url;

    let hyperlink = url;

    if (!hyperlink.match('^https?://')) {
      hyperlink = `http://${hyperlink}`;
    }

    return `<a target="_blank" href="${hyperlink}" rel="noopener noreferrer">${url}</a>`;
  });
}

function handleBold(text: string, noTag?: boolean): string {
  const replacer = noTag ? '$1' : '<b>$1</b>';
  return text.replace(/\*{2}(.*?)\*{2}/g, replacer);
}

export function handleText(
  text: string,
  removeLinks?: boolean,
  showBold?: boolean
): string {
  const replaced = replaceURLs(text, removeLinks);
  return sanitize(handleBold(replaced, !showBold), { ADD_ATTR: ['target'] });
}

export function getDocName(
  file: Pick<ChatMessageContent, 'name' | 'url'>
): string {
  if (file.name) return file.name;

  if (file.url) {
    const split = file.url.split('/');
    return split[split.length - 1];
  }

  return '';
}
