import { CallbackList } from '@/modules/callback/CallbackPage/CallbackListGroup/CallbackList/CallbackList';
import { CallbackItem } from '@/modules/callback/types';

import cls from './CallbackListGroup.module.scss';
type Props = {
  list: CallbackItem[];
};

export function CallbackListGroup({ list }: Props) {
  return (
    <ul className={cls.root}>
      <CallbackList list={list} />
    </ul>
  );
}
