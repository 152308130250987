import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { useRentSearchStore } from '@/modules/showroom/advert/rent/my/store';
import { EMPTY_BRAND_ID } from '@/modules/showroom/advert/search/consts';
import { smartAutoFiltersIds } from '@/modules/showroom/advert/search/helpers';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import cls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useTransportTransmissions } from '@/modules/transport/api';
import {
  GetTransportTransmissionsReq,
  TransportTransmission
} from '@/modules/transport/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { MAX_PG_LIMIT } from '@/utils/consts';

export function TransmissionFilter() {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const onceOpened = useOnceTrue(isOpen);

  const autoFilters = useRentSearchStore((s) => s.autoFilters);
  const transmissionsIds = useRentSearchStore((s) => s.transmissionsIds);
  const setTransmissionsIds = useRentSearchStore((s) => s.setTransmissionsIds);

  const transmissionsReq = useMemo<
    Nullable<GetTransportTransmissionsReq>
  >(() => {
    if (!onceOpened) return null;

    const filters = autoFilters.filter((f) => f.brand_id !== EMPTY_BRAND_ID);
    const idsReq = smartAutoFiltersIds(filters);
    return { limit: MAX_PG_LIMIT, ...idsReq };
  }, [autoFilters, onceOpened]);

  const [allTransmissions, transmissionsLoading] =
    useTransportTransmissions(transmissionsReq);
  const selectedTransmissions = allTransmissions?.filter((b) =>
    transmissionsIds?.includes(b.id)
  );

  const onTransmissionsChange = (v: FilterType) => {
    const b = v as TransportTransmission;
    if (transmissionsIds && transmissionsIds.includes(b.id)) {
      setTransmissionsIds(transmissionsIds.filter((bodyId) => bodyId !== b.id));
    } else {
      setTransmissionsIds(
        transmissionsIds ? [...transmissionsIds, b.id] : [b.id]
      );
    }
  };

  const onTransmissionsReset = () => {
    setOpen(false);
    setTransmissionsIds(null);
  };

  return (
    <>
      <FilterBtn
        text={t('transmissionShort')}
        onClick={() => setOpen(true)}
        active={isOpen}
        onResetClick={onTransmissionsReset}
        value={selectedTransmissions
          ?.map((b) => b.name)
          .join(t('common.listSeparator') as string)}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-transmission"
        isOpen={isOpen}
        close={() => setOpen(false)}
        parentCls={cls.li}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={transmissionsIds}
            list={allTransmissions || []}
            loading={transmissionsLoading}
            onClick={onTransmissionsChange}
            search=""
            allButtonText={t('common.anyN')}
            onAllClick={onTransmissionsReset}
            selectStyle
            multiple
          />
        </li>
      </Dropdown>
    </>
  );
}
