import { useTranslation } from 'react-i18next';

import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { useRentPayMethods } from '@/modules/showroom/advert/rent/pay-methods/hooks';
import { RentPayMethod } from '@/modules/showroom/advert/rent/pay-methods/types';
import { isPayMethodsValid } from '@/modules/showroom/advert/rent/update/helpers';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { Button } from '@/ui/Button/Button';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';

import cls from './PayMethods.module.scss';

type Props = {
  payMethodsIds: RentUpdateAdvertStore['payMethodsIds'];
  setPayMethodsIds: RentUpdateAdvertStore['setPayMethodsIds'];
  isPayMethodIdsFilled: RentUpdateAdvertStore['isPayMethodIdsFilled'];
  setPayMethodIdsFilled: RentUpdateAdvertStore['setPayMethodIdsFilled'];
};

export function PayMethods({
  payMethodsIds,
  setPayMethodsIds,
  isPayMethodIdsFilled,
  setPayMethodIdsFilled
}: Props) {
  const { t } = useTranslation();

  const payMethods = useRentPayMethods();

  const isValid = isPayMethodsValid(payMethodsIds);
  const onMethodClick = (p: RentPayMethod) => {
    return () => {
      const includes = payMethodsIds.includes(p.id);

      setPayMethodsIds(
        includes
          ? payMethodsIds.filter((v) => v !== p.id)
          : [...payMethodsIds, p.id]
      );
    };
  };
  const onContinueClick = () => {
    setPayMethodIdsFilled(true);
    scrollToSection(SCROLLTO.docs, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.payMethods}>
      <div className="box">
        <h1 className={cls.title}>{t('payMethods.title')}</h1>
        <p className={cls.subtitle}>{t('payMethods.subtitle')}</p>

        <ul className={cls.list}>
          {payMethods.map((p) => {
            const includes = payMethodsIds.includes(p.id);
            const disabled =
              isPayMethodIdsFilled && includes && payMethodsIds.length === 1;

            return (
              <li key={p.id}>
                <CheckBtn
                  active={includes}
                  onClick={onMethodClick(p)}
                  disabled={disabled}
                >
                  {p.name}
                </CheckBtn>
              </li>
            );
          })}
        </ul>

        <div className={cls.buttons}>
          {!isPayMethodIdsFilled && isValid && (
            <Button
              onClick={onContinueClick}
              color="blue"
              variant="primary"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
