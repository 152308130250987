import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Actions } from '@/modules/chat/Detail/Header/Actions/Actions';
import { APP_URL } from '@/utils/consts';

import cls from './Buttons.module.scss';
import { SearchIcon, MoreIcon, ProfileIcon, ReviewIcon } from './icons';

type Props = {
  chatId: number;
  userId?: number;
  username?: string;
  setChatOpen: (v: boolean) => void;
  onSearchClick: () => void;
};

export const Buttons: React.FC<Props> = ({
  chatId,
  userId,
  username,
  setChatOpen,
  onSearchClick
}) => {
  const { t } = useTranslation();
  const [isMoreOpen, setMoreOpen] = useState(false);

  const profileUrl = username
    ? `${APP_URL}/${username}`
    : `${APP_URL}/profile/${userId}`;
  const onProfileClick = () => {
    setChatOpen(false);
  };

  return (
    <div className={cls.root}>
      <ul className={cls.list}>
        <li>
          <a
            className={cls.btn}
            onClick={onProfileClick}
            href={profileUrl}
            target="_blank"
            rel="noreferrer"
          >
            <ProfileIcon />
            <span>{t('profile.title')}</span>
          </a>
        </li>

        <li>
          <button className={cls.btn} type="button" onClick={onSearchClick}>
            <SearchIcon />
            <span>{t('common.search')}</span>
          </button>
        </li>

        <li>
          <a
            className={cls.btn}
            href={`${profileUrl}?reviews=true`}
            target="_blank"
            rel="noreferrer"
          >
            <ReviewIcon />
            <span>{t('profile.reviews.title')}</span>
          </a>
        </li>

        <li className={cls.li_more}>
          <button
            className={cn(cls.btn, cls.btn_more)}
            type="button"
            onClick={() => setMoreOpen(!isMoreOpen)}
          >
            <MoreIcon />
            <span>{t('common.more')}</span>
          </button>

          <Actions
            parentCls={cls.li_more}
            chatId={chatId}
            isOpen={isMoreOpen}
            setOpen={setMoreOpen}
            hideSearch
          />
        </li>
      </ul>
    </div>
  );
};
