import { ChatMessageContent } from '@/modules/chat/types';

export function prepareContentForSend(
  content: ChatMessageContent[]
): ChatMessageContent[] {
  return content.map((c) => {
    if (!c.extension) return c;
    const extension = c.extension.includes('/')
      ? c.extension.split('/')[1]
      : c.extension;
    return { ...c, extension };
  });
}
