import { axios } from '@/app/api';
import {
  GetLeadsQueryReq,
  GetLeadsReq,
  GetLeadsRes,
  MarkLeadsAsViewedReq
} from '@/modules/leads/types';

const PATH = '/dealers/accounts/leads';

export const LEADS_API_ROUTES = {
  markLeadsAsViewed: `${PATH}/mark-viewed`,
  getLeads: PATH
};

export function getLeads(queryReq: GetLeadsQueryReq, req: GetLeadsReq) {
  return axios.post<GetLeadsRes>(LEADS_API_ROUTES.getLeads, req, {
    params: queryReq
  });
}

export function markLeadsAsViewed(req: MarkLeadsAsViewedReq) {
  return axios.patch<unknown>(LEADS_API_ROUTES.markLeadsAsViewed, req);
}

export function markAllLeadsAsViewed() {
  return axios.patch<unknown>(LEADS_API_ROUTES.markLeadsAsViewed, null, {
    params: { mark_all: true }
  });
}
