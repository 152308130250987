import { useEffect } from 'react';

import { useLang } from '@/hooks/useLang';
import { useRentDocsStore } from '@/modules/showroom/advert/rent/docs/store';
import { RentDocsCategory } from '@/modules/showroom/advert/rent/docs/types';

export function useRentDocs(): RentDocsCategory[] {
  const [lang] = useLang();

  const data = useRentDocsStore((s) => s.data);
  const list = data[lang];

  const fetchDocs = useRentDocsStore((s) => s.fetchData);
  useEffect(() => {
    fetchDocs();
  }, [fetchDocs]);

  return list;
}
