import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { Theme } from '@/modules/theme/types';
import { setCookie } from '@/utils/cookie';

import { getFromCookie, THEME_NAME } from './helpers';

const body = document.querySelector('body') as HTMLBodyElement;
const applyDarkValue = (dark: boolean) => {
  body.classList[dark ? 'add' : 'remove']('theme-dark');
};

interface ThemeStore {
  theme: Theme;
  isDark: boolean;
  setDark: () => void;
  setLight: () => void;
  toggleDark: () => void;
  setSystem: () => void;
}

const defaultTheme = Theme.light;

export const useThemeStore = create(
  subscribeWithSelector<ThemeStore>((set, get) => ({
    theme: getFromCookie() || defaultTheme,
    isDark: (() => {
      const theme = getFromCookie() || defaultTheme;
      return theme === Theme.system
        ? window.matchMedia('(prefers-color-scheme: dark)').matches
        : theme === Theme.dark;
    })(),
    setDark: () => {
      set({ isDark: true, theme: Theme.dark });
      setCookie(THEME_NAME, Theme.dark);
    },
    setLight: () => {
      set({ isDark: false, theme: Theme.light });
      setCookie(THEME_NAME, Theme.light);
    },
    setSystem: () => {
      const isSystemDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      set({ isDark: isSystemDark, theme: Theme.system });
      setCookie(THEME_NAME, Theme.system);
    },
    toggleDark: () => {
      const isDark = !get().isDark;
      const newTheme = isDark ? Theme.dark : Theme.light;
      set({ isDark, theme: newTheme });
      setCookie(THEME_NAME, newTheme);
    }
  }))
);

useThemeStore.subscribe(
  (state) => state.isDark,
  (isDark) => {
    applyDarkValue(isDark);
  },
  { fireImmediately: true }
);
