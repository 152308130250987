import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { DepositHistoryItem as DepositHistoryItemType } from '@/modules/wallet/types';
import { ADV_CUR } from '@/utils/consts';
import { formatDate } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';

import cls from './DepositHistory.module.scss';

type Props = {
  deposit: DepositHistoryItemType;
};

export const DepositHistoryItem: React.FC<Props> = ({ deposit }) => {
  const { t } = useTranslation();
  const [lang] = useLang();

  const payDate = formatDate(
    new Date(deposit.created_at * 1000),
    'd MMMM y, H:mm',
    lang
  );

  return (
    <div className={cls.item}>
      <div>
        <p className={cls.pay_method}>{deposit.pay_method_title}</p>
        <p className={cls.pay_date}>{payDate}</p>
      </div>
      <b className={cls.amount}>
        {t('priceCurrency', {
          price: numberWithSpaces(deposit.amount, lang),
          currency: ADV_CUR
        })}
      </b>
    </div>
  );
};
