import { nanoid } from 'nanoid';
import { create } from 'zustand';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { CHAT_API_ROUTES } from '@/modules/chat/api';
import { prepareContentForSend } from '@/modules/chat/Detail/helpers';
import {
  LOAD_COUNT,
  MAX_MEDIA_CONTENT_LEN,
  sortMessagesByDate
} from '@/modules/chat/Detail/Messages/helpers';
import {
  ChatBlockStatus,
  ChatDetail,
  ChatDirection,
  ChatMessageAuthor,
  ChatMessageContent,
  ChatMessageContentType,
  ChatMessageWithReplied,
  GetChatMessagesReq,
  GetChatMessagesRes,
  PullChatMessagesRes,
  ReadMessageReq,
  SendMessageReq,
  SendMessageRes,
  UpdateMessageReq,
  UpdateMessageRes
} from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import { UploadRes } from '@/modules/files/types';
import { NETWORK_ERR_CODE, TIMEOUT_ERR_CODE } from '@/utils/consts';
import { replaceBreaksWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

interface ChatDetailStore {
  // Detail
  detail: Nullable<ChatDetail>;
  isDetailLoading: boolean;
  fetchDetail: (id: number) => void;
  // Status
  updateBlockStatus: (v: ChatBlockStatus) => void;
  // Message actions
  actionsMessageId: Nullable<number>;
  setActionsMessageId: (id: Nullable<number>) => void;
  // Editing
  isMsgEditOpen: boolean;
  editingMsg: Nullable<ChatMessageWithReplied>;
  openMsgEdit: (msg: Nullable<ChatMessageWithReplied>) => void;
  closeMsgEdit: () => void;
  editTextMessage: (message_id: number, text: string) => void;
  // Replying
  isMsgReplyOpen: boolean;
  replyingMsg: Nullable<ChatMessageWithReplied>;
  replyingAttachedFiles: ChatMessageContent[];
  addReplyingAttachedFiles: (v: ChatMessageContent[]) => void;
  removeReplyingAttachedFile: (fileUrl: string) => void;
  removeReplyingAttachedFiles: () => void;
  openMsgReply: (msg: Nullable<ChatMessageWithReplied>) => void;
  closeMsgReply: () => void;
  replyMessage: (text: string, content: ChatMessageContent[]) => void;
  // Delete msg
  deleteMessage: (message_id: number, successText: string) => void;
  // Highlight message
  highlightMessageId: Nullable<number>;
  setHighlightMessageId: (id: Nullable<number>) => void;
  // Messages
  messages: ChatMessageWithReplied[];
  addLocalMessage: (
    text: Nullable<string>,
    content: ChatMessageContent[],
    replied_message?: Nullable<ChatMessageWithReplied>,
    local_id?: Nullable<string>
  ) => void;
  updateLocalMessage: (local_id: string, msg: ChatMessageWithReplied) => void;
  isCalculatedMsgsLoaded: boolean;
  lastPrev: Nullable<number>;
  lastNext: Nullable<number>;
  isMsgsLoading: boolean;
  isAreaLoading: boolean;
  isTopLoaded: boolean;
  isBotLoaded: boolean;
  fromTop: boolean;
  fromBot: boolean;
  lastMsgId: () => number;
  penultMsgId: () => number;
  isAllMsgsLoaded: () => boolean;
  calcSkip: (isTop: boolean) => number;
  loadMsgs: (dir?: ChatDirection) => void;
  scrollToMsg: (id: number, cb?: () => void) => void;
  listRef: Nullable<HTMLDivElement>;
  setListRef: (v: Nullable<HTMLDivElement>) => void;
  messagesContent: ChatMessageContent[];
  setMessagesContent: (v: ChatMessageContent[]) => void;
  previewIndex: number;
  setPreviewIndex: (index: number) => void;
  previewUrl: string;
  setPreviewUrl: (url: string) => void;
  isPreviewOpen: boolean;
  setPreviewOpen: (v: boolean) => void;
  // Pull messages
  pullMsgs: (id: number, abort: AbortController) => void;
  handlePullResponse: (response: PullChatMessagesRes) => void;
  // Send messages
  canSendMsgs: boolean;
  isSendingMessage: boolean;
  isUploading: boolean;
  setUploading: (v: boolean) => void;
  attachedFiles: ChatMessageContent[];
  addAttachedFiles: (v: ChatMessageContent[]) => void;
  removeAttachedFile: (fileUrl: string) => void;
  removeAttachedFiles: () => void;
  sendPrimaryMessage: (
    id: number,
    text: Nullable<string>,
    content: ChatMessageContent[]
  ) => void;
  sendLocMessage: (
    id: number,
    lat: number,
    long: number,
    replied_message?: Nullable<ChatMessageWithReplied>
  ) => void;
  sendFileMessage: (
    id: number,
    uploadRes: UploadRes,
    replied_message?: Nullable<ChatMessageWithReplied>
  ) => void;
  // Read messages
  isReadMsgsLoading: boolean;
  canAddUnreadMessage: boolean;
  unreadMsgId: Nullable<number>;
  setUnreadMsgId: (v: number) => void;
  unreadMessages: number[];
  addUnreadMessage: (v: number) => void;
  getUnreadMessages: () => number[];
  readMessages: () => void;
  // Scroll key
  msgsScrollKey: number;
  updateScrollKey: () => void;
  // Profile
  isProfileOpen: boolean;
  setProfileOpen: (v: boolean) => void;
  // Search
  isSearchOpen: boolean;
  setSearchOpen: (v: boolean) => void;
  searchText: string;
  setSearchText: (v: string) => void;
  searchIndex: number;
  setSearchIndex: (v: number) => void;
  searchIds: number[];
  setSearchIds: (v: number[]) => void;
  closeSearch: () => void;
  // Reset
  reset: () => void;
  resetMessages: () => void;
  msgsAbortController: AbortController;
}

export const useChatDetailStore = create<ChatDetailStore>((set, get) => ({
  // Detail
  detail: null,
  isDetailLoading: false,
  fetchDetail: async (id: number) => {
    set({ isDetailLoading: true });
    try {
      const { data } = await axios.get<ChatDetail>(
        CHAT_API_ROUTES.getChatDetail(id)
      );
      set({
        detail: data,
        isDetailLoading: false,
        canSendMsgs: data.is_available_to_send_messages
      });
    } catch (error) {
      showAlert({ error });
      set({ isDetailLoading: false });
    }
  },
  // Status
  updateBlockStatus: (v: ChatBlockStatus) => {
    const { detail } = get();
    if (detail) {
      const tmp = detail.block_status ? { ...detail.block_status } : {};
      set({ detail: { ...detail, block_status: { ...tmp, ...v } } });
    }
  },
  // Message actions
  actionsMessageId: null,
  setActionsMessageId: (actionsMessageId: Nullable<number>) =>
    set({ actionsMessageId }),
  // Editing
  isMsgEditOpen: false,
  editingMsg: null,
  openMsgEdit: (editingMsg: Nullable<ChatMessageWithReplied>) => {
    const { closeSearch, closeMsgReply } = get();
    closeSearch();
    closeMsgReply();
    set({ editingMsg, isMsgEditOpen: true });
  },
  closeMsgEdit: () => {
    set({ editingMsg: null, isMsgEditOpen: false });
  },
  editTextMessage: async (message_id: number, text: string) => {
    const { editingMsg, closeMsgEdit, messages } = get();

    if (editingMsg?.text) {
      const msgText = replaceBreaksWithSpaces(editingMsg.text);
      const newText = replaceBreaksWithSpaces(text);
      if (newText === msgText) {
        closeMsgEdit();
        return;
      }
    }

    set({
      isSendingMessage: true,
      isMsgEditOpen: false,
      editingMsg: null,
      messages: messages.map((m) => (m.id === message_id ? { ...m, text } : m))
    });

    try {
      const req: UpdateMessageReq = { text };
      const r = await axios.post<UpdateMessageRes>(
        CHAT_API_ROUTES.updateMessage(message_id),
        req
      );
      set({
        isSendingMessage: false,
        messages: messages.map((m) => (m.id === message_id ? r.data : m))
      });
    } catch (error) {
      showAlert({ error });
      set({
        isSendingMessage: false,
        messages: messages.map((m) =>
          m.id === message_id ? { ...m, text: editingMsg?.text || '' } : m
        )
      });
    }
  },
  // Replying
  isMsgReplyOpen: false,
  replyingMsg: null,
  openMsgReply: (replyingMsg: Nullable<ChatMessageWithReplied>) => {
    const { closeSearch, closeMsgEdit } = get();
    closeSearch();
    closeMsgEdit();
    set({ replyingMsg, isMsgReplyOpen: true });
  },
  closeMsgReply: () => {
    set({ replyingMsg: null, isMsgReplyOpen: false });
  },
  replyMessage: async (text: string, content: ChatMessageContent[]) => {
    const {
      replyingMsg,
      detail,
      addLocalMessage,
      updateLocalMessage,
      messages
    } = get();
    if (!replyingMsg || !detail) return;

    const msg = { ...replyingMsg };
    const local_id = nanoid();
    set({ isSendingMessage: true, isMsgReplyOpen: false, replyingMsg: null });

    try {
      addLocalMessage(text, content, msg, local_id);

      const req: SendMessageReq = {
        local_id,
        text,
        content: prepareContentForSend(content),
        replied_message_id: msg.id
      };
      const r = await axios.post<SendMessageRes>(
        CHAT_API_ROUTES.sendMessage(detail.id),
        req
      );
      if (r.data) updateLocalMessage(local_id, r.data);
    } catch (error) {
      showAlert({ error });
      set({
        messages: messages.filter((m) => m.local_id !== local_id)
      });
    } finally {
      set({ isSendingMessage: false, isMsgReplyOpen: false });
    }
  },
  replyingAttachedFiles: [],
  addReplyingAttachedFiles: (v: ChatMessageContent[]) => {
    const { replyingAttachedFiles } = get();
    const merged = [...replyingAttachedFiles, ...v];
    set({ replyingAttachedFiles: merged.slice(0, MAX_MEDIA_CONTENT_LEN) });
  },
  removeReplyingAttachedFile: (fileUrl: string) => {
    const { replyingAttachedFiles } = get();
    const filtered = replyingAttachedFiles.filter((v) => v.url !== fileUrl);
    set({ replyingAttachedFiles: filtered });
  },
  removeReplyingAttachedFiles: () => {
    set({ replyingAttachedFiles: [] });
  },
  // Highlight message,
  highlightMessageId: null,
  setHighlightMessageId: (highlightMessageId: Nullable<number>) =>
    set({ highlightMessageId }),
  // Delete msg
  deleteMessage: async (message_id: number, successText: string) => {
    set({ isSendingMessage: true });

    const { messages } = get();
    try {
      await axios.delete(CHAT_API_ROUTES.deleteMessage(message_id));
      set({
        messages: messages.map((m) =>
          m.id === message_id ? { ...m, is_deleted: true } : m
        )
      });
      showAlert({ type: 'success', text: successText });
    } catch (error) {
      showAlert({ error });
    } finally {
      set({ isSendingMessage: false });
    }
  },
  // Messages
  messages: [],
  addLocalMessage: (
    text: Nullable<string>,
    content: ChatMessageContent[],
    replied_message?: Nullable<ChatMessageWithReplied>,
    local_id?: Nullable<string>
  ) => {
    const { lastMsgId, updateScrollKey, messages } = get();

    const d = Date.now() / 1000;
    const msg: ChatMessageWithReplied = {
      id: lastMsgId() + 10,
      index: null,
      local_id: local_id || null,
      is_local: true,
      text,
      content,
      created_at: d,
      updated_at: null,
      author: ChatMessageAuthor.my,
      author_name: '',
      author_avatar_url: null,
      is_read: false,
      is_deleted: false,
      replied_message: replied_message || null
    };

    set({ messages: [...messages, msg] });
    updateScrollKey();
  },
  updateLocalMessage: (local_id: string, msg: ChatMessageWithReplied) => {
    const { messages } = get();
    set({
      messages: messages.map((m) => {
        if (m.local_id === local_id) {
          return { ...m, ...msg, is_local: false };
        }
        return m;
      })
    });
  },
  isCalculatedMsgsLoaded: false,
  lastPrev: null,
  lastNext: null,
  isMsgsLoading: false,
  isAreaLoading: false,
  isTopLoaded: false,
  isBotLoaded: false,
  fromTop: false,
  fromBot: false,
  lastMsgId: () => {
    const { messages } = get();
    if (messages.length <= 0) return -1;
    if (messages.length === 1) return messages[0].id;
    return messages[messages.length - 1].id;
  },
  penultMsgId: () => {
    const { messages } = get();
    if (messages.length <= 1) return -1;
    return messages[messages.length - 2].id;
  },
  isAllMsgsLoaded: () => {
    const { isCalculatedMsgsLoaded, isTopLoaded, isBotLoaded } = get();
    return isCalculatedMsgsLoaded || (isTopLoaded && isBotLoaded);
  },
  calcSkip: (isTop: boolean) => {
    const { fromBot, fromTop, messages, lastNext, lastPrev } = get();
    if (!fromBot && !fromTop && messages.length < LOAD_COUNT) {
      return messages.length;
    }

    if (isTop) {
      const res = lastNext === null ? 0 : LOAD_COUNT + lastNext;
      return res;
    }

    const res = lastPrev === null ? 0 : LOAD_COUNT + lastPrev;
    return res;
  },
  loadMsgs: async (dir?: Nullable<ChatDirection>) => {
    const {
      msgsAbortController,
      detail,
      isAreaLoading,
      isMsgsLoading,
      isAllMsgsLoaded,
      calcSkip,
      messages,
      lastPrev,
      lastNext
    } = get();
    if (isAreaLoading || isMsgsLoading || isAllMsgsLoaded() || !detail) {
      return;
    }

    try {
      set({ isMsgsLoading: true });

      // Dir
      const direction = dir || ChatDirection.direct;
      const isTop = direction === ChatDirection.direct;
      const isBot = !isTop;

      // Skip
      const skip = calcSkip(isTop);

      if (skip === 0) {
        set({ fromBot: isTop, fromTop: isBot });
      }

      // Response
      const req: GetChatMessagesReq = {
        direction,
        skip,
        limit: LOAD_COUNT
      };
      const response = await axios.get<GetChatMessagesRes>(
        CHAT_API_ROUTES.getChatMessages(detail.id),
        { params: req, signal: msgsAbortController.signal }
      );
      const resData = response.data;
      const responseMsgs = resData.messages;

      const resultMsgs = isTop
        ? [...responseMsgs.reverse(), ...messages]
        : [...messages, ...responseMsgs];

      const botUpdates = isBot
        ? {
            lastPrev: resData.previous,
            isBotLoaded: responseMsgs.length < LOAD_COUNT || resData.next === 0
          }
        : {};

      const topUpdates = isTop
        ? {
            lastNext: resData.next,
            isTopLoaded:
              responseMsgs.length < LOAD_COUNT || resData.previous === 0
          }
        : {};

      const prev = isBot ? botUpdates.lastPrev : lastPrev;
      const next = isTop ? topUpdates.lastNext : lastNext;

      set({
        ...topUpdates,
        ...botUpdates,
        messages: [...resultMsgs],
        isCalculatedMsgsLoaded:
          (resData.previous === 0 && prev === null) ||
          (resData.next === 0 && next === null),
        isMsgsLoading: false
      });
    } catch (error) {
      // @ts-ignore
      const isCanceled = !!error && error.message === 'canceled';
      if (!isCanceled) showAlert({ error });
      set({ isMsgsLoading: false });
    }
  },
  scrollToMsg: async (id: number, cb?: () => void) => {
    const {
      messages,
      listRef,
      detail,
      isUploading,
      isSendingMessage,
      isMsgsLoading,
      isReadMsgsLoading,
      isTopLoaded,
      isBotLoaded
    } = get();

    const ids = messages.map((m) => m.id);
    if (ids.includes(id) && listRef) {
      const msgEl = listRef.querySelector(
        `[data-msg-id="${id}"]`
      ) as HTMLElement;

      if (msgEl) {
        listRef.scrollTop = msgEl.offsetTop - 200;
        set({ highlightMessageId: id });
        if (cb) cb();
        return;
      }
    }

    if (
      isUploading ||
      isSendingMessage ||
      isMsgsLoading ||
      isReadMsgsLoading ||
      !detail
    )
      return;

    set({
      isMsgsLoading: true,
      isAreaLoading: true,
      isTopLoaded: false,
      isBotLoaded: false,
      fromBot: false,
      fromTop: false,
      isCalculatedMsgsLoaded: false,
      lastPrev: null,
      lastNext: null,
      messages: []
    });

    try {
      const req: GetChatMessagesReq = {
        purpose_entity_id: id,
        purpose_entity_area: (LOAD_COUNT - 1) / 2
      };
      const response = await axios.get<GetChatMessagesRes>(
        CHAT_API_ROUTES.getChatMessages(detail.id),
        {
          params: req
        }
      );
      const resData = response.data;
      const responseMsgs = resData.messages;

      const topUpdates = !isTopLoaded
        ? { isTopLoaded: resData.previous === 0 }
        : {};
      const botUpdates = !isBotLoaded
        ? { isBotLoaded: resData.next === 0 }
        : {};

      set({
        ...topUpdates,
        ...botUpdates,
        messages: [...responseMsgs.reverse()],
        lastPrev: resData.previous,
        lastNext: resData.next,
        isMsgsLoading: false,
        isAreaLoading: false
      });

      setTimeout(() => {
        if (listRef) {
          const msgEl = listRef.querySelector(
            `[data-msg-id="${id}"]`
          ) as HTMLElement;
          if (msgEl) listRef.scrollTop = msgEl.offsetTop - 200;
          set({ highlightMessageId: id });
          if (cb) cb();
        }
      }, 0);
    } catch (error) {
      showAlert({ error });
      set({ isMsgsLoading: false, isAreaLoading: false });
    }
  },
  listRef: null,
  setListRef: (listRef: Nullable<HTMLDivElement>) => set({ listRef }),
  messagesContent: [],
  setMessagesContent: (messagesContent) => set({ messagesContent }),
  previewIndex: 0,
  setPreviewIndex: (index: number) => set({ previewIndex: index }),
  previewUrl: '',
  setPreviewUrl: (url: string) => set({ previewUrl: url }),
  isPreviewOpen: false,
  setPreviewOpen: (v: boolean) => set({ isPreviewOpen: v }),
  // Pull messages
  pullMsgs: async (id: number, abort: AbortController) => {
    const { handlePullResponse, pullMsgs } = get();

    try {
      const r = await axios.get<PullChatMessagesRes>(
        CHAT_API_ROUTES.pullChatMessages(id),
        {
          signal: abort.signal
        }
      );
      handlePullResponse(r.data);
      pullMsgs(id, abort);
    } catch (error) {
      // @ts-ignore
      const isCanceled = !!error && error.message === 'canceled';

      if (!isCanceled) {
        if (
          // @ts-ignore
          (error && error.response?.status === TIMEOUT_ERR_CODE) ||
          // @ts-ignore
          (error && error.code === NETWORK_ERR_CODE)
        ) {
          pullMsgs(id, abort);
        } else {
          showAlert({ error });
        }
      }
    }
  },
  handlePullResponse: (response: PullChatMessagesRes) => {
    const { updateBlockStatus, updateScrollKey, messages } = get();
    set({ canSendMsgs: response.is_available_for_send_messages });

    // Status
    if (response.block_status) {
      updateBlockStatus(response.block_status);
    }

    // Remove local messages
    const updatedLocalIds = response.updated_messages
      .filter((m) => !!m.local_id)
      .map((m) => m.local_id);

    const newLocalIds = response.new_messages
      .filter((m) => !!m.local_id)
      .map((m) => m.local_id);

    let result = [
      ...messages.filter((msg) => {
        if (
          'is_local' in msg &&
          msg.local_id &&
          (updatedLocalIds.includes(msg.local_id) ||
            newLocalIds.includes(msg.local_id))
        ) {
          return false;
        }

        return true;
      })
    ];

    // Merge updated msgs
    if (response.updated_messages.length > 0) {
      const updatedIds = response.updated_messages.map((m) => m.id);

      result = result.map((msg) => {
        if (updatedIds.includes(msg.id)) {
          const updatedMsg = response.updated_messages.find(
            (m) => m.id === msg.id
          );
          return updatedMsg ? { ...msg, ...updatedMsg } : msg;
        }

        return msg;
      });
    }

    const mappedResult = [
      ...result,
      ...response.new_messages
        .map((m) => {
          const isOwnMsg = m.author === ChatMessageAuthor.my;
          return isOwnMsg ? m : { ...m, is_from_pull: true };
        })
        .reverse()
    ];
    set({
      messages: sortMessagesByDate(mappedResult)
    });

    if (response.new_messages.length > 0) {
      updateScrollKey();
    }
  },
  // Send messages
  canSendMsgs: false,
  isSendingMessage: false,
  isUploading: false,
  setUploading: (isUploading: boolean) => set({ isUploading }),
  attachedFiles: [],
  addAttachedFiles: (v: ChatMessageContent[]) => {
    const { attachedFiles } = get();
    const merged = [...attachedFiles, ...v];
    set({ attachedFiles: merged.slice(0, MAX_MEDIA_CONTENT_LEN) });
  },
  removeAttachedFile: (fileUrl: string) => {
    const { attachedFiles } = get();
    const filtered = attachedFiles.filter((v) => v.url !== fileUrl);
    set({ attachedFiles: filtered });
  },
  removeAttachedFiles: () => {
    set({ attachedFiles: [] });
  },
  sendPrimaryMessage: async (
    id: number,
    text: Nullable<string>,
    content: ChatMessageContent[]
  ) => {
    const { messages } = get();
    set({ isSendingMessage: true });
    const local_id = nanoid();

    const { addLocalMessage, updateLocalMessage } = get();
    try {
      addLocalMessage(text, content, null, local_id);

      const req: SendMessageReq = {
        text,
        content: prepareContentForSend(content),
        local_id,
        replied_message_id: null
      };
      const r = await axios.post<SendMessageRes>(
        CHAT_API_ROUTES.sendMessage(id),
        req
      );

      if (r.data) updateLocalMessage(local_id, r.data);
    } catch (error) {
      showAlert({ error });
      set({
        messages: messages.filter((m) => m.local_id !== local_id)
      });
    } finally {
      set({ isSendingMessage: false });
    }
  },
  sendLocMessage: async (
    id: number,
    lat: number,
    long: number,
    replied_message?: Nullable<ChatMessageWithReplied>
  ) => {
    set({ isSendingMessage: true });
    const local_id = nanoid();
    const { messages } = get();

    const { addLocalMessage, updateLocalMessage } = get();
    try {
      const content: ChatMessageContent = {
        name: null,
        url: null,
        thumbnail_url: null,
        duration: null,
        size: null,
        extension: null,
        upload_date: Date.now() / 1000,
        type: ChatMessageContentType.location,
        coords: {
          lat,
          long
        }
      };

      addLocalMessage(null, [content], replied_message, local_id);

      const req: SendMessageReq = {
        local_id,
        text: null,
        content: prepareContentForSend([content]),
        replied_message_id: replied_message?.id || null
      };
      const r = await axios.post<SendMessageRes>(
        CHAT_API_ROUTES.sendMessage(id),
        req
      );

      if (r.data) updateLocalMessage(local_id, r.data);
    } catch (error) {
      showAlert({ error });
      set({
        messages: messages.filter((m) => m.local_id !== local_id)
      });
    } finally {
      set({ isSendingMessage: false });
    }
  },
  sendFileMessage: async (
    id: number,
    uploadRes: UploadRes,
    replied_message?: Nullable<ChatMessageWithReplied>
  ) => {
    set({ isSendingMessage: true });
    const local_id = nanoid();
    const { messages } = get();
    const { url, name, extension, size } = uploadRes;

    const { addLocalMessage, updateLocalMessage } = get();
    try {
      const content: ChatMessageContent = {
        url,
        name,
        extension,
        size,
        upload_date: Date.now() / 1000,
        type: ChatMessageContentType.file,
        thumbnail_url: null,
        duration: null,
        coords: null
      };

      addLocalMessage(null, [content], replied_message, local_id);

      const req: SendMessageReq = {
        local_id,
        text: null,
        content: prepareContentForSend([content]),
        replied_message_id: replied_message?.id || null
      };
      const r = await axios.post<SendMessageRes>(
        CHAT_API_ROUTES.sendMessage(id),
        req
      );

      if (r.data) updateLocalMessage(local_id, r.data);
    } catch (error) {
      showAlert({ error });
      set({
        messages: messages.filter((m) => m.local_id !== local_id)
      });
    } finally {
      set({ isSendingMessage: false });
    }
  },
  // Read messages
  isReadMsgsLoading: false,
  canAddUnreadMessage: true,
  unreadMsgId: null,
  setUnreadMsgId: (unreadMsgId: number) => {
    set({ unreadMsgId });
  },
  unreadMessages: [],
  addUnreadMessage: (id: number) => {
    const { canAddUnreadMessage, unreadMessages } = get();
    if (canAddUnreadMessage && !unreadMessages.includes(id)) {
      set({ unreadMessages: [...unreadMessages, id] });
    }
  },
  getUnreadMessages: () => {
    const { unreadMessages } = get();
    const ids = [...unreadMessages];
    set({ unreadMessages: [] });
    return ids;
  },
  readMessages: async () => {
    const { isReadMsgsLoading, getUnreadMessages } = get();

    if (isReadMsgsLoading) return;

    set({ isReadMsgsLoading: true, canAddUnreadMessage: false });
    const ids = getUnreadMessages();
    set({ canAddUnreadMessage: true });

    if (ids.length > 0) {
      try {
        const req: ReadMessageReq = { ids };
        await axios.post(CHAT_API_ROUTES.readMessages, req);
        useChatUtilsStore.getState().updateUnreadCountUpdateKey();
      } catch (error) {
        showAlert({ error });
      } finally {
        set({ isReadMsgsLoading: false });
      }
    } else {
      set({ isReadMsgsLoading: false });
    }
  },
  // Scroll key
  msgsScrollKey: 0,
  updateScrollKey: () => {
    const { msgsScrollKey } = get();
    set({ msgsScrollKey: msgsScrollKey + 1 });
  },
  // Profile
  isProfileOpen: false,
  setProfileOpen: (isProfileOpen: boolean) => set({ isProfileOpen }),
  // Search
  isSearchOpen: false,
  setSearchOpen: (isSearchOpen: boolean) => set({ isSearchOpen }),
  searchText: '',
  setSearchText: (searchText: string) => set({ searchText }),
  searchIndex: 0,
  setSearchIndex: (searchIndex: number) => set({ searchIndex }),
  searchIds: [],
  setSearchIds: (searchIds: number[]) => set({ searchIds }),
  closeSearch: () => {
    set({
      isSearchOpen: false,
      searchText: '',
      searchIndex: 0,
      searchIds: []
    });
  },
  // Reset
  reset: () => {
    set({
      detail: null,
      isDetailLoading: false,
      actionsMessageId: null,
      isMsgEditOpen: false,
      editingMsg: null,
      isMsgReplyOpen: false,
      replyingMsg: null,
      replyingAttachedFiles: [],
      messages: [],
      isCalculatedMsgsLoaded: false,
      lastPrev: null,
      lastNext: null,
      isMsgsLoading: false,
      isAreaLoading: false,
      isTopLoaded: false,
      isBotLoaded: false,
      fromTop: false,
      fromBot: false,
      listRef: null,
      canSendMsgs: false,
      isSendingMessage: false,
      isUploading: false,
      attachedFiles: [],
      isReadMsgsLoading: false,
      canAddUnreadMessage: true,
      unreadMsgId: null,
      unreadMessages: [],
      msgsScrollKey: 0,
      isProfileOpen: false,
      isSearchOpen: false,
      searchText: '',
      searchIndex: 0,
      searchIds: []
    });
  },
  resetMessages: () => {
    const { msgsAbortController } = get();
    msgsAbortController.abort();

    set({
      isMsgsLoading: false,
      isAreaLoading: false,
      isTopLoaded: false,
      isBotLoaded: false,
      fromBot: false,
      fromTop: false,
      isCalculatedMsgsLoaded: false,
      lastPrev: null,
      lastNext: null,
      messages: [],
      msgsAbortController: new AbortController()
    });
  },
  msgsAbortController: new AbortController()
}));
