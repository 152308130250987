import { isFirefox as detectFirefox } from '@/utils/detect';

const isFirefox = detectFirefox();
let top = 0;
let lock = false;
let names: string[] = [];

const getScrollbarWidth = (): number => {
  const div = document.createElement('div');

  div.style.overflowY = 'scroll';
  div.style.position = 'absolute';
  div.style.width = '50px';
  div.style.height = '50px';
  div.style.visibility = 'hidden';

  document.body.appendChild(div);
  const scrollWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);

  return scrollWidth;
};

const disable = (name: string): void => {
  if (lock || names.includes(name)) return;
  lock = true;
  names.push(name);

  const body = document.querySelector('body') as HTMLElement;
  top = window.pageYOffset;
  body.style.top = `-${top}px`;
  body.style.paddingRight = `${getScrollbarWidth()}px`;
  body.classList.add('no-scroll');
  if (isFirefox) {
    body.classList.add('is-firefox');
  }
};

const enable = (name: string): void => {
  if (!lock || !names.includes(name)) return;
  lock = false;
  names = names.filter((n) => n !== name);

  const body = document.querySelector('body') as HTMLElement;
  body.style.top = '';
  body.style.paddingRight = '';
  body.classList.remove('no-scroll');
  window.scrollTo(0, top);
};

export const scroll = { disable, enable };
