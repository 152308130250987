import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useClientComm } from '@/modules/client-comm/hooks';
import { ClientCommunicationRes } from '@/modules/client-comm/types';
import {
  CARS_ADVERTS_URL,
  RENT_ADVERTS_URL,
  VRP_ADVERTS_URL
} from '@/modules/showroom/advert/my/helpers';
import { getFromStorage, saveInStorage } from '@/utils/local-storage';

import {
  OverviewIcon,
  CarIcon,
  CalendarIcon,
  PlateIcon,
  ChatIcon,
  CallIcon,
  CreditReqIcon,
  WalletNumbersIcon,
  TariffsIcon,
  InfoIcon,
  AddressIcon,
  ContactIcon,
  ReviewIcon,
  LeadsIcon,
  ArrowIcon,
  NotifyIcon
} from './icons';
import cls from './Nav.module.scss';

const OPENED_SECTIONS_STORAGE_KEY = 'opened-nav-sections';

type MappedNavList = {
  label: string;
  children: {
    label: string;
    path: string;
    icon: React.ReactNode;
    end?: boolean;
    count?: number;
  }[];
}[];

export function getNavList(
  counts: ClientCommunicationRes | undefined
): MappedNavList {
  return [
    {
      label: 'analytics',
      children: [
        {
          label: 'overview',
          path: '/',
          icon: <OverviewIcon />,
          end: true
        }
      ]
    },
    {
      label: 'sale',
      children: [
        {
          label: 'carShort',
          path: CARS_ADVERTS_URL,
          icon: <CarIcon />,
          end: true
        },
        {
          label: 'numbers',
          path: VRP_ADVERTS_URL,
          icon: <PlateIcon />,
          end: true
        }
      ]
    },
    {
      label: 'rent',
      children: [
        {
          label: 'carsRent',
          path: RENT_ADVERTS_URL,
          icon: <CarIcon />,
          end: true
        },
        {
          label: 'booking.titleMany',
          path: '/booking/rent',
          icon: <CalendarIcon />,
          count: counts?.count_unread_bookings || undefined,
          end: true
        }
      ]
    },
    {
      label: 'contactWithClients',
      children: [
        {
          label: 'chat.one',
          path: '/chat',
          icon: <ChatIcon />,
          count: counts?.count_unread_chats || undefined,
          end: true
        },
        {
          label: 'callback.title',
          path: '/callback',
          icon: <CallIcon />,
          count: counts?.count_unread_callbacks || undefined,
          end: true
        },
        {
          label: 'credits.req.title',
          path: '/credits/requests',
          icon: <CreditReqIcon />,
          count: counts?.count_unread_request_credit || undefined,
          end: true
        },
        {
          label: 'leads.title',
          path: '/leads',
          icon: <LeadsIcon />,
          count: counts?.count_unread_leads || undefined,
          end: true
        }
      ]
    },
    {
      label: 'tariffsAndPayment',
      children: [
        {
          label: 'wallet.title',
          path: '/payment/wallet',
          icon: <WalletNumbersIcon />,
          end: true
        },
        {
          label: 'tariffs.title',
          path: '/payment/tariffs',
          icon: <TariffsIcon />,
          end: true
        }
      ]
    },
    {
      label: 'showroom.label',
      children: [
        {
          label: 'general',
          path: '/showroom/general',
          icon: <InfoIcon />,
          end: true
        },
        {
          label: 'addressesAndBranches',
          path: '/showroom/address',
          icon: <AddressIcon />,
          end: true
        },
        {
          label: 'contacts.title',
          path: '/showroom/contacts',
          icon: <ContactIcon />,
          end: false
        },
        {
          label: 'notifications',
          path: '/showroom/notifications',
          icon: <NotifyIcon />
        },
        {
          label: 'ratingsAndReviews',
          path: '/showroom/reviews',
          icon: <ReviewIcon />,
          end: true
        }
      ]
    }
  ];
}

export function Nav() {
  const { t } = useTranslation();
  const clientComm = useClientComm();

  const navList = useMemo(() => getNavList(clientComm), [clientComm]);

  const [openedSections, setOpenedSections] = useState<number[]>([]);

  const toggleSection = (index: number) => {
    const newOpenedSections = openedSections.includes(index)
      ? [...openedSections.filter((openedIndex) => openedIndex !== index)]
      : [...openedSections, index];
    setOpenedSections(newOpenedSections);
    saveInStorage(OPENED_SECTIONS_STORAGE_KEY, newOpenedSections);
  };

  const restoreOpenedSections = () => {
    const openedSections = getFromStorage<number[]>(
      OPENED_SECTIONS_STORAGE_KEY
    );

    navList.map((_navItem, index) => index);
    if (!openedSections) {
      const defaultOpenedSections = navList.map((_navItem, index) => index);
      saveInStorage(
        OPENED_SECTIONS_STORAGE_KEY,
        navList.map((_navItem, index) => index)
      );
      setOpenedSections(defaultOpenedSections);
      return;
    }

    setOpenedSections(openedSections);
  };

  useEffect(() => {
    restoreOpenedSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className={cls.root}>
      <ul className={cls.root_list}>
        {navList.map((nav, index) => {
          const isSectionOpened = openedSections.includes(index);
          const sectionCounter = nav.children.reduce(
            (s, i) => (s = s + (i?.count || 0)),
            0
          );
          return (
            <li key={nav.label}>
              <button
                className={cn(cls.collapse_btn, {
                  [cls.collapse_btn_opened]: isSectionOpened
                })}
                onClick={() => toggleSection(index)}
              >
                <span className={cls.label}>{t(nav.label)}</span>
                {!isSectionOpened && !!sectionCounter && (
                  <span className={cls.counter}>{sectionCounter}</span>
                )}
                <ArrowIcon />
              </button>
              <Collapse isOpened={isSectionOpened}>
                <ul className={cls.list}>
                  {nav.children.map((c) => {
                    return (
                      <li key={c.path}>
                        <NavLink
                          className={({ isActive }) =>
                            cn(cls.list_link, {
                              [cls.list_link_active]: isActive
                            })
                          }
                          to={c.path}
                          end={c.end}
                        >
                          <span className={cls.icon}>{c.icon}</span>
                          <span>{t(c.label)}</span>
                          {'count' in c &&
                            !!c.count &&
                            typeof c.count === 'number' && (
                              <span className={cls.count}>{c.count}</span>
                            )}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </Collapse>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
