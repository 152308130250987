export function ChevronIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.80244 9.59239C4.60822 9.40233 4.5 9.14901 4.5 8.85724C4.5 8.26884 4.95637 7.80919 5.54317 7.80919C5.8382 7.80919 6.11029 7.9198 6.30107 8.12539L12.9459 14.9261H12.0622L18.7006 8.12539C18.8972 7.91818 19.1709 7.80919 19.4569 7.80919C20.042 7.80919 20.5 8.26884 20.5 8.85724C20.5 9.15062 20.3935 9.40396 20.196 9.59239L13.3179 16.6314C13.0895 16.873 12.8117 16.9967 12.5045 17C12.1916 17 11.9195 16.8746 11.6838 16.6314L4.80244 9.59239Z"
        fill="currentColor"
      />
    </svg>
  );
}
