import { useState, useCallback } from 'react';

import { axios } from '@/app/api';
import { CHAT_API_ROUTES } from '@/modules/chat/api';
import {
  ChatMessageContentWithAuthor,
  GetAllChatVideosReq,
  GetAllChatVideosRes
} from '@/modules/chat/types';
import { showAlert } from '@/utils/network';

const LOAD_COUNT = 41;

type UseChatVideos = {
  isAllLoaded: boolean;
  isLoading: boolean;
  data: ChatMessageContentWithAuthor[] | null;
  load: (chatId: number, skip: number) => void;
};

export function useChatVideos(): UseChatVideos {
  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ChatMessageContentWithAuthor[] | null>(null);

  const load = useCallback(async (chatId: number, skip: number) => {
    setLoading(true);

    try {
      const req: GetAllChatVideosReq = {
        limit: LOAD_COUNT,
        skip
      };
      const r = await axios.get<GetAllChatVideosRes>(
        CHAT_API_ROUTES.getAllChatVideos(chatId),
        { params: req }
      );
      const loaded = r.data;
      const len = loaded.length;

      setData((prev) => (prev ? [...prev, ...loaded] : loaded));
      setAllLoaded(len < LOAD_COUNT);
      setLoading(false);
    } catch (error) {
      showAlert({ error });
      setLoading(false);
    }
  }, []);

  return { isAllLoaded, isLoading, data, load };
}
