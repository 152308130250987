import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { useLang } from '@/hooks/useLang';
import { useAccountBalance } from '@/modules/accounts/balance/useAccountBalance';
import { useAccountStore } from '@/modules/accounts/store';
import { useStickersPrice } from '@/modules/emojis/api';
import { NO_PROMO_ID } from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { useTariff } from '@/modules/showroom/tariff/api';
import { TopUpModal } from '@/modules/showroom/tariff/TopUpModal/TopUpModal';
import { Button } from '@/ui/Button/Button';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './PublishResult.module.scss';

let timer = -1;

type Props = {
  stickersCount?: number;
  categoryId: number;
  promoPackagePrice: number;
  promoPackageName: string;
  promoPackageId: number;
  onClick: () => void;
  saveAd: () => void;
  onSaveClick?: () => void;
  loading: boolean;
  publishText?: string;
  disabled?: boolean;
  disablePublish?: boolean;
  hidePublish?: boolean;
  buttonsOnly?: boolean;
  setLastSectionVisible: CreateAdvertSlice['setLastSectionVisible'];
};

export function PublishResult({
  categoryId,
  promoPackagePrice,
  promoPackageName,
  promoPackageId,
  stickersCount,
  onClick,
  saveAd,
  onSaveClick,
  loading,
  publishText,
  disabled,
  disablePublish,
  hidePublish,
  buttonsOnly,
  setLastSectionVisible
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const isFreePromo = promoPackageId === NO_PROMO_ID;

  // Stickers
  const [stickerPriceData] = useStickersPrice();
  const stickerPrice = stickerPriceData || 0;
  const showStickers = typeof stickersCount === 'number';
  const stickersPrice = showStickers ? stickersCount * stickerPrice : 0;

  // Total
  const [publishTariff] = useTariff(categoryId);
  const firstDayPrice = publishTariff
    ? Number(publishTariff.price_first_day)
    : 0;
  const perDayPrice = publishTariff ? Number(publishTariff.price_per_day) : 0;
  const totalPrice = stickersPrice + promoPackagePrice + firstDayPrice;

  // Topup
  const [isTopUpOpen, setTopUpOpen] = useState(false);
  const { balanceAmount, balanceLoading } = useAccountBalance();
  const fetchBalance = useAccountStore((s) => s.fetchBalance);

  useEffect(() => {
    fetchBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTopUpConfirm = () => {
    fetchBalance();
    window.setTimeout(fetchBalance, 3000);
  };

  const handleClick = () => {
    if (balanceLoading) return;

    if (balanceAmount >= totalPrice) {
      onClick();
    } else {
      saveAd();
      setTopUpOpen(true);
    }
  };

  const enableObserver = useRef(false);
  useEffect(() => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      enableObserver.current = true;
    }, 1000);

    return () => {
      enableObserver.current = false;
      window.clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    return () => {
      setLastSectionVisible(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttons = (
    <InView
      as="div"
      onChange={(visible) => {
        if (enableObserver.current) setLastSectionVisible(visible);
      }}
    >
      <div className={cn(cls.buttons, { [cls.buttons_row]: buttonsOnly })}>
        {!hidePublish && (
          <Button
            onClick={handleClick}
            disabled={
              disablePublish ||
              disabled ||
              loading ||
              balanceLoading ||
              !publishTariff
            }
            loading={loading || balanceLoading}
            color="green"
            fullWidth
          >
            {publishText ? (
              publishText
            ) : (
              <>
                {totalPrice
                  ? t('publish.price', {
                      price: numberWithSpaces(totalPrice, lang),
                      currency: ADV_CUR
                    })
                  : t(disablePublish ? 'publish.do' : 'publish.free')}
              </>
            )}
          </Button>
        )}

        {onSaveClick && (
          <Button
            onClick={() => onSaveClick()}
            disabled={loading || disabled}
            loading={loading}
            variant="secondary"
            color="black"
            fullWidth
          >
            {t('publish.draft')}
          </Button>
        )}
      </div>
    </InView>
  );

  if (buttonsOnly) {
    return (
      <>
        {buttons}
        <TopUpModal
          isOpen={isTopUpOpen}
          setOpen={setTopUpOpen}
          onConfirm={onTopUpConfirm}
        />
      </>
    );
  }

  return (
    <div className={cls.root}>
      <div className="box">
        <ul className={cls.list}>
          {showStickers && (
            <li>
              <div className={cls.row}>
                <p>{t('stickers.titleSimple')}</p>
                <span className={cls.line} />
                <p className={cls.price}>
                  {t('priceCurrency', {
                    price: numberWithSpaces(stickersPrice, lang),
                    currency: ADV_CUR
                  })}
                </p>
              </div>
            </li>
          )}
          {firstDayPrice && (
            <li>
              <div className={cls.row}>
                <p>{t('publish.title')}</p>
                <span className={cls.line} />
                <p className={cls.price}>
                  {t('priceCurrency', {
                    price: numberWithSpaces(firstDayPrice, lang),
                    currency: ADV_CUR
                  })}
                  *
                </p>
              </div>
            </li>
          )}

          {!isFreePromo && (
            <li>
              <div className={cls.row}>
                <p>{promoPackageName}</p>
                <span className={cls.line} />
                <p className={cls.price}>
                  {t('priceCurrency', {
                    price: numberWithSpaces(promoPackagePrice, lang),
                    currency: ADV_CUR
                  })}
                </p>
              </div>
            </li>
          )}

          {firstDayPrice && perDayPrice && (
            <li>
              <div className={cls.row}>
                <p>
                  {t('publish.dayPrice', {
                    firstDay: firstDayPrice,
                    perDay: perDayPrice,
                    currency: ADV_CUR
                  })}
                </p>
              </div>
            </li>
          )}
        </ul>

        {buttons}

        <p className={cls.policy}>
          <Trans
            t={t}
            i18nKey="auth.policyAgreement"
            components={{
              termsLink: (
                <a
                  href={`${APP_URL}/terms_of_use`}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
              policyLink: (
                <a
                  href={`${APP_URL}/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                />
              )
            }}
          />
        </p>
      </div>

      <TopUpModal
        isOpen={isTopUpOpen}
        setOpen={setTopUpOpen}
        onConfirm={onTopUpConfirm}
      />
    </div>
  );
}
