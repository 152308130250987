import { VinSection } from '@/modules/showroom/advert/create/VinSection/VinSection';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function VinSectionWrap() {
  const vin = useUpdateAdvertStore((s) => s.vin);
  const setVin = useUpdateAdvertStore((s) => s.setVin);
  const isVinFilled = useUpdateAdvertStore((s) => s.isVinFilled);
  const setVinFilled = useUpdateAdvertStore((s) => s.setVinFilled);

  return (
    <VinSection
      vin={vin}
      setVin={setVin}
      isVinFilled={isVinFilled}
      setVinFilled={setVinFilled}
    />
  );
}
