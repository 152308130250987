import cn from 'classnames';

import { OptionI } from '@/app/types';

import cls from './Tabs.module.scss';

export type TabVariant =
  | 'primary'
  | 'secondary'
  | 'stroke'
  | 'underline'
  | 'segment';

export interface TabOption extends OptionI {
  icon?: string | JSX.Element;
}

type Props = {
  tabs: TabOption[];
  active?: TabOption;
  onChange?: (t: TabOption) => void;
  disabled?: boolean;
  variant?: TabVariant;
  size?: 'm' | 'l';
};

export const Tabs: React.FC<Props> = ({
  tabs,
  active,
  onChange,
  disabled,
  variant = 'primary',
  size = 'm'
}) => {
  return (
    <ul
      className={cn(cls.tabs, {
        [cls.tabs_primary]: variant === 'primary',
        [cls.tabs_secondary]: variant === 'secondary',
        [cls.tabs_segment]: variant === 'segment',
        [cls.tabs_stroke]: variant === 'stroke',
        [cls.tabs_underline]: variant === 'underline'
      })}
    >
      {tabs.map((tab) => (
        <li key={tab.id}>
          <button
            className={cn(cls.tab, {
              [cls.tab_active]: tab.id === active?.id,
              [cls.tab_l]: size === 'l',
              [cls.tab_m]: size === 'm'
            })}
            type="button"
            onClick={() => {
              if (onChange) onChange(tab);
            }}
            disabled={disabled}
            style={{ gap: tab.icon ? '8px' : '0.3em' }}
          >
            {tab.icon && (
              <>
                {typeof tab.icon === 'string' ? (
                  <img src={tab.icon} alt="" />
                ) : (
                  <>{tab.icon}</>
                )}
              </>
            )}
            {tab.text}
            {tab.extra && <span>{tab.extra}</span>}
          </button>
        </li>
      ))}
    </ul>
  );
};
