import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SCROLLTO,
  scrollToSection,
  isVinValid,
  MAX_VIN_LEN
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { VinHelp } from '@/modules/showroom/advert/create/VinSuggest/VinHelp';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { maxLength, onlyNumbersLatin } from '@/utils/format';

import cls from './VinSection.module.scss';

type Props = {
  vin: CreateAdvertSlice['vin'];
  setVin: CreateAdvertSlice['setVin'];
  isVinFilled: CreateAdvertSlice['isVinFilled'];
  setVinFilled: CreateAdvertSlice['setVinFilled'];
};

export function VinSection({ vin, setVin, isVinFilled, setVinFilled }: Props) {
  const { t } = useTranslation();

  const [isHelpOpen, setHelpOpen] = useState(false);

  const isValid = isVinValid(vin);
  const onContinueClick = () => {
    setVinFilled(true);
    scrollToSection(SCROLLTO.showroom, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.vin}>
      <div className="box">
        <div className={cls.header}>
          <h1>{t('vin.chassis')}</h1>
        </div>
        <p className={cls.text}>{t('vin.text')}</p>

        <div className={cls.field}>
          <Input
            value={vin}
            onChange={(e) =>
              setVin(
                onlyNumbersLatin(maxLength(e.currentTarget.value, MAX_VIN_LEN))
              )
            }
            label={t('vin.chassis')}
          />
          <button
            className={cls.vin_help}
            type="button"
            onClick={() => setHelpOpen(true)}
          >
            {t('vin.searchTip')}
          </button>
        </div>

        {!isVinFilled && (
          <Button
            onClick={onContinueClick}
            disabled={!isValid}
            variant="primary"
            color="blue"
            fullWidth
          >
            {t('common.continue')}
          </Button>
        )}
      </div>

      <VinHelp isOpen={isHelpOpen} close={() => setHelpOpen(false)} />
    </div>
  );
}
