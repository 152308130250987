import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountStore } from '@/modules/accounts/store';
import { confirmEmailSendCode } from '@/modules/auth/api';
import { ConfirmEmailCode } from '@/modules/auth/ConfirmEmail/ConfirmEmailCode';
import { useAuthStore } from '@/modules/auth/store';
import { ConfirmEmailRes } from '@/modules/auth/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { showAlert } from '@/utils/network';

export function ConfirmEmail() {
  const navigate = useNavigate();
  const auth = useAuthStore((s) => s.auth);
  const setAuth = useAuthStore((s) => s.setAuth);
  const accountInfo = useAccountStore((s) => s.accountInfo);
  const setAccountInfo = useAccountStore((s) => s.setAccountInfo);
  const email = accountInfo?.email || '';

  const [sentCode, setSendCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCodeSubmit = (response: ConfirmEmailRes) => {
    if (accountInfo) {
      setAccountInfo({ ...accountInfo, is_email_confirmed: true });
    }

    if (auth) {
      setAuth({ ...auth, ...response });
      navigate('/');
    }
  };

  const sentEmailCode = useCallback(async (emailValue: string) => {
    setLoading(true);

    try {
      await confirmEmailSendCode({ email: emailValue });
    } catch (error) {
      showAlert({ error });
    } finally {
      setSendCode(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading && !sentCode && email) {
      sentEmailCode(email);
    } else if (!auth) {
      navigate('/signin');
    }
  }, [auth, email, loading, navigate, sentCode, sentEmailCode]);

  return email && sentCode ? (
    <div className="box">
      <ConfirmEmailCode email={email} onSubmit={onCodeSubmit} />
    </div>
  ) : (
    <Spinner centered />
  );
}
