import type { TooltipProps } from 'rc-tooltip/lib/Tooltip';

import { removeProtocol } from '@/utils/format';

// Env
export const isProdApi = process.env.REACT_APP_SERVER === 'prod';
export const isBrowser = typeof window !== 'undefined';
export const isLocalDev = process.env.NODE_ENV === 'development';

// Urls
export const BUSINESS_APP_URL = process.env
  .REACT_APP_BUSINESS_APP_URL as string;
export const APP_URL = process.env.REACT_APP_APP_URL as string;

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL as string;
export const API_URL = `${process.env.REACT_APP_API_URL as string}/api`;

export const SOCKET_API_URL = `wss://${removeProtocol(API_URL)}`;
export const HIDDEN_CAR_IMG_PATH = `/img/hidden-car.webp`;

export const STORAGE_URL = isProdApi
  ? 'https://storage.auto.ae'
  : 'https://storage.devauto.ae';

// Other
export const emptyFn = () => null;
export const SUPPORT_EMAIl = 'support@auto.ae';
export const APP_AUTH_NAME = isProdApi
  ? 'auto.ae-dealersapp-auth-token'
  : 'auto.ae-dealersapp-auth-token-dev';
export const MODAL_ROOT_ID = 'modal-root';
export const SLUG_NEW = 'new';

export const CURRENCIES = {
  euro: 'eur',
  usd: 'usd',
  aed: 'aed'
};
export const CURRENCY_DISPLAY = {
  aed: 'AED',
  usd: '$',
  euro: '€'
};
export const ADV_CUR = CURRENCY_DISPLAY.aed;

export const MIN_PASSWORD_LENGTH = 5;
export const UNAUTH_CODE = 401;
export const TIMEOUT_ERR_CODE = 504;
export const NETWORK_ERR_CODE = 'ERR_NETWORK';
export const MAX_PG_LIMIT = 1000;
export const DESKTOP_BREAKPOINT = 1024;
export const BREAKPOINT = 770;
export const MOB_BREAKPOINT = 520;
export const HOURS_IN_DAY = 24;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY;
export const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;

export const MAX_PG_LIMIT_REQ = { limit: MAX_PG_LIMIT };
export const CHARTS_COLORS = {
  cars: '#6AB586',
  vrp: '#EBCB62',
  services: '#606ACB',
  refresh: '#8e2cbc'
};

export const timeZone = isBrowser
  ? Intl.DateTimeFormat().resolvedOptions().timeZone
  : '';

export const rcTooltipProps: Partial<TooltipProps> = {
  trigger: ['hover'],
  transitionName: 'rc-tooltip-anim'
};
