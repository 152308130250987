import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createReviewReply } from '@/modules/reviews/api-req';
import { ReviewsContext } from '@/modules/reviews/reviews-ctx';
import { ReplyIcon } from '@/modules/reviews/ReviewsList/ReviewCard/icons';
import { CreateNewReviewReplyReq, MyReview } from '@/modules/reviews/types';
import { EditInput } from '@/ui/EditInput/EditInput';
import { showAlert } from '@/utils/network';

import cls from './ReviewCard.module.scss';

type Props = {
  review: MyReview;
};

export function CreateReply({ review }: Props) {
  const { t } = useTranslation();

  const showReplyParams =
    !review.comments.reviewed || !review.comments.reviewed.comment;

  const [createReplyOpen, setCreateReplyOpen] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [isReplySaving, setReplySaving] = useState(false);
  const { fetchReviews } = useContext(ReviewsContext);

  const onSaveReply = async (replyText: string) => {
    if (!replyText) return;

    setReplySaving(true);
    try {
      const req: CreateNewReviewReplyReq = {
        replied_review_id: review.id,
        text: replyText
      };
      const res = await createReviewReply(req);
      if (res.data.result) await fetchReviews(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setReplySaving(false);
      setCreateReplyOpen(false);
    }
  };

  return (
    <>
      {showReplyParams && !createReplyOpen && (
        <div className={cls.review_params}>
          <button
            className={cls.param_btn}
            onClick={() => setCreateReplyOpen(true)}
          >
            <ReplyIcon />
            <p>{t('chat.messages.reply')}</p>
          </button>
        </div>
      )}

      {createReplyOpen && (
        <div className={cls.create_reply}>
          <EditInput
            value={replyText}
            onChange={(e) => setReplyText(e.currentTarget.value)}
            onSave={onSaveReply}
            isLoading={isReplySaving}
            small
          />
        </div>
      )}
    </>
  );
}
