export function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.11583 13.3132L12.2401 5.19234L10.8523 3.79871L2.72219 11.9196L2.01836 13.618C1.93304 13.8344 2.16138 14.0655 2.36745 13.9828L4.11583 13.3132ZM12.9323 4.51193L13.7147 3.74274C14.103 3.35445 14.1246 2.92423 13.7686 2.56689L13.4863 2.2833C13.1362 1.93189 12.7034 1.96342 12.3138 2.34448L11.5327 3.1183L12.9323 4.51193Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ImageIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6012 13.7884L13.4327 9.87698C13.123 9.58971 12.7513 9.44445 12.3751 9.44445C11.9894 9.44445 11.6433 9.57692 11.3221 9.86413L8.14398 12.7046L6.84592 11.5332C6.55037 11.2671 6.2254 11.1333 5.89592 11.1333C5.5704 11.1333 5.2792 11.26 4.98942 11.5262L2.33367 13.9063C2.36829 15.2028 2.97135 15.8793 4.13639 15.8793H15.5453C16.957 15.8793 17.6608 15.1509 17.6012 13.7884ZM3.97539 16.4708H16.0252C17.5498 16.4708 18.3337 15.6927 18.3337 14.1912V5.62114C18.3337 4.11832 17.5498 3.33582 16.0252 3.33582H3.97539C2.45654 3.33582 1.66699 4.11832 1.66699 5.62114V14.1912C1.66699 15.6927 2.45654 16.4708 3.97539 16.4708ZM4.05533 15.092C3.40103 15.092 3.04566 14.7533 3.04566 14.0696V5.74147C3.04566 5.05775 3.40103 4.71449 4.05533 4.71449H15.9453C16.5938 4.71449 16.9549 5.05775 16.9549 5.74147V14.0696C16.9549 14.7533 16.5938 15.092 15.9453 15.092H4.05533Z"
        fill="currentColor"
      />
      <path
        d="M7.11857 10.0311C8.04026 10.0311 8.80012 9.27118 8.80012 8.34118C8.80012 7.41948 8.04026 6.65259 7.11857 6.65259C6.18856 6.65259 5.42871 7.41948 5.42871 8.34118C5.42871 9.27118 6.18856 10.0311 7.11857 10.0311Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CropIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8061 14.1048C18.8061 13.6314 18.4756 13.3134 17.9632 13.3134H7.10742C6.94218 13.3134 6.88007 13.2513 6.88007 13.0781V2.18606C6.88007 1.66926 6.51953 1.32532 6.0416 1.32532C5.55215 1.32532 5.19962 1.66926 5.19962 2.18606V14.1935C5.19962 14.6167 5.47911 14.8962 5.90234 14.8962H17.9632C18.4756 14.8962 18.8061 14.5702 18.8061 14.1048ZM1.66699 5.69328C1.66699 6.16672 1.99746 6.48468 2.50976 6.48468H13.3657C13.5389 6.48468 13.601 6.54679 13.601 6.71203V17.6121C13.601 18.1288 13.9535 18.4727 14.4394 18.4727C14.9209 18.4727 15.2814 18.1288 15.2814 17.6121V5.59661C15.2814 5.17337 15.0019 4.89388 14.5787 4.89388H2.50976C1.99746 4.89388 1.66699 5.21985 1.66699 5.69328Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0514 14.7583C8.33904 14.7583 8.52442 14.576 8.5176 14.3076L8.30846 7.15147C8.3004 6.88554 8.11256 6.70889 7.83734 6.70889C7.55216 6.70889 7.36801 6.89114 7.37483 7.1583L7.57837 14.3113C7.58643 14.5828 7.7755 14.7583 8.0514 14.7583ZM10.062 14.7583C10.3448 14.7583 10.5438 14.5785 10.5438 14.3126V7.15584C10.5438 6.88991 10.3448 6.70889 10.062 6.70889C9.77807 6.70889 9.58463 6.88991 9.58463 7.15584V14.3126C9.58463 14.5785 9.77807 14.7583 10.062 14.7583ZM12.077 14.7583C12.3485 14.7583 12.5363 14.5828 12.5444 14.3113L12.748 7.1583C12.7548 6.89114 12.5706 6.70889 12.2854 6.70889C12.0102 6.70889 11.8224 6.88554 11.8143 7.15707L11.6108 14.3076C11.6039 14.576 11.7893 14.7583 12.077 14.7583ZM6.96105 4.80696H8.22081V3.38924C8.22081 3.0358 8.46804 2.80905 8.84811 2.80905H11.2635C11.6435 2.80905 11.8908 3.0358 11.8908 3.38924V4.80696H13.1506V3.3259C13.1506 2.2821 12.4843 1.66669 11.3533 1.66669H8.75828C7.62725 1.66669 6.96105 2.2821 6.96105 3.3259V4.80696ZM3.93708 5.5496H16.1913C16.5276 5.5496 16.7898 5.28244 16.7898 4.94607C16.7898 4.61215 16.5276 4.35059 16.1913 4.35059H3.93708C3.60631 4.35059 3.33301 4.61215 3.33301 4.94607C3.33301 5.28803 3.60631 5.5496 3.93708 5.5496ZM6.92719 17.0692H13.2012C14.2461 17.0692 14.9247 16.4295 14.9737 15.3834L15.4429 5.41868H14.1769L13.7281 15.1801C13.7145 15.5896 13.4443 15.8616 13.0551 15.8616H7.06207C6.68405 15.8616 6.41389 15.5828 6.39464 15.1801L5.92353 5.41868H4.67987L5.15468 15.389C5.20491 16.4351 5.87111 17.0692 6.92719 17.0692Z"
        fill="currentColor"
      />
    </svg>
  );
}
