export function ChevronIcon() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84586 7.00007C9.83833 6.7364 9.74039 6.51039 9.53699 6.30699L3.67594 0.574009C3.50267 0.408273 3.29926 0.317871 3.05066 0.317871C2.54592 0.317871 2.15417 0.709612 2.15417 1.21436C2.15417 1.45543 2.25211 1.68143 2.42538 1.8547L7.69882 7.00007L2.42538 12.1454C2.25211 12.3187 2.15417 12.5372 2.15417 12.7858C2.15417 13.2905 2.54592 13.6823 3.05066 13.6823C3.29173 13.6823 3.50267 13.5919 3.67594 13.4261L9.53699 7.68562C9.74792 7.48975 9.84586 7.26374 9.84586 7.00007Z"
        fill="currentColor"
      />
    </svg>
  );
}
