export function SearchIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 8.62641C2.5 12.0077 5.24779 14.7542 8.62776 14.7542C9.88476 14.7542 11.0383 14.3679 12.0001 13.7158L15.4585 17.1804C15.675 17.3969 15.9618 17.5 16.2636 17.5C16.9043 17.5 17.3624 17.0158 17.3624 16.3827C17.3624 16.0849 17.2531 15.807 17.0462 15.6002L13.6144 12.1485C14.3329 11.1601 14.7555 9.94495 14.7555 8.62641C14.7555 5.24644 12.0077 2.5 8.62776 2.5C5.24779 2.5 2.5 5.24644 2.5 8.62641ZM4.09618 8.62641C4.09618 6.12613 6.12748 4.09618 8.62776 4.09618C11.1281 4.09618 13.158 6.12613 13.158 8.62641C13.158 11.1281 11.1281 13.158 8.62776 13.158C6.12748 13.158 4.09618 11.1281 4.09618 8.62641Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SearchOutlinedIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6.90112C2 9.60618 4.19823 11.8033 6.90221 11.8033C7.90781 11.8033 8.83066 11.4943 9.60007 10.9726L12.3668 13.7443C12.54 13.9175 12.7694 14 13.0109 14C13.5234 14 13.8899 13.6127 13.8899 13.1062C13.8899 12.8679 13.8025 12.6456 13.637 12.4802L10.8915 9.71883C11.4663 8.9281 11.8044 7.95596 11.8044 6.90112C11.8044 4.19715 9.60618 2 6.90221 2C4.19823 2 2 4.19715 2 6.90112ZM3.27694 6.90112C3.27694 4.9009 4.90198 3.27694 6.90221 3.27694C8.90245 3.27694 10.5264 4.9009 10.5264 6.90112C10.5264 8.90245 8.90245 10.5264 6.90221 10.5264C4.90198 10.5264 3.27694 8.90245 3.27694 6.90112Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.7603 7.06478C5.42 6.72448 5.40379 6.1168 5.7684 5.7684C6.1168 5.40379 6.73258 5.42 7.07288 5.7603L11.0025 9.68995L14.9322 5.7603C15.2806 5.4119 15.8802 5.40379 16.2286 5.7684C16.5932 6.1168 16.5851 6.72448 16.2367 7.07288L12.307 11.0025L16.2367 14.9241C16.5851 15.2806 16.5932 15.8802 16.2286 16.2286C15.8802 16.5932 15.2806 16.5851 14.9322 16.2367L11.0025 12.307L7.07288 16.2367C6.73258 16.577 6.1168 16.5932 5.7684 16.2286C5.40379 15.8802 5.42 15.2725 5.7603 14.9322L9.68995 11.0025L5.7603 7.06478Z"
        fill="currentColor"
      />
    </svg>
  );
}
