import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatDetailStore } from '@/modules/chat/Detail/store';

import cls from './Messages.module.scss';

type Props = {
  id: number;
};

export const Unread: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const setUnreadMsgId = useChatDetailStore((s) => s.setUnreadMsgId);

  useEffect(() => {
    setUnreadMsgId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <p className={cls.unread}>{t('chat.messages.unread')}</p>;
};
