import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { isVideoContent } from '@/modules/chat/Detail/Messages/Message/helpers';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatMessageContent } from '@/modules/chat/types';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { secondsToHHMMSS } from '@/utils/date';

import cls from './ContentMedia.module.scss';
import { PlayIcon } from './icons';

type Props = {
  content: ChatMessageContent[];
};

export const ContentMedia: React.FC<Props> = ({ content }) => {
  const imgPlaceholder = useImgPlaceholder();

  const setPreviewUrl = useChatDetailStore((s) => s.setPreviewUrl);
  const setPreviewOpen = useChatDetailStore((s) => s.setPreviewOpen);

  const onPreviewClick = (url: string) => {
    return () => {
      setPreviewUrl(url);
      setPreviewOpen(true);
    };
  };

  return (
    <div className={cls.root}>
      <ul className={cls.list} data-count={content.length}>
        {content.map((c) => {
          const isVideo = isVideoContent(c.type);
          const imgSrc = isVideo ? c.thumbnail_url : c.thumbnail_url || c.url;

          return (
            <li key={c.url}>
              <button
                className={cls.preview}
                type="button"
                onClick={onPreviewClick(c.url as string)}
              >
                <ImgSkeleton
                  className={cls.preview_img}
                  src={imgSrc || imgPlaceholder}
                  alt={c.name || ''}
                  imgProxyWidth={546}
                  imgProxyHeight={420}
                />

                {isVideo && (
                  <>
                    <span className={cls.play_icon}>
                      <PlayIcon />
                    </span>

                    {c.duration && (
                      <span className={cls.time}>
                        {secondsToHHMMSS(c.duration)}
                      </span>
                    )}
                  </>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
