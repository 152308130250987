export function CheckIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2292 20.9999C10.7543 20.9999 11.1685 20.7808 11.4563 20.3417L20.9474 5.54581C21.1601 5.213 21.2453 4.93452 21.2453 4.66048C21.2453 3.96517 20.7564 3.48535 20.0538 3.48535C19.5554 3.48535 19.266 3.65297 18.9637 4.12754L10.1889 18.068L5.66703 12.2539C5.3728 11.8642 5.06403 11.701 4.62782 11.701C3.90687 11.701 3.40039 12.2057 3.40039 12.9027C3.40039 13.2022 3.51227 13.5048 3.7641 13.8107L8.99555 20.356C9.34392 20.7917 9.72054 20.9999 10.2292 20.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
