import { Price } from '@/modules/showroom/advert/create/Price/Price';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function PriceWrap() {
  const price = useUpdateAdvertStore((s) => s.price);
  const setPrice = useUpdateAdvertStore((s) => s.setPrice);
  const isPriceFilled = useUpdateAdvertStore((s) => s.isPriceFilled);
  const setPriceFilled = useUpdateAdvertStore((s) => s.setPriceFilled);

  const discount = useUpdateAdvertStore((s) => s.discount);
  const setDiscount = useUpdateAdvertStore((s) => s.setDiscount);
  const discountTypes = useUpdateAdvertStore((s) => s.discountTypes);
  const setDiscountTypes = useUpdateAdvertStore((s) => s.setDiscountTypes);

  return (
    <Price
      price={price}
      setPrice={setPrice}
      isPriceFilled={isPriceFilled}
      setPriceFilled={setPriceFilled}
      discount={discount}
      setDiscount={setDiscount}
      discountTypes={discountTypes}
      setDiscountTypes={setDiscountTypes}
    />
  );
}
