import cn from 'classnames';

import cls from './EditorLayout.module.scss';

type Props = {
  children: React.ReactNode;
  center?: boolean;
};

export const EditorSections: React.FC<Props> = ({ children, center }) => {
  return (
    <div className={cn(cls.sections, { [cls.sections_center]: center })}>
      {children}
    </div>
  );
};
