import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { TransportGeneration } from '@/modules/transport/types';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';

import cls from './GenerationSelect.module.scss';

type Props = {
  generation: TransportGeneration;
  isActive: boolean;
  onClick: (gen: TransportGeneration) => void;
};

export function GenerationItem({ generation, isActive, onClick }: Props) {
  const imgPlaceholder = useImgPlaceholder();

  return (
    <div className={cls.gen_btn}>
      <button className={cls.overlay_btn} onClick={() => onClick(generation)} />
      <ImgSkeleton
        className={cls.gen_btn_img}
        src={generation.photo_url || imgPlaceholder}
        alt=""
        imgProxyWidth={235}
        imgProxyHeight={173}
      />
      <span className={cls.list_icon}>
        <Checkbox
          id={generation.id.toString()}
          checked={isActive}
          onChange={() => null}
        />
      </span>

      <span>{generation.name}</span>
      <div className={cls.gen_btn_height} />
    </div>
  );
}
