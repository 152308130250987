import { axios } from '@/app/api';
import { REVIEWS_API_ROUTES } from '@/modules/reviews/api';
import {
  CreateNewReviewReplyReq,
  DeleteReplyReq
} from '@/modules/reviews/types';

export function createReviewReply(req: CreateNewReviewReplyReq) {
  return axios.post<{ result: boolean }>(
    REVIEWS_API_ROUTES.createNewReply,
    req
  );
}

export function deleteReply(params: DeleteReplyReq) {
  return axios.delete<{ result: boolean }>(REVIEWS_API_ROUTES.deleteMyReply, {
    params
  });
}
