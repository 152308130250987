import {
  ChatMessageAuthor,
  ChatMessageWithReplied,
  MessagesGroup
} from '@/modules/chat/types';
import { isEqualRoundedDate, isToday } from '@/utils/date';

export const LOAD_COUNT = 61;
export const MAX_MEDIA_CONTENT_LEN = 10;
export const MAX_CHAT_TEXT_LEN = 5000;

export enum ScrollState {
  initial = 'initial',
  scroll = 'scroll',
  stopped = 'stopped'
}

type GetDateDividerProps = {
  messages: ChatMessageWithReplied[];
  msg: ChatMessageWithReplied;
  i: number;
  todayStr?: string;
  lang?: string;
};

export function getDateDivider({
  messages,
  msg,
  i,
  todayStr,
  lang
}: GetDateDividerProps): {
  date: string;
  show: boolean;
} {
  const msgDate = new Date(msg.created_at * 1000);
  const prevMsgDate =
    i > 0
      ? new Date(messages[i - 1].created_at * 1000)
      : new Date(msg.created_at * 1000);

  const show = i === 0 || !isEqualRoundedDate(msgDate, prevMsgDate);
  const isMsgDateToday = isToday(msgDate);

  let date = '';
  if (todayStr && lang) {
    date = isMsgDateToday
      ? todayStr
      : msgDate.toLocaleString(lang, {
          month: 'long',
          day: 'numeric'
        });
  }

  return { date, show };
}

export function isMessageHasLargeRadius(
  messages: ChatMessageWithReplied[],
  msg: ChatMessageWithReplied,
  i: number
): boolean {
  const isFirst = i === 0;

  const msgDate = getDateDivider({ messages, msg, i });
  const isFirstInDay = !!msgDate && msgDate.show;

  const prevMsg = isFirst ? null : messages[i - 1];
  const byAuthor = !!prevMsg && prevMsg.author !== msg.author;

  return isFirst || byAuthor || isFirstInDay;
}

export function showMessageTail(
  messages: ChatMessageWithReplied[],
  msg: ChatMessageWithReplied,
  i: number
): boolean {
  const isLast = i === messages.length - 1;

  const nextMsg = isLast ? null : messages[i + 1];
  const nextMsgDate = nextMsg
    ? getDateDivider({ messages, msg: nextMsg, i: i + 1 })
    : null;
  const isLastInDay = !!nextMsgDate && nextMsgDate.show;
  const byAuthor = !!nextMsg && nextMsg.author !== msg.author;
  return isLast || byAuthor || isLastInDay;
}

export function showMessageUnread(
  messages: ChatMessageWithReplied[],
  msg: ChatMessageWithReplied,
  i: number
): boolean {
  const isFirst = i === 0;
  const prevMsg = isFirst ? null : messages[i - 1];

  const isOwnMsg = msg.author === ChatMessageAuthor.my;

  if (
    !isOwnMsg &&
    !msg.is_read &&
    !msg.is_from_pull &&
    prevMsg &&
    prevMsg.is_read
  ) {
    return true;
  }

  return false;
}

export function isMessageHasMargin(
  messages: ChatMessageWithReplied[],
  msg: ChatMessageWithReplied,
  i: number
): boolean {
  return showMessageTail(messages, msg, i);
}

export function groupMessages(
  messages: ChatMessageWithReplied[],
  todayStr: string,
  lang: string
): MessagesGroup[] {
  return messages.reduce<MessagesGroup[]>((acc, msg, i) => {
    const { show, date } = getDateDivider({
      messages,
      msg,
      i,
      lang,
      todayStr
    });
    if (show || acc.length <= 0) {
      const g = {
        date,
        messages: [{ ...msg, index: i }]
      };

      return [...acc, g];
    }

    const lastGroup = acc[acc.length - 1];
    const g = { ...lastGroup };

    g.messages.push({ ...msg, index: i });
    return [...acc.slice(0, -1), g];
  }, []);
}

export function sortMessagesByDate(
  messages: ChatMessageWithReplied[]
): ChatMessageWithReplied[] {
  return [...messages].sort((a, b) => {
    const timeA = a.created_at;
    const timeB = b.created_at;
    return timeA - timeB;
  });
}
