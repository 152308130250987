import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { UploadItem } from '@/modules/files/upload/helpers';
import { UploadImages } from '@/modules/files/upload/UploadImages/UploadImages';
import { Actions } from '@/modules/showroom/ShowroomEdit/CoverLogo/Cover/Actions/Actions';
import { CropperHeader } from '@/modules/showroom/ShowroomEdit/CoverLogo/Cover/CropperHeader';
import { Preview } from '@/modules/showroom/ShowroomEdit/CoverLogo/Cover/Preview/Preview';
import { UploadBtn } from '@/modules/showroom/ShowroomEdit/CoverLogo/Cover/UploadBtn/UploadBtn';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';

import cls from './Cover.module.scss';

const COVER_FILE_SIZE_MB = 20;
const MAX_IMG_SIZE = 1920;
const ASPECT = 2.15;

type Props = {
  name: string;
  logoUrl: string;
  coverUrl: string;
  setCoverUrl: (v: string) => void;
};

export function Cover({ name, logoUrl, coverUrl, setCoverUrl }: Props) {
  const { t } = useTranslation();

  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isActionPreviewOpen, setActionPreviewOpen] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(coverUrl);
  const [cropResetKey, setCropResetKey] = useState(0);

  const [isUploadOpen, setUploadOpen] = useState(false);
  const openUploadFn = useRef<Nullable<() => void>>(null);

  const onUpload = (v: UploadItem[]) => {
    if (v[0]) {
      setPreviewOpen(false);

      const url = v[0].response.url;
      setCoverUrl(url);
      setPreviewSrc(url);
    }
  };

  const onCropBackClick = () => {
    setCropResetKey((p) => p + 1);
    setUploadOpen(true);
  };

  const onCropCloseClick = () => {
    setCropResetKey((p) => p + 1);
  };

  const openPreview = () => {
    setPreviewOpen(true);
  };

  const onPreviewBackClick = () => {
    setPreviewOpen(false);
  };

  const onPreviewConfirmClick = () => {
    setPreviewOpen(false);
    setCoverUrl(previewSrc);
  };

  const onActionDeleteClick = () => {
    setPreviewSrc('');
    setCoverUrl('');
  };

  const onActionUploadClick = () => {
    setUploadOpen(true);
  };

  const onActionPreviewClick = () => {
    setActionPreviewOpen(true);
    setPreviewSrc(coverUrl);
    openPreview();
  };

  return (
    <div className={cls.root}>
      {coverUrl && (
        <div className={cls.cover}>
          <ImgSkeleton
            className={cls.cover_img}
            src={coverUrl}
            alt="Cover"
            imgProxyWidth={720}
            imgProxyHeight={335}
          />
          <Actions
            onDeleteClick={onActionDeleteClick}
            onUploadClick={onActionUploadClick}
            onPreviewClick={onActionPreviewClick}
          />
        </div>
      )}

      <UploadImages
        emitOpenFn={(fn) => {
          openUploadFn.current = fn;
        }}
        onUpload={onUpload}
        maxCropSize={MAX_IMG_SIZE}
        maxFileSizeMb={COVER_FILE_SIZE_MB}
        multiple={false}
        uploadBtn={
          <>
            {!coverUrl && (
              <div className={cls.upload_btn}>
                <UploadBtn onClick={() => setUploadOpen(true)} />
              </div>
            )}
          </>
        }
        resetKey={cropResetKey}
        cropperModalHeader={
          <CropperHeader
            title={t('cover.cropper.title')}
            text={t('cover.cropper.text')}
            onBackClick={onCropBackClick}
            onCloseClick={onCropCloseClick}
          />
        }
        cropperConfirmText={t('cover.setup') as string}
        cropperCancelText={t('cover.preview.title') as string}
        onCropCancel={openPreview}
        onCropChange={setPreviewSrc}
        aspect={ASPECT}
        crop
      />

      <AdaptiveModal
        name="showroom-cover-upload"
        isOpen={isUploadOpen}
        close={() => setUploadOpen(false)}
      >
        <div className={cls.upload_root}>
          <h2>{t('cover.title')}</h2>
          <p>{t('cover.description')}</p>
          <div className={cls.hr} />
          <h3>{t('cover.params.title')}</h3>
          <p>{t('cover.params.text')}</p>
          <div className={cls.upload_modal_btn}>
            <UploadBtn
              onClick={() => {
                setUploadOpen(false);
                if (openUploadFn.current) openUploadFn.current();
              }}
            />
          </div>
        </div>
      </AdaptiveModal>

      {previewSrc && (
        <Preview
          name={name}
          logoUrl={logoUrl}
          src={previewSrc}
          isOpen={isPreviewOpen}
          close={() => {
            setPreviewOpen(false);
            setActionPreviewOpen(false);
          }}
          onBackClick={onPreviewBackClick}
          onConfirmClick={onPreviewConfirmClick}
          hideBackButton={isActionPreviewOpen}
        />
      )}
    </div>
  );
}
