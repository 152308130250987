import { RefObject, useEffect, useState } from 'react';

export const useDetectVisible = (
  ref: RefObject<HTMLDivElement>,
  observerOptions: IntersectionObserverInit = { threshold: [1] }
) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    const el = ref?.current;
    const observer = new IntersectionObserver(([e]) => {
      setIsSticky(e.isIntersecting);
    }, observerOptions);

    if (el) {
      observer.observe(el);
    }

    return () => {
      el && observer.unobserve(el);
    };
  }, [ref, observerOptions]);

  return isSticky;
};
