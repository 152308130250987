import { useMemo, useState } from 'react';

import { useLang } from '@/hooks/useLang';
import { SearchMap } from '@/modules/chat/Detail/Form/Attach/SearchMap/SearchMap';
import { useSnapshotUrl } from '@/modules/snapshot/api';
import { SnapAnnotation, SnapshotColorScheme } from '@/modules/snapshot/types';
import { useDarkmode } from '@/modules/theme/useTheme';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './ContentLoc.module.scss';

type Props = {
  lat: number;
  long: number;
};

export const ContentLoc: React.FC<Props> = ({ lat, long }) => {
  // Modal
  const [isOpen, setOpen] = useState(false);

  // Snap props
  const [lang] = useLang();
  const { isDark } = useDarkmode();

  const req = useMemo(() => {
    const center = `${lat},${long}`;
    const marker: SnapAnnotation = { point: center };
    const annotations = JSON.stringify([marker]);
    return {
      lang,
      center,
      annotations,
      z: 16.5,
      colorScheme: isDark ? SnapshotColorScheme.dark : SnapshotColorScheme.light
    };
  }, [lat, long, lang, isDark]);
  const [snapshot, loading] = useSnapshotUrl(req);

  return (
    <div className={cls.root}>
      {snapshot && !loading && (
        <button
          className={cls.open_btn}
          type="button"
          onClick={() => setOpen(true)}
        >
          <img className={cls.snapshot} src={snapshot} alt="" />
        </button>
      )}

      {loading && (
        <div className={cls.spinner}>
          <Spinner size={24} color="var(--clr-primary)" />
        </div>
      )}

      {isOpen && (
        <SearchMap
          isOpen={isOpen}
          close={() => setOpen(false)}
          initialCoords={[lat, long]}
          mapOnly
        />
      )}
    </div>
  );
};
