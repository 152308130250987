import { UpdateActivity } from '@/modules/activity/UpdateActivity';
import { useAuthed } from '@/modules/auth/hooks';
import { Alert } from '@/ui/Alert/Alert';

export function Globals() {
  const [isAuthed, , auth] = useAuthed();

  return (
    <>
      <Alert />
      {isAuthed && auth && auth.access_token && (
        <UpdateActivity key={auth.access_token} authToken={auth.access_token} />
      )}
    </>
  );
}
