import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axios } from '@/app/api';
import { AdvertEngagementSmallRes } from '@/modules/showroom/advert/my/types';
import {
  GetExistedRentStatusesRes,
  RentAdvertStatusId
} from '@/modules/showroom/advert/rent/my/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { showAlert } from '@/utils/network';

export function getRentAdvertStatsEngagement(id: number) {
  return axios.get<AdvertEngagementSmallRes>(
    `/advertisements/rent/${id}/stats/engagement`
  );
}

export function getRentAdsStatuses() {
  return axios.get<GetExistedRentStatusesRes>(
    '/advertisements/rent/count-by-status'
  );
}

export function useRentAdsStatuses(
  refreshKey?: number,
  onLoad?: (res: GetExistedRentStatusesRes) => void
): [GetExistedRentStatusesRes | undefined, boolean] {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetExistedRentStatusesRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getRentAdsStatuses();
      const list = r.data;

      const hasPublished = list.find(
        (v) => v.status === AdvertStatusType.published
      );

      const res = hasPublished
        ? list
        : [
            {
              id: RentAdvertStatusId.published,
              status: AdvertStatusType.published,
              title: t('advertisements.statusType.onSale'),
              count: 0
            },
            ...list
          ];

      setData(res);
      if (onLoad) onLoad(res);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [t, onLoad]);

  useEffect(() => {
    load();
  }, [load, refreshKey]);

  return [data, isLoading];
}
