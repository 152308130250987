import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { getOfferSendAvailability } from '@/modules/offers/api';
import { Button } from '@/ui/Button/Button';
import { formatUnixTime } from '@/utils/date';
import { pluralKey } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './OfferSendButton.module.scss';

type Props = {
  advertId: number;
  onClickSend: () => Promise<void>;
  isRequiredParamsSetted: boolean;
  isParamsLoading?: boolean;
};

export const OfferSendButton: React.FC<Props> = ({
  advertId,
  onClickSend,
  isRequiredParamsSetted,
  isParamsLoading
}) => {
  const { t } = useTranslation();

  const [isSendingAvailable, setSendingAvailable] = useState(true);
  const [receiversCount, setReceiversCount] = useState(0);
  const [availableTimeToSend, setAvailableTimeToSend] =
    useState<Nullable<number>>(null);
  const [isLoading, setLoading] = useState(false);

  const formattedAvailableTimeToSend = useMemo(() => {
    if (!availableTimeToSend) return null;

    return formatUnixTime(availableTimeToSend);
  }, [availableTimeToSend]);

  const loadOfferSendAvailability = async () => {
    setLoading(true);

    try {
      const { data } = await getOfferSendAvailability({
        advertisement_id: advertId
      });

      setSendingAvailable(data.sending_available);
      setReceiversCount(data.count_receivers || 0);
      setAvailableTimeToSend(data.soonest_available_time_to_send);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleSendOffersClick = async () => {
    await onClickSend();
    await loadOfferSendAvailability();
  };

  useEffect(() => {
    loadOfferSendAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertId]);

  return (
    <div className={cls.root}>
      <Button
        size="s"
        loading={isLoading || isParamsLoading}
        disabled={!isSendingAvailable || !isRequiredParamsSetted}
        onClick={handleSendOffersClick}
        fullWidth
      >
        {!!receiversCount &&
          pluralKey(
            receiversCount,
            t('offer.sendOfferPlural.one', {
              count: receiversCount
            }),
            t('offer.sendOfferPlural.few', {
              count: receiversCount
            }),
            t('offer.sendOfferPlural.many', {
              count: receiversCount
            })
          )}
      </Button>

      <div className={cls.hints}>
        <small>{t('offer.sendOnceAWeek')}</small>
        {formattedAvailableTimeToSend && (
          <small>
            {t('offer.nextSendAt', {
              date: formattedAvailableTimeToSend.date,
              time: formattedAvailableTimeToSend.time
            })}
          </small>
        )}
      </div>
    </div>
  );
};
