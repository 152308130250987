import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { CountryCodeType } from '@/modules/country-codes/types';
import { PhoneSendCodeChannel } from '@/modules/showroom/contact/types';
import { Button } from '@/ui/Button/Button';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { PhoneCodeConfirm } from '@/ui/PhoneConfirm/PhoneCodeConfirm/PhoneCodeConfirm';
import { validatePhoneInput } from '@/ui/PhoneInput/helpers';
import { PhoneInput } from '@/ui/PhoneInput/PhoneInput';
import { onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './PhoneConfirm.module.scss';

type Props = {
  onConfirm: (phone: string) => void;
  confirmPhoneSendCode: (
    phone: string,
    channel?: PhoneSendCodeChannel
  ) => Promise<unknown>;
  confirmPhone: (phone: string, code: string) => Promise<unknown>;
  supportEmailKey: string;
  hideCountries?: boolean;
};

export function PhoneConfirm({
  onConfirm,
  confirmPhoneSendCode,
  confirmPhone,
  hideCountries
}: Props) {
  const { t } = useTranslation();
  const [isPhoneConfirmOpen, setPhoneConfirmOpen] = useState(false);
  const [isPhoneConfirmed, setPhoneConfirmed] = useState(false);

  // Phone
  const [code, setCode] = useState<Nullable<CountryCodeType>>(null);
  const [phone, setPhone] = useState('');
  const [isMaskCorrect, setMaskCorrect] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  useEffect(() => {
    setIsPhoneValid(
      validatePhoneInput(code, phone, isMaskCorrect, hideCountries)
    );
  }, [code, hideCountries, isMaskCorrect, phone, setIsPhoneValid]);

  const [isCodeSending, setCodeSending] = useState(false);

  const sendCode = async () => {
    setCodeSending(true);

    try {
      const phoneValue = onlyNumbers(phone, '+');
      await confirmPhoneSendCode(phoneValue, PhoneSendCodeChannel.sms);
      setPhoneConfirmOpen(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setCodeSending(false);
    }
  };

  const handleCofirmModalClose = () => {
    if (isPhoneConfirmed) onConfirm(phone);

    setPhoneConfirmOpen(false);
  };

  return (
    <>
      <div className={cls.root}>
        <div className={cls.singlefield}>
          <PhoneInput
            code={code}
            setCode={setCode}
            value={phone}
            onChange={setPhone}
            label={t('phone')}
            inputMode="numeric"
            hideCountries={hideCountries}
            setMaskCorrect={setMaskCorrect}
          />
        </div>

        {isPhoneValid && (
          <div className={cls.singlefield}>
            <Button
              onClick={() => {
                sendCode();
              }}
              disabled={isCodeSending}
              loading={isCodeSending}
              color="blue"
              variant="tertiary"
              fullWidth
            >
              {t('sendSmsCode')}
            </Button>
          </div>
        )}
      </div>

      <AdaptiveModal
        name="phone-confirm"
        isOpen={isPhoneConfirmOpen}
        close={handleCofirmModalClose}
        containerClass={cn(cls.modal_container, cls.modal_container_imp)}
      >
        <PhoneCodeConfirm
          phone={phone}
          confirmPhone={confirmPhone}
          confirmPhoneSendCode={confirmPhoneSendCode}
          close={handleCofirmModalClose}
          isPhoneConfirmed={isPhoneConfirmed}
          setPhoneConfirmed={setPhoneConfirmed}
        />
      </AdaptiveModal>
    </>
  );
}
