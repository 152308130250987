export function SelectedIcon(): JSX.Element {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6612 18.3234C15.2123 18.3234 18.9997 14.5462 18.9997 9.99516C18.9997 5.44408 15.202 1.66699 10.6509 1.66699C6.11009 1.66699 2.33301 5.44408 2.33301 9.99516C2.33301 14.5462 6.12042 18.3234 10.6612 18.3234Z"
        fill="#007AFF"
      />
      <path
        d="M9.75332 13.9676C9.4334 13.9676 9.1754 13.8437 8.92773 13.5134L7.00822 11.1089C6.86374 10.9334 6.7915 10.7271 6.7915 10.5207C6.7915 10.0976 7.1011 9.757 7.53454 9.757C7.79254 9.757 7.99893 9.83956 8.22598 10.1492L9.71204 12.1306L13.0041 6.80551C13.1692 6.51655 13.4066 6.37207 13.6543 6.37207C14.067 6.37207 14.4386 6.64039 14.4386 7.08414C14.4386 7.29054 14.3251 7.50726 14.2012 7.69302L10.5273 13.5134C10.3312 13.8231 10.0629 13.9676 9.75332 13.9676Z"
        fill="white"
      />
    </svg>
  );
}
