import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import {
  GetVrpBaseInfoReq,
  GetVrpBaseInfoRes,
  GetVrpTypesReq,
  GetVrpTypesRes
} from '@/modules/showroom/advert/vrp/types';
import { showAlert } from '@/utils/network';

export function useVrpBaseInfo(
  req: Nullable<GetVrpBaseInfoReq>
): [GetVrpBaseInfoRes | undefined, boolean] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetVrpBaseInfoRes>();

  const load = useCallback(async () => {
    if (!req) return;

    setLoading(true);
    try {
      const r = await axios.get<GetVrpBaseInfoRes>('/vrp/base-info/list', {
        params: req
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [req]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, loading];
}

export function useVrpTypes(
  req: Nullable<GetVrpTypesReq>
): [GetVrpTypesRes | undefined, boolean] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetVrpTypesRes>();

  const load = useCallback(async () => {
    if (!req) return;

    setLoading(true);
    try {
      const r = await axios.get<GetVrpTypesRes>('/vrp/types/list', {
        params: req
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [req]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, loading];
}
