import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useBookings } from '@/modules/booking/api';
import {
  MyBookingsList,
  MyBookingsListSkeleton
} from '@/modules/booking/MyBookings/MyBookingsList/MyBookingsList';
import { BookingExistedStatus } from '@/modules/booking/types';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import cls from '@/modules/showroom/advert/search/SearchAdverts/SearchResult/SearchResult.module.scss';

type Props = {
  search: string;
};

export function MyBookingsResult({ search }: Props) {
  const { t } = useTranslation();

  const {
    status: _status,
    setNotFoundAds,
    refresh
  } = useContext(MyAdvertsContext);
  const status = _status as BookingExistedStatus;

  const { isLoading, ads, load } = useBookings(status, search);
  const noAds = !isLoading && !!ads && ads.length <= 0;

  useEffect(() => {
    setNotFoundAds(noAds);
  }, [noAds, setNotFoundAds]);

  useEffect(() => {
    if (status !== undefined) {
      load(0);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status?.title]);

  return (
    <div className={cls.root}>
      {ads && ads.length > 0 && (
        <MyBookingsList list={ads} onConfirm={refresh} />
      )}

      {isLoading && <MyBookingsListSkeleton />}

      {noAds && <p className={cls.empty_text}>{t('booking.bookingsEmpty')}</p>}
    </div>
  );
}
