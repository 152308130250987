export function LightIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9814 2.53251V3.387H14.161C13.4427 3.08978 12.712 2.80495 11.9814 2.53251ZM11.9814 4.11765V5.00929H16.6625C16.6378 4.69969 16.6006 4.40248 16.5634 4.11765H11.9814ZM11.9814 6.21053V7.10216H18.9659C18.9536 6.79257 18.9411 6.49536 18.9164 6.21053H11.9814ZM11.9814 8.29101V9.18266H20.6501C20.6501 8.88544 20.6377 8.58823 20.6254 8.29101H11.9814ZM11.9814 10.3839V11.2755H20.6749C20.6749 10.9783 20.6626 10.6811 20.6626 10.3839H11.9814ZM11.9814 12.4768V13.3684H20.6626C20.6626 13.0712 20.6749 12.774 20.6749 12.4768H11.9814ZM11.9814 14.5696V15.4613H20.6254C20.6377 15.164 20.6501 14.8669 20.6501 14.5696H11.9814ZM11.9814 16.6625V17.5417H18.9164C18.9411 17.257 18.9536 16.9597 18.9659 16.6625H11.9814ZM11.9814 18.9783V19.87H16.5634C16.6006 19.5851 16.6378 19.2879 16.6625 18.9783H11.9814ZM11.9814 20.6006V21.4551C12.712 21.1826 13.4427 20.8978 14.161 20.6006H11.9814ZM11.9938 21.9876C17.4551 21.9876 22 17.4551 22 11.9938C22 6.5325 17.4427 2 11.9814 2C6.5325 2 2 6.5325 2 11.9938C2 17.4551 6.54489 21.9876 11.9938 21.9876ZM11.9938 20.1052C7.49844 20.1052 3.90712 16.5016 3.90712 11.9938C3.90712 7.48607 7.48607 3.88235 11.9814 3.88235C16.4891 3.88235 20.1052 7.48607 20.1052 11.9938C20.1052 16.5016 16.5016 20.1052 11.9938 20.1052Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DarkIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0062 2.53251C11.2879 2.80495 10.5573 3.08978 9.839 3.387H12.0062V2.53251ZM12.0062 4.11765H7.43653C7.39938 4.40248 7.36223 4.69969 7.33745 5.00929H12.0062V4.11765ZM12.0062 6.21053H5.08359C5.05882 6.49536 5.04643 6.79257 5.02167 7.10216H12.0062V6.21053ZM12.0062 8.29101H3.37461C3.36223 8.58823 3.34984 8.88544 3.33745 9.18266H12.0062V8.29101ZM12.0062 10.3839H3.33745C3.32508 10.6811 3.32508 10.9783 3.32508 11.2755H12.0062V10.3839ZM12.0062 12.4768H3.32508C3.32508 12.774 3.32508 13.0712 3.33745 13.3684H12.0062V12.4768ZM12.0062 14.5696H3.33745C3.34984 14.8669 3.36223 15.164 3.37461 15.4613H12.0062V14.5696ZM12.0062 16.6625H5.02167C5.04643 16.9597 5.05882 17.257 5.08359 17.5417H12.0062V16.6625ZM12.0062 18.9783H7.33745C7.36223 19.2879 7.39938 19.5851 7.43653 19.87H12.0062V18.9783ZM12.0062 20.6006H9.839C10.5573 20.8978 11.2879 21.1826 12.0062 21.4551V20.6006ZM11.9938 21.9876C17.4551 21.9876 22 17.4551 22 11.9938C22 6.5325 17.4427 2 11.9814 2C6.5325 2 2 6.5325 2 11.9938C2 17.4551 6.54489 21.9876 11.9938 21.9876ZM11.9938 20.1052C7.49844 20.1052 3.90712 16.5016 3.90712 11.9938C3.90712 7.48607 7.48607 3.88235 11.9814 3.88235C16.4891 3.88235 20.1052 7.48607 20.1052 11.9938C20.1052 16.5016 16.5016 20.1052 11.9938 20.1052Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LangIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9969 21.2062C14.7257 21.2062 16.9435 17.2512 16.9435 12.0145C16.9435 6.75223 14.7353 2.79551 11.9969 2.79551C9.26018 2.79551 7.05032 6.75223 7.05032 12.0145C7.05032 17.2512 9.26812 21.2062 11.9969 21.2062ZM11.9969 4.05364C13.8676 4.05364 15.5647 7.77055 15.5647 12.0145C15.5647 16.1915 13.8676 19.9402 11.9969 19.9402C10.1279 19.9402 8.43078 16.1968 8.43078 12.0145C8.43078 7.77055 10.1279 4.05364 11.9969 4.05364ZM11.3261 2.92926V21.0241H12.6775V2.92926H11.3261ZM11.9969 15.3937C8.97255 15.3937 6.28171 16.1759 4.94595 17.4155L5.98265 18.2729C7.23401 17.2915 9.3705 16.7451 11.9969 16.7451C14.625 16.7451 16.7616 17.2915 18.0111 18.2729L19.0478 17.4155C17.7138 16.1759 15.023 15.3937 11.9969 15.3937ZM20.8687 11.3146H3.12689V12.666H20.8687V11.3146ZM11.9969 8.64504C15.023 8.64504 17.7138 7.86287 19.0478 6.62313L18.0111 5.76586C16.7616 6.73927 14.625 7.29362 11.9969 7.29362C9.37225 7.29362 7.23749 6.73927 5.98265 5.76586L4.94595 6.62313C6.28171 7.86287 8.97255 8.64504 11.9969 8.64504ZM11.9969 21.9938C17.4739 21.9938 21.9955 17.4659 21.9955 11.9969C21.9955 6.51997 17.4659 2 11.989 2C6.52171 2 2 6.51997 2 11.9969C2 17.4659 6.52965 21.9938 11.9969 21.9938ZM11.9969 20.4442C7.3782 20.4442 3.55136 16.6174 3.55136 11.9969C3.55136 7.37647 7.37027 3.54961 11.989 3.54961C16.6094 3.54961 20.4442 7.37647 20.4442 11.9969C20.4442 16.6174 16.6174 20.4442 11.9969 20.4442Z"
        fill="currentColor"
      />
    </svg>
  );
}
