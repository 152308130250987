import { formatDate, isThisWeek, getWeekName, isToday } from '@/utils/date';

export function getMessageDateString(date: Date, lang: string): string {
  const today = isToday(date);
  if (today) return formatDate(date, 'p', lang);

  const thisWeek = isThisWeek(date);
  if (thisWeek) return getWeekName(date, lang, 'short');

  return date.toLocaleString(lang, { day: 'numeric', month: 'numeric' });
}
