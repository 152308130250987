import { create } from 'zustand';

import { Nullable } from '@/app/types';
import { emptyAutoFilter } from '@/modules/showroom/advert/search/consts';
import {
  AutoFilterIds,
  AutoFiltersSetter
} from '@/modules/showroom/advert/search/types';

export interface RentSearchStore {
  categoryId: Nullable<number>;
  setCategoryId: (category: number) => void;
  autoFilters: AutoFilterIds[];
  setAutoFilters: (setter: AutoFiltersSetter) => void;
  updateAutoFilter: (v: AutoFilterIds) => void;
  sortId: Nullable<number>;
  setSortId: (sortId: Nullable<number>) => void;
  bodiesIds: Nullable<number[]>;
  setBodiesIds: (bodiesIds: Nullable<number[]>) => void;
  transmissionsIds: Nullable<number[]>;
  setTransmissionsIds: (transmissionsIds: Nullable<number[]>) => void;
  colorsIds: Nullable<number[]>;
  setColorsIds: (colorsIds: Nullable<number[]>) => void;
  priceMin: Nullable<number>;
  setPriceMin: (priceMin: Nullable<number>) => void;
  priceMax: Nullable<number>;
  setPriceMax: (priceMax: Nullable<number>) => void;
  countSeats: Nullable<number>;
  setCountSeats: (countSeats: Nullable<number>) => void;
  searchQuery: Nullable<string>;
  setSearchQuery: (searchQuery: Nullable<string>) => void;
  resetValues: (values?: Partial<RentSearchStore>) => void;
}

export const useRentSearchStore = create<RentSearchStore>((set, get) => ({
  categoryId: null,
  setCategoryId: (categoryId: Nullable<number>) => set({ categoryId }),
  autoFilters: [emptyAutoFilter],
  setAutoFilters: (setter: AutoFiltersSetter) => {
    const prev = get().autoFilters;
    set({ autoFilters: setter([...prev]) });
  },
  updateAutoFilter: (v: AutoFilterIds) => {
    const current = get().autoFilters.filter(
      (v) => v.brand_id !== emptyAutoFilter.brand_id
    )[0];

    if (current) {
      set({ autoFilters: [{ ...current, ...v }] });
    } else {
      set({ autoFilters: [v] });
    }
  },
  sortId: null,
  setSortId: (sortId: Nullable<number>) => set({ sortId }),
  bodiesIds: null,
  setBodiesIds: (bodiesIds: Nullable<number[]>) => set({ bodiesIds }),
  transmissionsIds: null,
  setTransmissionsIds: (transmissionsIds: Nullable<number[]>) =>
    set({ transmissionsIds }),
  colorsIds: null,
  setColorsIds: (colorsIds: Nullable<number[]>) => set({ colorsIds }),
  priceMin: null,
  setPriceMin: (priceMin: Nullable<number>) => set({ priceMin }),
  priceMax: null,
  setPriceMax: (priceMax: Nullable<number>) => set({ priceMax }),
  countSeats: null,
  setCountSeats: (countSeats: Nullable<number>) => set({ countSeats }),
  searchQuery: null,
  setSearchQuery: (searchQuery: Nullable<string>) => set({ searchQuery }),
  resetValues: (values?: Partial<RentSearchStore>) => {
    set({
      autoFilters: [emptyAutoFilter],
      sortId: null,
      bodiesIds: null,
      transmissionsIds: null,
      colorsIds: null,
      priceMin: null,
      priceMax: null,
      countSeats: null,
      searchQuery: null,
      ...values
    });
  }
}));
