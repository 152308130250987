import cn from 'classnames';

import { BaseLayout } from '@/ui/layouts/BaseLayout/BaseLayout';

import cls from './EditorLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

export function EditorLayout({ children }: Props) {
  return (
    <BaseLayout>
      <div className={cls.root}>
        <div className={cn('container', cls.container)}>{children}</div>
      </div>
    </BaseLayout>
  );
}
