import { useEffect, useState } from 'react';

export function useOnceTrue(value: boolean) {
  const [onceTrue, setOnceTrue] = useState(value);

  useEffect(() => {
    if (value) setOnceTrue(true);
  }, [value]);

  return onceTrue;
}
