import { useMemo } from 'react';
import useWebSocket, { Options } from 'react-use-websocket';

import { ACITIVITY_ROUTES } from '@/modules/activity/api';

type Props = {
  authToken: string;
};

export const UpdateActivity = ({ authToken }: Props) => {
  const socketOptions = useMemo<Options>(
    () => ({
      shouldReconnect: () => true
    }),
    []
  );

  useWebSocket(ACITIVITY_ROUTES.setActive(authToken), socketOptions);
  return <></>;
};
