import { VinSuggest } from '@/modules/showroom/advert/create/VinSuggest/VinSuggest';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function VinSuggestWrap() {
  const setVinSuggestFilled = useUpdateAdvertStore(
    (s) => s.setVinSuggestFilled
  );
  const isDecoding = useUpdateAdvertStore((s) => s.isDecoding);
  const decodeVin = useUpdateAdvertStore((s) => s.decodeVin);

  const onSkipClick = () => {
    setVinSuggestFilled(true);
  };

  const onSubmitClick = (vin: string) => {
    decodeVin(vin);
  };

  return (
    <VinSuggest
      onSkipClick={onSkipClick}
      onSubmitClick={onSubmitClick}
      loading={isDecoding}
    />
  );
}
