export function DeleteIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05237 14.7586C8.34001 14.7586 8.5254 14.5763 8.51857 14.3079L8.30943 7.15178C8.30138 6.88585 8.11353 6.7092 7.83832 6.7092C7.55314 6.7092 7.36898 6.89145 7.3758 7.15861L7.57935 14.3116C7.58741 14.5832 7.77648 14.7586 8.05237 14.7586ZM10.063 14.7586C10.3457 14.7586 10.5448 14.5788 10.5448 14.3129V7.15615C10.5448 6.89022 10.3457 6.7092 10.063 6.7092C9.77905 6.7092 9.58561 6.89022 9.58561 7.15615V14.3129C9.58561 14.5788 9.77905 14.7586 10.063 14.7586ZM12.0779 14.7586C12.3495 14.7586 12.5373 14.5832 12.5454 14.3116L12.7489 7.15861C12.7557 6.89145 12.5716 6.7092 12.2864 6.7092C12.0112 6.7092 11.8233 6.88585 11.8153 7.15738L11.6118 14.3079C11.6049 14.5763 11.7903 14.7586 12.0779 14.7586ZM6.96203 4.80727H8.22179V3.38955C8.22179 3.03611 8.46902 2.80935 8.84908 2.80935H11.2645C11.6445 2.80935 11.8918 3.03611 11.8918 3.38955V4.80727H13.1515V3.3262C13.1515 2.2824 12.4853 1.66699 11.3543 1.66699H8.75926C7.62823 1.66699 6.96203 2.2824 6.96203 3.3262V4.80727ZM3.93806 5.5499H16.1922C16.5286 5.5499 16.7907 5.28274 16.7907 4.94637C16.7907 4.61245 16.5286 4.35089 16.1922 4.35089H3.93806C3.60729 4.35089 3.33398 4.61245 3.33398 4.94637C3.33398 5.28834 3.60729 5.5499 3.93806 5.5499ZM6.92817 17.0695H13.2021C14.247 17.0695 14.9257 16.4298 14.9747 15.3837L15.4439 5.41899H14.1778L13.7291 15.1804C13.7155 15.5899 13.4453 15.8619 13.0561 15.8619H7.06305C6.68503 15.8619 6.41487 15.5831 6.39561 15.1804L5.9245 5.41899H4.68085L5.15565 15.3893C5.20589 16.4354 5.87209 17.0695 6.92817 17.0695Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function EyeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.34342 8.9129C3.82214 8.9129 4.16123 8.56383 4.16123 8.08511V7.8258L3.98172 5.05319L5.85671 7.04787L8.17054 9.39163C8.33012 9.5512 8.52959 9.63099 8.749 9.63099C9.25765 9.63099 9.63664 9.29189 9.63664 8.78324C9.63664 8.54388 9.55685 8.32447 9.3873 8.16489L7.0635 5.84109L5.05884 3.96609L7.85139 4.14561H8.10073C8.57945 4.14561 8.94847 3.80652 8.94847 3.32779C8.94847 2.84907 8.57945 2.5 8.10073 2.5H3.96177C3.04422 2.5 2.51562 3.02859 2.51562 3.94614V8.08511C2.51562 8.55386 2.86469 8.9129 3.34342 8.9129ZM11.9006 17.5H16.0395C16.9572 17.5 17.4857 16.9714 17.4857 16.0539V11.9149C17.4857 11.4461 17.1366 11.0871 16.6579 11.0871C16.1892 11.0871 15.8401 11.4362 15.8401 11.9149V12.1742L16.0296 14.9468L14.1546 12.9521L11.8308 10.6084C11.6812 10.4488 11.4717 10.369 11.2523 10.369C10.7437 10.369 10.3647 10.7081 10.3647 11.2168C10.3647 11.4561 10.4545 11.6755 10.614 11.8351L12.9378 14.1589L14.9425 16.0339L12.1599 15.8544H11.9006C11.4218 15.8544 11.0629 16.1934 11.0528 16.6722C11.0528 17.1509 11.4218 17.5 11.9006 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function BinIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05237 14.7586C8.34001 14.7586 8.5254 14.5763 8.51857 14.3079L8.30943 7.15178C8.30138 6.88585 8.11353 6.7092 7.83832 6.7092C7.55314 6.7092 7.36898 6.89145 7.3758 7.15861L7.57935 14.3116C7.58741 14.5832 7.77648 14.7586 8.05237 14.7586ZM10.063 14.7586C10.3457 14.7586 10.5448 14.5788 10.5448 14.3129V7.15615C10.5448 6.89022 10.3457 6.7092 10.063 6.7092C9.77905 6.7092 9.58561 6.89022 9.58561 7.15615V14.3129C9.58561 14.5788 9.77905 14.7586 10.063 14.7586ZM12.0779 14.7586C12.3495 14.7586 12.5373 14.5832 12.5454 14.3116L12.7489 7.15861C12.7557 6.89145 12.5716 6.7092 12.2864 6.7092C12.0112 6.7092 11.8233 6.88585 11.8153 7.15738L11.6118 14.3079C11.6049 14.5763 11.7903 14.7586 12.0779 14.7586ZM6.96203 4.80727H8.22179V3.38955C8.22179 3.03611 8.46902 2.80935 8.84908 2.80935H11.2645C11.6445 2.80935 11.8918 3.03611 11.8918 3.38955V4.80727H13.1515V3.3262C13.1515 2.2824 12.4853 1.66699 11.3543 1.66699H8.75926C7.62823 1.66699 6.96203 2.2824 6.96203 3.3262V4.80727ZM3.93806 5.5499H16.1922C16.5286 5.5499 16.7907 5.28274 16.7907 4.94637C16.7907 4.61245 16.5286 4.35089 16.1922 4.35089H3.93806C3.60729 4.35089 3.33398 4.61245 3.33398 4.94637C3.33398 5.28834 3.60729 5.5499 3.93806 5.5499ZM6.92817 17.0695H13.2021C14.247 17.0695 14.9257 16.4298 14.9747 15.3837L15.4439 5.41899H14.1778L13.7291 15.1804C13.7155 15.5899 13.4453 15.8619 13.0561 15.8619H7.06305C6.68503 15.8619 6.41487 15.5831 6.39561 15.1804L5.9245 5.41899H4.68085L5.15565 15.3893C5.20589 16.4354 5.87209 17.0695 6.92817 17.0695Z"
        fill="currentColor"
      />
    </svg>
  );
}
