import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchMap } from '@/modules/chat/Detail/Form/Attach/SearchMap/SearchMap';
import { MAX_MEDIA_CONTENT_LEN } from '@/modules/chat/Detail/Messages/helpers';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatMessageContent } from '@/modules/chat/types';
import { UploadRes } from '@/modules/files/types';
import { UploadItem } from '@/modules/files/upload/helpers';
import { UploadFile } from '@/modules/files/upload/UploadFile';
import { UploadFiles } from '@/modules/files/upload/UploadFiles';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { Spinner } from '@/ui/Spinner/Spinner';
import { removeEmojis } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './Attach.module.scss';
import { mapUploadItems, MAX_ATTACH_IMG_FILE_SIZE_MB } from './helpers';
import { AttachIcon, MediaIcon, DocIcon, GeoIcon } from './icons';

enum AttachType {
  media = 'media',
  doc = 'document',
  loc = 'location'
}

type Props = {
  detailID: number;
  loading?: boolean;
};

export function Attach({ detailID, loading }: Props) {
  const { t } = useTranslation();

  const isAreaLoading = useChatDetailStore((s) => s.isAreaLoading);
  const attachedFiles = useChatDetailStore((s) => s.attachedFiles);
  const replyingAttachedFiles = useChatDetailStore(
    (s) => s.replyingAttachedFiles
  );
  const isMsgReplyOpen = useChatDetailStore((s) => s.isMsgReplyOpen);
  const replyingMsg = useChatDetailStore((s) => s.replyingMsg);
  const addReplyingAttachedFiles = useChatDetailStore(
    (s) => s.addReplyingAttachedFiles
  );
  const addAttachedFiles = useChatDetailStore((s) => s.addAttachedFiles);
  const sendFileMessage = useChatDetailStore((s) => s.sendFileMessage);
  const sendLocMessage = useChatDetailStore((s) => s.sendLocMessage);
  const setUploading = useChatDetailStore((s) => s.setUploading);

  // Dropdown
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const openDropdown = () => {
    setDropdownOpen(true);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  // Media upload
  const mediaAttachedFiles = isMsgReplyOpen
    ? replyingAttachedFiles
    : attachedFiles;
  const [mediaUploadKey, setMediaUploadKey] = useState(0);
  const [isMediaUploading, setMediaUploading] = useState(false);
  const [isMediaUploadOpen, setMediaUploadOpen] = useState(false);
  const onMediaAttach = () => {
    if (mediaAttachedFiles.length >= MAX_MEDIA_CONTENT_LEN) {
      showAlert({
        type: 'error',
        text: t('chat.error.maxAttached', { value: MAX_MEDIA_CONTENT_LEN })
      });
    } else {
      setMediaUploadKey((p) => p + 1);
      setMediaUploadOpen(true);
    }
  };

  const onMediaUpload = (uploaded: UploadItem[]) => {
    setMediaUploadOpen(false);
    const content = mapUploadItems(uploaded);
    if (isMsgReplyOpen) {
      addReplyingAttachedFiles(content);
    } else {
      addAttachedFiles(content);
    }
  };

  // Doc / file
  const [fileUploadKey, setFileUploadKey] = useState(0);
  const [isFileUploading, setFileUploading] = useState(false);
  const [isFileUploadOpen, setFileUploadOpen] = useState(false);

  const onDocAttach = () => {
    setFileUploadKey((p) => p + 1);
    setFileUploadOpen(true);
  };
  const onFileUpload = (r: UploadRes) => {
    setFileUploadOpen(false);
    if (isMsgReplyOpen && replyingMsg) {
      sendFileMessage(detailID, r, replyingMsg);
    } else {
      sendFileMessage(detailID, r);
    }
  };

  // Location
  const [isLocLoading, setLocLoading] = useState(false);
  const [isMapOpen, setMapOpen] = useState(false);
  const [coords, setCoords] = useState<ChatMessageContent['coords']>(null);
  const onLocAttach = () => {
    setLocLoading(true);

    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCoords({ lat: latitude, long: longitude });
        setMapOpen(true);
        setLocLoading(false);
      },
      (error) => {
        showAlert({
          type: 'error',
          text: error.message || t('chat.error.location')
        });
        setLocLoading(false);
      }
    );
  };

  const onLocSubmit = ([lat, long]: [number, number]) => {
    setMapOpen(false);
    setCoords({ lat, long });

    if (isMsgReplyOpen && replyingMsg) {
      sendLocMessage(detailID, lat, long, replyingMsg);
    } else {
      sendLocMessage(detailID, lat, long);
    }
  };

  // Other
  const onDropdownBtnClick = (type: AttachType) => {
    return () => {
      closeDropdown();

      if (type === AttachType.media) {
        onMediaAttach();
        return;
      }

      if (type === AttachType.doc) {
        onDocAttach();
        return;
      }

      if (type === AttachType.loc) {
        onLocAttach();
        return;
      }
    };
  };

  const attachLoading =
    loading || isLocLoading || isFileUploading || isMediaUploading;

  useEffect(() => {
    setUploading(attachLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachLoading]);

  return (
    <div className={cls.root}>
      <button
        className={cls.attach_btn}
        type="button"
        disabled={attachLoading || isAreaLoading}
        onClick={openDropdown}
      >
        {attachLoading ? (
          <span>
            <Spinner color="var(--clr-blue)" size={24} />
          </span>
        ) : (
          <AttachIcon />
        )}
      </button>

      <Dropdown
        name="chat-detail-attachment"
        isOpen={isDropdownOpen}
        close={closeDropdown}
        parentCls={cls.root}
        listTop
        withModal
        actionsModal
      >
        <DropdownItem>
          <button
            className={cls.dropdown_btn}
            type="button"
            onClick={onDropdownBtnClick(AttachType.media)}
          >
            <MediaIcon />
            {removeEmojis(t('chat.content.media'))}
          </button>
        </DropdownItem>
        <DropdownItem>
          <button
            className={cls.dropdown_btn}
            type="button"
            onClick={onDropdownBtnClick(AttachType.doc)}
          >
            <DocIcon />
            {removeEmojis(t('chat.content.file'))}
          </button>
        </DropdownItem>
        <DropdownItem>
          <button
            className={cls.dropdown_btn}
            type="button"
            onClick={onDropdownBtnClick(AttachType.loc)}
          >
            <GeoIcon />
            {removeEmojis(t('chat.content.location'))}
          </button>
        </DropdownItem>
      </Dropdown>

      {coords && isMapOpen && (
        <SearchMap
          isOpen={isMapOpen}
          close={() => setMapOpen(false)}
          initialCoords={[coords.lat, coords.long]}
          buttonText={t('chat.messages.sendLocation')}
          onSubmit={onLocSubmit}
        />
      )}

      {isMediaUploadOpen && (
        <UploadFiles
          key={`media-${mediaUploadKey}`}
          type="media"
          onUpload={onMediaUpload}
          onUploading={setMediaUploading}
          close={() => {
            setMediaUploadOpen(false);
            setMediaUploading(false);
          }}
          maxImgSizeMb={MAX_ATTACH_IMG_FILE_SIZE_MB}
          maxFilesLen={MAX_MEDIA_CONTENT_LEN}
          maxFileLenSlice={MAX_MEDIA_CONTENT_LEN - mediaAttachedFiles.length}
          maxLengthError={
            t('chat.error.maxAttached', {
              value: MAX_MEDIA_CONTENT_LEN
            }) as string
          }
          videoPoster
        />
      )}

      {isFileUploadOpen && (
        <UploadFile
          key={`file-${fileUploadKey}`}
          type="file"
          onUpload={onFileUpload}
          onUploading={setFileUploading}
          close={() => {
            setFileUploadOpen(false);
            setFileUploading(false);
          }}
        />
      )}
    </div>
  );
}
