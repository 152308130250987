export function StarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.89217 13.6765C4.16923 13.8869 4.51787 13.818 4.91722 13.5333L7.99943 11.3171L11.0843 13.5333C11.4858 13.818 11.8285 13.8869 12.1105 13.6765C12.3864 13.4719 12.4451 13.1358 12.2853 12.6794L11.0671 9.13832L14.1768 6.9532C14.5772 6.6753 14.7435 6.37233 14.6338 6.04509C14.5251 5.72632 14.2104 5.56954 13.7168 5.57638L9.90294 5.6006L8.74411 2.04003C8.59015 1.57479 8.34805 1.33331 7.99943 1.33331C7.65455 1.33331 7.41246 1.57479 7.25855 2.04003L6.09967 5.6006L2.28477 5.57638C1.79226 5.56954 1.47752 5.72632 1.36885 6.04405C1.25319 6.37233 1.42538 6.6753 1.8258 6.9532L4.93548 9.13832L3.71738 12.6794C3.55751 13.1358 3.61724 13.4719 3.89217 13.6765Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function MoreIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8686 14.254C21.0462 14.254 21.9999 13.307 21.9999 12.1313C21.9999 10.9537 21.0462 10 19.8686 10C18.6929 10 17.7373 10.9537 17.7373 12.1313C17.7373 13.307 18.6929 14.254 19.8686 14.254Z"
        fill="currentColor"
      />
      <path
        d="M12 14.254C13.1776 14.254 14.1227 13.307 14.1227 12.1313C14.1227 10.9537 13.1776 10 12 10C10.8224 10 9.86865 10.9537 9.86865 12.1313C9.86865 13.307 10.8224 14.254 12 14.254Z"
        fill="currentColor"
      />
      <path
        d="M4.13131 14.254C5.30701 14.254 6.254 13.307 6.254 12.1313C6.254 10.9537 5.30701 10 4.13131 10C2.95373 10 2 10.9537 2 12.1313C2 13.307 2.95373 14.254 4.13131 14.254Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronIcon(): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00006 9.46522L4.42685 6.48754C4.144 6.25183 3.72362 6.29005 3.48791 6.5729C3.2522 6.85575 3.29042 7.27613 3.57327 7.51184L7.57327 10.8452C7.8205 11.0512 8.17962 11.0512 8.42685 10.8452L12.4268 7.51184C12.7097 7.27613 12.7479 6.85575 12.5122 6.5729C12.2765 6.29005 11.8561 6.25183 11.5733 6.48754L8.00006 9.46522Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ReplyIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1894 3.10411C11.1192 3.16972 11.8531 3.94479 11.8531 4.89128L11.854 6.6555L11.871 6.65959C15.6479 7.44207 18.4365 10.7889 18.4365 14.7375C18.4365 15.0244 18.4218 15.3103 18.3928 15.5938C18.3236 16.2698 17.4631 16.5127 17.0506 15.9727L16.895 15.7756C15.6789 14.2869 13.9474 13.3103 12.0319 13.0545L11.854 13.033L11.8531 15.1116C11.8531 15.5121 11.7188 15.9013 11.4716 16.2166C10.8612 16.9954 9.73503 17.132 8.95623 16.5215L2.4365 11.4115C2.32296 11.3225 2.22059 11.2201 2.1316 11.1066C1.52119 10.3278 1.6577 9.20161 2.4365 8.59119L8.95623 3.48113C9.27162 3.23394 9.66076 3.09961 10.0615 3.09961L10.1894 3.10411ZM10.0615 4.59961C9.99624 4.59961 9.93289 4.62148 9.88155 4.66172L3.36182 9.77178C3.23504 9.87115 3.21282 10.0545 3.31219 10.1813C3.32667 10.1997 3.34334 10.2164 3.36182 10.2309L9.88155 15.341C10.0083 15.4403 10.1917 15.4181 10.291 15.2913C10.3313 15.24 10.3531 15.1766 10.3531 15.1114L10.354 12.2386C10.3541 11.8197 10.6974 11.4821 11.1163 11.4889L11.3106 11.4921L11.5958 11.5059C13.4909 11.6269 15.2597 12.3285 16.6946 13.4774L16.8398 13.5972L16.82 13.4844C16.2843 10.6382 13.9575 8.39657 11.013 8.03749C10.6368 7.99161 10.3539 7.67223 10.3538 7.29321L10.3531 4.89148C10.3531 4.73019 10.2226 4.59961 10.0615 4.59961Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ReportIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 1.42871C14.734 1.42871 18.5716 5.26627 18.5716 10.0001C18.5716 14.734 14.734 18.5716 10.0001 18.5716C5.26627 18.5716 1.42871 14.734 1.42871 10.0001C1.42871 5.26627 5.26627 1.42871 10.0001 1.42871ZM10.0001 2.85728C6.05525 2.85728 2.85728 6.05525 2.85728 10.0001C2.85728 13.945 6.05525 17.143 10.0001 17.143C13.945 17.143 17.143 13.945 17.143 10.0001C17.143 6.05525 13.945 2.85728 10.0001 2.85728ZM10.0527 8.57157C10.2224 8.57157 10.329 8.59809 10.4221 8.64789C10.5152 8.69769 10.5883 8.77078 10.6381 8.8639C10.6879 8.95702 10.7144 9.06355 10.7144 9.23327V13.6242C10.7144 13.7939 10.6879 13.9004 10.6381 13.9935C10.5883 14.0866 10.5152 14.1597 10.4221 14.2095C10.329 14.2593 10.2224 14.2859 10.0527 14.2859H9.94755C9.77784 14.2859 9.6713 14.2593 9.57818 14.2095C9.48506 14.1597 9.41198 14.0866 9.36218 13.9935C9.31238 13.9004 9.28585 13.7939 9.28585 13.6242V9.23327C9.28585 9.06355 9.31238 8.95702 9.36218 8.8639C9.41198 8.77078 9.48506 8.69769 9.57818 8.64789C9.6713 8.59809 9.77784 8.57157 9.94755 8.57157H10.0527ZM10.0001 5.42871C10.5524 5.42871 11.0001 5.87643 11.0001 6.42871C11.0001 6.981 10.5524 7.42871 10.0001 7.42871C9.44785 7.42871 9.00014 6.981 9.00014 6.42871C9.00014 5.87643 9.44785 5.42871 10.0001 5.42871Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DeleteIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 0.833008C11.8271 0.833008 13.0069 1.74641 13.3207 3.44691C13.324 3.46465 13.3303 3.48236 13.3328 3.50001L17.5832 3.49967C17.9974 3.49967 18.3332 3.83546 18.3332 4.24967C18.3332 4.63203 18.0471 4.94755 17.6772 4.99383L17.5832 4.99967H16.6657L15.5158 15.351C15.3848 16.5254 14.4263 17.4252 13.2608 17.4953L13.1141 17.4997H6.88562C5.65463 17.4997 4.62036 16.5744 4.48386 15.351L3.329 4.99967H2.4165C2.00229 4.99967 1.6665 4.66389 1.6665 4.24967C1.6665 3.86732 1.95262 3.5518 2.32243 3.50552L2.4165 3.49967L6.67673 3.50054C6.67465 3.50025 6.67256 3.49996 6.67047 3.49967C6.67252 3.48018 6.67534 3.46652 6.67896 3.44691C6.99274 1.74641 8.17255 0.833008 9.99984 0.833008ZM15.1607 4.99967H4.8365L5.97461 15.1847C6.01899 15.5824 6.31355 15.8971 6.69085 15.9789L6.7868 15.9944L6.88562 15.9997H13.1141C13.581 15.9997 13.9733 15.6487 14.0251 15.1847L15.1607 4.99967ZM9.99984 6.24967C10.3822 6.24967 10.6977 6.53579 10.744 6.9056L10.7498 6.99967V13.833C10.7498 14.2472 10.4141 14.583 9.99984 14.583C9.61749 14.583 9.30196 14.2969 9.25568 13.9271L9.24984 13.833V6.99967C9.24984 6.58546 9.58562 6.24967 9.99984 6.24967ZM12.8788 6.25106C13.2605 6.27434 13.558 6.57912 13.5817 6.95106L13.5818 7.04532L13.1651 13.8787C13.1399 14.2921 12.7843 14.6068 12.3709 14.5816C11.9892 14.5583 11.6917 14.2536 11.668 13.8816L11.6679 13.7874L12.0846 6.95403C12.1098 6.54058 12.4654 6.22585 12.8788 6.25106ZM7.90355 6.86048L7.91511 6.95403L8.33178 13.7874C8.35699 14.2008 8.04226 14.5564 7.62882 14.5816C7.24718 14.6049 6.91482 14.3385 6.84612 13.9722L6.83456 13.8787L6.41789 7.04532C6.39268 6.63188 6.70741 6.27627 7.12086 6.25106C7.5025 6.22779 7.83485 6.49417 7.90355 6.86048ZM9.99984 2.33301C8.96466 2.33301 8.42275 2.6885 8.20389 3.49786L8.19484 3.49967L11.7955 3.5004C11.7956 3.49982 11.7958 3.49923 11.796 3.49865C11.5773 2.68873 11.0354 2.33301 9.99984 2.33301Z"
        fill="currentColor"
      />
    </svg>
  );
}
