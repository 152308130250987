import { useTranslation } from 'react-i18next';

import { Description } from '@/modules/showroom/advert/create/Description/Description';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

export function DescriptionWrap() {
  const { t } = useTranslation();

  const description = useUpdateVrpStore((s) => s.description);
  const setDescription = useUpdateVrpStore((s) => s.setDescription);
  const isDescrGenerating = useUpdateVrpStore((s) => s.isDescrGenerating);
  const setDescrGenerating = useUpdateVrpStore((s) => s.setDescrGenerating);
  const isDescriptionFilled = useUpdateVrpStore((s) => s.isDescriptionFilled);
  const setDescriptionFilled = useUpdateVrpStore((s) => s.setDescriptionFilled);

  return (
    <Description
      nextSection={SCROLLTO.showroom}
      placeholder={t('advertisements.description.title')}
      value={description}
      onChange={setDescription}
      generating={isDescrGenerating}
      setGenerating={setDescrGenerating}
      isDescriptionFilled={isDescriptionFilled}
      setDescriptionFilled={setDescriptionFilled}
    />
  );
}
