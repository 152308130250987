import { useAccountStore } from '@/modules/accounts/store';
import { useAuthed } from '@/modules/auth/hooks';

export function useRedirectCheck() {
  const [isAuthed] = useAuthed();
  const accountInfo = useAccountStore((s) => s.accountInfo);

  // Confirm email
  const shouldConfirmEmail =
    isAuthed && accountInfo?.is_email_confirmed === false;

  // Showroom creation
  const shouldCreateShowroom =
    isAuthed && accountInfo?.showroom_created === false;

  const shouldLeaveShowroomCreate =
    isAuthed && accountInfo?.showroom_created === true;

  return {
    shouldConfirmEmail,
    shouldCreateShowroom,
    shouldLeaveShowroomCreate
  };
}
