import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PhotoType } from '@/app/types';
import { useSignupStore } from '@/modules/showroom/CreateShowroom/store';
import { MAX_SHOWROOM_PHOTOS_LEN } from '@/modules/showroom/ShowroomEdit/helpers';
import { Photos } from '@/modules/showroom/ShowroomEdit/Photos/Photos';
import { Button } from '@/ui/Button/Button';

import cls from './CreateShowroom.module.scss';
import { ArrowIcon } from './icons';

export function ShowroomPhoto() {
  const { t } = useTranslation();

  // Photos
  const photos = useSignupStore((s) => s.photos);
  const setPhotos = useSignupStore((s) => s.setPhotos);
  const addPhotos = (v: PhotoType[]) => {
    setPhotos([...photos, ...v]);
  };
  const removePhoto = (v: PhotoType['photo_url']) => {
    setPhotos(photos.filter((p) => p.photo_url !== v));
  };
  const replaceLocalPhoto = (uploaded: PhotoType, localUrl: string) => {
    setPhotos(photos.map((p) => (p.photo_url === localUrl ? uploaded : p)));
  };

  const navigate = useNavigate();
  const onPrevClick = () => {
    navigate('/showroom/create/username');
  };
  const onNextClick = () => {
    navigate('/showroom/create/contact');
  };

  return (
    <>
      <div className={cls.header}>
        <button className={cls.back_btn} type="button" onClick={onPrevClick}>
          <ArrowIcon />
        </button>
        <h2 className={cls.title}>
          <Trans
            t={t}
            i18nKey="showroom.photos.title"
            components={{
              countWrap: <span />
            }}
            values={{
              index: photos.length,
              count: MAX_SHOWROOM_PHOTOS_LEN
            }}
          />
        </h2>
      </div>
      <Photos
        photos={photos}
        setPhotos={setPhotos}
        addPhotos={addPhotos}
        removePhoto={removePhoto}
        replaceLocalPhoto={replaceLocalPhoto}
        fieldsOnly
        largePhotos
      />
      <div className={cls.btn}>
        <Button
          onClick={onNextClick}
          variant={photos.length ? 'primary' : 'secondary'}
          color={photos.length ? 'blue' : 'black'}
          fullWidth
        >
          {t(photos.length ? 'common.continue' : 'common.skip')}
        </Button>
      </div>
    </>
  );
}
