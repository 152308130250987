import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountStore } from '@/modules/accounts/store';
import { loginAccount } from '@/modules/auth/api';
import { useAuthStore } from '@/modules/auth/store';
import { changePasswordWithoutEmail } from '@/modules/showroom/settings/ChangePassword/api';
import { ChangePasswordReq } from '@/modules/showroom/settings/ChangePassword/types';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { Message } from '@/ui/Message/Message';
import { MIN_PASSWORD_LENGTH } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import cls from './ChangePassword.module.scss';

export function ChangePassword() {
  const { t } = useTranslation();

  const setAuth = useAuthStore((s) => s.setAuth);
  const accountInfo = useAccountStore((s) => s.accountInfo);
  const setAccountInfo = useAccountStore((s) => s.setAccountInfo);
  const email = accountInfo?.email || '';

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [loading, setLoading] = useState(false);

  const newPasswordValid =
    !!newPassword && newPassword.length >= MIN_PASSWORD_LENGTH;
  const isValid = !!oldPassword && newPasswordValid;
  newPassword === confirmPassword;

  const changePassword = async () => {
    if (!isValid) return;

    setLoading(true);
    const req: ChangePasswordReq = {
      current: oldPassword,
      new: newPassword
    };
    try {
      await changePasswordWithoutEmail(req);
      if (email) {
        const { data } = await loginAccount({ email, password: req.new });
        setAuth(data);
        setAccountInfo(data);
      }

      showAlert({
        type: 'success',
        text: t('settings.changePassword.success')
      });
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cls.root}>
      <Message text={[t('settings.changePassword.message')]} />

      <div className={cls.inputs}>
        <Input
          value={oldPassword}
          onChange={(e) => setOldPassword(e.currentTarget.value.trim())}
          type="password"
          placeholder={t('settings.changePassword.oldPassword')}
        />
        <Input
          value={newPassword}
          onChange={(e) => setNewPassword(e.currentTarget.value.trim())}
          type="password"
          placeholder={t('settings.changePassword.newPassword')}
        />
        <Input
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value.trim())}
          type="password"
          placeholder={t('settings.changePassword.confirmPassword')}
        />
      </div>

      <Button
        color="blue"
        disabled={!isValid || loading}
        onClick={changePassword}
        loading={loading}
        fullWidth
      >
        {t('settings.changePassword.save')}
      </Button>
    </div>
  );
}
