import { axios } from '@/app/api';

import {
  UpdateAdvertPromoRes,
  UpdateAdvertReq,
  UpdateAdvertRes,
  UpdateAdvertStickersReq
} from './types';

export function updateAdvertAutoExtension(id: number, status: boolean) {
  return axios.patch<UpdateAdvertPromoRes>(
    `/advertisements/promotion/${id}/package/auto-extension`,
    null,
    {
      params: {
        status
      }
    }
  );
}

export function updateAdvertPromoPackage(id: number, promoPackageId: number) {
  return axios.patch<UpdateAdvertPromoRes>(
    `/advertisements/promotion/${id}/package/${promoPackageId}`
  );
}

export function updateAdvertStickers(id: number, req: UpdateAdvertStickersReq) {
  return axios.patch<UpdateAdvertRes>(
    `/advertisements/promotion/${id}/sticker`,
    req
  );
}

export function updateAdvertisement(id: number, req: UpdateAdvertReq) {
  return axios.patch<UpdateAdvertRes>(
    `/dealers/showrooms/advertisements/${id}/update`,
    req
  );
}

export function deleteAdvertisement(id: number) {
  return axios.delete<unknown>(
    `/dealers/showrooms/advertisements/${id}/delete`
  );
}
