import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useTransportModels } from '@/modules/transport/api';
import { TransportBrand, TransportModel } from '@/modules/transport/types';
import { Input } from '@/ui/Input/Input';
import { Skeleton } from '@/ui/Skeleton';
import { MAX_PG_LIMIT } from '@/utils/consts';

import { ChevronIcon } from './icons';
import cls from './Model.module.scss';

const SHORT_COUNT = 23;
const skeletons = new Array(12).fill(0);

type Props = {
  brand: TransportBrand;
  model: Nullable<TransportModel>;
  onChange: (m: Nullable<TransportModel>) => void;
  disabled?: boolean;
};

export function Model({ brand, model, onChange, disabled }: Props) {
  const { t } = useTranslation();

  const [showList, setShowList] = useState(!model);
  const [isShort, setShort] = useState(true);
  const [search, setSearch] = useState(model?.name || '');

  const req = useMemo(
    () => ({ brand_id: brand.id, limit: MAX_PG_LIMIT }),
    [brand]
  );
  const [models, isLoading] = useTransportModels(req);

  const list = useMemo(() => {
    if (!models) return [];
    if (isShort) return models.slice(0, SHORT_COUNT);
    if (!search) return models;
    return models.filter((m) =>
      m.name.trim().toLowerCase().includes(search.trim().toLowerCase())
    );
  }, [models, isShort, search]);

  const toggleShort = () => {
    if (!isShort) setSearch('');
    setShort((p) => !p);
  };

  useEffect(() => {
    if (search && isShort) {
      setShort(false);
    }
  }, [search, isShort]);

  useEffect(() => {
    if (model && search !== model.name) {
      setSearch(model.name);
    }
  }, [model, search, showList]);

  const onSearchChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const v = e.currentTarget.value;
      setSearch(v);
      setShowList(true);
      onChange(null);
      if (!v && !isShort) {
        setShort(true);
      }
    },
    [isShort, onChange]
  );

  const onModelClick = useCallback(
    (m: TransportModel) => {
      return () => {
        onChange(m);
        setShowList(false);
        setSearch(m.name);
      };
    },
    [onChange]
  );

  const onFocus = () => {
    if (model) {
      setShowList(true);
      setSearch(model.name);
    }
  };

  useEffect(() => {
    if (models && models.length === 1) {
      const [first] = models;
      if (!model || model.id !== first.id) {
        onModelClick(first)();
      }
    }
  }, [model, models, onModelClick]);

  return (
    <div className={cls.root}>
      <div className={cls.field}>
        <Input
          value={search}
          onChange={onSearchChange}
          onFocus={onFocus}
          label={t('model')}
          disabled={disabled}
          onClear={search && !model ? () => setSearch('') : undefined}
        />
        {models && !model && search && list.length <= 0 && (
          <span className={cls.input_text}>{t('modelNotFound')}</span>
        )}
      </div>

      {showList && (
        <div
          className={cls.list_wrap}
          style={{
            display:
              !isLoading && models && models?.length <= 0 ? 'none' : 'block'
          }}
        >
          {models && !isLoading && (
            <ul className={cls.list}>
              {list.map((m) => (
                <li key={m.id}>
                  <button
                    className={cls.model_btn}
                    type="button"
                    onClick={onModelClick(m)}
                    disabled={disabled}
                  >
                    {m.name}
                  </button>
                </li>
              ))}

              {!search && models.length > SHORT_COUNT && (
                <li
                  className={cn(cls.toggler, {
                    [cls.toggler_opened]: !isShort
                  })}
                >
                  <button
                    className={cls.model_btn}
                    type="button"
                    onClick={toggleShort}
                  >
                    {t(isShort ? 'allModels' : 'common.fold')}
                    <ChevronIcon />
                  </button>
                </li>
              )}
            </ul>
          )}

          {isLoading && (
            <ul className={cls.list}>
              {skeletons.map((_, i) => (
                <li key={i}>
                  <Skeleton width="100%" height={22} />
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
