import { Fragment, useMemo } from 'react';

import { Nullable } from '@/app/types';
import {
  showMessageUnread,
  showMessageTail,
  isMessageHasLargeRadius,
  isMessageHasMargin
} from '@/modules/chat/Detail/Messages/helpers';
import { Unread } from '@/modules/chat/Detail/Messages/Unread';
import {
  ChatMessageAuthor,
  ChatMessageWithReplied
} from '@/modules/chat/types';

import { Message } from './Message';

type Props = {
  unreadMsgId: Nullable<number>;
  messages: ChatMessageWithReplied[];
  msg: ChatMessageWithReplied;
};

export function MessageWrap({ unreadMsgId, messages, msg }: Props) {
  const index = useMemo(() => msg.index as number, [msg.index]);

  const showUnread = useMemo(
    () => unreadMsgId === msg.id || showMessageUnread(messages, msg, index),
    [unreadMsgId, msg, index, messages]
  );
  const showTail = useMemo(
    () =>
      showMessageTail(messages, msg, index) &&
      msg.author !== ChatMessageAuthor.system,
    [messages, msg, index]
  );
  const hasLargeRadius = useMemo(
    () =>
      isMessageHasLargeRadius(messages, msg, index) &&
      msg.author !== ChatMessageAuthor.system,
    [messages, msg, index]
  );
  const hasMargin = useMemo(
    () => isMessageHasMargin(messages, msg, index),
    [messages, msg, index]
  );

  return (
    <Fragment key={msg.id}>
      {showUnread && <Unread id={msg.id} />}
      <Message
        message={msg}
        showTail={showTail}
        hasMargin={hasMargin}
        largeRadius={hasLargeRadius}
      />
    </Fragment>
  );
}
