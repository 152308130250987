import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useRedirectCheck } from '@/hooks/useRedirectCheck';
import { useAuthed } from '@/modules/auth/hooks';
import { AuthState } from '@/modules/auth/types';

export function PrivateRoute() {
  const location = useLocation();
  const { pathname } = location;
  const [isAuthed, authState] = useAuthed();

  const {
    shouldConfirmEmail,
    shouldCreateShowroom,
    shouldLeaveShowroomCreate
  } = useRedirectCheck();

  if (authState === AuthState.initial) {
    return null;
  }

  // Confirm email
  if (shouldConfirmEmail && pathname !== '/confirm-email') {
    return <Navigate to="/confirm-email" state={{ from: location }} />;
  }

  // Showroom creation
  if (shouldCreateShowroom && !pathname.startsWith('/showroom/create')) {
    return <Navigate to="/showroom/create" state={{ from: location }} />;
  }

  if (shouldLeaveShowroomCreate && pathname.startsWith('/showroom/create')) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  // Auth
  return isAuthed ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} />
  );
}
