import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { useRentDocs } from '@/modules/showroom/advert/rent/docs/hooks';
import { RentDoc } from '@/modules/showroom/advert/rent/docs/types';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';

import cls from './Docs.module.scss';

type Props = {
  docsIds: RentUpdateAdvertStore['docsIds'];
  setDocsIds: RentUpdateAdvertStore['setDocsIds'];
  isDocsIdsFilled: RentUpdateAdvertStore['isDocsIdsFilled'];
  setDocsIdsFilled: RentUpdateAdvertStore['setDocsIdsFilled'];
};

export function Docs({
  docsIds,
  setDocsIds,
  isDocsIdsFilled,
  setDocsIdsFilled
}: Props) {
  const { t } = useTranslation();

  const categories = useRentDocs();
  const allIds = categories.reduce<number[]>(
    (acc, cur) => [...acc, ...cur.documents.map((d) => d.id)],
    []
  );
  const selectedIds = docsIds || [];

  const toggleDoc = (d: RentDoc) => {
    return () => {
      const includes = selectedIds.includes(d.id);
      setDocsIds(
        includes
          ? selectedIds.filter((v) => v !== d.id)
          : [...selectedIds, d.id]
      );
    };
  };

  const onContinueClick = () => {
    setDocsIdsFilled(true);
    scrollToSection(SCROLLTO.showroom, 100);
  };

  useEffect(() => {
    if (!docsIds && allIds.length > 0) {
      setDocsIds(allIds);
    }
  }, [allIds, docsIds, setDocsIds]);

  return (
    <div className={cls.root} id={SCROLLTO.docs}>
      <div className="box">
        <h1 className={cls.title}>{t('docs.title')}</h1>
        <p className={cls.subtitle}>{t('docs.subtitle')}</p>

        <div className={cls.categories}>
          {categories.map((c) => (
            <div className={cls.category} key={c.name}>
              <p className={cls.category_name}>{c.name}</p>
              <ul className={cls.category_list}>
                {c.documents.map((d) => {
                  const includes = !!docsIds?.includes(d.id);

                  return (
                    <li key={d.id}>
                      <Checkbox
                        id={`${c.name}-${d.id}`}
                        checked={includes}
                        onChange={toggleDoc(d)}
                      >
                        {d.name}
                      </Checkbox>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>

        <div className={cls.buttons}>
          {!isDocsIdsFilled && selectedIds.length > 0 && (
            <Button
              onClick={onContinueClick}
              color="blue"
              variant="primary"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
