import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressForm } from '@/modules/showroom/address/AddressForm/AddressForm';
import {
  createShowroomAddress,
  useShowroomAddressList
} from '@/modules/showroom/address/api';
import { CreateShowroomAddressReq } from '@/modules/showroom/address/types';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { Skeleton } from '@/ui/Skeleton';
import { minutesToHHMM } from '@/utils/date';
import { showAlert } from '@/utils/network';

import cls from './Address.module.scss';
import { AddIcon } from './icons';

type Props = {
  addressId: CreateAdvertSlice['addressId'];
  setAddressId: CreateAdvertSlice['setAddressId'];
  isAddressFilled: CreateAdvertSlice['isAddressFilled'];
  setAddressFilled: CreateAdvertSlice['setAddressFilled'];
  title?: string;
  children?: React.ReactNode;
  nextSection?: string;
};

export function Address({
  title,
  addressId,
  setAddressId,
  isAddressFilled,
  setAddressFilled,
  children,
  nextSection
}: Props) {
  const { t } = useTranslation();

  const [list, listLoading, fetchAddressList] = useShowroomAddressList();
  const address = list?.find((s) => s.id === addressId) || null;

  const onContinueClick = () => {
    setAddressFilled(true);
    scrollToSection(nextSection || SCROLLTO.phone, 100);
  };

  // Add
  const [isAddLoading, setAddLoading] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);
  const onAddClick = () => {
    setAddOpen(true);
  };
  const onAddSubmit = async (values: CreateShowroomAddressReq) => {
    setAddLoading(true);

    try {
      await createShowroomAddress(values);
      fetchAddressList();
      setAddOpen(false);
    } catch (error) {
      showAlert({ error });
    } finally {
      setAddLoading(false);
    }
  };

  return (
    <div className={cls.root} id={SCROLLTO.showroom}>
      <div className="box">
        <div className={cls.header}>
          <h1 className={cls.title}>{title || t('showroom.label')}</h1>
          <Button
            variant="secondary"
            size="compact"
            color="black"
            onClick={onAddClick}
            disabled={isAddLoading}
            gap={4}
          >
            <AddIcon />
            {t('createNewAddress')}
          </Button>
        </div>

        {list && list.length <= 0 && !listLoading && (
          <p className={cls.empty}>{t('showroom.empty')}</p>
        )}

        {list && list.length > 0 && !listLoading && (
          <ul className={cls.list}>
            {list.map((a) => {
              const isActive = a.id === addressId;

              return (
                <li key={a.id}>
                  <div
                    className={cn(cls.address, {
                      [cls.address_active]: isActive
                    })}
                  >
                    <div>
                      <p className={cls.address_title}>{a.name}</p>
                      <p className={cls.address_subtitle}>{a.address}</p>

                      {isActive && (
                        <p className={cls.address_info}>
                          {a.emirate.name} {t('common.dot')}{' '}
                          {t('common.fromTime', {
                            time: minutesToHHMM(a.working_hours_from)
                          })}{' '}
                          {t('common.toTime', {
                            time: minutesToHHMM(a.working_hours_up_to)
                          })}
                        </p>
                      )}
                    </div>
                    <Checkbox
                      id={`ad-showroom-${a.id}`}
                      checked={isActive}
                      onChange={() => ''}
                      rounded
                    />
                    <button
                      className={cls.address_btn}
                      type="button"
                      onClick={() => setAddressId(a.id)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        {listLoading && (
          <ul className={cls.list}>
            <li>
              <Skeleton width="100%" height={72} borderRadius={12} />
            </li>
          </ul>
        )}

        {children}

        {!isAddressFilled && (
          <div className={cls.btn}>
            <Button
              onClick={onContinueClick}
              color="blue"
              variant="primary"
              disabled={!address || !list}
              fullWidth
            >
              {t('common.continue')}
            </Button>
          </div>
        )}

        <AddressForm
          isOpen={isAddOpen}
          close={() => setAddOpen(false)}
          buttonText={t('common.create')}
          onSubmit={onAddSubmit}
          loading={isAddLoading}
        />
      </div>
    </div>
  );
}
