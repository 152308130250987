import type { AxiosResponse } from 'axios';

import { axios } from '@/app/api';
import {
  CreatePaymentIntentReq,
  CreatePaymentIntentRes
} from '@/modules/payment/types';

export function createPaymentIntent(
  req: CreatePaymentIntentReq
): Promise<AxiosResponse<CreatePaymentIntentRes>> {
  return axios.post<CreatePaymentIntentRes>(
    `/payments/stripe/v1/create-payment-intent`,
    req
  );
}
