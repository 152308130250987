export function DocIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4808 2.0965C14.7777 2.16494 15.0405 2.27379 15.2989 2.4354C15.5573 2.597 15.7666 2.76664 16.3144 3.31438L19.6856 6.68562C20.2334 7.23336 20.403 7.44267 20.5646 7.70107C20.7262 7.95947 20.8351 8.22226 20.9035 8.51925C20.972 8.81623 21 9.08419 21 9.85881V17.1542C21 18.4915 20.8608 18.9764 20.5993 19.4653C20.3378 19.9542 19.9542 20.3378 19.4653 20.5993C18.9764 20.8608 18.4915 21 17.1542 21H7.84583C6.50855 21 6.02362 20.8608 5.53473 20.5993C5.04584 20.3378 4.66216 19.9542 4.4007 19.4653C4.13924 18.9764 4 18.4915 4 17.1542V5.84583C4 4.50855 4.13924 4.02362 4.4007 3.53473C4.66216 3.04584 5.04584 2.66216 5.53473 2.4007C6.02362 2.13924 6.50855 2 7.84583 2H13.1412C13.9158 2 14.1838 2.02805 14.4808 2.0965ZM13.8 3.92427C13.6343 3.92427 13.5 4.05858 13.5 4.22427V8.00002C13.5 8.27616 13.7239 8.50002 14 8.50002H17.7757C17.8553 8.50002 17.9316 8.46841 17.9879 8.41215C18.105 8.295 18.105 8.10505 17.9879 7.98789L14.0121 4.01213C13.9559 3.95587 13.8796 3.92427 13.8 3.92427Z"
        fill="currentColor"
      />
    </svg>
  );
}
