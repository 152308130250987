import { DepositHistoryItem } from '@/modules/wallet/types';

type MappedDepositHistory = {
  day: Date;
  deposits: DepositHistoryItem[];
  total: number;
};

const unixToStartOfDay = (unixDate: number): number => {
  const date = new Date(unixDate * 1000);
  return date.setHours(0, 0, 0, 0);
};

export const mapDepositHistoryData = (
  deposits: DepositHistoryItem[]
): MappedDepositHistory[] => {
  const dataWithDayKey: Record<string, DepositHistoryItem[]> = {};
  const result: MappedDepositHistory[] = [];

  deposits.forEach((deposit) => {
    const createdDateUnix = unixToStartOfDay(deposit.created_at).toString();

    if (!dataWithDayKey[createdDateUnix]) {
      dataWithDayKey[createdDateUnix] = [deposit];
    } else {
      dataWithDayKey[createdDateUnix].push(deposit);
    }
  });

  Object.keys(dataWithDayKey).forEach((key) => {
    const deposits = dataWithDayKey[key];
    result.push({
      day: new Date(Number(key)),
      deposits,
      total: deposits.reduce((a, b) => a + b.amount, 0)
    });
  });

  return result;
};
