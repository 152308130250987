/* eslint-disable @typescript-eslint/no-unused-vars */

import { Nullable } from '@/app/types';
import { UpdateAdvertStickersReq } from '@/modules/showroom/advert/update/types';
import { showAlert } from '@/utils/network';

// todo: rent advert promo requests
export function useRentAdvertPromoUpdate() {
  const updateAutoExtension = async (
    advertId: Nullable<number>,
    autoExtension: boolean
  ) => {
    if (!advertId) return;

    try {
      // await updateRentAdvertAutoExtension(advertId, autoExtension);
    } catch (error) {
      showAlert({ error });
    }
  };

  const updatePromoPackage = async (
    advertId: Nullable<number>,
    promoPackageId: number
  ) => {
    if (!advertId) return;

    try {
      // await updateRentAdvertPromoPackage(advertId, promoPackageId);
    } catch (error) {
      showAlert({ error });
    }
  };

  const updatePromoStickers = async (
    advertId: Nullable<number>,
    req: UpdateAdvertStickersReq
  ) => {
    if (!advertId) return;

    try {
      // await updateRentAdvertStickers(advertId, req);
    } catch (error) {
      showAlert({ error });
    }
  };

  return {
    updateAutoExtension,
    updatePromoPackage,
    updatePromoStickers
  };
}
