import { Price } from '@/modules/showroom/advert/create/Price/Price';
import {
  isVrpPriceValid,
  MIN_VRP_PRICE
} from '@/modules/showroom/advert/vrp/helpers';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

export function PriceWrap() {
  const price = useUpdateVrpStore((s) => s.price);
  const setPrice = useUpdateVrpStore((s) => s.setPrice);
  const isPriceFilled = useUpdateVrpStore((s) => s.isPriceFilled);
  const setPriceFilled = useUpdateVrpStore((s) => s.setPriceFilled);

  const discount = useUpdateVrpStore((s) => s.discount);
  const setDiscount = useUpdateVrpStore((s) => s.setDiscount);
  const discountTypes = useUpdateVrpStore((s) => s.discountTypes);
  const setDiscountTypes = useUpdateVrpStore((s) => s.setDiscountTypes);

  return (
    <Price
      price={price}
      setPrice={setPrice}
      minPrice={MIN_VRP_PRICE}
      validatePrice={isVrpPriceValid}
      isPriceFilled={isPriceFilled}
      setPriceFilled={setPriceFilled}
      discount={discount}
      setDiscount={setDiscount}
      discountTypes={discountTypes}
      setDiscountTypes={setDiscountTypes}
      hideDiscount
    />
  );
}
