import { useCallback } from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { BlockerFunction, usePrompt } from './usePrompt';

export function useRouteUnload(
  hasUnsavedChanges: boolean,
  confirmTitle: string
) {
  const onLocationChange = useCallback<BlockerFunction>(() => {
    if (hasUnsavedChanges) {
      return !window.confirm(confirmTitle);
    }
    return false;
  }, [hasUnsavedChanges, confirmTitle]);
  usePrompt(onLocationChange, hasUnsavedChanges);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          event.returnValue = '';
        }
      },
      [hasUnsavedChanges]
    ),
    { capture: true }
  );
}
