import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import { ApiError, Nullable } from '@/app/types';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import { showAlert } from '@/utils/network';

export function getAuthorDetail(id: number) {
  return axios.get<AdvertAuthorDetail>(
    `/dealers/showrooms/advertisements/my/${id}/detail`
  );
}

export function useAuthorDetail(
  id: Nullable<number>
): [AdvertAuthorDetail | undefined, boolean, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<AdvertAuthorDetail>();
  const [notFound, setNotFound] = useState(false);

  const load = useCallback(async () => {
    if (!id) return;
    setLoading(true);

    try {
      const r = await getAuthorDetail(id);
      setNotFound(false);
      setData(r.data);
    } catch (error) {
      showAlert({ error });

      const e = error as AxiosError<ApiError>;
      const notFoundCode = 420;
      const notFoundErr = e && e.response?.data?.code === notFoundCode;
      setNotFound(notFoundErr);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading, notFound];
}
