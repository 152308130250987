export function CloseIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33129 19.5085C6.89818 19.9416 6.87755 20.715 7.3416 21.1584C7.78502 21.6224 8.56874 21.6018 9.00185 21.1687L14.0032 16.1673L19.0046 21.1687C19.448 21.6121 20.2111 21.6224 20.6545 21.1584C21.1186 20.715 21.1083 19.9416 20.6649 19.4981L15.6635 14.4968L20.6649 9.50571C21.1083 9.05197 21.1186 8.28888 20.6545 7.84546C20.2111 7.38141 19.448 7.39172 19.0046 7.83514L14.0032 12.8365L9.00185 7.83514C8.56874 7.40203 7.78502 7.38141 7.3416 7.84546C6.87755 8.28888 6.89818 9.06229 7.33129 9.49539L12.3327 14.4968L7.33129 19.5085Z"
        fill="currentColor"
      />
    </svg>
  );
}
