import { AxiosResponse } from 'axios';

import { axios } from '@/app/api';
import { ChangePasswordReq } from '@/modules/showroom/settings/ChangePassword/types';

export function changePasswordWithoutEmail(
  req: ChangePasswordReq
): Promise<AxiosResponse<unknown>> {
  return axios.post<unknown>(`/dealers/accounts/password/restore`, req);
}
