import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from '@/modules/chat/ChatModal/Header/icons';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

import cls from './Header.module.scss';

type Props = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  showSearch?: boolean;
  searchDisabled?: boolean;
  onCloseClick?: () => void;
};

export function Header({
  searchText,
  setSearchText,
  showSearch,
  searchDisabled,
  onCloseClick
}: Props) {
  const { t } = useTranslation();

  return (
    <header
      className={cn(cls.root, {
        [cls.root_search]: showSearch
      })}
    >
      <div className={cls.row}>
        {onCloseClick && (
          <button
            className={cls.close_btn}
            type="button"
            onClick={onCloseClick}
          >
            <CloseIcon />
          </button>
        )}
      </div>

      {showSearch && (
        <div className={cls.search_field}>
          <InputSearch
            value={searchText}
            onChange={setSearchText}
            placeholder={t('chat.search')}
            disabled={searchDisabled}
            autoFocus
            showClear
          />
        </div>
      )}
    </header>
  );
}
