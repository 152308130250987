import { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { EngagementByDaySmall } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { getApexOptions } from '@/modules/overview/CountCard/EngagementGraph/helpers';
import { useMapEngagementDays } from '@/modules/overview/hooks';
import { useDarkmode } from '@/modules/theme/useTheme';

import cls from './EngagementGraph.module.scss';

type Props = {
  data: EngagementByDaySmall[];
  chartHeight?: number;
  datesRange?: [Date, Date];
  axisColor?: string;
};

export function EngagementGraph({
  data,
  chartHeight,
  datesRange,
  axisColor
}: Props) {
  const [lang] = useLang();
  const { isDark } = useDarkmode();

  const mappedData = useMapEngagementDays(data, datesRange);

  const series = useMemo(() => {
    return [{ data: mappedData.map((d) => d.count), name: '' }];
  }, [mappedData]);

  const [hovered, setHovered] = useState(false);

  const options = useMemo(
    () =>
      getApexOptions(
        mappedData.map((d) => d.date * 1000),
        lang,
        isDark,
        axisColor ||
          (hovered ? 'var(--thm-silver-grey-2)' : 'var(--thm-grey-1)')
      ),
    [mappedData, lang, isDark, axisColor, hovered]
  );

  return (
    <div
      className={cls.graph}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={chartHeight || 289}
      />
    </div>
  );
}
