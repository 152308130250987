export function AddIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66675 7.99983C2.66675 8.39122 2.98998 8.71039 3.37732 8.71039H7.29065V12.6237C7.29065 13.0099 7.60865 13.3332 8.00007 13.3332C8.39147 13.3332 8.71585 13.0099 8.71585 12.6237V8.71039H12.6239C13.0102 8.71039 13.3334 8.39122 13.3334 7.99983C13.3334 7.60841 13.0102 7.28403 12.6239 7.28403H8.71585V3.37707C8.71585 2.98973 8.39147 2.6665 8.00007 2.6665C7.60865 2.6665 7.29065 2.98973 7.29065 3.37707V7.28403H3.37732C2.98998 7.28403 2.66675 7.60841 2.66675 7.99983Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DeleteEmailIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66207 17.7099C10.0072 17.7099 10.2297 17.4912 10.2215 17.1691L9.97054 8.58175C9.96087 8.26263 9.73546 8.05065 9.4052 8.05065C9.06299 8.05065 8.842 8.26934 8.85018 8.58994L9.09444 17.1735C9.10411 17.4994 9.33099 17.7099 9.66207 17.7099ZM12.0748 17.7099C12.4141 17.7099 12.6529 17.4941 12.6529 17.1751V8.58699C12.6529 8.26787 12.4141 8.05065 12.0748 8.05065C11.7341 8.05065 11.5019 8.26787 11.5019 8.58699V17.1751C11.5019 17.4941 11.7341 17.7099 12.0748 17.7099ZM14.4927 17.7099C14.8186 17.7099 15.044 17.4994 15.0536 17.1735L15.2979 8.58994C15.3061 8.26934 15.0851 8.05065 14.7429 8.05065C14.4126 8.05065 14.1872 8.26263 14.1776 8.58846L13.9334 17.1691C13.9251 17.4912 14.1476 17.7099 14.4927 17.7099ZM8.35365 5.76833H9.86537V4.06707C9.86537 3.64294 10.162 3.37083 10.6181 3.37083H13.5166C13.9726 3.37083 14.2693 3.64294 14.2693 4.06707V5.76833H15.7811V3.99105C15.7811 2.73849 14.9816 2 13.6243 2H10.5103C9.15309 2 8.35365 2.73849 8.35365 3.99105V5.76833ZM4.72489 6.65949H19.4299C19.8336 6.65949 20.1481 6.3389 20.1481 5.93526C20.1481 5.53455 19.8336 5.22068 19.4299 5.22068H4.72489C4.32796 5.22068 4 5.53455 4 5.93526C4 6.34562 4.32796 6.65949 4.72489 6.65949ZM8.31302 20.483H15.8418C17.0957 20.483 17.91 19.7154 17.9688 18.46L18.5319 6.50239H17.0126L16.4741 18.2161C16.4578 18.7075 16.1335 19.0339 15.6665 19.0339H8.47487C8.02126 19.0339 7.69706 18.6994 7.67396 18.2161L7.10862 6.50239H5.61624L6.186 18.4668C6.24629 19.7221 7.04572 20.483 8.31302 20.483Z"
        fill="currentColor"
      />
    </svg>
  );
}
