import { Trans, useTranslation } from 'react-i18next';

import { BaseLayout } from '@/ui/layouts/BaseLayout/BaseLayout';
import { APP_URL } from '@/utils/consts';

import cls from './AuthLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

export function AuthLayout({ children }: Props) {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <div className={cls.root}>
        <div className={cls.inner}>
          <div>{children}</div>

          <p className={cls.policy}>
            <Trans
              t={t}
              i18nKey="auth.policyAgreement"
              components={{
                termsLink: (
                  <a
                    href={`${APP_URL}/terms_of_use`}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                policyLink: (
                  <a
                    href={`${APP_URL}/privacy-policy`}
                    target="_blank"
                    rel="noreferrer"
                  />
                )
              }}
            />
          </p>
        </div>
      </div>
    </BaseLayout>
  );
}
