import { axios } from '@/app/api';
import {
  CurrentPriceForRefreshRes,
  RefreshWeekDaysRes
} from '@/modules/refresh/types';

export function getRefreshWeekDays() {
  return axios.get<RefreshWeekDaysRes>(`/advertisements/refresh/week-day`);
}

export function getCurrentPriceForRefresh() {
  return axios.get<CurrentPriceForRefreshRes>(`/advertisements/refresh/price`);
}
