import type { ApexOptions } from 'apexcharts';

import { ADV_CUR } from '@/utils/consts';
import { getDateString } from '@/utils/date';

export function getApexOptions(
  xCategories: number[],
  lang: string,
  isDark: boolean
): ApexOptions {
  return {
    colors: ['#E60000'],
    stroke: {
      width: 2
    },
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      parentHeightOffset: 0
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      axisBorder: {
        color: 'var(--thm-grey-1)'
      },
      axisTicks: {
        color: 'var(--thm-grey-1)'
      },
      type: 'datetime',
      categories: xCategories,
      labels: {
        formatter: (value) =>
          getDateString(new Date(value), lang, { month: undefined })
      }
    },
    yaxis: {
      show: false
    },
    tooltip: {
      theme: isDark ? 'dark' : 'light',
      x: {
        formatter: (value) => getDateString(new Date(value), lang)
      },
      y: {
        formatter: (v: number) => `${Number(v)} ${ADV_CUR}`
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    fill: {
      opacity: 1,
      gradient: {
        shade: isDark ? 'dark' : 'light'
      }
    }
  };
}
