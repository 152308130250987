import { useDesktop } from '@/app/common-store';
import {
  CommonModal,
  CommonModalProps
} from '@/ui/modals/CommonModal/CommonModal';

import { BottomModal } from './BottomModal/BottomModal';

export const AdaptiveModal: React.FC<CommonModalProps> = ({
  children,
  ...rest
}) => {
  const isDesktop = useDesktop();
  const Modal = isDesktop ? CommonModal : BottomModal;
  return <Modal {...rest}>{children}</Modal>;
};
