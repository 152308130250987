import { CommonObj } from '@/app/types';
import { ExpensesDetailStatisticsRes } from '@/modules/wallet/types';
import { CHARTS_COLORS } from '@/utils/consts';

export const COLORS_MAP: Record<number, string> = {
  1: CHARTS_COLORS.cars,
  2: CHARTS_COLORS.vrp,
  3: CHARTS_COLORS.services,
  4: CHARTS_COLORS.refresh
};

export interface ExpenseCategory extends CommonObj {
  total: number;
  color: string;
}

export function getCategoriesWithTotal(
  data: ExpensesDetailStatisticsRes
): ExpenseCategory[] {
  const tmp: Record<number, number> = {};

  data.expenses.forEach((day) => {
    if (day.category_id in tmp) {
      const currentAmount = tmp[day.category_id];
      tmp[day.category_id] = currentAmount + day.amount;
    } else {
      tmp[day.category_id] = day.amount;
    }
  });

  return data.categories.map((c) => ({
    ...c,
    total: tmp[c.id] || 0,
    color: COLORS_MAP[c.id]
  }));
}
