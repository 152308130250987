import { useTranslation } from 'react-i18next';

import { SortFilter } from '@/modules/showroom/advert/my/types';
import { EmirateFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/PlateFilters/EmirateFilter';
import { NumberFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/PlateFilters/NumberFilter';
import { PlateNumberLengthFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/PlateFilters/PlateNumberLengthFilter';
import { PlateTypeFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/PlateFilters/PlateTypeFilter';
import { SortSelect } from '@/modules/showroom/advert/search/SearchAdverts/SortSelect/SortSelect';
import { useSearchStore } from '@/modules/showroom/advert/search/store';

import cls from '../Filters.module.scss';

type Props = {
  sortFilters: SortFilter[];
  showReset: boolean;
  onResetClick: () => void;
};

export function PlateFilters({ showReset, onResetClick, sortFilters }: Props) {
  const { t } = useTranslation();

  const sortId = useSearchStore((s) => s.sortId);
  const setSortId = useSearchStore((s) => s.setSortId);

  return (
    <div className={cls.root}>
      <ul className={cls.list}>
        <li className={cls.li}>
          <EmirateFilter />
        </li>
        <li className={cls.li}>
          <PlateTypeFilter />
        </li>
        <li className={cls.li_input}>
          <NumberFilter />
        </li>
        <li className={cls.li}>
          <PlateNumberLengthFilter />
        </li>
        {showReset && (
          <li className={cls.li}>
            <button
              className={cls.reset_btn}
              onClick={onResetClick}
              type="button"
            >
              {t('common.reset')}
            </button>
          </li>
        )}
      </ul>

      <SortSelect
        sortFilters={sortFilters}
        sortId={sortId}
        setSortId={setSortId}
      />
    </div>
  );
}
