import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button/Button';

import cls from './CallbackOffState.module.scss';

type Props = {
  openSettings: () => void;
};

export const CallbackOffState: React.FC<Props> = ({ openSettings }) => {
  const { t } = useTranslation();

  return (
    <div className={cls.root}>
      <p>{t('callback.offState')}</p>
      <Button
        variant="outline"
        size="compact"
        color="black"
        onClick={openSettings}
      >
        {t('common.settings')}
      </Button>
    </div>
  );
};
