import cn from 'classnames';

import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './Button.module.scss';
import { ButtonProps as Props } from './ButtonProps';

export const Button: React.FC<Props> = ({
  children,
  variant = 'primary',
  color = 'red',
  size = 'l',
  onClick = () => null,
  fullWidth,
  disabled,
  loading,
  gap,
  ...rest
}) => {
  return (
    <button
      className={cn(cls.button, {
        [cls.button_fullwidth]: fullWidth,
        [cls.button_loading]: loading,
        // Size
        [cls.button_size_s]: size === 's',
        [cls.button_size_m]: size === 'm',
        [cls.button_size_l]: size === 'l',
        [cls.button_size_compact]: size === 'compact',
        // Variants
        [cls.button_primary]: variant === 'primary',
        [cls.button_secondary]: variant === 'secondary',
        [cls.button_tertiary]: variant === 'tertiary',
        [cls.button_outline]: variant === 'outline',
        [cls.button_link]: variant === 'link',
        // Color
        [cls.button_black]: color === 'black',
        [cls.button_red]: color === 'red',
        [cls.button_green]: color === 'green',
        [cls.button_blue]: color === 'blue'
      })}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <div className={cls.inner} style={{ gap }}>
        {children}
      </div>
      {typeof loading === 'boolean' && (
        <div className={cls.spinner}>
          <Spinner color="currentColor" size={24} />
        </div>
      )}
    </button>
  );
};

Button.displayName = 'Button';
