import cn from 'classnames';
import { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDesktop } from '@/app/common-store';
import { useDownloadFile } from '@/hooks/useDownloadFile';
import { useLang } from '@/hooks/useLang';
import {
  isImageContent,
  isVideoContent
} from '@/modules/chat/Detail/Messages/Message/helpers';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { CommonModal } from '@/ui/modals/CommonModal/CommonModal';
import { isToday } from '@/utils/date';

import { DownloadIcon, CloseIcon, ChevronIcon } from './icons';
import cls from './Previews.module.scss';

const LEFT_CODE = 'ArrowLeft';
const RIGHT_CODE = 'ArrowRight';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const Previews: React.FC<Props> = ({ isOpen, close }) => {
  const { t } = useTranslation();
  const [lang] = useLang();

  const isDesktop = useDesktop();
  const [downloadFile] = useDownloadFile();

  const content = useChatDetailStore((s) => s.messagesContent);

  const initialUrl = useChatDetailStore((s) => s.previewUrl);
  const setInitalUrl = useChatDetailStore((s) => s.setPreviewUrl);
  const index = useChatDetailStore((s) => s.previewIndex);
  const setIndex = useChatDetailStore((s) => s.setPreviewIndex);
  const selected = useMemo(() => content[index], [content, index]);

  const date = useMemo(() => new Date(selected.upload_date * 1000), [selected]);
  const isDateToday = date ? isToday(date) : false;
  const dateString = isDateToday
    ? t('common.today')
    : date.toLocaleString(lang, {
        month: 'long',
        day: 'numeric'
      });

  useEffect(() => {
    if (typeof initialUrl === 'string') {
      const index = content.findIndex((media) => media.url === initialUrl);
      index !== -1 && setIndex(index);
    }
  }, [content, initialUrl, setIndex]);

  useEffect(() => {
    if (index === 0) return;
    selected.url && setInitalUrl(selected.url);
  }, [selected, setInitalUrl, index]);

  const onPrevClick = useCallback(() => {
    if (index !== 0) setIndex(index - 1);
  }, [index, setIndex]);

  const onNextClick = useCallback(() => {
    if (index !== content.length - 1) setIndex(index + 1);
  }, [index, content.length, setIndex]);

  const isImage = isImageContent(selected.type);
  const isVideo = isVideoContent(selected.type);

  // Keydown
  const onKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === LEFT_CODE) {
        e.preventDefault();
        onPrevClick();
      } else if (e.code === RIGHT_CODE) {
        e.preventDefault();
        onNextClick();
      }
    },
    [onPrevClick, onNextClick]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeydown);
    } else {
      window.removeEventListener('keydown', onKeydown);
    }

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, [onKeydown, isOpen]);

  return (
    <CommonModal
      name="message-preview"
      isOpen={isOpen}
      close={close}
      containerClass={cn(cls.modal_container, cls.modal_container_imp)}
      backdropClass={cls.modal_backdrop}
    >
      <div className={cls.root}>
        <header className={cls.header}>
          <div className={cls.info}>
            {!isDesktop && (
              <div className={cls.buttons}>
                <button type="button" onClick={close}>
                  <CloseIcon />
                </button>
              </div>
            )}

            <p className={cls.date}>{dateString}</p>
          </div>

          {(selected.url || isDesktop) && (
            <div className={cls.buttons}>
              {selected.url && (
                <button type="button" onClick={() => downloadFile(selected)}>
                  <DownloadIcon />
                </button>
              )}

              {isDesktop && (
                <button type="button" onClick={close}>
                  <CloseIcon />
                </button>
              )}
            </div>
          )}
        </header>

        <div className={cls.content}>
          <button
            className={cn(cls.arrow_btn, cls.arrow_btn_prev)}
            type="button"
            onClick={onPrevClick}
          >
            <ChevronIcon />
          </button>
          <button
            className={cn(cls.arrow_btn, cls.arrow_btn_next)}
            type="button"
            onClick={onNextClick}
          >
            <ChevronIcon />
          </button>

          {selected.url && isImage && <img src={selected.url} alt="" />}
          {selected.url && isVideo && isOpen && (
            <video
              src={selected.url}
              preload="metadata"
              autoPlay
              playsInline
              controls
            />
          )}
        </div>
      </div>
    </CommonModal>
  );
};
