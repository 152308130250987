export function ArrowIcon(): JSX.Element {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9997 23.1467C15.5812 23.1467 15.9927 22.7352 15.9927 22.1447V11.0964L15.9212 9.16406L18.2382 11.7495L20.2779 13.7713C20.4568 13.9502 20.7073 14.0665 20.9846 14.0665C21.5303 14.0665 21.9418 13.655 21.9418 13.0914C21.9418 12.8319 21.8434 12.5904 21.6287 12.3757L15.7333 6.47131C15.5365 6.26556 15.2681 6.1582 14.9997 6.1582C14.7224 6.1582 14.454 6.26556 14.2572 6.47131L8.37073 12.3757C8.15602 12.5904 8.05762 12.8319 8.05762 13.0914C8.05762 13.655 8.46019 14.0665 9.00589 14.0665C9.29217 14.0665 9.54265 13.9502 9.72157 13.7713L11.7523 11.7495L14.0783 9.15512L13.9978 11.0964V22.1447C13.9978 22.7352 14.4093 23.1467 14.9997 23.1467Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function QuickReplyIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9991 22C17.4773 22 22 17.4707 22 12C22 6.52137 17.4694 2 11.9912 2C6.52272 2 2 6.52137 2 12C2 17.4707 6.53066 22 11.9991 22ZM11.9991 20.0302C7.54339 20.0302 3.97762 16.4561 3.97762 12C3.97762 7.54387 7.53545 3.96986 11.9912 3.96986C16.4469 3.96986 20.0303 7.54387 20.0303 12C20.0303 16.4561 16.4548 20.0302 11.9991 20.0302Z"
        fill="currentColor"
      />
      <path
        d="M7.80359 12.6478C7.80359 12.9055 8.01113 13.0937 8.27849 13.0937H11.4607L9.76264 17.6278C9.51987 18.2771 10.213 18.6403 10.6439 18.1125L15.8632 11.6159C15.9732 11.4961 16.0313 11.3536 16.0313 11.2208C16.0313 10.9631 15.8238 10.7732 15.5564 10.7732H12.3742L14.0722 6.23903C14.315 5.59157 13.6219 5.22834 13.1909 5.74647L7.97164 12.2448C7.86167 12.3725 7.80359 12.5132 7.80359 12.6478Z"
        fill="currentColor"
      />
    </svg>
  );
}
