import {
  ComplectationCategory,
  ComplectationOption,
  ComplectationOptionItem
} from '@/modules/showroom/advert/complectation/types';

export function isOptionsSame(options1: number[], options2: number[]): boolean {
  if (options1.length !== options2.length) return false;
  const sorted1 = [...options1].sort((a, b) => a - b);
  const sorted2 = [...options2].sort((a, b) => a - b);
  const str1 = sorted1.join('');
  const str2 = sorted2.join('');
  if (str1.length !== str2.length) return false;
  return str1 === str2;
}

function getComplectationOptions(
  categories: ComplectationCategory[]
): ComplectationOption[] {
  return categories.reduce<ComplectationOption[]>((acc, cur) => {
    return [...acc, ...cur.options];
  }, []);
}

function getOptionsItems(
  options: ComplectationOption[]
): ComplectationOptionItem[] {
  return options.reduce<ComplectationOptionItem[]>((acc, option) => {
    return [...acc, ...option.items];
  }, []);
}

export function getCategoriesItems(
  categories: ComplectationCategory[]
): ComplectationOptionItem[] {
  return getOptionsItems(getComplectationOptions(categories));
}
