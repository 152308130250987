import { axios } from '@/app/api';
import {
  GenerateDescriptionReq,
  GenerateDescriptionRes
} from '@/modules/ai/types';

export const AI_API_ROUTES = {
  generateDescription: '/ai/ad/description/generate',
  formatDescription: '/ai/ad/description/format'
};

export function generateDescriptionWithAi(params: GenerateDescriptionReq) {
  return axios.get<GenerateDescriptionRes>(AI_API_ROUTES.generateDescription, {
    params
  });
}

export function formatDescriptionWithAi(to_fmt: string) {
  return axios.get<GenerateDescriptionRes>(AI_API_ROUTES.formatDescription, {
    params: { to_fmt }
  });
}
