import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setUpNotifications } from '@/modules/notifications/api';
import { useGetNotifications } from '@/modules/notifications/hooks';
import { NotificationsFormGroup } from '@/modules/notifications/NotificationsForm/NotificationsFormGroup';
import { NoticationsFormEmail } from '@/modules/notifications/types';
import { Button } from '@/ui/Button/Button';
import { Spinner } from '@/ui/Spinner/Spinner';
import { showAlert } from '@/utils/network';

import {
  SetterType,
  getInitialArr,
  setInitialValue,
  prepareEmailsForSave
} from './helpers';
import cls from './NotificationsForm.module.scss';

export const NotificationsForm = () => {
  const { t } = useTranslation();

  const [data, isLoading] = useGetNotifications();

  const [credits, setCredits] = useState<NoticationsFormEmail[]>(
    getInitialArr()
  );
  const [bookings, setBookings] = useState<NoticationsFormEmail[]>(
    getInitialArr()
  );
  const [callbacks, setCallbacks] = useState<NoticationsFormEmail[]>(
    getInitialArr()
  );

  useEffect(() => {
    if (data) {
      const { new_credit_request, new_bookings, new_callbacks } = data.email;
      setInitialValue(new_credit_request, setCredits);
      setInitialValue(new_bookings, setBookings);
      setInitialValue(new_callbacks, setCallbacks);
    }
  }, [data]);

  const onDelete = (id: NoticationsFormEmail['id'], setter: SetterType) => {
    setter((prevArr) => prevArr.filter((v) => v.id !== id));
  };

  const onChange = (
    email: NoticationsFormEmail,
    listLen: number,
    setter: SetterType
  ) => {
    const shouldDelete = !email.email && listLen > 1;

    if (shouldDelete) {
      onDelete(email.id, setter);
      return;
    }

    setter((prevArr) =>
      prevArr.map((v) => {
        if (v.id === email.id) {
          return email;
        }
        return v;
      })
    );
  };

  const onAddClick = (setter: SetterType) => {
    setter((prevArr) => [...prevArr, ...getInitialArr()]);
  };

  const [saving, setSaving] = useState(false);
  const onSaveClick = async () => {
    setSaving(true);

    try {
      await setUpNotifications({
        email: {
          new_credit_request: prepareEmailsForSave(credits),
          new_callbacks: prepareEmailsForSave(callbacks),
          new_bookings: prepareEmailsForSave(bookings)
        }
      });
      showAlert({ type: 'success', text: t('common.saved') });
    } catch (error) {
      showAlert({ error });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      {isLoading && !data && <Spinner centered />}

      {data && (
        <>
          <ul className={cls.groups}>
            <NotificationsFormGroup
              title={t('notification.creditRequest')}
              emails={credits}
              onChange={(e) => onChange(e, credits.length, setCredits)}
              onDelete={(id) => onDelete(id, setCredits)}
              onAddClick={() => onAddClick(setCredits)}
              disabled={isLoading || saving}
            />
            <NotificationsFormGroup
              title={t('notification.rentRequest')}
              emails={bookings}
              onChange={(e) => onChange(e, bookings.length, setBookings)}
              onDelete={(id) => onDelete(id, setBookings)}
              onAddClick={() => onAddClick(setBookings)}
              disabled={isLoading || saving}
            />
            <NotificationsFormGroup
              title={t('notification.callbackRequest')}
              emails={callbacks}
              onChange={(e) => onChange(e, callbacks.length, setCallbacks)}
              onDelete={(id) => onDelete(id, setCallbacks)}
              onAddClick={() => onAddClick(setCallbacks)}
              disabled={isLoading || saving}
            />
          </ul>

          <Button
            onClick={onSaveClick}
            loading={saving}
            disabled={saving || isLoading}
            color="green"
            fullWidth
          >
            {t('common.save')}
          </Button>
        </>
      )}
    </div>
  );
};
