import { Mileage } from '@/modules/showroom/advert/create/Mileage/Mileage';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { SLUG_NEW } from '@/utils/consts';

export function MileageWrap() {
  const mileage = useUpdateAdvertStore((s) => s.mileage);
  const setMileage = useUpdateAdvertStore((s) => s.setMileage);
  const isMileageFilled = useUpdateAdvertStore((s) => s.isMileageFilled);
  const setMileageFilled = useUpdateAdvertStore((s) => s.setMileageFilled);

  const type = useUpdateAdvertStore((s) => s.type);
  const isNewCar = type?.slug === SLUG_NEW;

  return (
    <Mileage
      mileage={mileage}
      setMileage={setMileage}
      isMileageFilled={isMileageFilled}
      setMileageFilled={setMileageFilled}
      isNewCar={isNewCar}
    />
  );
}
