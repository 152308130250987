import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getDocName,
  handleText,
  isDocContent,
  isImageContent,
  isLocationContent,
  isVideoContent
} from '@/modules/chat/Detail/Messages/Message/helpers';
import { ChatMessageWithReplied } from '@/modules/chat/types';

import cls from './FormMessage.module.scss';
import { ReplyIcon, EditIcon, CloseIcon } from './icons';

type Props = {
  isReply?: boolean;
  message: ChatMessageWithReplied;
  onCloseClick: () => void;
};

export const FormMessage: React.FC<Props> = ({
  isReply,
  message,
  onCloseClick
}) => {
  const { t } = useTranslation();

  const { author_name, text, content } = message;

  const firstContent = !!content && content.length > 0 ? content[0] : null;
  const isImage = firstContent ? isImageContent(firstContent.type) : false;
  const isVideo = firstContent ? isVideoContent(firstContent.type) : false;
  const isDoc = firstContent ? isDocContent(firstContent.type) : false;
  const isLoc = firstContent ? isLocationContent(firstContent.type) : false;

  const imgSrc = firstContent
    ? firstContent.thumbnail_url || firstContent.url
    : '';

  return (
    <div className={cls.root}>
      <div className={cls.icon}>{isReply ? <ReplyIcon /> : <EditIcon />}</div>

      <div className={cls.content}>
        {author_name && <b className={cls.name}>{author_name}</b>}
        {text && (
          <p
            className={cls.text}
            dangerouslySetInnerHTML={{
              __html: text ? handleText(text) : ''
            }}
          />
        )}

        {!text && firstContent && (
          <>
            {isImage && imgSrc && (
              <p className={cn(cls.text, cls.preview_img_text)}>
                <img
                  className={cls.preview_img}
                  src={imgSrc}
                  width={20}
                  height={20}
                  alt=""
                />
                {t('chat.content.image')}
              </p>
            )}

            {isVideo && <p className={cls.text}>{t('chat.content.video')}</p>}

            {isLoc && <p className={cls.text}>{t('chat.content.location')}</p>}

            {isDoc && (
              <p className={cls.text}>
                {getDocName(firstContent) || t('chat.content.file')}
              </p>
            )}
          </>
        )}
      </div>

      <button className={cls.close_btn} type="button" onClick={onCloseClick}>
        <CloseIcon />
      </button>
    </div>
  );
};
