export function ChevronIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.828 10C13.8252 9.74392 13.7296 9.52325 13.5269 9.32804L7.66096 3.58131C7.49015 3.41668 7.2865 3.33398 7.04053 3.33398C6.55612 3.33398 6.16675 3.71447 6.16675 4.2037C6.16675 4.44214 6.26375 4.66281 6.4317 4.83421L11.7275 9.99859L6.4317 15.1658C6.26511 15.3358 6.16675 15.5516 6.16675 15.7976C6.16675 16.2855 6.55612 16.6673 7.04053 16.6673C7.28379 16.6673 7.49015 16.5847 7.66096 16.4138L13.5269 10.6719C13.7309 10.4705 13.828 10.2547 13.828 10Z"
        fill="currentColor"
      />
    </svg>
  );
}
