import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouteUnload } from '@/hooks/useRouteUnload';
import { useRegSpecsStore } from '@/modules/regional-specs/store';
import { scrollToBottom } from '@/modules/showroom/advert/create/helpers';
import { StickyBar } from '@/modules/showroom/advert/create/StickyBar/StickyBar';
import { StickyMsg } from '@/modules/showroom/advert/create/StickyMsg/StickyMsg';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { AdditionalInfo } from '@/modules/showroom/advert/update/AdditionalInfo/AdditionalInfo';
import { BrandWrap } from '@/modules/showroom/advert/update/BrandWrap';
import { CheckList } from '@/modules/showroom/advert/update/CheckList';
import { HeaderWrap } from '@/modules/showroom/advert/update/HeaderWrap';
import { ModelWrap } from '@/modules/showroom/advert/update/ModelWrap';
import { SpecsWrap } from '@/modules/showroom/advert/update/SpecsWrap';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { VinSuggestWrap } from '@/modules/showroom/advert/update/VinSuggestWrap';
import { useTransportOwnersStore } from '@/modules/transport/owner/store';
import { TransportBrand } from '@/modules/transport/types';
import { EditorContainer } from '@/ui/layouts/EditorLayout/EditorContainer';
import { EditorSections } from '@/ui/layouts/EditorLayout/EditorSections';
import { EditorSectionsWrap } from '@/ui/layouts/EditorLayout/EditorSectionsWrap';
import { isLocalDev } from '@/utils/consts';

type Props = {
  detail: AdvertAuthorDetail;
  brands: TransportBrand[];
};

export function UpdateAdvert({ detail, brands }: Props) {
  const { t } = useTranslation();

  const storeDetail = useUpdateAdvertStore((s) => s.detail);
  const handleDetail = useUpdateAdvertStore((s) => s.handleDetail);

  useEffect(() => {
    handleDetail(detail);
  }, [handleDetail, detail]);

  const isVinSuggestFilled = useUpdateAdvertStore((s) => s.isVinSuggestFilled);
  const brand = useUpdateAdvertStore((s) => s.brand);
  const model = useUpdateAdvertStore((s) => s.model);
  const color = useUpdateAdvertStore((s) => s.color);
  const isSpecsFilled = useUpdateAdvertStore((s) => s.isSpecsFilled);
  const showSpecs = !!(brand && model);

  const onStepChange = useCallback(() => {
    if (!isSpecsFilled) scrollToBottom();
  }, [isSpecsFilled]);

  const isRejected = detail?.status.title === AdvertStatusType.rejected;
  const updatesRejectReason = detail?.updates_reject_reason || '';
  const isFullScrollStickyMsg = isRejected || !!updatesRejectReason;

  // Handle page leave
  const showPageLeaveWarn = useUpdateAdvertStore((s) => s.showPageLeaveWarn);
  useRouteUnload(isLocalDev ? false : showPageLeaveWarn, t('leaveWarn'));

  // Fetch lists
  const fetchOwners = useTransportOwnersStore((s) => s.fetchData);
  const fetchRegSpecs = useRegSpecsStore((s) => s.fetchData);
  useEffect(() => {
    fetchOwners();
    fetchRegSpecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!storeDetail) return <></>;

  return (
    <>
      <EditorContainer>
        {!isVinSuggestFilled && (
          <EditorSectionsWrap>
            <EditorSections center>
              <VinSuggestWrap />
            </EditorSections>
          </EditorSectionsWrap>
        )}

        {isVinSuggestFilled && (
          <>
            <CheckList />

            <EditorSectionsWrap>
              <div
                style={{ display: 'flex', alignItems: 'flex-start', gap: 36 }}
              >
                <EditorSections>
                  <div
                    className="box box-first"
                    style={{ marginBottom: isSpecsFilled && color ? 0 : 80 }}
                  >
                    <HeaderWrap />
                    <BrandWrap brands={brands} />
                    {brand && <ModelWrap brand={brand} />}

                    {showSpecs && (
                      <SpecsWrap
                        decodeInfo={null}
                        brand={brand}
                        model={model}
                        onStepChange={onStepChange}
                      />
                    )}
                  </div>
                </EditorSections>

                {!isFullScrollStickyMsg && <StickyMsg />}
              </div>

              <EditorSections>
                {isSpecsFilled && <AdditionalInfo />}
              </EditorSections>
            </EditorSectionsWrap>

            {isFullScrollStickyMsg && <StickyMsg />}
          </>
        )}
      </EditorContainer>

      {isVinSuggestFilled && <StickyBar />}
    </>
  );
}
