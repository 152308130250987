export function CloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 22.6411C5.84372 23.1157 6.66308 23.1096 7.12564 22.6471L14.0021 15.7706L20.8746 22.6451C21.3452 23.1157 22.1637 23.1229 22.6403 22.6391C23.117 22.1533 23.119 21.3531 22.6484 20.8805L15.7759 13.9968L22.6484 7.12435C23.119 6.65375 23.1262 5.84444 22.6403 5.3678C22.1545 4.88199 21.3452 4.87998 20.8746 5.35975L14.0021 12.2322L7.12564 5.35774C6.66308 4.88803 5.83455 4.87081 5.35791 5.36579C4.88328 5.8516 4.88932 6.65978 5.35187 7.12234L12.2284 13.9968L5.35187 20.8845C4.88932 21.345 4.87411 22.1644 5.35791 22.6411Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5935 11.9992C16.5902 11.6919 16.4754 11.4271 16.2322 11.1929L9.19307 4.29679C8.9881 4.09923 8.74373 4 8.44856 4C7.86727 4 7.40002 4.45658 7.40002 5.04366C7.40002 5.32978 7.51643 5.59459 7.71796 5.80028L14.0729 11.9975L7.71796 18.1981C7.51805 18.4022 7.40002 18.6612 7.40002 18.9564C7.40002 19.5418 7.86727 20 8.44856 20C8.74047 20 8.9881 19.9008 9.19307 19.6958L16.2322 12.8055C16.477 12.5638 16.5935 12.3049 16.5935 11.9992Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function BackIcon() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.86426 14.4809C3.86426 14.8358 4.02312 15.1893 4.29383 15.4488L11.3067 22.448C11.5897 22.7197 11.9148 22.8538 12.2437 22.8538C12.9806 22.8538 13.5169 22.319 13.5169 21.6003C13.5169 21.2369 13.3793 20.9129 13.1349 20.6822L11.1448 18.6434L6.8756 14.7874L6.46217 15.5377L10.3425 15.7925H22.8107C23.5961 15.7925 24.1358 15.2514 24.1358 14.4809C24.1358 13.7103 23.5961 13.1692 22.8107 13.1692H10.3425L6.46217 13.424L6.8756 14.1855L11.1448 10.3183L13.1349 8.27709C13.3793 8.04631 13.5169 7.72229 13.5169 7.3589C13.5169 6.64031 12.9806 6.10547 12.2437 6.10547C11.9148 6.10547 11.5897 6.23945 11.3067 6.51125L4.29383 13.5129C4.02312 13.7724 3.86426 14.1259 3.86426 14.4809Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49798 14.6632C12.1493 14.6632 15.1637 11.6446 15.1637 7.99859C15.1637 4.3473 12.144 1.33398 8.49269 1.33398C4.84785 1.33398 1.83337 4.3473 1.83337 7.99859C1.83337 11.6446 4.85314 14.6632 8.49798 14.6632ZM8.49798 13.3504C5.52814 13.3504 3.1515 10.9684 3.1515 7.99859C3.1515 5.02875 5.52285 2.64682 8.49269 2.64682C11.4625 2.64682 13.8509 5.02875 13.8509 7.99859C13.8509 10.9684 11.4678 13.3504 8.49798 13.3504Z"
        fill="currentColor"
      />
      <path
        d="M7.37002 11.5478H9.96693C10.2524 11.5478 10.4763 11.3403 10.4763 11.0514C10.4763 10.7789 10.2524 10.5626 9.96693 10.5626H9.24816V7.51434C9.24816 7.1334 9.05772 6.88141 8.69848 6.88141H7.48321C7.1966 6.88141 6.97388 7.09883 6.97388 7.37136C6.97388 7.6603 7.1966 7.86775 7.48321 7.86775H8.13525V10.5626H7.37002C7.0846 10.5626 6.86072 10.7789 6.86072 11.0514C6.86072 11.3403 7.0846 11.5478 7.37002 11.5478ZM8.45733 5.88353C8.94458 5.88353 9.3286 5.49306 9.3286 5.0093C9.3286 4.51972 8.94458 4.13158 8.45733 4.13158C7.97883 4.13158 7.58722 4.51972 7.58722 5.0093C7.58722 5.49306 7.97883 5.88353 8.45733 5.88353Z"
        fill="currentColor"
      />
    </svg>
  );
}
