import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { UploadItem } from '@/modules/files/upload/helpers';
import { UploadImages } from '@/modules/files/upload/UploadImages/UploadImages';
import { Cover } from '@/modules/showroom/ShowroomEdit/CoverLogo/Cover/Cover';
import { EditIcon } from '@/modules/showroom/ShowroomEdit/CoverLogo/icons';
import { NameRow } from '@/modules/showroom/ShowroomEdit/CoverLogo/NameRow/NameRow';
import { Header } from '@/modules/showroom/ShowroomEdit/Header/Header';
import { MAX_SHOWROOM_NAME_LEN } from '@/modules/showroom/ShowroomEdit/helpers';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Input } from '@/ui/Input/Input';
import { maxLength, preventExtraSpaces } from '@/utils/format';

import cls from './CoverLogo.module.scss';

const LOGO_FILE_SIZE_MB = 2;

type Props = {
  name?: string;
  setName?: (v: string) => void;
  logoUrl: string;
  setLogoUrl: (v: string) => void;
  coverUrl: string;
  setCoverUrl: (v: string) => void;
};

export function CoverLogo({
  name,
  setName,
  logoUrl,
  setLogoUrl,
  coverUrl,
  setCoverUrl
}: Props) {
  const { t } = useTranslation();
  const openUploadFn = useRef<Nullable<() => void>>(null);

  const onLogoUpload = (v: UploadItem[]) => {
    if (v[0]) {
      setLogoUrl(v[0].response.url);
    }
  };

  return (
    <div className={cls.root}>
      <Header
        coverNode={
          <Cover
            name={name || ''}
            logoUrl={logoUrl}
            coverUrl={coverUrl}
            setCoverUrl={setCoverUrl}
          />
        }
        logoNode={
          <div className={cls.logo}>
            <UploadImages
              emitOpenFn={(fn) => {
                openUploadFn.current = fn;
              }}
              onUpload={onLogoUpload}
              maxFileSizeMb={LOGO_FILE_SIZE_MB}
              multiple={false}
              aspect={1}
              secondaryStyle
              crop
              square
            />
            {logoUrl && (
              <>
                <ImgSkeleton
                  className={cls.logo_img}
                  src={logoUrl}
                  alt=""
                  imgProxyWidth={128}
                  imgProxyHeight={128}
                />
                <button
                  className={cls.edit_btn}
                  onClick={() => openUploadFn.current && openUploadFn.current()}
                >
                  <EditIcon />
                </button>
              </>
            )}
          </div>
        }
        rowNode={
          setName ? (
            <Input
              value={name || ''}
              onChange={(e) =>
                setName(
                  maxLength(
                    preventExtraSpaces(e.currentTarget.value.trimStart()),
                    MAX_SHOWROOM_NAME_LEN
                  )
                )
              }
              label={t('name')}
            />
          ) : (
            <NameRow name={name || ''} />
          )
        }
      />
    </div>
  );
}
