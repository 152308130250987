import { MAX_EMAIL_LENGTH } from '@/utils/validate';

export function onlyNumbers(value: string, allowedChars = ''): string {
  const reg = new RegExp(`[^0-9${allowedChars}]`, 'g');
  return value.trim().replace(reg, '');
}

export function onlyNumbersLatin(value: string): string {
  return value
    .trim()
    .replace(/[^A-Za-z0-9]+/g, '')
    .toUpperCase();
}

export function onlyNumbersWithoutTrim(
  value: string,
  allowedChars = ''
): string {
  const reg = new RegExp(`[^0-9${allowedChars}]`, 'g');
  return value.replace(reg, '');
}

export function numberInRange(value: string, min: number, max: number) {
  if (typeof value === 'undefined' || value === null) return '';
  if (value[value.length - 1] === '.') return value;

  const valueInt = Number(onlyNumbers(value, '.'));

  if (valueInt < min) return min.toString();
  if (valueInt > max) return max.toString();
  if (valueInt >= min && valueInt <= max) return value;

  return '';
}

export function formatUsername(value: string): string {
  const reg = new RegExp(`[^a-zA-Z0-9_]`, 'g');
  return value.trim().toLowerCase().replace(reg, '');
}

export function numberWithSpaces(x: number, lang: string): string {
  return x.toLocaleString(lang);
  // const parts = x.toString().split('.');
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  // return parts.join('.');
}

export function numberWithCommas(value: number | string): string {
  const strNumber: string =
    typeof value === 'number' ? value.toString() : value;

  const parts: string[] = strNumber.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
}

export function capitalizeWord(word: string): string {
  const loweredCase = word.toLowerCase();
  return word[0].toUpperCase() + loweredCase.slice(1);
}

export function removeEmojis(str: string) {
  return str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    .replace(/\s+/g, ' ')
    .trim();
}

export function replaceBreaksWithSpaces(str: string): string {
  return str.replace(/ {1,}/g, ' ');
}

export function removeSpaces(word: string): string {
  return word.replace(/\s/g, '');
}

export function maxLength(str: string, max: number): string {
  if (str.length > max) {
    return str.slice(0, max);
  }

  return str;
}

export function declension(
  count: number,
  one: string,
  two: string,
  five: string
): string {
  const last = count % 10;

  if (count >= 12 && last <= 14) {
    return five;
  }

  if (last >= 11 && last <= 14) {
    return five;
  }

  if (last >= 2 && last <= 4) {
    return two;
  }

  if (last === 1) {
    return one;
  }

  return five;
}

export function pluralKey(
  count: number,
  one: string,
  few: string,
  many: string
) {
  const a = count % 100;
  if (a >= 11 && a <= 14) return many;

  const b = count % 10;
  switch (b) {
    case 1:
      return one;
    case 2:
    case 3:
    case 4:
      return few;
    default:
      return many;
  }
}

export function escapePlus(str: string): string {
  return str.replace(/\\\+|\+|:P/g, '\\+');
}

export function bytesToSize(
  bytes: number,
  decimals?: number
): [string, string] {
  const decimalsValue = decimals || 2;

  const k = 1024;
  const dm = decimalsValue < 0 ? 0 : decimalsValue;
  const sizes = ['bytes', 'km', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return [String(parseFloat((bytes / k ** i).toFixed(dm))), sizes[i]];
}

export const bytesToMB = (bytes: number) => bytes / (1024 * 1024);
export const mbToBytes = (mb: number) => mb * (1024 * 1024);

export const preventExtraSpaces = (str: string) => {
  if (str.slice(-2) === '  ') {
    const nameLength = str.length;
    return str.slice(0, nameLength - 2);
  }

  return str;
};

export const formatPhoneNumber = (phone: string) => {
  return onlyNumbersWithoutTrim(
    preventExtraSpaces(phone.trimStart()),
    '\\+\\-\\(\\)\\s'
  );
};

export function formatEmail(email: string): string {
  const allowedCharactersRegex = /[a-zA-Z0-9_.@]/;
  const formattedEmail = email
    .trimStart()
    .split('')
    .filter((char) => allowedCharactersRegex.test(char))
    .join('');

  return maxLength(formattedEmail, MAX_EMAIL_LENGTH);
}

export function removeProtocol(str: string): string {
  const https = 'https://';
  const http = 'http://';

  if (str.startsWith(https)) return str.split(https)[1];
  if (str.startsWith(http)) return str.split(http)[1];

  return str;
}
