import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { OptionI } from '@/app/types';
import { ChangePassword } from '@/modules/showroom/settings/ChangePassword/ChangePassword';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { Tabs } from '@/ui/Tabs/Tabs';

import cls from './SettingsPage.module.scss';

enum TabType {
  changePassword = 'change_password'
}

export function SettingsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Tabs
  const tabs = useMemo<OptionI[]>(
    () => [
      { id: TabType.changePassword, text: t('settings.changePassword.title') }
    ],
    [t]
  );
  const { tabId } = useParams();
  const [tab, setTab] = useState<TabType>(TabType.changePassword);
  const activeTab = tabs.find((tb) => tb.id === tab);
  const onTabChange = (tb: OptionI) => {
    navigate(`/settings/${tb.id}`);
  };

  useEffect(() => {
    if (tabId && Object.values(TabType).includes(tabId as TabType))
      setTab(tabId as TabType);
  }, [tabId]);

  return (
    <div className={cls.root}>
      <LayoutHeader title={t('settings.title')} />
      <PageHead title={t('pageTitle', { title: t('settings.title') })} />

      <header className={cls.header}>
        <Tabs
          variant="underline"
          tabs={tabs}
          active={activeTab}
          onChange={onTabChange}
        />
      </header>

      <div className={cls.main}>
        {tab === TabType.changePassword && <ChangePassword />}
      </div>
    </div>
  );
}
