import { Owner } from '@/modules/showroom/advert/create/Owner/Owner';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function OwnerWrap() {
  const owner = useUpdateAdvertStore((s) => s.owner);
  const setOwner = useUpdateAdvertStore((s) => s.setOwner);
  const isOwnerFilled = useUpdateAdvertStore((s) => s.isOwnerFilled);
  const setOwnerFilled = useUpdateAdvertStore((s) => s.setOwnerFilled);

  return (
    <Owner
      owner={owner}
      setOwner={setOwner}
      isOwnerFilled={isOwnerFilled}
      setOwnerFilled={setOwnerFilled}
    />
  );
}
