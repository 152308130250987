import { useCallback, useEffect, useRef, useState } from 'react';

import { axios } from '@/app/api';
import { useCommonStore } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { useRefState } from '@/hooks/useRefState';
import { CALLBACK_API_ROUTES } from '@/modules/callback/api';
import {
  CallbackItem,
  GetCallbackCountRes,
  GetCallbackListReq,
  GetCallbackListRes,
  GetCallbackStatusRes
} from '@/modules/callback/types';
import { showAlert } from '@/utils/network';

export function useCallbackCount(): [GetCallbackCountRes | undefined, boolean] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetCallbackCountRes>();

  const callbackCountKey = useCommonStore((s) => s.callbackCountKey);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetCallbackCountRes>(
        CALLBACK_API_ROUTES.getCount
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load, callbackCountKey]);

  return [data, loading];
}

export function useCallbackStatus(): [
  GetCallbackStatusRes | undefined,
  boolean,
  () => void
] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetCallbackStatusRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetCallbackStatusRes>(
        CALLBACK_API_ROUTES.getStatus
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, loading, load];
}

type UseCallbackListPg = {
  isAllLoaded: boolean;
  isLoading: boolean;
  data: CallbackItem[] | undefined;
  load: (skip: number) => void;
};

const LOAD_COUNT = 20;

export function useCallbackListPg(search: string): UseCallbackListPg {
  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoading, setLoading, isLoadingRef] = useRefState<boolean>(false);
  const [data, setData] = useState<CallbackItem[]>();

  const abortController = useRef<Nullable<AbortController>>(null);

  const load = useCallback(
    async (skip: number) => {
      if (isLoadingRef.current) return;

      setLoading(true);
      if (!skip) {
        setData([]);
      }

      try {
        const queryReq: GetCallbackListReq = {
          limit: LOAD_COUNT,
          skip,
          search
        };

        if (abortController.current) abortController.current.abort();
        const ab = new window.AbortController();
        abortController.current = ab;

        const r = await axios.get<GetCallbackListRes>(
          CALLBACK_API_ROUTES.getList,
          { params: queryReq, signal: ab.signal }
        );

        const loaded = r.data;
        const len = loaded.length;

        setData((prev) => {
          if (!prev || skip === 0) {
            return loaded;
          }

          return [...prev, ...loaded];
        });
        setAllLoaded(len < LOAD_COUNT);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  return { isAllLoaded, isLoading, data, load };
}
