import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSignupStore } from '@/modules/showroom/CreateShowroom/store';
import { BaseInfo } from '@/modules/showroom/ShowroomEdit/BaseInfo/BaseInfo';
import { MAX_SHOWROOM_DESCR_LEN } from '@/modules/showroom/ShowroomEdit/helpers';
import { Button } from '@/ui/Button/Button';
import { validateSiteUrl } from '@/utils/validate';

import cls from './CreateShowroom.module.scss';

export function ShowroomAbout() {
  const { t } = useTranslation();

  // Values
  const name = useSignupStore((s) => s.name);
  const setName = useSignupStore((s) => s.setName);
  const description = useSignupStore((s) => s.description);
  const setDescription = useSignupStore((s) => s.setDescription);
  const isDescrValid = description.length <= MAX_SHOWROOM_DESCR_LEN;

  const siteUrl = useSignupStore((s) => s.siteUrl);
  const setSiteUrl = useSignupStore((s) => s.setSiteUrl);
  const isSiteUrlValid = siteUrl ? validateSiteUrl(siteUrl) : true;
  const isValid = !!name && isSiteUrlValid && isDescrValid;

  const navigate = useNavigate();
  const onNextClick = () => {
    navigate('/showroom/create/design');
  };

  return (
    <>
      <div className={cls.header}>
        <h2 className={cls.title}>{t('showroom.about')}</h2>
      </div>
      <BaseInfo
        name={name}
        setName={setName}
        isSiteValid={isSiteUrlValid}
        siteUrl={siteUrl}
        setSiteUrl={setSiteUrl}
        description={description}
        setDescription={setDescription}
        fieldsOnly
      />
      <div className={cls.btn}>
        <Button
          onClick={onNextClick}
          disabled={!isValid}
          color="blue"
          fullWidth
        >
          {t('common.continue')}
        </Button>
      </div>
    </>
  );
}
