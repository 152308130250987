import cn from 'classnames';

import cls from './Switch.module.scss';

interface Props {
  id: string;
  checked: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  primary?: boolean;
  black?: boolean;
  green?: boolean;
}

export const Switch: React.FC<Props> = ({
  id,
  checked,
  onChange,
  name,
  disabled,
  readOnly,
  primary,
  black,
  green
}) => {
  return (
    <div
      className={cn(cls.container, {
        [cls.container_checked]: checked,
        [cls.container_primary]: primary,
        [cls.container_black]: black,
        [cls.container_green]: green
      })}
    >
      <input
        name={name}
        className={cls.input}
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        type="checkbox"
        readOnly={readOnly}
      />
      <label className={cls.label} htmlFor={id}>
        <span className={cls.circle} />
      </label>
    </div>
  );
};
