export function AddIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66675 7.99983C2.66675 8.39122 2.98998 8.71039 3.37732 8.71039H7.29065V12.6237C7.29065 13.0099 7.60865 13.3332 8.00007 13.3332C8.39147 13.3332 8.71585 13.0099 8.71585 12.6237V8.71039H12.6239C13.0102 8.71039 13.3334 8.39122 13.3334 7.99983C13.3334 7.60841 13.0102 7.28403 12.6239 7.28403H8.71585V3.37707C8.71585 2.98973 8.39147 2.6665 8.00007 2.6665C7.60865 2.6665 7.29065 2.98973 7.29065 3.37707V7.28403H3.37732C2.98998 7.28403 2.66675 7.60841 2.66675 7.99983Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function MarkIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.5C8.62826 17.5 8.97342 17.3174 9.21323 16.9516L17.1225 4.62165C17.2997 4.34431 17.3708 4.11224 17.3708 3.88387C17.3708 3.30445 16.9633 2.9046 16.3779 2.9046C15.9625 2.9046 15.7214 3.04428 15.4694 3.43976L8.15705 15.0568L4.38887 10.2117C4.14367 9.88698 3.88636 9.75098 3.52286 9.75098C2.92207 9.75098 2.5 10.1715 2.5 10.7524C2.5 11.002 2.59323 11.2541 2.80309 11.5091L7.16264 16.9635C7.45294 17.3266 7.76679 17.5 8.19071 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SettingsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.699 16.1344C13.7259 16.1344 14.5666 15.2937 14.5666 14.2654C14.5666 13.2384 13.7273 12.3977 12.699 12.3977C11.6706 12.3977 10.824 13.2384 10.824 14.2654C10.824 15.2937 11.6706 16.1344 12.699 16.1344ZM12.699 15.1571C12.1979 15.1571 11.8072 14.7605 11.8072 14.2654C11.8072 13.7571 12.1979 13.375 12.699 13.375C13.2013 13.375 13.5893 13.7571 13.5893 14.2654C13.5893 14.7605 13.2013 15.1571 12.699 15.1571ZM11.4611 13.5727H3.17886C2.79782 13.5727 2.5 13.8857 2.5 14.2654C2.5 14.6412 2.79913 14.9521 3.17886 14.9521H11.4611V13.5727ZM16.839 13.5727H14.0171V14.9521H16.839C17.1962 14.9521 17.5 14.6438 17.5 14.2654C17.5 13.8831 17.1975 13.5727 16.839 13.5727ZM7.38371 11.6105C8.40811 11.6105 9.25266 10.7639 9.25266 9.73683C9.25266 8.70852 8.41204 7.86787 7.38371 7.86787C6.35668 7.86787 5.51604 8.70852 5.51604 9.73683C5.51604 10.7639 6.35668 11.6105 7.38371 11.6105ZM7.38371 10.6273C6.88991 10.6273 6.4933 10.2306 6.4933 9.73683C6.4933 9.23316 6.88991 8.84513 7.38371 8.84513C7.88739 8.84513 8.27542 9.23316 8.27542 9.73683C8.27542 10.2306 7.88739 10.6273 7.38371 10.6273ZM3.155 9.04417C2.79782 9.04417 2.5 9.35712 2.5 9.73683C2.5 10.1113 2.79913 10.4236 3.155 10.4236H6.09058V9.04417H3.155ZM16.8152 9.04417H8.60452V10.4236H16.8152C17.1962 10.4236 17.5 10.1152 17.5 9.73683C17.5 9.35319 17.1975 9.04417 16.8152 9.04417ZM12.699 7.07596C13.7259 7.07596 14.5666 6.23531 14.5666 5.20697C14.5666 4.17995 13.7259 3.33334 12.699 3.33334C11.6706 3.33334 10.824 4.17995 10.824 5.20697C10.824 6.23531 11.6706 7.07596 12.699 7.07596ZM12.699 6.0987C12.1979 6.0987 11.8072 5.70077 11.8072 5.20101C11.8072 4.69863 12.1979 4.3106 12.699 4.3106C13.2013 4.3106 13.5893 4.69863 13.5893 5.20101C13.5893 5.70077 13.2013 6.0987 12.699 6.0987ZM11.4923 4.52026H3.17886C2.79782 4.52026 2.5 4.82725 2.5 5.20697C2.5 5.58278 2.79913 5.89966 3.17886 5.89966H11.4923V4.52026ZM16.839 4.52026H13.9323V5.89966H16.839C17.1962 5.89966 17.5 5.5854 17.5 5.20697C17.5 4.82463 17.1975 4.52026 16.839 4.52026Z"
        fill="currentColor"
      />
    </svg>
  );
}
