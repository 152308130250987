import { useEffect, useMemo } from 'react';
import useWebSocket, { Options } from 'react-use-websocket';

import { useCommonStore } from '@/app/common-store';
import { useAuthed } from '@/modules/auth/hooks';
import { ClientCommunicationRes } from '@/modules/client-comm/types';
import { SOCKET_API_URL } from '@/utils/consts';

export function useClientComm(): ClientCommunicationRes | undefined {
  const [isAuthed, , auth] = useAuthed();
  const authToken = auth?.access_token;
  const data = useCommonStore((s) => s.clientCommunication);
  const setData = useCommonStore((s) => s.setClientCommunication);

  const socketOptions = useMemo<Options>(
    () => ({
      shouldReconnect: () => true
    }),
    []
  );

  const { lastMessage } = useWebSocket<ClientCommunicationRes>(
    `${SOCKET_API_URL}/ws/dealer/client-communication?token=${authToken}`,
    socketOptions,
    isAuthed && !!authToken
  );

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const parsed = JSON.parse(lastMessage.data);
      if (parsed && typeof parsed === 'object') {
        const res = parsed as ClientCommunicationRes;
        setData(res);
      }
    }
  }, [lastMessage, setData]);

  return data;
}
