export function ChevronIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58537 12.006C3.42353 12.1644 3.33334 12.3755 3.33334 12.6186C3.33334 13.109 3.71365 13.492 4.20265 13.492C4.44851 13.492 4.67525 13.3998 4.83423 13.2285L10.3716 7.56124H9.63522L15.1672 13.2285C15.331 13.4012 15.5591 13.492 15.7974 13.492C16.285 13.492 16.6667 13.109 16.6667 12.6186C16.6667 12.3742 16.5779 12.163 16.4133 12.006L10.6816 6.14014C10.4913 5.93885 10.2597 5.83572 10.0038 5.83301C9.74302 5.83301 9.51629 5.93749 9.31981 6.14014L3.58537 12.006Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ExitIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5478 18.2324H3.45214C1.81151 18.2324 1.00096 17.4121 1.00096 15.7422V4.25781C1.00096 2.59766 1.81151 1.76758 3.45214 1.76758H11.5478C13.1885 1.76758 14.0088 2.59766 14.0088 4.25781V7.76367H12.5928V4.27734C12.5928 3.57422 12.2119 3.17383 11.4795 3.17383H3.53026C2.78807 3.17383 2.40721 3.57422 2.40721 4.27734V15.7227C2.40721 16.4257 2.78807 16.8262 3.53026 16.8262H11.4795C12.2119 16.8262 12.5928 16.4257 12.5928 15.7227V12.2363H14.0088V15.7422C14.0088 17.4121 13.1885 18.2324 11.5478 18.2324Z"
        fill="currentColor"
      />
      <path
        d="M8.30564 10.6933H15.9326L17.0947 10.6347L16.6553 11.0547L15.4931 12.1289C15.3466 12.2461 15.288 12.4219 15.288 12.5977C15.288 12.959 15.542 13.2324 15.8935 13.2324C16.0791 13.2324 16.2255 13.1543 16.3525 13.0273L18.7646 10.5078C18.9404 10.332 18.999 10.1757 18.999 10C18.999 9.82422 18.9404 9.66797 18.7646 9.49219L16.3525 6.97265C16.2255 6.83594 16.0791 6.76758 15.8935 6.76758C15.542 6.76758 15.288 7.03125 15.288 7.39258C15.288 7.56836 15.3466 7.7539 15.4931 7.87109L16.6553 8.94531L17.1045 9.36524L15.9326 9.30664H8.30564C7.94432 9.30664 7.63182 9.6289 7.63182 10C7.63182 10.3711 7.94432 10.6933 8.30564 10.6933Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function TranslateIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99743 17.6722C12.2714 17.6722 14.1196 14.3763 14.1196 10.0124C14.1196 5.62719 12.2795 2.32992 9.99743 2.32992C7.71682 2.32992 5.87527 5.62719 5.87527 10.0124C5.87527 14.3763 7.72344 17.6722 9.99743 17.6722ZM9.99743 3.37836C11.5564 3.37836 12.9706 6.47578 12.9706 10.0124C12.9706 13.4932 11.5564 16.6171 9.99743 16.6171C8.43992 16.6171 7.02566 13.4976 7.02566 10.0124C7.02566 6.47578 8.43992 3.37836 9.99743 3.37836ZM9.43839 2.44138V17.5204H10.5646V2.44138H9.43839ZM9.99743 12.8284C7.47713 12.8284 5.23476 13.4802 4.12163 14.5133L4.98555 15.2277C6.02835 14.4099 7.80876 13.9546 9.99743 13.9546C12.1875 13.9546 13.968 14.4099 15.0093 15.2277L15.8732 14.5133C14.7615 13.4802 12.5192 12.8284 9.99743 12.8284ZM17.3906 9.42915H2.60574V10.5553H17.3906V9.42915ZM9.99743 7.20452C12.5192 7.20452 14.7615 6.55272 15.8732 5.5196L15.0093 4.8052C13.968 5.61638 12.1875 6.07834 9.99743 6.07834C7.81021 6.07834 6.03125 5.61638 4.98555 4.8052L4.12163 5.5196C5.23476 6.55272 7.47713 7.20452 9.99743 7.20452ZM9.99743 18.3285C14.5615 18.3285 18.3296 14.5553 18.3296 9.99774C18.3296 5.43363 14.5549 1.66699 9.99082 1.66699C5.43477 1.66699 1.66667 5.43363 1.66667 9.99774C1.66667 14.5553 5.44138 18.3285 9.99743 18.3285ZM9.99743 17.0371C6.14851 17.0371 2.95947 13.8481 2.95947 9.99774C2.95947 6.14738 6.1419 2.95834 9.99082 2.95834C13.8412 2.95834 17.0368 6.14738 17.0368 9.99774C17.0368 13.8481 13.8478 17.0371 9.99743 17.0371Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.4997C8.62826 17.4997 8.97342 17.3171 9.21323 16.9513L17.1225 4.62134C17.2997 4.344 17.3708 4.11193 17.3708 3.88357C17.3708 3.30415 16.9633 2.9043 16.3779 2.9043C15.9625 2.9043 15.7214 3.04398 15.4694 3.43946L8.15705 15.0565L4.38887 10.2114C4.14367 9.88667 3.88636 9.75067 3.52286 9.75067C2.92207 9.75067 2.5 10.1712 2.5 10.7521C2.5 11.0017 2.59323 11.2538 2.80309 11.5088L7.16264 16.9632C7.45294 17.3263 7.76679 17.4997 8.19071 17.4997Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RightChevronIcon() {
  return (
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5965 11.999C10.5945 11.6692 10.4777 11.3863 10.2145 11.1252L3.3539 4.32802C3.14177 4.10663 2.87316 4 2.55644 4C1.9117 4 1.40341 4.497 1.40341 5.13767C1.40341 5.44513 1.53873 5.73723 1.75379 5.96675L7.85448 11.997L1.75379 18.0312C1.54076 18.2608 1.40341 18.5436 1.40341 18.8623C1.40341 19.503 1.9117 20 2.55644 20C2.87113 20 3.14177 19.8934 3.3539 19.672L10.2145 12.8728C10.4797 12.6116 10.5965 12.3268 10.5965 11.999Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SwitchThemeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9845 2.11075V2.82282H11.8008C11.2022 2.57514 10.5934 2.33779 9.9845 2.11075ZM9.9845 3.4317V4.17473H13.8854C13.8648 3.91673 13.8338 3.66906 13.8028 3.4317H9.9845ZM9.9845 5.17577V5.91879H15.8049C15.7946 5.6608 15.7842 5.41312 15.7636 5.17577H9.9845ZM9.9845 6.9095V7.65254H17.2084C17.2084 7.40486 17.1981 7.15719 17.1878 6.9095H9.9845ZM9.9845 8.65357V9.3966H17.2291C17.2291 9.14892 17.2188 8.90124 17.2188 8.65357H9.9845ZM9.9845 10.3976V11.1407H17.2188C17.2188 10.893 17.2291 10.6453 17.2291 10.3976H9.9845ZM9.9845 12.1417V12.8847H17.1878C17.1981 12.637 17.2084 12.3894 17.2084 12.1417H9.9845ZM9.9845 13.8858V14.6184H15.7636C15.7842 14.3811 15.7946 14.1334 15.8049 13.8858H9.9845ZM9.9845 15.8156V16.5587H13.8028C13.8338 16.3212 13.8648 16.0736 13.8854 15.8156H9.9845ZM9.9845 17.1675V17.8796C10.5934 17.6525 11.2022 17.4152 11.8008 17.1675H9.9845ZM9.99482 18.3234C14.5459 18.3234 18.3333 14.5462 18.3333 9.99516C18.3333 5.44408 14.5356 1.66699 9.9845 1.66699C5.44374 1.66699 1.66666 5.44408 1.66666 9.99516C1.66666 14.5462 5.45406 18.3234 9.99482 18.3234ZM9.99482 16.7547C6.24869 16.7547 3.25592 13.7516 3.25592 9.99516C3.25592 6.23871 6.23838 3.23562 9.9845 3.23562C13.7409 3.23562 16.7543 6.23871 16.7543 9.99516C16.7543 13.7516 13.7513 16.7547 9.99482 16.7547Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SettingsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.699 16.1341C13.7259 16.1341 14.5666 15.2934 14.5666 14.2651C14.5666 13.238 13.7273 12.3974 12.699 12.3974C11.6706 12.3974 10.824 13.238 10.824 14.2651C10.824 15.2934 11.6706 16.1341 12.699 16.1341ZM12.699 15.1568C12.1979 15.1568 11.8072 14.7602 11.8072 14.2651C11.8072 13.7567 12.1979 13.3747 12.699 13.3747C13.2013 13.3747 13.5893 13.7567 13.5893 14.2651C13.5893 14.7602 13.2013 15.1568 12.699 15.1568ZM11.4611 13.5724H3.17886C2.79782 13.5724 2.5 13.8854 2.5 14.2651C2.5 14.6409 2.79913 14.9518 3.17886 14.9518H11.4611V13.5724ZM16.839 13.5724H14.0171V14.9518H16.839C17.1962 14.9518 17.5 14.6435 17.5 14.2651C17.5 13.8828 17.1975 13.5724 16.839 13.5724ZM7.38371 11.6101C8.40811 11.6101 9.25266 10.7635 9.25266 9.73649C9.25266 8.70818 8.41204 7.86753 7.38371 7.86753C6.35668 7.86753 5.51604 8.70818 5.51604 9.73649C5.51604 10.7635 6.35668 11.6101 7.38371 11.6101ZM7.38371 10.6269C6.88991 10.6269 6.4933 10.2303 6.4933 9.73649C6.4933 9.23282 6.88991 8.8448 7.38371 8.8448C7.88739 8.8448 8.27542 9.23282 8.27542 9.73649C8.27542 10.2303 7.88739 10.6269 7.38371 10.6269ZM3.155 9.04383C2.79782 9.04383 2.5 9.35678 2.5 9.73649C2.5 10.111 2.79913 10.4232 3.155 10.4232H6.09058V9.04383H3.155ZM16.8152 9.04383H8.60452V10.4232H16.8152C17.1962 10.4232 17.5 10.1149 17.5 9.73649C17.5 9.35285 17.1975 9.04383 16.8152 9.04383ZM12.699 7.07562C13.7259 7.07562 14.5666 6.23497 14.5666 5.20664C14.5666 4.17962 13.7259 3.33301 12.699 3.33301C11.6706 3.33301 10.824 4.17962 10.824 5.20664C10.824 6.23497 11.6706 7.07562 12.699 7.07562ZM12.699 6.09836C12.1979 6.09836 11.8072 5.70044 11.8072 5.20067C11.8072 4.6983 12.1979 4.31027 12.699 4.31027C13.2013 4.31027 13.5893 4.6983 13.5893 5.20067C13.5893 5.70044 13.2013 6.09836 12.699 6.09836ZM11.4923 4.51993H3.17886C2.79782 4.51993 2.5 4.82691 2.5 5.20664C2.5 5.58244 2.79913 5.89932 3.17886 5.89932H11.4923V4.51993ZM16.839 4.51993H13.9323V5.89932H16.839C17.1962 5.89932 17.5 5.58506 17.5 5.20664C17.5 4.82429 17.1975 4.51993 16.839 4.51993Z"
        fill="currentColor"
      />
    </svg>
  );
}
