import { Fragment, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ValidIcon } from '@/modules/showroom/ShowroomEdit/Login/icons';
import { ShowroomBox } from '@/modules/showroom/ShowroomEdit/ShowroomBox';
import { Input } from '@/ui/Input/Input';
import { Spinner } from '@/ui/Spinner/Spinner';
import { APP_URL } from '@/utils/consts';
import { formatUsername } from '@/utils/format';
import { validateUsername } from '@/utils/validate';

import cls from './Login.module.scss';

type Props = {
  login: string;
  setLogin: (v: string) => void;
  sameLogin?: boolean;
  isLoginAvailable?: boolean;
  isLoginValidating?: boolean;
  debouncedLogin?: string;
  fieldsOnly?: boolean;
};

export function Login({
  login,
  setLogin,
  sameLogin,
  isLoginAvailable,
  isLoginValidating,
  debouncedLogin,
  fieldsOnly
}: Props) {
  const { t } = useTranslation();
  const address = `${APP_URL}/${login}`;

  const [touched, setTouched] = useState(false);
  const isValid = validateUsername(login);

  const errorText = useMemo(() => {
    if (sameLogin) return '';

    if (touched && !isValid) {
      return t('showroom.login.invalid');
    }

    if (touched && !isLoginAvailable) {
      return t('showroom.login.invalid');
    }

    return '';
  }, [isLoginAvailable, isValid, sameLogin, t, touched]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setLogin(formatUsername(e.currentTarget.value));
  };

  const Container = fieldsOnly ? Fragment : ShowroomBox;

  const showIsValid =
    touched &&
    isLoginAvailable &&
    !isLoginValidating &&
    debouncedLogin === login;

  return (
    <Container>
      {!fieldsOnly && <h2>{t('shortAddress')}</h2>}

      <div className={cls.field}>
        <div className={cls.instructions}>
          <p className={cls.text}>{t('showroom.login.description')}</p>
          <p className={cls.text}>{t('showroom.login.symbols')}</p>
          <p className={cls.text}>{t('showroom.login.length')}</p>
        </div>

        <div className={cls.login_input}>
          <Input
            value={login}
            onChange={handleChange}
            onBlur={() => setTouched(true)}
            label={t('showroom.username')}
            errorText={errorText}
            suffix={showIsValid && <ValidIcon />}
            name="fixSafariAutocompleteWithSearchWordLol"
            autoComplete="off"
          />
          {showIsValid && (
            <span className={cls.valid}>
              {t('showroom.login.valid', { login: login })}
            </span>
          )}
          {isLoginValidating && (
            <span className={cls.on_validation}>
              <Spinner size={14} color="var(--clr-blue)" />
              {t('showroom.login.validation')}
            </span>
          )}
        </div>

        {showIsValid && (
          <p className={cls.text}>
            <Trans
              t={t}
              i18nKey="showroom.login.url"
              components={{
                personalLink: (
                  <a href={address} target="_blank" rel="noreferrer" />
                )
              }}
              values={{ address }}
            />
          </p>
        )}
      </div>
    </Container>
  );
}
