import { ContactCard } from '@/modules/showroom/contact/ContactsList/ContactCard/ContactCard';
import { ShowroomContact } from '@/modules/showroom/contact/types';

import cls from './ContactsList.module.scss';

type Props = {
  list: ShowroomContact[];
  onEditClick: (contact: ShowroomContact) => void;
  onDeleteClick: (contact: ShowroomContact) => void;
};

export function ContactsList({ list, onEditClick, onDeleteClick }: Props) {
  return (
    <ul className={cls.list}>
      {list.map((c) => (
        <li key={c.id}>
          <ContactCard
            contact={c}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        </li>
      ))}
    </ul>
  );
}
