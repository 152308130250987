import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { SpecsSlice, SpecStep } from '@/modules/showroom/advert/create/types';
import {
  TransportBody,
  TransportBrand,
  TransportColor,
  TransportDriveUnit,
  TransportEngine,
  TransportGeneration,
  TransportModel,
  TransportModification,
  TransportTransmission,
  TransportYear
} from '@/modules/transport/types';
import { GetVinInfoRes } from '@/modules/vin/types';
import { Button } from '@/ui/Button/Button';

import { Body } from './Body/Body';
import { Color } from './Color/Color';
import { Drive } from './Drive/Drive';
import { Engine } from './Engine/Engine';
import { Generation } from './Generation/Generation';
import { Modification } from './Modification/Modification';
import { SpecCollapse } from './SpecCollapse/SpecCollapse';
import cls from './Specs.module.scss';
import { Transmission } from './Transmission/Transmission';
import { Year } from './Year/Year';

type StoreType = Omit<
  SpecsSlice,
  'mileage' | 'setMileage' | 'isMileageFilled' | 'setMileageFilled'
>;

type ExtStoreType = StoreType & {
  interiorColor?: Nullable<TransportColor>;
  setInteriorColor?: (interiorColor: Nullable<TransportColor>) => void;
};

export type Props = {
  decodeInfo: Nullable<GetVinInfoRes>;
  store: ExtStoreType;
  brand: TransportBrand;
  model: TransportModel;
  onStepChange: () => void;
  disableSpecs: boolean;
};

export function Specs({
  decodeInfo,
  store,
  brand,
  model,
  onStepChange,
  disableSpecs
}: Props) {
  const { t } = useTranslation();

  const { step, setStep } = store;
  const { year, setYear } = store;
  const { generation, setGeneration } = store;
  const { body, setBody } = store;
  const { engine, setEngine } = store;
  const { drive, setDrive } = store;
  const { transmission, setTransmission } = store;
  const { modification, setModification } = store;
  const { color, setColor } = store;
  const { interiorColor, setInteriorColor } = store;
  const { isSpecsFilled, setSpecsFilled } = store;

  const hasInteriorColor =
    typeof interiorColor !== 'undefined' && !!setInteriorColor;

  const onStepClick = (s: SpecStep) => {
    return () => {
      setStep(step === s ? null : s);
    };
  };

  const onYearChange = (v: TransportYear) => {
    setYear(v);

    setGeneration(null);
    setBody(null);
    setEngine(null);
    setDrive(null);
    setTransmission(null);
    setModification(null);
    setColor(null);
    if (hasInteriorColor) setInteriorColor(null);

    setStep(SpecStep.gen);
    onStepChange();
  };

  const onGenerationChange = (v: TransportGeneration) => {
    setGeneration(v);

    const shouldScroll = !body;
    setBody(null);
    setEngine(null);
    setDrive(null);
    setTransmission(null);
    setModification(null);
    setColor(null);
    if (hasInteriorColor) setInteriorColor(null);

    setStep(SpecStep.body);
    if (shouldScroll) onStepChange();
  };

  const onBodyChange = (v: TransportBody) => {
    setBody(v);

    const shouldScroll = !engine;
    setEngine(null);
    setDrive(null);
    setTransmission(null);
    setModification(null);
    setColor(null);
    if (hasInteriorColor) setInteriorColor(null);

    setStep(SpecStep.engine);
    if (shouldScroll) onStepChange();
  };

  const onEngineChange = (v: TransportEngine) => {
    setEngine(v);

    const shouldScroll = !drive;
    setDrive(null);
    setTransmission(null);
    setModification(null);
    setColor(null);
    if (hasInteriorColor) setInteriorColor(null);

    setStep(SpecStep.drive);
    if (shouldScroll) onStepChange();
  };

  const onDriveChange = (v: TransportDriveUnit) => {
    setDrive(v);

    const shouldScroll = !transmission;
    setTransmission(null);
    setModification(null);
    setColor(null);
    if (hasInteriorColor) setInteriorColor(null);

    setStep(SpecStep.transmission);
    if (shouldScroll) onStepChange();
  };

  const onTransmissionChange = (v: TransportTransmission) => {
    setTransmission(v);

    const shouldScroll = !modification;
    setModification(null);
    setColor(null);
    if (hasInteriorColor) setInteriorColor(null);

    setStep(SpecStep.mod);
    if (shouldScroll) onStepChange();
  };

  const modName = useMemo(() => {
    const m = modification;
    if (m) {
      if (m.horse_power) {
        const hp = t('horsepower', { value: m.horse_power?.name });
        const v = m.volume?.name;
        const tr = m.transmission.short_name;
        if (hp && v && tr) return `${hp} (${v} ${tr})`;
      }

      return m.name;
    }

    return '';
  }, [modification, t]);

  const onModificationChange = (v: TransportModification) => {
    setModification(v);

    if (hasInteriorColor) {
      const shouldScroll = !color;
      setColor(null);
      setInteriorColor(null);
      setStep(SpecStep.color);
      if (shouldScroll) onStepChange();
    } else {
      setStep(SpecStep.color);
      onStepChange();
    }
  };

  const onColorChange = (v: TransportColor) => {
    setColor(v);
    if (!hasInteriorColor) return;

    const shouldScroll = !interiorColor;
    setInteriorColor(null);
    setStep(SpecStep.interiorColor);
    if (shouldScroll) onStepChange();
  };

  const onInteriorColorChange = (v: TransportColor) => {
    if (setInteriorColor) setInteriorColor(v);
  };

  const onSaveClick = () => {
    setSpecsFilled(true);
    setStep(null);
    scrollToSection(
      hasInteriorColor ? SCROLLTO.photo : SCROLLTO.condition,
      100
    );
  };

  return (
    <div className={cls.root}>
      <h1 className={cls.title} id={SCROLLTO.specs}>
        {t('specs')}
      </h1>

      <ul className={cls.list}>
        <li>
          <SpecCollapse
            label={t('yearOfIssue')}
            value={year?.name}
            showPlaceholder={!!decodeInfo}
            isOpen={step === SpecStep.year}
            onClick={onStepClick(SpecStep.year)}
            disabled={disableSpecs}
          >
            <Year
              yearsList={decodeInfo?.years_of_issue || null}
              year={year}
              model={model}
              onChange={onYearChange}
              disabled={disableSpecs}
            />
          </SpecCollapse>
        </li>

        {year && (
          <li>
            <SpecCollapse
              label={t('generation')}
              value={generation?.name}
              showPlaceholder={!!decodeInfo}
              isOpen={step === SpecStep.gen}
              onClick={onStepClick(SpecStep.gen)}
              disabled={disableSpecs}
            >
              <Generation
                model={model}
                year={year}
                selected={generation}
                onChange={onGenerationChange}
                disabled={disableSpecs}
              />
            </SpecCollapse>
          </li>
        )}

        {year && generation && (
          <li>
            <SpecCollapse
              label={t('body')}
              value={body?.name}
              showPlaceholder={!!decodeInfo}
              isOpen={step === SpecStep.body}
              onClick={onStepClick(SpecStep.body)}
              disabled={disableSpecs}
            >
              <Body
                generation={generation}
                year={year}
                selected={body}
                onChange={onBodyChange}
                disabled={disableSpecs}
              />
            </SpecCollapse>
          </li>
        )}

        {year && generation && body && (
          <li>
            <SpecCollapse
              label={t('engine')}
              value={engine?.name}
              showPlaceholder={!!decodeInfo}
              isOpen={step === SpecStep.engine}
              onClick={onStepClick(SpecStep.engine)}
              disabled={disableSpecs}
            >
              <Engine
                brand={brand}
                model={model}
                generation={generation}
                body={body}
                selected={engine}
                onChange={onEngineChange}
                disabled={disableSpecs}
              />
            </SpecCollapse>
          </li>
        )}

        {engine && year && generation && body && (
          <li>
            <SpecCollapse
              label={t('drive')}
              value={drive?.name}
              showPlaceholder={!!decodeInfo}
              isOpen={step === SpecStep.drive}
              onClick={onStepClick(SpecStep.drive)}
              disabled={disableSpecs}
            >
              <Drive
                brand={brand}
                model={model}
                generation={generation}
                body={body}
                engine={engine}
                selected={drive}
                onChange={onDriveChange}
                disabled={disableSpecs}
              />
            </SpecCollapse>
          </li>
        )}

        {drive && engine && year && generation && body && (
          <li>
            <SpecCollapse
              label={t('transmission')}
              value={transmission?.name}
              showPlaceholder={!!decodeInfo}
              isOpen={step === SpecStep.transmission}
              onClick={onStepClick(SpecStep.transmission)}
              disabled={disableSpecs}
            >
              <Transmission
                brand={brand}
                model={model}
                generation={generation}
                body={body}
                engine={engine}
                driveUnit={drive}
                selected={transmission}
                onChange={onTransmissionChange}
                disabled={disableSpecs}
              />
            </SpecCollapse>
          </li>
        )}

        {body && transmission && drive && engine && year && generation && (
          <li>
            <SpecCollapse
              label={t('modification')}
              value={modName}
              showPlaceholder={!!decodeInfo}
              isOpen={step === SpecStep.mod}
              onClick={onStepClick(SpecStep.mod)}
              disabled={disableSpecs}
            >
              <Modification
                body={body}
                generation={generation}
                engine={engine}
                driveUnit={drive}
                transmission={transmission}
                selected={modification}
                onChange={onModificationChange}
                disabled={disableSpecs}
              />
            </SpecCollapse>
          </li>
        )}

        {body &&
          transmission &&
          drive &&
          engine &&
          year &&
          generation &&
          modification && (
            <li>
              <SpecCollapse
                label={t(hasInteriorColor ? 'bodyColor' : 'color')}
                value={color?.name}
                isOpen={step === SpecStep.color}
                onClick={onStepClick(SpecStep.color)}
                disabled={disableSpecs}
              >
                <Color
                  color={color}
                  onChange={onColorChange}
                  disabled={disableSpecs}
                />
              </SpecCollapse>
            </li>
          )}

        {body &&
          transmission &&
          drive &&
          engine &&
          year &&
          generation &&
          modification &&
          color &&
          hasInteriorColor && (
            <li>
              <SpecCollapse
                label={t('interiorColor')}
                value={interiorColor?.name}
                isOpen={step === SpecStep.interiorColor}
                onClick={onStepClick(SpecStep.interiorColor)}
                disabled={disableSpecs}
              >
                <Color
                  color={interiorColor}
                  onChange={onInteriorColorChange}
                  disabled={disableSpecs}
                />
              </SpecCollapse>
            </li>
          )}
      </ul>

      {!isSpecsFilled && (hasInteriorColor ? !!interiorColor : !!color) && (
        <div className={cls.save_btn}>
          <Button onClick={onSaveClick} color="blue" fullWidth>
            {t('common.continue')}
          </Button>
        </div>
      )}
    </div>
  );
}
