import {
  Nullable,
  NullableValues,
  PaginationReq,
  PhotoType
} from '@/app/types';
import { MyAdvertEngagement } from '@/modules/showroom/advert/my/types';
import { RentTariff } from '@/modules/showroom/advert/rent/tariffs/types';
import { AutoFilterIds } from '@/modules/showroom/advert/search/types';
import {
  AdvertExistedStatus,
  AdvertStatus
} from '@/modules/showroom/advert/types';
import {
  TransportBody,
  TransportBrand,
  TransportColor,
  TransportGeneration,
  TransportModel,
  TransportModification,
  TransportYear
} from '@/modules/transport/types';

export enum RentAdvertStatusId {
  draft = 1,
  archive = 2,
  published = 3
}

export interface RentAdvertStatus {
  id: RentAdvertStatusId;
  name: string;
}

export interface GetRentAdvertsReqBase {
  auto_filter: AutoFilterIds[];
  price_max: number;
  price_min: number;
  count_seats: number;
  transmission_ids: number[];
  body_ids: number[];
  body_colour_ids: number[];
  sort_filter_id: number;
}

export interface GetMyRentAdvertsQueryReq extends PaginationReq {
  status_id?: Nullable<RentAdvertStatusId>;
  search?: string;
}
export type GetRentAdvertsReq = NullableValues<GetRentAdvertsReqBase>;
export type GetRentAdvertsRes = RentAdvert[];

export interface RentAdvertBase {
  brand: TransportBrand;
  model: TransportModel;
  generation: TransportGeneration;
  year_of_issue: TransportYear;
  modification: TransportModification;
  body: TransportBody;
  body_colour: TransportColor;
  interior_colour: TransportColor;
  count_seats: number;
  time_to_100: number;
  fuel_rate: number;
  tariffs: RentTariff[];
  photos: PhotoType[];
  published_at: number;
  updated_at_by_request_update: number;
}

export interface RentAdvert extends NullableValues<RentAdvertBase> {
  id: number;
  engagement: MyAdvertEngagement;
  url: string;
  status: AdvertStatus;
}

export interface RentAdvertExistedStatus extends AdvertExistedStatus {
  id: number;
}
export type GetExistedRentStatusesRes = RentAdvertExistedStatus[];
