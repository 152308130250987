import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { restorePassword } from '@/modules/auth/api';
import cls from '@/modules/auth/Signin/Signin.module.scss';
import { Button } from '@/ui/Button/Button';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { Input } from '@/ui/Input/Input';
import { LottieNoLoop } from '@/ui/LottieNoLoop';
import { showAlert } from '@/utils/network';
import { MAX_EMAIL_LENGTH, validateEmail } from '@/utils/validate';

import emailAnim from './email.json';

export function RestorePassword() {
  const { t } = useTranslation();

  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const isEmailValid = validateEmail(email);
  const isValid = isEmailValid;

  const submit = async () => {
    if (!isValid) return;

    setLoading(true);
    try {
      await restorePassword(email);
      setSent(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isValid && e.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className="box">
      {sent ? (
        <>
          <div className={cls.icon}>
            <LottieNoLoop animationData={emailAnim} />
          </div>

          <p
            className={cls.subtitle}
            style={{ maxWidth: '21em', marginBottom: 0 }}
          >
            {t('auth.restore.sent', { email })}
          </p>
        </>
      ) : (
        <>
          <div className={cls.icon}>
            <LottieNoLoop animationData={emailAnim} />
          </div>
          <h1 className={cls.title}>{t('auth.restore.title')}</h1>
          <p className={cls.subtitle} style={{ maxWidth: '21em' }}>
            {t('auth.restore.subtitle')}
          </p>

          <div className={cls.field}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              onBlur={() => setEmailTouched(true)}
              label={t('auth.email')}
              onKeyDown={onKeyDown}
              disabled={loading}
              maxLength={MAX_EMAIL_LENGTH}
              errorText={
                !isEmailValid && emailTouched ? t('auth.invalidEmail') : ''
              }
            />
          </div>
          <div style={{ display: 'none' }}>
            <input type="password" />
          </div>

          <div className={cls.buttons}>
            <Button
              onClick={submit}
              loading={loading}
              disabled={loading || !isValid}
              fullWidth
            >
              {t('common.continue')}
            </Button>
            <ButtonLink
              color="black"
              to="/signin"
              variant="secondary"
              fullWidth
            >
              {t('auth.restore.back')}
            </ButtonLink>
          </div>
        </>
      )}
    </div>
  );
}
