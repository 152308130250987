import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import {
  GetCompetitorsReq,
  GetCompetitorsRes
} from '@/modules/competitors/types';
import { showAlert } from '@/utils/network';

function getCompetitors(params?: GetCompetitorsReq) {
  return axios.get('/competitors/list', {
    params
  });
}

export function useCompetitors(
  params?: GetCompetitorsReq
): [GetCompetitorsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetCompetitorsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getCompetitors(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
