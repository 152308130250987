import { AddressCard } from '@/modules/showroom/address/AddressList/AddressCard/AddressCard';
import { ShowroomAddress } from '@/modules/showroom/address/types';

import cls from './AddressList.module.scss';

type Props = {
  list: ShowroomAddress[];
  onEditClick: (address: ShowroomAddress) => void;
  onDeleteClick: (address: ShowroomAddress) => void;
};

export function AddressList({ list, onEditClick, onDeleteClick }: Props) {
  return (
    <ul className={cls.list}>
      {list.map((a) => (
        <li key={a.id}>
          <AddressCard
            address={a}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        </li>
      ))}
    </ul>
  );
}
