import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowClick } from '@/hooks/useWindowClick';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { MAX_VRP_NUMBER_LEN } from '@/modules/showroom/advert/vrp/helpers';
import { escapePlus, maxLength, onlyNumbers } from '@/utils/format';

import cls from '../Filters.module.scss';

export function NumberFilter() {
  const { t } = useTranslation();

  const [isNumOpen, setNumOpen] = useState(false);

  const vrpPlateNumbers = useSearchStore((s) => s.vrpPlateNumbers);
  const setVrpPlateNumbers = useSearchStore((s) => s.setVrpPlateNumbers);
  const vrpPlateNumberLen = useSearchStore((s) => s.vrpPlateNumberLen);
  const setVrpPlateNumberLen = useSearchStore((s) => s.setVrpPlateNumberLen);

  useEffect(() => {
    const valueStr = vrpPlateNumbers ? String(vrpPlateNumbers) : '';

    if (
      vrpPlateNumberLen &&
      vrpPlateNumbers &&
      vrpPlateNumberLen !== valueStr.length
    )
      setVrpPlateNumberLen(valueStr.length);
  }, [
    vrpPlateNumbers,
    setVrpPlateNumberLen,
    setVrpPlateNumbers,
    vrpPlateNumberLen
  ]);

  const valueStr = vrpPlateNumbers ? String(vrpPlateNumbers) : '';

  useWindowClick((e) => {
    const el = e.target as HTMLElement | null;
    const parentEl = el?.closest(`.${escapePlus(cls.li_input)}`);

    if (!parentEl) {
      setNumOpen(false);
    }
  });

  const onResetClick = () => {
    setVrpPlateNumbers(null);
  };

  return (
    <>
      <div>
        <FilterBtn
          text={t('number')}
          onClick={() => setNumOpen(true)}
          active={isNumOpen}
          showSearch={isNumOpen}
          onResetClick={onResetClick}
          value={valueStr || ''}
          searchValue={valueStr || ''}
          onSearchChange={(v) =>
            setVrpPlateNumbers(
              Number(maxLength(onlyNumbers(v), MAX_VRP_NUMBER_LEN))
            )
          }
          searchPlaceholder={t('number')}
        />
      </div>
    </>
  );
}
