export function RemoveIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="white" />
      <path
        d="M9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1ZM12.1576 5.8424C11.9233 5.60808 11.5434 5.60808 11.3091 5.8424L9 8.15133L6.69093 5.8424L6.62948 5.7887C6.39431 5.60971 6.05719 5.62761 5.8424 5.8424L5.7887 5.90384C5.60971 6.13902 5.62761 6.47614 5.8424 6.69093L8.15133 9L5.8424 11.3091C5.60808 11.5434 5.60808 11.9233 5.8424 12.1576C6.07671 12.3919 6.45661 12.3919 6.69093 12.1576L9 9.84866L11.3091 12.1576L11.3705 12.2113C11.6057 12.3903 11.9428 12.3724 12.1576 12.1576L12.2113 12.0962C12.3903 11.861 12.3724 11.5239 12.1576 11.3091L9.84866 9L12.1576 6.69093C12.3919 6.45661 12.3919 6.07671 12.1576 5.8424Z"
        fill="var(--thm-silver-grey)"
      />
      <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="white" />
    </svg>
  );
}
