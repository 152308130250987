import { useDarkmode } from '@/modules/theme/useTheme';
import { APP_URL } from '@/utils/consts';

const PLACEHOLDER_PATH = `/img/placeholders-v1/placeholder.svg`;
const PLACEHOLDER_PATH_DARK = `/img/placeholders-v1/placeholder-dark.svg`;

export function useImgPlaceholder(): string {
  const { isDark } = useDarkmode();
  const path = isDark ? PLACEHOLDER_PATH_DARK : PLACEHOLDER_PATH;
  return `${APP_URL}${path}`;
}
