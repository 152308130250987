export function InfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99677 18.328C14.5609 18.328 18.329 14.5548 18.329 9.99726C18.329 5.43315 14.5543 1.6665 9.99016 1.6665C5.43411 1.6665 1.66602 5.43315 1.66602 9.99726C1.66602 14.5548 5.44072 18.328 9.99677 18.328ZM9.99677 16.687C6.28447 16.687 3.31367 13.7095 3.31367 9.99726C3.31367 6.28496 6.27786 3.30755 9.99016 3.30755C13.7024 3.30755 16.6879 6.28496 16.6879 9.99726C16.6879 13.7095 13.709 16.687 9.99677 16.687Z"
        fill="currentColor"
      />
      <path
        d="M8.58584 14.4339H11.832C12.1888 14.4339 12.4686 14.1745 12.4686 13.8134C12.4686 13.4727 12.1888 13.2024 11.832 13.2024H10.9335V9.39203C10.9335 8.91586 10.6955 8.60087 10.2464 8.60087H8.72734C8.36907 8.60087 8.09067 8.87264 8.09067 9.2133C8.09067 9.57447 8.36907 9.83379 8.72734 9.83379H9.54238V13.2024H8.58584C8.22907 13.2024 7.94922 13.4727 7.94922 13.8134C7.94922 14.1745 8.22907 14.4339 8.58584 14.4339ZM9.94499 7.35352C10.554 7.35352 11.0341 6.86544 11.0341 6.26073C11.0341 5.64876 10.554 5.16357 9.94499 5.16357C9.34687 5.16357 8.85734 5.64876 8.85734 6.26073C8.85734 6.86544 9.34687 7.35352 9.94499 7.35352Z"
        fill="currentColor"
      />
    </svg>
  );
}
