import { Deposit } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Deposit/Deposit';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function DepositWrap() {
  const deposit = useRentUpdateAdvertStore((s) => s.deposit);
  const setDeposit = useRentUpdateAdvertStore((s) => s.setDeposit);
  const depositReturnDays = useRentUpdateAdvertStore(
    (s) => s.depositReturnDays
  );
  const setDepositReturnDays = useRentUpdateAdvertStore(
    (s) => s.setDepositReturnDays
  );
  const isDepositFilled = useRentUpdateAdvertStore((s) => s.isDepositFilled);
  const setDepositFilled = useRentUpdateAdvertStore((s) => s.setDepositFilled);

  return (
    <Deposit
      deposit={deposit}
      setDeposit={setDeposit}
      depositReturnDays={depositReturnDays}
      setDepositReturnDays={setDepositReturnDays}
      isDepositFilled={isDepositFilled}
      setDepositFilled={setDepositFilled}
    />
  );
}
