import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDesktop } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { useDocumentClick } from '@/hooks/useDocumentClick';

import cls from './Actions.module.scss';
import { ReplyIcon, EditIcon, DeleteIcon, AddQuickReplyIcon } from './icons';

export type ActionType = 'edit' | 'reply' | 'delete' | 'addQuickReply';

type Props = {
  isOpen: boolean;
  close: () => void;
  own: boolean;
  listTop: boolean;
  showEdit: boolean;
  showDelete: boolean;
  rect: DOMRect;
  top: number;
  onActionClick: (type: ActionType, text?: Nullable<string>) => void;
  messageText?: Nullable<string>;
};

export function Actions({
  isOpen,
  own,
  listTop,
  showEdit,
  showDelete,
  rect,
  top,
  close,
  onActionClick,
  messageText
}: Props) {
  const { t } = useTranslation();

  const isDesktop = useDesktop();
  const rootRef = useRef<HTMLDivElement>(null);

  useDocumentClick((e) => {
    const el = e.target as HTMLElement | null;

    if (rootRef && rootRef.current && !rootRef.current.contains(el)) {
      close();
    }
  });

  const styles = useMemo<React.CSSProperties>(() => {
    const side = own ? 'right' : 'left';
    return {
      [side]: isDesktop ? `${Math.min(rect.width, 300)}px` : '24px',
      top: `calc(${top}px + ${rect.height / 2}px)`
    };
  }, [own, rect, top, isDesktop]);

  const handleActionClick = (type: ActionType, text?: Nullable<string>) => {
    return () => {
      onActionClick(type, text);
    };
  };

  return (
    <div
      className={cn(cls.root, {
        [cls.root_open]: isOpen,
        [cls.root_own]: own,
        [cls.root_top]: listTop
      })}
      ref={rootRef}
      style={styles}
      data-msg-actions
    >
      {isOpen && (
        <ul className={cls.list}>
          <li>
            <button
              className={cls.action_btn}
              type="button"
              onClick={handleActionClick('reply')}
            >
              {t('chat.messages.actions.reply')}
              <ReplyIcon />
            </button>
          </li>

          {showEdit && (
            <li>
              <button
                className={cls.action_btn}
                type="button"
                onClick={handleActionClick('edit')}
              >
                {t('common.change')}
                <EditIcon />
              </button>
            </li>
          )}

          {showDelete && (
            <li>
              <button
                className={cn(cls.action_btn, cls.action_btn_delete)}
                type="button"
                onClick={handleActionClick('delete')}
              >
                {t('common.delete')}
                <DeleteIcon />
              </button>
            </li>
          )}

          {own && messageText && (
            <li>
              <button
                className={cls.action_btn}
                type="button"
                onClick={handleActionClick('addQuickReply', messageText)}
              >
                {t('chat.quickReplies.add')}
                <AddQuickReplyIcon />
              </button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
