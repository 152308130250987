import cn from 'classnames';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import {
  confirmMainContactPhone,
  confirmMainContactPhoneSendCode,
  useShowroomContactLangs
} from '@/modules/showroom/contact/api';
import { ShowroomBox } from '@/modules/showroom/ShowroomEdit/ShowroomBox';
import { Input } from '@/ui/Input/Input';
import { PhoneConfirm } from '@/ui/PhoneConfirm/PhoneConfirm';
import { PrimarySelect } from '@/ui/PrimarySelect/PrimarySelect';
import { preventExtraSpaces } from '@/utils/format';

import cls from './Contact.module.scss';

type Props = {
  contactName: string;
  setContactName: (v: string) => void;
  contactPhone: string;
  setContactPhone: (v: string) => void;
  langIds: number[];
  setLangIds: (v: number[]) => void;
  showLang?: boolean;
  fieldsOnly?: boolean;
};

export function Contact({
  contactName,
  setContactName,
  contactPhone,
  setContactPhone,
  langIds,
  setLangIds,
  showLang,
  fieldsOnly
}: Props) {
  const { t } = useTranslation();

  const [langsList] = useShowroomContactLangs();
  const langsOptions = langsList
    ? langsList.map((l) => ({
        id: String(l.id),
        text: l.name
      }))
    : [];

  const langsValue = langsOptions.filter((l) => langIds.includes(Number(l.id)));
  const onLangChange = (opts: OptionI[]) => {
    setLangIds(opts.map((o) => Number(o.id)));
  };

  const Container = fieldsOnly ? Fragment : ShowroomBox;

  return (
    <Container>
      {!fieldsOnly && <h2>{t('showroom.contact.title')}</h2>}

      <div className={cls.field}>
        <Input
          value={contactName}
          onChange={(e) =>
            setContactName(
              preventExtraSpaces(e.currentTarget.value.trimStart())
            )
          }
          label={t('showroom.contact.name')}
        />
      </div>

      {contactPhone ? (
        <div className={cn(cls.field, cls.field_phone)}>
          <Input value={contactPhone} label={t('phoneNumber')} disabled />
        </div>
      ) : (
        <PhoneConfirm
          onConfirm={(p) => setContactPhone(p)}
          confirmPhoneSendCode={confirmMainContactPhoneSendCode}
          confirmPhone={confirmMainContactPhone}
          supportEmailKey="auth.supportEmail"
          hideCountries
        />
      )}

      {showLang && (
        <div className={cls.field} style={{ marginTop: 12 }}>
          <PrimarySelect
            name="edit-showroom-langs"
            options={langsOptions}
            onChange={onLangChange}
            value={langsValue}
            placeholder={t('showroom.contacts.speakOn')}
            dropdownTop
            dropdownFullWidth
            multiple
            large
          />
        </div>
      )}

      <p className={cls.text}>{t('showroom.contact.message')}</p>
    </Container>
  );
}
