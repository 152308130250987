import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import {
  getOptionsByComplectation,
  useComplectations
} from '@/modules/showroom/advert/complectation/api';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { TransportModification } from '@/modules/transport/types';
import { Button } from '@/ui/Button/Button';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';
import { Skeleton } from '@/ui/Skeleton';
import { MAX_PG_LIMIT } from '@/utils/consts';
import { pluralKey } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './Complectation.module.scss';
import { getCategoriesItems, isOptionsSame } from './helpers';
import { OptionsModal } from './OptionsModal/OptionsModal';

const skeletons = new Array(4).fill(0);

type Props = {
  nextSection: string;
  modification: Nullable<TransportModification>;
  isCustomComplectation: CreateAdvertSlice['isCustomComplectation'];
  setCustomComplectation: CreateAdvertSlice['setCustomComplectation'];
  complectationId: CreateAdvertSlice['complectationId'];
  setComplectationId: CreateAdvertSlice['setComplectationId'];
  complectationOptionsItemsIds: CreateAdvertSlice['complectationOptionsItemsIds'];
  setComplectationOptionsItemsIds: CreateAdvertSlice['setComplectationOptionsItemsIds'];
  isComplectationFilled: CreateAdvertSlice['isComplectationFilled'];
  setComplectationFilled: CreateAdvertSlice['setComplectationFilled'];
};

export function Complectation({
  nextSection,
  modification,
  isCustomComplectation,
  setCustomComplectation,
  complectationId,
  setComplectationId,
  complectationOptionsItemsIds,
  setComplectationOptionsItemsIds,
  isComplectationFilled,
  setComplectationFilled
}: Props) {
  const { t } = useTranslation();

  const req = useMemo(
    () => ({
      modification_id: modification?.id || null,
      limit: MAX_PG_LIMIT
    }),
    [modification]
  );
  const [complectations, complectationsLoading] = useComplectations(req);
  const count = complectationOptionsItemsIds.length;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // Handlers
  const onComplectationClick = (id: Nullable<number>) => {
    if (!id) return;

    return async () => {
      setLoading(true);

      try {
        const { data } = await getOptionsByComplectation({
          complectation_id: id
        });
        setCustomComplectation(false);
        setComplectationId(id);
        setComplectationOptionsItemsIds(
          getCategoriesItems(data).map((v) => v.id)
        );
      } catch (error) {
        showAlert({ error });
      } finally {
        setLoading(false);
      }
    };
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const onCustomEmptyClick = () => {
    setCustomComplectation(true);
    setComplectationId(null);
    openModal();
  };

  const onContinueClick = () => {
    setComplectationFilled(true);
    scrollToSection(nextSection, 100);
  };

  const onOptionsSave = (itemsIds: number[]) => {
    const same = isOptionsSame(complectationOptionsItemsIds, itemsIds);
    if (!same) {
      setCustomComplectation(true);
    }
    setComplectationOptionsItemsIds(itemsIds);
    setModalOpen(false);
    scrollToSection(nextSection, 100);
  };

  const onReset = () => {
    setComplectationOptionsItemsIds([]);
    setCustomComplectation(false);
    setComplectationId(null);
    setLoading(false);
    setModalOpen(false);
  };

  return (
    <div className={cls.root} id={SCROLLTO.complectation}>
      <div className="box">
        <h1 className={cls.title}>
          {count > 0 ? (
            <Trans
              t={t}
              i18nKey="complectation.titleCount"
              components={{
                countWrap: <span />
              }}
              values={{
                count: pluralKey(
                  count,
                  t('complectation.optionsPlural.one', { count }),
                  t('complectation.optionsPlural.few', { count }),
                  t('complectation.optionsPlural.many', { count })
                )
              }}
            />
          ) : (
            t('complectation.title')
          )}
        </h1>
        <p className={cls.desc}>{t('complectation.text')}</p>

        {complectations && !complectationsLoading && (
          <>
            {complectations.length > 0 ||
            complectationOptionsItemsIds.length > 0 ? (
              <ul className={cls.list}>
                {complectations.map((c) => (
                  <li key={c.id}>
                    <CheckBtn
                      active={
                        !isCustomComplectation && complectationId === c.id
                      }
                      onClick={onComplectationClick(c.id)}
                      disabled={isLoading}
                    >
                      {c.name}
                    </CheckBtn>
                  </li>
                ))}

                <li>
                  <CheckBtn
                    active={isCustomComplectation}
                    onClick={onCustomEmptyClick}
                    disabled={isLoading}
                  >
                    {t('complectation.other')}
                  </CheckBtn>
                </li>
              </ul>
            ) : (
              <p className={cls.empty}>{t('complectation.empty')}</p>
            )}
          </>
        )}

        {complectationsLoading && (
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton width={106} height={48} borderRadius={27} />
              </li>
            ))}
          </ul>
        )}

        <div className={cls.buttons}>
          {complectationOptionsItemsIds.length > 0 && (
            <Button
              onClick={openModal}
              disabled={isLoading}
              variant="secondary"
              color="blue"
              fullWidth
            >
              {t('complectation.showOptions')}
            </Button>
          )}

          {complectationOptionsItemsIds.length <= 0 &&
            !complectationId &&
            !complectationsLoading &&
            complectations &&
            complectations.length <= 0 && (
              <Button
                onClick={openModal}
                disabled={isLoading}
                variant="secondary"
                color="blue"
                fullWidth
              >
                {t('complectation.setManually')}
              </Button>
            )}

          {!isComplectationFilled && (
            <>
              <Button
                onClick={onContinueClick}
                variant={
                  isCustomComplectation || complectationId
                    ? 'primary'
                    : 'secondary'
                }
                color={
                  isCustomComplectation || complectationId ? 'blue' : 'black'
                }
                fullWidth
              >
                {t(
                  isCustomComplectation || complectationId
                    ? 'common.continue'
                    : 'common.skip'
                )}
              </Button>
            </>
          )}
        </div>

        <OptionsModal
          isOpen={isModalOpen}
          close={() => setModalOpen(false)}
          onSave={onOptionsSave}
          onReset={onReset}
          initial={complectationOptionsItemsIds}
          modification={modification}
        />
      </div>
    </div>
  );
}
