export function CloseIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.69989 5.69998C6.08649 5.31338 6.71329 5.31338 7.09989 5.69998L11.9999 10.6L16.8999 5.69998C17.2865 5.31338 17.9133 5.31338 18.2999 5.69998C18.6865 6.08658 18.6865 6.71338 18.2999 7.09998L13.3999 12L18.2999 16.9C18.6865 17.2866 18.6865 17.9134 18.2999 18.3C17.9133 18.6866 17.2865 18.6866 16.8999 18.3L11.9999 13.4L7.09989 18.3C6.71329 18.6866 6.08649 18.6866 5.69989 18.3C5.3133 17.9134 5.3133 17.2866 5.69989 16.9L10.5999 12L5.69989 7.09998C5.3133 6.71338 5.3133 6.08658 5.69989 5.69998Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronIcon(): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.48799 8L5.41058 3.92259C5.08514 3.59715 5.08514 3.06951 5.41058 2.74408C5.73602 2.41864 6.26366 2.41864 6.58909 2.74408L11.2558 7.41074C11.5812 7.73618 11.5812 8.26382 11.2558 8.58926L6.58909 13.2559C6.26366 13.5814 5.73602 13.5814 5.41058 13.2559C5.08514 12.9305 5.08514 12.4028 5.41058 12.0774L9.48799 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
