import cn from 'classnames';

import { Skeleton } from '@/ui/Skeleton';

import cls from './MyBookingCard.module.scss';

export function MyBookingCardSkeleton() {
  return (
    <div className={cls.root}>
      <div className={cls.advert}>
        <div className={cls.img}>
          <Skeleton className={cls.img_inner} borderRadius={0} />
        </div>

        <div className={cls.info}>
          <p className={cls.name_wrap}>
            <Skeleton width={288} height={24} />
          </p>
        </div>

        <div className={cls.price_col}>
          <div className={cls.price_wrap}>
            <Skeleton width={88} height={24} />
          </div>
        </div>

        <p className={cn(cls.days, cls.days_desktop)}>
          <Skeleton width="100%" height={18} />
        </p>

        <div className={cls.right_col}>
          <ul className={cls.stats}>
            <li>
              <Skeleton width="100%" height={20} />
            </li>
            <li>
              <Skeleton width="100%" height={20} />
            </li>
            <li>
              <Skeleton width="100%" height={20} />
            </li>
            <li>
              <Skeleton width="100%" height={20} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
