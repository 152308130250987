import { Phone } from '@/modules/showroom/advert/create/Phone/Phone';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function PhoneWrap() {
  const contacts = useUpdateAdvertStore((s) => s.contacts);
  const setContacts = useUpdateAdvertStore((s) => s.setContacts);
  const disableChat = useUpdateAdvertStore((s) => s.disableChat);
  const setDisableChat = useUpdateAdvertStore((s) => s.setDisableChat);
  const isPhoneFilled = useUpdateAdvertStore((s) => s.isPhoneFilled);
  const setPhoneFilled = useUpdateAdvertStore((s) => s.setPhoneFilled);

  return (
    <Phone
      contacts={contacts}
      setContacts={setContacts}
      disableChat={disableChat}
      setDisableChat={setDisableChat}
      isPhoneFilled={isPhoneFilled}
      setPhoneFilled={setPhoneFilled}
    />
  );
}
