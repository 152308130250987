import { useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useDocumentClick } from '@/hooks/useDocumentClick';
import { useAccountStore } from '@/modules/accounts/store';
import { Stepper } from '@/modules/showroom/CreateShowroom/Stepper/Stepper';
import { LOGO_ID } from '@/ui/layouts/BaseLayout/BaseLayout';

import cls from './CreateShowroom.module.scss';

const stepsMap: Record<string, number> = {
  '/showroom/create/about': 0,
  '/showroom/create/design': 1,
  '/showroom/create/username': 2,
  '/showroom/create/photo': 3,
  '/showroom/create/contact': 4,
  '/showroom/create/success': 5
};

export function CreateShowroom() {
  const { pathname } = useLocation();

  const index = stepsMap[pathname];
  const count = Object.keys(stepsMap).length;
  const isLastStep = index === count - 1;

  const accountInfo = useAccountStore((s) => s.accountInfo);
  const showroomCreated = !!accountInfo?.showroom_created;

  const onDocumentClick = useCallback(
    (e: MouseEvent) => {
      if (isLastStep || showroomCreated) return;
      const el = e.target as HTMLElement;
      const logoClicked = el?.closest(`#${LOGO_ID}`);
      if (!logoClicked) return;
      e.preventDefault();
      window.location.reload();
    },
    [isLastStep, showroomCreated]
  );
  useDocumentClick(onDocumentClick);

  return (
    <div className={cls.container}>
      <div className={cls.stepper}>
        <Stepper index={index} count={count} />
      </div>

      <div className="box">
        <Outlet />
      </div>
    </div>
  );
}
