import cls from './Spinner.module.scss';

interface PropsI {
  size?: number;
  color?: string;
  centered?: boolean;
}

export function Spinner({
  size = 40,
  color = 'var(--clr-primary)',
  centered
}: PropsI): JSX.Element {
  const inner = (
    <div
      style={{ '--color': color, '--size': `${size}px` } as React.CSSProperties}
      className={cls.spinner}
    />
  );

  return centered ? <div className={cls.container}>{inner}</div> : inner;
}

Spinner.displayName = 'Spinner';
