export function RemoveIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66207 17.7099C10.0072 17.7099 10.2297 17.4912 10.2215 17.1691L9.97054 8.58175C9.96087 8.26263 9.73546 8.05065 9.4052 8.05065C9.06299 8.05065 8.842 8.26934 8.85018 8.58994L9.09444 17.1735C9.10411 17.4994 9.33099 17.7099 9.66207 17.7099ZM12.0748 17.7099C12.4141 17.7099 12.6529 17.4941 12.6529 17.1751V8.58699C12.6529 8.26787 12.4141 8.05065 12.0748 8.05065C11.7341 8.05065 11.5019 8.26787 11.5019 8.58699V17.1751C11.5019 17.4941 11.7341 17.7099 12.0748 17.7099ZM14.4927 17.7099C14.8186 17.7099 15.044 17.4994 15.0536 17.1735L15.2979 8.58994C15.3061 8.26934 15.0851 8.05065 14.7429 8.05065C14.4126 8.05065 14.1872 8.26263 14.1776 8.58846L13.9334 17.1691C13.9251 17.4912 14.1476 17.7099 14.4927 17.7099ZM8.35365 5.76833H9.86537V4.06707C9.86537 3.64294 10.162 3.37083 10.6181 3.37083H13.5166C13.9726 3.37083 14.2693 3.64294 14.2693 4.06707V5.76833H15.7811V3.99105C15.7811 2.73849 14.9816 2 13.6243 2H10.5103C9.15309 2 8.35365 2.73849 8.35365 3.99105V5.76833ZM4.72489 6.65949H19.4299C19.8336 6.65949 20.1481 6.3389 20.1481 5.93526C20.1481 5.53455 19.8336 5.22068 19.4299 5.22068H4.72489C4.32796 5.22068 4 5.53455 4 5.93526C4 6.34562 4.32796 6.65949 4.72489 6.65949ZM8.31302 20.483H15.8418C17.0957 20.483 17.91 19.7154 17.9688 18.46L18.5319 6.50239H17.0126L16.4741 18.2161C16.4578 18.7075 16.1335 19.0339 15.6665 19.0339H8.47487C8.02126 19.0339 7.69706 18.6994 7.67396 18.2161L7.10862 6.50239H5.61624L6.186 18.4668C6.24629 19.7221 7.04572 20.483 8.31302 20.483Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99663 14.6622C11.6479 14.6622 14.6624 11.6436 14.6624 7.99761C14.6624 4.34632 11.6426 1.33301 7.99135 1.33301C4.34651 1.33301 1.33203 4.34632 1.33203 7.99761C1.33203 11.6436 4.3518 14.6622 7.99663 14.6622ZM7.99663 13.3494C5.0268 13.3494 2.65015 10.9674 2.65015 7.99761C2.65015 5.02777 5.02151 2.64584 7.99135 2.64584C10.9612 2.64584 13.3496 5.02777 13.3496 7.99761C13.3496 10.9674 10.9664 13.3494 7.99663 13.3494Z"
        fill="currentColor"
      />
      <path
        d="M6.86867 11.5469H9.46558C9.75105 11.5469 9.97491 11.3394 9.97491 11.0504C9.97491 10.7779 9.75105 10.5616 9.46558 10.5616H8.74682V7.51336C8.74682 7.13243 8.55638 6.88043 8.19714 6.88043H6.98187C6.69526 6.88043 6.47254 7.09785 6.47254 7.37038C6.47254 7.65932 6.69526 7.86677 6.98187 7.86677H7.63391V10.5616H6.86867C6.58326 10.5616 6.35937 10.7779 6.35937 11.0504C6.35937 11.3394 6.58326 11.5469 6.86867 11.5469ZM7.95599 5.88255C8.44324 5.88255 8.82726 5.49209 8.82726 5.00832C8.82726 4.51875 8.44324 4.1306 7.95599 4.1306C7.47749 4.1306 7.08587 4.51875 7.08587 5.00832C7.08587 5.49209 7.47749 5.88255 7.95599 5.88255Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33331 10.0001C3.33331 10.4894 3.73735 10.8884 4.22152 10.8884H9.11319V15.78C9.11319 16.2628 9.51069 16.6668 9.99997 16.6668C10.4892 16.6668 10.8947 16.2628 10.8947 15.78V10.8884H15.7798C16.2626 10.8884 16.6666 10.4894 16.6666 10.0001C16.6666 9.51088 16.2626 9.1054 15.7798 9.1054H10.8947V4.22171C10.8947 3.73753 10.4892 3.3335 9.99997 3.3335C9.51069 3.3335 9.11319 3.73753 9.11319 4.22171V9.1054H4.22152C3.73735 9.1054 3.33331 9.51088 3.33331 10.0001Z"
        fill="currentColor"
      />
    </svg>
  );
}
