export const VRP_CATEGORY_ID = 2;
export const VRP_MOTO_TYPE_ID = 3;

export const MAX_VRP_NUMBER_LEN = 5;

export const MIN_VRP_PRICE = 10;
export const MIN_VRP_PRICE_LEN = 2;
export const MAX_VRP_PRICE_LEN = 8;

export function isVrpPriceValid(price: number): boolean {
  return price >= MIN_VRP_PRICE && String(price).length >= MIN_VRP_PRICE_LEN;
}
