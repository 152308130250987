import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useAuthorDetail } from '@/modules/showroom/advert/detail/api';
import { CARS_ADVERTS_URL } from '@/modules/showroom/advert/my/helpers';
import { CARS_CATEGORY_ID } from '@/modules/showroom/advert/search/consts';
import { UpdateAdvert } from '@/modules/showroom/advert/update/UpdateAdvert/UpdateAdvert';
import { useTransportBrands } from '@/modules/transport/api';
import { Spinner } from '@/ui/Spinner/Spinner';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

export function UpdateAdvertWrap() {
  const { id } = useParams();
  const numId = id ? Number(id) : null;
  const isNaN = Number.isNaN(numId);

  const brandsReq = useMemo(
    () => ({ ...MAX_PG_LIMIT_REQ, category_id: CARS_CATEGORY_ID, value: null }),
    []
  );
  const [brands, brandsLoading] = useTransportBrands(brandsReq);
  const [detail, detailLoading, notFound] = useAuthorDetail(numId);
  const loading = brandsLoading || detailLoading;

  if (!numId || isNaN || notFound) return <Navigate to={CARS_ADVERTS_URL} />;
  if (loading) return <Spinner centered />;
  if (!detail || !brands) return <Spinner centered />;

  return <UpdateAdvert detail={detail} brands={brands} />;
}
