import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { SortFilter } from '@/modules/showroom/advert/my/types';
import {
  BREAKPOINT,
  DEBOUNCE_TIME,
  SEARCH_RESULT_ID
} from '@/modules/showroom/advert/search/consts';
import {
  calcVrpSelectedParamsCount,
  defaultSearchReq,
  storeToSearchReq
} from '@/modules/showroom/advert/search/helpers';
import {
  SearchSelectContext,
  useSearchSelect
} from '@/modules/showroom/advert/search/search-select-ctx';
import { PlateFilters } from '@/modules/showroom/advert/search/SearchAdverts/Filters/PlateFilters/PlateFilters';
import { SearchResult } from '@/modules/showroom/advert/search/SearchAdverts/SearchResult/SearchResult';
import { Statuses } from '@/modules/showroom/advert/search/SearchAdverts/Statuses';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { SearchAdvertsReq } from '@/modules/showroom/advert/search/types';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';

import cls from '../SearchAdverts/SearchAdverts.module.scss';

type Props = {
  req: SearchAdvertsReq;
  categoryId: number;
  showResults: boolean;
  sortFilters: SortFilter[];
};

export function SearchPlates({
  req: initialReq,
  categoryId,
  showResults,
  sortFilters
}: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const windowWidth = useCommonStore((s) => s.windowWidth);
  const isDesktop = windowWidth > BREAKPOINT;

  const { setStatus, refreshKey, notFoundAds } = useContext(MyAdvertsContext);
  const searchStoreValue = useSearchStore((s) => s);
  const searchStore = useMemo(
    () => ({ ...searchStoreValue, categoryId }),
    [categoryId, searchStoreValue]
  );
  const debouncedStore = useDebounce(searchStore, DEBOUNCE_TIME);
  const selectedParamsCount = useMemo(
    () => calcVrpSelectedParamsCount(searchStore),
    [searchStore]
  );

  // const [req, setReq] = useState(initialReq);
  const [, setReq] = useState(initialReq);

  // Search
  const searchReq = useMemo(
    () => storeToSearchReq(debouncedStore),
    [debouncedStore]
  );

  // const searchReqUrl = useMemo(
  //   () => stringifySearchReq(searchReq),
  //   [searchReq]
  // );

  const [searchStateReq, setSearchStateReq] = useState<
    Nullable<SearchAdvertsReq>
  >(showResults ? searchReq : null);

  useEffect(() => {
    showResults && setSearchStateReq(searchReq);
  }, [searchReq, showResults]);

  const searchStateReqWithSort = useMemo(
    () =>
      searchStateReq
        ? {
            ...searchStateReq,
            sort_filter_id:
              searchReq.sort_filter_id || searchStateReq.sort_filter_id || null
          }
        : null,
    [searchReq.sort_filter_id, searchStateReq]
  );

  // const searchStateReqUrl = useMemo(
  //   () =>
  //     searchStateReqWithSort ? stringifySearchReq(searchStateReqWithSort) : '',
  //   [searchStateReqWithSort]
  // );

  const searchResultReq = useMemo(
    () => (isDesktop ? searchStateReqWithSort : searchReq),
    [isDesktop, searchReq, searchStateReqWithSort]
  );
  // const searchResultUrl = useMemo(
  //   () => (isDesktop ? searchStateReqUrl : searchReqUrl),
  //   [isDesktop, searchReqUrl, searchStateReqUrl]
  // );

  // Handlers
  const showReset = selectedParamsCount > 0;
  const hideFilters = !showReset && notFoundAds;
  const onResetClick = () => {
    setReq(defaultSearchReq);
    searchStore.resetValues();
    navigate(pathname, { replace: true });
  };

  useEffect(() => {
    return () => {
      setStatus(undefined);
      searchStore.resetValues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchClick = () => {
    // const url = stringifySearchReq(searchReq);
    // const newUrl = `${pathname}?${url}`;
    // navigate(newUrl, { replace: true });
    setSearchStateReq(searchReq);
  };

  useEffect(() => {
    if (showResults) {
      onSearchClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults]);

  // Search contexts
  const searchSelects = useSearchSelect();

  return (
    <SearchSelectContext.Provider value={searchSelects}>
      <div className={cls.root}>
        <div>
          <Statuses categoryId={categoryId} />
          <div className={cls.container}>
            <div
              style={{
                opacity: hideFilters ? '0' : '1',
                pointerEvents: hideFilters ? 'none' : 'auto'
              }}
            >
              <PlateFilters
                sortFilters={sortFilters}
                showReset={showReset}
                onResetClick={onResetClick}
              />
            </div>

            <div id={SEARCH_RESULT_ID} key={refreshKey}>
              {searchResultReq && (
                <SearchResult
                  req={searchResultReq}
                  categoryId={VRP_CATEGORY_ID}
                  onResetClick={onResetClick}
                  selectedParamsCount={selectedParamsCount}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SearchSelectContext.Provider>
  );
}
