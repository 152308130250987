export function RefreshIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6738_72990)">
        <rect x="4" y="4" width="16" height="16" rx="8" fill="white" />
        <path
          d="M24 12C24 18.6245 18.6253 24 11.9981 24C5.38253 24 0 18.6245 0 12C0 5.38338 5.38253 0 11.9981 0C18.6253 0 24 5.38338 24 12ZM10.9189 6.0987C10.9189 6.36436 11.0365 6.5605 11.1594 6.71687L11.7609 7.34149C11.6762 7.33731 11.5643 7.33311 11.4717 7.33678C8.90508 7.47216 6.68771 9.7358 6.68771 12.5408C6.68771 15.5095 9.06446 17.8986 11.9981 17.8986C14.9355 17.8986 17.3122 15.5095 17.3122 12.5758C17.3122 12.0393 16.8704 11.6089 16.3491 11.6089C15.809 11.6089 15.3671 12.0393 15.3671 12.5758C15.3671 14.4552 13.8693 15.961 11.9981 15.961C10.127 15.961 8.63287 14.4479 8.63287 12.5758C8.63287 10.8471 9.92211 9.3998 11.5628 9.3193C11.6904 9.30832 11.8295 9.3052 11.9409 9.29893L11.1234 10.1271C11.0036 10.2615 10.9298 10.4607 10.9298 10.6715C10.9298 11.207 11.3257 11.559 11.8281 11.559C12.0676 11.559 12.3071 11.4445 12.4593 11.2886L14.6688 9.0715C15.0301 8.7253 15.007 8.1197 14.6725 7.7693L12.4787 5.54433C12.3191 5.37278 12.0792 5.24049 11.8172 5.24049C11.2703 5.24049 10.9189 5.64735 10.9189 6.0987Z"
          fill="var(--clr-purple)"
        />
      </g>
      <defs>
        <clipPath id="clip0_6738_72990">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
