/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-mapkit';

import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { EmirateType } from '@/modules/emirate/types';
import { useMapDarkmode } from '@/modules/mapkit/helpers';
import { SuggestionsDropdown } from '@/modules/mapkit/SuggestionsDropdown/SuggestionsDropdown';
import {
  GeocodeLookupResponse,
  SearchAutoCompleteItem
} from '@/modules/mapkit/types';
import { AddressMap } from '@/modules/showroom/address/AddressForm/AddressMap';
import { Input } from '@/ui/Input/Input';
import { maxLength } from '@/utils/format';

import cls from './AddressForm.module.scss';

const MAX_ADDRESS_LEN = 64;

type Props = {
  emirate: Nullable<EmirateType>;
  lat: number;
  long: number;
  setLat: (v: number) => void;
  setLong: (v: number) => void;
  address: string;
  setAddress: (v: string) => void;
  loading?: boolean;
};

export function AddressMapWrap({
  emirate,
  lat,
  long,
  setLat,
  setLong,
  address,
  setAddress,
  loading
}: Props) {
  const { t } = useTranslation();

  const debouncedAddress = useDebounce(address, 500);

  const [isMapSearchOpen, setMapSearchOpen] = useState(false);
  const [mapSearch, setMapSearch] = useState();
  const [geocoder, setGeocoder] = useState<any>();
  const mapCfg = useMap({
    region: {
      latitude: lat,
      longitude: long,
      latitudeSpan: 0.01,
      longitudeSpan: 0.01
    }
  });
  const { map, mapProps, mapkit, setCenter, setRegion } = mapCfg;
  useMapDarkmode(map);

  const onRegionChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      const { latitude, longitude } = e.target.center;
      if (geocoder) {
        const coord = new mapkit.Coordinate(latitude, longitude);
        geocoder.reverseLookup(
          coord,
          (error: unknown, data: GeocodeLookupResponse) => {
            if (error) {
              console.error(error);
              return;
            } else if (data && data.results && data.results[0]) {
              const { formattedAddress } = data.results[0];
              if (formattedAddress) {
                setLat(latitude);
                setLong(longitude);
                setAddress(maxLength(formattedAddress, MAX_ADDRESS_LEN));
              }
            }
          }
        );
      } else {
        setLat(latitude);
        setLong(longitude);
      }
    },
    [geocoder, mapkit, setAddress, setLat, setLong]
  );

  const updateMap = (latitude: number, longitude: number) => {
    setLat(latitude);
    setLong(longitude);
    setCenter([latitude, longitude]);
    setRegion({
      latitude,
      longitude,
      latitudeSpan: 0.001,
      longitudeSpan: 0.001
    });
  };

  const onSearchClick = (v: SearchAutoCompleteItem) => {
    setMapSearchOpen(false);
    const { coordinate, displayLines } = v;
    const { latitude, longitude } = coordinate;
    setAddress(maxLength(displayLines.reverse().join(' '), MAX_ADDRESS_LEN));
    updateMap(latitude, longitude);
  };

  useEffect(() => {
    if (mapkit && mapkit.Search && !mapSearch) {
      const s = new mapkit.Search();
      setMapSearch(s);
    }
  }, [mapSearch, mapkit]);

  useEffect(() => {
    if (mapkit && mapkit.Search && !geocoder) {
      const g = new mapkit.Geocoder();
      setGeocoder(g);
    }
  }, [geocoder, mapkit]);

  useEffect(() => {
    if (emirate) updateMap(emirate.lat, emirate.long);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emirate]);

  useEffect(() => {
    if (map) {
      map.addEventListener('region-change-end', onRegionChange);

      return () => {
        map.removeEventListener('region-change-end', onRegionChange);
      };
    }
  }, [map, onRegionChange]);

  return (
    <>
      <div className={cls.field}>
        <Input
          value={address}
          onChange={(e) => {
            setMapSearchOpen(true);
            setAddress(
              maxLength(e.currentTarget.value.trimStart(), MAX_ADDRESS_LEN)
            );
          }}
          disabled={loading}
          label={t('address')}
        />

        {!!mapSearch && (
          <SuggestionsDropdown
            emirateId={emirate?.id}
            search={mapSearch}
            query={debouncedAddress}
            isOpen={isMapSearchOpen}
            close={() => setMapSearchOpen(false)}
            onClick={onSearchClick}
          />
        )}
      </div>
      <div className={cls.map}>
        <AddressMap mapProps={mapProps} latitude={lat} longitude={long} />
      </div>
    </>
  );
}
