export function ChevronIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.30244 9.59239C4.10822 9.40233 4 9.14901 4 8.85724C4 8.26884 4.45637 7.80919 5.04317 7.80919C5.3382 7.80919 5.61029 7.9198 5.80107 8.12539L12.4459 14.9261H11.5622L18.2006 8.12539C18.3972 7.91818 18.6709 7.80919 18.9569 7.80919C19.542 7.80919 20 8.26884 20 8.85724C20 9.15062 19.8935 9.40396 19.696 9.59239L12.8179 16.6314C12.5895 16.873 12.3117 16.9967 12.0045 17C11.6916 17 11.4195 16.8746 11.1838 16.6314L4.30244 9.59239Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LockIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26541 21.4911H16.4298C17.916 21.4911 18.6953 20.6941 18.6953 19.0897V12.1582C18.6953 10.5671 17.916 9.77003 16.4298 9.77003H7.26541C5.77745 9.77003 5 10.5671 5 12.1582V19.0897C5 20.6941 5.77745 21.4911 7.26541 21.4911ZM7.54334 19.7368C7.13661 19.7368 6.90428 19.4883 6.90428 19.0264V12.2249C6.90428 11.7612 7.13661 11.5244 7.54334 11.5244H16.1536C16.5685 11.5244 16.7892 11.7612 16.7892 12.2249V19.0264C16.7892 19.4883 16.5685 19.7368 16.1536 19.7368H7.54334ZM6.79017 10.5923H8.62281V7.29948C8.62281 5.00137 10.0928 3.75258 11.8436 3.75258C13.5909 3.75258 15.0823 5.00137 15.0823 7.29948V10.5923H16.9068V7.47329C16.9068 3.8622 14.517 2 11.8436 2C9.17832 2 6.79017 3.8622 6.79017 7.47329V10.5923Z"
        fill="currentColor"
      />
    </svg>
  );
}
