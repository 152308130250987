import { ExpensesDetailStatisticsRes } from '@/modules/wallet/types';
import { EmptyGraph } from '@/modules/wallet/WalletGraph/EmptyGraph/EmptyGraph';
import { WalletGraph } from '@/modules/wallet/WalletGraph/WalletGraph';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './WalletGraph.module.scss';

type Props = {
  from: Date;
  to: Date;
  data: ExpensesDetailStatisticsRes | undefined;
  loading: boolean;
};

export function WalletGraphWrap({ from, to, data, loading }: Props) {
  const hasData = data?.expenses && data?.expenses.length > 0;

  return (
    <div className={cls.root}>
      {hasData && !loading ? (
        <WalletGraph from={from} to={to} data={data} />
      ) : loading ? (
        <Spinner color="var(--clr-blue)" />
      ) : (
        <EmptyGraph />
      )}
    </div>
  );
}
