export function ChevronIcon() {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.48009 5.99955C5.47908 5.83464 5.42068 5.69322 5.28909 5.56265L1.85877 2.16407C1.75271 2.05338 1.61841 2.00006 1.46004 2.00006C1.13768 2.00006 0.88353 2.24856 0.88353 2.56889C0.88353 2.72263 0.951189 2.86867 1.05872 2.98343L4.10906 5.99854L1.05872 9.01565C0.952206 9.13045 0.88353 9.27185 0.88353 9.43123C0.88353 9.75157 1.13768 10.0001 1.46004 10.0001C1.61739 10.0001 1.75271 9.94676 1.85877 9.83606L5.28909 6.43646C5.4217 6.30588 5.48009 6.16345 5.48009 5.99955Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function WhatsAppIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81502 11.2218L1.86363 14.6956L5.41849 13.7634C6.39801 14.2975 7.50074 14.579 8.62294 14.5795H8.62583C12.3216 14.5795 15.3301 11.5719 15.3318 7.87582C15.3324 6.08445 14.6353 4.40021 13.3695 3.13307C12.1032 1.86593 10.4196 1.16787 8.62583 1.16699C4.92942 1.16699 1.9214 4.17413 1.9199 7.87042C1.91952 9.0519 2.22823 10.2053 2.81502 11.2218ZM6.94991 5.06781C6.82431 4.78886 6.69219 4.78321 6.57287 4.77831C6.48044 4.77439 6.37543 4.7744 6.26984 4.77442L6.25172 4.77442C6.13994 4.77442 5.95846 4.81637 5.80498 4.98404C5.79514 4.99477 5.78416 5.00648 5.77222 5.01922C5.5977 5.20533 5.21845 5.6098 5.21845 6.38142C5.21845 7.19943 5.80963 7.98999 5.90052 8.11153L5.90257 8.11426C5.9089 8.12272 5.92091 8.14052 5.93843 8.1665C6.1526 8.48398 7.19051 10.0226 8.76489 10.6434C10.0439 11.1478 10.4023 11.1057 10.6863 11.0723C10.7165 11.0688 10.746 11.0653 10.7756 11.0626C11.0828 11.0347 11.7669 10.6573 11.9064 10.2661C12.0461 9.87487 12.0461 9.53965 12.0043 9.46957C11.9705 9.41323 11.8915 9.37514 11.7745 9.3187C11.7462 9.30509 11.7155 9.29028 11.683 9.27402C11.5154 9.19024 10.6917 8.78482 10.5381 8.72893C10.3844 8.67304 10.2728 8.64516 10.161 8.81283C10.0494 8.9805 9.72846 9.35779 9.63062 9.46957C9.53291 9.58147 9.4352 9.59542 9.26753 9.51164C9.24003 9.49785 9.2025 9.48156 9.15616 9.46145C8.92011 9.35899 8.45521 9.1572 7.92014 8.68007C7.42202 8.23597 7.0858 7.68749 6.98796 7.51982C6.89025 7.35215 6.97754 7.26147 7.06156 7.17795C7.11151 7.12808 7.16898 7.05818 7.22653 6.98819C7.25566 6.95276 7.28481 6.91731 7.31301 6.88443C7.38415 6.80109 7.41501 6.73807 7.45706 6.65222C7.46438 6.63728 7.47203 6.62165 7.4803 6.6051C7.53619 6.4932 7.50831 6.39536 7.46636 6.31159C7.44865 6.27611 7.38002 6.10703 7.29548 5.89871C7.18039 5.61514 7.03577 5.25879 6.94991 5.06781Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChatIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26413 13.6353C5.98201 13.8963 5.8017 14.0084 5.56789 14.0084C5.22718 14.0084 5.0299 13.7663 5.0299 13.3943V11.7556H4.80174C3.15905 11.7556 2.18182 10.7868 2.18182 9.10365V4.71728C2.18182 3.03409 3.13563 2.06071 4.83737 2.06071H12.0519C13.7547 2.06071 14.7085 3.03873 14.7085 4.71728V9.10365C14.7085 10.7822 13.7547 11.7556 12.0519 11.7556H8.34151L6.26413 13.6353ZM10.4211 6.90531C10.4211 7.39151 10.8117 7.78313 11.2968 7.78313C11.784 7.78313 12.1746 7.39151 12.1746 6.90531C12.1746 6.42016 11.784 6.02855 11.2968 6.02855C10.8117 6.02855 10.4211 6.42016 10.4211 6.90531ZM8.45185 7.78313C7.96564 7.78313 7.5694 7.39151 7.5694 6.90531C7.5694 6.42016 7.96564 6.02855 8.45185 6.02855C8.93236 6.02855 9.3286 6.42016 9.3286 6.90531C9.3286 7.39151 8.93236 7.78313 8.45185 7.78313ZM4.72439 6.90531C4.72439 7.39151 5.11962 7.78313 5.60116 7.78313C6.08632 7.78313 6.47793 7.39151 6.47793 6.90531C6.47793 6.42016 6.08632 6.02855 5.60116 6.02855C5.11962 6.02855 4.72439 6.42016 4.72439 6.90531Z"
        fill="currentColor"
      />
    </svg>
  );
}
