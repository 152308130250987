import { useState } from 'react';

import { Actions } from './Actions';
import cls from './Actions.module.scss';
import { MoreIcon } from './icons';

type Props = {
  chatId: number;
};

export const ActionsWrap: React.FC<Props> = ({ chatId }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={cls.root}>
      <button
        className={cls.more_btn}
        type="button"
        onClick={() => setOpen(!isOpen)}
      >
        <MoreIcon />
      </button>

      <Actions
        parentCls={cls.root}
        chatId={chatId}
        isOpen={isOpen}
        setOpen={setOpen}
      />
    </div>
  );
};
