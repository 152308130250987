import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { showAlert } from '@/utils/network';

import {
  GetMyRentTransportBrandsReq,
  GetMyRentTransportGenerationsReq,
  GetMyRentTransportModelsReq,
  GetMyTransportBrandsReq,
  GetMyTransportGenerationsReq,
  GetMyTransportModelsReq,
  GetTransportBodiesReq,
  GetTransportBodiesRes,
  GetTransportBrandsReq,
  GetTransportBrandsRes,
  GetTransportColorsReq,
  GetTransportColorsRes,
  GetTransportDriveUnitsReq,
  GetTransportDriveUnitsRes,
  GetTransportEnginesReq,
  GetTransportEnginesRes,
  GetTransportGenerationsReq,
  GetTransportGenerationsRes,
  GetTransportModelsReq,
  GetTransportModelsRes,
  GetTransportModificationsReq,
  GetTransportModificationsRes,
  GetTransportTransmissionsReq,
  GetTransportTransmissionsRes,
  GetTransportTypesReq,
  GetTransportTypesRes,
  GetTransportYearsReq,
  GetTransportYearsRes
} from './types';

export const TRANSPORT_API_ROUTES = {
  getTypes: '/transport-types/list',
  getBrands: '/transport-brands/list',
  getMyBrands: '/dealers/showrooms/advertisements/my/brands/list',
  getMyRentBrands: '/advertisements/rent/my/brands/list',
  getModels: '/transport-models/list',
  getMyModels: '/dealers/showrooms/advertisements/my/models/list',
  getMyRentModels: '/advertisements/rent/my/models/list',
  getGenerations: '/transport-generation/list',
  getMyGenerations: '/dealers/showrooms/advertisements/my/generations/list',
  getMyRentGenerations: '/advertisements/rent/my/generations/list',
  getYears: '/transport-issue/list',
  getBodies: '/transport-body/list',
  getColors: '/transport-colors/list',
  getEngines: '/transport-engine/list',
  getDriveUnits: '/transport-drive-unit/list',
  getTransmissions: '/transport-transmissions/list',
  getOwners: '/transport-owners/list',
  getModifications: '/transport-modifications/list'
};

// Types
export function useTransportTypes(
  params?: Nullable<GetTransportTypesReq>
): [GetTransportTypesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportTypesRes>([]);

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportTypesRes>(
        TRANSPORT_API_ROUTES.getTypes,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Brands
export function useTransportBrands(
  params?: Nullable<GetTransportBrandsReq>
): [GetTransportBrandsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportBrandsRes>([]);

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportBrandsRes>(
        TRANSPORT_API_ROUTES.getBrands,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useMyTransportBrands(
  params?: Nullable<GetMyTransportBrandsReq>
): [GetTransportBrandsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportBrandsRes>([]);

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportBrandsRes>(
        TRANSPORT_API_ROUTES.getMyBrands,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useMyRentTransportBrands(
  params?: Nullable<GetMyRentTransportBrandsReq>
): [GetTransportBrandsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportBrandsRes>([]);

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportBrandsRes>(
        TRANSPORT_API_ROUTES.getMyRentBrands,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Models
export function useTransportModels(
  params?: Nullable<GetTransportModelsReq>
): [GetTransportModelsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportModelsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportModelsRes>(
        TRANSPORT_API_ROUTES.getModels,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useMyTransportModels(
  params?: Nullable<GetMyTransportModelsReq>
): [GetTransportModelsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportModelsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportModelsRes>(
        TRANSPORT_API_ROUTES.getMyModels,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useMyRentTransportModels(
  params?: Nullable<GetMyRentTransportModelsReq>
): [GetTransportModelsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportModelsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportModelsRes>(
        TRANSPORT_API_ROUTES.getMyRentModels,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Years
export function useTransportYears(
  params?: GetTransportYearsReq
): [GetTransportYearsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportYearsRes>([]);

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetTransportYearsRes>(
        TRANSPORT_API_ROUTES.getYears,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Generations
export function useTransportGenerations(
  params?: Nullable<GetTransportGenerationsReq>
): [GetTransportGenerationsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportGenerationsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportGenerationsRes>(
        TRANSPORT_API_ROUTES.getGenerations,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useMyTransportGenerations(
  params?: Nullable<GetMyTransportGenerationsReq>
): [GetTransportGenerationsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportGenerationsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportGenerationsRes>(
        TRANSPORT_API_ROUTES.getMyGenerations,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useMyRentTransportGenerations(
  params?: Nullable<GetMyRentTransportGenerationsReq>
): [GetTransportGenerationsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportGenerationsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportGenerationsRes>(
        TRANSPORT_API_ROUTES.getMyRentGenerations,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Bodies
export function useTransportBodies(
  params?: Nullable<GetTransportBodiesReq>
): [GetTransportBodiesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportBodiesRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportBodiesRes>(
        TRANSPORT_API_ROUTES.getBodies,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Engines
export function useTransportEngines(
  params?: Nullable<GetTransportEnginesReq>
): [GetTransportEnginesRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportEnginesRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportEnginesRes>(
        TRANSPORT_API_ROUTES.getEngines,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Drive units
export function useTransportDriveUnits(
  params?: Nullable<GetTransportDriveUnitsReq>
): [GetTransportDriveUnitsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportDriveUnitsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportDriveUnitsRes>(
        TRANSPORT_API_ROUTES.getDriveUnits,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Transmissions
export function useTransportTransmissions(
  params?: Nullable<GetTransportTransmissionsReq>
): [GetTransportTransmissionsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportTransmissionsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportTransmissionsRes>(
        TRANSPORT_API_ROUTES.getTransmissions,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Modifications
export function useTransportModifications(
  params?: Nullable<GetTransportModificationsReq>
): [GetTransportModificationsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportModificationsRes>([]);

  const load = useCallback(async () => {
    if (params === null) {
      setData([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const r = await axios.get<GetTransportModificationsRes>(
        TRANSPORT_API_ROUTES.getModifications,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

// Colors
export function useTransportColors(
  params?: Nullable<GetTransportColorsReq>
): [GetTransportColorsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetTransportColorsRes>([]);

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await axios.get<GetTransportColorsRes>(
        TRANSPORT_API_ROUTES.getColors,
        {
          params
        }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
