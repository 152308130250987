import { useCallback, useContext, useMemo, useRef } from 'react';

import { OptionI } from '@/app/types';
import { useExistedStatuses } from '@/modules/showroom/advert/api';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { GetExistedStatusesRes } from '@/modules/showroom/advert/types';
import { Skeleton } from '@/ui/Skeleton';
import { Tabs } from '@/ui/Tabs/Tabs';

import cls from './SearchAdverts.module.scss';

const skeletons = new Array(6).fill(0);

type Props = {
  categoryId: number;
};

export function Statuses({ categoryId }: Props) {
  const { refreshKey } = useContext(MyAdvertsContext);
  const { status, setStatus } = useContext(MyAdvertsContext);

  const loadHandledRef = useRef(false);
  const onStatusesLoad = useCallback(
    (loadedList: GetExistedStatusesRes) => {
      if (!loadHandledRef.current && loadedList[0]) {
        loadHandledRef.current = true;
        setStatus(loadedList[0]);
      }
    },
    [setStatus]
  );

  const [statuses, loading] = useExistedStatuses(
    categoryId,
    refreshKey,
    onStatusesLoad
  );

  const options = useMemo<OptionI[]>(
    () =>
      statuses
        ? statuses.map((v) => ({
            id: v.status as string,
            text: v.title,
            extra: String(v.count)
          }))
        : [],
    [statuses]
  );
  const option = options.find((opt) => opt.id === status?.status);
  const onChange = (opt: OptionI) => {
    const s = statuses?.find((v) => v.status === opt.id);
    if (s) setStatus(s);
  };

  return (
    <div className={cls.statuses}>
      {!loading && statuses && statuses.length > 0 ? (
        <Tabs
          variant="underline"
          tabs={options}
          active={option}
          onChange={onChange}
        />
      ) : (
        <ul className={cls.skeletons}>
          {skeletons.map((_, i) => (
            <li key={i}>
              <div style={{ paddingBottom: 14 }}>
                <Skeleton width={80} height={22} />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
