import { create } from 'zustand';

import { axios } from '@/app/api';
import { LANGS } from '@/modules/i18n';
import {
  GetRentDocsRes,
  RentDocsCategory
} from '@/modules/showroom/advert/rent/docs/types';
import {
  getFromStorage,
  removeFromStorage,
  saveInStorage
} from '@/utils/local-storage';
import { showAlert } from '@/utils/network';

const RENT_DOCS_STORE_NAME = 'dealers-autoae-rentdocs-list';

type StoreValue = Record<string, RentDocsCategory[]>;
const defaultValue = LANGS.reduce((acc, lang) => {
  return { ...acc, [lang]: [] };
}, {});

interface RentDocsStore {
  data: StoreValue;
  fetchData: () => void;
  reset: () => void;
}

export const useRentDocsStore = create<RentDocsStore>((set, get) => ({
  data: getFromStorage(RENT_DOCS_STORE_NAME) || defaultValue,
  fetchData: async () => {
    const current = get().data;
    if (Object.values(current).every((list) => !!list.length)) return;

    try {
      const promises = LANGS.map((lang) =>
        axios.get<GetRentDocsRes>('/advertisements/rent/docs', {
          headers: { lang }
        })
      );
      const results = await Promise.all(promises);
      const data: StoreValue = results.reduce((acc, res, i) => {
        return { ...acc, [LANGS[i]]: res.data };
      }, {});

      set({ data });
      saveInStorage(RENT_DOCS_STORE_NAME, data);
    } catch (error) {
      showAlert({ error });
    }
  },
  reset: () => {
    set({ data: defaultValue });
    removeFromStorage(RENT_DOCS_STORE_NAME);
  }
}));
