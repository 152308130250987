import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { Input } from '@/ui/Input/Input';

import { ListIcon, RemoveIcon } from './icons';
import cls from './PrimarySelect.module.scss';

function isOptionActive(option: OptionI, value: OptionI[]): boolean {
  return value.map((v) => v.id).includes(option.id);
}

type Props = {
  name: string;
  value?: OptionI[];
  options: OptionI[];
  onChange?: (option: OptionI[]) => void;
  onOptionRemove?: (option: OptionI) => void;
  onOptionAdd?: (option: OptionI, removeOtherOptions?: boolean) => void;
  multiple?: boolean;
  dropdownTop?: boolean;
  dropdownFullWidth?: boolean;
  dropdownDesktop?: boolean;
  clearText?: Nullable<string>;
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  onClearClick: () => void;
  showSearch?: boolean;
  searchFilterFn?: (searchText: string, id: OptionI['id']) => void;
  firstDropdownItem?: React.ReactNode;
  showScrollbar?: boolean;
  disableOutsideClick?: boolean;
};

export const PrimarySelectDropdown: React.FC<Props> = ({
  name,
  value,
  options,
  dropdownTop,
  dropdownFullWidth,
  dropdownDesktop,
  clearText,
  isOpen,
  setOpen,
  onChange,
  onOptionRemove,
  onOptionAdd,
  multiple,
  onClearClick,
  showSearch,
  searchFilterFn,
  firstDropdownItem,
  showScrollbar,
  disableOutsideClick
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const searchLow = search.toLowerCase().trim();
  const filteredOptions = useMemo(() => {
    if (showSearch && searchLow) {
      return options.filter((o) => {
        if (searchFilterFn) {
          return searchFilterFn(searchLow, o.id);
        }
        return o.text.toLowerCase().includes(searchLow);
      });
    }

    return options;
  }, [showSearch, searchLow, options, searchFilterFn]);

  const hasValue = !!value && value.length > 0;

  const onOptionClick = (option: OptionI) => {
    return () => {
      const prevState = value || [];
      const prevIds = prevState.map((v) => v.id);
      const hasThisOption = prevIds.includes(option.id);

      if (onChange) {
        if (hasThisOption) {
          onChange(prevState.filter((v) => v.id !== option.id));
          if (!multiple) setOpen(false);
        } else {
          const merged = [...prevState, option];

          if (multiple) {
            onChange(merged);
          } else {
            onChange([option]);
          }
        }

        return;
      }

      if (multiple) {
        if (onOptionRemove && hasThisOption) {
          onOptionRemove(option);
          return;
        }

        if (onOptionAdd && !hasThisOption) {
          onOptionAdd(option);
          return;
        }
      } else {
        if (hasThisOption) {
          if (onOptionRemove) {
            onOptionRemove(option);
            setOpen(false);
          }
        } else {
          if (onOptionAdd) {
            onOptionAdd(option, true);
            setOpen(false);
          }
        }
      }
    };
  };

  return (
    <Dropdown
      name={name}
      isOpen={isOpen}
      close={() => setOpen(false)}
      parentCls={cls.root}
      className={cls.dropdown}
      modalClassName={cn({ [cls.dropdown_modal]: showSearch })}
      listTop={dropdownTop}
      fullWidth={dropdownFullWidth}
      isDesktop={dropdownDesktop}
      showScrollbar={showScrollbar}
      disableOutsideClick={disableOutsideClick}
      withModal
    >
      {firstDropdownItem && (
        <DropdownItem className={cls.li}>
          <div>{firstDropdownItem}</div>
        </DropdownItem>
      )}

      {showSearch && (
        <DropdownItem className={cls.li}>
          <div>
            <Input
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder={t('common.search')}
              onClear={() => setSearch('')}
              small
            />
          </div>
        </DropdownItem>
      )}

      {isOpen && clearText && (
        <DropdownItem className={cls.seperated_li}>
          <button className={cls.list_btn} type="button" onClick={onClearClick}>
            <span className={cls.clear_icon}>
              <RemoveIcon />
            </span>
            <span>{clearText}</span>
          </button>
        </DropdownItem>
      )}

      {isOpen &&
        filteredOptions.map((v) => {
          const isActive = hasValue && isOptionActive(v, value);

          return (
            <DropdownItem key={v.id} active={isActive}>
              <button
                className={cls.list_btn}
                type="button"
                onClick={onOptionClick(v)}
              >
                <span className={cls.list_icon}>
                  {isActive && <ListIcon />}
                </span>

                <span>{v.text}</span>
              </button>
            </DropdownItem>
          );
        })}
    </Dropdown>
  );
};

PrimarySelectDropdown.displayName = 'PrimarySelectDropdown';
