import { useTranslation } from 'react-i18next';

import { ImgIcon } from './icons';
import cls from './UploadBtn.module.scss';

type Props = {
  onClick: () => void;
};

export function UploadBtn({ onClick }: Props) {
  const { t } = useTranslation();

  return (
    <button className={cls.root} onClick={onClick}>
      <ImgIcon />
      <span>{t('cover.upload')}</span>
    </button>
  );
}
