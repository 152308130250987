const COLORS = [
  '#56CCF2',
  '#BB6BD9',
  '#6FCF97',
  '#F2B703',
  '#967CBA',
  '#FF9960',
  '#566FF2',
  '#FF89C9',
  '#1CBDE0',
  '#F38460',
  '#939ED6',
  '#F271A0',
  '#2ABF93',
  '#FF9C9C',
  '#23B0FF',
  '#3B4244'
];

export function getRandomColor(id: number): string {
  const i = id ? id % COLORS.length : 0;
  return COLORS[i];
}
