import { createContext } from 'react';

import { Nullable } from '@/app/types';
import { BookingExistedStatus } from '@/modules/booking/types';
import { RentAdvertExistedStatus } from '@/modules/showroom/advert/rent/my/types';
import { AdvertExistedStatus } from '@/modules/showroom/advert/types';

type StatusType =
  | Nullable<
      AdvertExistedStatus | RentAdvertExistedStatus | BookingExistedStatus
    >
  | undefined;

interface MyAdvertsContextType {
  refreshKey: number;
  status: StatusType;
  setStatus: React.Dispatch<React.SetStateAction<StatusType>>;
  refresh: () => void;
  notFoundAds: boolean;
  setNotFoundAds: (v: boolean) => void;
}

export const MyAdvertsContext = createContext<MyAdvertsContextType>({
  refreshKey: 0,
  status: undefined,
  setStatus: () => null,
  refresh: () => null,
  notFoundAds: false,
  setNotFoundAds: () => null
});
