import { Complectation } from '@/modules/showroom/advert/create/Complectation/Complectation';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { SLUG_NEW } from '@/utils/consts';

export function ComplectationWrap() {
  const type = useUpdateAdvertStore((s) => s.type);
  const isNewCar = type?.slug === SLUG_NEW;
  const nextSection = isNewCar ? SCROLLTO.regspecs : SCROLLTO.owner;

  const modification = useUpdateAdvertStore((s) => s.modification);
  const isCustomComplectation = useUpdateAdvertStore(
    (s) => s.isCustomComplectation
  );
  const setCustomComplectation = useUpdateAdvertStore(
    (s) => s.setCustomComplectation
  );
  const complectationId = useUpdateAdvertStore((s) => s.complectationId);
  const setComplectationId = useUpdateAdvertStore((s) => s.setComplectationId);
  const setComplectationOptionsItemsIds = useUpdateAdvertStore(
    (s) => s.setComplectationOptionsItemsIds
  );
  const complectationOptionsItemsIds = useUpdateAdvertStore(
    (s) => s.complectationOptionsItemsIds
  );
  const isComplectationFilled = useUpdateAdvertStore(
    (s) => s.isComplectationFilled
  );
  const setComplectationFilled = useUpdateAdvertStore(
    (s) => s.setComplectationFilled
  );

  return (
    <>
      {modification && (
        <Complectation
          nextSection={nextSection}
          modification={modification}
          isCustomComplectation={isCustomComplectation}
          setCustomComplectation={setCustomComplectation}
          complectationId={complectationId}
          setComplectationId={setComplectationId}
          complectationOptionsItemsIds={complectationOptionsItemsIds}
          setComplectationOptionsItemsIds={setComplectationOptionsItemsIds}
          isComplectationFilled={isComplectationFilled}
          setComplectationFilled={setComplectationFilled}
        />
      )}
    </>
  );
}
