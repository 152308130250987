export function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.5C8.62826 17.5 8.97342 17.3174 9.21323 16.9516L17.1225 4.62165C17.2997 4.34431 17.3708 4.11224 17.3708 3.88387C17.3708 3.30445 16.9633 2.9046 16.3779 2.9046C15.9625 2.9046 15.7214 3.04428 15.4694 3.43976L8.15705 15.0568L4.38887 10.2117C4.14367 9.88698 3.88636 9.75098 3.52286 9.75098C2.92207 9.75098 2.5 10.1715 2.5 10.7524C2.5 11.002 2.59323 11.2541 2.80309 11.5091L7.16264 16.9635C7.45294 17.3266 7.76679 17.5 8.19071 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
