import cn from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { useDebounce } from '@/hooks/useDebounce';
import { useDefaultPeriod } from '@/hooks/useDefaultPeriod';
import { useLang } from '@/hooks/useLang';
import { BalanceCard } from '@/modules/wallet/BalanceCard/BalanceCard';
import { DepositHistory } from '@/modules/wallet/DepositHistory/DepositHistory';
import { ExpensesAdverts } from '@/modules/wallet/ExpensesAdverts/ExpensesAdverts';
import { ExpensesDays } from '@/modules/wallet/ExpensesDays/ExpensesDays';
import { BalanceFilters } from '@/modules/wallet/Filters/BalanceFilters';
import { WalletFilters } from '@/modules/wallet/Filters/WalletFilters';
import {
  useExpensesDetailStatistics,
  useExpensesDetailStatisticsV2,
  WalletCategories,
  WalletCategoriesType,
  WalletFilterType
} from '@/modules/wallet/hooks';
import { WalletGraphWrap } from '@/modules/wallet/WalletGraph/WalletGraphWrap';
import { Card } from '@/ui/Card/Card';
import { DatesFilter } from '@/ui/Filters/DatesFilter';
import { Input } from '@/ui/Input/Input';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { useQuery } from '@/utils/query';

import cls from './WalletPage.module.scss';

const MIN_GRAPH_WORK_SIZE = 480;

export function WalletPage() {
  const { t } = useTranslation();
  const [lang] = useLang();
  const defaultPeriod = useDefaultPeriod();
  const windowWidth = useCommonStore((s) => s.windowWidth);

  const [activeTab, setActiveTab] = useState<WalletCategories>(
    WalletCategories.debit
  );

  const showGraph =
    windowWidth > MIN_GRAPH_WORK_SIZE && activeTab === WalletCategories.debit;

  // Wallet filters
  const onWalletFilterClick = (tab: WalletCategoriesType) => {
    setActiveTab(tab.id);
  };

  // Filter type
  const [filterType, setFilterType] = useState<WalletFilterType>(
    WalletFilterType.services
  );

  // Vin
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryHandled = useRef(false);
  const debouncedSearchQuery = useDebounce(searchQuery, 800);

  const query = useQuery();
  useEffect(() => {
    if (
      query &&
      query.vin &&
      typeof query.vin === 'string' &&
      !searchQueryHandled.current
    ) {
      setSearchQuery(query.vin);
      setActiveTab(WalletCategories.debit);
      setFilterType(WalletFilterType.adverts);
      searchQueryHandled.current = true;
    }
  }, [query]);

  // Dates
  const [from, setFrom] = useState<Date>(defaultPeriod.from);
  const [to, setTo] = useState<Date>(defaultPeriod.to);
  const onDatesChange = (fromDate: Date, toDate: Date) => {
    toDate.setHours(23, 59, 59);
    setFrom(fromDate);
    setTo(toDate);
  };
  const fromPeriodUnix = useMemo(
    () => Math.round(from.getTime() / 1000),
    [from]
  );
  const toPeriodUnix = useMemo(() => Math.round(to.getTime() / 1000), [to]);

  // Expenses data
  const expensesReq = useMemo(
    () => ({
      period_start: fromPeriodUnix,
      period_end: toPeriodUnix,
      search: debouncedSearchQuery || undefined
    }),
    [debouncedSearchQuery, fromPeriodUnix, toPeriodUnix]
  );
  const [expensesData, expensesLoading] = useExpensesDetailStatistics(
    expensesReq,
    lang
  );
  const [daysData, daysLoading] = useExpensesDetailStatisticsV2(
    expensesReq,
    lang
  );

  return (
    <>
      <LayoutHeader title={t('wallet.title')} />
      <PageHead title={t('pageTitle', { title: t('wallet.title') })} />

      <div className={cls.root}>
        <div className={cls.tabs}>
          <WalletFilters onClick={onWalletFilterClick} />
        </div>

        <div className={cls.container}>
          <div className={cls.content}>
            <div
              className={cls.row}
              style={{
                marginBottom: activeTab === WalletCategories.refill ? 24 : 16
              }}
            >
              <DatesFilter from={from} to={to} onDatesChange={onDatesChange} />
              {/* <Button
                variant="secondary"
                color="black"
                size="compact"
                gap={4}
                onClick={() =>
                  showAlert({ type: 'message', text: 'todo: export' })
                }
              >
                <DownloadIcon />
                {t('export')}
              </Button> */}
            </div>

            {showGraph && (
              <Card>
                <WalletGraphWrap
                  from={from}
                  to={to}
                  data={expensesData}
                  loading={expensesLoading}
                />
              </Card>
            )}

            <div
              style={{
                display:
                  activeTab === WalletCategories.refill ? 'block' : 'none'
              }}
            >
              <DepositHistory from={from} to={to} />
            </div>
          </div>

          <aside
            className={cls.aside}
            style={{
              paddingTop: activeTab === WalletCategories.refill ? 0 : 52
            }}
          >
            <BalanceCard
              fromPeriod={fromPeriodUnix}
              toPeriod={toPeriodUnix}
              buttonSize="l"
              balanceInfo={daysData?.balance}
              loading={daysLoading}
            />
          </aside>
        </div>

        <div
          style={{
            display: activeTab === WalletCategories.debit ? 'block' : 'none'
          }}
        >
          {filterType === WalletFilterType.services && (
            <>
              <div className={cls.view_types}>
                <div className={cls.type_tabs}>
                  <BalanceFilters
                    selected={filterType}
                    onChange={setFilterType}
                  />
                </div>
              </div>

              <div className={cls.days}>
                <ExpensesDays
                  days={daysData?.mapped_days || []}
                  loading={daysLoading}
                />
              </div>
            </>
          )}

          {filterType === WalletFilterType.adverts && (
            <>
              <div className={cls.view_types}>
                <div className={cls.type_tabs}>
                  <BalanceFilters
                    selected={filterType}
                    onChange={setFilterType}
                  />
                </div>

                <div className={cls.search_input}>
                  <Input
                    placeholder={t('common.search')}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.currentTarget.value)}
                    small
                  />
                </div>
              </div>

              {expensesData && (
                <div className={cn(cls.container, cls.container_adverts)}>
                  <ExpensesAdverts expenses={expensesData} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
