import { uploadFile } from '@/modules/files/api';

import { DrawSettings } from './draw-settings';

// Helpers
let fontsLoaded = false;
export function loadFonts() {
  if (fontsLoaded) return Promise.resolve();

  return new Promise<void>((resolve, reject) => {
    const semiboldFont = new FontFace(
      'Oswald',
      'url(/fonts/oswald/Oswald-SemiBold.woff2)'
    );
    const promises = [semiboldFont.load()];
    Promise.all(promises)
      .then((fonts) => {
        fonts.forEach((font) => {
          document.fonts.add(font);
        });
        fontsLoaded = true;
        resolve();
      })
      .catch(reject);
  });
}

export function loadImg(src: string) {
  return new Promise<HTMLImageElement>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.crossOrigin = 'anonymous';
    img.src = src;
  });
}

export function getNumberImage(
  number: string,
  settings: DrawSettings['number']
) {
  const canvas = document.createElement('canvas');
  canvas.style.fontFamily = 'Oswald';
  const ctx = canvas.getContext('2d');
  canvas.width = settings.w;
  canvas.height = settings.h;
  if (!ctx) return null;

  ctx.font = `${settings.fw} ${settings.fs}px Oswald`;
  ctx.textAlign = 'center';
  const m = ctx.measureText(number);
  ctx.fillText(
    number,
    canvas.width / 2,
    m.actualBoundingBoxAscent / 2 + canvas.height / 2
  );
  const r = canvas.toDataURL('image/png');
  return r;
}

export function getCodeImage(code: string, settings: DrawSettings['code']) {
  if (!settings) return null;

  const canvas = document.createElement('canvas');
  canvas.style.fontFamily = 'Oswald';
  const ctx = canvas.getContext('2d');
  canvas.width = settings.w;
  canvas.height = settings.h;
  if (!ctx) return null;

  ctx.font = `${settings.fw} ${settings.fs}px Oswald`;
  ctx.textAlign = 'center';
  const m = ctx.measureText(code);
  ctx.fillText(
    code,
    canvas.width / 2,
    m.actualBoundingBoxAscent / 2 + canvas.height / 2
  );

  const r = canvas.toDataURL('image/png');
  return r;
}

const SNAPSHOT_WIDTH = 1026;
const SNAPSHOT_HEIGHT = 770;
const SNAPSHOT_PLATE_WIDTH = 810;
const SNAPSHOT_PLATE_HEIGHT = 186.5;
const SNAPSHOT_PLATE_MOTO_WIDTH = 713.7;
const SNAPSHOT_PLATE_MOTO_HEIGHT = 360;
export async function generatePlateSnapshot(
  plateSrc: string,
  moto: boolean,
  onUpload: (src: string) => void
) {
  const canvas = document.createElement('canvas');
  canvas.style.fontFamily = 'Oswald';
  const ctx = canvas.getContext('2d');
  canvas.width = SNAPSHOT_WIDTH;
  canvas.height = SNAPSHOT_HEIGHT;

  if (!ctx) return;

  const img = await loadImg(plateSrc);
  const imgW = moto ? SNAPSHOT_PLATE_MOTO_WIDTH : SNAPSHOT_PLATE_WIDTH;
  const imgH = moto ? SNAPSHOT_PLATE_MOTO_HEIGHT : SNAPSHOT_PLATE_HEIGHT;
  ctx.drawImage(
    img,
    canvas.width / 2 - imgW / 2,
    canvas.height / 2 - imgH / 2,
    imgW,
    imgH
  );

  canvas.toBlob((blob) => {
    if (!blob) return;
    const file = new File([blob], `plate-number.png`, {
      type: 'image/png'
    });

    uploadFile({ file }).then((r) => {
      onUpload(r.data.url);
    });
  }, 'image/png');
}
