import { useEffect, useState } from 'react';

import { CommonObj, Nullable } from '@/app/types';

export function useSpecsEffect<T extends CommonObj>(
  selected: Nullable<T>,
  list: T[] | null | undefined,
  onChange: (v: T) => void
) {
  const [selectedByVin, setSelectedByVin] = useState(false);

  useEffect(() => {
    if (list && list.length === 1) {
      const [first] = list;
      if (!selected || (selected.id !== first.id && !selectedByVin)) {
        onChange(first);
      }
    } else if (list && selected && !selectedByVin) {
      const ids = list.map((m) => m.id);
      if (!ids.includes(selected.id)) {
        setSelectedByVin(true);
        onChange(selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, list, selectedByVin]);
}
