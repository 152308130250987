export function TailIcon(): JSX.Element {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55533 11.925C6.33324 10.2886 6.60599 5.52278 6.5 0C10.0246 0 12.8818 2.85725 12.8818 6.38184V13.287C9.7022 16.0534 5.63485 17.3795 0.679752 16.9056C0.616535 16.8996 0.560035 16.8642 0.527676 16.8105C0.47165 16.7174 0.502931 16.5973 0.597558 16.5422L0.955302 16.3299C3.2998 14.9125 4.83314 13.4441 5.55533 11.925Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function MyTailIcon(): JSX.Element {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.44467 11.925C6.66676 10.2886 6.39401 5.52278 6.5 0C2.97541 0 0.118164 2.85725 0.118164 6.38184V13.287C3.2978 16.0534 7.36515 17.3795 12.3202 16.9056C12.3835 16.8996 12.44 16.8642 12.4723 16.8105C12.5283 16.7174 12.4971 16.5973 12.4024 16.5422L12.0447 16.3299C9.7002 14.9125 8.16686 13.4441 7.44467 11.925Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function UnreadIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.67716 9.28387C3.36877 9.00734 2.8946 9.03317 2.61807 9.34155C2.34153 9.64994 2.36736 10.1241 2.67575 10.4006L3.67716 9.28387ZM6.37 12.7059L5.8693 13.2643C6.15184 13.5176 6.5791 13.52 6.86444 13.2698L6.37 12.7059ZM15.318 5.85807C15.6294 5.58499 15.6605 5.11114 15.3874 4.79969C15.1144 4.48823 14.6405 4.45712 14.3291 4.73019L15.318 5.85807ZM2.67575 10.4006L5.8693 13.2643L6.8707 12.1475L3.67716 9.28387L2.67575 10.4006ZM6.86444 13.2698L15.318 5.85807L14.3291 4.73019L5.87556 12.142L6.86444 13.2698Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ReadIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.57058 9.22737C1.26776 8.94474 0.793172 8.96111 0.510546 9.26392C0.227921 9.56674 0.244286 10.0413 0.547099 10.324L1.57058 9.22737ZM4.01369 12.5335L3.50196 13.0818C3.79011 13.3508 4.23728 13.3508 4.52543 13.0818L4.01369 12.5335ZM12.2819 5.84242C12.5847 5.55979 12.6011 5.0852 12.3185 4.78239C12.0359 4.47958 11.5613 4.46321 11.2585 4.74584L12.2819 5.84242ZM9.18469 12.7059L8.67296 13.2542C8.96111 13.5231 9.40828 13.5231 9.69643 13.2542L9.18469 12.7059ZM17.4529 6.01479C17.7557 5.73216 17.7721 5.25757 17.4895 4.95476C17.2069 4.65194 16.7323 4.63558 16.4295 4.9182L17.4529 6.01479ZM8.77304 11.2958C8.47023 11.0131 7.99564 11.0295 7.71301 11.3323C7.43038 11.6351 7.44675 12.1097 7.74956 12.3924L8.77304 11.2958ZM0.547099 10.324L3.50196 13.0818L4.52543 11.9852L1.57058 9.22737L0.547099 10.324ZM4.52543 13.0818L12.2819 5.84242L11.2585 4.74584L3.50196 11.9852L4.52543 13.0818ZM9.69643 13.2542L17.4529 6.01479L16.4295 4.9182L8.67296 12.1576L9.69643 13.2542ZM9.69643 12.1576L8.77304 11.2958L7.74956 12.3924L8.67296 13.2542L9.69643 12.1576Z"
        fill="currentColor"
      />
    </svg>
  );
}
