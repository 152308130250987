import cn from 'classnames';
import { useMemo } from 'react';

import { useDesktop } from '@/app/common-store';
import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { ChatSimple } from '@/modules/chat/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { Avatar } from '@/ui/Avatar/Avatar';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';

import cls from './ChatCard.module.scss';

type Props = {
  chat: ChatSimple;
};

export function ChatImage({ chat }: Props) {
  const isDesktop = useDesktop();

  const { ad, companion } = chat;
  const status = ad?.status;
  const photos = ad?.photos;
  const isSold = status && status.title === AdvertStatusType.sold;
  const isRemoved = status && status.title === AdvertStatusType.removed;
  const isVrp = !!chat.ad?.vrp_image_url;

  const imgPlaceholder = useImgPlaceholder();
  const src = useMemo(() => {
    if (ad?.vrp_image_url) return ad.vrp_image_url;
    if (!photos || photos.length <= 0) return imgPlaceholder;
    const s = photos[0].thumbnail_url || photos[0].photo_url;
    return s || imgPlaceholder;
  }, [ad?.vrp_image_url, photos, imgPlaceholder]);

  return (
    <div
      className={cls.img_wrap}
      style={{ backgroundColor: isVrp ? 'var(--thm-card)' : '' }}
    >
      {companion && (
        <div className={cls.avatar}>
          <Avatar
            src={companion.author_avatar_url || ''}
            user={{
              id: companion.user_id,
              name: companion.name || ad?.brand?.name || ''
            }}
            size={isDesktop ? 28 : 24}
          />
        </div>
      )}

      <ImgSkeleton
        className={cls.img_ad}
        src={src}
        alt=""
        imgProxyWidth={85}
        imgProxyHeight={66}
      />

      {(isSold || isRemoved) && (
        <span
          className={cn(cls.img_status, cls.trunc, {
            [cls.img_status_grey]: isRemoved
          })}
        >
          {status.name}
        </span>
      )}
    </div>
  );
}
