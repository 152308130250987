import { Skeleton } from '@/ui/Skeleton';

import cls from './AddressCard.module.scss';

export function AddressCardSkeleton() {
  return (
    <div className={cls.root}>
      <Skeleton width={200} height={150} borderRadius={8} />

      <div className={cls.info}>
        <div className={cls.header}>
          <div className={cls.title}>
            <Skeleton width="40%" height={24} />
            <Skeleton width="80%" height={20} />
          </div>
        </div>

        <div className={cls.footer}>
          <div className={cls.phone}>
            <Skeleton width={65} height={20} />
            <Skeleton width={130} height={24} />
          </div>

          <div className={cls.work_time}>
            <Skeleton width={80} height={20} />
            <Skeleton width={126} height={24} />
          </div>
        </div>
      </div>
    </div>
  );
}
