export function ArrowIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.78346 13.3338L15.5001 7.61712C15.9512 7.16608 15.9512 6.43481 15.5001 5.98378C15.0491 5.53275 14.3178 5.53275 13.8668 5.98378L6.17508 13.6755C5.71947 14.1311 5.71947 14.8698 6.17508 15.3254L13.8668 23.0171C14.3178 23.4681 15.0491 23.4681 15.5001 23.0171C15.9512 22.5661 15.9512 21.8348 15.5001 21.3838L9.78346 15.6671H21.6835C22.3278 15.6671 22.8501 15.1448 22.8501 14.5004C22.8501 13.8561 22.3278 13.3338 21.6835 13.3338H9.78346Z"
        fill="currentColor"
      />
    </svg>
  );
}
