import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { BookingType } from '@/modules/booking/types';
import { CallbackItem } from '@/modules/callback/types';
import { ChatAdvert } from '@/modules/chat/types';
import { CreditRequestType } from '@/modules/credit/types';
import { ReviewAd } from '@/modules/reviews/types';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { RentAdvert } from '@/modules/showroom/advert/rent/my/types';
import {
  updateAdvertAutoExtension,
  updateAdvertPromoPackage,
  updateAdvertStickers
} from '@/modules/showroom/advert/update/api';
import { UpdateAdvertStickersReq } from '@/modules/showroom/advert/update/types';
import { StatisticAdvert } from '@/modules/wallet/types';
import { showAlert } from '@/utils/network';

export function useAdvertName(
  advert:
    | BookingType['rent_ad']
    | CreditRequestType['ad_info']
    | MyAdvert
    | RentAdvert
    | StatisticAdvert
    | ChatAdvert
    | ReviewAd
    | CallbackItem['advertisement'],
  fullVrpName?: boolean
): string {
  const { t } = useTranslation();

  const isVrp = 'vrp_plate_emirate' in advert && !!advert.vrp_plate_emirate;
  const brand = advert.brand?.name || '';
  const model = advert.model?.name || '';
  const generation =
    'generation' in advert ? advert.generation?.name || '' : '';
  const year = advert.year_of_issue?.name || '';
  const name = useMemo(() => {
    const title = t('advertisements.advertTitle', {
      brand,
      model,
      generation
    }).trim();

    if (year) return t('common.commaSeparated', { first: title, second: year });

    return title;
  }, [brand, generation, model, t, year]);

  const vrpName = useMemo(() => {
    const emirate =
      'vrp_plate_emirate' in advert ? advert.vrp_plate_emirate?.name || '' : '';
    const type = 'vrp_type' in advert ? advert.vrp_type?.name || '' : '';
    const code =
      'vrp_plate_code' in advert ? advert.vrp_plate_code?.name || '' : '';
    const number =
      'vrp_plate_number' in advert ? advert.vrp_plate_number || '' : '';

    if (fullVrpName) {
      return [type, emirate, `${code} ${number}`]
        .filter((v) => !!v)
        .join(t('common.listSeparator') as string);
    }

    if (emirate && type)
      return t('common.commaSeparated', { first: emirate, second: type });

    if (!emirate && type) return type;
    return emirate;
  }, [advert, fullVrpName, t]);

  return isVrp ? vrpName : name;
}

export function useAdvertPromoUpdate() {
  const updateAutoExtension = async (
    advertId: Nullable<number>,
    autoExtension: boolean
  ) => {
    if (!advertId) return;

    try {
      await updateAdvertAutoExtension(advertId, autoExtension);
    } catch (error) {
      showAlert({ error });
    }
  };

  const updatePromoPackage = async (
    advertId: Nullable<number>,
    promoPackageId: number
  ) => {
    if (!advertId) return;

    try {
      await updateAdvertPromoPackage(advertId, promoPackageId);
    } catch (error) {
      showAlert({ error });
    }
  };

  const updatePromoStickers = async (
    advertId: Nullable<number>,
    req: UpdateAdvertStickersReq
  ) => {
    if (!advertId) return;

    try {
      await updateAdvertStickers(advertId, req);
    } catch (error) {
      showAlert({ error });
    }
  };

  return {
    updateAutoExtension,
    updatePromoPackage,
    updatePromoStickers
  };
}
