import { Skeleton } from '@/ui/Skeleton';

import cls from './ContactCard.module.scss';

export function ContactCardSkeleton() {
  return (
    <div className={cls.root}>
      <div className={cls.wrap}>
        <div className={cls.header}>
          <div className={cls.person}>
            <Skeleton width={48} height={48} borderRadius="50%" />
            <Skeleton width={72} height={24} />
          </div>

          <div className={cls.work_time}>
            <Skeleton width={120} height={20} />
            <Skeleton width={120} height={24} />
          </div>
        </div>
        <div className={cls.info}>
          <div className={cls.langs} style={{ lineHeight: 1.25 }}>
            <Skeleton width={124} height={20} />
            <Skeleton width={64} height={20} />
          </div>
          <div className={cls.phone}>
            <Skeleton width={200} height={24} />
          </div>
        </div>
      </div>
    </div>
  );
}
