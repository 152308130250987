import { useTranslation } from 'react-i18next';

import bgImg from './bg.png';
import cls from './EmptyGraph.module.scss';

export function EmptyGraph() {
  const { t } = useTranslation();

  return (
    <div className={cls.root} style={{ backgroundImage: `url(${bgImg}` }}>
      <div className={cls.blur}>
        <h3>{t('wallet.emptyData')}</h3>
      </div>
    </div>
  );
}
