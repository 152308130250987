import { useCallback, useEffect, useState } from 'react';

import { Nullable } from '@/app/types';
import { getBalance } from '@/modules/accounts/api';
import { useAccountStore } from '@/modules/accounts/store';
import { BalanceReq, BalanceRes } from '@/modules/accounts/types';
import { showAlert } from '@/utils/network';

export function useAccountBalance() {
  const isUserInfoLoading = useAccountStore((s) => s.isUserLoading);
  const balanceInfo = useAccountStore((s) => s.balanceInfo);

  return {
    balanceInfo,
    balanceAmount: balanceInfo?.balance ? Number(balanceInfo.balance) : 0,
    balanceLoading: isUserInfoLoading
  };
}

export function useAccountBalancePeriod(params: BalanceReq, skip?: boolean) {
  const [balanceInfo, setBalanceInfo] = useState<Nullable<BalanceRes>>();
  const [balanceLoading, setBalanceLoading] = useState(false);

  const load = useCallback(async () => {
    setBalanceLoading(true);

    try {
      const r = await getBalance(params);
      setBalanceInfo(r.data);
    } catch (error) {
      setBalanceInfo(undefined);
      showAlert({ error });
    } finally {
      setBalanceLoading(false);
    }
  }, [params]);

  useEffect(() => {
    if (!skip) load();
  }, [load, skip]);

  return {
    balanceInfo,
    balanceAmount: balanceInfo?.balance || 0,
    balanceLoading
  };
}
