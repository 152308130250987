export function DesktopIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.78136 17.479H19.886C21.3139 17.479 22.1679 16.6249 22.1679 15.1971V5.21954C22.1679 3.79168 21.3139 2.93768 19.886 2.93768H3.78136C2.35351 2.93768 1.49951 3.79168 1.49951 5.21954V15.1971C1.49951 16.6249 2.35351 17.479 3.78136 17.479ZM3.62796 13.468C3.39743 13.468 3.29001 13.3821 3.29001 13.1389V5.32222C3.29001 4.95291 3.51474 4.72819 3.88406 4.72819H19.7833C20.1527 4.72819 20.3774 4.95291 20.3774 5.32222V13.1389C20.3774 13.3821 20.2788 13.468 20.0395 13.468H3.62796ZM9.04353 19.341H14.6327V17.3039H9.04353V19.341ZM9.03472 20.5761H14.6415C15.1029 20.5761 15.4865 20.1937 15.4865 19.733C15.4865 19.2724 15.1029 18.8813 14.6415 18.8813H9.03472C8.56444 18.8813 8.18094 19.2724 8.18094 19.733C8.18094 20.1937 8.56444 20.5761 9.03472 20.5761Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function TabletIcon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.76742 19.437H18.235C20.1203 19.437 21.0844 18.4709 21.0844 16.612V6.39689C21.0844 4.53807 20.1203 3.57385 18.235 3.57385H3.76548C1.88904 3.57385 0.916016 4.53807 0.916016 6.39689V16.612C0.916016 18.4709 1.89097 19.437 3.76742 19.437ZM3.87398 17.5991C3.15081 17.5991 2.74712 17.2295 2.74712 16.4634V6.54749C2.74712 5.79017 3.14888 5.41183 3.87205 5.41183H18.1284C18.8516 5.41183 19.2553 5.79017 19.2553 6.54749V16.4634C19.2553 17.2295 18.8516 17.5991 18.1284 17.5991H3.87398ZM7.8458 16.9294H14.1654C14.4222 16.9294 14.6037 16.7605 14.6037 16.4911C14.6037 16.2129 14.4222 16.0421 14.1654 16.0421H7.8458C7.58906 16.0421 7.39871 16.2129 7.39871 16.4911C7.39871 16.7605 7.58906 16.9294 7.8458 16.9294Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PhoneIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.77113 20.8384H14.5946C16.0403 20.8384 17.0468 19.8751 17.0468 18.4776V4.49531C17.0468 3.09776 16.0403 2.1344 14.5946 2.1344H7.77113C6.30224 2.1344 5.28711 3.09776 5.28711 4.49338V18.4795C5.28711 19.8751 6.30224 20.8384 7.77113 20.8384ZM8.08544 18.959C7.48947 18.959 7.11628 18.6181 7.11628 18.0446V4.92822C7.11628 4.3548 7.48947 4.01384 8.08544 4.01384H14.2416C14.8445 4.01384 15.2088 4.35866 15.2088 4.93015V18.0427C15.2088 18.6142 14.8445 18.959 14.2416 18.959H8.08544ZM9.33518 18.2846H13.0075C13.27 18.2846 13.4643 18.1031 13.4643 17.8278C13.4643 17.5526 13.27 17.3692 13.0075 17.3692H9.33518C9.06384 17.3692 8.87843 17.5526 8.87843 17.8278C8.87843 18.1031 9.06384 18.2846 9.33518 18.2846ZM10.0863 6.06151H12.2476C12.633 6.06151 12.9385 5.756 12.9385 5.36176C12.9385 4.97634 12.6311 4.6689 12.2476 4.6689H10.0863C9.69397 4.6689 9.38653 4.97634 9.38653 5.36176C9.38653 5.756 9.69204 6.06151 10.0863 6.06151Z"
        fill="currentColor"
      />
    </svg>
  );
}
