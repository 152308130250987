export function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59194 19.4066C5.00834 19.8134 5.71066 19.8082 6.10714 19.4117L12.0013 13.5176L17.892 19.41C18.2954 19.8134 18.9969 19.8195 19.4054 19.4048C19.814 18.9884 19.8157 18.3026 19.4123 17.8975L13.5216 11.9972L19.4123 6.10652C19.8157 5.70315 19.8219 5.00945 19.4054 4.6009C18.989 4.1845 18.2954 4.18277 17.892 4.594L12.0013 10.4847L6.10714 4.59228C5.71066 4.18967 5.00049 4.17491 4.59194 4.59918C4.18511 5.01559 4.19028 5.70832 4.58676 6.10479L10.4809 11.9972L4.58676 17.9009C4.19028 18.2957 4.17725 18.998 4.59194 19.4066Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9969 21.9938C17.4739 21.9938 21.9955 17.4659 21.9955 11.9969C21.9955 6.51997 17.4659 2 11.989 2C6.52171 2 2 6.51997 2 11.9969C2 17.4659 6.52965 21.9938 11.9969 21.9938ZM11.9969 20.0246C7.54215 20.0246 3.97718 16.4516 3.97718 11.9969C3.97718 7.54215 7.53421 3.96925 11.989 3.96925C16.4437 3.96925 20.0263 7.54215 20.0263 11.9969C20.0263 16.4516 16.4516 20.0246 11.9969 20.0246Z"
        fill="currentColor"
      />
      <path
        d="M10.305 17.3207H14.2003C14.6285 17.3207 14.9643 17.0094 14.9643 16.576C14.9643 16.1672 14.6285 15.8428 14.2003 15.8428H13.1222V11.2704C13.1222 10.699 12.8365 10.321 12.2977 10.321H10.4748C10.0448 10.321 9.71076 10.6472 9.71076 11.056C9.71076 11.4894 10.0448 11.8005 10.4748 11.8005H11.4528V15.8428H10.305C9.87684 15.8428 9.54102 16.1672 9.54102 16.576C9.54102 17.0094 9.87684 17.3207 10.305 17.3207ZM11.9359 8.82422C12.6668 8.82422 13.2428 8.23852 13.2428 7.51287C13.2428 6.77851 12.6668 6.19629 11.9359 6.19629C11.2182 6.19629 10.6308 6.77851 10.6308 7.51287C10.6308 8.23852 11.2182 8.82422 11.9359 8.82422Z"
        fill="currentColor"
      />
    </svg>
  );
}
