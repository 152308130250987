export function ArrowIcon() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22721 7.99961L3.86361 4.63601C3.51214 4.28453 3.51214 3.71469 3.86361 3.36321C4.21508 3.01174 4.78493 3.01174 5.1364 3.36321L9.1364 7.36321C9.48787 7.71469 9.48787 8.28453 9.1364 8.63601L5.1364 12.636C4.78493 12.9875 4.21508 12.9875 3.86361 12.636C3.51214 12.2845 3.51214 11.7147 3.86361 11.3632L7.22721 7.99961Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RemoveIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.82718 16.1722C4.17419 16.5112 4.75945 16.5069 5.08985 16.1765L10.0016 11.2647L14.9105 16.175C15.2467 16.5112 15.8313 16.5163 16.1718 16.1707C16.5122 15.8237 16.5137 15.2522 16.1775 14.9146L11.2686 9.99772L16.1775 5.08881C16.5137 4.75266 16.5188 4.17458 16.1718 3.83413C15.8248 3.48712 15.2467 3.48568 14.9105 3.82838L10.0016 8.73729L5.08985 3.82694C4.75945 3.49143 4.16764 3.47914 3.82718 3.83269C3.48816 4.1797 3.49247 4.75697 3.82287 5.08737L8.73465 9.99772L3.82287 14.9175C3.49247 15.2464 3.48161 15.8317 3.82718 16.1722Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.5C8.62826 17.5 8.97342 17.3174 9.21323 16.9516L17.1225 4.62165C17.2997 4.34431 17.3708 4.11224 17.3708 3.88387C17.3708 3.30445 16.9633 2.9046 16.3779 2.9046C15.9625 2.9046 15.7214 3.04428 15.4694 3.43976L8.15705 15.0568L4.38887 10.2117C4.14367 9.88698 3.88636 9.75098 3.52286 9.75098C2.92207 9.75098 2.5 10.1715 2.5 10.7524C2.5 11.002 2.59323 11.2541 2.80309 11.5091L7.16264 16.9635C7.45294 17.3266 7.76679 17.5 8.19071 17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
