import { Nullable } from '@/app/types';

export const MAX_EMAIL_LENGTH = 64;

export function validateEmail(value: string): boolean {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(value) && value.length <= MAX_EMAIL_LENGTH;
}

export function validateUsername(value: string): boolean {
  if (!value || value.length < 5) return false;

  const reg = /^([a-zA-Z0-9_]+)$/;
  return reg.test(value);
}

export const MAX_SITE_LEN = 64;
export function validateSiteUrl(value: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const reg = /^[A-Za-z0-9\/.:-]*\.[A-Za-z][A-Za-z0-9\/.-]*[A-Za-z0-9\/-]$/;
  return reg.test(value) && value.length <= MAX_SITE_LEN;
}

export function validateAnyPhoneNumber(value: string): boolean {
  const reg =
    /^(\+?\d{1,3}[\s-]?)?(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$|^.{5,15}$/;
  return reg.test(value);
}

export function validatePhoneNumber(value: string): boolean {
  const reg = /^[\\/+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
  return reg.test(value);
}

export function hasOnlyNumbers(value: string): boolean {
  const reg = /^\d+$/;
  return reg.test(value);
}

export function hasOnlyLatinWithNumbersAndSpecial(str: string): boolean {
  const reg = /^([a-zA-Z0-9 !@#$%^&*-_=,.]+)$/;
  return reg.test(str);
}

export function hasOnlyLatinWithNumber(str: string): boolean {
  const reg = /^([a-zA-Z0-9]+)$/;
  return reg.test(str);
}

export function hasOnlyLatinWithSpace(str: string): boolean {
  const reg = /^([a-zA-Z ]+)$/;
  return reg.test(str);
}

export function validImgSrc(
  src: Nullable<string | undefined>
): string | undefined {
  return ['http://', 'https://', '/img/'].some(
    (protocol) => src && src.startsWith(protocol)
  )
    ? src || undefined
    : undefined;
}
