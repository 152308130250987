import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { StoreRentTariff } from '@/modules/showroom/advert/rent/tariffs/types';
import { handleTariffs } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Tariffs/helpers';
import {
  defaultTariff,
  Tariff
} from '@/modules/showroom/advert/rent/update/AdditionalInfo/Tariffs/Tariff';
import { isTariffsValid } from '@/modules/showroom/advert/rent/update/helpers';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { Button } from '@/ui/Button/Button';

import { AddIcon } from './icons';
import cls from './Tariffs.module.scss';

const MAX_TARIFFS_LEN = 3;

type Props = {
  tariffs: RentUpdateAdvertStore['tariffs'];
  setTariffs: RentUpdateAdvertStore['setTariffs'];
  isTariffsFilled: RentUpdateAdvertStore['isTariffsFilled'];
  setTariffsFilled: RentUpdateAdvertStore['setTariffsFilled'];
};

export function Tariffs({
  tariffs,
  setTariffs,
  isTariffsFilled,
  setTariffsFilled
}: Props) {
  const { t } = useTranslation();
  const lastTf = tariffs[tariffs.length - 1];

  useEffect(() => {
    if (tariffs.length <= 0) {
      setTariffs([defaultTariff()]);
    }
  }, [setTariffs, tariffs.length]);

  const onTariffChange = (tf: StoreRentTariff) => {
    const index = tariffs.findIndex((trf) => trf.localId === tf.localId);
    const nextIndex = index + 1;
    const nextTf = tariffs[nextIndex] as StoreRentTariff | undefined;

    const handledTariffs = handleTariffs({
      tariffs,
      index,
      tf,
      nextTf,
      nextIndex
    });
    setTariffs(handledTariffs);
  };

  const onTariffDelete = (localId: string, index: number) => {
    const isLast = index === tariffs.length - 1;
    const filtered = tariffs.filter((v, i) => i < index);

    if (!isLast) {
      const last = filtered[filtered.length - 1];
      setTariffs([
        ...filtered,
        {
          localId: nanoid(),
          days_from: (last.days_up_to || last.days_from) + 1,
          days_up_to: null,
          price: 0
        }
      ]);
    } else {
      setTariffs([...filtered]);
    }
  };

  const addDisabled = lastTf && !lastTf.days_up_to;
  const onAddClick = () => {
    setTariffs([
      ...tariffs,
      {
        localId: nanoid(),
        price: 0,
        days_from: lastTf.days_up_to
          ? lastTf.days_up_to + 1
          : lastTf.days_from + 1,
        days_up_to: null
      }
    ]);
  };

  const isValid = isTariffsValid(tariffs);
  const onContinueClick = () => {
    setTariffsFilled(true);
    scrollToSection(SCROLLTO.deposit, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.tariffs}>
      <div className="box">
        <h1 className={cls.title}>{t('rentTariffs.title')}</h1>
        <p className={cls.subtitle}>{t('rentTariffs.subtitle')}</p>

        <ul className={cls.list}>
          {tariffs.map((tf, i) => (
            <li key={i}>
              <Tariff
                tariff={tf}
                index={i}
                onTariffChange={onTariffChange}
                onDeleteClick={onTariffDelete}
                showDelete={i !== 0}
                isLast={i === tariffs.length - 1}
                prevTariff={tariffs[i - 1]}
                nextTariff={tariffs[i + 1]}
              />
            </li>
          ))}
          {tariffs.length < MAX_TARIFFS_LEN && (
            <li>
              <Button
                onClick={onAddClick}
                gap={4}
                variant="secondary"
                color="black"
                size="compact"
                disabled={addDisabled}
              >
                <AddIcon />
                {t('rentTariffs.addPeriod')}
              </Button>
            </li>
          )}
        </ul>

        <div className={cls.buttons}>
          {!isTariffsFilled && (
            <Button
              onClick={onContinueClick}
              disabled={!isValid}
              color="blue"
              variant="primary"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
