import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { isBrowser, MODAL_ROOT_ID } from '@/utils/consts';

import cls from './CommonModal.module.scss';
import { scroll } from './scroll';

export interface CommonModalProps {
  name: string;
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
  rootChildren?: React.ReactNode;
  rootClass?: string;
  containerClass?: string;
  backdropClass?: string;
}

export const CommonModal: React.FC<CommonModalProps> = ({
  name,
  isOpen,
  close,
  children,
  rootChildren,
  rootClass,
  containerClass,
  backdropClass
}) => {
  const root = useMemo(
    () =>
      !isBrowser
        ? null
        : (document.querySelector(`#${MODAL_ROOT_ID}`) as Element),
    []
  );
  const [waitAnimation, setWaitAnimation] = useState<boolean>(isOpen);

  useEffect(() => {
    let timer = 0;

    if (!isOpen) {
      timer = window.setTimeout(() => {
        setWaitAnimation(false);
      }, 300);
    } else {
      timer = window.setTimeout(() => {
        setWaitAnimation(true);
      }, 0);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      scroll.disable(name);
    } else {
      scroll.enable(name);
    }

    return () => {
      scroll.enable(name);
    };
  }, [isOpen, name]);

  return root && (isOpen || waitAnimation)
    ? createPortal(
        <section
          className={cn(cls.root, rootClass)}
          data-open={isOpen ? waitAnimation : isOpen}
        >
          <button
            className={cn(cls.backdrop, backdropClass)}
            type="button"
            onClick={close}
          />
          <div className={cn(cls.container, containerClass, 'hide-scrollbar')}>
            {children}
          </div>
          {rootChildren}
        </section>,
        root
      )
    : null;
};

CommonModal.displayName = 'CommonModal';
