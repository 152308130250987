import { useContext, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

import { useAccountStore } from '@/modules/accounts/store';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import {
  MyAdvertsList,
  MyAdvertsListSkeleton
} from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertsList';
import { useSearchAdvertsPg } from '@/modules/showroom/advert/search/hooks';
import { SearchAdvertsReq } from '@/modules/showroom/advert/search/types';
import {
  AdvertExistedStatus,
  AdvertStatusType
} from '@/modules/showroom/advert/types';

import cls from './SearchResult.module.scss';
import { SearchResultEmpty } from './SearchResultEmpty';

type Props = {
  req: SearchAdvertsReq;
  categoryId: number;
  onResetClick: () => void;
  selectedParamsCount: number;
  search?: string;
};

export function SearchResult({
  req,
  categoryId,
  onResetClick,
  selectedParamsCount,
  search
}: Props) {
  const {
    status: _status,
    refresh,
    setNotFoundAds
  } = useContext(MyAdvertsContext);
  const status = _status as AdvertExistedStatus | null;

  const selectedParamsCountWithSearch = selectedParamsCount + (search ? 1 : 0);

  const { isAllLoaded, isLoading, isLoadingRef, ads, load } =
    useSearchAdvertsPg(req, status, search);

  const noAds = !isLoading && !!ads && ads.length <= 0;

  useEffect(() => {
    setNotFoundAds(noAds);
  }, [noAds, setNotFoundAds]);

  useEffect(() => {
    if (!isLoadingRef.current) load(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [req]);

  useEffect(() => {
    if (status !== undefined && !isLoadingRef.current) {
      load(0);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status?.title]);

  const fetchPaginatedData = () => {
    if (isAllLoaded || isLoading || isLoadingRef.current) return;
    load(ads ? ads.length : 0);
  };

  const fetchBalance = useAccountStore((s) => s.fetchBalance);
  const onPublishToggle = () => {
    refresh();
    window.setTimeout(fetchBalance, 500);
  };

  return (
    <div className={cls.root}>
      {ads && ads.length > 0 && (
        <MyAdvertsList list={ads} onPublishToggle={onPublishToggle} />
      )}

      {isLoading && <MyAdvertsListSkeleton />}

      {noAds && (
        <SearchResultEmpty
          onResetClick={onResetClick}
          categoryId={categoryId}
          noPublished={
            status?.status === AdvertStatusType.published &&
            ads &&
            ads.length <= 0 &&
            selectedParamsCountWithSearch <= 0
          }
        />
      )}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchPaginatedData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </div>
  );
}
