export function CheckIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19071 17.4997C8.62826 17.4997 8.97342 17.3171 9.21323 16.9513L17.1225 4.62134C17.2997 4.344 17.3708 4.11193 17.3708 3.88357C17.3708 3.30415 16.9633 2.9043 16.3779 2.9043C15.9625 2.9043 15.7214 3.04398 15.4694 3.43946L8.15705 15.0565L4.38887 10.2114C4.14367 9.88667 3.88636 9.75067 3.52286 9.75067C2.92207 9.75067 2.5 10.1712 2.5 10.7521C2.5 11.0017 2.59323 11.2538 2.80309 11.5088L7.16264 16.9632C7.45294 17.3263 7.76679 17.4997 8.19071 17.4997Z"
        fill="currentColor"
      />
    </svg>
  );
}
