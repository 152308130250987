export function CloseIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66437 8.49355C6.18808 8.01726 6.1654 7.16674 6.67571 6.67911C7.16333 6.16881 8.02519 6.19149 8.50148 6.66777L14.0015 12.1678L19.5015 6.66777C19.9891 6.18015 20.8283 6.16881 21.3159 6.67911C21.8262 7.16674 21.8149 8.01726 21.3273 8.50489L15.8273 14.0049L21.3273 19.4935C21.8149 19.9925 21.8262 20.8317 21.3159 21.3193C20.8283 21.8296 19.9891 21.8183 19.5015 21.3307L14.0015 15.8307L8.50148 21.3307C8.02519 21.807 7.16333 21.8296 6.67571 21.3193C6.1654 20.8317 6.18808 19.9812 6.66437 19.5049L12.1644 14.0049L6.66437 8.49355Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function MyTailIcon(): JSX.Element {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.44467 11.925C6.66676 10.2886 6.39401 5.52278 6.5 0C2.97541 0 0.118164 2.85725 0.118164 6.38184V13.287C3.2978 16.0534 7.36515 17.3795 12.3202 16.9056C12.3835 16.8996 12.44 16.8642 12.4723 16.8105C12.5283 16.7174 12.4971 16.5973 12.4024 16.5422L12.0447 16.3299C9.7002 14.9125 8.16686 13.4441 7.44467 11.925Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function QuickReplyIcon(): JSX.Element {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9977 58.6673C46.6062 58.6673 58.6667 46.5892 58.6667 32.0007C58.6667 17.391 46.585 5.33398 31.9766 5.33398C17.394 5.33398 5.33337 17.391 5.33337 32.0007C5.33337 46.5892 17.4151 58.6673 31.9977 58.6673ZM31.9977 53.4144C20.1157 53.4144 10.607 43.8836 10.607 32.0007C10.607 20.1176 20.0946 10.587 31.9766 10.587C43.8585 10.587 53.4142 20.1176 53.4142 32.0007C53.4142 43.8836 43.8796 53.4144 31.9977 53.4144Z"
        fill="currentColor"
      />
      <path
        d="M20.8097 33.7249C20.8097 34.412 21.3631 34.9139 22.0761 34.9139H30.5621L26.0338 47.0049C25.3864 48.7362 27.2347 49.7049 28.384 48.2974L42.3021 30.973C42.5953 30.6538 42.7501 30.2738 42.7501 29.9196C42.7501 29.2325 42.1968 28.726 41.4838 28.726H32.9979L37.5261 16.6348C38.1735 14.9083 36.3251 13.9396 35.176 15.3213L21.2578 32.6503C20.9646 32.9907 20.8097 33.3661 20.8097 33.7249Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RemoveIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05164 14.7576C8.33928 14.7576 8.52467 14.5754 8.51784 14.307L8.3087 7.1508C8.30065 6.88487 8.1128 6.70822 7.83758 6.70822C7.55241 6.70822 7.36825 6.89047 7.37507 7.15763L7.57862 14.3106C7.58667 14.5822 7.77574 14.7576 8.05164 14.7576ZM10.0623 14.7576C10.345 14.7576 10.544 14.5778 10.544 14.3119V7.15517C10.544 6.88924 10.345 6.70822 10.0623 6.70822C9.77831 6.70822 9.58488 6.88924 9.58488 7.15517V14.3119C9.58488 14.5778 9.77831 14.7576 10.0623 14.7576ZM12.0772 14.7576C12.3487 14.7576 12.5366 14.5822 12.5446 14.3106L12.7482 7.15763C12.755 6.89047 12.5708 6.70822 12.2857 6.70822C12.0104 6.70822 11.8226 6.88487 11.8146 7.1564L11.611 14.307C11.6042 14.5754 11.7896 14.7576 12.0772 14.7576ZM6.9613 4.80629H8.22106V3.38857C8.22106 3.03513 8.46829 2.80837 8.84835 2.80837H11.2637C11.6438 2.80837 11.891 3.03513 11.891 3.38857V4.80629H13.1508V3.32523C13.1508 2.28143 12.4846 1.66602 11.3535 1.66602H8.75852C7.62749 1.66602 6.9613 2.28143 6.9613 3.32523V4.80629ZM3.93733 5.54892H16.1915C16.5279 5.54892 16.79 5.28176 16.79 4.94539C16.79 4.61148 16.5279 4.34992 16.1915 4.34992H3.93733C3.60656 4.34992 3.33325 4.61148 3.33325 4.94539C3.33325 5.28736 3.60656 5.54892 3.93733 5.54892ZM6.92744 17.0686H13.2014C14.2463 17.0686 14.9249 16.4288 14.9739 15.3827L15.4432 5.41801H14.1771L13.7284 15.1794C13.7147 15.589 13.4445 15.8609 13.0553 15.8609H7.06231C6.6843 15.8609 6.41413 15.5822 6.39488 15.1794L5.92377 5.41801H4.68011L5.15492 15.3883C5.20516 16.4344 5.87135 17.0686 6.92744 17.0686Z"
        fill="currentColor"
      />
    </svg>
  );
}
