import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { useLang } from '@/hooks/useLang';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { EmojiType } from '@/modules/emojis/types';
import { OfferModal } from '@/modules/offers/OfferModal/OfferModal';
import { PromotionPackage } from '@/modules/promotion/types';
import {
  MAX_STICKERS_LEN,
  NO_PROMO_ID
} from '@/modules/showroom/advert/create/helpers';
import { useWeekDaysForScheduleRefresh } from '@/modules/showroom/advert/my/hooks';
import { PromoModal } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/PromoModal/PromoModal';
import { RefreshModal } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/RefreshModal/RefreshModal';
import { StickersModal } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/StickersModal/StickersModal';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { dateDistanceToNowArray } from '@/utils/date';
import { numberWithSpaces, pluralKey } from '@/utils/format';

import offerIcon from './offer-icon.svg';
import promoIcon from './promo-icon.svg';
import cls from './Promotions.module.scss';
import refreshIcon from './refresh-icon.svg';
import stickersIcon from './stickers-icon.svg';

type Props = {
  advert: MyAdvert;
  promotionsList: PromotionPackage[];
  stickersList: EmojiType[];
  hovered: boolean;
  onPublishToggle?: () => void;
};

export function Promotions({
  advert,
  promotionsList,
  stickersList,
  hovered,
  onPublishToggle
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const isVrp = advert.category.id === VRP_CATEGORY_ID;

  // Packages
  const adPromo =
    advert.promotion_package?.id !== NO_PROMO_ID
      ? advert.promotion_package
      : null;

  const cheapestPackagePrice = useMemo(() => {
    let min = 0;
    promotionsList.forEach((p, i) => {
      min = i === 0 ? p.price : Math.min(min, p.price);
    });

    return min;
  }, [promotionsList]);

  const leftTime = useMemo(() => {
    if (!adPromo) return '';

    const leftTimeStr = dateDistanceToNowArray(
      new Date(adPromo.end_date * 1000),
      lang
    ).join(t('common.spaceSeparator') || ' ');

    const hasAutoext = !!adPromo.promotion_package_automatic_extension;

    return leftTimeStr
      ? t(
          hasAutoext
            ? 'advertisements.autoextAfter'
            : 'advertisements.promoLeft',
          {
            date: leftTimeStr
          }
        )
      : '';
  }, [adPromo, lang, t]);

  const [isPromoOpen, setPromoOpen] = useState(false);

  // Stickers
  const stickerPrice = stickersList[0].price;
  const adStickers = advert.stickers ? advert.stickers : [];
  const hasStickers = adStickers.length > 0;
  const stickersFirst = hasStickers && !adPromo;

  const [isStickersOpen, setStickersOpen] = useState(false);

  // Refresh
  const [isRefreshOpen, setRefreshOpen] = useState(false);
  const refreshPrice = useCommonStore((s) => s.refreshPrice);
  const { refreshScheduleIsSetted, scheduleWeekDaysString, scheduleHour } =
    useWeekDaysForScheduleRefresh(
      advert.current_week_days_for_schedule_refresh
    );
  const handleRefreshClose = () => {
    setRefreshOpen(false);
    onPublishToggle?.();
  };

  // Offer
  const [isOfferOpen, setOfferOpen] = useState(false);
  const offerOpenedOnce = useOnceTrue(isOfferOpen);

  const countAddToFavorites = advert.engagement.count_add_to_favorites;
  const canMakeOffer = !!countAddToFavorites;

  return (
    <>
      <ul
        className={cn(cls.root, {
          [cls.root_reverse]: stickersFirst,
          [cls.root_hovered]: hovered
        })}
      >
        <li className={cn(cls.li, { [cls.li_visible]: adPromo })}>
          <button
            className={cn(cls.btn, { [cls.btn_green]: adPromo })}
            type="button"
            onClick={() => setPromoOpen(true)}
          >
            <img src={adPromo?.logo_url || promoIcon} alt="" />
            <div>
              <b className={cls.title}>
                {adPromo?.name || t('advertisements.promo.title')}
              </b>
              {adPromo ? (
                <p className={cls.subtitle}>{leftTime}</p>
              ) : (
                <p className={cls.subtitle}>
                  {t('common.fromValue', {
                    value: t('priceCurrency', {
                      price: numberWithSpaces(cheapestPackagePrice, lang),
                      currency: CURRENCY_DISPLAY.aed
                    })
                  })}
                </p>
              )}
            </div>
          </button>
        </li>
        <li
          className={cn(cls.li, { [cls.li_visible]: refreshScheduleIsSetted })}
        >
          <button
            className={cn(cls.btn, {
              [cls.btn_purple]: refreshScheduleIsSetted
            })}
            type="button"
            onClick={() => setRefreshOpen(true)}
          >
            <img src={refreshIcon} alt="" />
            <div>
              <b className={cls.title}>{t('advertisements.refresh')}</b>
              {refreshScheduleIsSetted ? (
                <p className={cls.subtitle}>
                  {t('common.dotSeparated', {
                    first: scheduleWeekDaysString,
                    second: t('common.hoursAfterMidnight', {
                      hour: scheduleHour
                    })
                  })}
                </p>
              ) : (
                <p className={cls.subtitle}>
                  {t('priceCurrency', {
                    price: numberWithSpaces(refreshPrice, lang),
                    currency: CURRENCY_DISPLAY.aed
                  })}
                </p>
              )}
            </div>
          </button>
        </li>
        {!isVrp && (
          <li className={cn(cls.li, { [cls.li_visible]: hasStickers })}>
            <button
              className={cn(cls.btn, { [cls.btn_blue]: hasStickers })}
              type="button"
              onClick={() => setStickersOpen(true)}
            >
              <img src={stickersIcon} alt="" />
              <div>
                <b className={cls.title}>{t('stickers.multiple')}</b>
                {hasStickers ? (
                  <p className={cls.subtitle}>
                    {t('counter', {
                      index: adStickers.length,
                      count: MAX_STICKERS_LEN
                    })}
                  </p>
                ) : (
                  <p className={cls.subtitle}>
                    {t('priceCurrency', {
                      price: numberWithSpaces(stickerPrice, lang),
                      currency: CURRENCY_DISPLAY.aed
                    })}
                  </p>
                )}
              </div>
            </button>
          </li>
        )}
        {canMakeOffer && (
          <li className={cls.li}>
            <button
              className={cls.btn}
              type="button"
              onClick={() => setOfferOpen(true)}
            >
              <img src={offerIcon} alt="" />
              <div>
                <b className={cls.title}>{t('offer.title')}</b>
                <p className={cls.subtitle}>
                  {pluralKey(
                    countAddToFavorites,
                    t('offer.buyersPlural.one', {
                      buyersCount: countAddToFavorites
                    }),
                    t('offer.buyersPlural.few', {
                      buyersCount: countAddToFavorites
                    }),
                    t('offer.buyersPlural.many', {
                      buyersCount: countAddToFavorites
                    })
                  )}
                </p>
              </div>
            </button>
          </li>
        )}
      </ul>

      <PromoModal
        isOpen={isPromoOpen}
        close={() => setPromoOpen(false)}
        advert={advert}
        onPublishToggle={onPublishToggle}
        promotionsList={promotionsList}
      />

      <StickersModal
        isOpen={isStickersOpen}
        close={() => setStickersOpen(false)}
        advert={advert}
        onPublishToggle={onPublishToggle}
        stickersList={stickersList}
      />

      <RefreshModal
        isOpen={isRefreshOpen}
        close={handleRefreshClose}
        advert={advert}
      />

      {offerOpenedOnce && (
        <OfferModal
          isOpen={isOfferOpen}
          close={() => setOfferOpen(false)}
          advert={advert}
        />
      )}
    </>
  );
}
