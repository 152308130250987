import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import {
  formatDescriptionWithAi,
  generateDescriptionWithAi
} from '@/modules/ai/api';
import { GenerateDescriptionReq } from '@/modules/ai/types';
import {
  MAX_DESCR_LEN,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { Spinner } from '@/ui/Spinner/Spinner';
import { Textarea } from '@/ui/Textarea/Textarea';
import { maxLength } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './Description.module.scss';
import { SparklesIcon, ArrowIcon, BackIcon } from './icons';

const MAX_GENERATION_COUNT = 5;
const HIDDEN_MAX_DESCR_LEN = MAX_DESCR_LEN + 1000;

type Props = {
  nextSection: string;
  value: string;
  onChange: (value: string) => void;
  generating: boolean;
  setGenerating: (v: boolean) => void;
  isDescriptionFilled: CreateAdvertSlice['isDescriptionFilled'];
  setDescriptionFilled: CreateAdvertSlice['setDescriptionFilled'];
  updateAdvert?: () => Promise<void>;
  placeholder?: Nullable<string>;
  advertId?: Nullable<number>;
  rent?: boolean;
};

export function Description({
  nextSection,
  value,
  onChange,
  generating,
  setGenerating,
  isDescriptionFilled,
  setDescriptionFilled,
  updateAdvert,
  placeholder,
  advertId,
  rent
}: Props) {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [variants, setVariants] = useState<string[]>([value]);

  const onTextareaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const v = e.currentTarget.value;
    onChange(maxLength(v, HIDDEN_MAX_DESCR_LEN));
    setVariants((prev) => prev.map((item, i) => (i === index ? v : item)));
  };

  // Prev / Next
  const onPrevClick = () => {
    if (index > 0) {
      const newIndex = index - 1;
      setIndex(newIndex);
      onChange(variants[newIndex]);
    }
  };

  const onNextClick = () => {
    if (index < variants.length - 1) {
      const newIndex = index + 1;
      setIndex(newIndex);
      onChange(variants[newIndex]);
    }
  };

  // Generate
  const [genCount, setGenCount] = useState(0);
  const isGeneratedMax = genCount >= MAX_GENERATION_COUNT;
  const onGenerationClick = async () => {
    if (!advertId || generating || isGeneratedMax) return;

    setGenerating(true);

    try {
      const isFirstGeneration = !genCount;
      if (isFirstGeneration && updateAdvert) {
        await updateAdvert();
      }

      const req: GenerateDescriptionReq = rent
        ? { rent_id: advertId }
        : { advertisement_id: advertId };
      const r = await generateDescriptionWithAi(req);
      setGenCount((p) => p + 1);
      const text = maxLength(r.data.generated, MAX_DESCR_LEN);

      if (variants.length < MAX_GENERATION_COUNT) {
        const newIndex = variants.length;
        setVariants((p) => (isFirstGeneration ? [text] : [...p, text]));
        setIndex(newIndex);
        onChange(text);
      }
    } catch (error) {
      showAlert({ error });
    } finally {
      setGenerating(false);
    }
  };

  // Format
  const showFormat = !!value && genCount < 1;
  const [beforeFormatText, setBeforeFormatText] = useState('');
  const [formatDone, setFormatDone] = useState(false);
  const [formatting, setFormatting] = useState(false);
  const onFormatClick = async () => {
    if (!value || formatDone) return;

    setFormatting(true);
    setBeforeFormatText(value);

    try {
      const r = await formatDescriptionWithAi(value);
      setFormatDone(true);
      const text = maxLength(r.data.generated, MAX_DESCR_LEN);
      setVariants((p) => p.map((v, i) => (i === index ? text : v)));
      onChange(text);
    } catch (error) {
      showAlert({ error });
    } finally {
      setFormatting(false);
    }
  };

  const onBackClick = () => {
    setVariants((p) => p.map((v, i) => (i === index ? beforeFormatText : v)));
    onChange(beforeFormatText);

    setBeforeFormatText('');
    setFormatDone(false);
  };

  const isValid = value.length <= MAX_DESCR_LEN;
  const onContinueClick = () => {
    setDescriptionFilled(true);
    scrollToSection(nextSection, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.descr}>
      <div className="box">
        <h1 className={cls.title}>{t('advertisements.description.title')}</h1>
        <p className={cls.desc}>{t('advertisements.description.text')}</p>

        <div className={cls.textarea}>
          <Textarea
            value={value}
            onChange={onTextareaChange}
            placeholder={placeholder}
            minRows={4}
            disabled={generating || formatting}
          />

          {advertId && (
            <div className={cls.buttons}>
              {!showFormat && (
                <div className={cls.row}>
                  <Button
                    color="black"
                    size="compact"
                    onClick={onGenerationClick}
                    disabled={formatting || generating || isGeneratedMax}
                    gap={8}
                  >
                    {formatting || generating ? (
                      <Spinner size={18} color="var(--thm-grey-3)" />
                    ) : (
                      <SparklesIcon />
                    )}
                    {t(
                      generating
                        ? 'advertisements.description.ai.generating'
                        : genCount > 0
                        ? 'advertisements.description.ai.createNew'
                        : 'advertisements.description.ai.generate'
                    )}
                  </Button>

                  {variants.length > 1 && (
                    <>
                      <div className={cls.prev_btn}>
                        <Button
                          variant="secondary"
                          color="black"
                          size="compact"
                          disabled={formatting || generating || index <= 0}
                          onClick={onPrevClick}
                        >
                          <ArrowIcon />
                        </Button>
                      </div>
                      <div className={cls.next_btn}>
                        <Button
                          variant="secondary"
                          color="black"
                          size="compact"
                          disabled={
                            formatting ||
                            generating ||
                            index >= variants.length - 1
                          }
                          onClick={onNextClick}
                        >
                          <ArrowIcon />
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}

              {showFormat && (
                <>
                  {formatDone && beforeFormatText ? (
                    <Button
                      onClick={onBackClick}
                      color="black"
                      size="compact"
                      disabled={formatting || generating}
                      gap={8}
                    >
                      {formatting || generating ? (
                        <Spinner size={18} color="var(--thm-grey-3)" />
                      ) : (
                        <BackIcon />
                      )}
                      {t('advertisements.description.ai.back')}
                    </Button>
                  ) : (
                    <Button
                      onClick={onFormatClick}
                      color="black"
                      size="compact"
                      disabled={
                        formatting || generating || !value || formatDone
                      }
                      gap={8}
                    >
                      {formatting || generating ? (
                        <Spinner size={18} color="var(--thm-grey-3)" />
                      ) : (
                        <SparklesIcon />
                      )}
                      {t(
                        formatting
                          ? 'advertisements.description.ai.formatting'
                          : 'advertisements.description.ai.format'
                      )}
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <span
          className={cn(cls.counter, {
            [cls.counter_red]: !isValid
          })}
        >
          {t('common.slashSeparated', {
            first: value.length,
            second: MAX_DESCR_LEN
          })}
        </span>

        {!isDescriptionFilled && (
          <Button
            onClick={onContinueClick}
            color={value ? 'blue' : 'black'}
            variant={value ? 'primary' : 'secondary'}
            disabled={generating || !isValid}
            fullWidth
          >
            {t(value ? 'common.continue' : 'common.skip')}
          </Button>
        )}
      </div>
    </div>
  );
}
