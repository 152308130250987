import { axios } from '@/app/api';
import {
  GetOfferPreviewMessageRes,
  GetOfferSendAvailabilityReq,
  GetOfferSendAvailabilityRes,
  PromoOfferParams,
  PromoOfferReq,
  SendPromoOffersReq
} from '@/modules/offers/types';

export function getOfferPreviewMessage(req: PromoOfferReq) {
  return axios.post<GetOfferPreviewMessageRes>(
    `/offers/discounts/message/preview`,
    req
  );
}

export function getOfferSendAvailability(params: GetOfferSendAvailabilityReq) {
  return axios.get<GetOfferSendAvailabilityRes>(
    `/offers/discounts/send/check-available`,
    { params }
  );
}

export function sendOffers(params: PromoOfferParams, req: SendPromoOffersReq) {
  return axios.post<unknown>(`/offers/send`, req, { params });
}
