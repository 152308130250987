import { useTranslation } from 'react-i18next';

import { Switch } from '@/ui/Switch/Switch';

import cls from './CallbackStatus.module.scss';

interface Props {
  value: boolean;
  disabled: boolean;
  onSwitch: () => void;
}

export function CallbackStatus({ value, disabled, onSwitch }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cls.root}>
      <p className={cls.title}>{t('callback.status.title')}</p>
      <p className={cls.text}>{t('callback.status.text')}</p>
      <div className={cls.switch}>
        <Switch
          id="callback-status"
          checked={value}
          disabled={disabled}
          green
        />
      </div>
      <button
        className={cls.btn}
        type="button"
        onClick={onSwitch}
        disabled={disabled}
      />
    </div>
  );
}
