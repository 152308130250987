import { RawAxiosRequestHeaders } from 'axios';

import { axios } from '@/app/api';
import {
  GetCountryCodesReq,
  GetCountryCodesRes
} from '@/modules/country-codes/types';

export function getCountryCodes(
  params?: GetCountryCodesReq,
  headers?: RawAxiosRequestHeaders
) {
  return axios.get<GetCountryCodesRes>('/country-codes/list', {
    params,
    headers
  });
}
