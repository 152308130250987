import { ExpensesDetailStatisticsRes } from '@/modules/wallet/types';

export function mapDays(from: Date, to: Date) {
  const days = [];
  const timeZoneHoursOffset = Math.abs(new Date().getTimezoneOffset() / 60);

  const currentDate = new Date(from);
  currentDate.setHours(timeZoneHoursOffset, 0);
  while (currentDate <= to) {
    days.push(new Date(currentDate).getTime());
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return days;
}

export function mapGraphData(
  data: ExpensesDetailStatisticsRes,
  from: Date,
  to: Date
) {
  const days = mapDays(from, to);
  const dataByCategories: Record<string, { data: number[]; name: string }> = {};

  data.categories.forEach((category) => {
    dataByCategories[category.id.toString()] = {
      data: new Array(days.length).fill(0),
      name: category.name
    };
  });

  data.expenses.forEach((day) => {
    const createdAt = day.created_at * 1000;

    const dayIndex = days.findIndex(
      (dayTime, index) =>
        days[index + 1] && createdAt < days[index + 1] && createdAt > dayTime
    );
    const filteredDayIndex = dayIndex !== -1 ? dayIndex : days.length - 1;

    dataByCategories[day.category_id.toString()].data[filteredDayIndex] +=
      day.amount;
  });

  return { mappedData: Object.values(dataByCategories), mappedDates: days };
}
