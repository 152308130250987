export function CloseIcon() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 23.1411C5.84372 23.6157 6.66308 23.6096 7.12564 23.1471L14.0021 16.2706L20.8746 23.1451C21.3452 23.6157 22.1637 23.6229 22.6403 23.1391C23.117 22.6533 23.119 21.8532 22.6484 21.3805L15.7759 14.4968L22.6484 7.62437C23.119 7.15377 23.1262 6.34446 22.6403 5.86782C22.1545 5.38201 21.3452 5.38 20.8746 5.85977L14.0021 12.7322L7.12564 5.85776C6.66308 5.38804 5.83455 5.37083 5.35791 5.86581C4.88328 6.35161 4.88932 7.1598 5.35187 7.62236L12.2284 14.4968L5.35187 21.3845C4.88932 21.845 4.87411 22.6644 5.35791 23.1411Z"
        fill="currentColor"
      />
    </svg>
  );
}
