import qs from 'qs';

import { axios } from '@/app/api';
import {
  MarkCreditRequestsAsViewedReq,
  UpdateCreditFormReq
} from '@/modules/credit/types';

const PATH = '/dealers/credits';

export const CREDITS_API_ROUTES = {
  getForm: `${PATH}/cform`,
  updateForm: `${PATH}/cform`,
  markCreditRequestsAsViewed: `${PATH}/mark-viewed`,
  sendCode: `${PATH}/verification/phone/send-code`,
  confirmCode: `${PATH}/verification/phone/confirm`,
  checkPhone: `${PATH}/verification/phone/check`,
  getRequests: PATH,
  request: (accId: number) => `${PATH}/${accId}`
};

export function updateCreditForm(req: UpdateCreditFormReq) {
  return axios.patch<unknown>(CREDITS_API_ROUTES.updateForm, req);
}

export function markCreditRequestsAsViewed(
  params: MarkCreditRequestsAsViewedReq
) {
  return axios.patch<unknown>(
    CREDITS_API_ROUTES.markCreditRequestsAsViewed,
    null,
    {
      params,
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        }
      }
    }
  );
}
