export function SearchIcon(): JSX.Element {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4163 4.08301C18.0877 4.08301 21.8747 7.86993 21.8747 12.5413C21.8747 14.4561 21.2384 16.2222 20.1658 17.6399L25.033 22.508C25.4886 22.9637 25.4886 23.7024 25.033 24.158C24.5774 24.6136 23.8387 24.6136 23.383 24.158L18.5149 19.2908C17.0972 20.3634 15.3311 20.9997 13.4163 20.9997C8.74493 20.9997 4.95801 17.2127 4.95801 12.5413C4.95801 7.86993 8.74493 4.08301 13.4163 4.08301ZM13.4163 6.41634C10.0336 6.41634 7.29134 9.1586 7.29134 12.5413C7.29134 15.9241 10.0336 18.6663 13.4163 18.6663C16.7991 18.6663 19.5413 15.9241 19.5413 12.5413C19.5413 9.1586 16.7991 6.41634 13.4163 6.41634Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ProfileIcon(): JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.35 7.9498C15.35 6.37499 14.0748 5.0998 12.5 5.0998C10.9252 5.0998 9.64998 6.37499 9.64998 7.9498C9.64998 9.52462 10.9252 10.7998 12.5 10.7998C14.0748 10.7998 15.35 9.52462 15.35 7.9498ZM17.15 7.9498C17.15 10.5187 15.0689 12.5998 12.5 12.5998C9.93104 12.5998 7.84998 10.5187 7.84998 7.9498C7.84998 5.38087 9.93104 3.2998 12.5 3.2998C15.0689 3.2998 17.15 5.38087 17.15 7.9498ZM6.39998 18.4284C6.39998 19.1972 6.30921 19.0998 6.73527 19.0998H18.2647C18.6907 19.0998 18.6 19.1972 18.6 18.4284C18.6 16.5358 15.8218 15.3998 12.5 15.3998C9.17817 15.3998 6.39998 16.5358 6.39998 18.4284ZM4.59998 18.4284C4.59998 15.1016 8.27276 13.5998 12.5 13.5998C16.7272 13.5998 20.4 15.1016 20.4 18.4284C20.4 20.164 19.7144 20.8998 18.2647 20.8998H6.73527C5.28551 20.8998 4.59998 20.164 4.59998 18.4284Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ReviewIcon(): JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0543 4.69403C12.8499 4.19991 12.15 4.19991 11.9455 4.69403L10.3316 8.59447C10.2004 8.91152 9.9003 9.12639 9.55788 9.14848L5.43266 9.41462C4.89861 9.44908 4.67344 10.1125 5.07619 10.4649L8.34259 13.323C8.59468 13.5436 8.70341 13.8859 8.62481 14.2115L7.69505 18.0634C7.57133 18.5759 8.12143 18.9874 8.57813 18.7239L12.0502 16.7208C12.3285 16.5602 12.6714 16.5602 12.9497 16.7208L16.4217 18.7239C16.8785 18.9874 17.4285 18.5759 17.3048 18.0634L16.3751 14.2115C16.2965 13.8859 16.4052 13.5436 16.6573 13.323L19.9237 10.4649C20.3264 10.1125 20.1013 9.44908 19.5672 9.41462L15.442 9.14848C15.0996 9.12639 14.7995 8.91152 14.6683 8.59447L13.0543 4.69403ZM10.2823 4.00579C11.1002 2.02932 13.8997 2.02932 14.7176 4.00579L16.1172 7.3883L19.6831 7.61836C21.8193 7.75618 22.72 10.4099 21.109 11.8196L18.2527 14.3189L19.0546 17.641C19.5494 19.6912 17.3491 21.337 15.5222 20.283L12.4999 18.5394L9.47763 20.283C7.6508 21.337 5.45043 19.6912 5.9453 17.641L6.74721 14.3189L3.89088 11.8196C2.27988 10.4099 3.18056 7.75618 5.31677 7.61836L8.88264 7.3883L10.2823 4.00579Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function MoreIcon(): JSX.Element {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1253 11.667C22.4087 11.667 23.4587 12.717 23.4587 14.0003C23.4587 15.2837 22.4087 16.3337 21.1253 16.3337C19.842 16.3337 18.792 15.2837 18.792 14.0003C18.792 12.717 19.842 11.667 21.1253 11.667ZM14.1253 16.3337C12.842 16.3337 11.792 15.2837 11.792 14.0003C11.792 12.717 12.842 11.667 14.1253 11.667C15.4087 11.667 16.4587 12.717 16.4587 14.0003C16.4587 15.2837 15.4087 16.3337 14.1253 16.3337ZM7.12533 16.3337C5.84199 16.3337 4.79199 15.2837 4.79199 14.0003C4.79199 12.717 5.84199 11.667 7.12533 11.667C8.40866 11.667 9.45866 12.717 9.45866 14.0003C9.45866 15.2837 8.40866 16.3337 7.12533 16.3337Z"
        fill="currentColor"
      />
    </svg>
  );
}
