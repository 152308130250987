export function CheckboxIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.13641 21.0343H17.8639C19.9816 21.0343 21.0218 19.994 21.0218 17.9135V6.09925C21.0218 4.01876 19.9816 2.97852 17.8639 2.97852H6.13641C4.03115 2.97852 2.97852 4.01876 2.97852 6.09925V17.9135C2.97852 19.994 4.03115 21.0343 6.13641 21.0343Z"
        fill="#007AFF"
      />
      <path
        d="M10.9038 16.761C10.5199 16.761 10.2103 16.6125 9.91307 16.2162L7.60967 13.3307C7.44868 13.1202 7.34961 12.8725 7.34961 12.6248C7.34961 12.1171 7.73351 11.7084 8.24125 11.7084C8.55085 11.7084 8.79852 11.8075 9.07096 12.179L10.8542 14.5567L14.8047 8.16661C15.0152 7.81986 15.3001 7.64648 15.5973 7.64648C16.0802 7.64648 16.5384 7.96847 16.5384 8.50097C16.5384 8.74865 16.3898 9.00871 16.2536 9.23162L11.8326 16.2162C11.6097 16.5876 11.2877 16.761 10.9038 16.761Z"
        fill="white"
      />
    </svg>
  );
}

export function CheckboxRoundedIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9938 21.9876C17.4551 21.9876 22 17.4551 22 11.9938C22 6.5325 17.4427 2 11.9814 2C6.5325 2 2 6.5325 2 11.9938C2 17.4551 6.54489 21.9876 11.9938 21.9876Z"
        fill="#007AFF"
      />
      <path
        d="M10.9038 16.7621C10.5199 16.7621 10.2103 16.6134 9.91307 16.2171L7.60967 13.3317C7.4363 13.1211 7.34961 12.8735 7.34961 12.6258C7.34961 12.118 7.72112 11.7094 8.24125 11.7094C8.55085 11.7094 8.79852 11.8084 9.07098 12.18L10.8543 14.5577L14.8047 8.16759C15.0029 7.82083 15.2877 7.64746 15.5849 7.64746C16.0802 7.64746 16.5261 7.96944 16.5261 8.50195C16.5261 8.74963 16.3899 9.00969 16.2413 9.2326L11.8326 16.2171C11.5973 16.5886 11.2753 16.7621 10.9038 16.7621Z"
        fill="white"
      />
    </svg>
  );
}

export function UncheckIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.13641 21.0343H17.8639C19.9816 21.0343 21.0218 19.994 21.0218 17.9135V6.09925C21.0218 4.01876 19.9816 2.97852 17.8639 2.97852H6.13641C4.03115 2.97852 2.97852 4.01876 2.97852 6.09925V17.9135C2.97852 19.994 4.03115 21.0343 6.13641 21.0343Z"
        fill="transparent"
      />
      <path
        d="M6.13739 21.0343H17.8649C19.9825 21.0343 21.0228 19.994 21.0228 17.9135V6.09925C21.0228 4.01876 19.9825 2.97852 17.8649 2.97852H6.13739C4.03212 2.97852 2.97949 4.01876 2.97949 6.09925V17.9135C2.97949 19.994 4.03212 21.0343 6.13739 21.0343ZM6.16216 19.2385C5.27051 19.2385 4.76277 18.7679 4.76277 17.8268V6.18595C4.76277 5.25715 5.27051 4.77418 6.16216 4.77418H17.8402C18.7318 4.77418 19.2395 5.25715 19.2395 6.18595V17.8268C19.2395 18.7679 18.7318 19.2385 17.8402 19.2385H6.16216Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function UncheckRoundedIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9938 21.9876C17.4551 21.9876 22 17.4551 22 11.9938C22 6.5325 17.4427 2 11.9814 2C6.5325 2 2 6.5325 2 11.9938C2 17.4551 6.54489 21.9876 11.9938 21.9876Z"
        fill="transparent"
      />
      <path
        d="M11.9938 21.9935C17.4551 21.9935 22 17.461 22 11.9997C22 6.53836 17.4427 2.00586 11.9814 2.00586C6.5325 2.00586 2 6.53836 2 11.9997C2 17.461 6.54489 21.9935 11.9938 21.9935ZM11.9938 20.1111C7.49845 20.1111 3.90712 16.5074 3.90712 11.9997C3.90712 7.49193 7.48607 3.88821 11.9814 3.88821C16.4891 3.88821 20.1052 7.49193 20.1052 11.9997C20.1052 16.5074 16.5016 20.1111 11.9938 20.1111Z"
        fill="currentColor"
      />
    </svg>
  );
}
