import { useTranslation } from 'react-i18next';

import { SortFilter } from '@/modules/showroom/advert/my/types';
import { BodyFilter } from '@/modules/showroom/advert/rent/my/BodyFilter';
import { ColorFilter } from '@/modules/showroom/advert/rent/my/ColorFilter';
import { SeatsFilter } from '@/modules/showroom/advert/rent/my/SeatsFilter';
import { useRentSearchStore } from '@/modules/showroom/advert/rent/my/store';
import { TransmissionFilter } from '@/modules/showroom/advert/rent/my/TransmissionFilter';
import { AutoFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/AutoFilter';
import cls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { PriceFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/PriceFilter';
import { SortSelect } from '@/modules/showroom/advert/search/SearchAdverts/SortSelect/SortSelect';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

type Props = {
  sortFilters: SortFilter[];
  showReset: boolean;
  onResetClick: () => void;
};

export function Filters({ sortFilters, showReset, onResetClick }: Props) {
  const { t } = useTranslation();

  const searchQuery = useRentSearchStore((s) => s.searchQuery);
  const setSearchQuery = useRentSearchStore((s) => s.setSearchQuery);
  const updateAutoFilter = useRentSearchStore((s) => s.updateAutoFilter);
  const autoFilters = useRentSearchStore((s) => s.autoFilters);
  const min = useRentSearchStore((s) => s.priceMin);
  const setMin = useRentSearchStore((s) => s.setPriceMin);
  const max = useRentSearchStore((s) => s.priceMax);
  const setMax = useRentSearchStore((s) => s.setPriceMax);
  const sortId = useRentSearchStore((s) => s.sortId);
  const setSortId = useRentSearchStore((s) => s.setSortId);

  return (
    <div className={cls.root}>
      <div className={cls.search}>
        <InputSearch
          placeholder={t('common.search')}
          value={searchQuery || ''}
          onChange={(v) => setSearchQuery(v)}
          outlined
          compact
        />
      </div>
      <ul className={cls.list}>
        <li className={cls.li}>
          <AutoFilter
            updateAutoFilter={updateAutoFilter}
            autoFilters={autoFilters}
            rent
          />
        </li>
        <li className={cls.li}>
          <PriceFilter min={min} max={max} setMin={setMin} setMax={setMax} />
        </li>
        <li className={cls.li}>
          <BodyFilter />
        </li>
        <li className={cls.li}>
          <TransmissionFilter />
        </li>
        <li className={cls.li}>
          <ColorFilter />
        </li>
        <li className={cls.li_input}>
          <SeatsFilter />
        </li>
        {showReset && (
          <li className={cls.li}>
            <button
              className={cls.reset_btn}
              onClick={onResetClick}
              type="button"
            >
              {t('common.reset')}
            </button>
          </li>
        )}
      </ul>
      <SortSelect
        sortFilters={sortFilters}
        sortId={sortId}
        setSortId={setSortId}
      />
    </div>
  );
}
