/* eslint-disable no-console */
import { showAlert } from './network';

function fallbackCopyToClipboard(text: string): void {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy', err);
  }
  document.body.removeChild(textArea);
}

export function copyToClipboard(text: string, successText?: string): void {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(text);
    if (successText) showAlert({ type: 'success', text: successText });
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      if (successText) showAlert({ type: 'success', text: successText });
    },
    (err) => {
      console.error('Could not copy text: ', err);
    }
  );
}
