import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSignupStore } from '@/modules/showroom/CreateShowroom/store';
import { CoverLogo } from '@/modules/showroom/ShowroomEdit/CoverLogo/CoverLogo';
import { Button } from '@/ui/Button/Button';

import cls from './CreateShowroom.module.scss';
import { ArrowIcon } from './icons';

export function ShowroomDesign() {
  const { t } = useTranslation();

  // Design
  const name = useSignupStore((s) => s.name);
  const coverUrl = useSignupStore((s) => s.coverUrl);
  const setCoverUrl = useSignupStore((s) => s.setCoverUrl);
  const logoUrl = useSignupStore((s) => s.logoUrl);
  const setLogoUrl = useSignupStore((s) => s.setLogoUrl);

  const hasDesign = coverUrl || logoUrl;

  const navigate = useNavigate();
  const onPrevClick = () => {
    navigate('/showroom/create/about');
  };
  const onNextClick = () => {
    navigate('/showroom/create/username');
  };

  return (
    <>
      <div className={cls.header}>
        <button className={cls.back_btn} type="button" onClick={onPrevClick}>
          <ArrowIcon />
        </button>
        <h2 className={cls.title}>{t('showroom.design')}</h2>
      </div>
      <CoverLogo
        name={name}
        logoUrl={logoUrl}
        setLogoUrl={setLogoUrl}
        coverUrl={coverUrl}
        setCoverUrl={setCoverUrl}
      />
      <div className={cls.btn}>
        <Button
          onClick={onNextClick}
          variant={hasDesign ? 'primary' : 'secondary'}
          color={hasDesign ? 'blue' : 'black'}
          fullWidth
        >
          {t(hasDesign ? 'common.continue' : 'common.skip')}
        </Button>
      </div>
    </>
  );
}
