import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useDebounce } from '@/hooks/useDebounce';
import { ChatList } from '@/modules/chat/ChatList/ChatList';
import { Header } from '@/modules/chat/ChatModal/Header/Header';
import { ChatsSearch } from '@/modules/chat/ChatsSearch/ChatsSearch';
import { useChatBgImage, useMyChats } from '@/modules/chat/hooks';
import { EmptyPlaceholder } from '@/modules/chat/Placeholders/EmptyPlaceholder';
import { ChatSimple, ChatWithFoundedMessage } from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';

import cls from './ChatPage.module.scss';

export function ChatPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const setScrollToChatMsgId = useChatUtilsStore((s) => s.setScrollToChatMsgId);
  const bgImg = useChatBgImage();

  const { id } = useParams();
  const chatId = id ? Number(id) : null;

  // Chats
  const [chats, chatsLoading] = useMyChats();
  const selectedChat = chats?.find((c) => c.id === chatId) || null;
  const showEmpty = !chatsLoading && chats && chats.length <= 0;

  const onChatClick = (c: ChatSimple) => {
    navigate(`/chat/${c.id}`);
  };

  // Search
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 800);
  const onSearchChatClick = (c: ChatSimple) => {
    onChatClick(c);
  };
  const onSearchChatMsgClick = (c: ChatWithFoundedMessage) => {
    const chat = chats ? chats.find((v) => v.id === c.id) : null;
    if (chat && c.founded_message) {
      onChatClick(chat);
      setScrollToChatMsgId(c.founded_message.id);
    }
  };

  return (
    <div className={cls.root}>
      <LayoutHeader title={t('chat.one')} />
      <PageHead title={t('pageTitle', { title: t('chat.one') })} />

      <div className={cls.inner}>
        <div className={cn(cls.left, 'hide-scrollbar')}>
          <Header
            searchText={searchText}
            setSearchText={setSearchText}
            searchDisabled={showEmpty}
            showSearch
          />

          {showEmpty ? (
            <div className={cls.placeholder}>
              <EmptyPlaceholder />
            </div>
          ) : (
            <>
              {!debouncedSearch ? (
                <div className={cls.chats}>
                  <ChatList
                    chats={chats || []}
                    loading={chatsLoading}
                    selectedChat={selectedChat}
                    onChatClick={onChatClick}
                    chatLink={false}
                  />
                </div>
              ) : (
                <ChatsSearch
                  searchText={debouncedSearch}
                  setSearchText={setSearchText}
                  onChatClick={onSearchChatClick}
                  onChatMessageClick={onSearchChatMsgClick}
                />
              )}
            </>
          )}
        </div>

        <div className={cn(cls.right, { [cls.right_chat]: !!selectedChat })}>
          <div
            className={cls.right_bg}
            style={{ backgroundImage: `url(${bgImg})` }}
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
