import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowClick } from '@/hooks/useWindowClick';
import { MAX_VIN_LEN } from '@/modules/showroom/advert/create/helpers';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { escapePlus, maxLength, onlyNumbersLatin } from '@/utils/format';

import cls from './Filters.module.scss';

export function VinFilter() {
  const { t } = useTranslation();

  const [isVinOpen, setVinOpen] = useState(false);

  const vin = useSearchStore((s) => s.vin);
  const setVin = useSearchStore((s) => s.setVin);

  const onResetClick = () => {
    setVinOpen(false);
    setVin(null);
  };

  useWindowClick((e) => {
    const el = e.target as HTMLElement | null;
    const parentEl = el?.closest(`.${escapePlus(cls.li_input)}`);

    if (!parentEl) {
      setVinOpen(false);
    }
  });

  return (
    <>
      <div>
        <FilterBtn
          text={t('vin.label')}
          onClick={() => setVinOpen(true)}
          active={isVinOpen}
          showSearch={isVinOpen}
          onResetClick={onResetClick}
          value={vin || ''}
          searchValue={vin || ''}
          onSearchChange={(v) =>
            setVin(onlyNumbersLatin(maxLength(v, MAX_VIN_LEN)))
          }
          searchPlaceholder={t('vin.label')}
        />
      </div>
    </>
  );
}
