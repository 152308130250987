export function ReplyIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1384 4.00652C15.4757 4.10158 16.5313 5.22458 16.5313 6.59595L16.5325 9.15215L16.5569 9.15807C21.9892 10.2918 26 15.141 26 20.8621C26 21.2778 25.9789 21.6921 25.9372 22.1029C25.8376 23.0823 24.5999 23.4343 24.0067 22.6518L23.7828 22.3663C22.0337 20.2093 19.5435 18.7944 16.7883 18.4237L16.5325 18.3925L16.5313 21.4042C16.5313 21.9845 16.338 22.5484 15.9825 23.0053C15.1046 24.1337 13.4848 24.3315 12.3647 23.4471L2.98738 16.0431C2.82409 15.9142 2.67684 15.7658 2.54886 15.6013C1.67091 14.4729 1.86724 12.8412 2.98738 11.9568L12.3647 4.55279C12.8183 4.19463 13.378 4 13.9543 4L14.1384 4.00652ZM13.9543 6.17336C13.8605 6.17336 13.7694 6.20504 13.6955 6.26335L4.31827 13.6673C4.13592 13.8113 4.10396 14.0769 4.24688 14.2606C4.26771 14.2874 4.29168 14.3116 4.31827 14.3326L13.6955 21.7365C13.8779 21.8805 14.1416 21.8483 14.2845 21.6646C14.3424 21.5902 14.3738 21.4984 14.3738 21.4039L14.375 17.2416C14.3752 16.6345 14.869 16.1454 15.4715 16.1553L15.7509 16.16L16.1611 16.1799C18.8868 16.3552 21.4309 17.3718 23.4946 19.0365L23.7035 19.21L23.675 19.0465C22.9045 14.9226 19.558 11.6748 15.3229 11.1545C14.7818 11.088 14.3749 10.6253 14.3748 10.0761L14.3738 6.59625C14.3738 6.36256 14.186 6.17336 13.9543 6.17336Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function EditIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1213 4.28884L23.7071 5.87462C24.8787 7.0462 24.8787 8.94569 23.7071 10.1173L21 12.8244L10.1464 23.678C9.30249 24.5219 8.15794 24.9959 6.96451 24.9959H5.05C3.91782 24.9959 3 24.0781 3 22.9459V21.0315C3 19.838 3.47414 18.6934 4.31811 17.8494L15.172 6.99616L15.1716 6.99594L17.8787 4.28884C19.0503 3.11726 20.9497 3.11726 22.1213 4.28884ZM16.5 8.4966L5.73228 19.2637C5.26341 19.7325 5 20.3685 5 21.0315V22.9459C5 22.9736 5.02239 22.9959 5.05 22.9959H6.96451C7.62752 22.9959 8.26339 22.7326 8.73222 22.2638L19.5 11.4966L16.5 8.4966ZM19.2929 5.70305L18 6.99594L21 9.99594L22.2929 8.70305C22.6834 8.31253 22.6834 7.67936 22.2929 7.28884L20.7071 5.70305C20.3166 5.31253 19.6834 5.31253 19.2929 5.70305Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6L12 12M12 12L18 18M12 12L18 6M12 12L6 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
