import { useEffect, useRef } from 'react';
import { useBlocker } from 'react-router-dom';

interface Location {
  pathname: string;
  search: string;
  hash: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
  key: string;
}

export type BlockerFunction = (args: {
  currentLocation: Location;
  nextLocation: Location;
}) => boolean;

export function usePrompt(
  onLocationChange: BlockerFunction,
  hasUnsavedChanges: boolean
): void {
  const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}
