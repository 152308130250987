import { PromotionPackage } from '@/modules/promotion/types';

export const TARIFFS_MAX_DAYS_LEN = 3;

export type PackagesCountType = Record<string, number>;

export function getInitialPackagesCount(packages: PromotionPackage[]) {
  const initial: PackagesCountType = {};

  packages.forEach((p) => {
    initial[String(p.id)] = 0;
  });

  return initial;
}
