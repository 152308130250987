import { create } from 'zustand';

import { Nullable } from '@/app/types';
import { GetShowroomRes } from '@/modules/showroom/types';

interface ShowroomStore {
  showroom: Nullable<GetShowroomRes>;
  setShowroom: (showroom: Nullable<GetShowroomRes>) => void;
}

export const useShowroomStore = create<ShowroomStore>((set) => ({
  showroom: null,
  setShowroom: (showroom: Nullable<GetShowroomRes>) => set({ showroom })
}));
