import { create } from 'zustand';

import { axios } from '@/app/api';
import { LANGS } from '@/modules/i18n';
import { RegSpec } from '@/modules/regional-specs/types';
import { TRANSPORT_API_ROUTES } from '@/modules/transport/api';
import { GetTransportOwnersRes } from '@/modules/transport/types';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';
import {
  getFromStorage,
  removeFromStorage,
  saveInStorage
} from '@/utils/local-storage';
import { showAlert } from '@/utils/network';

const OWNERS_STORE_NAME = 'dealers-autoae-owners-list';

type StoreValue = Record<string, RegSpec[]>;
const defaultValue = LANGS.reduce((acc, lang) => {
  return { ...acc, [lang]: [] };
}, {});

interface TransportOwnersStore {
  data: StoreValue;
  fetchData: () => void;
  reset: () => void;
}

export const useTransportOwnersStore = create<TransportOwnersStore>(
  (set, get) => ({
    data: getFromStorage(OWNERS_STORE_NAME) || defaultValue,
    fetchData: async () => {
      const current = get().data;
      if (Object.values(current).every((list) => !!list.length)) return;

      try {
        const promises = LANGS.map((lang) =>
          axios.get<GetTransportOwnersRes>(TRANSPORT_API_ROUTES.getOwners, {
            params: MAX_PG_LIMIT_REQ,
            headers: { lang }
          })
        );
        const results = await Promise.all(promises);
        const data: StoreValue = results.reduce((acc, res, i) => {
          return { ...acc, [LANGS[i]]: res.data };
        }, {});

        set({ data });
        saveInStorage(OWNERS_STORE_NAME, data);
      } catch (error) {
        showAlert({ error });
      }
    },
    reset: () => {
      set({ data: defaultValue });
      removeFromStorage(OWNERS_STORE_NAME);
    }
  })
);
