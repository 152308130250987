import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useLang } from '@/hooks/useLang';
import { useDarkmode } from '@/modules/theme/useTheme';
import { mapGraphData } from '@/modules/wallet/helpers';
import { ExpensesDetailStatisticsRes } from '@/modules/wallet/types';

import cls from './ExpensesCard.module.scss';
import { getApexOptions } from './helpers';

type Props = {
  from: Date;
  to: Date;
  expenses: ExpensesDetailStatisticsRes;
};

export function ExpensesCardGraph({ from, to, expenses }: Props) {
  const [lang] = useLang();
  const { isDark } = useDarkmode();

  const mappedData = useMemo(
    () => mapGraphData(expenses, from, to),
    [expenses, from, to]
  );
  const series = useMemo(() => {
    const totalExpenses = mappedData.mappedData.reduce((acc, cur) => {
      const newArr = cur.data.map((v, i) => {
        const prev = acc[i] || 0;
        return prev + v;
      });

      return newArr;
    }, [] as number[]);
    return [{ data: totalExpenses, name: '' }];
  }, [mappedData.mappedData]);

  const options = useMemo(
    () => getApexOptions(mappedData.mappedDates, lang, isDark),
    [lang, mappedData.mappedDates, isDark]
  );

  return (
    <div className={cls.graph}>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={125}
      />
    </div>
  );
}
