import { useCallback } from 'react';

import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { CreditRequests } from '@/modules/credit/CreditRequests/CreditRequests';
import { UseCreditRequestsPg } from '@/modules/credit/hooks';
import { GetCreditRequestsReq } from '@/modules/credit/types';

import cls from './CreditRequests.module.scss';

const SORT_ID_MAP: Record<number, GetCreditRequestsReq['order_by']> = {
  1: 'from_new_to_old',
  2: 'from_old_to_new'
};

type Props = {
  search: string;
  sortId: Nullable<number>;
  updateKey: number;
  setHasNotViewed: (v: boolean) => void;
};

export const CreditRequestsPage: React.FC<Props> = ({
  search,
  sortId,
  updateKey,
  setHasNotViewed
}) => {
  const debouncedSearch = useDebounce(search, 800);
  const orderBy = sortId ? SORT_ID_MAP[sortId] : undefined;

  const onDataChange = useCallback(
    (data: UseCreditRequestsPg['data']) => {
      const notViewed = data ? data.filter((v) => !v.has_been_viewed) : [];
      setHasNotViewed(notViewed.length > 0);
    },
    [setHasNotViewed]
  );

  return (
    <div className={cls.root}>
      <CreditRequests
        orderBy={orderBy}
        key={updateKey + debouncedSearch + (orderBy || '')}
        search={debouncedSearch}
        onDataChange={onDataChange}
      />
    </div>
  );
};
