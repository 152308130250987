import cn from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupedOptionI, Nullable, OptionI } from '@/app/types';
import { useWindowClick } from '@/hooks/useWindowClick';
import { PrimaryGroupedSelectDropdown } from '@/ui/PrimarySelect/PrimaryGroupedSelectDropdown';
import { Spinner } from '@/ui/Spinner/Spinner';
import { escapePlus } from '@/utils/format';

import { ChevronIcon } from './icons';
import cls from './PrimarySelect.module.scss';
import { PrimarySelectDropdown } from './PrimarySelectDropdown';

type Props = {
  name: string;
  value?: OptionI[];
  onOpen?: () => void;
  onChange?: (option: OptionI[]) => void;
  onOptionRemove?: (option: OptionI) => void;
  onOptionAdd?: (option: OptionI, removeOtherOptions?: boolean) => void;
  options: OptionI[] | GroupedOptionI[];
  multiple?: boolean;
  disabled?: boolean;
  loading?: boolean;
  dropdownTop?: boolean;
  dropdownFullWidth?: boolean;
  placeholder?: Nullable<string>;
  clearText?: Nullable<string>;
  onClear?: () => void;
  showSearch?: boolean;
  searchFilterFn?: (searchText: string, id: OptionI['id']) => void;
  firstDropdownItem?: React.ReactNode;
  showScrollbar?: boolean;
  large?: boolean;
};

export const PrimarySelect: React.FC<Props> = ({
  name,
  value,
  onOpen,
  onChange,
  onOptionRemove,
  onOptionAdd,
  options,
  multiple,
  disabled,
  loading,
  dropdownTop,
  dropdownFullWidth,
  placeholder,
  clearText,
  onClear,
  showSearch,
  searchFilterFn,
  firstDropdownItem,
  showScrollbar,
  large
}) => {
  const { t } = useTranslation();

  const rootRef = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  useWindowClick((e) => {
    const el = e.target as HTMLElement | null;
    const clickedRoot = el?.closest(`.${escapePlus(cls.root)}`);
    const thisRoot = rootRef?.current;

    if (!!clickedRoot && clickedRoot !== thisRoot) {
      setOpen(false);
    }
  });

  const hasValue = !!value && value.length > 0;

  // Handlers
  const toggleOpen = () => {
    const newValue = !isOpen;
    setOpen(newValue);
    if (newValue && onOpen) onOpen();
  };

  const onClearClick = () => {
    setOpen(false);
    if (onClear) onClear();
  };

  return (
    <div
      ref={rootRef}
      className={cn(cls.root, {
        [cls.root_open]: isOpen,
        [cls.root_large]: large,
        [cls.root_selected]: hasValue,
        [cls.root_disabled]: disabled
      })}
    >
      <button
        className={cls.select_btn}
        onClick={toggleOpen}
        disabled={disabled}
        type="button"
      >
        <span className={cls.btn_text}>
          {hasValue ? (
            value.map((v) => v.text).join(t('common.listSeparator') as string)
          ) : (
            <>
              {placeholder && (
                <span className={cls.placeholder}>{placeholder}</span>
              )}
            </>
          )}
        </span>

        {loading ? (
          <Spinner color="var(--clr-blue)" size={20} />
        ) : (
          <span className={cls.btn_right}>
            <ChevronIcon />
          </span>
        )}
      </button>

      {options.length > 0 && (
        <>
          {/* eslint-disable-next-line no-prototype-builtins */}
          {options[0].hasOwnProperty('options') ? (
            <PrimaryGroupedSelectDropdown
              name={name}
              value={value}
              groupedOptions={options as GroupedOptionI[]}
              dropdownTop={dropdownTop}
              dropdownFullWidth={dropdownFullWidth}
              clearText={clearText}
              isOpen={isOpen}
              setOpen={setOpen}
              onChange={onChange}
              onOptionRemove={onOptionRemove}
              onOptionAdd={onOptionAdd}
              multiple={multiple}
              onClearClick={onClearClick}
              showSearch={showSearch}
              searchFilterFn={searchFilterFn}
              firstDropdownItem={firstDropdownItem}
            />
          ) : (
            <PrimarySelectDropdown
              name={name}
              value={value}
              options={options as OptionI[]}
              dropdownTop={dropdownTop}
              dropdownFullWidth={dropdownFullWidth}
              clearText={clearText}
              isOpen={isOpen}
              setOpen={setOpen}
              onChange={onChange}
              onOptionRemove={onOptionRemove}
              onOptionAdd={onOptionAdd}
              multiple={multiple}
              onClearClick={onClearClick}
              showSearch={showSearch}
              searchFilterFn={searchFilterFn}
              firstDropdownItem={firstDropdownItem}
              showScrollbar={showScrollbar}
            />
          )}
        </>
      )}
    </div>
  );
};

PrimarySelect.displayName = 'PrimarySelect';
