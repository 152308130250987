/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap, Map, Marker } from 'react-mapkit';

import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { useMapDarkmode } from '@/modules/mapkit/helpers';
import { SuggestionsDropdown } from '@/modules/mapkit/SuggestionsDropdown/SuggestionsDropdown';
import {
  GeocodeLookupResponse,
  SearchAutoCompleteItem
} from '@/modules/mapkit/types';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';

import cls from './SearchMap.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
  initialCoords: [number, number];
  buttonText?: Nullable<string>;
  onSubmit?: (coords: [number, number]) => void;
  mapOnly?: boolean;
};

export const SearchMap: React.FC<Props> = ({
  isOpen,
  close,
  initialCoords,
  buttonText,
  onSubmit,
  mapOnly
}) => {
  const { t } = useTranslation();

  const [address, setAddress] = useState('');
  const [lat, setLat] = useState(initialCoords[0]);
  const [long, setLong] = useState(initialCoords[1]);
  const { map, mapProps, mapkit, setCenter, setRegion } = useMap({
    region: {
      latitude: lat,
      longitude: long,
      latitudeSpan: 0.01,
      longitudeSpan: 0.01
    }
  });
  useMapDarkmode(map);

  const [isSearchOpen, setSearchOpen] = useState(false);
  const [mapSearch, setMapSearch] = useState();
  const [geocoder, setGeocoder] = useState<any>();
  const debouncedAddress = useDebounce(address, 500);

  const onRegionChange = useCallback(
    (e: any) => {
      if (mapOnly) return;

      const { latitude, longitude } = e.target.center;
      if (geocoder) {
        const coord = new mapkit.Coordinate(latitude, longitude);
        geocoder.reverseLookup(
          coord,
          (error: any, data: GeocodeLookupResponse) => {
            if (error) {
              console.error(error);
              return;
            } else if (data && data.results && data.results[0]) {
              const { formattedAddress } = data.results[0];
              if (formattedAddress) {
                setLat(latitude);
                setLong(longitude);
                setAddress(formattedAddress);
              }
            }
          }
        );
      } else {
        setLat(latitude);
        setLong(longitude);
      }
    },
    [geocoder, mapkit, mapOnly]
  );

  useEffect(() => {
    if (mapkit && mapkit.Search && !mapSearch) {
      const s = new mapkit.Search();
      setMapSearch(s);
    }
  }, [mapSearch, mapkit]);

  useEffect(() => {
    if (mapkit && mapkit.Search && !geocoder) {
      const g = new mapkit.Geocoder();
      setGeocoder(g);
    }
  }, [geocoder, mapkit]);

  useEffect(() => {
    if (map) {
      map.addEventListener('region-change-end', onRegionChange);
      return () => {
        map.removeEventListener('region-change-end', onRegionChange);
      };
    }
  }, [map, onRegionChange]);

  useEffect(() => {
    if (!address && geocoder) {
      const coord = new mapkit.Coordinate(initialCoords[0], initialCoords[1]);
      geocoder.reverseLookup(
        coord,
        (error: any, data: GeocodeLookupResponse) => {
          if (error) {
            console.error(error);
            return;
          } else if (data && data.results && data.results[0]) {
            const { formattedAddress } = data.results[0];
            if (formattedAddress) {
              setAddress(formattedAddress);
            }
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geocoder, initialCoords, address]);

  const onSearchClick = (v: SearchAutoCompleteItem) => {
    setSearchOpen(false);

    const { coordinate, displayLines } = v;
    setAddress(displayLines.reverse().join(' '));

    const { latitude, longitude } = coordinate;
    setLat(latitude);
    setLong(longitude);
    setCenter([latitude, longitude]);
    setRegion({
      latitude,
      longitude,
      latitudeSpan: 0.001,
      longitudeSpan: 0.001
    });
  };

  return (
    <AdaptiveModal name="search-map" isOpen={isOpen} close={close}>
      <div className={cls.root}>
        {!mapOnly && (
          <div className={cls.field}>
            <Input
              value={address}
              onChange={(e) => {
                setSearchOpen(true);
                setAddress(e.currentTarget.value);
              }}
              label={t('common.search')}
            />

            {!!mapSearch && (
              <SuggestionsDropdown
                search={mapSearch}
                query={debouncedAddress}
                isOpen={isSearchOpen}
                close={() => setSearchOpen(false)}
                onClick={onSearchClick}
              />
            )}
          </div>
        )}

        <div className={cls.map}>
          <Map {...mapProps}>
            <Marker key={lat + long} latitude={lat} longitude={long} />
          </Map>
        </div>

        {!mapOnly && buttonText && onSubmit && (
          <Button onClick={() => onSubmit([lat, long])} fullWidth>
            {buttonText}
          </Button>
        )}
      </div>
    </AdaptiveModal>
  );
};
