import {
  CreateAdvertSlice,
  SpecsSlice
} from '@/modules/showroom/advert/create/types';
import { RentAdvertAuthorDetail } from '@/modules/showroom/advert/rent/detail/types';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { UpdateRentAdvertReq } from '@/modules/showroom/advert/rent/update/types';
import {
  isArraySame,
  isMultilineTextSame,
  isPhotosSame
} from '@/modules/showroom/advert/update/compare-req';
import { cleanPhotosObjects } from '@/modules/showroom/advert/update/helpers';

export function optimizeRentReq(
  store: CreateAdvertSlice & SpecsSlice & RentUpdateAdvertStore,
  detail: RentAdvertAuthorDetail
): Omit<UpdateRentAdvertReq, 'status_id'> {
  const brandSame = store?.brand?.id === detail.brand?.id;
  const modelSame = store?.model?.id === detail.model?.id;
  const generationSame = store?.generation?.id === detail.generation?.id;
  const yearSame = store?.year?.id === detail.year_of_issue?.id;
  const bodySame = store?.body?.id === detail.body?.id;
  const modificationSame = store?.modification?.id === detail.modification?.id;
  const engineSame = store?.engine?.id === detail.engine?.id;
  const driveSame = store?.drive?.id === detail.drive_unit?.id;
  const transmissionSame = store?.transmission?.id === detail.transmission?.id;
  const bodyColorSame = store?.bodyColor?.id === detail.body_colour?.id;
  const interiorColorSame =
    store?.interiorColor?.id === detail.interior_colour?.id;

  const photos = cleanPhotosObjects(store.photos);
  const photosSame = isPhotosSame(photos, detail.photos);
  const descrSame = isMultilineTextSame(store.description, detail.description);

  const isChatAvailableSame = !store.disableChat === detail.available_chat;

  const storeComplectationId = store.isCustomComplectation
    ? null
    : store.complectationId;
  const complectationIdSame = storeComplectationId === detail.complectation?.id;

  const storeOptionsIds =
    store.complectationId && !store.isCustomComplectation
      ? null
      : store.complectationOptionsItemsIds;
  const detailComplectationOptionsItemsIds = detail.complectation
    ? detail.complectation?.complectation_options.reduce<number[]>(
        (acc, cur) => {
          return [
            ...acc,
            ...cur.options.reduce<number[]>((optAcc, optCur) => {
              return [...optAcc, ...optCur.items.map((item) => item.id)];
            }, [])
          ];
        },
        []
      )
    : null;
  const complectationOptionsItemsIdsSame = isArraySame(
    storeOptionsIds,
    detailComplectationOptionsItemsIds
  );

  const storeContacts =
    store.contacts && store.contacts?.length > 0 ? store.contacts : null;

  const addressIdSame = store.addressId === detail.address?.id;

  const depositSame = store?.deposit === detail.deposit;
  const depositReturnDaysSame =
    store?.depositReturnDays === detail.deposit_return_days;

  const ageSame = store?.age === detail.age;
  const driveExpSame = store?.driveExp === detail.driving_experience;

  const payMethodsIdsSame = isArraySame(
    store.payMethodsIds,
    detail.pay_methods ? detail.pay_methods.map((p) => p.id) : null
  );

  const docsIdsSame = isArraySame(
    store.docsIds,
    detail.docs ? detail.docs.map((p) => p.id) : null
  );

  const deliveryToAirportSame =
    !!store?.deliveryToAirport === !!detail.delivery_to_airport;
  const deliveryToCustomerSame =
    !!store?.deliveryToCustomer === !!detail.delivery_to_customer;

  const bookingAvailableSame =
    store?.isBookingAvailable === detail.available_booking;

  return {
    brand_id: brandSame ? undefined : store.brand?.id || null,
    model_id: modelSame ? undefined : store.model?.id || null,
    generation_id: generationSame ? undefined : store.generation?.id || null,
    year_of_issue_id: yearSame ? undefined : store.year?.id || null,
    body_id: bodySame ? undefined : store.body?.id || null,
    modification_id: modificationSame
      ? undefined
      : store.modification?.id || null,
    engine_id: engineSame ? undefined : store.engine?.id || null,
    drive_unit_id: driveSame ? undefined : store.drive?.id || null,
    transmission_id: transmissionSame
      ? undefined
      : store.transmission?.id || null,
    body_colour_id: bodyColorSame ? undefined : store.bodyColor?.id || null,
    interior_colour_id: interiorColorSame
      ? undefined
      : store.interiorColor?.id || null,
    photos: photosSame ? undefined : photos,
    description: descrSame ? undefined : store.description,
    available_chat: isChatAvailableSame ? undefined : !store.disableChat,
    complectation_id: complectationIdSame ? undefined : storeComplectationId,
    options_items_list_ids: complectationOptionsItemsIdsSame
      ? undefined
      : storeOptionsIds,
    contacts: storeContacts,
    showroom_address_id: addressIdSame ? undefined : store.addressId,
    deposit: depositSame ? undefined : store.deposit,
    deposit_return_days: depositReturnDaysSame
      ? undefined
      : store.depositReturnDays,
    age: ageSame ? undefined : store.age,
    driving_experience: driveExpSame ? undefined : store.driveExp,
    pay_method_ids: payMethodsIdsSame ? undefined : store.payMethodsIds,
    docs_ids: docsIdsSame ? undefined : store.docsIds,
    delivery_to_airport: deliveryToAirportSame
      ? undefined
      : store.deliveryToAirport,
    delivery_to_customer: deliveryToCustomerSame
      ? undefined
      : store.deliveryToCustomer,
    available_booking: bookingAvailableSame
      ? undefined
      : store.isBookingAvailable
  };
}
