import { useEffect } from 'react';

import { useLang } from '@/hooks/useLang';
import { useRegSpecsStore } from '@/modules/regional-specs/store';
import { GetRegSpecsRes } from '@/modules/regional-specs/types';

export function useRegSpecs(): GetRegSpecsRes {
  const [lang] = useLang();

  const data = useRegSpecsStore((s) => s.data);
  const list = data[lang];

  const fetchRegSpecs = useRegSpecsStore((s) => s.fetchData);
  useEffect(() => {
    fetchRegSpecs();
  }, [fetchRegSpecs]);

  return list;
}
