import { Warranty } from '@/modules/showroom/advert/create/Warranty/Warranty';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';

export function WarrantyWrap() {
  const warranty = useUpdateAdvertStore((s) => s.warranty);
  const setWarranty = useUpdateAdvertStore((s) => s.setWarranty);
  const isWarrantyFilled = useUpdateAdvertStore((s) => s.isWarrantyFilled);
  const setWarrantyFilled = useUpdateAdvertStore((s) => s.setWarrantyFilled);

  return (
    <Warranty
      warrantyDate={warranty}
      setWarrantyDate={setWarranty}
      isWarrantyFilled={isWarrantyFilled}
      setWarrantyFilled={setWarrantyFilled}
    />
  );
}
