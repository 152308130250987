export function CloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 22.6411C5.84372 23.1157 6.66308 23.1096 7.12564 22.6471L14.0021 15.7706L20.8746 22.6451C21.3452 23.1157 22.1637 23.1229 22.6403 22.6391C23.117 22.1533 23.119 21.3531 22.6484 20.8805L15.7759 13.9968L22.6484 7.12435C23.119 6.65375 23.1262 5.84444 22.6403 5.3678C22.1545 4.88199 21.3452 4.87998 20.8746 5.35975L14.0021 12.2322L7.12564 5.35774C6.66308 4.88803 5.83455 4.87081 5.35791 5.36579C4.88328 5.8516 4.88932 6.65978 5.35187 7.12234L12.2284 13.9968L5.35187 20.8845C4.88932 21.345 4.87411 22.1644 5.35791 22.6411Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58537 12.0063C3.42353 12.1646 3.33334 12.3757 3.33334 12.6189C3.33334 13.1092 3.71365 13.4923 4.20265 13.4923C4.44851 13.4923 4.67525 13.4001 4.83423 13.2288L10.3716 7.56148H9.63522L15.1672 13.2288C15.331 13.4014 15.5591 13.4923 15.7974 13.4923C16.285 13.4923 16.6667 13.1092 16.6667 12.6189C16.6667 12.3744 16.5779 12.1633 16.4133 12.0063L10.6816 6.14039C10.4913 5.93909 10.2597 5.83596 10.0038 5.83325C9.74302 5.83325 9.51629 5.93774 9.31981 6.14039L3.58537 12.0063Z"
        fill="currentColor"
      />
    </svg>
  );
}
