import { Nullable } from '@/app/types';
import { makeKey, useCurrencyStore } from '@/modules/currency/store';

export function useCurrencyRate(
  base: string,
  target: string
): Nullable<number> {
  const rates = useCurrencyStore((s) => s.rates);
  const key = makeKey(base, target);
  const rate = key in rates ? rates[key] : null;
  return rate;
}
