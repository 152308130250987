import { Trans, useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import {
  DEPOSIT_DAYS_MAX_LEN,
  DEPOSIT_MAX_LEN
} from '@/modules/showroom/advert/rent/update/consts';
import { isDepositValid } from '@/modules/showroom/advert/rent/update/helpers';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { maxLength, numberWithSpaces, onlyNumbers } from '@/utils/format';

import cls from './Deposit.module.scss';

type Props = {
  deposit: RentUpdateAdvertStore['deposit'];
  setDeposit: RentUpdateAdvertStore['setDeposit'];
  depositReturnDays: RentUpdateAdvertStore['depositReturnDays'];
  setDepositReturnDays: RentUpdateAdvertStore['setDepositReturnDays'];
  isDepositFilled: RentUpdateAdvertStore['isDepositFilled'];
  setDepositFilled: RentUpdateAdvertStore['setDepositFilled'];
};

export function Deposit({
  deposit,
  setDeposit,
  depositReturnDays,
  setDepositReturnDays,
  isDepositFilled,
  setDepositFilled
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const noDeposit = deposit === 0 && depositReturnDays === 0;
  const isValid = isDepositValid(deposit, depositReturnDays);

  const onSetClick = () => {
    setDepositFilled(false);
    setDeposit(null);
    setDepositReturnDays(null);
  };

  const onRemoveClick = () => {
    setDepositFilled(true);
    setDeposit(0);
    setDepositReturnDays(0);
  };

  const onContinueClick = () => {
    setDepositFilled(true);
    scrollToSection(SCROLLTO.age, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.deposit}>
      <div className="box">
        <h1 className={cls.title}>
          {noDeposit ? (
            <Trans
              t={t}
              i18nKey="deposit.titleRich"
              components={{
                wrap: <span />
              }}
              values={{ value: t('deposit.without') }}
            />
          ) : (
            t('deposit.title')
          )}
        </h1>
        {!noDeposit && (
          <>
            <p className={cls.subtitle}>{t('deposit.subtitle')}</p>

            <div className={cls.row}>
              <Input
                value={deposit ? numberWithSpaces(deposit, lang) : ''}
                onChange={(e) =>
                  setDeposit(
                    Number(
                      maxLength(
                        onlyNumbers(e.currentTarget.value),
                        DEPOSIT_MAX_LEN
                      )
                    )
                  )
                }
                label={t('deposit.sum', { currency: CURRENCY_DISPLAY.aed })}
              />
              <Input
                value={
                  depositReturnDays
                    ? numberWithSpaces(depositReturnDays, lang)
                    : ''
                }
                onChange={(e) =>
                  setDepositReturnDays(
                    Number(
                      maxLength(
                        onlyNumbers(e.currentTarget.value),
                        DEPOSIT_DAYS_MAX_LEN
                      )
                    )
                  )
                }
                label={t('deposit.period')}
              />
            </div>
          </>
        )}

        <div className={cls.buttons}>
          {noDeposit ? (
            <Button
              onClick={onSetClick}
              color="black"
              variant="secondary"
              fullWidth
            >
              {t('deposit.set')}
            </Button>
          ) : (
            <Button
              onClick={onRemoveClick}
              color={isDepositFilled ? 'red' : 'black'}
              variant={isDepositFilled ? 'tertiary' : 'secondary'}
              fullWidth
            >
              {t(isDepositFilled ? 'deposit.remove' : 'deposit.without')}
            </Button>
          )}

          {!isDepositFilled && isValid && (
            <Button
              onClick={onContinueClick}
              color="blue"
              variant="primary"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
