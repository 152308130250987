import cn from 'classnames';

import { useCookieBool } from '@/hooks/useCookieBool';

import { CloseIcon, InfoIcon } from './icons';
import cls from './Message.module.scss';

type Props = {
  text: string[];
  closeCookieName?: string;
};

export function Message({ text, closeCookieName }: Props) {
  const [clicked, setClicked] = useCookieBool(closeCookieName || '');
  if (closeCookieName && clicked) return <></>;

  return (
    <div className={cn(cls.root, { [cls.root_closeable]: closeCookieName })}>
      <InfoIcon />

      {text.map((tx, i) => (
        <p key={i}>{tx}</p>
      ))}

      {closeCookieName && (
        <button
          className={cls.close_btn}
          type="button"
          onClick={() => setClicked(true)}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
