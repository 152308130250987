import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { Nullable } from '@/app/types';
import {
  getAdvertStatsEngagement,
  getBrokenParams,
  getPriceStats,
  getPubDays,
  getReasonsOfRemove,
  getSortFilters,
  getViewsAndExpensesByDay
} from '@/modules/showroom/advert/my/api';
import {
  AdvertEngagementSmallRes,
  GetBrokenParamsReq,
  GetBrokenParamsRes,
  GetPubDaysReq,
  GetPubDaysRes,
  GetReasonsOfRemoveReq,
  GetReasonsOfRemoveRes,
  GetSortFiltersReq,
  GetSortFiltersRes,
  WeekDaysForScheduleRefresh,
  getPriceStatsRes,
  getViewsAndExpensesByDayRes
} from '@/modules/showroom/advert/my/types';
import { getRentAdvertStatsEngagement } from '@/modules/showroom/advert/rent/my/api';
import { showAlert } from '@/utils/network';

export function usePubDays(
  params?: Nullable<GetPubDaysReq>
): [GetPubDaysRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetPubDaysRes>();

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await getPubDays(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useBrokenParams(
  params?: Nullable<GetBrokenParamsReq>
): [GetBrokenParamsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetBrokenParamsRes>();

  const load = useCallback(async () => {
    if (params === null) return;
    setLoading(true);

    try {
      const r = await getBrokenParams(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useSortFilters(
  params?: GetSortFiltersReq
): [GetSortFiltersRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetSortFiltersRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getSortFilters(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useReasonsOfRemove(
  params?: GetReasonsOfRemoveReq
): [GetReasonsOfRemoveRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<GetReasonsOfRemoveRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getReasonsOfRemove(params);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useViewsAndExpensesByDay(
  advertId: number
): [getViewsAndExpensesByDayRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<getViewsAndExpensesByDayRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getViewsAndExpensesByDay(advertId);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [advertId]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function usePriceStats(
  advertId: number
): [getPriceStatsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<getPriceStatsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getPriceStats(advertId);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [advertId]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useAdvertStatsEngagement(
  advertId: number,
  rent?: boolean
): [AdvertEngagementSmallRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<AdvertEngagementSmallRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const fn = rent ? getRentAdvertStatsEngagement : getAdvertStatsEngagement;
      const r = await fn(advertId);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [advertId, rent]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export const useWeekDaysForScheduleRefresh = (
  weekDaysForScheduleRefresh: Nullable<WeekDaysForScheduleRefresh[]>
) => {
  const { t } = useTranslation();
  const weekDays = useCommonStore((s) => s.weekDays);

  const refreshScheduleIsSetted = weekDaysForScheduleRefresh?.length;

  const scheduleWeekDaysIds = useMemo(() => {
    if (!refreshScheduleIsSetted) return [];

    return weekDaysForScheduleRefresh.map((weekDay) => weekDay.id);
  }, [refreshScheduleIsSetted, weekDaysForScheduleRefresh]);

  const scheduleWeekDaysString = useMemo(() => {
    if (!refreshScheduleIsSetted) return '';

    if (weekDaysForScheduleRefresh.length === weekDays.length)
      return t('refresh.everyDay');

    if (weekDaysForScheduleRefresh.length > 2)
      return t('refresh.daysWithCount', {
        days: weekDaysForScheduleRefresh
          .slice(0, 2)
          .map((weekDay) => weekDay.short_name)
          .join(t('common.listSeparator') || ''),
        count: weekDaysForScheduleRefresh.length - 2
      });

    return weekDaysForScheduleRefresh
      .map((weekDay) => weekDay.short_name)
      .join(t('common.listSeparator') || '');
  }, [refreshScheduleIsSetted, t, weekDays.length, weekDaysForScheduleRefresh]);

  const scheduleHour = useMemo<Nullable<number>>(() => {
    if (!refreshScheduleIsSetted) return null;

    return weekDaysForScheduleRefresh[0].hours_after_midnight;
  }, [refreshScheduleIsSetted, weekDaysForScheduleRefresh]);

  return {
    refreshScheduleIsSetted,
    scheduleWeekDaysIds,
    scheduleWeekDaysString,
    scheduleHour
  };
};
