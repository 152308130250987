export function CloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 22.6411C5.84372 23.1157 6.66308 23.1096 7.12564 22.6471L14.0021 15.7706L20.8746 22.6451C21.3452 23.1157 22.1637 23.1229 22.6403 22.6391C23.117 22.1533 23.119 21.3531 22.6484 20.8805L15.7759 13.9968L22.6484 7.12435C23.119 6.65375 23.1262 5.84444 22.6403 5.3678C22.1545 4.88199 21.3452 4.87998 20.8746 5.35975L14.0021 12.2322L7.12564 5.35774C6.66308 4.88803 5.83455 4.87081 5.35791 5.36579C4.88328 5.8516 4.88932 6.65978 5.35187 7.12234L12.2284 13.9968L5.35187 20.8845C4.88932 21.345 4.87411 22.1644 5.35791 22.6411Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49795 14.6625C12.1492 14.6625 15.1637 11.644 15.1637 7.99795C15.1637 4.34666 12.144 1.33334 8.49266 1.33334C4.84782 1.33334 1.83334 4.34666 1.83334 7.99795C1.83334 11.644 4.85311 14.6625 8.49795 14.6625ZM8.49795 13.3497C5.52811 13.3497 3.15146 10.9678 3.15146 7.99795C3.15146 5.02811 5.52282 2.64618 8.49266 2.64618C11.4625 2.64618 13.8509 5.02811 13.8509 7.99795C13.8509 10.9678 11.4678 13.3497 8.49795 13.3497Z"
        fill="currentColor"
      />
      <path
        d="M7.36999 11.5472H9.9669C10.2524 11.5472 10.4762 11.3397 10.4762 11.0508C10.4762 10.7782 10.2524 10.562 9.9669 10.562H9.24813V7.5137C9.24813 7.13276 9.05769 6.88077 8.69845 6.88077H7.48318C7.19657 6.88077 6.97385 7.09819 6.97385 7.37072C6.97385 7.65966 7.19657 7.86711 7.48318 7.86711H8.13522V10.562H7.36999C7.08457 10.562 6.86069 10.7782 6.86069 11.0508C6.86069 11.3397 7.08457 11.5472 7.36999 11.5472ZM8.4573 5.88289C8.94455 5.88289 9.32857 5.49242 9.32857 5.00866C9.32857 4.51908 8.94455 4.13093 8.4573 4.13093C7.9788 4.13093 7.58718 4.51908 7.58718 5.00866C7.58718 5.49242 7.9788 5.88289 8.4573 5.88289Z"
        fill="currentColor"
      />
    </svg>
  );
}
