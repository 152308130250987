import { useMemo } from 'react';

import { useCommonStore } from '@/app/common-store';
import {
  DESKTOP_MAX_TEXTAREA_HEIGHT,
  MOBILE_MAX_TEXTAREA_HEIGHT
} from '@/modules/chat/Detail/Form/consts';
import { DESKTOP_BREAKPOINT } from '@/utils/consts';

export function useChatTextareaScrollHeight() {
  const windowWidth = useCommonStore((s) => s.windowWidth);
  const isDesktop = windowWidth > DESKTOP_BREAKPOINT;
  const allowScrollAfter = useMemo(
    () =>
      isDesktop ? DESKTOP_MAX_TEXTAREA_HEIGHT : MOBILE_MAX_TEXTAREA_HEIGHT,
    [isDesktop]
  );

  return allowScrollAfter;
}
