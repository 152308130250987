import { PayMethods } from '@/modules/showroom/advert/rent/update/AdditionalInfo/PayMethods/PayMethods';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function PayMethodsWrap() {
  const payMethodsIds = useRentUpdateAdvertStore((s) => s.payMethodsIds);
  const setPayMethodsIds = useRentUpdateAdvertStore((s) => s.setPayMethodsIds);
  const isPayMethodIdsFilled = useRentUpdateAdvertStore(
    (s) => s.isPayMethodIdsFilled
  );
  const setPayMethodIdsFilled = useRentUpdateAdvertStore(
    (s) => s.setPayMethodIdsFilled
  );

  return (
    <PayMethods
      payMethodsIds={payMethodsIds}
      setPayMethodsIds={setPayMethodsIds}
      isPayMethodIdsFilled={isPayMethodIdsFilled}
      setPayMethodIdsFilled={setPayMethodIdsFilled}
    />
  );
}
