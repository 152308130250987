import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useInterval } from '@/hooks/useInterval';
import { useRefState } from '@/hooks/useRefState';
import { CODE_LEN, COUNTER } from '@/modules/auth/helpers';
import { PhoneSendCodeChannel } from '@/modules/showroom/contact/types';
import { SplittedInput } from '@/ui/Input/SplittedInput';
import { LottieNoLoop } from '@/ui/LottieNoLoop';
import { CloseIcon } from '@/ui/PhoneConfirm/PhoneCodeConfirm/icons';
import { SUPPORT_EMAIl } from '@/utils/consts';
import { onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './PhoneCodeConfirm.module.scss';
import smsAnim from './sms.json';
import succeessAnim from './success.json';

const resendSteps = [PhoneSendCodeChannel.whatsapp, PhoneSendCodeChannel.call];
const allSteps = [PhoneSendCodeChannel.sms, ...resendSteps];

type Props = {
  phone: string;
  confirmPhoneSendCode: (
    phone: string,
    channel?: PhoneSendCodeChannel
  ) => Promise<unknown>;
  confirmPhone: (phone: string, code: string) => Promise<unknown>;
  isPhoneConfirmed: boolean;
  setPhoneConfirmed: Dispatch<SetStateAction<boolean>>;
  close?: () => void;
  isEdit?: boolean;
};

export function PhoneCodeConfirm({
  phone,
  confirmPhoneSendCode,
  confirmPhone,
  isPhoneConfirmed,
  setPhoneConfirmed,
  close,
  isEdit
}: Props) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [needEmailSupport, setEmailSupport] = useState(false);
  const [code, setCode] = useState('');
  const [stepIndex, setStepIndex] = useState(0);

  const phoneValue = onlyNumbers(phone, '+');
  const formattedPhone = phone;

  const resendTitle = useMemo(() => {
    const nextIndex = stepIndex;
    if (nextIndex === resendSteps.length) return '';

    return t(`auth.${resendSteps[nextIndex]}Resend`);
  }, [stepIndex, t]);

  // Counter
  const [counter, setCounter, counterRef] = useRefState<number>(COUNTER);
  useInterval(
    () => {
      const v = counterRef.current;
      if (v > 0) setCounter(v - 1);
    },
    counter > 0 ? 1000 : null
  );

  const resendCode = async () => {
    await confirmPhoneSendCode(phoneValue, resendSteps[stepIndex]);

    const newIndex = stepIndex + 1;
    setStepIndex(newIndex);
    setCounter(COUNTER);

    if (stepIndex === resendSteps.length - 1) {
      setEmailSupport(true);
    }
  };

  const onResendClick = async () => {
    setLoading(true);

    try {
      await resendCode();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  // Submit
  const isValid = !!phoneValue && code.length === CODE_LEN;

  const submit = async (codeValue: string) => {
    if (!codeValue || !phoneValue || isLoading) return;
    setLoading(true);

    try {
      await confirmPhone(phoneValue, codeValue);
      setPhoneConfirmed(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isOnlyEnter = e.key === 'Enter' && !e.shiftKey;
    if (isValid && !isLoading && isOnlyEnter) {
      submit(code);
    }
  };

  const handleCodeChange = (v: string) => {
    setCode(v);

    const isValid = !!phoneValue && v.length === CODE_LEN;
    if (isValid && !isLoading) {
      submit(v);
    }
  };

  return (
    <div className={cls.root}>
      {!isPhoneConfirmed && (
        <>
          <div className={cls.icon}>
            <LottieNoLoop animationData={smsAnim} />
          </div>

          <h1 className={cls.title}>{t('auth.enterCode')}</h1>

          <h2 className={cls.code_title}>
            <Trans
              t={t}
              i18nKey={`auth.${allSteps[stepIndex]}Confirm`}
              components={{
                span: <span />
              }}
              values={{ phone }}
            />
          </h2>

          <div className={cls.fields}>
            <SplittedInput
              value={code}
              onChange={handleCodeChange}
              length={CODE_LEN}
              onLastKeyDown={onKeyDown}
              disabled={isLoading}
              numbersOnly
            />
          </div>

          {!needEmailSupport && (
            <div className={cls.resend}>
              {counter > 0 ? (
                <p>
                  {t('auth.resendCode', {
                    seconds: counter > 9 ? counter : `0${counter}`
                  })}
                </p>
              ) : (
                <button
                  className={cls.resend_btn}
                  type="button"
                  onClick={onResendClick}
                  disabled={!phoneValue || isLoading}
                >
                  {resendTitle}
                </button>
              )}
            </div>
          )}

          {needEmailSupport && (
            <div className={cls.resend}>
              <a
                className={cls.resend_btn}
                href={
                  t('auth.supportEmail', {
                    email: SUPPORT_EMAIl,
                    phone: formattedPhone
                  }) || ''
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('auth.didntGetCode')}
              </a>
            </div>
          )}
        </>
      )}

      {isPhoneConfirmed && (
        <>
          <div className={cls.icon}>
            <LottieNoLoop animationData={succeessAnim} />
          </div>

          <h1 className={cls.title}>{t('auth.done')}</h1>

          <h2 className={cls.subtitle}>
            {isEdit ? t('auth.phoneEdited') : t('auth.phoneAdded')}
          </h2>
        </>
      )}

      <button className={cls.close_btn} onClick={close}>
        <CloseIcon />
      </button>
    </div>
  );
}
