import { useTranslation } from 'react-i18next';

import { handleText } from '@/modules/chat/Detail/Messages/Message/helpers';
import { Message } from '@/modules/showroom/advert/create/Message/Message';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

import cls from './StickyMsg.module.scss';

export function VrpStickyMsg() {
  const { t } = useTranslation();

  const detail = useUpdateVrpStore((s) => s.detail);

  const isRejected = detail?.status.title === AdvertStatusType.rejected;
  const reasonText = detail?.moderation_rejected_reason || '';

  const updatesPending = !!detail?.updates_pending_moderation;
  const updatesRejectReason = detail?.updates_reject_reason || '';

  return (
    <div className={cls.root}>
      {isRejected && reasonText && (
        <Message title={t('rejectReason') as string} red>
          <p
            dangerouslySetInnerHTML={{
              __html: handleText(reasonText)
            }}
          />
        </Message>
      )}

      {updatesRejectReason && (
        <Message title={t('rejectReason') as string} red>
          <p
            dangerouslySetInnerHTML={{
              __html: handleText(updatesRejectReason)
            }}
          />
        </Message>
      )}

      {updatesPending && (
        <Message title={t('publish.updatesPending.title') as string}>
          <p>{t('publish.updatesPending.subtitle')}</p>
        </Message>
      )}
    </div>
  );
}
