import { useTranslation } from 'react-i18next';

import { Placeholder } from '@/ui/Placeholder/Placeholder';

import empty from './empty.json';

export function EmptyPlaceholder() {
  const { t } = useTranslation();

  return (
    <Placeholder
      lottieData={empty}
      title={t('chat.empty.title')}
      text={t('chat.empty.text')}
    />
  );
}
