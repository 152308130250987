import { CallbackRow } from '@/modules/callback/CallbackPage/CallbackRow/CallbackRow';
import { CallbackItem } from '@/modules/callback/types';

type Props = {
  list: CallbackItem[];
};

export function CallbackList({ list }: Props) {
  return (
    <>
      {list.map((el) => (
        <CallbackRow data={el} key={el.id} />
      ))}
    </>
  );
}
