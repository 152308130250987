export function ChevronIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.30244 9.59239C4.10822 9.40233 4 9.14901 4 8.85724C4 8.26884 4.45637 7.80919 5.04317 7.80919C5.3382 7.80919 5.61029 7.9198 5.80107 8.12539L12.4459 14.9261H11.5622L18.2006 8.12539C18.3972 7.91818 18.6709 7.80919 18.9569 7.80919C19.542 7.80919 20 8.26884 20 8.85724C20 9.15062 19.8935 9.40396 19.696 9.59239L12.8179 16.6314C12.5895 16.873 12.3117 16.9967 12.0045 17C11.6916 17 11.4195 16.8746 11.1838 16.6314L4.30244 9.59239Z"
        fill="currentColor"
      />
    </svg>
  );
}
