import { Docs } from '@/modules/showroom/advert/rent/update/AdditionalInfo/Docs/Docs';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function DocsWrap() {
  const docsIds = useRentUpdateAdvertStore((s) => s.docsIds);
  const setDocsIds = useRentUpdateAdvertStore((s) => s.setDocsIds);
  const isDocsIdsFilled = useRentUpdateAdvertStore((s) => s.isDocsIdsFilled);
  const setDocsIdsFilled = useRentUpdateAdvertStore((s) => s.setDocsIdsFilled);

  return (
    <Docs
      docsIds={docsIds}
      setDocsIds={setDocsIds}
      isDocsIdsFilled={isDocsIdsFilled}
      setDocsIdsFilled={setDocsIdsFilled}
    />
  );
}
