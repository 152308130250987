import { nanoid } from 'nanoid';

import { Nullable } from '@/app/types';
import {
  NoticationsFormEmail,
  NoticationsEmailObj
} from '@/modules/notifications/types';

export const MAX_EMAILS_LEN = 5;

export type SetterType = (
  value: React.SetStateAction<NoticationsFormEmail[]>
) => void;

function removeEmailId(emailObj: NoticationsFormEmail): NoticationsEmailObj {
  return { email: emailObj.email };
}

export function prepareEmailsForSave(emails: NoticationsFormEmail[]) {
  const filtered = emails.filter((v) => !!v.email);
  const isEmpty = filtered.length <= 0;
  if (isEmpty) return [];
  return filtered.map((v) => removeEmailId(v));
}

export function getInitialArr(): NoticationsFormEmail[] {
  return [{ email: '', id: nanoid() }];
}

export function setInitialValue(
  initialValue: Nullable<string[]>,
  setter: SetterType
) {
  if (initialValue && initialValue.length > 0) {
    setter(initialValue.map((v) => ({ email: v, id: nanoid() })));
  } else {
    setter(getInitialArr());
  }
}
