import { useState } from 'react';

import { Nullable } from '@/app/types';
import { useAccountStore } from '@/modules/accounts/store';
import { PromotionPackage } from '@/modules/promotion/types';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import {
  updateAdvertAutoExtension,
  updateAdvertPromoPackage
} from '@/modules/showroom/advert/update/api';
import { showAlert } from '@/utils/network';

export const usePromotionsList = (
  advertId: MyAdvert['id'],
  onPublishToggle?: () => void
) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isActivateOpen, setActivateOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Nullable<PromotionPackage>>(null);

  const fetchBalance = useAccountStore((s) => s.fetchBalance);

  const [autoExtension, setAutoExtension] = useState(true);
  const activatePromo = async (
    promoPackage: PromotionPackage,
    onSuccess?: () => void
  ) => {
    setLoading(true);

    try {
      await updateAdvertPromoPackage(advertId, promoPackage.id);
      await updateAdvertAutoExtension(advertId, autoExtension);
      if (onPublishToggle) onPublishToggle();
      if (onSuccess) onSuccess();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onPromotionClick = async (promoPackage: PromotionPackage) => {
    fetchBalance();
    setSelected(promoPackage);
    setActivateOpen(true);
  };

  const onActivateClick = async () => {
    if (!selected) return;

    activatePromo(selected, () => {
      setActivateOpen(false);
      setSelected(null);
    });
  };

  return {
    isLoading,
    isActivateOpen,
    autoExtension,
    setAutoExtension,
    onPromotionClick,
    onActivateClick,
    selected,
    setActivateOpen
  };
};
