import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRefState } from '@/hooks/useRefState';
import { bytesToMB } from '@/utils/format';
import { showAlert } from '@/utils/network';

import {
  UploadAcceptType,
  acceptMap,
  UploadItem,
  handleVideo,
  handleDoc,
  handleImage
} from './helpers';

export type Props = {
  type: UploadAcceptType;
  close: () => void;
  onUpload?: (v: UploadItem[]) => void;
  onUploading?: (uploading: boolean) => void;
  videoPoster?: boolean;
  maxFilesLen?: number;
  maxFileLenSlice?: number;
  maxImgSizeMb?: number;
  maxLengthError?: string;
};

export const UploadFiles: React.FC<Props> = ({
  type,
  onUpload,
  onUploading,
  close,
  videoPoster,
  maxFilesLen,
  maxFileLenSlice,
  maxImgSizeMb,
  maxLengthError
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);

  const [isClicked, setClicked, isClickedRef] = useRefState(false);
  const [isUploading, setUploading] = useState(false);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) return;
    setUploading(true);

    const f = e.target.files ? Array.from(e.target.files) : null;
    e.target.value = '';

    let files = f || [];

    // Files len
    if (maxFilesLen && files.length > maxFilesLen) {
      const sliceEnd = maxFileLenSlice || maxFilesLen;
      files = files.slice(0, sliceEnd);

      if (maxLengthError)
        showAlert({
          type: 'error',
          text: maxLengthError
        });
    }

    // Img size
    if (maxImgSizeMb && (type === 'media' || type === 'image')) {
      const imgs = files.filter((f) => f.type.startsWith('image/'));
      const invalidSizeImgs = imgs.filter(
        (img) => bytesToMB(img.size) >= maxImgSizeMb
      );
      const invalidNames = invalidSizeImgs.map((f) => f.name);

      if (invalidSizeImgs.length) {
        const errorText =
          invalidSizeImgs.length > 1
            ? t('filesTooLarge', {
                files: invalidNames.join(', '),
                maxFileSize: maxImgSizeMb
              })
            : t('fileTooLarge', {
                maxFileSize: maxImgSizeMb
              });

        showAlert({
          type: 'error',
          text: errorText
        });

        files = files.filter((f) => !invalidNames.includes(f.name));
      }
    }

    if (!files || files.length <= 0) {
      close();
      setUploading(false);
      return;
    }

    try {
      // Media (photos + videos)
      if (type === 'media') {
        const resolvedImgs: UploadItem[] = [];
        const resolvedVideos: UploadItem[] = [];
        const imgFiles: File[] = [];
        const videoFiles: File[] = [];

        files.forEach((fl) => {
          const isImg = fl.type.startsWith('image');
          if (isImg) {
            imgFiles.push(fl);
          } else {
            videoFiles.push(fl);
          }
        });

        const videoPromises = videoFiles.map((fl) =>
          handleVideo(fl, videoPoster)
        );
        const videoResults = await Promise.allSettled(videoPromises);
        videoResults.forEach((v) => {
          if (v.status == 'fulfilled') {
            resolvedVideos.push(v.value);
          }
        });

        const imgPromises = imgFiles.map((fl) => handleImage({ file: fl }));
        const imgResults = await Promise.allSettled(imgPromises);
        imgResults.forEach((v) => {
          if (v.status == 'fulfilled') {
            resolvedImgs.push(v.value);
          }
        });

        if (onUpload) onUpload([...resolvedVideos, ...resolvedImgs]);
        setUploading(false);
      }
      // Images
      else if (type === 'image') {
        const promises = Array.from(files).map((fl) =>
          handleImage({ file: fl })
        );
        const results = await Promise.allSettled(promises);
        const resolved: UploadItem[] = [];
        results.forEach((v) => {
          if (v.status == 'fulfilled') {
            resolved.push(v.value);
          }
        });
        if (onUpload) onUpload(resolved);
        setUploading(false);
      }
      // Video
      else if (type === 'video') {
        const promises = Array.from(files).map((fl) =>
          handleVideo(fl, videoPoster)
        );
        const results = await Promise.allSettled(promises);
        const resolved: UploadItem[] = [];
        results.forEach((v) => {
          if (v.status == 'fulfilled') {
            resolved.push(v.value);
          }
        });
        if (onUpload) onUpload(resolved);
        setUploading(false);
      }
      // Docs / files
      else {
        const promises = Array.from(files).map((fl) => handleDoc(fl));
        const results = await Promise.allSettled(promises);
        const resolved: UploadItem[] = [];
        results.forEach((v) => {
          if (v.status == 'fulfilled') {
            resolved.push(v.value);
          }
        });
        if (onUpload) onUpload(resolved);
        setUploading(false);
      }
    } catch (error) {
      showAlert({ error });

      if (ref && ref.current && !isClicked) {
        ref.current.click();
      }

      setUploading(false);
    }
  };

  useLayoutEffect(() => {
    if (ref && ref.current && !isClickedRef.current) {
      ref.current.click();
      setClicked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onUploading) {
      onUploading(isUploading);
    }

    return () => {
      if (onUploading) onUploading(false);
    };
  }, [onUploading, isUploading]);

  return (
    <input
      style={{ display: 'none' }}
      ref={ref}
      type="file"
      accept={acceptMap[type]}
      onChange={handleChange}
      disabled={isUploading}
      multiple
    />
  );
};
