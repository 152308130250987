import Tooltip from 'rc-tooltip';
import { useTranslation } from 'react-i18next';

import { Address } from '@/modules/showroom/advert/create/Address/Address';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { InfoIcon } from '@/modules/showroom/advert/create/Phone/icons';
import phoneCls from '@/modules/showroom/advert/create/Phone/Phone.module.scss';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { rcTooltipProps } from '@/utils/consts';

export function AddressWrap() {
  const { t } = useTranslation();

  const addressId = useRentUpdateAdvertStore((s) => s.addressId);
  const setAddressId = useRentUpdateAdvertStore((s) => s.setAddressId);
  const isAddressFilled = useRentUpdateAdvertStore((s) => s.isAddressFilled);
  const setAddressFilled = useRentUpdateAdvertStore((s) => s.setAddressFilled);

  const deliveryToAirport = useRentUpdateAdvertStore(
    (s) => s.deliveryToAirport
  );
  const setDeliveryToAirport = useRentUpdateAdvertStore(
    (s) => s.setDeliveryToAirport
  );
  const deliveryToCustomer = useRentUpdateAdvertStore(
    (s) => s.deliveryToCustomer
  );
  const setDeliveryToCustomer = useRentUpdateAdvertStore(
    (s) => s.setDeliveryToCustomer
  );

  return (
    <Address
      title={t('delivery.title') as string}
      addressId={addressId}
      setAddressId={setAddressId}
      isAddressFilled={isAddressFilled}
      setAddressFilled={setAddressFilled}
      nextSection={SCROLLTO.booking}
    >
      <div className={phoneCls.flags}>
        <div className={phoneCls.flag}>
          <Checkbox
            id={`delivery-customer-${addressId}`}
            checked={deliveryToCustomer}
            onChange={() => setDeliveryToCustomer(!deliveryToCustomer)}
          >
            {t('delivery.customer.title')}
          </Checkbox>

          <Tooltip
            placement="top"
            overlay={t('delivery.customer.subtitle')}
            overlayClassName={phoneCls.tooltip_overlay}
            {...rcTooltipProps}
          >
            <div className={phoneCls.info}>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>

        <div className={phoneCls.flag}>
          <Checkbox
            id={`delivery-airport-${addressId}`}
            checked={deliveryToAirport}
            onChange={() => setDeliveryToAirport(!deliveryToAirport)}
          >
            {t('delivery.airport.title')}
          </Checkbox>

          <Tooltip
            placement="top"
            overlay={t('delivery.airport.subtitle')}
            overlayClassName={phoneCls.tooltip_overlay}
            {...rcTooltipProps}
          >
            <div className={phoneCls.info}>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
      </div>
    </Address>
  );
}
