import { axios } from '@/app/api';
import { CALLBACK_API_ROUTES } from '@/modules/callback/api';
import {
  MarkCallbackAsViewedParams,
  MarkCallbackAsViewedReq
} from '@/modules/callback/types';

export function changeCallbackStatus(enabled: boolean) {
  return axios.patch<unknown>(
    `${CALLBACK_API_ROUTES.changeStatus}?s=${enabled}`
  );
}

export function changeCallbackStatusById(
  callback_id: number,
  enabled: boolean
) {
  return axios.patch<unknown>(
    `${CALLBACK_API_ROUTES.changeCbStatus(callback_id)}?s=${enabled}`
  );
}

export function markCallbackAsViewed(
  req?: MarkCallbackAsViewedReq,
  params?: MarkCallbackAsViewedParams
) {
  return axios.patch<unknown>(CALLBACK_API_ROUTES.markViewed, req, { params });
}
