import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import cls from './Message.module.scss';

type Props = {
  title?: string;
  children?: React.ReactNode;
  red?: boolean;
};

export function Message({ title, children, red }: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn(cls.root, { [cls.root_red]: red })}>
      <b className={cls.title}>{title || t('common.attention')}</b>
      {children}
    </div>
  );
}
