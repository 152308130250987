export function CloseIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 22.6411C5.84372 23.1157 6.66308 23.1096 7.12564 22.6471L14.0021 15.7706L20.8746 22.6451C21.3452 23.1157 22.1637 23.1229 22.6403 22.6391C23.117 22.1533 23.119 21.3532 22.6484 20.8805L15.7759 13.9968L22.6484 7.12437C23.119 6.65377 23.1262 5.84446 22.6403 5.36782C22.1545 4.88201 21.3452 4.88 20.8746 5.35977L14.0021 12.2322L7.12564 5.35776C6.66308 4.88804 5.83455 4.87083 5.35791 5.36581C4.88328 5.85161 4.88932 6.6598 5.35187 7.12236L12.2284 13.9968L5.35187 20.8845C4.88932 21.345 4.87411 22.1644 5.35791 22.6411Z"
        fill="currentColor"
      />
    </svg>
  );
}
