import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouteUnload } from '@/hooks/useRouteUnload';
import { scrollToBottom } from '@/modules/showroom/advert/create/helpers';
import { RentStickyBar } from '@/modules/showroom/advert/create/StickyBar/RentStickyBar';
import { RentStickyMsg } from '@/modules/showroom/advert/create/StickyMsg/RentStickyMsg';
import { RentAdvertAuthorDetail } from '@/modules/showroom/advert/rent/detail/types';
import { useRentDocsStore } from '@/modules/showroom/advert/rent/docs/store';
import { useRentPayMethodsStore } from '@/modules/showroom/advert/rent/pay-methods/store';
import { AdditionalInfo } from '@/modules/showroom/advert/rent/update/AdditionalInfo/AdditionalInfo';
import { BrandWrap } from '@/modules/showroom/advert/rent/update/BrandWrap';
import { CheckList } from '@/modules/showroom/advert/rent/update/CheckList';
import { HeaderWrap } from '@/modules/showroom/advert/rent/update/HeaderWrap';
import { ModelWrap } from '@/modules/showroom/advert/rent/update/ModelWrap';
import { SpecsWrap } from '@/modules/showroom/advert/rent/update/SpecsWrap';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { TransportBrand } from '@/modules/transport/types';
import { EditorContainer } from '@/ui/layouts/EditorLayout/EditorContainer';
import { EditorSections } from '@/ui/layouts/EditorLayout/EditorSections';
import { EditorSectionsWrap } from '@/ui/layouts/EditorLayout/EditorSectionsWrap';
import { isLocalDev } from '@/utils/consts';

type Props = {
  detail: RentAdvertAuthorDetail;
  brands: TransportBrand[];
};

export function UpdateRentAdvert({ detail, brands }: Props) {
  const { t } = useTranslation();

  const storeDetail = useRentUpdateAdvertStore((s) => s.detail);
  const handleDetail = useRentUpdateAdvertStore((s) => s.handleDetail);

  useEffect(() => {
    handleDetail(detail);
  }, [handleDetail, detail]);

  const brand = useRentUpdateAdvertStore((s) => s.brand);
  const model = useRentUpdateAdvertStore((s) => s.model);
  const interiorColor = useRentUpdateAdvertStore((s) => s.interiorColor);
  const isSpecsFilled = useRentUpdateAdvertStore((s) => s.isSpecsFilled);
  const showSpecs = !!(brand && model);

  const onStepChange = useCallback(() => {
    if (!isSpecsFilled) scrollToBottom();
  }, [isSpecsFilled]);

  const isRejected = detail?.status.title === AdvertStatusType.rejected;
  const updatesRejectReason = detail?.updates_reject_reason || '';
  const isFullScrollStickyMsg = isRejected || !!updatesRejectReason;

  // Handle page leave
  const showPageLeaveWarn = useRentUpdateAdvertStore(
    (s) => s.showPageLeaveWarn
  );
  useRouteUnload(isLocalDev ? false : showPageLeaveWarn, t('leaveWarn'));

  // Fetch lists
  const fetchDocs = useRentDocsStore((s) => s.fetchData);
  const fetchPayMethods = useRentPayMethodsStore((s) => s.fetchData);
  useEffect(() => {
    fetchDocs();
    fetchPayMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!storeDetail) return <></>;

  return (
    <>
      <EditorContainer>
        <CheckList />

        <EditorSectionsWrap>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 36 }}>
            <EditorSections>
              <div
                className="box box-first"
                style={{
                  marginBottom: isSpecsFilled && interiorColor ? 0 : 80
                }}
              >
                <HeaderWrap />
                <BrandWrap brands={brands} />
                {brand && <ModelWrap brand={brand} />}

                {showSpecs && (
                  <SpecsWrap
                    decodeInfo={null}
                    brand={brand}
                    model={model}
                    onStepChange={onStepChange}
                  />
                )}
              </div>
            </EditorSections>

            {!isFullScrollStickyMsg && <RentStickyMsg />}
          </div>

          <EditorSections>{isSpecsFilled && <AdditionalInfo />}</EditorSections>
        </EditorSectionsWrap>

        {isFullScrollStickyMsg && <RentStickyMsg />}
      </EditorContainer>

      <RentStickyBar />
    </>
  );
}
