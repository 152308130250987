import { arrayMoveImmutable } from 'array-move';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortEnd
} from 'react-sortable-hoc';

import { PhotoType } from '@/app/types';
import { handleImage } from '@/modules/files/upload/helpers';
import { UploadImages } from '@/modules/files/upload/UploadImages/UploadImages';
import {
  MAX_PHOTOS_LEN,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Previews } from '@/ui/Previews/Previews';
import { Spinner } from '@/ui/Spinner/Spinner';
import { showAlert } from '@/utils/network';

import { DeleteIcon, EyeIcon, BinIcon } from './icons';
import cls from './Photos.module.scss';

const FILE_SIZE_MB = 20;

// Element
interface ElementProps extends SortableElementProps {
  value: PhotoType;
  onPreviewClick: (url: string) => void;
  onRemoveClick: (url: string) => void;
  replaceLocalPhoto: (uploaded: PhotoType, localUrl: string) => void;
  disabledPhotoRemove?: boolean;
}

const SortableItem = SortableElement<ElementProps>(
  ({
    value,
    onPreviewClick,
    onRemoveClick,
    replaceLocalPhoto,
    disabledPhotoRemove
  }: ElementProps) => {
    const [loading, setLoading] = useState(false);

    const upload = useCallback(
      async (file: File, localUrl: string) => {
        if (loading) return;
        setLoading(true);

        try {
          const r = await handleImage({
            file
          });

          replaceLocalPhoto(
            {
              localUrl,
              photo_url: r.response.url,
              thumbnail_url: r.response.url,
              file: null
            },
            localUrl
          );

          setLoading(false);
        } catch (error) {
          showAlert({ error });
          onRemoveClick(localUrl);
          setLoading(false);
        }
      },
      [onRemoveClick, replaceLocalPhoto, loading]
    );

    useEffect(() => {
      if (value.file && value.localUrl) {
        upload(value.file, value.localUrl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <li className={cls.li}>
        <div className={cls.photo_cell}>
          {value.file ? (
            <ImgSkeleton
              className={cls.photo_cell_img}
              draggable={false}
              src={value.localUrl || value.thumbnail_url || value.photo_url}
              alt=""
              imgProxyWidth={165}
              imgProxyHeight={124}
            />
          ) : (
            <ImgSkeleton
              className={cls.photo_cell_img}
              draggable={false}
              src={value.thumbnail_url || value.photo_url}
              alt=""
              imgProxyWidth={165}
              imgProxyHeight={124}
            />
          )}

          <button
            className={cls.action_btn}
            type="button"
            onClick={() => onPreviewClick(value.photo_url)}
            onTouchEnd={() => onPreviewClick(value.photo_url)}
            disabled={!!value.file || loading}
          >
            <EyeIcon />
          </button>
          <button
            className={cls.action_btn}
            type="button"
            onClick={() => onRemoveClick(value.photo_url)}
            onTouchEnd={() => onRemoveClick(value.photo_url)}
            disabled={disabledPhotoRemove || !!value.file || loading}
          >
            <DeleteIcon />
          </button>

          {(!!value.file || loading) && (
            <span className={cls.spinner}>
              <Spinner color="var(--clr-white)" size={40} />
            </span>
          )}
        </div>
      </li>
    );
  }
);

// Container
interface ContainerProps extends SortableContainerProps {
  items: PhotoType[];
  onLocalUpload: (v: File[]) => void;
  replaceLocalPhoto: (uploaded: PhotoType, localUrl: string) => void;
  onPreviewClick: (url: string) => void;
  onRemoveClick: (url: string) => void;
  disabledPhotoRemove?: boolean;
}

const SortableList = SortableContainer<ContainerProps>(
  ({ items, onLocalUpload, ...restProps }: ContainerProps) => {
    const availableLen = MAX_PHOTOS_LEN - items.length;

    return (
      <ul className={cls.list}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${
              value.localUrl || value.thumbnail_url || value.photo_url
            }`}
            index={index}
            value={value}
            {...restProps}
          />
        ))}

        {availableLen > 0 && (
          <li>
            <UploadImages
              onLocalUpload={onLocalUpload}
              maxFileSizeMb={FILE_SIZE_MB}
              maxFilesLen={MAX_PHOTOS_LEN}
              maxFileLenSlice={availableLen}
              square
            />
          </li>
        )}
      </ul>
    );
  }
);

// Photos
type Props = {
  photos: CreateAdvertSlice['photos'];
  setPhotos: CreateAdvertSlice['setPhotos'];
  addPhotos: CreateAdvertSlice['addPhotos'];
  removePhoto: CreateAdvertSlice['removePhoto'];
  replaceLocalPhoto: CreateAdvertSlice['replaceLocalPhoto'];
  isPhotosFilled: CreateAdvertSlice['isPhotosFilled'];
  setPhotosFilled: CreateAdvertSlice['setPhotosFilled'];
  disabledPhotoRemove?: boolean;
};

export function Photos(props: Props) {
  const { t } = useTranslation();

  const {
    photos,
    addPhotos,
    removePhoto,
    replaceLocalPhoto,
    setPhotos,
    isPhotosFilled,
    setPhotosFilled,
    disabledPhotoRemove
  } = props;

  const isMax = photos.length >= MAX_PHOTOS_LEN;
  const availableLen = MAX_PHOTOS_LEN - photos.length;

  const onLocalUpload = (files: File[]) => {
    if (isMax) return;

    const sliced = files.slice(0, availableLen);
    if (sliced.length <= 0) return;

    addPhotos(
      sliced.map((f) => {
        const localUrl = URL.createObjectURL(f);
        return {
          localUrl,
          photo_url: localUrl,
          thumbnail_url: localUrl,
          medium_url: localUrl,
          file: f
        };
      })
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const hasLoadingPhotos = photos.some((p) => !!p.file);
    if (oldIndex !== newIndex && !hasLoadingPhotos) {
      setPhotos(arrayMoveImmutable(photos, oldIndex, newIndex));
    }
  };

  const onButtonClick = () => {
    setPhotosFilled(true);
    scrollToSection(SCROLLTO.complectation, 100);
  };

  const removeAllPhotos = () => {
    setPhotos([]);
  };

  // Preview
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const onPreviewClick = (photoUrl: string) => {
    setPreviewUrl(photoUrl);
    setPreviewOpen(true);
  };

  return (
    <div className={cls.root} id={SCROLLTO.photo}>
      <div className="box">
        <div className={cls.header}>
          <h1 className={cls.title}>
            <Trans
              t={t}
              i18nKey="photosTitleCount"
              components={{
                countWrap: <span />
              }}
              values={{ index: photos.length, count: MAX_PHOTOS_LEN }}
            />
          </h1>

          {photos.length > 0 && (
            <Button
              onClick={removeAllPhotos}
              size="compact"
              variant="secondary"
              color="black"
              gap={4}
            >
              <BinIcon />
              {t('showroom.photos.removeAll')}
            </Button>
          )}
        </div>

        <p className={cls.desc}>
          <span>{t('publish.photoRequired')}</span>
          <span>{t('upload.image.params')}</span>
          {t('upload.image.aspectRatio')}
        </p>

        {photos.length > 0 ? (
          <SortableList
            items={photos}
            onSortEnd={onSortEnd}
            axis="xy"
            onLocalUpload={onLocalUpload}
            replaceLocalPhoto={replaceLocalPhoto}
            onPreviewClick={onPreviewClick}
            onRemoveClick={removePhoto}
            disabledPhotoRemove={disabledPhotoRemove}
          />
        ) : (
          <div className={cls.upload}>
            <UploadImages
              onLocalUpload={onLocalUpload}
              maxFileSizeMb={FILE_SIZE_MB}
              maxFilesLen={MAX_PHOTOS_LEN}
              maxFileLenSlice={availableLen}
            />
          </div>
        )}

        {!isPhotosFilled && (
          <div className={cls.btn}>
            <Button
              variant={photos.length > 0 ? 'primary' : 'secondary'}
              color={photos.length > 0 ? 'blue' : 'black'}
              onClick={onButtonClick}
              fullWidth
            >
              {t(photos.length > 0 ? 'common.continue' : 'common.skip')}
            </Button>
          </div>
        )}
      </div>

      {photos.length > 0 && (
        <Previews
          photos={photos.map((p) => p.photo_url)}
          isOpen={isPreviewOpen}
          close={() => setPreviewOpen(false)}
          initialUrl={previewUrl}
        />
      )}
    </div>
  );
}
