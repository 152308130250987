import { useTranslation } from 'react-i18next';

import { Skeleton } from '@/ui/Skeleton';

import cls from './CreditRequest.module.scss';

export function CreditRequestSkeleton() {
  const { t } = useTranslation();

  return (
    <div className={cls.root}>
      <div className={cls.col}>
        <div className={cls.img}>
          <Skeleton className={cls.img_inner} />
        </div>
      </div>

      <div className={cls.col}>
        <div className={cls.advert_wrap}>
          <p className={cls.advert}>
            <Skeleton width={180} height={24} />
          </p>
        </div>
        <Skeleton width={320} height={20} />
        <div className={cls.date}>
          <Skeleton width={160} height={20} />
        </div>
      </div>

      <div className={cls.col}>
        <p className={cls.col_label}>{t('credits.req.aboutLoan')}</p>
        <div className={cls.row}>
          <Skeleton width={124} height={20} />
          <Skeleton width={88} height={20} />
        </div>
        <div className={cls.row}>
          <Skeleton width={124} height={20} />
          <Skeleton width={88} height={20} />
        </div>
        <div className={cls.row}>
          <Skeleton width={124} height={20} />
          <Skeleton width={88} height={20} />
        </div>
      </div>

      <div className={cls.col}>
        <p className={cls.col_label}>{t('credits.req.aboutLead')}</p>

        <div className={cls.contact}>
          <Skeleton width={124} height={20} />
        </div>
        <div className={cls.contact}>
          <Skeleton width={124} height={20} />
        </div>
        <div className={cls.contact}>
          <Skeleton width={124} height={20} />
        </div>
      </div>
    </div>
  );
}
