import { Complectation } from '@/modules/showroom/advert/create/Complectation/Complectation';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';

export function ComplectationWrap() {
  const modification = useRentUpdateAdvertStore((s) => s.modification);
  const isCustomComplectation = useRentUpdateAdvertStore(
    (s) => s.isCustomComplectation
  );
  const setCustomComplectation = useRentUpdateAdvertStore(
    (s) => s.setCustomComplectation
  );
  const complectationId = useRentUpdateAdvertStore((s) => s.complectationId);
  const setComplectationId = useRentUpdateAdvertStore(
    (s) => s.setComplectationId
  );
  const setComplectationOptionsItemsIds = useRentUpdateAdvertStore(
    (s) => s.setComplectationOptionsItemsIds
  );
  const complectationOptionsItemsIds = useRentUpdateAdvertStore(
    (s) => s.complectationOptionsItemsIds
  );
  const isComplectationFilled = useRentUpdateAdvertStore(
    (s) => s.isComplectationFilled
  );
  const setComplectationFilled = useRentUpdateAdvertStore(
    (s) => s.setComplectationFilled
  );

  return (
    <>
      {modification && (
        <Complectation
          nextSection={SCROLLTO.descr}
          modification={modification}
          isCustomComplectation={isCustomComplectation}
          setCustomComplectation={setCustomComplectation}
          complectationId={complectationId}
          setComplectationId={setComplectationId}
          complectationOptionsItemsIds={complectationOptionsItemsIds}
          setComplectationOptionsItemsIds={setComplectationOptionsItemsIds}
          isComplectationFilled={isComplectationFilled}
          setComplectationFilled={setComplectationFilled}
        />
      )}
    </>
  );
}
