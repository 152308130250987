/* eslint-disable import/no-duplicates */

import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { useMemo } from 'react';

import { getDateAgo } from '@/utils/date';

export function useDefaultPeriod(): { from: Date; to: Date } {
  const period = useMemo(
    () => ({
      from: startOfDay(getDateAgo(30)),
      to: endOfDay(new Date())
    }),
    []
  );

  return period;
}
