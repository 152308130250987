export function MoreIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10C19.1 10 20 10.9 20 12C20 13.1 19.1 14 18 14C16.9 14 16 13.1 16 12C16 10.9 16.9 10 18 10ZM12 14C10.9 14 10 13.1 10 12C10 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14ZM6 14C4.9 14 4 13.1 4 12C4 10.9 4.9 10 6 10C7.1 10 8 10.9 8 12C8 13.1 7.1 14 6 14Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SearchIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85384 3.20825C13.5242 3.20825 16.4997 6.18369 16.4997 9.85408C16.4997 11.3585 15.9998 12.7462 15.157 13.8601L18.9812 17.6851C19.3392 18.043 19.3392 18.6235 18.9812 18.9814C18.6232 19.3394 18.0428 19.3394 17.6848 18.9814L13.8599 15.1573C12.746 16 11.3583 16.4999 9.85384 16.4999C6.18345 16.4999 3.20801 13.5245 3.20801 9.85408C3.20801 6.18369 6.18345 3.20825 9.85384 3.20825ZM9.85384 5.04159C7.19597 5.04159 5.04134 7.19621 5.04134 9.85408C5.04134 12.512 7.19597 14.6666 9.85384 14.6666C12.5117 14.6666 14.6663 12.512 14.6663 9.85408C14.6663 7.19621 12.5117 5.04159 9.85384 5.04159Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LockIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9997 1.57153C13.6033 1.57153 15.714 3.68219 15.714 6.28582L15.7156 7.87511C16.3778 7.91615 16.8003 8.03202 17.2358 8.26494C17.7569 8.54361 18.1705 8.95723 18.4492 9.47828C18.7518 10.0442 18.8569 10.5881 18.8569 11.6647V16.6212C18.8569 17.6978 18.7518 18.2417 18.4492 18.8076C18.1705 19.3287 17.7569 19.7423 17.2358 20.021C16.6699 20.3236 16.126 20.4287 15.0494 20.4287H6.95001C5.87344 20.4287 5.32955 20.3236 4.76361 20.021C4.24256 19.7423 3.82894 19.3287 3.55027 18.8076C3.24761 18.2417 3.14258 17.6978 3.14258 16.6212V11.6647C3.14258 10.5881 3.24761 10.0442 3.55027 9.47828C3.82894 8.95723 4.24256 8.54361 4.76361 8.26494C5.1993 8.03193 5.62193 7.91606 6.28461 7.87506L6.28544 6.28582C6.28544 3.68219 8.39609 1.57153 10.9997 1.57153ZM15.0494 9.42868H6.95001C6.11067 9.42868 5.81185 9.48638 5.5047 9.65065C5.25749 9.78286 5.06819 9.97216 4.93598 10.2194C4.77171 10.5265 4.71401 10.8253 4.71401 11.6647V16.6212C4.71401 17.4606 4.77171 17.7594 4.93598 18.0666C5.06819 18.3138 5.25749 18.5031 5.5047 18.6353C5.81185 18.7995 6.11067 18.8572 6.95001 18.8572H15.0494C15.8888 18.8572 16.1876 18.7995 16.4947 18.6353C16.7419 18.5031 16.9313 18.3138 17.0635 18.0666C17.2277 17.7594 17.2854 17.4606 17.2854 16.6212V11.6647C17.2854 10.8253 17.2277 10.5265 17.0635 10.2194C16.9313 9.97216 16.7419 9.78286 16.4947 9.65065C16.1876 9.48638 15.8888 9.42868 15.0494 9.42868ZM10.9997 11.7858C11.4337 11.7858 11.7854 12.1376 11.7854 12.5715V14.9287C11.7854 15.3626 11.4337 15.7144 10.9997 15.7144C10.5658 15.7144 10.214 15.3626 10.214 14.9287V12.5715C10.214 12.1376 10.5658 11.7858 10.9997 11.7858ZM10.9997 3.14296C9.26397 3.14296 7.85686 4.55007 7.85686 6.28582V7.85725H14.1426V6.28582C14.1426 4.55007 12.7355 3.14296 10.9997 3.14296Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RemoveIcon(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9997 0.916748C13.0097 0.916748 14.3075 1.92149 14.6526 3.79204C14.6562 3.81156 14.6632 3.83103 14.6659 3.85045L19.3413 3.85008C19.797 3.85008 20.1663 4.21945 20.1663 4.67508C20.1663 5.09567 19.8516 5.44275 19.4448 5.49365L19.3413 5.50008H18.3321L17.0673 16.8865C16.9231 18.1784 15.8688 19.1681 14.5867 19.2452L14.4253 19.2501H7.57403C6.21995 19.2501 5.08225 18.2323 4.9321 16.8865L3.66176 5.50008H2.65801C2.20237 5.50008 1.83301 5.13072 1.83301 4.67508C1.83301 4.2545 2.14773 3.90742 2.55452 3.85651L2.65801 3.85008L7.34426 3.85103C7.34197 3.85071 7.33967 3.8504 7.33737 3.85008C7.33962 3.82863 7.34272 3.81361 7.3467 3.79204C7.69186 1.92149 8.98966 0.916748 10.9997 0.916748ZM16.6766 5.50008H5.32001L6.57192 16.7036C6.62074 17.1411 6.94476 17.4872 7.35979 17.5772L7.46534 17.5943L7.57403 17.6001H14.4253C14.9389 17.6001 15.3705 17.214 15.4274 16.7036L16.6766 5.50008ZM10.9997 6.87508C11.4203 6.87508 11.7673 7.18981 11.8182 7.59659L11.8247 7.70008V15.2167C11.8247 15.6724 11.4553 16.0417 10.9997 16.0417C10.5791 16.0417 10.232 15.727 10.1811 15.3202L10.1747 15.2167V7.70008C10.1747 7.24445 10.544 6.87508 10.9997 6.87508ZM14.1666 6.87661C14.5864 6.90221 14.9136 7.23747 14.9397 7.64661L14.9398 7.75029L14.4815 15.267C14.4537 15.7217 14.0626 16.0679 13.6078 16.0402C13.188 16.0146 12.8607 15.6794 12.8347 15.2702L12.8345 15.1665L13.2929 7.64987C13.3206 7.19508 13.7118 6.84888 14.1666 6.87661ZM8.69376 7.54697L8.70648 7.64987L9.16481 15.1665C9.19254 15.6213 8.84634 16.0125 8.39155 16.0402C7.97175 16.0658 7.60616 15.7728 7.53058 15.3699L7.51787 15.267L7.05954 7.75029C7.03181 7.2955 7.37801 6.90434 7.8328 6.87661C8.2526 6.85101 8.61819 7.14403 8.69376 7.54697ZM10.9997 2.56675C9.86098 2.56675 9.26488 2.95779 9.02413 3.84808L9.01417 3.85008L12.9749 3.85088L12.9755 3.84895C12.7349 2.95804 12.1387 2.56675 10.9997 2.56675Z"
        fill="currentColor"
      />
    </svg>
  );
}
