import { StateCreator } from 'zustand';

import { Nullable, PhotoType } from '@/app/types';
import { RegSpec } from '@/modules/regional-specs/types';
import {
  CreateAdvertSlice,
  CreateAdvertStore,
  SpecStep
} from '@/modules/showroom/advert/create/types';
import { UpdateAdvertContact } from '@/modules/showroom/advert/update/types';
import {
  TransportType,
  TransportBrand,
  TransportModel,
  TransportOwner
} from '@/modules/transport/types';
import { getInfoByVin } from '@/modules/vin/api';
import { GetVinInfoReq } from '@/modules/vin/types';
import { showAlert } from '@/utils/network';

export const makeCreateAdvertSlice: StateCreator<
  CreateAdvertStore,
  [],
  [],
  CreateAdvertSlice
> = (set, get) => ({
  showPageLeaveWarn: true,
  setShowPageLeaveWarn: (showPageLeaveWarn) => set({ showPageLeaveWarn }),
  lastSectionVisible: false,
  setLastSectionVisible: (lastSectionVisible) => set({ lastSectionVisible }),
  advertId: null,
  setAdvertId: (advertId) => set({ advertId }),
  vin: '',
  setVin: (vin: string) => set({ vin }),
  isDecoding: false,
  decodeVin: async (vin: string) => {
    set({ isDecoding: true });

    try {
      const req: GetVinInfoReq = { vin };
      const r = await getInfoByVin(req);
      const decodeInfo = r.data;
      if (decodeInfo.brand) {
        const yearFromList =
          decodeInfo.years_of_issue && decodeInfo.years_of_issue.length === 0
            ? decodeInfo.years_of_issue[0]
            : null;

        set({
          vin,
          decodeInfo,
          isDecoding: false,
          isVinSuggestFilled: true,
          isVinFilled: true,
          brand: decodeInfo.brand,
          model: decodeInfo.model,
          generation: decodeInfo.generation,
          body: decodeInfo.body,
          year: decodeInfo.year_of_issue || yearFromList,
          engine: decodeInfo.engine,
          drive: decodeInfo.drive_unit,
          transmission: decodeInfo.transmission,
          modification: decodeInfo.modification
        });
      } else {
        set({ isDecoding: false });
      }
    } catch (error) {
      showAlert({ error });
      set({ isDecoding: false });
    }
  },
  decodeInfo: null,
  isVinSuggestFilled: false,
  setVinSuggestFilled: (isVinSuggestFilled: boolean) =>
    set({ isVinSuggestFilled }),
  isVinFilled: false,
  setVinFilled: (isVinFilled: boolean) => set({ isVinFilled }),
  type: null,
  setType: (type: Nullable<TransportType>) => set({ type }),
  resetBrandKey: 0,
  brand: null,
  setBrand: (brand: Nullable<TransportBrand>) => set({ brand }),
  model: null,
  setModel: (model: Nullable<TransportModel>) => set({ model }),
  owner: null,
  setOwner: (owner: Nullable<TransportOwner>) => set({ owner }),
  isOwnerFilled: false,
  setOwnerFilled: (isOwnerFilled: boolean) => set({ isOwnerFilled }),
  photos: [],
  setPhotos: (photos: PhotoType[]) => set({ photos }),
  replaceLocalPhoto: (uploaded: PhotoType, localUrl: string) => {
    const current = get().photos;
    set({
      photos: current.map((p) => (p.photo_url === localUrl ? uploaded : p))
    });
  },
  addPhotos: (photos: PhotoType[]) => {
    const current = get().photos;
    set({ photos: [...current, ...photos] });
  },
  removePhoto: (url: PhotoType['photo_url']) => {
    set({ photos: get().photos.filter((p) => p.photo_url !== url) });
  },
  isPhotosFilled: false,
  setPhotosFilled: (isPhotosFilled: boolean) => set({ isPhotosFilled }),
  description: '',
  setDescription: (description: string) => set({ description }),
  isDescriptionFilled: false,
  setDescriptionFilled: (isDescriptionFilled: boolean) =>
    set({ isDescriptionFilled }),
  isDescrGenerating: false,
  setDescrGenerating: (isDescrGenerating: boolean) =>
    set({ isDescrGenerating }),
  broken: false,
  setBroken: (broken: Nullable<boolean>) => set({ broken }),
  isBrokenFilled: false,
  setBrokenFilled: (isBrokenFilled: boolean) => set({ isBrokenFilled }),
  isCustomComplectation: false,
  setCustomComplectation: (isCustomComplectation: boolean) =>
    set({ isCustomComplectation }),
  complectationId: null,
  setComplectationId: (complectationId: Nullable<number>) =>
    set({ complectationId }),
  complectationOptionsItemsIds: [],
  setComplectationOptionsItemsIds: (complectationOptionsItemsIds: number[]) =>
    set({ complectationOptionsItemsIds }),
  isComplectationFilled: false,
  setComplectationFilled: (isComplectationFilled: boolean) =>
    set({ isComplectationFilled }),
  regSpecs: null,
  setRegSpecs: (regSpecs: Nullable<RegSpec>) => set({ regSpecs }),
  isRegSpecsFilled: false,
  setRegSpecsFilled: (isRegSpecsFilled: boolean) => set({ isRegSpecsFilled }),
  contacts: null,
  setContacts: (contacts: Nullable<UpdateAdvertContact[]>) => set({ contacts }),
  isPhoneFilled: false,
  setPhoneFilled: (isPhoneFilled: boolean) => set({ isPhoneFilled }),
  chatOnly: false,
  setChatOnly: (chatOnly: boolean) => set({ chatOnly }),
  disableChat: false,
  setDisableChat: (disableChat: boolean) => set({ disableChat }),
  addressId: null,
  setAddressId: (addressId: Nullable<number>) => set({ addressId }),
  isAddressFilled: false,
  setAddressFilled: (isAddressFilled: boolean) => set({ isAddressFilled }),
  stickers: [],
  setStickers: (stickers: number[]) => set({ stickers }),
  promotionPackageId: null,
  setPromotionPackageId: (promotionPackageId: Nullable<number>) =>
    set({ promotionPackageId }),
  price: 0,
  setPrice: (price: number) => set({ price }),
  discount: 0,
  setDiscount: (discount: number) => set({ discount }),
  discountTypes: [],
  setDiscountTypes: (discountTypes: number[]) => set({ discountTypes }),
  canExchange: false,
  setCanExchange: (canExchange: boolean) => set({ canExchange }),
  isPriceFilled: false,
  setPriceFilled: (isPriceFilled: boolean) => set({ isPriceFilled }),
  warranty: '',
  setWarranty: (warranty: string) => set({ warranty }),
  isWarrantyFilled: false,
  setWarrantyFilled: (isWarrantyFilled: boolean) => set({ isWarrantyFilled }),
  autoExtension: false,
  setAutoExtension: (autoExtension: boolean) => set({ autoExtension }),
  resetAll: (resetBrand?: boolean) => {
    const state = get();

    const brandKeys = resetBrand
      ? { brand: null, resetBrandKey: state.resetBrandKey + 1 }
      : {};

    set({
      ...brandKeys,
      model: null,
      year: null,
      generation: null,
      body: null,
      engine: null,
      drive: null,
      transmission: null,
      modification: null,
      color: null,
      mileage: 0,
      isMileageFilled: false,
      isSpecsFilled: false,
      step: SpecStep.year,
      vin: '',
      isDecoding: false,
      decodeInfo: null,
      isVinFilled: false,
      owner: null,
      isOwnerFilled: false,
      photos: [],
      isPhotosFilled: false,
      description: '',
      isDescrGenerating: false,
      isDescriptionFilled: false,
      broken: false,
      isBrokenFilled: false,
      isCustomComplectation: false,
      complectationId: null,
      complectationOptionsItemsIds: [],
      isComplectationFilled: false,
      regSpecs: null,
      isRegSpecsFilled: false,
      contacts: null,
      isPhoneFilled: false,
      chatOnly: false,
      disableChat: false,
      addressId: null,
      isAddressFilled: false,
      stickers: [],
      promotionPackageId: null,
      price: 0,
      discount: 0,
      discountTypes: [],
      canExchange: false,
      isPriceFilled: false,
      warranty: '',
      isWarrantyFilled: false,
      autoExtension: false,
      lastSectionVisible: false,
      showPageLeaveWarn: true
    });
  }
});
