import cn from 'classnames';

import cls from './Header.module.scss';

export enum ViewType {
  desktop = 'desktop',
  tablet = 'tablet',
  phone = 'phone'
}

type Props = {
  coverNode: React.ReactNode;
  logoNode: React.ReactNode;
  rowNode: React.ReactNode;
  viewType?: ViewType;
};

export function Header({
  coverNode,
  logoNode,
  rowNode,
  viewType = ViewType.desktop
}: Props) {
  const viewCls = cls[`root_${viewType}`];

  return (
    <div className={cn(cls.root, viewCls)}>
      <div className={cls.cover}>{coverNode}</div>
      <div className={cls.subheader}>
        <div className={cls.logo_wrap}>
          <div className={cls.logo}>{logoNode}</div>
        </div>
        <div className={cls.row}>{rowNode}</div>
      </div>
    </div>
  );
}
