import { create } from 'zustand';

import { Nullable } from '@/app/types';
import { getAccountInfo, getBalance } from '@/modules/accounts/api';
import { showAlert } from '@/utils/network';

import { BalanceRes, NullableAccountInfo } from './types';

interface AccountStore {
  isUserLoading: boolean;
  accountInfo: NullableAccountInfo;
  setAccountInfo: (accountInfo: NullableAccountInfo) => void;
  fetchAccountInfo: () => void;
  balanceInfo: Nullable<BalanceRes>;
  fetchBalance: () => void;
  reset: () => void;
}

export const useAccountStore = create<AccountStore>((set, get) => ({
  isUserLoading: false,
  accountInfo: null,
  setAccountInfo: (accountInfo: NullableAccountInfo) => set({ accountInfo }),
  fetchAccountInfo: async () => {
    set({ isUserLoading: true });
    try {
      const { data } = await getAccountInfo();
      set({ accountInfo: data, isUserLoading: false });
      get().fetchBalance();
    } catch (error) {
      showAlert({ error });
      set({ isUserLoading: false });
    }
  },
  balanceInfo: null,
  fetchBalance: async () => {
    set({ isUserLoading: true });
    try {
      const { data } = await getBalance();
      set({ balanceInfo: data, isUserLoading: false });
    } catch (error) {
      showAlert({ error });
      set({ isUserLoading: false });
    }
  },
  reset: () => {
    set({ accountInfo: null, isUserLoading: false, balanceInfo: null });
  }
}));
