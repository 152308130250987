import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './Button.module.scss';
import { ButtonProps } from './ButtonProps';

interface Props extends Omit<ButtonProps, 'onClick'> {
  to: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const ButtonLink: React.FC<Props> = ({
  children,
  size = 'l',
  variant = 'primary',
  color = 'red',
  fullWidth,
  to,
  externalLink,
  loading,
  gap,
  ...rest
}) => {
  const classes = cn(cls.button, {
    [cls.button_fullwidth]: fullWidth,
    [cls.button_loading]: loading,
    // Size
    [cls.button_size_s]: size === 's',
    [cls.button_size_m]: size === 'm',
    [cls.button_size_l]: size === 'l',
    [cls.button_size_compact]: size === 'compact',
    // Variants
    [cls.button_primary]: variant === 'primary',
    [cls.button_secondary]: variant === 'secondary',
    [cls.button_tertiary]: variant === 'tertiary',
    [cls.button_outline]: variant === 'outline',
    [cls.button_link]: variant === 'link',
    // Color
    [cls.button_black]: color === 'black',
    [cls.button_red]: color === 'red',
    [cls.button_green]: color === 'green',
    [cls.button_blue]: color === 'blue'
  });

  return externalLink ? (
    <a className={classes} href={to} target="_blank" rel="noreferrer" {...rest}>
      <div className={cls.inner} style={{ gap }}>
        {children}
      </div>
      {typeof loading === 'boolean' && (
        <div className={cls.spinner}>
          <Spinner color="currentColor" size={24} />
        </div>
      )}
    </a>
  ) : (
    <Link to={to} className={classes} {...rest}>
      <div className={cls.inner} style={{ gap }}>
        {children}
      </div>
      {typeof loading === 'boolean' && (
        <div className={cls.spinner}>
          <Spinner color="currentColor" size={24} />
        </div>
      )}
    </Link>
  );
};

ButtonLink.displayName = 'ButtonLink';
