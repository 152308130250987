import { useMemo, useState } from 'react';

import { ImgProxyOptions, imgproxy } from '@/modules/imgproxy/consts';
import { Skeleton } from '@/ui/Skeleton';
import { validImgSrc } from '@/utils/validate';

import cls from './ImgSkeleton.module.scss';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;
type Props = ImageProps & {
  skeletonStyle?: React.CSSProperties;
  showSkeleton?: boolean;
  imgProxyWidth?: ImgProxyOptions['width'];
  imgProxyHeight?: ImgProxyOptions['height'];
};

export function ImgSkeleton({
  skeletonStyle,
  showSkeleton,
  className,
  onLoad,
  onError,
  style,
  imgProxyWidth,
  imgProxyHeight,
  src: originalSrc,
  ...props
}: Props) {
  const imgProxyOptions = useMemo(
    () => ({ width: imgProxyWidth, height: imgProxyHeight }),
    [imgProxyHeight, imgProxyWidth]
  );

  const src = useMemo(() => {
    if (originalSrc) {
      return imgproxy(originalSrc, imgProxyOptions);
    }

    return originalSrc;
  }, [originalSrc, imgProxyOptions]);

  const [loaded, setLoaded] = useState(false);
  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setLoaded(true);
    if (onLoad) onLoad(e);
  };
  const handleLoadErr = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (onError) onError(e);
  };

  const shouldShowSkeleton = !!showSkeleton || !loaded;

  return (
    <>
      {shouldShowSkeleton && (
        <span className={cls.skeleton}>
          <Skeleton
            style={typeof window !== 'undefined' ? skeletonStyle : {}}
            className={className}
          />
        </span>
      )}
      <img
        {...(props as ImageProps)}
        src={validImgSrc(src)}
        className={className}
        onLoad={handleLoad}
        onError={handleLoadErr}
        style={
          typeof window !== 'undefined'
            ? { ...style, display: shouldShowSkeleton ? 'none' : undefined }
            : {}
        }
      />
    </>
  );
}
