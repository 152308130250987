import { useMemo } from 'react';

import { ExpensesAdvert } from '@/modules/wallet/ExpensesAdverts/ExpensesAdvert';
import { mapExpensesAdverts } from '@/modules/wallet/ExpensesAdverts/helpers';
import { ExpensesDetailStatisticsRes } from '@/modules/wallet/types';

import cls from './ExpensesAdverts.module.scss';

type Props = {
  expenses: ExpensesDetailStatisticsRes;
};

export function ExpensesAdverts({ expenses }: Props) {
  const mappedData = useMemo(() => mapExpensesAdverts(expenses), [expenses]);

  return (
    <ul className={cls.list}>
      {mappedData.map((v) => (
        <li key={v.advert.id}>
          <ExpensesAdvert expensesAdvert={v} />
        </li>
      ))}
    </ul>
  );
}
