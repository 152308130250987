import { CommonObj, Nullable, PaginationReq } from '@/app/types';
import { RentAdvertStatusId } from '@/modules/showroom/advert/rent/my/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';

export enum TransportIconType {
  pdf = 'pdf',
  svg = 'svg'
}

// Types
export interface TransportType extends CommonObj {
  slug: string;
}

export type GetTransportTypesReq = PaginationReq;
export type GetTransportTypesRes = TransportType[];

// Brands
export interface TransportBrand extends CommonObj {
  photo_url: string;
  dark_mode_photo_url: string;
  keywords_string: Nullable<string>;
  is_popular: boolean;
}

export interface GetTransportBrandsReq extends PaginationReq {
  value: Nullable<string>;
  category_id: Nullable<number>;
  include_keywords?: Nullable<boolean>;
}

export interface GetMyTransportBrandsReq extends PaginationReq {
  status: Nullable<AdvertStatusType> | undefined;
}

export interface GetMyRentTransportBrandsReq extends PaginationReq {
  status_id: Nullable<RentAdvertStatusId> | undefined;
}

export type GetTransportBrandsRes = TransportBrand[];

// Models
export interface TransportModel extends CommonObj {
  is_popular: boolean;
}

export interface GetTransportModelsReq extends PaginationReq {
  value?: string;
  brand_id: Nullable<number>;
}
export interface GetMyTransportModelsReq extends GetMyTransportBrandsReq {
  brand_id: Nullable<number>;
}
export interface GetMyRentTransportModelsReq
  extends GetMyRentTransportBrandsReq {
  brand_id: Nullable<number>;
}

export type GetTransportModelsRes = TransportModel[];

// Generations
export interface TransportGeneration extends CommonObj {
  photo_url: Nullable<string>;
}

export interface GetTransportGenerationsReq extends PaginationReq {
  model_id: Nullable<number>;
  year_of_issue_id: Nullable<number>;
}
export interface GetMyTransportGenerationsReq extends GetMyTransportBrandsReq {
  model_id: Nullable<number>;
}
export interface GetMyRentTransportGenerationsReq
  extends GetMyRentTransportBrandsReq {
  model_id: Nullable<number>;
}

export type GetTransportGenerationsRes = TransportGeneration[];

// Years
export type TransportYear = CommonObj;

export interface GetTransportYearsReq extends PaginationReq {
  model_id?: number;
  brand_ids?: number[];
  model_ids?: number[];
}

export type GetTransportYearsRes = TransportYear[];

// Bodies
export interface TransportBody extends CommonObj {
  photo_url: Nullable<string>;
}

export interface GetTransportBodiesReq extends PaginationReq {
  generation_id?: number;
  brand_ids?: number[];
  model_ids?: number[];
  icon_type?: TransportIconType;
}

export type GetTransportBodiesRes = TransportBody[];

// Colors
export interface TransportColor extends CommonObj {
  hex: string;
  photo_url: Nullable<string>;
}

export interface GetTransportColorsReq extends PaginationReq {
  photo_type?: TransportIconType;
}

export type GetTransportColorsRes = TransportColor[];

// Engines
export interface TransportEngine extends CommonObj {
  icon_url: Nullable<string>;
}

export interface GetTransportEnginesReq extends PaginationReq {
  icon_type?: TransportIconType;
  brand_id?: number;
  model_id?: number;
  generation_id?: number;
  body_id?: number;
  brand_ids?: number[];
  model_ids?: number[];
}

export type GetTransportEnginesRes = TransportEngine[];

// Drive units
export interface TransportDriveUnit extends CommonObj {
  icon_url: Nullable<string>;
}

export interface GetTransportDriveUnitsReq extends GetTransportEnginesReq {
  engine_id?: number;
}
export type GetTransportDriveUnitsRes = TransportDriveUnit[];

// Transmissions
export interface TransportTransmission extends CommonObj {
  icon_url: Nullable<string>;
}

export interface GetTransportTransmissionsReq
  extends GetTransportDriveUnitsReq {
  drive_unit_id?: number;
}
export type GetTransportTransmissionsRes = TransportTransmission[];

// Owners
export type TransportOwner = CommonObj;
export type GetTransportOwnersReq = PaginationReq;
export type GetTransportOwnersRes = TransportOwner[];

// Modifications
interface ModificationParam extends CommonObj {
  short_name: string;
}
export interface TransportModification extends CommonObj {
  engine: ModificationParam;
  drive_unit: ModificationParam;
  transmission: ModificationParam;
  volume: Nullable<ModificationParam>;
  horse_power: Nullable<ModificationParam>;
  notice: Nullable<string>;
}

export interface GetTransportModificationsReq extends PaginationReq {
  generation_id?: number;
  body_id?: number;
  drive_unit_id?: number;
  engine_id?: number;
  transmission_id?: number;
}
export type GetTransportModificationsRes = TransportModification[];
