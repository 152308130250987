import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { ContactsFilter } from '@/modules/leads/LeadsPage/Toolbar/ContactsFilter';
import { SortFilter } from '@/modules/showroom/advert/my/types';
import filtersCls from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters.module.scss';
import { SortSelect } from '@/modules/showroom/advert/search/SearchAdverts/SortSelect/SortSelect';
import { AutoFilterIds } from '@/modules/showroom/advert/search/types';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

import { AutoFilter } from './AutoFilter';
import cls from './Toolbar.module.scss';

type Props = {
  search: string;
  setSearch: (search: string) => void;
  autoFilters: AutoFilterIds[];
  updateAutoFilter: (v: AutoFilterIds) => void;
  contactIds: Nullable<number[]>;
  setContactIds: (contactIds: Nullable<number[]>) => void;
  sortFilters: SortFilter[];
  sortId: Nullable<number>;
  setSortId: (sortId: Nullable<number>) => void;
  showReset: boolean;
  onResetClick: () => void;
};

export function Toolbar({
  search,
  setSearch,
  autoFilters,
  updateAutoFilter,
  contactIds,
  setContactIds,
  sortFilters,
  sortId,
  setSortId,
  showReset,
  onResetClick
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={filtersCls.root}>
      <div className={cls.search_field}>
        <InputSearch
          value={search}
          onChange={setSearch}
          placeholder={t('common.search')}
          showClear
          compact
          outlined
        />
      </div>
      <ul className={filtersCls.list}>
        <li className={filtersCls.li}>
          <AutoFilter
            updateAutoFilter={updateAutoFilter}
            autoFilters={autoFilters}
          />
        </li>
        <li className={filtersCls.li}>
          <ContactsFilter
            contactIds={contactIds}
            setContactIds={setContactIds}
          />
        </li>
        {showReset && (
          <li className={filtersCls.li}>
            <button
              className={filtersCls.reset_btn}
              onClick={onResetClick}
              type="button"
            >
              {t('common.reset')}
            </button>
          </li>
        )}
      </ul>
      <SortSelect
        sortFilters={sortFilters}
        sortId={sortId}
        setSortId={setSortId}
      />
    </div>
  );
}
