export function ChevronIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58534 7.99365C3.4235 7.83527 3.33331 7.62416 3.33331 7.38102C3.33331 6.89069 3.71362 6.50764 4.20262 6.50764C4.44848 6.50764 4.67522 6.59982 4.8342 6.77115L10.3716 12.4384H9.63519L15.1671 6.77115C15.3309 6.59848 15.5591 6.50764 15.7974 6.50764C16.285 6.50764 16.6666 6.89069 16.6666 7.38102C16.6666 7.62551 16.5779 7.83662 16.4133 7.99365L10.6815 13.8595C10.4913 14.0608 10.2597 14.1639 10.0038 14.1667C9.74299 14.1667 9.51626 14.0622 9.31978 13.8595L3.58534 7.99365Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ClearIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L10 8.93934L14.2197 4.71967C14.5126 4.42678 14.9874 4.42678 15.2803 4.71967C15.5732 5.01256 15.5732 5.48744 15.2803 5.78033L11.0607 10L15.2803 14.2197C15.5732 14.5126 15.5732 14.9874 15.2803 15.2803C14.9874 15.5732 14.5126 15.5732 14.2197 15.2803L10 11.0607L5.78033 15.2803C5.48744 15.5732 5.01256 15.5732 4.71967 15.2803C4.42678 14.9874 4.42678 14.5126 4.71967 14.2197L8.93934 10L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0303 4.96967C17.3232 5.26256 17.3232 5.73744 17.0303 6.03033L8.03033 15.0303C7.73744 15.3232 7.26256 15.3232 6.96967 15.0303L3.46967 11.5303C3.17678 11.2374 3.17678 10.7626 3.46967 10.4697C3.76256 10.1768 4.23744 10.1768 4.53033 10.4697L7.5 13.4393L15.9697 4.96967C16.2626 4.67678 16.7374 4.67678 17.0303 4.96967Z"
        fill="currentColor"
      />
    </svg>
  );
}
