import cls from './CloseBtn.module.scss';
import { CloseIcon } from './icons';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

export function CloseBtn({ onClick, disabled }: Props) {
  return (
    <button
      className={cls.root}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <CloseIcon />
    </button>
  );
}
