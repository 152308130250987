import cn from 'classnames';

import cls from './Checkbox.module.scss';
import {
  CheckboxIcon,
  CheckboxRoundedIcon,
  UncheckIcon,
  UncheckRoundedIcon
} from './icons';

interface Props {
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  children?: React.ReactNode;
  rounded?: boolean;
  small?: boolean;
}

export const Checkbox: React.FC<Props> = ({
  id,
  checked,
  onChange,
  disabled,
  name,
  children,
  rounded,
  small
}) => {
  return (
    <div className={cn(cls.container, { [cls.container_small]: small })}>
      <input
        className={cls.input}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
      />

      <label className={cls.label} htmlFor={id}>
        {rounded ? (
          <>{checked ? <CheckboxRoundedIcon /> : <UncheckRoundedIcon />}</>
        ) : (
          <>{checked ? <CheckboxIcon /> : <UncheckIcon />}</>
        )}
      </label>

      {children && (
        <label className={cls.text} htmlFor={id}>
          {children}
        </label>
      )}
    </div>
  );
};

Checkbox.displayName = 'Checkbox';
