import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { useCountryCodes } from '@/modules/country-codes/hooks';
import { Input } from '@/ui/Input/Input';
import { Select } from '@/ui/Select/Select';

import {
  handlePlusAtStart,
  formatByMask,
  Props,
  searchCountryCode,
  handlePlusAtEnd,
  handleStr,
  MAX_PHONE_LENGTH
} from './helpers';

export function PhoneInput({
  code,
  setCode,
  onChange,
  hideCountries,
  setMaskCorrect,
  ...rest
}: Props) {
  const { t } = useTranslation();

  // Country codes
  const codes = useCountryCodes();
  const onCodeChange = (v: OptionI) => {
    if (!codes) return;

    const c = codes.find((cd) => String(cd.id) === v.id);
    if (c) {
      setCode(c);
      if (!code || code.code !== c.code) {
        onChange(c.code);
      }
    }
  };

  // Country options
  const options = useMemo<OptionI[]>(
    () =>
      codes
        ? codes.map((e) => ({
            id: String(e.id),
            text: e.country,
            extra: e.code,
            keywords: e.keywords_string || ''
          }))
        : [],
    [codes]
  );
  const option = options.find((opt) => Number(opt.id) === code?.id);

  // Phone input
  const handleChange = (value: string) => {
    const setValid = (v: boolean) => {
      setMaskCorrect ? setMaskCorrect(v) : () => null;
    };
    setValid(true);

    let v = handlePlusAtStart(handleStr(value));

    // Empty
    if (!v || v === '+') {
      onChange('+');
      setCode(null);
      return;
    }

    if (!v.startsWith('+')) {
      const result = handlePlusAtEnd(`+${v}`);
      v = result;
      if (code && !code.code.startsWith(result)) {
        setCode(null);
      }
    }

    // New country code
    if (codes && v) {
      const c = searchCountryCode(codes, v);

      if (c && (!code || code.code !== c.code)) {
        setCode(c);
        onChange(formatByMask(v, c.code, c.mask));
        return;
      }
    }

    // Mask
    if (code && code.mask) {
      const mask = code.mask;

      let maskIndex = 0;
      const initial = code.code + ' ';
      let formattedValue = initial;

      if (v.length > initial.length - 1) {
        for (let i = initial.length - 1; i < v.length; i++) {
          if (mask[maskIndex] === ' ' && v[i] !== ' ') {
            formattedValue += ' ';
            maskIndex++;
          }
          if (mask[maskIndex] === '#' && /\d/.test(v[i])) {
            formattedValue += v[i];
            maskIndex++;
          }
        }

        if (formattedValue.length !== mask.length + code.code.length + 1) {
          setValid(false);
        }

        onChange(formattedValue);
        return;
      } else {
        setValid(false);
      }
    } else {
      setValid(false);
    }

    onChange(v);
  };

  useEffect(() => {
    if (!rest.value) {
      onChange('+');
    }
  }, [onChange, rest.value]);

  useEffect(() => {
    if (!code && rest.value) {
      const c = searchCountryCode(codes, rest.value);
      if (c) setCode(c);
    }
  }, [code, codes, rest.value, setCode]);

  return (
    <>
      {!hideCountries && (
        <Select
          name="signin-country"
          value={option}
          onChange={onCodeChange}
          options={options}
          label={t('country')}
          showSearch
          countries
        />
      )}

      <Input
        onChange={(e) => handleChange(e.currentTarget.value)}
        maxLength={MAX_PHONE_LENGTH}
        {...rest}
      />
    </>
  );
}
