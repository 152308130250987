import { OptionI } from '@/app/types';
import { useWalletFilters, WalletFilterType } from '@/modules/wallet/hooks';
import { Tabs } from '@/ui/Tabs/Tabs';

type Props = {
  selected: WalletFilterType;
  onChange: (s: WalletFilterType) => void;
};

export function BalanceFilters({ selected, onChange }: Props) {
  const options = useWalletFilters();
  const option = options.find((opt) => opt.id === selected);

  const handleChange = (opt: OptionI) => {
    onChange(opt.id as WalletFilterType);
  };

  return (
    <Tabs
      variant="segment"
      tabs={options}
      active={option}
      onChange={handleChange}
    />
  );
}
