'use client';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountStore } from '@/modules/accounts/store';
import { loginAccount } from '@/modules/auth/api';
import { ConfirmEmailCode } from '@/modules/auth/ConfirmEmail/ConfirmEmailCode';
import { useAuthStore } from '@/modules/auth/store';

import { Signup } from './Signup';

export function SignupPage() {
  const navigate = useNavigate();
  const setAuth = useAuthStore((s) => s.setAuth);
  const setAccountInfo = useAccountStore((s) => s.setAccountInfo);

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');

  const onSubmit = (createdEmail: string, password: string) => {
    setEmail(createdEmail);
    setPass(password);
  };

  const onCodeSubmit = () => {
    loginAccount({ email, password: pass })
      .then((res) => {
        setAuth(res.data);
        setAccountInfo(res.data);
        navigate(res.data.showroom_created ? '/' : '/showroom/create');
      })
      .catch(() => {
        navigate('/signin');
      });
  };

  return (
    <div className="box">
      {email ? (
        <ConfirmEmailCode email={email} onSubmit={onCodeSubmit} />
      ) : (
        <Signup onSubmit={onSubmit} />
      )}
    </div>
  );
}
