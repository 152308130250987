import { nanoid } from 'nanoid';

import { Nullable } from '@/app/types';
import {
  EMPTY_BRAND_ID,
  MAX_AUTO_FILTERS_LEN
} from '@/modules/showroom/advert/search/consts';
import { AutoFilterIds } from '@/modules/showroom/advert/search/types';

export type StringsObject = Record<string, string>;

export function parseAutoFilter(arr: StringsObject[]): AutoFilterIds[] {
  return arr
    .filter((v) => !!v.brand_id && v.brand_id !== String(EMPTY_BRAND_ID))
    .slice(0, MAX_AUTO_FILTERS_LEN)
    .map((v) => {
      const generation_id = Array.isArray(v.generation_id)
        ? v.generation_id.map((v) => Number(v))
        : v.generation_id
        ? Number(v.generation_id)
        : null;

      return {
        nanoid: nanoid(),
        brand_id: Number(v.brand_id),
        model_id: v.model_id ? Number(v.model_id) : null,
        generation_id
      };
    });
}

export function parseIds(arr: string[]): number[] {
  return arr.map((v) => Number(v));
}

export function parseNum(v: unknown): Nullable<number> {
  if (typeof v === 'number') return v;
  if (typeof v === 'string') {
    const num = Number(v);
    if (num && !Number.isNaN(num)) {
      return num;
    }
  }
  return null;
}

export function parseBool(v: unknown): Nullable<boolean> {
  if (v === true || v === 'true') return true;
  if (v === false || v === 'false') return false;
  return null;
}

export function parseString(v: unknown): Nullable<string> {
  if (typeof v === 'string' && v) return v;
  return null;
}

export function isArr(v: unknown): boolean {
  return Array.isArray(v);
}
