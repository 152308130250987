import { useEffect } from 'react';

import { useLang } from '@/hooks/useLang';
import { useRentPayMethodsStore } from '@/modules/showroom/advert/rent/pay-methods/store';
import { RentPayMethod } from '@/modules/showroom/advert/rent/pay-methods/types';

export function useRentPayMethods(): RentPayMethod[] {
  const [lang] = useLang();

  const data = useRentPayMethodsStore((s) => s.data);
  const list = data[lang];

  const fetchPayMethods = useRentPayMethodsStore((s) => s.fetchData);
  useEffect(() => {
    fetchPayMethods();
  }, [fetchPayMethods]);

  return list;
}
