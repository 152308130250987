import { AddressCardSkeleton } from '@/modules/showroom/address/AddressList/AddressCard/AddressCardSkeleton';

import cls from './AddressList.module.scss';

const skeletons = new Array(4).fill(0);

export function AddressListSkeleton() {
  return (
    <ul className={cls.list}>
      {skeletons.map((_, i) => (
        <li key={i}>
          <AddressCardSkeleton />
        </li>
      ))}
    </ul>
  );
}
