import { useMemo } from 'react';

import { useSortFilters } from '@/modules/showroom/advert/my/hooks';
import { useSearchReq } from '@/modules/showroom/advert/search/hooks';
import { SearchPlates } from '@/modules/showroom/advert/search/SearchPlates/SearchPlates';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

type Props = {
  categoryId: number;
  showResults?: boolean;
};

export function SearchPlatesWrap({
  categoryId,
  showResults: showResultsProp
}: Props) {
  const [req, showResults] = useSearchReq();

  const sortReq = useMemo(
    () => ({
      ...MAX_PG_LIMIT_REQ,
      category_id: categoryId
    }),
    [categoryId]
  );
  const [sortFilters] = useSortFilters(sortReq);

  return (
    <SearchPlates
      req={req}
      categoryId={categoryId}
      showResults={showResults || !!showResultsProp}
      sortFilters={sortFilters || []}
    />
  );
}
