import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { RentAdvertsWrap } from '@/modules/showroom/advert/rent/my/RentAdvertsWrap';
import { RentAdvertExistedStatus } from '@/modules/showroom/advert/rent/my/types';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';

export function MyRentAdverts() {
  const { t } = useTranslation();

  const [refreshKey, setRefreshKey] = useState(0);
  const [notFoundAds, setNotFoundAds] = useState(false);

  const [status, setStatus] = useState<
    Nullable<RentAdvertExistedStatus> | undefined
  >();
  const refresh = useCallback(() => {
    setRefreshKey((p) => p + 1);
  }, []);
  const ctx = useMemo(
    () => ({
      refreshKey,
      status,
      setStatus,
      refresh,
      notFoundAds,
      setNotFoundAds
    }),
    [notFoundAds, refresh, refreshKey, status]
  );

  return (
    <div>
      <LayoutHeader title={t('carsRent')} />
      <PageHead title={t('pageTitle', { title: t('carsRent') })} />

      {/* todo: fix type err */}
      {/* @ts-ignore */}
      <MyAdvertsContext.Provider value={ctx}>
        <div>
          <RentAdvertsWrap showResults />
        </div>
      </MyAdvertsContext.Provider>
    </div>
  );
}
