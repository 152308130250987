import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import {
  CreateShowroomAddressReq,
  GetShowroomAddressListRes,
  UpdateShowroomAddressReq
} from '@/modules/showroom/address/types';
import { showAlert } from '@/utils/network';

const PATH = '/dealers/showrooms/address';

export function createShowroomAddress(req: CreateShowroomAddressReq) {
  return axios.post<unknown>(PATH, req);
}

export function updateShowroomAddress(req: UpdateShowroomAddressReq) {
  return axios.patch<unknown>(PATH, req);
}

export function deleteShowroomAddress(address_id: number) {
  return axios.delete<unknown>(PATH, { params: { address_id } });
}

function getShowroomAddressList() {
  return axios.get<GetShowroomAddressListRes>(`${PATH}/list`);
}

export function useShowroomAddressList(
  skip?: boolean
): [GetShowroomAddressListRes | undefined, boolean, () => Promise<void>] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetShowroomAddressListRes>();

  const load = useCallback(async () => {
    if (skip) return;
    setLoading(true);

    try {
      const r = await getShowroomAddressList();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [skip]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, loading, load];
}
