export enum SnapshotColorScheme {
  light = 'light',
  dark = 'dark'
}

export type SnapshotReq = {
  center?: string;
  z?: number;
  lang?: string;
  annotations?: string;
  colorScheme?: SnapshotColorScheme;
};

export type SnapshotRes = {
  url: string;
};

export interface SnapAnnotation {
  point: string;
  color?: string;
}
