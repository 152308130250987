import { UpdateAdvertContact } from '@/modules/showroom/advert/update/types';
import { makeHoursArray } from '@/utils/date';

export const hours = makeHoursArray();

export const defaultCommunicationMethod: UpdateAdvertContact['communication_method'] =
  {
    phone: {
      available: true,
      call_after: null,
      call_before: null
    },
    whatsapp: {
      available: true
    }
  };
