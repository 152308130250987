import cn from 'classnames';
import { useMemo, useEffect, useCallback, useState } from 'react';

import { useDesktop } from '@/app/common-store';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { CommonModal } from '@/ui/modals/CommonModal/CommonModal';

import { CloseIcon, ChevronIcon } from './icons';
import cls from './Previews.module.scss';

const ESC_KEY = 'Escape';
const UP_CODE = 'ArrowUp';
const DOWN_CODE = 'ArrowDown';
const LEFT_CODE = 'ArrowLeft';
const RIGHT_CODE = 'ArrowRight';

type Props = {
  isOpen: boolean;
  close: () => void;
  photos: string[];
  initialUrl?: string;
};

export const Previews: React.FC<Props> = ({
  isOpen,
  close,
  photos,
  initialUrl
}) => {
  const isDesktop = useDesktop();

  const [index, setIndex] = useState(0);
  const selected = useMemo(() => photos[index], [photos, index]);

  useEffect(() => {
    if (typeof initialUrl === 'string') {
      const index = photos.findIndex((p) => p === initialUrl);
      if (index !== -1) setIndex(index);
    }
  }, [photos, initialUrl, setIndex]);

  const onPrevClick = useCallback(() => {
    if (index !== 0) setIndex(index - 1);
  }, [index, setIndex]);

  const onNextClick = useCallback(() => {
    if (index !== photos.length - 1) setIndex(index + 1);
  }, [index, photos.length, setIndex]);

  // Keydown
  const onKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === ESC_KEY) {
        e.preventDefault();
        close();
      } else if (e.code === LEFT_CODE || e.code === UP_CODE) {
        e.preventDefault();
        onPrevClick();
      } else if (e.code === RIGHT_CODE || e.code === DOWN_CODE) {
        e.preventDefault();
        onNextClick();
      }
    },
    [close, onPrevClick, onNextClick]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeydown);
    } else {
      window.removeEventListener('keydown', onKeydown);
    }

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, [onKeydown, isOpen]);

  return (
    <CommonModal
      name="photos-previews"
      isOpen={isOpen}
      close={close}
      containerClass={cn(cls.modal_container, cls.modal_container_imp)}
      backdropClass={cls.modal_backdrop}
    >
      <div className={cls.root}>
        <header className={cls.header}>
          <div className={cls.info}>
            {!isDesktop && (
              <div className={cls.buttons}>
                <button type="button" onClick={close}>
                  <CloseIcon />
                </button>
              </div>
            )}
          </div>

          {isDesktop && (
            <div className={cls.buttons}>
              <button type="button" onClick={close}>
                <CloseIcon />
              </button>
            </div>
          )}
        </header>

        <div className={cls.content}>
          <button
            className={cn(cls.arrow_btn, cls.arrow_btn_prev)}
            type="button"
            onClick={onPrevClick}
          >
            <ChevronIcon />
          </button>
          <button
            className={cn(cls.arrow_btn, cls.arrow_btn_next)}
            type="button"
            onClick={onNextClick}
          >
            <ChevronIcon />
          </button>

          {selected && (
            <ImgSkeleton
              className={cls.img}
              src={selected}
              alt=""
              imgProxyWidth={2000}
              imgProxyHeight={2000}
            />
          )}
        </div>
      </div>
    </CommonModal>
  );
};
