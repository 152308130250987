import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { ContentDoc } from '@/modules/chat/Detail/Messages/Message/ContentDoc/ContentDoc';
import { Placeholder } from '@/ui/Placeholder/Placeholder';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './Files.module.scss';
import { useChatFiles } from './helpers';

import empty from '../empty.json';

type Props = {
  chatId: number;
};

export const Files: React.FC<Props> = ({ chatId }) => {
  const { t } = useTranslation();
  const { isAllLoaded, isLoading, data, load } = useChatFiles();

  const fetchData = () => {
    if (isAllLoaded || isLoading) return;
    load(chatId, data ? data.length : 0);
  };

  return (
    <div className={cls.root}>
      {data && data.length > 0 && (
        <ul className={cls.list}>
          {data.map((v) => (
            <li key={v.url}>
              <ContentDoc doc={v} compact />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && data && data.length <= 0 && (
        <div className={cls.empty}>
          <Placeholder
            lottieData={empty}
            title={t('chat.empty.files.title')}
            text={t('chat.empty.files.text')}
          />
        </div>
      )}

      {isLoading && <Spinner centered color="var(--clr-blue)" size={32} />}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </div>
  );
};
