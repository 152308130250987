import { StoreRentTariff } from '@/modules/showroom/advert/rent/tariffs/types';

type HandlerParams = {
  tariffs: StoreRentTariff[];
  index: number;
  nextIndex: number;
  tf: StoreRentTariff;
  nextTf: StoreRentTariff | undefined;
};

export function handleTariffs({
  tariffs,
  index,
  tf,
  nextTf
}: HandlerParams): StoreRentTariff[] {
  const to = tf.days_up_to;

  if (nextTf && typeof to === 'number' && to >= nextTf.days_from) {
    return [
      ...tariffs.slice(0, index),
      tf,
      { ...nextTf, days_from: to + 1, days_up_to: null }
    ];
  }

  return tariffs.map((v) => {
    if (v.localId === tf.localId) return tf;
    return v;
  });
}
