import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { FoundChatCard } from '@/modules/chat/ChatList/ChatCard/FoundChatCard';
import { ChatWithFoundedMessage } from '@/modules/chat/types';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './ChatsSearch.module.scss';
import { useSearchMessages } from './helpers';

type Props = {
  searchText: string;
  onChatClick: (c: ChatWithFoundedMessage) => void;
};

export const MessagesWrap: React.FC<Props> = ({ searchText, onChatClick }) => {
  const { t } = useTranslation();
  const { isAllLoaded, isLoading, data, load } = useSearchMessages();

  const fetchData = () => {
    if (isAllLoaded || isLoading) return;
    load(searchText, data ? data.length : 0);
  };

  return (
    <div className={cls.chats}>
      <p className={cls.list_title}>{t('chat.messages.in')}</p>

      {data && data.length > 0 && (
        <ul className={cls.list}>
          {data.map((c) => (
            <li key={c.id}>
              <FoundChatCard chat={c} onClick={() => onChatClick(c)} />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && data && data.length <= 0 && (
        <p className={cls.empty}>{t('noResults')}</p>
      )}

      {isLoading && <Spinner centered color="var(--clr-blue)" />}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </div>
  );
};
