import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { CommonObj, Nullable } from '@/app/types';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Spinner } from '@/ui/Spinner/Spinner';

import cls from './Avatar.module.scss';
import { getRandomColor } from './helpers';

type AvatarUser = CommonObj;

interface Props {
  // Link props
  to?: string;
  // Props
  user?: Nullable<AvatarUser>;
  src?: string;
  size?: number;
  style?: ElementCSSInlineStyle;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
}

export function Avatar({
  to,
  user,
  src,
  size = 30,
  style,
  onClick,
  disabled,
  loading
}: Props): JSX.Element {
  const showPlaceholder = useMemo(() => !src, [src]);

  const computedStyle = useMemo(() => {
    const s = `${size}px`;

    return {
      width: s,
      height: s,
      minWidth: s,
      ...style
    };
  }, [size, style]);

  const letter = useMemo(() => {
    if (user && user.name) {
      const name = user.name || '';
      if (name && name.replaceAll) {
        const withoutEmoji = name.replaceAll(/\p{Emoji}/gu, '').trim();
        if (withoutEmoji) return withoutEmoji[0].toUpperCase();
      }
    }

    return 'A';
  }, [user]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) onClick(e);
    },
    [onClick]
  );

  return (
    <div className={cls.avatar} style={computedStyle}>
      {src && (
        <ImgSkeleton
          className={cls.img}
          src={src}
          alt=""
          imgProxyWidth={size}
          imgProxyHeight={size}
        />
      )}

      <>
        {onClick && (
          <button
            className={cls.avatar_btn}
            type="button"
            onClick={handleClick}
            disabled={disabled}
          >
            <span>Avatar</span>
          </button>
        )}

        <div className={cls.border}>
          {to && <Link className={cls.link} to={to} />}
        </div>

        {showPlaceholder && (
          <div
            className={cls.placeholder}
            style={{
              backgroundColor: getRandomColor(user ? user.id : 0),
              fontSize: (size || 40) / 2.85
            }}
          >
            {letter}
          </div>
        )}

        {loading && (
          <div className={cls.spinner}>
            <Spinner size={size / 3.5} color="var(--clr-blue)" />
          </div>
        )}
      </>
    </div>
  );
}

Avatar.displayName = 'Avatar';
