import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Nullable } from '@/app/types';
import { Button } from '@/ui/Button/Button';
import { getCroppedImg } from '@/ui/Cropper/helpers';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { CommonModalProps } from '@/ui/modals/CommonModal/CommonModal';

import cls from './Cropper.module.scss';

const initialCrop: Crop = {
  unit: '%',
  width: 10,
  height: 10,
  x: 25,
  y: 25
};

type CropperData = {
  src: string;
  aspect?: number;
  maxWidth?: number;
  maxHeight?: number;
  loading?: boolean;
};

interface Props extends CommonModalProps {
  modalHeader?: React.ReactNode;
  cropperData: CropperData;
  onCrop: (blob: Nullable<Blob>, src: Nullable<string>) => void;
  cancelText?: string;
  confirmText?: string;
  onCancelClick?: () => void;
  onCropChange?: (croppedImgUrl: string) => void;
}

export function Cropper({
  modalHeader,
  cropperData,
  onCrop,
  cancelText,
  confirmText,
  onCancelClick,
  onCropChange,
  ...rest
}: Props) {
  const { t } = useTranslation();

  const { src, maxWidth, maxHeight, loading } = cropperData;

  const [croppedBlob, setCroppedBlob] = useState<Blob>();
  const [croppedUrl, setCroppedUrl] = useState('');
  const [image, setImage] = useState<Nullable<HTMLImageElement>>(null);
  const [crop, setCrop] = useState<Crop>(initialCrop);

  const onCancel = () => {
    if (onCancelClick) {
      onCancelClick();
      return;
    }

    onCrop(null, null);
    close();
  };

  const onComplete = async (
    p: PixelCrop | PercentCrop,
    el?: HTMLImageElement
  ) => {
    const img = el || image;
    if (img && p.width && p.height) {
      const [blob, croppedSrc] = await getCroppedImg(img, p);

      setCroppedBlob(blob);
      setCroppedUrl(croppedSrc);
      if (onCropChange) onCropChange(croppedSrc);
    }
  };

  const onCompleteClick = async () => {
    onCrop(croppedBlob || null, croppedUrl || null);
  };

  const onImageLoad = async (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const el = e.currentTarget;
    setImage(el);

    const partialCrop: Partial<Crop> = {
      unit: 'px',
      width: maxWidth || el.clientWidth
    };

    const defaultAspect = maxWidth ? 1 : el.clientWidth / el.clientHeight;
    const aspectValue = cropperData.aspect || defaultAspect;

    const updatedCrop = centerCrop(
      makeAspectCrop(partialCrop, aspectValue, el.clientWidth, el.clientHeight),
      el.clientWidth,
      el.clientHeight
    );

    onComplete(updatedCrop, el);
    setCrop(updatedCrop);
  };

  useEffect(() => {
    if (!rest.isOpen) {
      setCroppedBlob(undefined);
      setCroppedUrl('');
    }
  }, [rest.isOpen]);

  return (
    <AdaptiveModal {...rest}>
      <div className={cls.root}>
        {modalHeader}

        <div className={cls.cropper}>
          <ReactCrop
            aspect={cropperData.aspect}
            crop={crop}
            onChange={(_, p) => setCrop(p)}
            onComplete={(c) => {
              if (image) {
                onComplete(c);
              }
            }}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            style={{
              maxHeight: '80vh'
            }}
          >
            <img src={src} alt="" onLoad={onImageLoad} />
          </ReactCrop>
        </div>

        <div className={cls.buttons}>
          <Button
            variant="secondary"
            color="black"
            onClick={onCancel}
            disabled={!!loading}
          >
            {cancelText || t('common.cancel')}
          </Button>

          <Button
            color="black"
            onClick={onCompleteClick}
            disabled={!croppedUrl || !!loading}
            loading={loading}
          >
            {confirmText || t('common.continue')}
          </Button>
        </div>
      </div>
    </AdaptiveModal>
  );
}
