import cn from 'classnames';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';

import { ChevronIcon, LockIcon } from './icons';
import cls from './SpecCollapse.module.scss';

type Props = {
  isOpen: boolean;
  onClick: () => void;
  label: string;
  value?: Nullable<string>;
  children: React.ReactNode;
  disabled?: boolean;
  showPlaceholder?: boolean;
};

export const SpecCollapse: React.FC<Props> = ({
  isOpen,
  onClick,
  label,
  value,
  children,
  disabled,
  showPlaceholder
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(cls.root, { [cls.root_open]: isOpen && !disabled })}>
      <button
        className={cls.btn}
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        <span className={cls.label}>{label}</span>

        <span className={cls.value_wrap}>
          {value && <span className={cls.value}>{value}</span>}
          {!value && showPlaceholder && (
            <span className={cls.placeholder}>{t('common.notSpecified')}</span>
          )}
          {disabled ? <LockIcon /> : <ChevronIcon />}
        </span>
      </button>

      <Collapse isOpened={isOpen && !disabled}>
        <div className={cls.content}>{children}</div>
      </Collapse>
    </div>
  );
};
