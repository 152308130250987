import Tooltip from 'rc-tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  deleteRentAdvertisement,
  updateRentAdvertisement
} from '@/modules/showroom/advert/rent/api';
import { RentAdvertStatusId } from '@/modules/showroom/advert/rent/my/types';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { CommonModalProps } from '@/ui/modals/CommonModal/CommonModal';
import { rcTooltipProps } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import { CloseIcon, InfoIcon } from './icons';
import cls from './UnpublishRentAdvert.module.scss';

interface Props extends CommonModalProps {
  advertId: number;
  onSubmit: () => void;
}

export function UnpublishRentAdvert({ advertId, onSubmit, ...props }: Props) {
  const { t } = useTranslation();

  const [shouldDelete, setShouldDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);

    try {
      if (shouldDelete) {
        await deleteRentAdvertisement(advertId);
      } else {
        await updateRentAdvertisement(advertId, {
          status_id: RentAdvertStatusId.archive
        });
      }
      onSubmit();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdaptiveModal {...props}>
      <div className={cls.root}>
        <header className={cls.header}>
          <h2 className={cls.title}>{t('unpublish.archive')}</h2>
          <button className={cls.close_btn} type="button" onClick={props.close}>
            <CloseIcon />
          </button>
        </header>

        <div className={cls.container}>
          <Button
            onClick={submit}
            disabled={loading}
            loading={loading}
            color={shouldDelete ? 'red' : 'blue'}
            variant="primary"
            size="l"
            fullWidth
          >
            {t(shouldDelete ? 'advertisements.delete' : 'unpublish.archive')}
          </Button>
          <div className={cls.delete_wrap}>
            <div className={cls.delete_check}>
              <Checkbox
                id={`need-to-delete`}
                checked={shouldDelete}
                onChange={() => null}
                disabled={loading}
              />
              <span>{t('unpublish.deleteAd')}</span>
              <button
                type="button"
                onClick={() => setShouldDelete(!shouldDelete)}
                disabled={loading}
              />
            </div>

            <Tooltip
              placement="top"
              overlay={t('unpublish.deleteInfo')}
              overlayStyle={{ width: '260px' }}
              overlayInnerStyle={{ textAlign: 'center' }}
              {...rcTooltipProps}
            >
              <span className={cls.info_icon}>
                <InfoIcon />
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </AdaptiveModal>
  );
}
