import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { ChatAdvert } from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './Advert.module.scss';

type Props = {
  advert: ChatAdvert;
};

export function Advert({ advert }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();
  const { photos, price } = advert;

  const advertUrl = advert.url ? `${APP_URL}/${advert.url}` : '';
  const setChatOpen = useChatUtilsStore((s) => s.setChatOpen);

  const imgPlaceholder = useImgPlaceholder();
  const src = useMemo(() => {
    if (advert.vrp_image_url) return advert.vrp_image_url;
    if (!photos || photos.length <= 0) return imgPlaceholder;
    const s = photos[0].thumbnail_url || photos[0].photo_url;
    return s || imgPlaceholder;
  }, [advert.vrp_image_url, photos, imgPlaceholder]);

  const name = useAdvertName(advert);

  return (
    <div className={cls.root}>
      <a
        className={cls.link}
        onClick={() => setChatOpen(false)}
        href={advertUrl}
        target="_blank"
        rel="noreferrer"
      />

      <div className={cls.img}>
        <ImgSkeleton
          className={cls.img_inner}
          src={src}
          alt=""
          imgProxyWidth={85}
          imgProxyHeight={66}
        />
      </div>

      <div className={cls.text}>
        <p className={cls.name}>{name}</p>
        {price && (
          <span className={cls.price}>
            {t('priceCurrency', {
              price: numberWithSpaces(price, lang),
              currency: ADV_CUR
            })}
          </span>
        )}
      </div>
    </div>
  );
}
